import numeral from "numeral"
import Moment from "moment"

export const formatValue = (value, key) => {
  switch (key) {
    case "greek":
    case "iVolAsk":
    case "iVolBid":
    case "optionAsk":
    case "optionBid":
    case "tradeVol":
      return numeral(value).format("0.00")
    case "stockPx":
      return numeral(value).format("$0,0.00")
    case "tradeOptPx":
    case "tradeCost":
      return numeral(value).format("$0.00")
    case "entryDate":
      return Moment(value).format("MMMM Do YYYY")
    default:
      return value
  }
}

export const currencyFormatter = ({ value }) => {
  if (isNaN(value) || value === null) return ""
  return numeral(value).format("$0,0.00")
}

export const floatFormatter = ({ value }) => {
  if (isNaN(value)) return ""
  return numeral(value).format("0.00")
}

export const intFormatter = ({ value }) => {
  if (isNaN(value)) return ""
  return numeral(value).format("0,0")
}

export const debugFormatter = ({ value }) => {
  console.log(value)
  return value
}

export const numberParser = ({ newValue }) => {
  return Number(newValue)
}
