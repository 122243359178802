// OK, so i think I'll add a strategy Dropdown to the Menu
// immediately below the menu we'll have a list labels showing the selected Strategies
// we'll also show a list of the selected SYMBOLS
// select Strategies will also add to the SYMBOLS list

import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { Icon, Label, Loader, Dropdown } from "semantic-ui-react"

import Candle from "./Candle"
import Trades from "./Trades"
import util from "../../util"

import map from "lodash/map"

@inject("store")
@observer
export default class TradeFinder extends Component {
  componentWillMount() {
    this.props.store.setActiveRoute("tradefinder")
  }

  onStrategyRemove = (e, { name: key }) => {
    this.props.store.updateStategies(key, false)
    this.props.store.reFetchTrades()
  }

  onStrategyAdd = (e, { value: key }) => {
    this.props.store.updateStategies(key, true)
    this.props.store.reFetchTrades()
  }

  render() {
    const { store } = this.props
    const {
      chartData,
      isLoadingHistory,
      selectedStrategies,
      userStrategyOptions
    } = store

    return (
      <div>
        {map(selectedStrategies, (k, i) => (
          <Label
            key={i}
            name={k}
            content={util.strategyNamesMap[k]}
            onRemove={this.onStrategyRemove}
          />
        ))}
        <Label color="teal">
          <Icon name="add" />
          <Dropdown
            search
            label
            labeled
            options={userStrategyOptions}
            onChange={this.onStrategyAdd}
            text="ADD"
          />
        </Label>
        {/* <Link style={{ marginLeft: 4 }} to="/scan/strategies">
          Edit
        </Link> */}
        {chartData.length > 0 && !isLoadingHistory && <Candle />}
        {isLoadingHistory && (
          <div style={{ paddingTop: 30 }}>
            <Loader active size="tiny" inline="centered">
              loading chart data
            </Loader>
          </div>
        )}
        <Trades />
      </div>
    )
  }
}
