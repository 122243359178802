import React, { Component } from "react"
import { select, event as d3Event } from "d3-selection"
import { GenericChartComponent, utils } from "react-stockcharts"
import find from "lodash/find"
import numeral from "numeral"

const { d3Window, MOUSEMOVE, MOUSEUP, noop, functor } = utils
const CURSOR_HEIGHT = 8

export default class OutlookControl extends Component {
  constructor(props) {
    super(props)
    this.renderSVG = this.renderSVG.bind(this)

    const { pctChg, dollarValue } = this.props.store.outlook
    this.state = {
      hover: false,
      isPressed: false,
      mouse: [0, 0],
      delta: [0, 0],
      pctChg: pctChg,
      dollarValue: dollarValue
    }
  }

  saveNode = node => {
    this.node = node
  }

  handleMouseEnter = () => {
    this.setState({ hover: true })
  }
  handleMouseLeave = () => {
    this.setState({ hover: false })
  }

  handleMouseDown = e => {
    e.preventDefault()

    const {
      xScale,
      chartConfig: { yScale },
      plotData,
      xAccessor
    } = this.node.getMoreProps()

    let { expiration } = this.props.store.selectedExpiration
    const x = xScale(xAccessor(find(plotData, { tradeDate: expiration })))
    const y = yScale(
      this.state.dollarValue !== null
        ? this.state.dollarValue
        : this.props.store.zeroDriftPrice
    )

    const win = d3Window(this.node.refs.capture)

    select(win)
      .on(MOUSEMOVE, this.handleDrag)
      .on(MOUSEUP, this.handleDragEnd)

    this.setState({
      isPressed: true,
      delta: [x, y],
      mouse: [e.pageX - x, e.pageY - y]
    })
  }

  handleDrag = () => {
    const e = d3Event
    const [mouseDownX, mouseDownY] = this.state.mouse
    const delta = [e.pageX - mouseDownX, e.pageY - mouseDownY]
    const { dollarValue, pctChg } = this.getCursorValues(delta)
    this.setState({ delta, pctChg, dollarValue })
    this.props.store.setOutlook(pctChg, dollarValue)
  }

  handleDragEnd = () => {
    var win = d3Window(this.node.refs.capture)
    select(win)
      .on(MOUSEMOVE, null)
      .on(MOUSEUP, null)

    const { dollarValue, pctChg } = this.getCursorValues(this.state.delta)
    this.setState({ isPressed: false, pctChg, dollarValue })
    this.props.store.reFetchTrades()
  }

  getCursorValues = ([x, y]) => {
    const {
      chartConfig: { yScale }
    } = this.node.getMoreProps()
    var dollarValue = yScale.invert(y)
    const { zeroDriftPrice } = this.props
    const change = dollarValue - zeroDriftPrice
    const pctChg = (change / zeroDriftPrice) * 100
    return { dollarValue, pctChg }
  }

  renderSVG(moreProps) {
    const {
      xScale,
      chartConfig: { yScale },
      plotData,
      xAccessor
    } = moreProps

    const { delta, isPressed, pctChg } = this.state

    const { zeroDriftPrice } = this.props.store

    const dollarValue =
      this.state.dollarValue !== null ? this.state.dollarValue : zeroDriftPrice

    const x = xScale(
      xAccessor(find(plotData, { tradeDate: this.props.expiration }))
    )
    if (isNaN(x)) {
      return null
    }

    const y = isPressed ? delta[1] : yScale(dollarValue)

    const opacity = this.state.hover ? 1 : 0.8
    const cursor = this.state.isPressed ? "-webkit-grabbing" : "-webkit-grab"
    return (
      <g ref="capture">
        <circle
          cx={x + CURSOR_HEIGHT}
          cy={y}
          r={CURSOR_HEIGHT}
          opacity={opacity}
          className="react-stockcharts-enable-interaction"
          style={{ cursor }}
          stroke="orange"
          strokeWidth="2"
          fill="white"
          onMouseDown={this.handleMouseDown}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
        <text
          x={x + 18}
          y={y + 5}
          fill="black"
          fontFamily="Roboto Condensed"
          fontSize="10pt">{`${numeral(dollarValue).format("$0.0")} (${numeral(
          pctChg / 100
        ).format("0.0%")})`}</text>
      </g>
    )
  }

  render() {
    return (
      <GenericChartComponent
        ref={this.saveNode}
        svgDraw={this.renderSVG}
        isHover={functor(true)}
        onMouseDown={this.handleMouseDown}
        drawOnPan
      />
    )
  }
}

OutlookControl.drawOnCanvas = noop
