import React from "react"
import { Code } from "../Styled"
import { List } from "semantic-ui-react"

export const HELP_SNIPPETS = {
  general: { title: "Days to Expiration", content: "" },
  dte: {
    title: "Days to Expiration",
    content: (
      <div>
        <p>Days to expiration min and max for the trade to be considered.</p>
        <p>
          For example, it will search options that have days to expiration
          between and including <Code>20</Code> to <Code>55</Code>.
        </p>
      </div>
    )
  },
  absDelta: {
    title: "Absolute Delta",
    content: (
      <div>
        <p>Absolute value of a delta of an option.</p>
        <p>
          For example, it would search options that have absolute value of a
          delta between and including <Code>.20</Code> and <Code>.40</Code>.
        </p>
      </div>
    )
  },
  stockOTMPct: {
    title: "Strike / Stock Price Ratio",
    content: (
      <div>
        <p>
          Stock Percentage (<Code>strike</Code> / <Code>stockpx</Code>).
        </p>
        <p>
          For example it would search options that have stockpct between and
          including <Code>.9</Code> to <Code>1.25</Code>.
        </p>
      </div>
    )
  },
  spreadPx: {
    title: "Spread Price",
    content: (
      <div>
        <p>The net price of the spread.</p>
        <p>
          IMPORTANT: for selling options and net credit spreads the Spread
          Prices should be NEGATIVE. For example, Ideal:{" "}
          <Code textColor="red">-0.2</Code> Min:{" "}
          <Code textColor="red">-0.3</Code> Max:{" "}
          <Code textColor="red">-0.1</Code>.
        </p>
        <p>
          For example, it would set a min max of $0.10 to $0.20 and find only
          spreads with prices within that range.{" "}
        </p>
      </div>
    )
  },
  spreadDelta: {
    title: "Spread Delta",
    content: (
      <div>
        <p>The net delta of the spread.</p>
        <p>
          For example, it would set a min max of <Code>.30</Code> to{" "}
          <Code>.40</Code> and find only spreads with net deltas within that
          range.{" "}
        </p>
      </div>
    )
  },
  spreadYieldPct: {
    title: "Spread Yield",
    content: (
      <div>
        <p>
          Yield Percentage is (<Code>option entry price</Code> /{" "}
          <Code>stockprice</Code>).
        </p>
        <p>
          For example it would search options that have <Code>yldpct</Code>{" "}
          between and including <Code>.05</Code> to <Code>.10</Code> (5 and 10
          percent).
        </p>
      </div>
    )
  },
  exitDTEDays: {
    title: "Exit Days to Expiration Days",
    content: (
      <div>
        <p>
          Exit the trade when the days to expiration left is equal to or below
          this.
        </p>
        <p>For example, it would exit a trade with 10 days to expiration. </p>
      </div>
    )
  },
  exitHoldDays: {
    title: "Exit Hold Days",
    content: (
      <div>
        <p>Exit the trade when the trade was held this many days.</p>
        <p>
          For example, it would exit a trade when the trade has been held for
          <Code>20</Code> days.
        </p>
      </div>
    )
  },
  exitLeg1Delta: {
    title: "Exit Leg 1 Delta",
    content: (
      <div>
        <p>
          Exit the trade when the delta of leg 1 is below the min or above the
          max.
        </p>
        <p>
          For example, it would exit when the delta of the first leg is below{" "}
          <Code>.10</Code> or above <Code>.90</Code> delta.
        </p>
      </div>
    )
  },
  exitLeg1OTMPct: {
    title: "Exit Leg 1 OTM Ratio",
    content: (
      <div>
        <p>
          Exit the trade when the strike as a percent of stock price of leg 1 is
          below the min or above the max.
        </p>
        <p>
          For example, it would exit when the strike percentage of stock price
          is below <Code>1.05</Code> or above <Code>1.20</Code>.
        </p>
      </div>
    )
  },
  exitProfitLossPct: {
    title: "Exit Profit / Stop Loss Percent",
    content: (
      <div>
        <p>Take profits and add stop loss to exit trade at these intervals.</p>
        <p>
          For example, set a stop loss of <Code>50</Code> (<Code>-50%</Code>){" "}
          and a profit target at <Code>200</Code> (<Code>200%</Code>) on a long
          call. Set only a stop loss by leaving profit blank.
        </p>
      </div>
    )
  },
  exitSpreadDelta: {
    title: "Exit Spread Delta",
    content: (
      <div>
        <p>
          Exit and roll the trade if the spread total delta exceed the min or
          max value. For Example;
        </p>
        <p>
          <Code>spread delta</Code> = <Code>leg1ratio</Code> *{" "}
          <Code>leg1delta</Code> + <Code>leg2ratio</Code> *{" "}
          <Code>leg2delta</Code>
        </p>
      </div>
    )
  },
  exitSpreadPx: {
    title: "Exit Spread Price",
    content: (
      <div>
        <p>
          Exit the trade when the trade price falls below the min or rises above
          the max.
        </p>
        <p>
          For example, it would exit if below <Code>0.4</Code> min or above{" "}
          <Code>$0.90</Code> max price.
        </p>
      </div>
    )
  },
  exitStrikeDiffPctValue: {
    title: "Exit Strike Diff Ration",
    content: (
      <div>
        <p>
          Exit the trade when the trade price divided by the difference in
          strike prices falls below the min or rises above the max.
        </p>
        <p>
          For example, a $5 wide call spread would exit if the price of{" "}
          <Code>$1</Code> divided by <Code>5</Code> is below the min of{" "}
          <Code>.20</Code> or the price of <Code>4.5</Code> that is above the
          max of <Code>.90</Code>.
        </p>
      </div>
    )
  },
  hedgeDays: {
    title: "Hedge Days",
    content: (
      <div>
        <p>Delta hedge at the end of this many days.</p>
        <p>
          For example, <Code>1</Code> would delta hedge at the end of each day.
        </p>
      </div>
    )
  },
  entryDays: {
    title: "Entry Days",
    content: (
      <div>
        <p>Stagger trades every this many Entry Days. </p>
        <p>
          For example, there would be a new trade every <Code>7</Code> days from
          the last new trade.
        </p>
      </div>
    )
  },
  standardExpiration: {
    title: "Standard Expirations",
    content: (
      <div>
        <p>Use all expirations, only standard or only non standard.</p>
        <p>
          For example, only using non standard would only use weekly, monthly
          and quarterly expirations.{" "}
        </p>
      </div>
    )
  },
  contractSize: {
    title: "Contract Size",
    content: (
      <div>
        <p>Multiply the profit in the trades report by the Contract Size. </p>
        <p>
          For example, the profit is multiplied by <Code>10</Code> contract
          size.
        </p>
      </div>
    )
  },
  leg1Leg2DeltaTotal: {
    title: "Leg1 - Leg2 Delta Total",
    content: (
      <div>
        <p>Leg1 delta minus leg2 delta min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg1-leg2</Code>
          delta is above the min and below the max.
        </p>
      </div>
    )
  },
  leg2Leg3DeltaTotal: {
    title: "Leg2 - Leg3 Delta Total",
    content: (
      <div>
        <p>Leg2 delta minus leg3 delta min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg2-leg3</Code>
          delta is above the min and below the max.
        </p>
      </div>
    )
  },
  leg3Leg4DeltaTotal: {
    title: "Leg3 - Leg4 Delta Total",
    content: (
      <div>
        <p>Leg3 delta minus leg4 delta min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg3-leg4</Code>
          delta is above the min and below the max.
        </p>
      </div>
    )
  },
  leg1Leg2DteDiff: {
    title: "Leg1 - Leg2 DTE Diff",
    content: (
      <div>
        <p>Leg1 days to expiration minus leg2 days to expiration min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg1-leg2</Code>{" "}
          days to expiration is above the min and below the max.
        </p>
      </div>
    )
  },
  leg2Leg3DteDiff: {
    title: "Leg2 - Leg3 DTE Diff",
    content: (
      <div>
        <p>Leg2 days to expiration minus leg3 days to expiration min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg2-leg3</Code>{" "}
          days to expiration is above the min and below the max.
        </p>
      </div>
    )
  },
  leg3Leg4DteDiff: {
    title: "Leg3 - Leg4 DTE Diff",
    content: (
      <div>
        <p>Leg3 days to expiration minus leg4 days to expiration min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg3-leg4</Code>{" "}
          days to expiration is above the min and below the max.
        </p>
      </div>
    )
  },
  leg1Leg2StrikeWidth: {
    title: "Leg1 - Leg2 Strike Width",
    content: (
      <div>
        <p>Leg1 strike width minus leg2 strike width min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg1-leg2</Code>
          strike width is above the min and below the max.
        </p>
      </div>
    )
  },
  leg2Leg3StrikeWidth: {
    title: "Leg2 - Leg3 Strike Width",
    content: (
      <div>
        <p>Leg2 strike width minus leg3 strike width min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg2-leg3</Code>{" "}
          strike width is above the min and below the max.
        </p>
      </div>
    )
  },
  leg3Leg4StrikeWidth: {
    title: "Leg3 - Leg4 Strike Width",
    content: (
      <div>
        <p>Leg3 strike width minus leg4 strike width min max.</p>
        <p>
          For example, the trade would only enter if the <Code>leg3-leg4</Code>{" "}
          strike width is above the min and below the max.
        </p>
      </div>
    )
  },
  mktWidthPc: {
    title: "Market Width Ratio",
    content: (
      <div>
        <p>
          Market Width Ratio ( (<Code>optAsk</Code> - <Code>optBid</Code>) /{" "}
          <Code>strike</Code>).
        </p>
        <p>
          For example it would search options that have mktwidthpct between and
          including <Code>.004</Code> to <Code>.01</Code>.
        </p>
      </div>
    )
  },
  symEntryExit: {
    title: "Symbol Entry & Exit",
    content: (
      <div>
        <p>
          Paste in multiple rows of <Code>symbol</Code>, <Code>entryDate</Code>,
          <Code>exitDate</Code> and the backtest will enter the first available
          day and if exit on signal is selected, will exit on the exit date,
          otherwise will exit at expiration.
        </p>
        <p>
          Paste "<Code>symbol</Code> <Code>entryDate</Code>{" "}
          <Code>exitDate</Code>" like "<Code>IBM</Code> <Code>1/3/07</Code>{" "}
          <Code>1/31/07</Code>" from a Spreadsheet. Valid date formats are{" "}
          <Code>YYYY-MM-DD</Code> or <Code>M/D/YYYY</Code>.
        </p>
      </div>
    )
  },
  iVRank: {
    title: "IV Rank %",
    content: (
      <div>
        <p>
          The one year rank of the 30 day interpolated implied volatilty
          ex-earnings effect.
        </p>
        <p>
          <Code>IV Rank</Code> = ((<Code>the current IV level</Code> -{" "}
          <Code>1 yr IV low</Code>) / (<Code>1 yr IV high</Code> -{" "}
          <Code>1 yr IV low</Code>) ) * <Code>100</Code>
        </p>
      </div>
    )
  },
  entryDaysToEarn: {
    title: "Entry Days to Earnings",
    content: (
      <div>
        <p>
          The number of days before earnings to enter the trade. Consider a
          range of at least 4 days to capture weekends and holidays.
        </p>
        <p>
          For example, if you wanted to avoid earnings you might set only the
          min of <Code>20</Code> days. If you are testing an earnings strategy
          you might enter between <Code>5</Code> and <Code>9</Code> days before.
        </p>
      </div>
    )
  },
  exitBizDaysBeforeEarn: {
    title: "Exit business days pre earnings",
    content: (
      <div>
        <p>The number of days before earnings to exit the trade.</p>
        <p>
          For example, you would set this value to <Code>1</Code> to exit your
          position before earnings announcement date if you had a buywrite
          strategy that avoided earnings.
        </p>
      </div>
    )
  },
  exitBizDaysAfterEarn: {
    title: "Exit business days post earnings",
    content: (
      <div>
        <p>The number of days after earnings to exit the trade.</p>
        <p>
          For example, you might exit <Code>1</Code> day after earnings to test
          a straddle buying strategy over earnings dates.
        </p>
      </div>
    )
  },
  iBidVol: {
    title: "Implied Bid Volatility",
    content: (
      <div>
        <p>
          Implied Bid Volatility found by using the bid price of the option.
        </p>
        <p>
          For example, it would search options with implied bid volatilty
          between and including <Code>15</Code> to <Code>80</Code>.
        </p>
      </div>
    )
  },
  iAskVol: {
    title: "Implied Ask Volatility",
    content: (
      <div>
        <p>
          Implied Ask Volatility found by using the bid price of the option.
        </p>
        <p>
          For example, it would search options with implied ask volatility
          between and including <Code>20</Code> to <Code>85</Code>.
        </p>
      </div>
    )
  },
  symbol: {
    title: "Symbol",
    content: (
      <div>
        <p>
          Enter a symbol of a stock, ETF or Index that has US equity options. If
          you try a symbol that has options and is not found here, please
          contact support@orats.com.
        </p>
        <p>
          You can enable Weightings by entering a comma-separated list of
          symbols; <Code>AAPL, MSFT</Code>.{" "}
        </p>
        <p>
          Paste two-column data from excel to input Symbols and Weightings
          together:
        </p>
        <List>
          <List.Item>
            <Code>AAPL 0.5</Code>
          </List.Item>
          <List.Item>
            <Code>MSFT 0.5</Code>
          </List.Item>
        </List>
      </div>
    )
  },
  strategy: {
    title: "Strategy",
    content: (
      <div>
        <p>The name of the options strategy.</p>
      </div>
    )
  },
  dateRange: {
    title: "Date Range",
    content: (
      <div>
        <p>The beginning and ending of the test.</p>
      </div>
    )
  },
  weightings: {
    title: "Portfolio Weightings",
    content: (
      <div>
        <p>Enter your symbols and ratio of total portfolio.</p>
        <p>
          For example, if you own AAPL and MSFT in equal dollar amounts you
          would paste in the following:
        </p>
        <List>
          <List.Item>
            <Code>AAPL,.5</Code>
          </List.Item>
          <List.Item>
            <Code>MSFT,.5</Code>
          </List.Item>
        </List>
      </div>
    )
  },
  AnnReturn: {
    content: (
      <p>
        Average annual return for the entire backtest found by the averaging all
        monthly strategy returns divided by total years.
      </p>
    )
  },
  AnnSharpe: {
    content: (
      <p>
        Annual Sharpe ratio is the annual return of the strategy minus a risk
      </p>
    )
  },
  "MaxDrawDown%": {
    content: (
      <p>
        The drawdown is peak to trough of the equity line divided by the peak
        value
      </p>
    )
  },
  DrawDownDays: {
    content: <p>The number of days from peak value to trough</p>
  },
  "P&L$PerTradeAvg": {
    content: (
      <p>The profit average dollar amount per trade times contract size</p>
    )
  },
  "P&L$PerDayAvg": {
    content: (
      <p>
        The profit average dollar amount per day of trading times contract size
      </p>
    )
  },
  "P&L%PerTradeAvg": {
    content: (
      <p>
        The profit percentage is found by the average dollar amount per trade
        divided by the price of the options entry for each one lot
      </p>
    )
  },
  "P&L%PerDayAvg": {
    content: (
      <p>
        The profit percentage is found by the total P&L % of all trades divided
        by total days traded
      </p>
    )
  },
  StratWinRate: {
    content: (
      <p>
        The percentage of trades that are profitable to the total number of
        trades
      </p>
    )
  },
  DaysInTradeAvg: {
    content: (
      <p>
        The total number of days in a trade for all trades divided by the total
        number of trades
      </p>
    )
  },
  TotStratTrades: {
    content: <p>The total number of trades in the backtest</p>
  },
  "TotStratP&L$": {
    content: <p>The total profit from all trades times contract size</p>
  },
  "TotStratP&L%": {
    content: (
      <p>
        The total percentage profit for the backtest found by total profit
        (TotStratP&L$) from all trades divided by total debit paid (if long
        trade) or total credit received (short trade)
      </p>
    )
  },
  "%OfTimeInMarket": {
    content: (
      <p>
        The percentage of business days where the strategy has a trade in the
        market and the total business days in the backtest as defined by the
        Date Range
      </p>
    )
  },
  adjustLegDelta: {
    content: (
      <p>
        Adjust this leg's option if the delta of this leg moves below the min or
        above the max. The option will adjust to the "Adjust Leg To Delta"
        within the same expiration
      </p>
    )
  },
  adjustmentTrigger: {
    content: (
      <p>
        Adjust this leg's option if the delta of this leg moves below the min or
        above the max. The option will adjust to the "Adjust Leg To Delta"
        within the same expiration
      </p>
    )
  },
  adjustMinMaxDays: {
    content: (
      <p>
        Only adjust this option if the days to expiration is between the adjust
        min max days
      </p>
    )
  },
  adjustLegToDelta: {
    content: (
      <p>
        The option will exit and a new option will open closest to the Ideal
        Delta and above the Min Delta and below the Max Delta
      </p>
    )
  },
  adjustmentStrikeSelection: {
    content: (
      <p>
        The option will exit and a new option will open closest to the Ideal
        Delta and above the Min Delta and below the Max Delta
      </p>
    )
  },
  adjustTiedLegNumber: {
    content: (
      <p>
        The option will not adjust until the Adjust Tied Leg Delta of the Adjust
        Tied Leg Number and the Adjust Leg Delta parameters are met
      </p>
    )
  },
  adjustTiedLegDelta: {
    content: (
      <p>
        The option will not adjust until both the Adjust Leg Delta and the
        Adjust Tied Leg Delta parameters are met
      </p>
    )
  },
  adjustWithLegNumber: {
    content: (
      <p>This option will adjust when the Adjust With Leg Number adjusts</p>
    )
  }
}
