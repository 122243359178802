import React, { Component } from "react"
import styled, { keyframes, css } from "styled-components"
import { Form, Table } from "semantic-ui-react"
import { Text } from "rebass"
import _ from "lodash"
import numeral from "numeral"

// export const Modal = styled.div`
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   visibility: ${props => (props.open ? "visible" : "hidden")};
//   top: 0;
//   left: 0;
//   z-index: 1000;
//   opacity: ${props => (props.open ? 1 : 0)};
//   background: rgba(256, 256, 256, 0.9);
//   -webkit-transition: all 0.2s;
//   -moz-transition: all 0.2s;
//   transition: all 0.2s;
// `

// console.log(fadeIn)

const fadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`

export const ListItem = styled("li")`
  display: inline;
  list-style-type: none;
  padding-right: 16px;
  float: left;
`

export const Slug = styled("div")`
  font-family: "Roboto Condensed";
  color: #9b9b9b;
  font-size: 0.85rem;
  line-height: 1rem;
`

export const UnderlyingInfoItem = styled("div")`
  font-family: "Roboto Condensed";
  padding-bottom: 0px;
  font-size: 0.95rem;
  line-height: 1rem;
`

export const PlanHeader = styled("div")`
  margin-top: 0.5em;
  font-size: 2em;
  font-weight: 100;
`

export const Price = styled("span")`
  color: ${props => (props.changePct < 0 ? "INDIANRED" : "#52BE80")};
`

export const FormGroup = styled(Form.Group)(props => {
  const [, animationDuration] = props.className.split(":")
  return {
    animation: `${JSON.stringify(fadeIn)} ${+animationDuration ? "0.5s" : "0"}`
  }
})

export const Leg = styled("span")`
  background-color: transparent;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-collapse: separate;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-style: solid;
  border-width: thin;
  border-color: rgba(0, 0, 0, 0.1392157);
  box-sizing: border-box;
  color: ${props => (props.ratio > 0 ? "#21ba45" : "#db2828")};
  display: inline-block;
  font-size: 10px;
  height: 18.5px;
  line-height: 17.25px;
  text-align: left;
  white-space: pre;
  margin-left: 8px;
  padding-left: 3px;
  padding-right: 3px;
`

export const GroupName = styled("div")`
  display: inline-block;
  cursor: default;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.05px;
  margin-bottom: 6px;
  color: ${props =>
    props.disabled ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.9)"};
`

export const AccordianMenuLink = styled("a")`
  font-weight: ${props => (props.isActive ? 700 : 400)};
  color: ${props => (props.isActive ? "black" : "grey")};
`

export const FormGroupPosition = styled("div")`
  margin: 0 0 20px 0;
  &:last-child {
    margin: 0;
  }
`

// export const OuterParam = styled.div`
//   -webkit-font-smoothing: antialiased;
//   background-color: rgb(42, 126, 210);
//   border-radius: 2px;
//   box-sizing: border-box;
//   color: rgb(255, 255, 255);
//   display: inline-flex;
//   font-family: "Open Sans", sans-serif;
//   font-weight: normal;
//   margin-bottom: 10px;
//   margin-right: 10px;
//   opacity: 0.5;
//   padding: 6px 6px 6px 9px;
//   height: 37px;
//   line-height: 24px;
// `
// export const Params = styled.div`
//   -webkit-font-smoothing: antialiased;
//   background-color: rgba(255, 255, 255, 0.2);
//   border-radius: 2px;
//   box-sizing: border-box;
//   color: rgb(255, 255, 255);
//   display: block;
//   font-family: Consolas, monaco, monospace;
//   font-size: 12px;
//   font-weight: normal;
//   margin-left: 6px;
//   padding: 4px 5px 3px 5px;
//   height: 25px;
//   line-height: 18px;
// `
//
// export const ParamName = styled.div`
//   -webkit-font-smoothing: antialiased;
//   box-sizing: border-box;
//   color: rgb(255, 255, 255);
//   display: block;
//   font-family: "Open Sans", sans-serif;
//   font-size: 16px;
//   font-weight: 600;
//   height: 25px;
//   line-height: 24px;
// `

// small
// rgb(97, 106, 107);
// ${props => (props.ratio >= 1 ? "green" : "red")};
export const OuterParam = styled("div")`
  -webkit-font-smoothing: antialiased;
  background-color: rgb(127, 140, 141);
  border-radius: 2px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: inline-flex;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  margin-bottom: 6px;
  margin-right: 6px;
  opacity: 0.8;
  padding: 4px 4px 4px 5px;
  height: 22px;
  line-height: 14px;
`

export const Params = styled("div")`
  -webkit-font-smoothing: antialiased;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  display: block;
  font-family: Consolas, monaco, monospace;
  font-size: 10px;
  font-weight: normal;
  margin-left: 4px;
  padding: 2px 3px 2px 3px;
  height: 15px;
  line-height: 11px;
`

export const ParamName = styled("div")`
  -webkit-font-smoothing: antialiased;
  display: block;
  font-size: 12px;
  font-weight: 600;
  height: 15px;
  line-height: 14px;
`

export const Code = styled("code")`
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
  -webkit-box-direction: normal;
  background-color: rgba(0, 0, 0, 0.0392157);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-collapse: separate;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-sizing: border-box;
  color: ${props => props.textColor || "rgba(0, 0, 0, 0.870588)"};
  /* color: rgba(0, 0, 0, 0.870588)}; */
  display: inline-block;
  font-family: monospace, monospace;
  font-size: 12.25px;
  height: 19px;
  line-height: 19.999000549316406px;
  text-align: left;
  white-space: pre;
  padding-left: 3px;
  padding-right: 3px;
`

const fadeFlash = keyframes`
  0% {
    background-color: white;
  }
  30% {
    background-color: #D0ECE7;
  }
  100% {
    background-color: white;
  }
`

const fadeFlashDown = keyframes`
  0% {
    background-color: white;
  }
  30% {
    background-color: #F5B7B1;
  }
  100% {
    background-color: white;
  }
`

const fadeFlashUp = keyframes`
  0% {
    background-color: white;
  }
  30% {
    background-color: #7DCEA0;
  }
  100% {
    background-color: white;
  }
`

const flashFadeMixin = css`
  animation: 8s ${fadeFlash} ease-out;
`
export const FlashTableRow = styled(({ shouldFlash, ...rest }) => (
  <Table.Row {...rest} />
))`
  ${props => (props.shouldFlash ? flashFadeMixin : "background-color: white;")};
`

const flashFadeUpMixin = css`
  animation: 1.5s ${fadeFlashUp} ease-out;
`

const flashFadeDownMixin = css`
  animation: 1.5s ${fadeFlashDown} ease-out;
`

export const TC = styled(({ flash, isTickUp, ...rest }) => (
  <Table.Cell {...rest} />
))`
  min-width: 44px;
  color: ${props =>
    props.isTickUp === null ? "black" : props.isTickUp ? "#21ba45" : "red"};
  ${props =>
    props.flash === null
      ? "animation: none;"
      : props.isTickUp
      ? flashFadeUpMixin
      : flashFadeDownMixin};
`

export class TickCell extends Component {
  state = {
    isTickUp: null,
    flash: null
  }
  componentWillUpdate(nextProps) {
    if (
      this.props.tickValue !== null &&
      nextProps.tickValue !== this.props.tickValue
    ) {
      this.setState({
        flash: true,
        isTickUp: nextProps.tickValue > this.props.tickValue
      })
      _.delay(() => {
        this.setState({ flash: null })
      }, 1500)
    }
  }
  render() {
    const { isTickUp, flash } = this.state
    const { tickValue, format, ...rest } = this.props
    return (
      <TC flash={flash} isTickUp={isTickUp} {...rest}>
        {tickValue && <Text children={numeral(tickValue).format(format)} />}
      </TC>
    )
  }
}
