module.exports = [
  { ticker: "A", name: "AGILENT TECHNOLOGIES INC" },
  { ticker: "AA", name: "ALCOA CORP" },
  { ticker: "AAAU", name: "PERTH MINT PHYSICAL GOLD ETF" },
  { ticker: "AACG", name: "ATA CREATIVITY GLOBAL - ADR" },
  { ticker: "AADR", name: "ADVISORSHARES DORSEY WRIGHT" },
  { ticker: "AAL", name: "AMERICAN AIRLINES GROUP INC" },
  { ticker: "AAMC", name: "ALTISOURCE ASSET MANAGEMENT" },
  { ticker: "AAME", name: "ATLANTIC AMERICAN CORP" },
  { ticker: "AAN", name: "AARON'S INC" },
  { ticker: "AAOI", name: "APPLIED OPTOELECTRONICS INC" },
  { ticker: "AAON", name: "AAON INC" },
  { ticker: "AAP", name: "ADVANCE AUTO PARTS INC" },
  { ticker: "AAPL", name: "APPLE INC" },
  { ticker: "AAT", name: "AMERICAN ASSETS TRUST INC" },
  { ticker: "AAU", name: "ALMADEN MINERALS LTD - B" },
  { ticker: "AAWW", name: "ATLAS AIR WORLDWIDE HOLDINGS" },
  { ticker: "AAXJ", name: "ISHARES MSCI ALL COUNTRY ASI" },
  { ticker: "AAXN", name: "AXON ENTERPRISE INC" },
  { ticker: "AB", name: "ALLIANCEBERNSTEIN HOLDING LP" },
  { ticker: "ABB", name: "ABB LTD-SPON ADR" },
  { ticker: "ABBV", name: "ABBVIE INC" },
  { ticker: "ABC", name: "AMERISOURCEBERGEN CORP" },
  { ticker: "ABCB", name: "AMERIS BANCORP" },
  { ticker: "ABEO", name: "ABEONA THERAPEUTICS INC" },
  { ticker: "ABEQ", name: "ABSOLUTE CORE STRATEGY ETF" },
  { ticker: "ABEV", name: "AMBEV SA-ADR" },
  { ticker: "ABG", name: "ASBURY AUTOMOTIVE GROUP" },
  { ticker: "ABIO", name: "ARCA BIOPHARMA INC" },
  { ticker: "ABM", name: "ABM INDUSTRIES INC" },
  { ticker: "ABMD", name: "ABIOMED INC" },
  { ticker: "ABR", name: "ARBOR REALTY TRUST INC" },
  { ticker: "ABT", name: "ABBOTT LABORATORIES" },
  { ticker: "ABTX", name: "ALLEGIANCE BANCSHARES INC" },
  { ticker: "ABUS", name: "ARBUTUS BIOPHARMA CORP" },
  { ticker: "AC", name: "ASSOCIATED CAPITAL GROUP - A" },
  { ticker: "ACA", name: "ARCOSA INC" },
  { ticker: "ACAD", name: "ACADIA PHARMACEUTICALS INC" },
  { ticker: "ACAM", name: "ACAMAR PARTNERS ACQUISITI- A" },
  { ticker: "ACAMU", name: "ACAMAR PARTNERS ACQUISITION" },
  { ticker: "ACB", name: "AURORA CANNABIS INC" },
  { ticker: "ACBI", name: "ATLANTIC CAPITAL BANCSHARES" },
  { ticker: "ACC", name: "AMERICAN CAMPUS COMMUNITIES" },
  { ticker: "ACCO", name: "ACCO BRANDS CORP" },
  { ticker: "ACEL", name: "ACCEL ENTERTAINMENT INC" },
  { ticker: "ACER", name: "ACER THERAPEUTICS INC" },
  { ticker: "ACES", name: "ALPS CLEAN ENERGY ETF" },
  { ticker: "ACGL", name: "ARCH CAPITAL GROUP LTD" },
  { ticker: "ACH", name: "ALUMINUM CORP OF CHINA-ADR" },
  { ticker: "ACHC", name: "ACADIA HEALTHCARE CO INC" },
  { ticker: "ACHV", name: "ACHIEVE LIFE SCIENCES INC" },
  { ticker: "ACIA", name: "ACACIA COMMUNICATIONS INC" },
  { ticker: "ACIO", name: "APTUS COLLARED INC OPP ETF" },
  { ticker: "ACIU", name: "AC IMMUNE SA" },
  { ticker: "ACIW", name: "ACI WORLDWIDE INC" },
  { ticker: "ACLS", name: "AXCELIS TECHNOLOGIES INC" },
  { ticker: "ACM", name: "AECOM" },
  { ticker: "ACMR", name: "ACM RESEARCH INC-CLASS A" },
  { ticker: "ACN", name: "ACCENTURE PLC-CL A" },
  { ticker: "ACNB", name: "ACNB CORP" },
  { ticker: "ACOR", name: "ACORDA THERAPEUTICS INC" },
  { ticker: "ACP", name: "ABERDEEN INCOME CREDIT STRAT" },
  { ticker: "ACRE", name: "ARES COMMERCIAL REAL ESTATE" },
  { ticker: "ACRS", name: "ACLARIS THERAPEUTICS INC" },
  { ticker: "ACRX", name: "ACELRX PHARMACEUTICALS INC" },
  { ticker: "ACSG", name: "XTRACKERS MSCI ACWI EX USA E" },
  { ticker: "ACSI", name: "AMERICAN CUSTOMER SATISFACTI" },
  { ticker: "ACST", name: "ACASTI PHARMA INC" },
  { ticker: "ACT", name: "ADVISORSHARES VICE ETF" },
  { ticker: "ACTG", name: "ACACIA RESEARCH CORP" },
  { ticker: "ACTT", name: "ACT II GLOBAL ACQUISITIO-A" },
  { ticker: "ACTTU", name: "ACT II GLOBAL ACQUISITION CO" },
  { ticker: "ACU", name: "ACME UNITED CORP" },
  { ticker: "ACV", name: "ALLIANZGI DIVERSIFIED INCOME" },
  { ticker: "ACWF", name: "ISHARES EDGE MSCI MULTIFACTO" },
  { ticker: "ACWI", name: "ISHARES MSCI ACWI ETF" },
  { ticker: "ACWV", name: "ISHARES EDGE MSCI MIN VOL GL" },
  { ticker: "ACWX", name: "ISHARES MSCI ACWI EX US ETF" },
  { ticker: "ACY", name: "AEROCENTURY CORP" },
  { ticker: "ADAP", name: "ADAPTIMMUNE THERAPEUTICS-ADR" },
  { ticker: "ADBE", name: "ADOBE INC" },
  { ticker: "ADC", name: "AGREE REALTY CORP" },
  { ticker: "ADES", name: "ADVANCED EMISSIONS SOLUTIONS" },
  { ticker: "ADI", name: "ANALOG DEVICES INC" },
  { ticker: "ADIL", name: "ADIAL PHARMACEUTICALS INC" },
  { ticker: "ADM", name: "ARCHER-DANIELS-MIDLAND CO" },
  { ticker: "ADMA", name: "ADMA BIOLOGICS INC" },
  { ticker: "ADME", name: "APTUS DRAWDOWN MANAGED EQUIT" },
  { ticker: "ADMP", name: "ADAMIS PHARMACEUTICALS CORP" },
  { ticker: "ADMS", name: "ADAMAS PHARMACEUTICALS INC" },
  { ticker: "ADNT", name: "ADIENT PLC" },
  { ticker: "ADP", name: "AUTOMATIC DATA PROCESSING" },
  { ticker: "ADPT", name: "ADAPTIVE BIOTECHNOLOGIES" },
  { ticker: "ADRE", name: "INVESCO BLDRS EMERGING MARKE" },
  { ticker: "ADRO", name: "ADURO BIOTECH INC" },
  { ticker: "ADS", name: "ALLIANCE DATA SYSTEMS CORP" },
  { ticker: "ADSK", name: "AUTODESK INC" },
  { ticker: "ADSW", name: "ADVANCED DISPOSAL SERVICES I" },
  { ticker: "ADT", name: "ADT INC" },
  { ticker: "ADTN", name: "ADTRAN INC" },
  { ticker: "ADUS", name: "ADDUS HOMECARE CORP" },
  { ticker: "ADVM", name: "ADVERUM BIOTECHNOLOGIES INC" },
  { ticker: "ADX", name: "ADAMS DIVERSIFIED EQUITY" },
  { ticker: "ADXN", name: "ADDEX THERAPEUTICS LTD" },
  { ticker: "ADXS", name: "ADVAXIS INC" },
  { ticker: "AE", name: "ADAMS RESOURCES & ENERGY INC" },
  { ticker: "AEE", name: "AMEREN CORPORATION" },
  { ticker: "AEF", name: "ABERDEEN EM MRKT EQTY INC" },
  { ticker: "AEG", name: "AEGON N.V.-NY REG SHR" },
  { ticker: "AEGN", name: "AEGION CORP" },
  { ticker: "AEHR", name: "AEHR TEST SYSTEMS" },
  { ticker: "AEIS", name: "ADVANCED ENERGY INDUSTRIES" },
  { ticker: "AEL", name: "AMERICAN EQUITY INVT LIFE HL" },
  { ticker: "AEM", name: "AGNICO EAGLE MINES LTD" },
  { ticker: "AEMD", name: "AETHLON MEDICAL INC" },
  { ticker: "AEO", name: "AMERICAN EAGLE OUTFITTERS" },
  { ticker: "AEP", name: "AMERICAN ELECTRIC POWER" },
  { ticker: "AER", name: "AERCAP HOLDINGS NV" },
  { ticker: "AERI", name: "AERIE PHARMACEUTICALS INC" },
  { ticker: "AES", name: "AES CORP" },
  { ticker: "AESE", name: "ALLIED ESPORTS ENTERTAINMENT" },
  { ticker: "AESR", name: "ANFIELD US EQUITY SECTOR ROT" },
  { ticker: "AEY", name: "ADDVANTAGE TECHNOLOGIES GRP" },
  { ticker: "AEYE", name: "AUDIOEYE INC" },
  { ticker: "AEZS", name: "AETERNA ZENTARIS INC" },
  { ticker: "AFB", name: "ALLIANCE NATIONAL MUNI INC" },
  { ticker: "AFG", name: "AMERICAN FINANCIAL GROUP INC" },
  { ticker: "AFH", name: "ATLAS FINANCIAL HOLDINGS INC" },
  { ticker: "AFI", name: "ARMSTRONG FLOORING INC" },
  { ticker: "AFIF", name: "ANFIELD UNIVERSAL FIXED INCO" },
  { ticker: "AFIN", name: "AMERICAN FINANCE TRUST INC" },
  { ticker: "AFK", name: "VANECK AFRICA INDEX" },
  { ticker: "AFL", name: "AFLAC INC" },
  { ticker: "AFLG", name: "FIRST TRUST ACTIVE FACTOR LA" },
  { ticker: "AFMC", name: "FIRST TRUST ACTIVE FACTOR MI" },
  { ticker: "AFMD", name: "AFFIMED NV" },
  { ticker: "AFSM", name: "FIRST TRUST ACTIVE FACTOR SM" },
  { ticker: "AFT", name: "APOLLO SENIOR FLOATING RATE" },
  { ticker: "AFTY", name: "PACER CSOP FTSE CHINA A50 ET" },
  { ticker: "AFYA", name: "AFYA LTD-CLASS A" },
  { ticker: "AG", name: "FIRST MAJESTIC SILVER CORP" },
  { ticker: "AGBA", name: "AGBA ACQUISITION LTD" },
  { ticker: "AGBAR", name: "AGBA ACQUISITION LTD-RIGHTS" },
  { ticker: "AGBAU", name: "AGBA ACQUISITION LTD" },
  { ticker: "AGCO", name: "AGCO CORP" },
  { ticker: "AGD", name: "ABERDEEN GLBL DYN DIVID" },
  { ticker: "AGE", name: "AGEX THERAPEUTICS INC" },
  { ticker: "AGEN", name: "AGENUS INC" },
  { ticker: "AGFS", name: "AGROFRESH SOLUTIONS INC" },
  { ticker: "AGG", name: "ISHARES CORE U.S. AGGREGATE" },
  { ticker: "AGGP", name: "IQ ENHANCED CORE PLUS BOND U" },
  { ticker: "AGGY", name: "WISDOMTREE YIELD ENHANCED US" },
  { ticker: "AGI", name: "ALAMOS GOLD INC-CLASS A" },
  { ticker: "AGIO", name: "AGIOS PHARMACEUTICALS INC" },
  { ticker: "AGLE", name: "AEGLEA BIOTHERAPEUTICS INC" },
  { ticker: "AGM", name: "FEDERAL AGRIC MTG CORP-CL C" },
  { ticker: "AGM.A", name: "FEDERAL AGRIC MTG CORP-CL A" },
  { ticker: "AGMH", name: "AGM GROUP HOLDINGS INC" },
  { ticker: "AGN", name: "ALLERGAN PLC" },
  { ticker: "AGNC", name: "AGNC INVESTMENT CORP" },
  { ticker: "AGND", name: "WISDOMTREE TRUST WISDOMTREE" },
  { ticker: "AGO", name: "ASSURED GUARANTY LTD" },
  { ticker: "AGQ", name: "PROSHARES ULTRA SILVER" },
  { ticker: "AGR", name: "AVANGRID INC" },
  { ticker: "AGRO", name: "ADECOAGRO SA" },
  { ticker: "AGRX", name: "AGILE THERAPEUTICS INC" },
  { ticker: "AGS", name: "PLAYAGS INC" },
  { ticker: "AGT", name: "ISHARES ARGENTINA" },
  { ticker: "AGTC", name: "APPLIED GENETIC TECHNOLOGIES" },
  { ticker: "AGX", name: "ARGAN INC" },
  { ticker: "AGYS", name: "AGILYSYS INC" },
  { ticker: "AGZ", name: "ISHARES AGENCY BOND ETF" },
  { ticker: "AGZD", name: "WISDOMTREE TRUST WISDOMTREE" },
  { ticker: "AHC", name: "A H BELO CORP-A" },
  { ticker: "AHCO", name: "ADAPTHEALTH CORP" },
  { ticker: "AHH", name: "ARMADA HOFFLER PROPERTIES IN" },
  { ticker: "AHPI", name: "ALLIED HEALTHCARE PRODUCTS" },
  { ticker: "AHT", name: "ASHFORD HOSPITALITY TRUST" },
  { ticker: "AI", name: "ARLINGTON ASSET INVESTMENT-A" },
  { ticker: "AIA", name: "ISHARES ASIA 50 ETF" },
  { ticker: "AIEQ", name: "AI POWERED EQUITY ETF" },
  { ticker: "AIF", name: "APOLLO TACTICAL INCOME FUND" },
  { ticker: "AIG", name: "AMERICAN INTERNATIONAL GROUP" },
  { ticker: "AIH", name: "AESTHETIC MEDICAL INTERN-ADR" },
  { ticker: "AIHS", name: "SENMIAO TECHNOLOGY LTD" },
  { ticker: "AIIQ", name: "AI POWERED INTERNATIONAL EQU" },
  { ticker: "AIM", name: "AIM IMMUNOTECH INC" },
  { ticker: "AIMC", name: "ALTRA INDUSTRIAL MOTION CORP" },
  { ticker: "AIMT", name: "AIMMUNE THERAPEUTICS INC" },
  { ticker: "AIN", name: "ALBANY INTL CORP-CL A" },
  { ticker: "AINC", name: "ASHFORD INC" },
  { ticker: "AINV", name: "APOLLO INVESTMENT CORP" },
  { ticker: "AIO", name: "ALLIANZGI ARTIFICIAL INTEL" },
  { ticker: "AIQ", name: "Global X Future Analytics TE" },
  { ticker: "AIR", name: "AAR CORP" },
  { ticker: "AIRG", name: "AIRGAIN INC" },
  { ticker: "AIRI", name: "AIR INDUSTRIES GROUP" },
  { ticker: "AIRR", name: "FIRST TRUST RBA AMERICAN IND" },
  { ticker: "AIRT", name: "AIR T INC" },
  { ticker: "AIT", name: "APPLIED INDUSTRIAL TECH INC" },
  { ticker: "AIV", name: "APARTMENT INVT & MGMT CO -A" },
  { ticker: "AIZ", name: "ASSURANT INC" },
  { ticker: "AJG", name: "ARTHUR J GALLAGHER & CO" },
  { ticker: "AJRD", name: "AEROJET ROCKETDYNE HOLDINGS" },
  { ticker: "AJX", name: "GREAT AJAX CORP" },
  { ticker: "AKAM", name: "AKAMAI TECHNOLOGIES INC" },
  { ticker: "AKBA", name: "AKEBIA THERAPEUTICS INC" },
  { ticker: "AKCA", name: "AKCEA THERAPEUTICS INC" },
  { ticker: "AKER", name: "AKERS BIOSCIENCES INC" },
  { ticker: "AKG", name: "ASANKO GOLD INC" },
  { ticker: "AKO.A", name: "EMBOTELLADORA ANDINA-ADR A" },
  { ticker: "AKO.B", name: "EMBOTELLADORA ANDINA-ADR B" },
  { ticker: "AKR", name: "ACADIA REALTY TRUST" },
  { ticker: "AKRO", name: "AKERO THERAPEUTICS INC" },
  { ticker: "AKRX", name: "AKORN INC" },
  { ticker: "AKS", name: "AK STEEL HOLDING CORP" },
  { ticker: "AKTS", name: "AKOUSTIS TECHNOLOGIES INC" },
  { ticker: "AKTX", name: "AKARI THERAPEUTICS PLC-ADR" },
  { ticker: "AL", name: "AIR LEASE CORP" },
  { ticker: "ALAC", name: "ALBERTON ACQUISITION CORP" },
  { ticker: "ALACR", name: "ALBERTON ACQUISITION CO-RTS" },
  { ticker: "ALACU", name: "ALBERTON ACQUISITION CORP" },
  { ticker: "ALB", name: "ALBEMARLE CORP" },
  { ticker: "ALBO", name: "ALBIREO PHARMA INC" },
  { ticker: "ALC", name: "ALCON INC" },
  { ticker: "ALCO", name: "ALICO INC" },
  { ticker: "ALDX", name: "ALDEYRA THERAPEUTICS INC" },
  { ticker: "ALE", name: "ALLETE INC" },
  { ticker: "ALEC", name: "ALECTOR INC" },
  { ticker: "ALEX", name: "ALEXANDER & BALDWIN INC" },
  { ticker: "ALFA", name: "ALPHACLONE ALTERNATIVE ALPHA" },
  { ticker: "ALG", name: "ALAMO GROUP INC" },
  { ticker: "ALGN", name: "ALIGN TECHNOLOGY INC" },
  { ticker: "ALGR", name: "ALLEGRO MERGER CORP" },
  { ticker: "ALGRR", name: "ALLEGRO MERGER CORP-RTS" },
  { ticker: "ALGRU", name: "ALLEGRO MERGER CORP" },
  { ticker: "ALGT", name: "ALLEGIANT TRAVEL CO" },
  { ticker: "ALIM", name: "ALIMERA SCIENCES INC" },
  { ticker: "ALJJ", name: "ALJ REGIONAL HOLDINGS INC" },
  { ticker: "ALK", name: "ALASKA AIR GROUP INC" },
  { ticker: "ALKS", name: "ALKERMES PLC" },
  { ticker: "ALL", name: "ALLSTATE CORP" },
  { ticker: "ALLE", name: "ALLEGION PLC" },
  { ticker: "ALLK", name: "ALLAKOS INC" },
  { ticker: "ALLO", name: "ALLOGENE THERAPEUTICS INC" },
  { ticker: "ALLT", name: "ALLOT LTD" },
  { ticker: "ALLY", name: "ALLY FINANCIAL INC" },
  { ticker: "ALNA", name: "ALLENA PHARMACEUTICALS INC" },
  { ticker: "ALNY", name: "ALNYLAM PHARMACEUTICALS INC" },
  { ticker: "ALO", name: "ALIO GOLD INC" },
  { ticker: "ALOT", name: "ASTRONOVA INC" },
  { ticker: "ALPN", name: "ALPINE IMMUNE SCIENCES INC" },
  { ticker: "ALRM", name: "ALARM.COM HOLDINGS INC" },
  { ticker: "ALRN", name: "AILERON THERAPEUTICS INC" },
  { ticker: "ALRS", name: "ALERUS FINANCIAL CORP" },
  { ticker: "ALSK", name: "ALASKA COMM SYSTEMS GROUP" },
  { ticker: "ALSN", name: "ALLISON TRANSMISSION HOLDING" },
  { ticker: "ALT", name: "ALTIMMUNE INC" },
  { ticker: "ALTG", name: "ALTA EQUIPMENT GROUP INC" },
  { ticker: "ALTM", name: "ALTUS MIDSTREAM CO -A" },
  { ticker: "ALTR", name: "ALTAIR ENGINEERING INC - A" },
  { ticker: "ALTS", name: "PRSHR MRNGSTR ALT SOL ETF" },
  { ticker: "ALTY", name: "GLOBAL X SUPERDIVIDEND ALTER" },
  { ticker: "ALUS", name: "ALUSSA ENERGY ACQUISITION -A" },
  { ticker: "ALUS=", name: "ALUSSA ENERGY ACQUISITION CO" },
  { ticker: "ALV", name: "AUTOLIV INC" },
  { ticker: "ALX", name: "ALEXANDER'S INC" },
  { ticker: "ALXN", name: "ALEXION PHARMACEUTICALS INC" },
  { ticker: "ALYA", name: "ALITHYA GROUP INC-CLASS A" },
  { ticker: "AM", name: "ANTERO MIDSTREAM CORP" },
  { ticker: "AMAG", name: "AMAG PHARMACEUTICALS INC" },
  { ticker: "AMAL", name: "AMALGAMATED BK OF NEW YORK-A" },
  { ticker: "AMAT", name: "APPLIED MATERIALS INC" },
  { ticker: "AMBA", name: "AMBARELLA INC" },
  { ticker: "AMBC", name: "AMBAC FINANCIAL GROUP INC" },
  { ticker: "AMBO", name: "AMBOW EDUCATION HOLDING-ADR" },
  { ticker: "AMC", name: "AMC ENTERTAINMENT HLDS-CL A" },
  { ticker: "AMCA", name: "ISHARES RUSSELL 1000 US REV" },
  { ticker: "AMCI", name: "AMCI ACQUISITION CORP-CL A" },
  { ticker: "AMCIU", name: "AMCI ACQUISITION CORP" },
  { ticker: "AMCR", name: "AMCOR PLC" },
  { ticker: "AMCX", name: "AMC NETWORKS INC-A" },
  { ticker: "AMD", name: "ADVANCED MICRO DEVICES" },
  { ticker: "AME", name: "AMETEK INC" },
  { ticker: "AMED", name: "AMEDISYS INC" },
  { ticker: "AMEH", name: "APOLLO MEDICAL HOLDINGS INC" },
  { ticker: "AMG", name: "AFFILIATED MANAGERS GROUP" },
  { ticker: "AMGN", name: "AMGEN INC" },
  { ticker: "AMH", name: "AMERICAN HOMES 4 RENT- A" },
  { ticker: "AMHC", name: "AMPLITUDE HEALTHCARE ACQ-A" },
  { ticker: "AMHCU", name: "AMPLITUDE HEALTHCARE ACQUISI" },
  { ticker: "AMJ", name: "JPMORGAN ALERIAN MLP INDEX" },
  { ticker: "AMJL", name: "X-LINKS 2XLEVRG ALERIAN MLP" },
  { ticker: "AMK", name: "ASSETMARK FINANCIAL HOLDINGS" },
  { ticker: "AMKR", name: "AMKOR TECHNOLOGY INC" },
  { ticker: "AMLP", name: "ALERIAN MLP ETF" },
  { ticker: "AMN", name: "AMN HEALTHCARE SERVICES INC" },
  { ticker: "AMNB", name: "AMER NATL BNKSHS/DANVILLE VA" },
  { ticker: "AMOM", name: "QRAFT AI-ENH US LRG CAP MTM" },
  { ticker: "AMOT", name: "ALLIED MOTION TECHNOLOGIES" },
  { ticker: "AMOV", name: "AMERICA MOVIL-ADR SERIES A" },
  { ticker: "AMP", name: "AMERIPRISE FINANCIAL INC" },
  { ticker: "AMPE", name: "AMPIO PHARMACEUTICALS INC" },
  { ticker: "AMPH", name: "AMPHASTAR PHARMACEUTICALS IN" },
  { ticker: "AMPY", name: "AMPLIFY ENERGY CORP" },
  { ticker: "AMRB", name: "AMERICAN RIVER BANKSHRS (CA)" },
  { ticker: "AMRC", name: "AMERESCO INC-CL A" },
  { ticker: "AMRH", name: "AMERI HOLDINGS INC" },
  { ticker: "AMRK", name: "A-MARK PRECIOUS METALS INC" },
  { ticker: "AMRN", name: "AMARIN CORP PLC -ADR" },
  { ticker: "AMRS", name: "AMYRIS INC" },
  { ticker: "AMRX", name: "AMNEAL PHARMACEUTICALS INC" },
  { ticker: "AMS", name: "AMERICAN SHARED HOSPITAL SER" },
  { ticker: "AMSC", name: "AMERICAN SUPERCONDUCTOR CORP" },
  { ticker: "AMSF", name: "AMERISAFE INC" },
  { ticker: "AMSWA", name: "AMERICAN SOFTWARE INC-CL A" },
  { ticker: "AMT", name: "AMERICAN TOWER CORP" },
  { ticker: "AMTB", name: "AMERANT BANCORP INC" },
  { ticker: "AMTBB", name: "AMERANT BANCORP INC - B" },
  { ticker: "AMTD", name: "TD AMERITRADE HOLDING CORP" },
  { ticker: "AMTX", name: "AEMETIS INC" },
  { ticker: "AMU", name: "ETRACS ALERIAN MLP ETN" },
  { ticker: "AMUB", name: "ETRACS ALERIAN MLP IND SER B" },
  { ticker: "AMWD", name: "AMERICAN WOODMARK CORP" },
  { ticker: "AMX", name: "AMERICA MOVIL-SPN ADR CL L" },
  { ticker: "AMZA", name: "INFRACAP MLP ETF" },
  { ticker: "AMZN", name: "AMAZON.COM INC" },
  { ticker: "AN", name: "AUTONATION INC" },
  { ticker: "ANAB", name: "ANAPTYSBIO INC" },
  { ticker: "ANAT", name: "AMERICAN NATIONAL INSURANCE" },
  { ticker: "ANCN", name: "ANCHIANO THERAPEUTICS - ADR" },
  { ticker: "ANDA", name: "ANDINA ACQUISITION CORP III" },
  { ticker: "ANDAR", name: "ANDINA ACQUISITION III-RTS" },
  { ticker: "ANDAU", name: "ANDINA ACQUISITION CORP III" },
  { ticker: "ANDE", name: "ANDERSONS INC/THE" },
  { ticker: "ANET", name: "ARISTA NETWORKS INC" },
  { ticker: "ANF", name: "ABERCROMBIE & FITCH CO-CL A" },
  { ticker: "ANGI", name: "ANGI HOMESERVICES INC- A" },
  { ticker: "ANGL", name: "VANECK FALLEN ANGEL HIGH YLD" },
  { ticker: "ANGO", name: "ANGIODYNAMICS INC" },
  { ticker: "ANH", name: "ANWORTH MORTGAGE ASSET CORP" },
  { ticker: "ANIK", name: "ANIKA THERAPEUTICS INC" },
  { ticker: "ANIP", name: "ANI PHARMACEUTICALS INC" },
  { ticker: "ANIX", name: "ANIXA BIOSCIENCES INC" },
  { ticker: "ANPC", name: "ANPAC BIO-MEDICAL SCIENC-ADR" },
  { ticker: "ANSS", name: "ANSYS INC" },
  { ticker: "ANTE", name: "AIRNET TECHNOLOGY INC-ADR" },
  { ticker: "ANTM", name: "ANTHEM INC" },
  { ticker: "ANVS", name: "ANNOVIS BIO" },
  { ticker: "ANY", name: "SPHERE 3D CORP" },
  { ticker: "AOA", name: "ISHARES CORE AGGRESSIVE ALLO" },
  { ticker: "AOBC", name: "AMERICAN OUTDOOR BRANDS CORP" },
  { ticker: "AOD", name: "ABERDEEN TTL DYN DIVID" },
  { ticker: "AOK", name: "ISHARES CORE CONSERVATIVE AL" },
  { ticker: "AOM", name: "ISHARES CORE MODERATE ALLOCA" },
  { ticker: "AON", name: "AON PLC" },
  { ticker: "AOR", name: "ISHARES CORE GROWTH ALLOCATI" },
  { ticker: "AOS", name: "SMITH (A.O.) CORP" },
  { ticker: "AOSL", name: "ALPHA & OMEGA SEMICONDUCTOR" },
  { ticker: "AP", name: "AMPCO-PITTSBURGH CORP" },
  { ticker: "APA", name: "APACHE CORP" },
  { ticker: "APAM", name: "ARTISAN PARTNERS ASSET MA -A" },
  { ticker: "APD", name: "AIR PRODUCTS & CHEMICALS INC" },
  { ticker: "APDN", name: "APPLIED DNA SCIENCES INC" },
  { ticker: "APEI", name: "AMERICAN PUBLIC EDUCATION" },
  { ticker: "APEN", name: "APOLLO ENDOSURGERY INC" },
  { ticker: "APEX", name: "APEX GLOBAL BRANDS INC" },
  { ticker: "APH", name: "AMPHENOL CORP-CL A" },
  { ticker: "APHA", name: "APHRIA INC" },
  { ticker: "APLE", name: "APPLE HOSPITALITY REIT INC" },
  { ticker: "APLS", name: "APELLIS PHARMACEUTICALS INC" },
  { ticker: "APLT", name: "APPLIED THERAPEUTICS INC" },
  { ticker: "APM", name: "APTORUM GROUP LTD-CLASS A" },
  { ticker: "APO", name: "APOLLO GLOBAL MANAGEMENT INC" },
  { ticker: "APOG", name: "APOGEE ENTERPRISES INC" },
  { ticker: "APOP", name: "CELLECT BIOTECHNOLOGY LT-ADR" },
  { ticker: "APPF", name: "APPFOLIO INC - A" },
  { ticker: "APPN", name: "APPIAN CORP" },
  { ticker: "APPS", name: "DIGITAL TURBINE INC" },
  { ticker: "APRE", name: "APREA THERAPEUTICS INC" },
  { ticker: "APRN", name: "BLUE APRON HOLDINGS INC-A" },
  { ticker: "APT", name: "ALPHA PRO TECH LTD" },
  { ticker: "APTO", name: "APTOSE BIOSCIENCES INC" },
  { ticker: "APTS", name: "PREFERRED APARTMENT COMMUN-A" },
  { ticker: "APTV", name: "APTIV PLC" },
  { ticker: "APTX", name: "APTINYX INC" },
  { ticker: "APVO", name: "APTEVO THERAPEUTICS INC" },
  { ticker: "APWC", name: "ASIA PACIFIC WIRE & CABLE" },
  { ticker: "APXT", name: "APEX TECHNOLOGY ACQUI-CL A" },
  { ticker: "APXTU", name: "APEX TECHNOLOGY ACQUISITION" },
  { ticker: "APY", name: "APERGY CORP" },
  { ticker: "APYX", name: "APYX MEDICAL CORP" },
  { ticker: "AQB", name: "AQUABOUNTY TECHNOLOGIES" },
  { ticker: "AQMS", name: "AQUA METALS INC" },
  { ticker: "AQN", name: "ALGONQUIN POWER & UTILITIES" },
  { ticker: "AQST", name: "AQUESTIVE THERAPEUTICS INC" },
  { ticker: "AQUA", name: "EVOQUA WATER TECHNOLOGIES CO" },
  { ticker: "AR", name: "ANTERO RESOURCES CORP" },
  { ticker: "ARA", name: "AMERICAN RENAL ASSOCIATES HO" },
  { ticker: "ARAV", name: "ARAVIVE INC" },
  { ticker: "ARAY", name: "ACCURAY INC" },
  { ticker: "ARC", name: "ARC DOCUMENT SOLUTIONS INC" },
  { ticker: "ARCB", name: "ARCBEST CORP" },
  { ticker: "ARCC", name: "ARES CAPITAL CORP" },
  { ticker: "ARCE", name: "ARCO PLATFORM LTD - CLASS A" },
  { ticker: "ARCH", name: "ARCH COAL INC - A" },
  { ticker: "ARCM", name: "ARROW RESERVE CAP MANAG ETF" },
  { ticker: "ARCO", name: "ARCOS DORADOS HOLDINGS INC-A" },
  { ticker: "ARCT", name: "ARCTURUS THERAPEUTICS HOLDIN" },
  { ticker: "ARD", name: "ARDAGH GROUP SA" },
  { ticker: "ARDC", name: "ARES DYNAMIC CREDIT ALLOCATI" },
  { ticker: "ARDS", name: "ARIDIS PHARMACEUTICALS INC" },
  { ticker: "ARDX", name: "ARDELYX INC" },
  { ticker: "ARE", name: "ALEXANDRIA REAL ESTATE EQUIT" },
  { ticker: "AREC", name: "AMERICAN RESOURCES CORP" },
  { ticker: "ARES", name: "ARES MANAGEMENT CORP - A" },
  { ticker: "ARGO", name: "ARGO GROUP INTERNATIONAL" },
  { ticker: "ARGT", name: "GLOBAL X MSCI ARGENTINA ETF" },
  { ticker: "ARGX", name: "ARGENX SE - ADR" },
  { ticker: "ARI", name: "APOLLO COMMERCIAL REAL ESTAT" },
  { ticker: "ARKF", name: "ARK FINTECH INNOVATION ETF" },
  { ticker: "ARKG", name: "ARK GENOMIC REVOLUTION ETF" },
  { ticker: "ARKK", name: "ARK INNOVATION ETF" },
  { ticker: "ARKQ", name: "ARK AUTONOMOUS TECH & ROBOT" },
  { ticker: "ARKR", name: "ARK RESTAURANTS CORP" },
  { ticker: "ARKW", name: "ARK NEXT GENERATION INTERNET" },
  { ticker: "ARL", name: "AMERICAN REALTY INVESTORS IN" },
  { ticker: "ARLO", name: "ARLO TECHNOLOGIES INC" },
  { ticker: "ARLP", name: "ALLIANCE RESOURCE PARTNERS" },
  { ticker: "ARMK", name: "ARAMARK" },
  { ticker: "ARMP", name: "ARMATA PHARMACEUTICALS INC" },
  { ticker: "ARMR", name: "ARMOR US EQUITY INDEX ETF" },
  { ticker: "ARNA", name: "ARENA PHARMACEUTICALS INC" },
  { ticker: "ARNC", name: "ARCONIC INC" },
  { ticker: "AROC", name: "ARCHROCK INC" },
  { ticker: "AROW", name: "ARROW FINANCIAL CORP" },
  { ticker: "ARPO", name: "AERPIO PHARMACEUTICALS INC" },
  { ticker: "ARQT", name: "ARCUTIS BIOTHERAPEUTICS INC" },
  { ticker: "ARR", name: "ARMOUR RESIDENTIAL REIT INC" },
  { ticker: "ARTL", name: "ARTELO BIOSCIENCES INC" },
  { ticker: "ARTNA", name: "ARTESIAN RESOURCES CORP-CL A" },
  { ticker: "ARTW", name: "ART'S-WAY MANUFACTURING CO" },
  { ticker: "ARVN", name: "ARVINAS INC" },
  { ticker: "ARW", name: "ARROW ELECTRONICS INC" },
  { ticker: "ARWR", name: "ARROWHEAD PHARMACEUTICALS IN" },
  { ticker: "ARYA", name: "ARYA SCIENCES ACQUISITION-A" },
  { ticker: "ARYAU", name: "ARYA SCIENCES ACQUISITION CO" },
  { ticker: "ASA", name: "ASA GOLD AND PRECIOUS METALS" },
  { ticker: "ASB", name: "ASSOCIATED BANC-CORP" },
  { ticker: "ASC", name: "ARDMORE SHIPPING CORP" },
  { ticker: "ASEA", name: "GLOBAL X FTSE SOUTHEAST ASIA" },
  { ticker: "ASET", name: "FLEXSHARES REAL ASSETS FUND" },
  { ticker: "ASFI", name: "ASTA FUNDING INC" },
  { ticker: "ASG", name: "LIBERTY ALL-STAR GROWTH FD" },
  { ticker: "ASGN", name: "ASGN INC" },
  { ticker: "ASH", name: "ASHLAND GLOBAL HOLDINGS INC" },
  { ticker: "ASHR", name: "XTRACKERS HARVEST CSI 300 CH" },
  { ticker: "ASHS", name: "XTRACKERS HARVEST CSI 500 CH" },
  { ticker: "ASHX", name: "XTRACKERS MSCI CHINA A INCLU" },
  { ticker: "ASIX", name: "ADVANSIX INC" },
  { ticker: "ASLN", name: "ASLAN PHARMACEUTICALS LT-ADR" },
  { ticker: "ASM", name: "AVINO SILVER & GOLD MINES" },
  { ticker: "ASMB", name: "ASSEMBLY BIOSCIENCES INC" },
  { ticker: "ASML", name: "ASML HOLDING NV-NY REG SHS" },
  { ticker: "ASNA", name: "ASCENA RETAIL GROUP INC" },
  { ticker: "ASND", name: "ASCENDIS PHARMA A/S - ADR" },
  { ticker: "ASPN", name: "ASPEN AEROGELS INC" },
  { ticker: "ASPS", name: "ALTISOURCE PORTFOLIO SOL" },
  { ticker: "ASPU", name: "ASPEN GROUP INC" },
  { ticker: "ASR", name: "GRUPO AEROPORTUARIO SUR-ADR" },
  { ticker: "ASRT", name: "ASSERTIO THERAPEUTICS INC" },
  { ticker: "ASRV", name: "AMERISERV FINANCIAL INC" },
  { ticker: "ASTC", name: "ASTROTECH CORP" },
  { ticker: "ASTE", name: "ASTEC INDUSTRIES INC" },
  { ticker: "ASUR", name: "ASURE SOFTWARE INC" },
  { ticker: "ASX", name: "ASE TECHNOLOGY HOLDING -ADR" },
  { ticker: "ASYS", name: "AMTECH SYSTEMS INC" },
  { ticker: "AT", name: "ATLANTIC POWER CORP" },
  { ticker: "ATAX", name: "AMERICA FIRST MULTIFAMILY IN" },
  { ticker: "ATCO", name: "ATLAS CORP" },
  { ticker: "ATCX", name: "ATLAS TECHNICAL CONSULTANTS" },
  { ticker: "ATEC", name: "ALPHATEC HOLDINGS INC" },
  { ticker: "ATEN", name: "A10 NETWORKS INC" },
  { ticker: "ATEX", name: "ANTERIX INC" },
  { ticker: "ATGE", name: "ADTALEM GLOBAL EDUCATION INC" },
  { ticker: "ATH", name: "ATHENE HOLDING LTD-CLASS A" },
  { ticker: "ATHE", name: "ALTERITY THERAPEUTICS-ADR" },
  { ticker: "ATHM", name: "AUTOHOME INC-ADR" },
  { ticker: "ATHX", name: "ATHERSYS INC" },
  { ticker: "ATI", name: "ALLEGHENY TECHNOLOGIES INC" },
  { ticker: "ATIF", name: "ATIF HOLDINGS LTD" },
  { ticker: "ATKR", name: "ATKORE INTERNATIONAL GROUP I" },
  { ticker: "ATLC", name: "ATLANTICUS HOLDINGS CORP" },
  { ticker: "ATLO", name: "AMES NATIONAL CORP" },
  { ticker: "ATMP", name: "IPATH SELECT MLP ETN" },
  { ticker: "ATNI", name: "ATN INTERNATIONAL INC" },
  { ticker: "ATNM", name: "ACTINIUM PHARMACEUTICALS INC" },
  { ticker: "ATNX", name: "ATHENEX INC" },
  { ticker: "ATO", name: "ATMOS ENERGY CORP" },
  { ticker: "ATOM", name: "ATOMERA INC" },
  { ticker: "ATOS", name: "ATOSSA THERAPEUTICS INC" },
  { ticker: "ATR", name: "APTARGROUP INC" },
  { ticker: "ATRA", name: "ATARA BIOTHERAPEUTICS INC" },
  { ticker: "ATRC", name: "ATRICURE INC" },
  { ticker: "ATRI", name: "ATRION CORPORATION" },
  { ticker: "ATRO", name: "ASTRONICS CORP" },
  { ticker: "ATRS", name: "ANTARES PHARMA INC" },
  { ticker: "ATSG", name: "AIR TRANSPORT SERVICES GROUP" },
  { ticker: "ATTO", name: "ATENTO SA" },
  { ticker: "ATUS", name: "ALTICE USA INC- A" },
  { ticker: "ATV", name: "ACORN INTERNATIONAL INC-ADR" },
  { ticker: "ATVI", name: "ACTIVISION BLIZZARD INC" },
  { ticker: "ATXI", name: "AVENUE THERAPEUTICS INC" },
  { ticker: "AU", name: "ANGLOGOLD ASHANTI-SPON ADR" },
  { ticker: "AUB", name: "ATLANTIC UNION BANKSHARES CO" },
  { ticker: "AUBN", name: "AUBURN NATL BANCORPORATION" },
  { ticker: "AUDC", name: "AUDIOCODES LTD" },
  { ticker: "AUG", name: "AURYN RESOURCES INC" },
  { ticker: "AUMN", name: "GOLDEN MINERALS CO" },
  { ticker: "AUPH", name: "AURINIA PHARMACEUTICALS INC" },
  { ticker: "AUSF", name: "GLOBAL X ADAPTIVE US FACTOR" },
  { ticker: "AUTL", name: "AUTOLUS THERAPEUTICS PLC" },
  { ticker: "AUTO", name: "AUTOWEB INC" },
  { ticker: "AUY", name: "YAMANA GOLD INC" },
  { ticker: "AVA", name: "AVISTA CORP" },
  { ticker: "AVAL", name: "GRUPO AVAL ACCIONES Y VALORE" },
  { ticker: "AVAV", name: "AEROVIRONMENT INC" },
  { ticker: "AVB", name: "AVALONBAY COMMUNITIES INC" },
  { ticker: "AVCO", name: "AVALON GLOBOCARE CORP" },
  { ticker: "AVD", name: "AMERICAN VANGUARD CORP" },
  { ticker: "AVDE", name: "AVANTIS INTERNATIONAL EQUITY" },
  { ticker: "AVDL", name: "AVADEL PHARMACEUTICALS PLC" },
  { ticker: "AVDV", name: "AVANTIS INTL S/C VALUE ETF" },
  { ticker: "AVEM", name: "AVANTIS EMERGING MARKETS EQ" },
  { ticker: "AVEO", name: "AVEO PHARMACEUTICALS INC" },
  { ticker: "AVGO", name: "BROADCOM INC" },
  { ticker: "AVGR", name: "AVINGER INC" },
  { ticker: "AVH", name: "AVIANCA HOLDINGS SA-SPON ADR" },
  { ticker: "AVID", name: "AVID TECHNOLOGY INC" },
  { ticker: "AVK", name: "ADVENT CONVERT & INCOME" },
  { ticker: "AVLR", name: "AVALARA INC" },
  { ticker: "AVNS", name: "AVANOS MEDICAL INC" },
  { ticker: "AVNW", name: "AVIAT NETWORKS INC" },
  { ticker: "AVRO", name: "AVROBIO INC" },
  { ticker: "AVT", name: "AVNET INC" },
  { ticker: "AVTR", name: "AVANTOR INC" },
  { ticker: "AVUS", name: "AVANTIS U.S. EQUITY ETF" },
  { ticker: "AVUV", name: "AVANTIS US SMALL CAP VALUE" },
  { ticker: "AVX", name: "AVX CORP" },
  { ticker: "AVXL", name: "ANAVEX LIFE SCIENCES CORP" },
  { ticker: "AVY", name: "AVERY DENNISON CORP" },
  { ticker: "AVYA", name: "AVAYA HOLDINGS CORP" },
  { ticker: "AWAY", name: "ETFMG TRAVEL TECH ETF" },
  { ticker: "AWF", name: "ALLIANCEBERNSTEIN GL HI INC" },
  { ticker: "AWI", name: "ARMSTRONG WORLD INDUSTRIES" },
  { ticker: "AWK", name: "AMERICAN WATER WORKS CO INC" },
  { ticker: "AWP", name: "ABERDEEN GLBL PREM PROP" },
  { ticker: "AWR", name: "AMERICAN STATES WATER CO" },
  { ticker: "AWRE", name: "AWARE INC/MASS" },
  { ticker: "AWTM", name: "AWARE ULTRA SHORT DURATION" },
  { ticker: "AWX", name: "AVALON HOLDINGS CORP-A" },
  { ticker: "AX", name: "AXOS FINANCIAL INC" },
  { ticker: "AXAS", name: "ABRAXAS PETROLEUM CORP" },
  { ticker: "AXDX", name: "ACCELERATE DIAGNOSTICS INC" },
  { ticker: "AXE", name: "ANIXTER INTERNATIONAL INC" },
  { ticker: "AXGN", name: "AXOGEN INC" },
  { ticker: "AXGT", name: "AXOVANT GENE THERAPIES LTD" },
  { ticker: "AXJL", name: "WISDOMTREE ASIA-PACIFIC EX-J" },
  { ticker: "AXL", name: "AMERICAN AXLE & MFG HOLDINGS" },
  { ticker: "AXLA", name: "AXCELLA HEALTH INC" },
  { ticker: "AXNX", name: "AXONICS MODULATION TECHNOLOG" },
  { ticker: "AXP", name: "AMERICAN EXPRESS CO" },
  { ticker: "AXR", name: "AMREP CORP" },
  { ticker: "AXS", name: "AXIS CAPITAL HOLDINGS LTD" },
  { ticker: "AXSM", name: "AXSOME THERAPEUTICS INC" },
  { ticker: "AXTA", name: "AXALTA COATING SYSTEMS LTD" },
  { ticker: "AXTI", name: "AXT INC" },
  { ticker: "AXU", name: "ALEXCO RESOURCE CORP" },
  { ticker: "AY", name: "ATLANTICA YIELD PLC" },
  { ticker: "AYI", name: "ACUITY BRANDS INC" },
  { ticker: "AYR", name: "AIRCASTLE LTD" },
  { ticker: "AYTU", name: "AYTU BIOSCIENCE INC" },
  { ticker: "AYX", name: "ALTERYX INC - CLASS A" },
  { ticker: "AZN", name: "ASTRAZENECA PLC-SPONS ADR" },
  { ticker: "AZO", name: "AUTOZONE INC" },
  { ticker: "AZPN", name: "ASPEN TECHNOLOGY INC" },
  { ticker: "AZRE", name: "AZURE POWER GLOBAL LTD" },
  { ticker: "AZRX", name: "AZURRX BIOPHARMA INC" },
  { ticker: "AZUL", name: "AZUL SA-ADR" },
  { ticker: "AZZ", name: "AZZ INC" },
  { ticker: "B", name: "BARNES GROUP INC" },
  { ticker: "BA", name: "BOEING CO/THE" },
  { ticker: "BAB", name: "INVESCO TAXABLE MUNICIPAL BO" },
  { ticker: "BABA", name: "ALIBABA GROUP HOLDING-SP ADR" },
  { ticker: "BAC", name: "BANK OF AMERICA CORP" },
  { ticker: "BAF", name: "BLACKROCK MUNICIPAL INCOME I" },
  { ticker: "BAH", name: "BOOZ ALLEN HAMILTON HOLDINGS" },
  { ticker: "BAK", name: "BRASKEM SA-SPON ADR" },
  { ticker: "BAL", name: "IPATHR SERIES B BLOOMBERG CO" },
  { ticker: "BAM", name: "BROOKFIELD ASSET MANAGE-CL A" },
  { ticker: "BANC", name: "BANC OF CALIFORNIA INC" },
  { ticker: "BAND", name: "BANDWIDTH INC-CLASS A" },
  { ticker: "BANF", name: "BANCFIRST CORP" },
  { ticker: "BANR", name: "BANNER CORPORATION" },
  { ticker: "BANX", name: "STONECASTLE FINANCIAL CORP" },
  { ticker: "BAP", name: "CREDICORP LTD" },
  { ticker: "BAPR", name: "INNOVATOR S&P 500 BUFFER ETF" },
  { ticker: "BAR", name: "GRANITESHARES GOLD TRUST" },
  { ticker: "BASI", name: "BIOANALYTICAL SYSTEMS INC" },
  { ticker: "BATL", name: "BATTALION OIL CORP" },
  { ticker: "BATRA", name: "LIBERTY MEDIA CORP-BRAVES A" },
  { ticker: "BATRK", name: "LIBERTY MEDIA CORP-BRAVES C" },
  { ticker: "BATT", name: "AMPLIFY ADVANCED BATTERY MET" },
  { ticker: "BAUG", name: "INNOVATOR S&P 500 BUFFER AUG" },
  { ticker: "BAX", name: "BAXTER INTERNATIONAL INC" },
  { ticker: "BB", name: "BLACKBERRY LTD" },
  { ticker: "BBAR", name: "BBVA ARGENTINA SA-ADR" },
  { ticker: "BBAX", name: "JPM BTABLDRS DEV ASIA X-JPN" },
  { ticker: "BBBY", name: "BED BATH & BEYOND INC" },
  { ticker: "BBC", name: "VIRTUS LIFESCI BIOTECH CLINI" },
  { ticker: "BBCA", name: "JPMORGAN BETABUILDERS CANADA" },
  { ticker: "BBCP", name: "CONCRETE PUMPING HOLDINGS IN" },
  { ticker: "BBD", name: "BANCO BRADESCO-ADR" },
  { ticker: "BBDC", name: "BARINGS BDC INC" },
  { ticker: "BBDO", name: "BANCO BRADESCO-ADR" },
  { ticker: "BBEU", name: "JPMORGAN BETABUILDERS EUROPE" },
  { ticker: "BBF", name: "BLACKROCK MUNICIPAL INCOME" },
  { ticker: "BBGI", name: "BEASLEY BROADCAST GRP INC -A" },
  { ticker: "BBH", name: "VANECK BIOTECH ETF" },
  { ticker: "BBI", name: "BRICKELL BIOTECH INC" },
  { ticker: "BBIN", name: "JPM BETABUILDERS INTL EQTY" },
  { ticker: "BBIO", name: "BRIDGEBIO PHARMA INC" },
  { ticker: "BBJP", name: "JPMORGAN BETABUILDERS JAPAN" },
  { ticker: "BBK", name: "BLACKROCK MUNI BOND TRUST" },
  { ticker: "BBL", name: "BHP GROUP PLC-ADR" },
  { ticker: "BBN", name: "BLACKROCK TAXABLE MUNICIPAL" },
  { ticker: "BBP", name: "VIRTUS LIFESCI BIOTECH PRODU" },
  { ticker: "BBQ", name: "BBQ HOLDINGS INC" },
  { ticker: "BBRE", name: "JPM BETABLDRS MSCI US REIT" },
  { ticker: "BBSA", name: "JPMORGAN BETABUILDERS 1-5 YR" },
  { ticker: "BBSI", name: "BARRETT BUSINESS SVCS INC" },
  { ticker: "BBU", name: "BROOKFIELD BUSINESS PT-UNIT" },
  { ticker: "BBUS", name: "JPMORGAN BETABUILDERS US EQU" },
  { ticker: "BBVA", name: "BANCO BILBAO VIZCAYA-SP ADR" },
  { ticker: "BBW", name: "BUILD-A-BEAR WORKSHOP INC" },
  { ticker: "BBX", name: "BBX CAPITAL CORP" },
  { ticker: "BBY", name: "BEST BUY CO INC" },
  { ticker: "BC", name: "BRUNSWICK CORP" },
  { ticker: "BCBP", name: "BCB BANCORP INC" },
  { ticker: "BCC", name: "BOISE CASCADE CO" },
  { ticker: "BCD", name: "ABERDEEN STANDARD BLOOMBERG" },
  { ticker: "BCDA", name: "BIOCARDIA INC" },
  { ticker: "BCE", name: "BCE INC" },
  { ticker: "BCEI", name: "BONANZA CREEK ENERGY INC" },
  { ticker: "BCEL", name: "ATRECA INC - A" },
  { ticker: "BCH", name: "BANCO DE CHILE-ADR" },
  { ticker: "BCI", name: "ABERDEEN STANDARD BLOOMBERG" },
  { ticker: "BCLI", name: "BRAINSTORM CELL THERAPEUTICS" },
  { ticker: "BCM", name: "IPATH PURE BETA BROAD CMDTY" },
  { ticker: "BCML", name: "BAYCOM CORP" },
  { ticker: "BCO", name: "BRINK'S CO/THE" },
  { ticker: "BCOM", name: "B COMMUNICATIONS LTD" },
  { ticker: "BCOR", name: "BLUCORA INC" },
  { ticker: "BCOV", name: "BRIGHTCOVE" },
  { ticker: "BCOW", name: "1895 BANCORP OF WISCONSIN IN" },
  { ticker: "BCPC", name: "BALCHEM CORP" },
  { ticker: "BCRH", name: "BLUE CAPITAL REINSURANCE HOL" },
  { ticker: "BCRX", name: "BIOCRYST PHARMACEUTICALS INC" },
  { ticker: "BCS", name: "BARCLAYS PLC-SPONS ADR" },
  { ticker: "BCSF", name: "BAIN CAPITAL SPECIALTY FINAN" },
  { ticker: "BCTF", name: "BANCORP 34 INC" },
  { ticker: "BCV", name: "BANCROFT FUND LTD" },
  { ticker: "BCX", name: "BLACKROCK RESOURCES & COMMOD" },
  { ticker: "BCYC", name: "BICYCLE THERAPEUTICS LTD-ADR" },
  { ticker: "BDC", name: "BELDEN INC" },
  { ticker: "BDCL", name: "ETRACS 2X WELLS FARGO BDCI" },
  { ticker: "BDCS", name: "ETRACS WELLS FARGO BDCI ETN" },
  { ticker: "BDCY", name: "ETRACS 2XM-L WELLS FARGO BDC" },
  { ticker: "BDCZ", name: "ETRACS WELL FARGO BDC SER B" },
  { ticker: "BDEC", name: "INNOVATOR S&P 500 BUFFER-DEC" },
  { ticker: "BDGE", name: "BRIDGE BANCORP INC" },
  { ticker: "BDJ", name: "BLACKROCK ENHANCED EQTY DVD" },
  { ticker: "BDL", name: "FLANIGAN'S ENTERPRISES INC" },
  { ticker: "BDN", name: "BRANDYWINE REALTY TRUST" },
  { ticker: "BDR", name: "BLONDER TONGUE LABORATORIES" },
  { ticker: "BDRY", name: "BREAKWAVE DRY BULK SHIPPING" },
  { ticker: "BDSI", name: "BIODELIVERY SCIENCES INTL" },
  { ticker: "BDTX", name: "BLACK DIAMOND THERAPEUTICS I" },
  { ticker: "BDX", name: "BECTON DICKINSON AND CO" },
  { ticker: "BE", name: "BLOOM ENERGY CORP- A" },
  { ticker: "BEAM", name: "BEAM THERAPEUTICS INC" },
  { ticker: "BEAT", name: "BIOTELEMETRY INC" },
  { ticker: "BECN", name: "BEACON ROOFING SUPPLY INC" },
  { ticker: "BEDU", name: "BRIGHT SCHOLAR EDUCATION-ADR" },
  { ticker: "BELFA", name: "BEL FUSE INC-CL A" },
  { ticker: "BELFB", name: "BEL FUSE INC-CL B" },
  { ticker: "BEN", name: "FRANKLIN RESOURCES INC" },
  { ticker: "BEP", name: "BROOKFIELD RENEWABLE PARTNER" },
  { ticker: "BERY", name: "BERRY GLOBAL GROUP INC" },
  { ticker: "BEST", name: "BEST INC - ADR" },
  { ticker: "BF.A", name: "BROWN-FORMAN CORP-CLASS A" },
  { ticker: "BF.B", name: "BROWN-FORMAN CORP-CLASS B" },
  { ticker: "BFAM", name: "BRIGHT HORIZONS FAMILY SOLUT" },
  { ticker: "BFC", name: "BANK FIRST CORP" },
  { ticker: "BFEB", name: "INNOVATOR SP 500 BUFFER-FEB" },
  { ticker: "BFIN", name: "BANKFINANCIAL CORP" },
  { ticker: "BFIT", name: "GLOBAL X HEALTH & WELLNESS" },
  { ticker: "BFK", name: "BLACKROCK MUNICIPAL INC TRST" },
  { ticker: "BFO", name: "BLACKROCK FL MUNI 2020 TERM" },
  { ticker: "BFOR", name: "BARRON'S 400 ETF" },
  { ticker: "BFRA", name: "BIOFRONTERA AG" },
  { ticker: "BFS", name: "SAUL CENTERS INC" },
  { ticker: "BFST", name: "BUSINESS FIRST BANCSHARES" },
  { ticker: "BFY", name: "BLACKROCK NEW YORK MUN II" },
  { ticker: "BFYT", name: "BENEFYTT TECHNOLOGIES INC" },
  { ticker: "BFZ", name: "BLACKROCK CALIFOR MUNI IN TR" },
  { ticker: "BG", name: "BUNGE LTD" },
  { ticker: "BGB", name: "BLACKSTONE/GSO STRATEGIC C" },
  { ticker: "BGCP", name: "BGC PARTNERS INC-CL A" },
  { ticker: "BGFV", name: "BIG 5 SPORTING GOODS CORP" },
  { ticker: "BGG", name: "BRIGGS & STRATTON" },
  { ticker: "BGH", name: "BARINGS GL SH DUR HI YLD" },
  { ticker: "BGI", name: "BIRKS GROUP INC" },
  { ticker: "BGIO", name: "BLACKROCK 2022 GLOBAL INCOME" },
  { ticker: "BGNE", name: "BEIGENE LTD-ADR" },
  { ticker: "BGR", name: "BLACKROCK ENRGY & RES" },
  { ticker: "BGRN", name: "ISHARES GLOBAL GREEN BOND ET" },
  { ticker: "BGS", name: "B&G FOODS INC" },
  { ticker: "BGSF", name: "BG STAFFING INC" },
  { ticker: "BGT", name: "BLACKROCK FLT RT INC" },
  { ticker: "BGX", name: "BLACKSTONE/GSO LONG-SHORT CR" },
  { ticker: "BGY", name: "BLACKROCK ENHANCED INTERNATI" },
  { ticker: "BH", name: "BIGLARI HOLDINGS INC-B" },
  { ticker: "BH.A", name: "BIGLARI HOLDINGS INC-A" },
  { ticker: "BHAT", name: "BLUE HAT INTERACTIVE ENTERTA" },
  { ticker: "BHB", name: "BAR HARBOR BANKSHARES" },
  { ticker: "BHC", name: "BAUSCH HEALTH COS INC" },
  { ticker: "BHE", name: "BENCHMARK ELECTRONICS INC" },
  { ticker: "BHF", name: "BRIGHTHOUSE FINANCIAL INC" },
  { ticker: "BHK", name: "BLACKROCK CORE BOND TRUST" },
  { ticker: "BHLB", name: "BERKSHIRE HILLS BANCORP INC" },
  { ticker: "BHP", name: "BHP GROUP LTD-SPON ADR" },
  { ticker: "BHR", name: "BRAEMAR HOTELS & RESORTS INC" },
  { ticker: "BHTG", name: "BIOHITECH GLOBAL INC" },
  { ticker: "BHV", name: "BLACKROCK VIRGINIA MUNI BOND" },
  { ticker: "BHVN", name: "BIOHAVEN PHARMACEUTICAL HOLD" },
  { ticker: "BIB", name: "PROSHARES ULTRA NASD BIOTECH" },
  { ticker: "BIBL", name: "INSPIRE 100 ETF" },
  { ticker: "BICK", name: "FIRST TRUST BICK INDEX FUND" },
  { ticker: "BIDU", name: "BAIDU INC - SPON ADR" },
  { ticker: "BIF", name: "BOULDER GROWTH & INCOME FUND" },
  { ticker: "BIG", name: "BIG LOTS INC" },
  { ticker: "BIIB", name: "BIOGEN INC" },
  { ticker: "BIL", name: "SPDR BBG BARC 1-3 MONTH TBIL" },
  { ticker: "BILI", name: "BILIBILI INC-SPONSORED ADR" },
  { ticker: "BILL", name: "BILL.COM HOLDINGS INC" },
  { ticker: "BIMI", name: "BOQI INTERNATIONAL MEDICAL I" },
  { ticker: "BIO", name: "BIO-RAD LABORATORIES-A" },
  { ticker: "BIO.B", name: "BIO-RAD LABORATORIES -CL B" },
  { ticker: "BIOC", name: "BIOCEPT INC" },
  { ticker: "BIOL", name: "BIOLASE INC" },
  { ticker: "BIOX", name: "BIOCERES CROP SOLUTIONS CORP" },
  { ticker: "BIP", name: "BROOKFIELD INFRASTRUCTURE PA" },
  { ticker: "BIS", name: "PROSHARES ULTRASHORT NAS BIO" },
  { ticker: "BIT", name: "BLACKROCK MULTI-SECTOR INCOM" },
  { ticker: "BITA", name: "BITAUTO HOLDINGS LTD-ADR" },
  { ticker: "BIV", name: "VANGUARD INTERMEDIATE-TERM B" },
  { ticker: "BIZD", name: "VANECK BDC INCOME" },
  { ticker: "BJ", name: "BJ'S WHOLESALE CLUB HOLDINGS" },
  { ticker: "BJAN", name: "INNOVATOR S&P 500 BUFFER-JAN" },
  { ticker: "BJK", name: "VANECK GAMING ETF" },
  { ticker: "BJRI", name: "BJ'S RESTAURANTS INC" },
  { ticker: "BJUL", name: "INNOVATOR S&P 500 BUFFER-JLY" },
  { ticker: "BJUN", name: "INNOVATOR S&P 500 BUFFER JUN" },
  { ticker: "BK", name: "BANK OF NEW YORK MELLON CORP" },
  { ticker: "BKCC", name: "BLACKROCK CAPITAL INVESTMENT" },
  { ticker: "BKD", name: "BROOKDALE SENIOR LIVING INC" },
  { ticker: "BKE", name: "BUCKLE INC/THE" },
  { ticker: "BKEP", name: "BLUEKNIGHT ENERGY PARTNERS L" },
  { ticker: "BKF", name: "ISHARES MSCI BRIC ETF" },
  { ticker: "BKH", name: "BLACK HILLS CORP" },
  { ticker: "BKI", name: "BLACK KNIGHT INC" },
  { ticker: "BKK", name: "BLACKROCK MUNI 2020 TERM TRS" },
  { ticker: "BKLN", name: "INVESCO SENIOR LOAN ETF" },
  { ticker: "BKN", name: "BLACKROCK INVT QUALITY MUNI" },
  { ticker: "BKNG", name: "BOOKING HOLDINGS INC" },
  { ticker: "BKR", name: "BAKER HUGHES CO" },
  { ticker: "BKSC", name: "BANK OF SOUTH CAROLINA CORP" },
  { ticker: "BKT", name: "BLACKROCK INCOME TRUST" },
  { ticker: "BKTI", name: "BK TECHNOLOGIES CORP" },
  { ticker: "BKU", name: "BANKUNITED INC" },
  { ticker: "BKYI", name: "BIO-KEY INTERNATIONAL INC" },
  { ticker: "BL", name: "BLACKLINE INC" },
  { ticker: "BLBD", name: "BLUE BIRD CORP" },
  { ticker: "BLCM", name: "BELLICUM PHARMACEUTICALS INC" },
  { ticker: "BLCN", name: "REALITY SHRS NASDAQ NEXGEN" },
  { ticker: "BLD", name: "TOPBUILD CORP" },
  { ticker: "BLDP", name: "BALLARD POWER SYSTEMS INC" },
  { ticker: "BLDR", name: "BUILDERS FIRSTSOURCE INC" },
  { ticker: "BLE", name: "BLACKROCK MUN INC TRUST II" },
  { ticker: "BLES", name: "INSPIRE GLOBAL HOPE ETF" },
  { ticker: "BLFS", name: "BIOLIFE SOLUTIONS INC" },
  { ticker: "BLHY", name: "VIRTUS NEWFLEET DYNAMIC CRED" },
  { ticker: "BLIN", name: "BRIDGELINE DIGITAL INC" },
  { ticker: "BLK", name: "BLACKROCK INC" },
  { ticker: "BLKB", name: "BLACKBAUD INC" },
  { ticker: "BLL", name: "BALL CORP" },
  { ticker: "BLMN", name: "BLOOMIN' BRANDS INC" },
  { ticker: "BLNK", name: "BLINK CHARGING CO" },
  { ticker: "BLOK", name: "AMPLIFY TRANSFOR DATA SHARIN" },
  { ticker: "BLPH", name: "BELLEROPHON THERAPEUTICS INC" },
  { ticker: "BLRX", name: "BIOLINERX LTD-SPONS ADR" },
  { ticker: "BLU", name: "BELLUS HEALTH INC" },
  { ticker: "BLUE", name: "BLUEBIRD BIO INC" },
  { ticker: "BLV", name: "VANGUARD LONG-TERM BOND ETF" },
  { ticker: "BLW", name: "BLACKROCK LTD DURATION INC" },
  { ticker: "BLX", name: "BANCO LATINOAMERICANO COME-E" },
  { ticker: "BMA", name: "BANCO MACRO SA-ADR" },
  { ticker: "BMAR", name: "INNOVATOR S&P 500 BUFFER ETF" },
  { ticker: "BMCH", name: "BMC STOCK HOLDINGS INC" },
  { ticker: "BME", name: "BLACKROCK HEALTH SCIENCES" },
  { ticker: "BMEZ", name: "BLACKROCK HEALTH SCI TR II" },
  { ticker: "BMI", name: "BADGER METER INC" },
  { ticker: "BMLP", name: "DORSEY WRIGHT MLP INDEX ETNS" },
  { ticker: "BMO", name: "BANK OF MONTREAL" },
  { ticker: "BMRA", name: "BIOMERICA INC" },
  { ticker: "BMRC", name: "BANK OF MARIN BANCORP/CA" },
  { ticker: "BMRN", name: "BIOMARIN PHARMACEUTICAL INC" },
  { ticker: "BMTC", name: "BRYN MAWR BANK CORP" },
  { ticker: "BMY", name: "BRISTOL-MYERS SQUIBB CO" },
  { ticker: "BMY^", name: "BRISTOL-MYERS SQUIBB-CVR" },
  { ticker: "BND", name: "VANGUARD TOTAL BOND MARKET" },
  { ticker: "BNDC", name: "FLEXSHARES CORE SELECT BOND" },
  { ticker: "BNDW", name: "VANGUARD TOTAL WORLD BOND ET" },
  { ticker: "BNDX", name: "VANGUARD TOTAL INTL BOND ETF" },
  { ticker: "BNED", name: "BARNES & NOBLE EDUCATION INC" },
  { ticker: "BNFT", name: "BENEFITFOCUS INC" },
  { ticker: "BNGO", name: "BIONANO GENOMICS INC" },
  { ticker: "BNKD", name: "MICROSECTORS US BIG BANK -3X" },
  { ticker: "BNKO", name: "MICROSECTORS US BIG BANK 2X" },
  { ticker: "BNKU", name: "MICROSECTORS US BIG BANKS 3X" },
  { ticker: "BNKZ", name: "MICROSECTORS US BIG BANK -2X" },
  { ticker: "BNO", name: "UNITED STATES BRENT OIL FUND" },
  { ticker: "BNOV", name: "INNOVATOR S&P 500 BUFFER-NOV" },
  { ticker: "BNS", name: "BANK OF NOVA SCOTIA" },
  { ticker: "BNSO", name: "BONSO ELECTRONICS INTL INC" },
  { ticker: "BNTC", name: "BENITEC BIOPHARMA-SP ADR" },
  { ticker: "BNTX", name: "BIONTECH SE" },
  { ticker: "BNY", name: "BLACKROCK NEW YORK MUNI INC" },
  { ticker: "BOCH", name: "BANK OF COMMERCE HOLDINGS" },
  { ticker: "BOCT", name: "INNOVATOR S&P 500 BUFFER OCT" },
  { ticker: "BOE", name: "BLACKROCK ENHANCED GLOBAL DI" },
  { ticker: "BOH", name: "BANK OF HAWAII CORP" },
  { ticker: "BOIL", name: "PROSHARES ULTRA BLOOMBERG NA" },
  { ticker: "BOKF", name: "BOK FINANCIAL CORPORATION" },
  { ticker: "BOMN", name: "BOSTON OMAHA CORP-CL A" },
  { ticker: "BOND", name: "PIMCO ACTIVE BOND EXCHANGE-T" },
  { ticker: "BOOM", name: "DMC GLOBAL INC" },
  { ticker: "BOOT", name: "BOOT BARN HOLDINGS INC" },
  { ticker: "BORR", name: "BORR DRILLING LTD" },
  { ticker: "BOSC", name: "BOS BETTER ON-LINE SOLUTIONS" },
  { ticker: "BOSS", name: "GLOBAL X FOUNDER-RUN COMPANI" },
  { ticker: "BOTJ", name: "BANK OF THE JAMES FINANCIAL" },
  { ticker: "BOTZ", name: "GLOBAL X ROBOTICS & ARTIFICI" },
  { ticker: "BOUT", name: "INNOVATOR IBD BREAKOUT OPPS" },
  { ticker: "BOX", name: "BOX INC - CLASS A" },
  { ticker: "BOXL", name: "BOXLIGHT CORP - CLASS A" },
  { ticker: "BP", name: "BP PLC-SPONS ADR" },
  { ticker: "BPFH", name: "BOSTON PRIVATE FINL HOLDING" },
  { ticker: "BPMC", name: "BLUEPRINT MEDICINES CORP" },
  { ticker: "BPMP", name: "BP MIDSTREAM PARTNERS LP" },
  { ticker: "BPMX", name: "BIOPHARMX CORP" },
  { ticker: "BPOP", name: "POPULAR INC" },
  { ticker: "BPRN", name: "THE BANK OF PRINCETON" },
  { ticker: "BPT", name: "BP PRUDHOE BAY ROYALTY TRUST" },
  { ticker: "BPTH", name: "BIO-PATH HOLDINGS INC" },
  { ticker: "BPY", name: "BROOKFIELD PROPERTY PARTNERS" },
  { ticker: "BPYU", name: "BROOKFIELD PROPERTY REIT I-A" },
  { ticker: "BQH", name: "BLACKROCK NEW YORK MUNI BOND" },
  { ticker: "BR", name: "BROADRIDGE FINANCIAL SOLUTIO" },
  { ticker: "BRBR", name: "BELLRING BRANDS INC-CLASS A" },
  { ticker: "BRBS", name: "BLUE RIDGE BANKSHARES INC" },
  { ticker: "BRC", name: "BRADY CORPORATION - CL A" },
  { ticker: "BREW", name: "CRAFT BREW ALLIANCE INC" },
  { ticker: "BRF", name: "VANECK BRAZIL SMALL-CAP" },
  { ticker: "BRFS", name: "BRF SA-ADR" },
  { ticker: "BRG", name: "BLUEROCK RESIDENTIAL GROWTH" },
  { ticker: "BRID", name: "BRIDGFORD FOODS CORP" },
  { ticker: "BRK.A", name: "BERKSHIRE HATHAWAY INC-CL A" },
  { ticker: "BRK.B", name: "BERKSHIRE HATHAWAY INC-CL B" },
  { ticker: "BRKL", name: "BROOKLINE BANCORP INC" },
  { ticker: "BRKR", name: "BRUKER CORP" },
  { ticker: "BRKS", name: "BROOKS AUTOMATION INC" },
  { ticker: "BRMK", name: "BROADMARK REALTY CAPITAL INC" },
  { ticker: "BRN", name: "BARNWELL INDUSTRIES INC" },
  { ticker: "BRO", name: "BROWN & BROWN INC" },
  { ticker: "BROG", name: "BROOGE HOLDINGS LTD" },
  { ticker: "BRP", name: "BRP GROUP INC-A" },
  { ticker: "BRPA", name: "BIG ROCK PARTNERS ACQUISITIO" },
  { ticker: "BRPAR", name: "BIG ROCK PARTNERS ACQUIS-RTS" },
  { ticker: "BRPAU", name: "BIG ROCK PARTNERS ACQUISITIO" },
  { ticker: "BRQS", name: "BORQS TECHNOLOGIES INC" },
  { ticker: "BRT", name: "BRT APARTMENTS CORP" },
  { ticker: "BRX", name: "BRIXMOR PROPERTY GROUP INC" },
  { ticker: "BRY", name: "BERRY CORP" },
  { ticker: "BRZU", name: "DIREXION DLY BRAZIL BULL 3X" },
  { ticker: "BSAC", name: "BANCO SANTANDER-CHILE-ADR" },
  { ticker: "BSAE", name: "INVESCO 2021 USD EM MKT DEBT" },
  { ticker: "BSBE", name: "INVESCO 2022 USD EM MKT DEBT" },
  { ticker: "BSBK", name: "BOGOTA FINANCIAL CORP" },
  { ticker: "BSBR", name: "BANCO SANTANDER BRASIL-ADS" },
  { ticker: "BSCE", name: "INVESCO 2023 USD EM MKT DEBT" },
  { ticker: "BSCK", name: "INVESCO BULLETSHARES 2020 CO" },
  { ticker: "BSCL", name: "INVESCO BULLETSHARES 2021 CO" },
  { ticker: "BSCM", name: "INVESCO BULLETSHARES 2022 CO" },
  { ticker: "BSCN", name: "INVESCO BULLETSHARES 2023 CO" },
  { ticker: "BSCO", name: "INVESCO BULLETSHARES 2024 CO" },
  { ticker: "BSCP", name: "INVESCO BULLETSHARES 2025 CO" },
  { ticker: "BSCQ", name: "INVESCO BULLETSHARES 2026 CO" },
  { ticker: "BSCR", name: "INVESCO BULLETSHARES 2027 CO" },
  { ticker: "BSCS", name: "INVESCO BULLETSHARES 2028" },
  { ticker: "BSCT", name: "INVESCO BULLETSHARES 2029 CO" },
  { ticker: "BSD", name: "BLACKROCK STRATEGIC MUNICIPL" },
  { ticker: "BSDE", name: "INVESCO 2024 USD EM MKT DEBT" },
  { ticker: "BSE", name: "BLACKROCK NEW YORK MUNICIPAL" },
  { ticker: "BSEP", name: "INNOVATOR S&P 500 BUFFER SEP" },
  { ticker: "BSET", name: "BASSETT FURNITURE INDS" },
  { ticker: "BSGM", name: "BIOSIG TECHNOLOGIES INC" },
  { ticker: "BSIG", name: "BRIGHTSPHERE INVESTMENT GROU" },
  { ticker: "BSJK", name: "INVESCO BULLETSHARES 2020 HI" },
  { ticker: "BSJL", name: "INVESCO BULLETSHARES 2021 HI" },
  { ticker: "BSJM", name: "INVESCO BULLETSHARES 2022 HI" },
  { ticker: "BSJN", name: "INVESCO BULLETSHARES 2023 HI" },
  { ticker: "BSJO", name: "INVESCO BULLETSHARES 2024 HI" },
  { ticker: "BSJP", name: "INVESCO BULLETSHARES 2025 HI" },
  { ticker: "BSJQ", name: "INVESCO BULLETSHARES 2026 HI" },
  { ticker: "BSJR", name: "INVESCO BULLETSHARES 2027 HI" },
  { ticker: "BSL", name: "BLACKSTONE/GSO SENIOR FLOAT" },
  { ticker: "BSM", name: "BLACK STONE MINERALS LP" },
  { ticker: "BSML", name: "INVESCO BULLETSHARES 2021 MU" },
  { ticker: "BSMM", name: "INVESCO BULLETSHARES 2022 MU" },
  { ticker: "BSMN", name: "INVESCO BULLETSHARES 2023 MU" },
  { ticker: "BSMO", name: "INVESCO BULLETSHARES 2024 MU" },
  { ticker: "BSMP", name: "INVESCO BULLETSHARES 2025 MU" },
  { ticker: "BSMQ", name: "INVESCO BULLETSHARES 2026 MU" },
  { ticker: "BSMR", name: "INVESCO BULLETSHARES 2027 MU" },
  { ticker: "BSMS", name: "INVESCO BULLETSHARES 2028 MU" },
  { ticker: "BSMT", name: "INVESCO BULLETSHARES 2029 MU" },
  { ticker: "BSMX", name: "BANCO SANTANDER MEXICO -ADR" },
  { ticker: "BSQR", name: "BSQUARE CORP" },
  { ticker: "BSRR", name: "SIERRA BANCORP" },
  { ticker: "BST", name: "BLACKROCK SCIENCE & TECH TR" },
  { ticker: "BSTC", name: "BIOSPECIFICS TECHNOLOGIES" },
  { ticker: "BSTZ", name: "BLACKROCK SCIENCE & TECH II" },
  { ticker: "BSV", name: "VANGUARD SHORT-TERM BOND ETF" },
  { ticker: "BSVN", name: "BANK7 CORP" },
  { ticker: "BSX", name: "BOSTON SCIENTIFIC CORP" },
  { ticker: "BTA", name: "BLACKROCK LNG-TM MUN ADV TST" },
  { ticker: "BTAI", name: "BIOXCEL THERAPEUTICS INC" },
  { ticker: "BTAL", name: "AGFIQ US MARKET NEUTRAL ANTI" },
  { ticker: "BTE", name: "BAYTEX ENERGY CORP" },
  { ticker: "BTEC", name: "PRINCIPAL HEALTHCARE INNOV" },
  { ticker: "BTG", name: "B2GOLD CORP" },
  { ticker: "BTI", name: "BRITISH AMERICAN TOB-SP ADR" },
  { ticker: "BTN", name: "BALLANTYNE STRONG INC" },
  { ticker: "BTO", name: "JOHN HANCOCK FINANCIAL OPPOR" },
  { ticker: "BTT", name: "BLACKROCK MUNICIPAL 2030 TAR" },
  { ticker: "BTU", name: "PEABODY ENERGY CORP" },
  { ticker: "BTYS", name: "IPATH SERIES B US TREASURY" },
  { ticker: "BTZ", name: "BLACKROCK CREDIT ALLOCATION" },
  { ticker: "BUD", name: "ANHEUSER-BUSCH INBEV-SPN ADR" },
  { ticker: "BUG", name: "GLOBAL X CYBERSECURITY ETF" },
  { ticker: "BUI", name: "BLCKRCK UTIL INFRA & PWR OPP" },
  { ticker: "BUL", name: "PACER US CASH COWS GROWTH" },
  { ticker: "BURG", name: "CHANTICLEER HOLDINGS INC" },
  { ticker: "BURL", name: "BURLINGTON STORES INC" },
  { ticker: "BUSE", name: "FIRST BUSEY CORP" },
  { ticker: "BUY", name: "USCF SUMMERHAVEN SHPEI IN FD" },
  { ticker: "BUYN", name: "USCF SUMMERHAVEN SHPEN INDEX" },
  { ticker: "BUYZ", name: "FRANKLIN DISRUPTIVE COMMERCE" },
  { ticker: "BV", name: "BRIGHTVIEW HOLDINGS INC" },
  { ticker: "BVAL", name: "BRAND VALUE ETF" },
  { ticker: "BVN", name: "CIA DE MINAS BUENAVENTUR-ADR" },
  { ticker: "BVSN", name: "BROADVISION INC" },
  { ticker: "BVXV", name: "BIONDVAX PHARMACEUTICALS-ADR" },
  { ticker: "BW", name: "BABCOCK & WILCOX ENTERPR" },
  { ticker: "BWA", name: "BORGWARNER INC" },
  { ticker: "BWAY", name: "BRAINSWAY LTD-ADR" },
  { ticker: "BWB", name: "BRIDGEWATER BANCSHARES INC" },
  { ticker: "BWEN", name: "BROADWIND ENERGY INC" },
  { ticker: "BWFG", name: "BANKWELL FINANCIAL GROUP INC" },
  { ticker: "BWG", name: "BRANDYWINEGLOBAL GLOBAL INCO" },
  { ticker: "BWL.A", name: "BOWL AMERICA INC-CLASS A" },
  { ticker: "BWX", name: "SPDR BBG BARC INTL TREASURY" },
  { ticker: "BWXT", name: "BWX TECHNOLOGIES INC" },
  { ticker: "BWZ", name: "SPDR BBG BARC ST INTL TREAS" },
  { ticker: "BX", name: "BLACKSTONE GROUP INC/THE-A" },
  { ticker: "BXC", name: "BLUELINX HOLDINGS INC" },
  { ticker: "BXG", name: "BLUEGREEN VACATIONS CORP" },
  { ticker: "BXMT", name: "BLACKSTONE MORTGAGE TRU-CL A" },
  { ticker: "BXMX", name: "NUVEEN S&P500 BUY-WRT INC FD" },
  { ticker: "BXP", name: "BOSTON PROPERTIES INC" },
  { ticker: "BXRX", name: "BAUDAX BIO INC" },
  { ticker: "BXS", name: "BANCORPSOUTH BANK" },
  { ticker: "BY", name: "BYLINE BANCORP INC" },
  { ticker: "BYD", name: "BOYD GAMING CORP" },
  { ticker: "BYFC", name: "BROADWAY FINANCIAL CORP/DE" },
  { ticker: "BYLD", name: "ISHARES YIELD OPTIMIZED BOND" },
  { ticker: "BYM", name: "BLACKROCK MUNICIPAL INCOME Q" },
  { ticker: "BYND", name: "BEYOND MEAT INC" },
  { ticker: "BYSI", name: "BEYONDSPRING INC" },
  { ticker: "BZH", name: "BEAZER HOMES USA INC" },
  { ticker: "BZM", name: "BLACKROCK MARYLAND MUNI BOND" },
  { ticker: "BZQ", name: "PROSHARES ULTRASHORT MSCI BR" },
  { ticker: "BZUN", name: "BAOZUN INC-SPN ADR" },
  { ticker: "C", name: "CITIGROUP INC" },
  { ticker: "CAAP", name: "CORP AMERICA AIRPORTS SA" },
  { ticker: "CAAS", name: "CHINA AUTOMOTIVE SYSTEMS INC" },
  { ticker: "CABA", name: "CABALETTA BIO INC" },
  { ticker: "CABO", name: "CABLE ONE INC" },
  { ticker: "CAC", name: "CAMDEN NATIONAL CORP" },
  { ticker: "CACC", name: "CREDIT ACCEPTANCE CORP" },
  { ticker: "CACG", name: "CLEARBRIDGE ALLCAP GRWTH ETF" },
  { ticker: "CACI", name: "CACI INTERNATIONAL INC -CL A" },
  { ticker: "CADE", name: "CADENCE BANCORP" },
  { ticker: "CAE", name: "CAE INC" },
  { ticker: "CAF", name: "MORGAN STANLEY CHINA A SHARE" },
  { ticker: "CAG", name: "CONAGRA BRANDS INC" },
  { ticker: "CAH", name: "CARDINAL HEALTH INC" },
  { ticker: "CAI", name: "CAI INTERNATIONAL INC" },
  { ticker: "CAJ", name: "CANON INC-SPONS ADR" },
  { ticker: "CAKE", name: "CHEESECAKE FACTORY INC/THE" },
  { ticker: "CAL", name: "CALERES INC" },
  { ticker: "CALA", name: "CALITHERA BIOSCIENCES INC" },
  { ticker: "CALF", name: "PACER US SMALL CAP CASH COWS" },
  { ticker: "CALM", name: "CAL-MAINE FOODS INC" },
  { ticker: "CALX", name: "CALIX INC" },
  { ticker: "CAMP", name: "CALAMP CORP" },
  { ticker: "CAMT", name: "CAMTEK LTD" },
  { ticker: "CAN", name: "CANAAN INC" },
  { ticker: "CANE", name: "TEUCRIUM SUGAR FUND" },
  { ticker: "CANF", name: "CAN FITE BIOPHARMA LTD-ADR" },
  { ticker: "CANG", name: "CANGO INC/KY - ADR" },
  { ticker: "CAPE", name: "IPATH SHILLER CAPE ETN" },
  { ticker: "CAPL", name: "CROSSAMERICA PARTNERS LP" },
  { ticker: "CAPR", name: "CAPRICOR THERAPEUTICS INC" },
  { ticker: "CAR", name: "AVIS BUDGET GROUP INC" },
  { ticker: "CARA", name: "CARA THERAPEUTICS INC" },
  { ticker: "CARE", name: "CARTER BANK & TRUST" },
  { ticker: "CARG", name: "CARGURUS INC" },
  { ticker: "CARO", name: "CAROLINA FINANCIAL CORP" },
  { ticker: "CARS", name: "CARS.COM INC" },
  { ticker: "CARV", name: "CARVER BANCORP INC" },
  { ticker: "CARZ", name: "FIRST TRUST NASDAQ GLOBAL AU" },
  { ticker: "CASA", name: "CASA SYSTEMS INC" },
  { ticker: "CASH", name: "META FINANCIAL GROUP INC" },
  { ticker: "CASI", name: "CASI PHARMACEUTICALS INC" },
  { ticker: "CASS", name: "CASS INFORMATION SYSTEMS INC" },
  { ticker: "CASY", name: "CASEY'S GENERAL STORES INC" },
  { ticker: "CAT", name: "CATERPILLAR INC" },
  { ticker: "CATB", name: "CATABASIS PHARMACEUTICALS IN" },
  { ticker: "CATC", name: "CAMBRIDGE BANCORP" },
  { ticker: "CATH", name: "GLOBAL X S&P 500 CA VAL ETF" },
  { ticker: "CATM", name: "CARDTRONICS PLC - A" },
  { ticker: "CATO", name: "CATO CORP-CLASS A" },
  { ticker: "CATS", name: "CATASYS INC" },
  { ticker: "CATY", name: "CATHAY GENERAL BANCORP" },
  { ticker: "CB", name: "CHUBB LTD" },
  { ticker: "CBAN", name: "COLONY BANKCORP" },
  { ticker: "CBAT", name: "CBAK ENERGY TECHNOLOGY INC" },
  { ticker: "CBAY", name: "CYMABAY THERAPEUTICS INC" },
  { ticker: "CBB", name: "CINCINNATI BELL INC" },
  { ticker: "CBD", name: "CIA BRASILEIRA DE DIS- ADR" },
  { ticker: "CBFV", name: "CB FINANCIAL SERVICES INC" },
  { ticker: "CBH", name: "ALLIANZGI CONVERTIBLE & INCO" },
  { ticker: "CBIO", name: "CATALYST BIOSCIENCES INC" },
  { ticker: "CBL", name: "CBL & ASSOCIATES PROPERTIES" },
  { ticker: "CBLI", name: "CLEVELAND BIOLABS INC" },
  { ticker: "CBMB", name: "CBM BANCORP INC" },
  { ticker: "CBMG", name: "CELLULAR BIOMEDICINE GROUP I" },
  { ticker: "CBNK", name: "CAPITAL BANCORP INC/MD" },
  { ticker: "CBOE", name: "CBOE GLOBAL MARKETS INC" },
  { ticker: "CBON", name: "VANECK CAMC CHINA BOND" },
  { ticker: "CBPO", name: "CHINA BIOLOGIC PRODUCTS HOLD" },
  { ticker: "CBRE", name: "CBRE GROUP INC - A" },
  { ticker: "CBRL", name: "CRACKER BARREL OLD COUNTRY" },
  { ticker: "CBSH", name: "COMMERCE BANCSHARES INC" },
  { ticker: "CBT", name: "CABOT CORP" },
  { ticker: "CBTX", name: "CBTX INC" },
  { ticker: "CBU", name: "COMMUNITY BANK SYSTEM INC" },
  { ticker: "CBZ", name: "CBIZ INC" },
  { ticker: "CC", name: "CHEMOURS CO/THE" },
  { ticker: "CCAC=", name: "CITIC CAPITAL ACQUISITION" },
  { ticker: "CCAP", name: "CRESCENT CAPITAL BDC INC" },
  { ticker: "CCB", name: "COASTAL FINANCIAL CORP/WA" },
  { ticker: "CCBG", name: "CAPITAL CITY BANK GROUP INC" },
  { ticker: "CCC", name: "CLARIVATE ANALYTICS PLC" },
  { ticker: "CCCL", name: "CHINA CERAMICS CO LTD" },
  { ticker: "CCD", name: "CALAMOS DYNAMIC CONVERTIBLE" },
  { ticker: "CCEP", name: "COCA-COLA EUROPEAN PARTNERS" },
  { ticker: "CCF", name: "CHASE CORP" },
  { ticker: "CCH", name: "COLLIER CREEK HOLDINGS-A" },
  { ticker: "CCH=", name: "COLLIER CREEK HOLDINGS" },
  { ticker: "CCI", name: "CROWN CASTLE INTL CORP" },
  { ticker: "CCJ", name: "CAMECO CORP" },
  { ticker: "CCK", name: "CROWN HOLDINGS INC" },
  { ticker: "CCL", name: "CARNIVAL CORP" },
  { ticker: "CCLP", name: "CSI COMPRESSCO LP" },
  { ticker: "CCM", name: "CONCORD MEDICAL - SPON ADR" },
  { ticker: "CCMP", name: "CABOT MICROELECTRONICS CORP" },
  { ticker: "CCNE", name: "CNB FINANCIAL CORP/PA" },
  { ticker: "CCO", name: "CLEAR CHANNEL OUTDOOR HOLDIN" },
  { ticker: "CCOI", name: "COGENT COMMUNICATIONS HOLDIN" },
  { ticker: "CCOR", name: "CORE ALTERNATIVE ETF" },
  { ticker: "CCR", name: "CONSOL COAL RESOURCES LP" },
  { ticker: "CCRC", name: "CHINA CUSTOMER RELATIONS CEN" },
  { ticker: "CCRN", name: "CROSS COUNTRY HEALTHCARE INC" },
  { ticker: "CCS", name: "CENTURY COMMUNITIES INC" },
  { ticker: "CCU", name: "CIA CERVECERIAS UNI-SPON ADR" },
  { ticker: "CCX", name: "CHURCHILL CAPITAL CORP II-A" },
  { ticker: "CCX=", name: "CHURCHILL CAPITAL CORP II" },
  { ticker: "CCXI", name: "CHEMOCENTRYX INC" },
  { ticker: "CCXX=", name: "CHURCHILL CAPITAL CORP III" },
  { ticker: "CDAY", name: "CERIDIAN HCM HOLDING INC" },
  { ticker: "CDC", name: "VICTORYSHARES US EQ INCOME E" },
  { ticker: "CDE", name: "COEUR MINING INC" },
  { ticker: "CDEV", name: "CENTENNIAL RESOURCE DEVELO-A" },
  { ticker: "CDK", name: "CDK GLOBAL INC" },
  { ticker: "CDL", name: "VICTORYSHARES US LARGE CAP H" },
  { ticker: "CDLX", name: "CARDLYTICS INC" },
  { ticker: "CDMO", name: "AVID BIOSERVICES INC" },
  { ticker: "CDNA", name: "CAREDX INC" },
  { ticker: "CDNS", name: "CADENCE DESIGN SYS INC" },
  { ticker: "CDOR", name: "CONDOR HOSPITALITY TRUST INC" },
  { ticker: "CDR", name: "CEDAR REALTY TRUST INC" },
  { ticker: "CDTX", name: "CIDARA THERAPEUTICS INC" },
  { ticker: "CDW", name: "CDW CORP/DE" },
  { ticker: "CDXC", name: "CHROMADEX CORP" },
  { ticker: "CDXS", name: "CODEXIS INC" },
  { ticker: "CDZI", name: "CADIZ INC" },
  { ticker: "CE", name: "CELANESE CORP" },
  { ticker: "CEA", name: "CHINA EASTERN AIRLINES-ADS" },
  { ticker: "CECE", name: "CECO ENVIRONMENTAL CORP" },
  { ticker: "CEE", name: "CENTRAL AND EASTERN EUROPE F" },
  { ticker: "CEF", name: "SPROTT PHYSICAL GOLD AND SIL" },
  { ticker: "CEFL", name: "ETRACS MONTH PAY 2X LEV C/E" },
  { ticker: "CEFS", name: "SABA CLOSED END FUNDS ETF" },
  { ticker: "CEFZ", name: "ETRACS MONTHLY PAY 2XL-CEF-B" },
  { ticker: "CEI", name: "CAMBER ENERGY INC" },
  { ticker: "CEIX", name: "CONSOL ENERGY INC" },
  { ticker: "CEL", name: "CELLCOM ISRAEL LTD" },
  { ticker: "CELC", name: "CELCUITY INC" },
  { ticker: "CELG^", name: "BRISTOL MYERS - CELGENE CVR" },
  { ticker: "CELH", name: "CELSIUS HOLDINGS INC" },
  { ticker: "CELP", name: "CYPRESS ENERGY PARTNERS LP" },
  { ticker: "CEM", name: "CLEARBRIDGE MLP & MIDSTREAM" },
  { ticker: "CEMB", name: "ISHARES JP MORGAN EM CORPORA" },
  { ticker: "CEMI", name: "CHEMBIO DIAGNOSTICS INC" },
  { ticker: "CEN", name: "CENTER COAST BROOKFIELD MLP" },
  { ticker: "CENT", name: "CENTRAL GARDEN & PET CO" },
  { ticker: "CENTA", name: "CENTRAL GARDEN AND PET CO-A" },
  { ticker: "CENX", name: "CENTURY ALUMINUM COMPANY" },
  { ticker: "CEO", name: "CNOOC LTD-SPON ADR" },
  { ticker: "CEPU", name: "CENTRAL PUERTO-SPONSORED ADR" },
  { ticker: "CEQP", name: "CRESTWOOD EQUITY PARTNERS LP" },
  { ticker: "CERC", name: "CERECOR INC" },
  { ticker: "CERN", name: "CERNER CORP" },
  { ticker: "CERS", name: "CERUS CORP" },
  { ticker: "CET", name: "CENTRAL SECURITIES CORP" },
  { ticker: "CETV", name: "CENTRAL EUROPEAN MEDIA ENT-A" },
  { ticker: "CETX", name: "CEMTREX INC" },
  { ticker: "CEV", name: "EATON VANCE CA MUNI INC TRST" },
  { ticker: "CEVA", name: "CEVA INC" },
  { ticker: "CEW", name: "WISDOMTREE EMERGING CURRENCY" },
  { ticker: "CEY", name: "VICTORYSHARES EMERGING MARKE" },
  { ticker: "CEZ", name: "VICTORYSHARES EMERGING MARKE" },
  { ticker: "CF", name: "CF INDUSTRIES HOLDINGS INC" },
  { ticker: "CFA", name: "VICTORYSHARES US 500 VOLATIL" },
  { ticker: "CFB", name: "CROSSFIRST BANKSHARES INC" },
  { ticker: "CFBI", name: "COMMUNITY FIRST BANCSHARES I" },
  { ticker: "CFBK", name: "CENTRAL FEDERAL CORP" },
  { ticker: "CFFA", name: "CF FINANCE ACQUISITION-CL A" },
  { ticker: "CFFAU", name: "CF FINANCE ACQUISITION CORP" },
  { ticker: "CFFI", name: "C & F FINANCIAL CORP" },
  { ticker: "CFFN", name: "CAPITOL FEDERAL FINANCIAL IN" },
  { ticker: "CFG", name: "CITIZENS FINANCIAL GROUP" },
  { ticker: "CFMS", name: "CONFORMIS INC" },
  { ticker: "CFO", name: "VICTORYSHARES US 500 ENHANCE" },
  { ticker: "CFR", name: "CULLEN/FROST BANKERS INC" },
  { ticker: "CFRX", name: "CONTRAFECT CORP" },
  { ticker: "CFX", name: "COLFAX CORP" },
  { ticker: "CG", name: "CARLYLE GROUP INC/THE" },
  { ticker: "CGA", name: "CHINA GREEN AGRICULTURE INC" },
  { ticker: "CGBD", name: "TCG BDC INC" },
  { ticker: "CGC", name: "CANOPY GROWTH CORP" },
  { ticker: "CGEN", name: "COMPUGEN LTD" },
  { ticker: "CGIX", name: "CANCER GENETICS INC" },
  { ticker: "CGNX", name: "COGNEX CORP" },
  { ticker: "CGO", name: "CALAMOS GLOBAL TOTAL RETURN" },
  { ticker: "CGW", name: "INVESCO S&P GLOBAL WATER IND" },
  { ticker: "CHA", name: "CHINA TELECOM CORP LTD-ADR" },
  { ticker: "CHAD", name: "DRX DLY CHINA A-SHR BEAR 1X" },
  { ticker: "CHAP", name: "CHAPARRAL ENERGY INC-CLASS A" },
  { ticker: "CHAU", name: "DRX DLY CHINA A-SHR BULL 2X" },
  { ticker: "CHCI", name: "COMSTOCK HOLDING COMPANIES" },
  { ticker: "CHCO", name: "CITY HOLDING CO" },
  { ticker: "CHCT", name: "COMMUNITY HEALTHCARE TRUST I" },
  { ticker: "CHD", name: "CHURCH & DWIGHT CO INC" },
  { ticker: "CHDN", name: "CHURCHILL DOWNS INC" },
  { ticker: "CHE", name: "CHEMED CORP" },
  { ticker: "CHEF", name: "CHEFS' WAREHOUSE INC/THE" },
  { ticker: "CHEK", name: "CHECK CAP LTD" },
  { ticker: "CHEP", name: "AGFIQ US MARKET NEUTRAL VALU" },
  { ticker: "CHFS", name: "CHF SOLUTIONS INC" },
  { ticker: "CHGG", name: "CHEGG INC" },
  { ticker: "CHGX", name: "CHANGE FINANCE US LARGE CAP" },
  { ticker: "CHH", name: "CHOICE HOTELS INTL INC" },
  { ticker: "CHI", name: "CALAMOS CONVERTIBLE OPP&INC" },
  { ticker: "CHIC", name: "GLOBAL X MSCI CHINA COMMUNIC" },
  { ticker: "CHIE", name: "GLOBAL X MSCI CHINA ENERGY E" },
  { ticker: "CHIH", name: "GLOBAL X MSCI CHINA HEALTH" },
  { ticker: "CHII", name: "GLOBAL X MSCI CHINA INDUSTRI" },
  { ticker: "CHIK", name: "GLOBAL X MSCI CHINA INFO ETF" },
  { ticker: "CHIL", name: "GLBL MSCI CHINA LARGE CAP" },
  { ticker: "CHIM", name: "GLOBAL X MSCI CHINA MATERIAL" },
  { ticker: "CHIQ", name: "GLOBAL X MSCI CHINA CONSUMER" },
  { ticker: "CHIR", name: "GBL X MSCI CHINA REAL ESTATE" },
  { ticker: "CHIS", name: "GBL X MSCI CHINA CONSUMER ST" },
  { ticker: "CHIU", name: "GLOBAL X MSCI CHINA UTIL ETF" },
  { ticker: "CHIX", name: "GLOBAL X MSCI CHINA FINANCIA" },
  { ticker: "CHK", name: "CHESAPEAKE ENERGY CORP" },
  { ticker: "CHKP", name: "CHECK POINT SOFTWARE TECH" },
  { ticker: "CHL", name: "CHINA MOBILE LTD-SPON ADR" },
  { ticker: "CHMA", name: "CHIASMA INC" },
  { ticker: "CHMG", name: "CHEMUNG FINANCIAL CORP" },
  { ticker: "CHMI", name: "CHERRY HILL MORTGAGE INVESTM" },
  { ticker: "CHN", name: "CHINA FUND INC" },
  { ticker: "CHNA", name: "LONCAR CHINA BIOPHARMA ETF" },
  { ticker: "CHNG", name: "CHANGE HEALTHCARE INC" },
  { ticker: "CHNGV", name: "CHANGE HEALTHCARE INC - WI" },
  { ticker: "CHNR", name: "CHINA NATURAL RESOURCES INC" },
  { ticker: "CHPM", name: "CHP MERGER CORP-CLASS A" },
  { ticker: "CHPMU", name: "CHP MERGER CORP" },
  { ticker: "CHRA", name: "CHARAH SOLUTIONS INC" },
  { ticker: "CHRS", name: "COHERUS BIOSCIENCES INC" },
  { ticker: "CHRW", name: "C.H. ROBINSON WORLDWIDE INC" },
  { ticker: "CHS", name: "CHICO'S FAS INC" },
  { ticker: "CHT", name: "CHUNGHWA TELECOM LT-SPON ADR" },
  { ticker: "CHTR", name: "CHARTER COMMUNICATIONS INC-A" },
  { ticker: "CHU", name: "CHINA UNICOM HONG KONG-ADR" },
  { ticker: "CHUY", name: "CHUY'S HOLDINGS INC" },
  { ticker: "CHW", name: "CALAMOS GLOBAL DYNAMIC INCOM" },
  { ticker: "CHWY", name: "CHEWY INC - CLASS A" },
  { ticker: "CHY", name: "CALAMOS CVT AND HIGH INCOME" },
  { ticker: "CI", name: "CIGNA CORP" },
  { ticker: "CIA", name: "CITIZENS INC" },
  { ticker: "CIB", name: "BANCOLOMBIA S.A.-SPONS ADR" },
  { ticker: "CIBR", name: "FIRST TRUST NASDAQ CYBERSECU" },
  { ticker: "CID", name: "VICTORYSHARES INTERNATIONAL" },
  { ticker: "CIDM", name: "CINEDIGM CORP - A" },
  { ticker: "CIEN", name: "CIENA CORP" },
  { ticker: "CIF", name: "MFS INTERMEDIATE HIGH INC FN" },
  { ticker: "CIFS", name: "CHINA INTERNET NATIONWIDE FI" },
  { ticker: "CIG", name: "CIA ENERGETICA DE-SPON ADR" },
  { ticker: "CIG.C", name: "CIA ENERGETICA DE-SPON ADR" },
  { ticker: "CIGI", name: "COLLIERS INTERNATIONAL GROUP" },
  { ticker: "CIH", name: "CHINA INDEX HDS-ADR" },
  { ticker: "CII", name: "BLACKROCK ENH CAP AND INC" },
  { ticker: "CIIC", name: "CIIG MERGER CORP-CL A" },
  { ticker: "CIICU", name: "CIIG MERGER CORP" },
  { ticker: "CIK", name: "CREDIT SUISSE ASSET MGMT INC" },
  { ticker: "CIL", name: "VICTORYSHARES INTERNATIONAL" },
  { ticker: "CIM", name: "CHIMERA INVESTMENT CORP" },
  { ticker: "CINF", name: "CINCINNATI FINANCIAL CORP" },
  { ticker: "CINR", name: "CINER RESOURCES LP" },
  { ticker: "CIO", name: "CITY OFFICE REIT INC" },
  { ticker: "CIR", name: "CIRCOR INTERNATIONAL INC" },
  { ticker: "CIT", name: "CIT GROUP INC" },
  { ticker: "CIVB", name: "CIVISTA BANCSHARES INC" },
  { ticker: "CIX", name: "COMPX INTERNATIONAL INC" },
  { ticker: "CIZ", name: "VICTORYSHARES DEVELOPED ENHA" },
  { ticker: "CIZN", name: "CITIZENS HOLDING COMPANY" },
  { ticker: "CJJD", name: "CHINA JO-JO DRUGSTORES INC" },
  { ticker: "CKH", name: "SEACOR HOLDINGS INC" },
  { ticker: "CKPT", name: "CHECKPOINT THERAPEUTICS INC" },
  { ticker: "CKX", name: "CKX LANDS INC" },
  { ticker: "CL", name: "COLGATE-PALMOLIVE CO" },
  { ticker: "CLAR", name: "CLARUS CORP" },
  { ticker: "CLB", name: "CORE LABORATORIES N.V." },
  { ticker: "CLBK", name: "COLUMBIA FINANCIAL INC" },
  { ticker: "CLBS", name: "CALADRIUS BIOSCIENCES INC" },
  { ticker: "CLCT", name: "COLLECTORS UNIVERSE" },
  { ticker: "CLDB", name: "CORTLAND BANCORP" },
  { ticker: "CLDR", name: "CLOUDERA INC" },
  { ticker: "CLDT", name: "CHATHAM LODGING TRUST" },
  { ticker: "CLDX", name: "CELLDEX THERAPEUTICS INC" },
  { ticker: "CLF", name: "CLEVELAND-CLIFFS INC" },
  { ticker: "CLFD", name: "CLEARFIELD INC" },
  { ticker: "CLGN", name: "COLLPLANT BIOTECHN-SPON ADR" },
  { ticker: "CLGX", name: "CORELOGIC INC" },
  { ticker: "CLH", name: "CLEAN HARBORS INC" },
  { ticker: "CLI", name: "MACK-CALI REALTY CORP" },
  { ticker: "CLIR", name: "CLEARSIGN TECHNOLOGIES CORP" },
  { ticker: "CLIX", name: "PROSHARES LONG ONLINE/SHORT" },
  { ticker: "CLLS", name: "CELLECTIS - ADR" },
  { ticker: "CLM", name: "CORNERSTONE STRATEGIC VALUE" },
  { ticker: "CLMT", name: "CALUMET SPECIALTY PRODUCTS" },
  { ticker: "CLNC", name: "COLONY CREDIT REAL ESTATE IN" },
  { ticker: "CLNE", name: "CLEAN ENERGY FUELS CORP" },
  { ticker: "CLNY", name: "COLONY CAPITAL INC" },
  { ticker: "CLOU", name: "GLOBAL X CLOUD COMPUTING ETF" },
  { ticker: "CLPR", name: "CLIPPER REALTY INC" },
  { ticker: "CLPS", name: "CLPS INC" },
  { ticker: "CLPT", name: "CLEARPOINT NEURO INC" },
  { ticker: "CLR", name: "CONTINENTAL RESOURCES INC/OK" },
  { ticker: "CLRB", name: "CELLECTAR BIOSCIENCES INC" },
  { ticker: "CLRG", name: "IQ CHAIKIN US LARGE CAP ETF" },
  { ticker: "CLRO", name: "CLEARONE INC" },
  { ticker: "CLS", name: "CELESTICA INC" },
  { ticker: "CLSD", name: "CLEARSIDE BIOMEDICAL INC" },
  { ticker: "CLSK", name: "CLEANSPARK INC" },
  { ticker: "CLSN", name: "CELSION CORP" },
  { ticker: "CLTL", name: "INVESCO TREASURY COLLATERAL" },
  { ticker: "CLUB", name: "TOWN SPORTS INTERNATIONAL" },
  { ticker: "CLVS", name: "CLOVIS ONCOLOGY INC" },
  { ticker: "CLW", name: "CLEARWATER PAPER CORP" },
  { ticker: "CLWT", name: "EURO TECH HOLDINGS CO LTD" },
  { ticker: "CLX", name: "CLOROX COMPANY" },
  { ticker: "CLXT", name: "CALYXT INC" },
  { ticker: "CM", name: "CAN IMPERIAL BK OF COMMERCE" },
  { ticker: "CMA", name: "COMERICA INC" },
  { ticker: "CMBM", name: "CAMBIUM NETWORKS CORP" },
  { ticker: "CMBS", name: "ISHARES CMBS ETF" },
  { ticker: "CMC", name: "COMMERCIAL METALS CO" },
  { ticker: "CMCL", name: "CALEDONIA MINING CORP PLC" },
  { ticker: "CMCM", name: "CHEETAH MOBILE INC - ADR" },
  { ticker: "CMCO", name: "COLUMBUS MCKINNON CORP/NY" },
  { ticker: "CMCSA", name: "COMCAST CORP-CLASS A" },
  { ticker: "CMCT", name: "CIM COMMERCIAL TRUST CORP" },
  { ticker: "CMD", name: "CANTEL MEDICAL CORP" },
  { ticker: "CMDY", name: "ISHARES BLOOMBERG ROLL SELEC" },
  { ticker: "CME", name: "CME GROUP INC" },
  { ticker: "CMF", name: "ISHARES CALIFORNIA MUNI BOND" },
  { ticker: "CMG", name: "CHIPOTLE MEXICAN GRILL INC" },
  { ticker: "CMI", name: "CUMMINS INC" },
  { ticker: "CMLS", name: "CUMULUS MEDIA INC-CL A" },
  { ticker: "CMO", name: "CAPSTEAD MORTGAGE CORP" },
  { ticker: "CMP", name: "COMPASS MINERALS INTERNATION" },
  { ticker: "CMPR", name: "CIMPRESS PLC" },
  { ticker: "CMRE", name: "COSTAMARE INC" },
  { ticker: "CMRX", name: "CHIMERIX INC" },
  { ticker: "CMS", name: "CMS ENERGY CORP" },
  { ticker: "CMT", name: "CORE MOLDING TECHNOLOGIES IN" },
  { ticker: "CMTL", name: "COMTECH TELECOMMUNICATIONS" },
  { ticker: "CMU", name: "MFS HIGH YIELD MUNICIPAL TRU" },
  { ticker: "CN", name: "XTRACKERS MSCI ALL CHINA EQU" },
  { ticker: "CNA", name: "CNA FINANCIAL CORP" },
  { ticker: "CNAT", name: "CONATUS PHARMACEUTICALS INC" },
  { ticker: "CNBKA", name: "CENTURY BANCORP INC -CL A" },
  { ticker: "CNBS", name: "AMPLIFY SEYMOUR CANNABIS ETF" },
  { ticker: "CNC", name: "CENTENE CORP" },
  { ticker: "CNCE", name: "CONCERT PHARMACEUTICALS INC" },
  { ticker: "CNCR", name: "LONCAR CANCER IMMUNOTHERAPY" },
  { ticker: "CNDT", name: "CONDUENT INC" },
  { ticker: "CNET", name: "CHINANET ONLINE HOLDINGS INC" },
  { ticker: "CNF", name: "CNFINANCE HOLDINGS LTD" },
  { ticker: "CNFR", name: "CONIFER HOLDINGS INC" },
  { ticker: "CNHI", name: "CNH INDUSTRIAL NV" },
  { ticker: "CNI", name: "CANADIAN NATL RAILWAY CO" },
  { ticker: "CNK", name: "CINEMARK HOLDINGS INC" },
  { ticker: "CNMD", name: "CONMED CORP" },
  { ticker: "CNNB", name: "CINCINNATI BANCORP INC" },
  { ticker: "CNNE", name: "CANNAE HOLDINGS INC" },
  { ticker: "CNO", name: "CNO FINANCIAL GROUP INC" },
  { ticker: "CNOB", name: "CONNECTONE BANCORP INC" },
  { ticker: "CNP", name: "CENTERPOINT ENERGY INC" },
  { ticker: "CNQ", name: "CANADIAN NATURAL RESOURCES" },
  { ticker: "CNR", name: "CORNERSTONE BUILDING BRANDS" },
  { ticker: "CNRG", name: "SPDR S&P KENSHO CLEAN POWER" },
  { ticker: "CNS", name: "COHEN & STEERS INC" },
  { ticker: "CNSL", name: "CONSOLIDATED COMMUNICATIONS" },
  { ticker: "CNSP", name: "CNS PHARMACEUTICALS INC" },
  { ticker: "CNST", name: "CONSTELLATION PHARMACEUTICAL" },
  { ticker: "CNTG", name: "CENTOGENE NV" },
  { ticker: "CNTY", name: "CENTURY CASINOS INC" },
  { ticker: "CNX", name: "CNX RESOURCES CORP" },
  { ticker: "CNXM", name: "CNX MIDSTREAM PARTNERS LP" },
  { ticker: "CNXN", name: "PC CONNECTION INC" },
  { ticker: "CNXT", name: "VANECK CAMC SME-CHINEXT" },
  { ticker: "CNY", name: "MARKET VECTORS-RENMINBI/USD" },
  { ticker: "CNYA", name: "ISHARES MSCI CHINA A ETF" },
  { ticker: "CO", name: "GLOBAL CORD BLOOD CORP" },
  { ticker: "COCP", name: "COCRYSTAL PHARMA INC" },
  { ticker: "CODA", name: "CODA OCTOPUS GROUP INC" },
  { ticker: "CODI", name: "COMPASS DIVERSIFIED HOLDINGS" },
  { ticker: "CODX", name: "CO-DIAGNOSTICS INC" },
  { ticker: "COE", name: "CHINA ONLINE EDUCATION-ADR" },
  { ticker: "COF", name: "CAPITAL ONE FINANCIAL CORP" },
  { ticker: "COFS", name: "CHOICEONE FINANCIAL SVCS INC" },
  { ticker: "COG", name: "CABOT OIL & GAS CORP" },
  { ticker: "COHN", name: "COHEN & CO INC" },
  { ticker: "COHR", name: "COHERENT INC" },
  { ticker: "COHU", name: "COHU INC" },
  { ticker: "COKE", name: "COCA-COLA CONSOLIDATED INC" },
  { ticker: "COLB", name: "COLUMBIA BANKING SYSTEM INC" },
  { ticker: "COLD", name: "AMERICOLD REALTY TRUST" },
  { ticker: "COLL", name: "COLLEGIUM PHARMACEUTICAL INC" },
  { ticker: "COLM", name: "COLUMBIA SPORTSWEAR CO" },
  { ticker: "COM", name: "DIREXION AUSPICE BRD CMDTY" },
  { ticker: "COMB", name: "GRANITESHARES BCOM BROAD STR" },
  { ticker: "COMM", name: "COMMSCOPE HOLDING CO INC" },
  { ticker: "COMT", name: "ISHARES COMMOD SELECT STRAT" },
  { ticker: "CONE", name: "CYRUSONE INC" },
  { ticker: "CONN", name: "CONN'S INC" },
  { ticker: "COO", name: "COOPER COS INC/THE" },
  { ticker: "COOP", name: "MR COOPER GROUP INC" },
  { ticker: "COP", name: "CONOCOPHILLIPS" },
  { ticker: "COPX", name: "GLOBAL X COPPER MINERS ETF" },
  { ticker: "COR", name: "CORESITE REALTY CORP" },
  { ticker: "CORE", name: "CORE-MARK HOLDING CO INC" },
  { ticker: "CORN", name: "TEUCRIUM CORN FUND" },
  { ticker: "CORP", name: "PIMCO INV GRADE CORP BD ETF" },
  { ticker: "CORR", name: "CORENERGY INFRASTRUCTURE TRU" },
  { ticker: "CORT", name: "CORCEPT THERAPEUTICS INC" },
  { ticker: "CORV", name: "CORREVIO PHARMA CORP" },
  { ticker: "COST", name: "COSTCO WHOLESALE CORP" },
  { ticker: "COTY", name: "COTY INC-CL A" },
  { ticker: "COUP", name: "COUPA SOFTWARE INC" },
  { ticker: "COW", name: "IPATH SERIES B BLOOMBERG LIV" },
  { ticker: "COWN", name: "COWEN INC - A" },
  { ticker: "COWZ", name: "PACER US CASH COWS 100 ETF" },
  { ticker: "CP", name: "CANADIAN PACIFIC RAILWAY LTD" },
  { ticker: "CPA", name: "COPA HOLDINGS SA-CLASS A" },
  { ticker: "CPAA", name: "CONYERS PARK II ACQUISITIO-A" },
  { ticker: "CPAAU", name: "CONYERS PARK II ACQUISITION" },
  { ticker: "CPAC", name: "CEMENTOS PACASMAYO SAA - ADR" },
  { ticker: "CPAH", name: "COUNTERPATH CORP" },
  { ticker: "CPB", name: "CAMPBELL SOUP CO" },
  { ticker: "CPE", name: "CALLON PETROLEUM CO" },
  { ticker: "CPER", name: "UNITED STATES COPPER INDEX" },
  { ticker: "CPF", name: "CENTRAL PACIFIC FINANCIAL CO" },
  { ticker: "CPG", name: "CRESCENT POINT ENERGY CORP" },
  { ticker: "CPHC", name: "CANTERBURY PARK HOLDING CORP" },
  { ticker: "CPHI", name: "CHINA PHARMA HOLDINGS INC" },
  { ticker: "CPI", name: "IQ REAL RETURN ETF" },
  { ticker: "CPIX", name: "CUMBERLAND PHARMACEUTICALS" },
  { ticker: "CPK", name: "CHESAPEAKE UTILITIES CORP" },
  { ticker: "CPLG", name: "COREPOINT LODGING INC" },
  { ticker: "CPLP", name: "CAPITAL PRODUCT PARTNERS LP" },
  { ticker: "CPRI", name: "CAPRI HOLDINGS LTD" },
  { ticker: "CPRT", name: "COPART INC" },
  { ticker: "CPRX", name: "CATALYST PHARMACEUTICALS INC" },
  { ticker: "CPS", name: "COOPER-STANDARD HOLDING" },
  { ticker: "CPSH", name: "CPS TECHNOLOGIES CORP" },
  { ticker: "CPSI", name: "COMPUTER PROGRAMS & SYSTEMS" },
  { ticker: "CPSS", name: "CONSUMER PORTFOLIO SERVICES" },
  { ticker: "CPST", name: "CAPSTONE TURBINE CORP" },
  { ticker: "CPT", name: "CAMDEN PROPERTY TRUST" },
  { ticker: "CPTA", name: "CAPITALA FINANCE CORP" },
  { ticker: "CPZ", name: "CALAMOS LNG/SHRT EQ & DYN" },
  { ticker: "CQP", name: "CHENIERE ENERGY PARTNERS LP" },
  { ticker: "CQQQ", name: "INVESCO CHINA TECHNOLOGY ETF" },
  { ticker: "CR", name: "CRANE CO" },
  { ticker: "CRAI", name: "CRA INTERNATIONAL INC" },
  { ticker: "CRAK", name: "VANECK OIL REFINERS" },
  { ticker: "CRBN", name: "ISHARES MSCI ACWI LOW CARBON" },
  { ticker: "CRBP", name: "CORBUS PHARMACEUTICALS HOLDI" },
  { ticker: "CRC", name: "CALIFORNIA RESOURCES CORP" },
  { ticker: "CRD.A", name: "CRAWFORD & COMPANY -CL A" },
  { ticker: "CRD.B", name: "CRAWFORD & CO  -CL B" },
  { ticker: "CREE", name: "CREE INC" },
  { ticker: "CREG", name: "CHINA RECYCLING ENERGY CORP" },
  { ticker: "CRESY", name: "CRESUD S.A.-SPONS ADR" },
  { ticker: "CREX", name: "CREATIVE REALITIES INC" },
  { ticker: "CRF", name: "CORNERSTONE TOTAL RETURN FND" },
  { ticker: "CRH", name: "CRH PLC-SPONSORED ADR" },
  { ticker: "CRHM", name: "CRH MEDICAL CORP" },
  { ticker: "CRI", name: "CARTER'S INC" },
  { ticker: "CRIS", name: "CURIS INC" },
  { ticker: "CRK", name: "COMSTOCK RESOURCES INC" },
  { ticker: "CRL", name: "CHARLES RIVER LABORATORIES" },
  { ticker: "CRM", name: "SALESFORCE.COM INC" },
  { ticker: "CRMD", name: "CORMEDIX INC" },
  { ticker: "CRMT", name: "AMERICA'S CAR-MART INC" },
  { ticker: "CRNC", name: "CERENCE INC" },
  { ticker: "CRNT", name: "CERAGON NETWORKS LTD" },
  { ticker: "CRNX", name: "CRINETICS PHARMACEUTICALS IN" },
  { ticker: "CROC", name: "PROSHARES ULTRASHORT AUD" },
  { ticker: "CRON", name: "CRONOS GROUP INC" },
  { ticker: "CROP", name: "IQ GLOBAL AGRIBUSINESS SMALL" },
  { ticker: "CROX", name: "CROCS INC" },
  { ticker: "CRS", name: "CARPENTER TECHNOLOGY" },
  { ticker: "CRSA", name: "CRESCENT ACQUISITION CORP-A" },
  { ticker: "CRSAU", name: "CRESCENT ACQUISITION CORP" },
  { ticker: "CRSP", name: "CRISPR THERAPEUTICS AG" },
  { ticker: "CRT", name: "CROSS TIMBERS ROYALTY TRUST" },
  { ticker: "CRTO", name: "CRITEO SA-SPON ADR" },
  { ticker: "CRTX", name: "CORTEXYME INC" },
  { ticker: "CRUS", name: "CIRRUS LOGIC INC" },
  { ticker: "CRVL", name: "CORVEL CORP" },
  { ticker: "CRVS", name: "CORVUS PHARMACEUTICALS INC" },
  { ticker: "CRWD", name: "CROWDSTRIKE HOLDINGS INC - A" },
  { ticker: "CRWS", name: "CROWN CRAFTS INC" },
  { ticker: "CRY", name: "CRYOLIFE INC" },
  { ticker: "CS", name: "CREDIT SUISSE GROUP-SPON ADR" },
  { ticker: "CSA", name: "VICTORYSHARES US SMALL CAP V" },
  { ticker: "CSB", name: "VICTORYSHARES US SMALL CAP H" },
  { ticker: "CSBR", name: "CHAMPIONS ONCOLOGY INC" },
  { ticker: "CSCO", name: "CISCO SYSTEMS INC" },
  { ticker: "CSD", name: "INVESCO S&P SPIN-OFF ETF" },
  { ticker: "CSF", name: "VICTORYSHARES US DISCOVERY E" },
  { ticker: "CSFL", name: "CENTERSTATE BANK CORP" },
  { ticker: "CSGP", name: "COSTAR GROUP INC" },
  { ticker: "CSGS", name: "CSG SYSTEMS INTL INC" },
  { ticker: "CSII", name: "CARDIOVASCULAR SYSTEMS INC" },
  { ticker: "CSIQ", name: "CANADIAN SOLAR INC" },
  { ticker: "CSL", name: "CARLISLE COS INC" },
  { ticker: "CSLT", name: "CASTLIGHT HEALTH INC-B" },
  { ticker: "CSM", name: "PROSHARES LARGE CAP CORE PLU" },
  { ticker: "CSML", name: "IQ CHAIKIN SMALL CAP ETF" },
  { ticker: "CSOD", name: "CORNERSTONE ONDEMAND INC" },
  { ticker: "CSPI", name: "CSP INC" },
  { ticker: "CSPR", name: "CASPER SLEEP INC" },
  { ticker: "CSQ", name: "CALAMOS STRAT TOT RETURN FD" },
  { ticker: "CSSE", name: "CHICKEN SOUP FOR THE SOUL EN" },
  { ticker: "CSTE", name: "CAESARSTONE LTD" },
  { ticker: "CSTL", name: "CASTLE BIOSCIENCES INC" },
  { ticker: "CSTM", name: "CONSTELLIUM SE" },
  { ticker: "CSTR", name: "CAPSTAR FINANCIAL HOLDINGS I" },
  { ticker: "CSU", name: "CAPITAL SENIOR LIVING CORP" },
  { ticker: "CSV", name: "CARRIAGE SERVICES INC" },
  { ticker: "CSWC", name: "CAPITAL SOUTHWEST CORP" },
  { ticker: "CSWI", name: "CSW INDUSTRIALS INC" },
  { ticker: "CSX", name: "CSX CORP" },
  { ticker: "CTAS", name: "CINTAS CORP" },
  { ticker: "CTB", name: "COOPER TIRE & RUBBER" },
  { ticker: "CTBI", name: "COMMUNITY TRUST BANCORP INC" },
  { ticker: "CTEK", name: "CYNERGISTEK INC/DE" },
  { ticker: "CTG", name: "COMPUTER TASK GROUP INC" },
  { ticker: "CTHR", name: "CHARLES & COLVARD LTD" },
  { ticker: "CTIB", name: "CTI INDUSTRIES CORP" },
  { ticker: "CTIC", name: "CTI BIOPHARMA CORP" },
  { ticker: "CTK", name: "COOTEK CAYMAN INC-ADR" },
  { ticker: "CTL", name: "CENTURYLINK INC" },
  { ticker: "CTLT", name: "CATALENT INC" },
  { ticker: "CTMX", name: "CYTOMX THERAPEUTICS INC" },
  { ticker: "CTO", name: "CONS TOMOKA LAND CO-FLORIDA" },
  { ticker: "CTR", name: "CLEARBRIDGE MLP & MIDSTREAM" },
  { ticker: "CTRA", name: "CONTURA ENERGY INC" },
  { ticker: "CTRC", name: "CENTRIC BRANDS INC" },
  { ticker: "CTRE", name: "CARETRUST REIT INC" },
  { ticker: "CTRM", name: "CASTOR MARITIME INC" },
  { ticker: "CTRN", name: "CITI TRENDS INC" },
  { ticker: "CTS", name: "CTS CORP" },
  { ticker: "CTSH", name: "COGNIZANT TECH SOLUTIONS-A" },
  { ticker: "CTSO", name: "CYTOSORBENTS CORP" },
  { ticker: "CTST", name: "CANNTRUST HOLDINGS INC" },
  { ticker: "CTT", name: "CATCHMARK TIMBER TRUST INC-A" },
  { ticker: "CTVA", name: "CORTEVA INC" },
  { ticker: "CTXR", name: "CITIUS PHARMACEUTICALS INC" },
  { ticker: "CTXS", name: "CITRIX SYSTEMS INC" },
  { ticker: "CUB", name: "CUBIC CORP" },
  { ticker: "CUBA", name: "HERZFELD CARIBBEAN BASIN" },
  { ticker: "CUBE", name: "CUBESMART" },
  { ticker: "CUBI", name: "CUSTOMERS BANCORP INC" },
  { ticker: "CUE", name: "CUE BIOPHARMA INC" },
  { ticker: "CUI", name: "CUI GLOBAL INC" },
  { ticker: "CUK", name: "CARNIVAL PLC-ADR" },
  { ticker: "CULP", name: "CULP INC" },
  { ticker: "CUO", name: "CONTINENTAL MATERIALS CORP" },
  { ticker: "CURE", name: "DRX DLY HEALTHCARE BULL 3X" },
  { ticker: "CURO", name: "CURO GROUP HOLDINGS CORP" },
  { ticker: "CUT", name: "INVESCO MSCI GLOBAL TIMBER E" },
  { ticker: "CUTR", name: "CUTERA INC" },
  { ticker: "CUZ", name: "COUSINS PROPERTIES INC" },
  { ticker: "CVA", name: "COVANTA HOLDING CORP" },
  { ticker: "CVBF", name: "CVB FINANCIAL CORP" },
  { ticker: "CVCO", name: "CAVCO INDUSTRIES INC" },
  { ticker: "CVCY", name: "CENTRAL VALLEY COMM BANCORP" },
  { ticker: "CVE", name: "CENOVUS ENERGY INC" },
  { ticker: "CVEO", name: "CIVEO CORP" },
  { ticker: "CVET", name: "COVETRUS INC" },
  { ticker: "CVGI", name: "COMMERCIAL VEHICLE GROUP INC" },
  { ticker: "CVGW", name: "CALAVO GROWERS INC" },
  { ticker: "CVI", name: "CVR ENERGY INC" },
  { ticker: "CVIA", name: "COVIA HOLDINGS CORP" },
  { ticker: "CVLT", name: "COMMVAULT SYSTEMS INC" },
  { ticker: "CVLY", name: "CODORUS VALLEY BANCORP INC" },
  { ticker: "CVM", name: "CEL-SCI CORP" },
  { ticker: "CVNA", name: "CARVANA CO" },
  { ticker: "CVR", name: "CHICAGO RIVET & MACHINE CO" },
  { ticker: "CVS", name: "CVS HEALTH CORP" },
  { ticker: "CVTI", name: "COVENANT TRANSPORT GRP-CL A" },
  { ticker: "CVU", name: "CPI AEROSTRUCTURES INC" },
  { ticker: "CVV", name: "CVD EQUIPMENT CORP" },
  { ticker: "CVX", name: "CHEVRON CORP" },
  { ticker: "CVY", name: "INVESCO ZACKS MULTI-ASSET IN" },
  { ticker: "CW", name: "CURTISS-WRIGHT CORP" },
  { ticker: "CWB", name: "SPDR BBG BARC CONVERTIBLE" },
  { ticker: "CWBC", name: "COMMUNITY WEST BANCSHARES" },
  { ticker: "CWBR", name: "COHBAR INC" },
  { ticker: "CWCO", name: "CONSOLIDATED WATER CO-ORD SH" },
  { ticker: "CWEB", name: "DRX DLY CHINA INT BULL 2X" },
  { ticker: "CWEN", name: "CLEARWAY ENERGY INC-C" },
  { ticker: "CWEN.A", name: "CLEARWAY ENERGY INC-A" },
  { ticker: "CWH", name: "CAMPING WORLD HOLDINGS INC-A" },
  { ticker: "CWI", name: "SPDR MSCI ACWI EX-US" },
  { ticker: "CWK", name: "CUSHMAN & WAKEFIELD PLC" },
  { ticker: "CWS", name: "ADVISORSHARES EQUITY FOCUSED" },
  { ticker: "CWST", name: "CASELLA WASTE SYSTEMS INC-A" },
  { ticker: "CWT", name: "CALIFORNIA WATER SERVICE GRP" },
  { ticker: "CX", name: "CEMEX SAB-SPONS ADR PART CER" },
  { ticker: "CXDC", name: "CHINA XD PLASTICS CO LTD" },
  { ticker: "CXE", name: "MFS HIGH INCOME MUNICIPAL TR" },
  { ticker: "CXH", name: "MFS INVESTMENT GRADE MUNICIP" },
  { ticker: "CXO", name: "CONCHO RESOURCES INC" },
  { ticker: "CXP", name: "COLUMBIA PROPERTY TRUST INC" },
  { ticker: "CXSE", name: "WISDOMTREE CHINA EX-ST OW" },
  { ticker: "CXW", name: "CORECIVIC INC" },
  { ticker: "CY", name: "CYPRESS SEMICONDUCTOR CORP" },
  { ticker: "CYAD", name: "CELYAD -SPON ADR" },
  { ticker: "CYAN", name: "CYANOTECH CORP" },
  { ticker: "CYB", name: "WISDOMTREE CHINESE YUAN STRA" },
  { ticker: "CYBE", name: "CYBEROPTICS CORP" },
  { ticker: "CYBR", name: "CYBERARK SOFTWARE LTD/ISRAEL" },
  { ticker: "CYCC", name: "CYCLACEL PHARMACEUTICALS INC" },
  { ticker: "CYCN", name: "CYCLERION THERAPEUTICS INC" },
  { ticker: "CYD", name: "CHINA YUCHAI INTL LTD" },
  { ticker: "CYH", name: "COMMUNITY HEALTH SYSTEMS INC" },
  { ticker: "CYOU", name: "CHANGYOU.COM LTD-ADR" },
  { ticker: "CYRN", name: "CYREN LTD" },
  { ticker: "CYRX", name: "CRYOPORT INC" },
  { ticker: "CYTK", name: "CYTOKINETICS INC" },
  { ticker: "CZA", name: "INVESCO ZACKS MID-CAP ETF" },
  { ticker: "CZNC", name: "CITIZENS & NORTHERN CORP" },
  { ticker: "CZR", name: "CAESARS ENTERTAINMENT CORP" },
  { ticker: "CZWI", name: "CITIZENS COMMUNITY BANCORP I" },
  { ticker: "CZZ", name: "COSAN LTD-CLASS A SHARES" },
  { ticker: "D", name: "DOMINION ENERGY INC" },
  { ticker: "DAC", name: "DANAOS CORP" },
  { ticker: "DAIO", name: "DATA I/O CORP" },
  { ticker: "DAKT", name: "DAKTRONICS INC" },
  { ticker: "DAL", name: "DELTA AIR LINES INC" },
  { ticker: "DALI", name: "FIRST TRUST DORSEYWRIGHT DAL" },
  { ticker: "DALT", name: "ANFIELD CAPITAL DIVERSIFIED" },
  { ticker: "DAN", name: "DANA INC" },
  { ticker: "DAO", name: "YOUDAO INC" },
  { ticker: "DAR", name: "DARLING INGREDIENTS INC" },
  { ticker: "DARE", name: "DARE BIOSCIENCE INC" },
  { ticker: "DAUD", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "DAUG", name: "FT CBOE US EQUITY DEEP BUFFE" },
  { ticker: "DAVA", name: "ENDAVA PLC- SPON ADR" },
  { ticker: "DAX", name: "GLOBAL X DAX GERMANY ETF" },
  { ticker: "DB", name: "DEUTSCHE BANK AG-REGISTERED" },
  { ticker: "DBA", name: "INVESCO DB AGRICULTURE FUND" },
  { ticker: "DBAW", name: "XTRACKERS MSCI ALL WORLD EX" },
  { ticker: "DBB", name: "INVESCO DB BASE METALS FUND" },
  { ticker: "DBC", name: "INVESCO DB COMMODITY INDEX T" },
  { ticker: "DBD", name: "DIEBOLD NIXDORF INC" },
  { ticker: "DBE", name: "INVESCO DB ENERGY FUND" },
  { ticker: "DBEF", name: "XTRACKERS MSCI EAFE HEDGED E" },
  { ticker: "DBEH", name: "IM DBI HEDGE STRATEGY ETF" },
  { ticker: "DBEM", name: "XTRACKERS MSCI EMERGING MARK" },
  { ticker: "DBEU", name: "XTRACKERS MSCI EUROPE HEDGED" },
  { ticker: "DBEZ", name: "XTRACKERS MSCI EUROZONE HEDG" },
  { ticker: "DBGR", name: "XTRACKERS MSCI GERMANY HEDGE" },
  { ticker: "DBI", name: "DESIGNER BRANDS INC-CLASS A" },
  { ticker: "DBJP", name: "XTRACKERS MSCI JAPAN HEDGED" },
  { ticker: "DBL", name: "DOUBLELINE OPPORT CREDIT" },
  { ticker: "DBLV", name: "ADVISORSHARES DOUBLELINE VAL" },
  { ticker: "DBMF", name: "IM DBI MANAGED FUTURES ETF" },
  { ticker: "DBO", name: "INVESCO DB OIL FUND" },
  { ticker: "DBP", name: "INVESCO DB PRECIOUS METALS F" },
  { ticker: "DBS", name: "INVESCO DB SILVER FUND" },
  { ticker: "DBV", name: "INVESCO DB G10 CURRENCY HARV" },
  { ticker: "DBVT", name: "DBV TECHNOLOGIES SA-SPON ADR" },
  { ticker: "DBX", name: "DROPBOX INC-CLASS A" },
  { ticker: "DCAR", name: "DROPCAR INC" },
  { ticker: "DCF", name: "BNY MELLON ALCENTRA GLOBAL C" },
  { ticker: "DCHF", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "DCI", name: "DONALDSON CO INC" },
  { ticker: "DCIX", name: "PERFORMANCE SHIPPING INC" },
  { ticker: "DCO", name: "DUCOMMUN INC" },
  { ticker: "DCOM", name: "DIME COMMUNITY BANCSHARES" },
  { ticker: "DCP", name: "DCP MIDSTREAM LP" },
  { ticker: "DCPH", name: "DECIPHERA PHARMACEUTICALS IN" },
  { ticker: "DD", name: "DUPONT DE NEMOURS INC" },
  { ticker: "DDD", name: "3D SYSTEMS CORP" },
  { ticker: "DDF", name: "DELAWARE INV DIVIDEND & INC" },
  { ticker: "DDG", name: "PROSHARES SHORT OIL & GAS" },
  { ticker: "DDIV", name: "FIRST TRUST DORSEY WRIGHT MO" },
  { ticker: "DDLS", name: "WISDOMTREE DY CUR HDG SCP EQ" },
  { ticker: "DDM", name: "PROSHARES ULTRA DOW30" },
  { ticker: "DDMX", name: "DD3 ACQUISITION CORP" },
  { ticker: "DDMXU", name: "DD3 ACQUISITION CORP" },
  { ticker: "DDOG", name: "DATADOG INC - CLASS A" },
  { ticker: "DDS", name: "DILLARDS INC-CL A" },
  { ticker: "DDWM", name: "WISDOMTREE DY CUR HDG INTL" },
  { ticker: "DE", name: "DEERE & CO" },
  { ticker: "DEA", name: "EASTERLY GOVERNMENT PROPERTI" },
  { ticker: "DEAC", name: "DIAMOND EAGLE ACQUISIT-CL A" },
  { ticker: "DEACU", name: "DIAMOND EAGLE ACQUISITION CO" },
  { ticker: "DECK", name: "DECKERS OUTDOOR CORP" },
  { ticker: "DEEF", name: "XTRACKERS FTSE DEVELOPED EX" },
  { ticker: "DEF", name: "INVESCO DEFENSIVE EQUITY ETF" },
  { ticker: "DEFA", name: "ISHARES ADPT CUR HDG EAFE ET" },
  { ticker: "DEI", name: "DOUGLAS EMMETT INC" },
  { ticker: "DELL", name: "DELL TECHNOLOGIES -C" },
  { ticker: "DEM", name: "WISDOMTREE EMERGING MARKETS" },
  { ticker: "DENN", name: "DENNY'S CORP" },
  { ticker: "DEO", name: "DIAGEO PLC-SPONSORED ADR" },
  { ticker: "DES", name: "WISDOMTREE US SMALLCAP DIVID" },
  { ticker: "DESP", name: "DESPEGAR.COM CORP" },
  { ticker: "DEUR", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "DEUS", name: "XTRACKERS RUSSELL 1000 COMPR" },
  { ticker: "DEW", name: "WISDOMTREE GLOBAL HIGH DIVID" },
  { ticker: "DEX", name: "DELAWARE ENHANCED GLOBAL DIV" },
  { ticker: "DFE", name: "WISDOMTREE EUR S/C DIVIDEND" },
  { ticker: "DFEB", name: "FT CBOE VEST US EQY DEEP BUF" },
  { ticker: "DFEN", name: "DRX DLY AERO & DEF BULL 3X" },
  { ticker: "DFFN", name: "DIFFUSION PHARMACEUTICALS IN" },
  { ticker: "DFIN", name: "DONNELLEY FINANCIAL SOLUTION" },
  { ticker: "DFJ", name: "WISDOMTREE JPN S/C DVD FUND" },
  { ticker: "DFND", name: "REALITY SHRS DIVCON DEF ETF" },
  { ticker: "DFNL", name: "DAVIS SELECT FINANCIAL ETF" },
  { ticker: "DFNS=", name: "LGL SYSTEMS ACQUISITION CORP" },
  { ticker: "DFP", name: "FLAHERTY & CRUMRINE DYNAMIC" },
  { ticker: "DFS", name: "DISCOVER FINANCIAL SERVICES" },
  { ticker: "DFVL", name: "IPATH US TREASURY 5-YR BULL" },
  { ticker: "DFVS", name: "IPATH US TREASURY 5 YR BEAR" },
  { ticker: "DG", name: "DOLLAR GENERAL CORP" },
  { ticker: "DGAZ", name: "VELOCITYSHARES 3X INVERSE NA" },
  { ticker: "DGBP", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "DGICA", name: "DONEGAL GROUP INC-CL A" },
  { ticker: "DGICB", name: "DONEGAL GROUP INC-B" },
  { ticker: "DGII", name: "DIGI INTERNATIONAL INC" },
  { ticker: "DGL", name: "INVESCO DB GOLD FUND" },
  { ticker: "DGLD", name: "VELOCITYSHARES 3X INVERSE GO" },
  { ticker: "DGLY", name: "DIGITAL ALLY INC" },
  { ticker: "DGP", name: "DB GOLD DOUBLE LONG ETN" },
  { ticker: "DGRE", name: "WISDOMTREE EMERGING MARKETS" },
  { ticker: "DGRO", name: "ISHARES CORE DIVIDEND GROWTH" },
  { ticker: "DGRS", name: "WISDOMTREE U.S. SMALLCAP QUA" },
  { ticker: "DGRW", name: "WISDOMTREE U.S. QUALITY DIVI" },
  { ticker: "DGS", name: "WISDOMTREE EM SMALL CAP" },
  { ticker: "DGT", name: "SPDR GLOBAL DOW ETF" },
  { ticker: "DGX", name: "QUEST DIAGNOSTICS INC" },
  { ticker: "DGZ", name: "DB GOLD SHORT ETN" },
  { ticker: "DHC", name: "DIVERSIFIED HEALTHCARE TRUST" },
  { ticker: "DHDG", name: "WISDOMTREE DYN CURR HDG QDIV" },
  { ticker: "DHF", name: "BNYM HIGH YIELD STRAT" },
  { ticker: "DHI", name: "DR HORTON INC" },
  { ticker: "DHIL", name: "DIAMOND HILL INVESTMENT GRP" },
  { ticker: "DHR", name: "DANAHER CORP" },
  { ticker: "DHS", name: "WISDOMTREE US HIGH DIVIDEND" },
  { ticker: "DHT", name: "DHT HOLDINGS INC" },
  { ticker: "DHX", name: "DHI GROUP INC" },
  { ticker: "DHY", name: "CREDIT SUISSE HIGH YIELD BD" },
  { ticker: "DIA", name: "SPDR DJIA TRUST" },
  { ticker: "DIAL", name: "COLUMBIA DIVERSIFIED ETF" },
  { ticker: "DIAX", name: "NUVEEN DOW30 DYN OVERWRT FD" },
  { ticker: "DIET", name: "DEFIANCE NEXT GEN FOOD&AGRIC" },
  { ticker: "DIG", name: "PROSHARES ULTRA OIL & GAS" },
  { ticker: "DIM", name: "WISDOMTREE INTL M/C DVD FUND" },
  { ticker: "DIN", name: "DINE BRANDS GLOBAL INC" },
  { ticker: "DINT", name: "DAVIS SELECT INTERNATIONAL" },
  { ticker: "DIOD", name: "DIODES INC" },
  { ticker: "DIS", name: "WALT DISNEY CO/THE" },
  { ticker: "DISCA", name: "DISCOVERY INC - A" },
  { ticker: "DISCB", name: "DISCOVERY INC-B" },
  { ticker: "DISCK", name: "DISCOVERY INC-C" },
  { ticker: "DISH", name: "DISH NETWORK CORP-A" },
  { ticker: "DIT", name: "AMCON DISTRIBUTING CO" },
  { ticker: "DIV", name: "GLOBAL X SUPERDIVIDEND USE" },
  { ticker: "DIVA", name: "AGFIQ HEDGED DIVIDEND INCOME" },
  { ticker: "DIVB", name: "ISHARES US DVD AND BUYBACK" },
  { ticker: "DIVC", name: "C-TRACKS ETN MILLER/HOWARD" },
  { ticker: "DIVO", name: "AMPLIFY CWP ENHANCED DIVIDEN" },
  { ticker: "DIVY", name: "REALITY SHARES DIVS ETF" },
  { ticker: "DJCB", name: "ETRACS BLOOMBERG CMD INX-S/B" },
  { ticker: "DJCI", name: "E-TRACS BLOOMBERG COMMODITY" },
  { ticker: "DJCO", name: "DAILY JOURNAL CORP" },
  { ticker: "DJD", name: "INVESCO DOW JONES INDUSTRIAL" },
  { ticker: "DJP", name: "IPATH BLOOMBERG COMMODITY IN" },
  { ticker: "DJPY", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "DK", name: "DELEK US HOLDINGS INC" },
  { ticker: "DKL", name: "DELEK LOGISTICS PARTNERS LP" },
  { ticker: "DKS", name: "DICK'S SPORTING GOODS INC" },
  { ticker: "DL", name: "CHINA DISTANCE EDUCATION-ADR" },
  { ticker: "DLA", name: "DELTA APPAREL INC" },
  { ticker: "DLB", name: "DOLBY LABORATORIES INC-CL A" },
  { ticker: "DLBR", name: "VELOCITYSHARES SHRT LBR ETN" },
  { ticker: "DLHC", name: "DLH HOLDINGS CORP" },
  { ticker: "DLN", name: "WISDOMTREE US LARGECAP DIVID" },
  { ticker: "DLNG", name: "DYNAGAS LNG PARTNERS LP" },
  { ticker: "DLPH", name: "DELPHI TECHNOLOGIES PLC" },
  { ticker: "DLPN", name: "DOLPHIN ENTERTAINMENT INC" },
  { ticker: "DLR", name: "DIGITAL REALTY TRUST INC" },
  { ticker: "DLS", name: "WISDOMTREE INTL S/C DVD FUND" },
  { ticker: "DLTH", name: "DULUTH HOLDINGS INC - CL B" },
  { ticker: "DLTR", name: "DOLLAR TREE INC" },
  { ticker: "DLX", name: "DELUXE CORP" },
  { ticker: "DLY", name: "DOUBLELINE YIELD OPPORT" },
  { ticker: "DMAC", name: "DIAMEDICA THERAPEUTICS INC" },
  { ticker: "DMB", name: "BNYM MUNI BND INFRA" },
  { ticker: "DMDV", name: "AAM S&P DEVELOPED MARKETS HI" },
  { ticker: "DMF", name: "BNYM MUNICIPAL INCOME" },
  { ticker: "DMLP", name: "DORCHESTER MINERALS LP" },
  { ticker: "DMO", name: "WESTERN ASSET MORTGAGE OPPOR" },
  { ticker: "DMPI", name: "DELMAR PHARMACEUTICALS INC" },
  { ticker: "DMRC", name: "DIGIMARC CORP" },
  { ticker: "DMRE", name: "DELTASHARES EM MGD RISK ETF" },
  { ticker: "DMRI", name: "DELTASHARES S&P INT MGD RISK" },
  { ticker: "DMRL", name: "DELTASHARES S&P 500 MGD RISK" },
  { ticker: "DMRM", name: "DELTASHARES S&P 400 MGD RISK" },
  { ticker: "DMRS", name: "DELTASHARES S&P 600 MGD RISK" },
  { ticker: "DMTK", name: "DERMTECH INC" },
  { ticker: "DMYT=", name: "DMY TECHNOLOGY GROUP INC" },
  { ticker: "DNI", name: "DIVIDEND AND INCOME FUND" },
  { ticker: "DNJR", name: "GOLDEN BULL LTD" },
  { ticker: "DNK", name: "PHOENIX TREE HOLDINGS LTD" },
  { ticker: "DNKN", name: "DUNKIN' BRANDS GROUP INC" },
  { ticker: "DNL", name: "WISDOMTREE GLOBAL EX-US QUAL" },
  { ticker: "DNLI", name: "DENALI THERAPEUTICS INC" },
  { ticker: "DNN", name: "DENISON MINES CORP" },
  { ticker: "DNOV", name: "FT CBOE VEST US EQUITY DEEP" },
  { ticker: "DNOW", name: "NOW INC" },
  { ticker: "DNP", name: "DNP SELECT INCOME FUND INC" },
  { ticker: "DNR", name: "DENBURY RESOURCES INC" },
  { ticker: "DO", name: "DIAMOND OFFSHORE DRILLING" },
  { ticker: "DOC", name: "PHYSICIANS REALTY TRUST" },
  { ticker: "DOCU", name: "DOCUSIGN INC" },
  { ticker: "DOG", name: "PROSHARES SHORT DOW30" },
  { ticker: "DOGS", name: "ARROW DOGS OF THE WORLD ETF" },
  { ticker: "DOGZ", name: "DOGNESS INTERNATIONAL CORP-A" },
  { ticker: "DOL", name: "WISDOMTREE INTL L/C DVD FUND" },
  { ticker: "DOMO", name: "DOMO INC - CLASS B" },
  { ticker: "DON", name: "WISDOMTREE US MIDCAP DIVIDEN" },
  { ticker: "DOO", name: "WISDOMTREE INTL DVD EX-FIN" },
  { ticker: "DOOO", name: "BRP INC/CA- SUB VOTING" },
  { ticker: "DOOR", name: "MASONITE INTERNATIONAL CORP" },
  { ticker: "DORM", name: "DORMAN PRODUCTS INC" },
  { ticker: "DOV", name: "DOVER CORP" },
  { ticker: "DOW", name: "DOW INC" },
  { ticker: "DOX", name: "AMDOCS LTD" },
  { ticker: "DOYU", name: "DOUYU INTERNATIONAL HOLD-ADR" },
  { ticker: "DPG", name: "DUFF & PHELPS UTILITY & INC" },
  { ticker: "DPHC", name: "DIAMONDPEAK HOLDINGS CORP-A" },
  { ticker: "DPHCU", name: "DIAMONDPEAK HOLDINGS CORP" },
  { ticker: "DPK", name: "DIREXION DLY DEV MKT BEAR 3X" },
  { ticker: "DPST", name: "DRX DLY REG BANK BULL 3X" },
  { ticker: "DPW", name: "DPW HOLDINGS INC" },
  { ticker: "DPZ", name: "DOMINO'S PIZZA INC" },
  { ticker: "DQ", name: "DAQO NEW ENERGY CORP-ADR" },
  { ticker: "DRAD", name: "DIGIRAD CORP" },
  { ticker: "DRD", name: "DRDGOLD LTD-SPONSORED ADR" },
  { ticker: "DRE", name: "DUKE REALTY CORP" },
  { ticker: "DRH", name: "DIAMONDROCK HOSPITALITY CO" },
  { ticker: "DRI", name: "DARDEN RESTAURANTS INC" },
  { ticker: "DRIO", name: "DARIOHEALTH CORP" },
  { ticker: "DRIP", name: "DRX DLY OIL & GAS BEAR 3X" },
  { ticker: "DRIV", name: "GLOBAL X AUTONOMOUS&ELEC-ETF" },
  { ticker: "DRN", name: "DRX DLY REAL ESTATE BULL 3X" },
  { ticker: "DRNA", name: "DICERNA PHARMACEUTICALS INC" },
  { ticker: "DRQ", name: "DRIL-QUIP INC" },
  { ticker: "DRR", name: "MARKET VECTORS DBL SHORT EUR" },
  { ticker: "DRRX", name: "DURECT CORPORATION" },
  { ticker: "DRSK", name: "APTUS DEFINED RISK ETF" },
  { ticker: "DRTT", name: "DIRTT ENVIRONMENTAL SOLUTION" },
  { ticker: "DRV", name: "DRX DLY REAL ESTATE BEAR 3X" },
  { ticker: "DRW", name: "WISDOMTREE GLOBAL EX-US REAL" },
  { ticker: "DS", name: "DRIVE SHACK INC" },
  { ticker: "DSE", name: "DUFF & PHELPS SEL MLP & MIDS" },
  { ticker: "DSGX", name: "DESCARTES SYSTEMS GRP/THE" },
  { ticker: "DSI", name: "ISHARES MSCI KLD 400 SOCIAL" },
  { ticker: "DSKE", name: "DASEKE INC" },
  { ticker: "DSL", name: "DOUBLELINE INCOME SOLUTIONS" },
  { ticker: "DSLV", name: "VELOCITYSHARES 3X INVERSE SI" },
  { ticker: "DSM", name: "BNYM STRAT MUNI BND" },
  { ticker: "DSPG", name: "DSP GROUP INC" },
  { ticker: "DSS", name: "DOCUMENT SECURITY SYSTEMS" },
  { ticker: "DSSI", name: "DIAMOND S SHIPPING INC" },
  { ticker: "DSTL", name: "DISTILLATE US FUNDAMENTAL ST" },
  { ticker: "DSU", name: "BLACKROCK DEBT STRATEGIES FD" },
  { ticker: "DSWL", name: "DESWELL INDUSTRIES INC" },
  { ticker: "DSX", name: "DIANA SHIPPING INC" },
  { ticker: "DT", name: "DYNATRACE INC" },
  { ticker: "DTD", name: "WISDOMTREE US TOTAL DIVIDEND" },
  { ticker: "DTE", name: "DTE ENERGY COMPANY" },
  { ticker: "DTEA", name: "DAVIDSTEA INC" },
  { ticker: "DTEC", name: "ALPS DISRUPTIVE TECHNOLOGIES" },
  { ticker: "DTF", name: "DTF TAX-FREE INCOME INC" },
  { ticker: "DTH", name: "WISDOMTREE INTERNATIONAL HIG" },
  { ticker: "DTIL", name: "PRECISION BIOSCIENCES INC" },
  { ticker: "DTN", name: "WISDOMTREE US DIVIDEND EX-FI" },
  { ticker: "DTO", name: "DB CRUDE OIL DOUBLE SHORT" },
  { ticker: "DTSS", name: "DATASEA INC" },
  { ticker: "DTUL", name: "IPATH US TSY 2Y BULL" },
  { ticker: "DTUS", name: "IPATH US TSY 2Y BEAR" },
  { ticker: "DTYL", name: "IPATH US TSY 10Y BULL" },
  { ticker: "DTYS", name: "IPATH US TSY 10Y BEAR" },
  { ticker: "DUC", name: "DUFF & PHELPS UTIL & CORP BD" },
  { ticker: "DUG", name: "PROSHARES ULTRASHORT OIL&GAS" },
  { ticker: "DUK", name: "DUKE ENERGY CORP" },
  { ticker: "DUO", name: "FANGDD NETWORK GROUP LTD-ADR" },
  { ticker: "DUOT", name: "DUOS TECHNOLOGIES GROUP INC" },
  { ticker: "DURA", name: "VANECK MSTAR DURABLE DIVIDND" },
  { ticker: "DUSA", name: "DAVIS SELECT US EQUITY ETF" },
  { ticker: "DUSL", name: "DRX DLY INDUSTRIALS BULL 3X" },
  { ticker: "DUST", name: "DRX DLY GOLD MINERS BEAR 3X" },
  { ticker: "DVA", name: "DAVITA INC" },
  { ticker: "DVAX", name: "DYNAVAX TECHNOLOGIES CORP" },
  { ticker: "DVD", name: "DOVER MOTORSPORTS INC" },
  { ticker: "DVEM", name: "WISDOMTREE EM MKT DVD ETF" },
  { ticker: "DVHL", name: "ETRACS MON PAY 2XLEV HI INC" },
  { ticker: "DVLU", name: "FIRST TRUST DRSY WRIGHT MOME" },
  { ticker: "DVN", name: "DEVON ENERGY CORP" },
  { ticker: "DVOL", name: "FIRST TRUST DW MOMTM & LOW" },
  { ticker: "DVOP", name: "SOURCE DIVIDEND OPPORTUNITY" },
  { ticker: "DVP", name: "DEEP VALUE ETF" },
  { ticker: "DVY", name: "ISHARES SELECT DIVIDEND ETF" },
  { ticker: "DVYA", name: "ISHARES ASIA/PACIFIC DIVIDEN" },
  { ticker: "DVYE", name: "ISHARES EMERGING MARKETS DIV" },
  { ticker: "DVYL", name: "ETRACS 2X DJ SEL DVD ETN" },
  { ticker: "DWAS", name: "INVESCO DWA SMALLCAP MOMENT" },
  { ticker: "DWAT", name: "ARROW DWA TACTICAL ETF" },
  { ticker: "DWAW", name: "ADVISORSHARES D/W FSM CAP" },
  { ticker: "DWCR", name: "ARROW DWA COUNTRY ROTATION E" },
  { ticker: "DWEQ", name: "ADVISORSHARES D/W ALPH EQ WT" },
  { ticker: "DWFI", name: "SPDR DORSEY WRIGHT FI ALLOC" },
  { ticker: "DWLD", name: "DAVIS SELECT WORLDWIDE ETF" },
  { ticker: "DWM", name: "WISDOMTREE INTERNATIONAL EQU" },
  { ticker: "DWMC", name: "ADVISORSHARES DORSEY MC ETF" },
  { ticker: "DWMF", name: "WISDOMTREE INTERNATIONAL MUL" },
  { ticker: "DWPP", name: "FIRST TRUST DORSEY WRIGHT PE" },
  { ticker: "DWSH", name: "AdvisorShares Dorsey WS ETF" },
  { ticker: "DWSN", name: "DAWSON GEOPHYSICAL CO" },
  { ticker: "DWT", name: "VELOCITYSHARES 3X INV CRUDE" },
  { ticker: "DWUS", name: "ADVISORSHARES D/W FSM CORE" },
  { ticker: "DWX", name: "SPDR S&P INTER DVD ETF" },
  { ticker: "DX", name: "DYNEX CAPITAL INC" },
  { ticker: "DXC", name: "DXC TECHNOLOGY CO" },
  { ticker: "DXCM", name: "DEXCOM INC" },
  { ticker: "DXD", name: "PROSHARES ULTRASHORT DOW30" },
  { ticker: "DXF", name: "DUNXIN FINANCIAL HOLDING-ADR" },
  { ticker: "DXGE", name: "WISDOMTREE GERMANY HEDGED EQ" },
  { ticker: "DXJ", name: "WISDOMTREE JAPAN HEDGED EQ" },
  { ticker: "DXJS", name: "WISDOMTREE JAPAN HEDGED SMAL" },
  { ticker: "DXLG", name: "DESTINATION XL GROUP INC" },
  { ticker: "DXPE", name: "DXP ENTERPRISES INC" },
  { ticker: "DXR", name: "DAXOR CORP" },
  { ticker: "DXYN", name: "DIXIE GROUP INC" },
  { ticker: "DY", name: "DYCOM INDUSTRIES INC" },
  { ticker: "DYAI", name: "DYADIC INTERNATIONAL INC" },
  { ticker: "DYLS", name: "WISDOMTREE DYN L/S US EQ ETF" },
  { ticker: "DYNF", name: "BLACKROCK US EQY FCTR ROTATE" },
  { ticker: "DYNT", name: "DYNATRONICS CORP" },
  { ticker: "DZK", name: "DIREXION DLY DEV MKT BULL 3X" },
  { ticker: "DZSI", name: "DASAN ZHONE SOLUTIONS INC" },
  { ticker: "DZZ", name: "DB GOLD DOUBLE SHORT ETN" },
  { ticker: "E", name: "ENI SPA-SPONSORED ADR" },
  { ticker: "EA", name: "ELECTRONIC ARTS INC" },
  { ticker: "EAD", name: "WELLS FARGO INCOME OPPORTUNI" },
  { ticker: "EAF", name: "GRAFTECH INTERNATIONAL LTD" },
  { ticker: "EAGG", name: "ISHARES ESG US AGGREGATE BON" },
  { ticker: "EARN", name: "ELLINGTON RESIDENTIAL MORTGA" },
  { ticker: "EARS", name: "AURIS MEDICAL HOLDING LTD" },
  { ticker: "EASG", name: "XTRACKERS MSCI EAFE ESG LDRS" },
  { ticker: "EASI", name: "AMPLIFY EASI TAC GROWTH ETF" },
  { ticker: "EAST", name: "EASTSIDE DISTILLING INC" },
  { ticker: "EAT", name: "BRINKER INTERNATIONAL INC" },
  { ticker: "EB", name: "EVENTBRITE INC-CLASS A" },
  { ticker: "EBAY", name: "EBAY INC" },
  { ticker: "EBF", name: "ENNIS INC" },
  { ticker: "EBIX", name: "EBIX INC" },
  { ticker: "EBIZ", name: "GLOBAL X E-COMMERCE ETF" },
  { ticker: "EBMT", name: "EAGLE BANCORP MONTANA INC" },
  { ticker: "EBND", name: "SPDR BBG BARC EM LOCAL BOND" },
  { ticker: "EBR", name: "CENTRAIS ELETRICAS BR-SP ADR" },
  { ticker: "EBR.B", name: "CENTRAIS ELEC BRAS-ADR PREF" },
  { ticker: "EBS", name: "EMERGENT BIOSOLUTIONS INC" },
  { ticker: "EBSB", name: "MERIDIAN BANCORP INC" },
  { ticker: "EBTC", name: "ENTERPRISE BANCORP INC" },
  { ticker: "EC", name: "ECOPETROL SA-SPONSORED ADR" },
  { ticker: "ECC", name: "EAGLE POINT CREDIT CO INC" },
  { ticker: "ECF", name: "ELLSWORTH GROWTH AND INCOME" },
  { ticker: "ECH", name: "ISHARES MSCI CHILE ETF" },
  { ticker: "ECHO", name: "ECHO GLOBAL LOGISTICS INC" },
  { ticker: "ECL", name: "ECOLAB INC" },
  { ticker: "ECLN", name: "FIRST TRUST EIP CARBON IMPAC" },
  { ticker: "ECNS", name: "ISHARES MSCI CHINA SMALL-CAP" },
  { ticker: "ECOL", name: "US ECOLOGY INC" },
  { ticker: "ECOM", name: "CHANNELADVISOR CORP" },
  { ticker: "ECON", name: "COLUMBIA EMERGING MARKETS CO" },
  { ticker: "ECOR", name: "ELECTROCORE INC" },
  { ticker: "ECOW", name: "PACER EMERGING MARKETS CASH" },
  { ticker: "ECOZ", name: "TRUEMARK ESG ACTIVE OPPORTUN" },
  { ticker: "ECPG", name: "ENCORE CAPITAL GROUP INC" },
  { ticker: "ECT", name: "ECA MARCELLUS TRUST I" },
  { ticker: "ED", name: "CONSOLIDATED EDISON INC" },
  { ticker: "EDAP", name: "EDAP TMS SA -ADR" },
  { ticker: "EDC", name: "DIREXION DLY EMG MKT BULL 3X" },
  { ticker: "EDD", name: "MORGAN STANLEY EMERGING MARK" },
  { ticker: "EDEN", name: "ISHARES MSCI DENMARK ETF" },
  { ticker: "EDF", name: "STONE HARBOR EMER MKT INC" },
  { ticker: "EDI", name: "STONE HARBOR EM MKT TOTL INC" },
  { ticker: "EDIT", name: "EDITAS MEDICINE INC" },
  { ticker: "EDIV", name: "SPDR S&P EMERGING MARKETS DI" },
  { ticker: "EDN", name: "EMP DISTRIB Y COMERC NOR-ADR" },
  { ticker: "EDNT", name: "EDISON NATION INC" },
  { ticker: "EDOG", name: "ALPS EMERGING SECTOR DIVIDEN" },
  { ticker: "EDOW", name: "FIRST TRUST DOW 30 EQL WGHT" },
  { ticker: "EDRY", name: "EURODRY LTD" },
  { ticker: "EDSA", name: "EDESA BIOTECH INC" },
  { ticker: "EDTX", name: "EDTECHX HOLDINGS ACQUISITION" },
  { ticker: "EDTXU", name: "EDTECHX HOLDINGS ACQUISITION" },
  { ticker: "EDU", name: "NEW ORIENTAL EDUCATIO-SP ADR" },
  { ticker: "EDUC", name: "EDUCATIONAL DEVELOPMENT CORP" },
  { ticker: "EDV", name: "VANGUARD EXTENDED DUR TREAS" },
  { ticker: "EDZ", name: "DIREXION DLY EMG MKT BEAR 3X" },
  { ticker: "EE", name: "EL PASO ELECTRIC CO" },
  { ticker: "EEA", name: "EUROPEAN EQUITY FUND INC/THE" },
  { ticker: "EEFT", name: "EURONET WORLDWIDE INC" },
  { ticker: "EEH", name: "ELEMENTS SPECTRUM ETN" },
  { ticker: "EELV", name: "INVESCO S&P EMERGING MARKETS" },
  { ticker: "EEM", name: "ISHARES MSCI EMERGING MARKET" },
  { ticker: "EEMA", name: "ISHARES MSCI EMERG MRKT ASIA" },
  { ticker: "EEMD", name: "AAM S&P EM HIGH DIV VAL ETF" },
  { ticker: "EEMO", name: "INVESCO S&P EMERGING MARKETS" },
  { ticker: "EEMS", name: "ISHARES MSCI EMERGING MKT SM" },
  { ticker: "EEMV", name: "ISHARES EDGE MSCI MIN VOL EM" },
  { ticker: "EEMX", name: "SPDR MSCI EM FSL FL RSV FREE" },
  { ticker: "EES", name: "WISDOMTREE US SMALLCAP FUND" },
  { ticker: "EET", name: "PROSHARES ULT MSCI EMER MKTS" },
  { ticker: "EEV", name: "PROSHARES ULTSHRT MSCI EM" },
  { ticker: "EEX", name: "EMERALD HOLDING INC" },
  { ticker: "EFA", name: "ISHARES MSCI EAFE ETF" },
  { ticker: "EFAD", name: "PROSHARES EAFE DVD GROWERS" },
  { ticker: "EFAS", name: "GLOBAL X MSCI SUPERDIVIDEND" },
  { ticker: "EFAV", name: "ISHARES EDGE MSCI MIN VOL EA" },
  { ticker: "EFAX", name: "SPDR MSCI EAFA FOSSIL FUEL" },
  { ticker: "EFC", name: "ELLINGTON FINANCIAL INC" },
  { ticker: "EFF", name: "EATON VANCE FLOATING-RATE IN" },
  { ticker: "EFG", name: "ISHARES MSCI EAFE GROWTH ETF" },
  { ticker: "EFL", name: "EATON VANCE FLOATING-RATE 20" },
  { ticker: "EFNL", name: "ISHARES MSCI FINLAND ETF" },
  { ticker: "EFO", name: "PROSHARES ULTRA MSCI EAFE" },
  { ticker: "EFOI", name: "ENERGY FOCUS INC" },
  { ticker: "EFR", name: "EATON VANCE SR FLTG RATE TR" },
  { ticker: "EFSC", name: "ENTERPRISE FINANCIAL SERVICE" },
  { ticker: "EFT", name: "EATON VANCE FLOAT RT INC TR" },
  { ticker: "EFU", name: "PROSHARES ULTSHRT MSCI EAFE" },
  { ticker: "EFV", name: "ISHARES MSCI EAFE VALUE ETF" },
  { ticker: "EFX", name: "EQUIFAX INC" },
  { ticker: "EFZ", name: "PROSHARES SHORT MSCI EAFE" },
  { ticker: "EGAN", name: "EGAIN CORP" },
  { ticker: "EGBN", name: "EAGLE BANCORP INC" },
  { ticker: "EGF", name: "BLACKROCK ENHANCED GOVT FUND" },
  { ticker: "EGHT", name: "8X8 INC" },
  { ticker: "EGIF", name: "EAGLE GROWTH & INCOME OPPORT" },
  { ticker: "EGLE", name: "EAGLE BULK SHIPPING INC" },
  { ticker: "EGO", name: "ELDORADO GOLD CORP" },
  { ticker: "EGOV", name: "NIC INC" },
  { ticker: "EGP", name: "EASTGROUP PROPERTIES INC" },
  { ticker: "EGPT", name: "VANECK EGYPT" },
  { ticker: "EGRX", name: "EAGLE PHARMACEUTICALS INC" },
  { ticker: "EGY", name: "VAALCO ENERGY INC" },
  { ticker: "EH", name: "EHANG HOLDINGS LTD-SPS ADR" },
  { ticker: "EHC", name: "ENCOMPASS HEALTH CORP" },
  { ticker: "EHI", name: "WESTERN ASSET GLOBAL HIGH IN" },
  { ticker: "EHT", name: "EATON VANCE HIGH INCOME 2021" },
  { ticker: "EHTH", name: "EHEALTH INC" },
  { ticker: "EIC", name: "EAGLE POINT INCOME CO INC" },
  { ticker: "EIDO", name: "ISHARES MSCI INDONESIA ETF" },
  { ticker: "EIDX", name: "EIDOS THERAPEUTICS INC" },
  { ticker: "EIG", name: "EMPLOYERS HOLDINGS INC" },
  { ticker: "EIGI", name: "ENDURANCE INTERNATIONAL GROU" },
  { ticker: "EIGR", name: "EIGER BIOPHARMACEUTICALS INC" },
  { ticker: "EIM", name: "EATON VANCE MUNICIPAL BOND F" },
  { ticker: "EINC", name: "VANECK VECTORS ENERGY INCOME" },
  { ticker: "EIRL", name: "ISHARES MSCI IRELAND ETF" },
  { ticker: "EIS", name: "ISHARES MSCI ISRAEL ETF" },
  { ticker: "EIX", name: "EDISON INTERNATIONAL" },
  { ticker: "EJAN", name: "INNOVATOR MSCI EMERGING MKT" },
  { ticker: "EJUL", name: "INNO MSCI EMER MKTS P B JULY" },
  { ticker: "EKAR", name: "IDEANOMICS NEXTGEN VEH&TECH" },
  { ticker: "EKSO", name: "EKSO BIONICS HOLDINGS INC" },
  { ticker: "EL", name: "ESTEE LAUDER COMPANIES-CL A" },
  { ticker: "ELA", name: "ENVELA CORP" },
  { ticker: "ELAN", name: "ELANCO ANIMAL HEALTH INC" },
  { ticker: "ELD", name: "WISDOMTREE EMRG MKTS DEBT" },
  { ticker: "ELF", name: "ELF BEAUTY INC" },
  { ticker: "ELGX", name: "ENDOLOGIX INC" },
  { ticker: "ELLO", name: "ELLOMAY CAPITAL LTD" },
  { ticker: "ELMD", name: "ELECTROMED INC" },
  { ticker: "ELOX", name: "ELOXX PHARMACEUTICALS INC" },
  { ticker: "ELP", name: "CIA PARANAENSE ENER-SP ADR P" },
  { ticker: "ELS", name: "EQUITY LIFESTYLE PROPERTIES" },
  { ticker: "ELSE", name: "ELECTRO-SENSORS INC" },
  { ticker: "ELTK", name: "ELTEK LTD" },
  { ticker: "ELVT", name: "ELEVATE CREDIT INC" },
  { ticker: "ELY", name: "CALLAWAY GOLF COMPANY" },
  { ticker: "EMAG", name: "VANECK EM AGGREGATE BOND" },
  { ticker: "EMAN", name: "EMAGIN CORPORATION" },
  { ticker: "EMB", name: "ISHARES JP MORGAN USD EMERGI" },
  { ticker: "EMBH", name: "ISHARES INT RATE HDG EM BOND" },
  { ticker: "EMCB", name: "WISDOMTREE EM CORP BOND" },
  { ticker: "EMCF", name: "EMCLAIRE FINANCIAL CORP" },
  { ticker: "EMCG", name: "WISDOMTREE EMERGING MARKETS" },
  { ticker: "EMD", name: "WESTERN ASSET EMRG MRKT DBT" },
  { ticker: "EMDV", name: "PROSHARES MSCI EMERGING MARK" },
  { ticker: "EME", name: "EMCOR GROUP INC" },
  { ticker: "EMF", name: "TEMPLETON EMERGING MKTS FND" },
  { ticker: "EMFM", name: "GLOBAL X MSCI NEXT EMERGING" },
  { ticker: "EMGF", name: "ISHARES EDGE MSCI MF EM MKT" },
  { ticker: "EMHY", name: "ISHARES J.P. MORGAN EM HIGH" },
  { ticker: "EMIF", name: "ISHARES EMERGING MARKETS INF" },
  { ticker: "EMIH", name: "X-TRACKERS EMERGING MARKETS" },
  { ticker: "EMKR", name: "EMCORE CORP" },
  { ticker: "EML", name: "EASTERN CO/THE" },
  { ticker: "EMLC", name: "VANECK JPM EM LOCAL CCY BOND" },
  { ticker: "EMLP", name: "FIRST TRUST NORTH AMERICAN E" },
  { ticker: "EMMF", name: "WISDOMTREE EMERGING MARKETS" },
  { ticker: "EMMS", name: "EMMIS COMMUNICATIONS-CLASS A" },
  { ticker: "EMN", name: "EASTMAN CHEMICAL CO" },
  { ticker: "EMNT", name: "PIMCO ENHCD ST M-ACTIVE ESG" },
  { ticker: "EMO", name: "CLEARBRIDGE ENERGY MIDSTREAM" },
  { ticker: "EMQQ", name: "EMERGING MRKTS INTERNET & EC" },
  { ticker: "EMR", name: "EMERSON ELECTRIC CO" },
  { ticker: "EMSG", name: "XTRACKERS MSCI EMERGING MARK" },
  { ticker: "EMSH", name: "PROSHARES SHORT TERM USD EME" },
  { ticker: "EMTL", name: "SPDR DL EM FX IN ETF" },
  { ticker: "EMTY", name: "PROSHARES DECLINE OF THE RET" },
  { ticker: "EMX", name: "EMX ROYALTY CORP" },
  { ticker: "EMXC", name: "ISHARES MSCI EMR MRK EX CHNA" },
  { ticker: "ENB", name: "ENBRIDGE INC" },
  { ticker: "ENBL", name: "ENABLE MIDSTREAM PARTNERS LP" },
  { ticker: "ENDP", name: "ENDO INTERNATIONAL PLC" },
  { ticker: "ENFR", name: "ALERIAN ENERGY INFRASTRUCTUR" },
  { ticker: "ENG", name: "ENGLOBAL CORP" },
  { ticker: "ENIA", name: "ENEL AMERICAS SA-ADR" },
  { ticker: "ENIC", name: "ENEL CHILE SA-ADR" },
  { ticker: "ENLC", name: "ENLINK MIDSTREAM LLC" },
  { ticker: "ENLV", name: "ENLIVEX THERAPEUTICS LTD" },
  { ticker: "ENOB", name: "ENOCHIAN BIOSCIENCES INC" },
  { ticker: "ENOR", name: "ISHARES MSCI NORWAY ETF" },
  { ticker: "ENPH", name: "ENPHASE ENERGY INC" },
  { ticker: "ENR", name: "ENERGIZER HOLDINGS INC" },
  { ticker: "ENS", name: "ENERSYS" },
  { ticker: "ENSG", name: "ENSIGN GROUP INC/THE" },
  { ticker: "ENSV", name: "ENSERVCO CORP" },
  { ticker: "ENT", name: "GLOBAL EAGLE ENTERTAINMENT I" },
  { ticker: "ENTA", name: "ENANTA PHARMACEUTICALS INC" },
  { ticker: "ENTG", name: "ENTEGRIS INC" },
  { ticker: "ENTR", name: "ERSHARES ENTREPRENEUR 30 ETF" },
  { ticker: "ENTX", name: "ENTERA BIO LTD" },
  { ticker: "ENV", name: "ENVESTNET INC" },
  { ticker: "ENVA", name: "ENOVA INTERNATIONAL INC" },
  { ticker: "ENX", name: "EATON VANCE NEW YORK MUNICIP" },
  { ticker: "ENZ", name: "ENZO BIOCHEM INC" },
  { ticker: "ENZL", name: "ISHARES MSCI NEW ZEALAND ETF" },
  { ticker: "EOD", name: "WELLS FARGO GLOBAL DIVIDEND" },
  { ticker: "EOG", name: "EOG RESOURCES INC" },
  { ticker: "EOI", name: "EATON VANCE ENHANCED EQ INCM" },
  { ticker: "EOLS", name: "EVOLUS INC" },
  { ticker: "EOS", name: "EATON VANCE ENH EQT INC II" },
  { ticker: "EOT", name: "EATON VANCE NATIONAL MUNICIP" },
  { ticker: "EPAC", name: "ENERPAC TOOL GROUP CORP" },
  { ticker: "EPAM", name: "EPAM SYSTEMS INC" },
  { ticker: "EPAY", name: "BOTTOMLINE TECHNOLOGIES (DE)" },
  { ticker: "EPC", name: "EDGEWELL PERSONAL CARE CO" },
  { ticker: "EPD", name: "ENTERPRISE PRODUCTS PARTNERS" },
  { ticker: "EPHE", name: "ISHARES MSCI PHILIPPINES ETF" },
  { ticker: "EPI", name: "WISDOMTREE INDIA EARNINGS" },
  { ticker: "EPIX", name: "ESSA PHARMA INC" },
  { ticker: "EPM", name: "EVOLUTION PETROLEUM CORP" },
  { ticker: "EPOL", name: "ISHARES MSCI POLAND ETF" },
  { ticker: "EPP", name: "ISHARES MSCI PACIFIC EX JAPA" },
  { ticker: "EPR", name: "EPR PROPERTIES" },
  { ticker: "EPRF", name: "INNOVAT S&P INVEST GRD PREF" },
  { ticker: "EPRT", name: "ESSENTIAL PROPERTIES REALTY" },
  { ticker: "EPS", name: "WISDOMTREE US LARGE CAP FUND" },
  { ticker: "EPSN", name: "EPSILON ENERGY LTD" },
  { ticker: "EPU", name: "ISHARES MSCI PERU ETF" },
  { ticker: "EPV", name: "PROSHARES ULTRASHORT FTSE EU" },
  { ticker: "EPZM", name: "EPIZYME INC" },
  { ticker: "EQ", name: "EQUILLIUM INC" },
  { ticker: "EQAL", name: "INVESCO RUSSELL 1000 EQUAL W" },
  { ticker: "EQBK", name: "EQUITY BANCSHARES INC - CL A" },
  { ticker: "EQC", name: "EQUITY COMMONWEALTH" },
  { ticker: "EQH", name: "EQUITABLE HOLDINGS INC" },
  { ticker: "EQIX", name: "EQUINIX INC" },
  { ticker: "EQL", name: "ALPS EQUAL SECTOR WEIGHT ETF" },
  { ticker: "EQM", name: "EQM MIDSTREAM PARTNERS LP" },
  { ticker: "EQNR", name: "EQUINOR ASA-SPON ADR" },
  { ticker: "EQR", name: "EQUITY RESIDENTIAL" },
  { ticker: "EQRR", name: "PROSHRS EQTY RISING RATE ETF" },
  { ticker: "EQS", name: "EQUUS TOTAL RETURN INC" },
  { ticker: "EQT", name: "EQT CORP" },
  { ticker: "EQWL", name: "INVESCO S&P 100 EQUAL WEIGHT" },
  { ticker: "EQX", name: "EQUINOX GOLD CORP" },
  { ticker: "ERA", name: "ERA GROUP INC" },
  { ticker: "ERC", name: "WELLS FARGO MULTI-SECTOR INC" },
  { ticker: "ERF", name: "ENERPLUS CORP" },
  { ticker: "ERH", name: "WELLS FARGO UTILITIES AND HI" },
  { ticker: "ERI", name: "ELDORADO RESORTS INC" },
  { ticker: "ERIC", name: "ERICSSON (LM) TEL-SP ADR" },
  { ticker: "ERIE", name: "ERIE INDEMNITY COMPANY-CL A" },
  { ticker: "ERII", name: "ENERGY RECOVERY INC" },
  { ticker: "ERJ", name: "EMBRAER SA-SPON ADR" },
  { ticker: "ERM", name: "EQUITYCOMPASS RISK MNGR ETF" },
  { ticker: "EROS", name: "EROS INTERNATIONAL PLC" },
  { ticker: "ERSX", name: "ERShares Non-US SMALLCAP ETF" },
  { ticker: "ERUS", name: "ISHARES MSCI RUSSIA ETF" },
  { ticker: "ERX", name: "DIREXION DLY ENERGY BULL 3X" },
  { ticker: "ERY", name: "DIREXION DLY ENERGY BEAR 3X" },
  { ticker: "ERYP", name: "ERYTECH PHARMA - SPON ADR" },
  { ticker: "ES", name: "EVERSOURCE ENERGY" },
  { ticker: "ESBA", name: "EMPIRE STATE REALTY OP LP-ES" },
  { ticker: "ESBK", name: "ELMIRA SAVINGS BANK" },
  { ticker: "ESCA", name: "ESCALADE INC" },
  { ticker: "ESE", name: "ESCO TECHNOLOGIES INC" },
  { ticker: "ESEA", name: "EUROSEAS LTD" },
  { ticker: "ESG", name: "FLEXSHARES STOXX US IMPACT" },
  { ticker: "ESGD", name: "ISHARES ESG MSCI EAFE ETF" },
  { ticker: "ESGE", name: "ISHARES ESG MSCI EM ETF" },
  { ticker: "ESGG", name: "FLEXSHARES STOXX GBL IMPACT" },
  { ticker: "ESGN", name: "COLUMBIA SUSTAIN INTL EQ ETF" },
  { ticker: "ESGR", name: "ENSTAR GROUP LTD" },
  { ticker: "ESGS", name: "COLUMBIA SUSTAIN US EQ I ETF" },
  { ticker: "ESGU", name: "ISHARES TRUST ISHARES ESG MS" },
  { ticker: "ESGV", name: "VANGUARD ESG US STOCK ETF" },
  { ticker: "ESGW", name: "COLUMBIA SUSTAIN GLOBAL ETF" },
  { ticker: "ESI", name: "ELEMENT SOLUTIONS INC" },
  { ticker: "ESLT", name: "ELBIT SYSTEMS LTD" },
  { ticker: "ESML", name: "ISHARES ESG MSCI USA SMALL-C" },
  { ticker: "ESNG", name: "DIREXION MSCI USA ESG -LEAD" },
  { ticker: "ESNT", name: "ESSENT GROUP LTD" },
  { ticker: "ESP", name: "ESPEY MFG & ELECTRONICS CORP" },
  { ticker: "ESPO", name: "VANECK VIDEO GAMING ESPORTS" },
  { ticker: "ESPR", name: "ESPERION THERAPEUTICS INC" },
  { ticker: "ESQ", name: "ESQUIRE FINANCIAL HOLDINGS I" },
  { ticker: "ESRT", name: "EMPIRE STATE REALTY TRUST-A" },
  { ticker: "ESS", name: "ESSEX PROPERTY TRUST INC" },
  { ticker: "ESSA", name: "ESSA BANCORP INC" },
  { ticker: "ESSCU", name: "EAST STONE ACQUISITION CORP" },
  { ticker: "ESTA", name: "ESTABLISHMENT LABS HOLDINGS" },
  { ticker: "ESTC", name: "ELASTIC NV" },
  { ticker: "ESTE", name: "EARTHSTONE ENERGY INC - A" },
  { ticker: "ESXB", name: "COMMUNITY BANKERS TRUST CORP" },
  { ticker: "ET", name: "ENERGY TRANSFER LP" },
  { ticker: "ETB", name: "EATON VANCE T/M BUY-WR IN" },
  { ticker: "ETFC", name: "E*TRADE FINANCIAL CORP" },
  { ticker: "ETG", name: "EATON VANCE T/A GL DVD INCM" },
  { ticker: "ETH", name: "ETHAN ALLEN INTERIORS INC" },
  { ticker: "ETHO", name: "ETHO CLIMATE LEADERSHIP ETF" },
  { ticker: "ETJ", name: "EATON VANCE RISK-MANAGED DIV" },
  { ticker: "ETM", name: "ENTERCOM COMMUNICATIONS-CL A" },
  { ticker: "ETN", name: "EATON CORP PLC" },
  { ticker: "ETNB", name: "89BIO INC" },
  { ticker: "ETO", name: "EATON VANCE TAX ADV GL DVD O" },
  { ticker: "ETON", name: "ETON PHARMACEUTICALS INC" },
  { ticker: "ETR", name: "ENTERGY CORP" },
  { ticker: "ETRN", name: "EQUITRANS MIDSTREAM CORP" },
  { ticker: "ETSY", name: "ETSY INC" },
  { ticker: "ETTX", name: "ENTASIS THERAPEUTICS HOLDING" },
  { ticker: "ETV", name: "EATON VANCE T/M BUY-WRITE OP" },
  { ticker: "ETW", name: "EATON VANCE TAX MAN GLBL BR" },
  { ticker: "ETX", name: "EATON VANCE MUNI INC 2028 TM" },
  { ticker: "ETY", name: "EATON VANCE TAX-MANAGED DIVE" },
  { ticker: "EUDG", name: "WISDOMTREE EUROPE QUALITY DI" },
  { ticker: "EUDV", name: "PROSHARES MSCI EUROPE DIVIDE" },
  { ticker: "EUFL", name: "DRX DLY EURO FIN BULL 2X" },
  { ticker: "EUFN", name: "ISHARES MSCI EUROPE FINANCIA" },
  { ticker: "EUFX", name: "PROSHARES SHORT EURO ETF" },
  { ticker: "EUM", name: "PROSHARES SHORT MSCI EMR MKT" },
  { ticker: "EUMF", name: "WISDOMTREE EUROPE MULTIFACTO" },
  { ticker: "EUMV", name: "ISHARES EDGE MSCI MIN VOL EU" },
  { ticker: "EUO", name: "PROSHARES ULTRASHORT EURO" },
  { ticker: "EURL", name: "DRX DLY FTSE EUROPE BULL 3X" },
  { ticker: "EURN", name: "EURONAV NV" },
  { ticker: "EURZ", name: "XTRACKERS EUROZONE EQUITY ET" },
  { ticker: "EUSA", name: "ISHARES MSCI USA EQUAL WEIGH" },
  { ticker: "EUSC", name: "WISDOMTREE EUROPE HEDG SM EQ" },
  { ticker: "EV", name: "EATON VANCE CORP" },
  { ticker: "EVA", name: "ENVIVA PARTNERS LP" },
  { ticker: "EVBG", name: "EVERBRIDGE INC" },
  { ticker: "EVBN", name: "EVANS BANCORP INC" },
  { ticker: "EVC", name: "ENTRAVISION COMMUNICATIONS-A" },
  { ticker: "EVER", name: "EVERQUOTE INC - CLASS A" },
  { ticker: "EVF", name: "EATON VANCE SENIOR INCOME TR" },
  { ticker: "EVFM", name: "EVOFEM BIOSCIENCES INC" },
  { ticker: "EVG", name: "EATON VANCE SHORT DUR DIV IN" },
  { ticker: "EVGBC", name: "EATON VANCE GBL INC BLDR NS" },
  { ticker: "EVGN", name: "EVOGENE LTD" },
  { ticker: "EVH", name: "EVOLENT HEALTH INC - A" },
  { ticker: "EVI", name: "EVI INDUSTRIES INC" },
  { ticker: "EVIX", name: "VELOCITYSHARES 1X LONG VSTOX" },
  { ticker: "EVK", name: "EVER-GLORY INTERNATIONAL GRO" },
  { ticker: "EVLMC", name: "EATON VANCE TABS 5-15 LDR MU" },
  { ticker: "EVLO", name: "EVELO BIOSCIENCES INC" },
  { ticker: "EVM", name: "EATON VANCE CAL MUNI BOND" },
  { ticker: "EVN", name: "EATON VANCE MUNICIPAL INCOME" },
  { ticker: "EVOK", name: "EVOKE PHARMA INC" },
  { ticker: "EVOL", name: "EVOLVING SYSTEMS INC" },
  { ticker: "EVOP", name: "EVO PAYMENTS INC-CLASS A" },
  { ticker: "EVR", name: "EVERCORE INC - A" },
  { ticker: "EVRG", name: "EVERGY INC" },
  { ticker: "EVRI", name: "EVERI HOLDINGS INC" },
  { ticker: "EVSI", name: "ENVISION SOLAR INTERNATIONAL" },
  { ticker: "EVSTC", name: "EATON VANCE STOCK NEXTSHARES" },
  { ticker: "EVT", name: "EATON VANCE TAX-ADV DVD INC" },
  { ticker: "EVTC", name: "EVERTEC INC" },
  { ticker: "EVV", name: "EATON VANCE LTD DURATION FND" },
  { ticker: "EVX", name: "VANECK ENVIRONMENTAL SRVCS" },
  { ticker: "EVY", name: "EATON VANCE NY MUNICIPAL INC" },
  { ticker: "EW", name: "EDWARDS LIFESCIENCES CORP" },
  { ticker: "EWA", name: "ISHARES MSCI AUSTRALIA ETF" },
  { ticker: "EWBC", name: "EAST WEST BANCORP INC" },
  { ticker: "EWC", name: "ISHARES MSCI CANADA ETF" },
  { ticker: "EWCO", name: "INVESCO S&P 500 EQUAL WEIG C" },
  { ticker: "EWD", name: "ISHARES MSCI SWEDEN ETF" },
  { ticker: "EWG", name: "ISHARES MSCI GERMANY ETF" },
  { ticker: "EWGS", name: "ISHARES MSCI GERMANY SMALL-C" },
  { ticker: "EWH", name: "ISHARES MSCI HONG KONG ETF" },
  { ticker: "EWI", name: "ISHARES MSCI ITALY ETF" },
  { ticker: "EWJ", name: "ISHARES MSCI JAPAN ETF" },
  { ticker: "EWJE", name: "ISHARES MSCI JAPAN EQL WGHT" },
  { ticker: "EWJV", name: "ISHARES MSCI JAPAN VALUE ETF" },
  { ticker: "EWK", name: "ISHARES MSCI BELGIUM ETF" },
  { ticker: "EWL", name: "ISHARES MSCI SWITZERLAND ETF" },
  { ticker: "EWM", name: "ISHARES MSCI MALAYSIA ETF" },
  { ticker: "EWMC", name: "INVESCO S&P MIDCAP 400 EQUAL" },
  { ticker: "EWN", name: "ISHARES MSCI NETHERLANDS ETF" },
  { ticker: "EWO", name: "ISHARES MSCI AUSTRIA ETF" },
  { ticker: "EWP", name: "ISHARES MSCI SPAIN ETF" },
  { ticker: "EWQ", name: "ISHARES MSCI FRANCE ETF" },
  { ticker: "EWRE", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "EWS", name: "ISHARES MSCI SINGAPORE ETF" },
  { ticker: "EWSC", name: "INVESCO S&P SMALLCAP 600 EQU" },
  { ticker: "EWT", name: "ISHARES MSCI TAIWAN ETF" },
  { ticker: "EWU", name: "ISHARES MSCI UNITED KINGDOM" },
  { ticker: "EWUS", name: "ISHARES MSCI UNITED KINGDOM" },
  { ticker: "EWV", name: "PROSHARES ULTSHRT MSCI JAPAN" },
  { ticker: "EWW", name: "ISHARES MSCI MEXICO ETF" },
  { ticker: "EWX", name: "SPDR S&P EMERGING MKTS SMALL" },
  { ticker: "EWY", name: "ISHARES MSCI SOUTH KOREA ETF" },
  { ticker: "EWZ", name: "ISHARES MSCI BRAZIL ETF" },
  { ticker: "EWZS", name: "ISHARES MSCI BRAZIL SMALL-CA" },
  { ticker: "EXAS", name: "EXACT SCIENCES CORP" },
  { ticker: "EXC", name: "EXELON CORP" },
  { ticker: "EXD", name: "EATON VANCE TAX-MGD B/W STR" },
  { ticker: "EXEL", name: "EXELIXIS INC" },
  { ticker: "EXFO", name: "EXFO INC" },
  { ticker: "EXG", name: "EATON VANCE TAX-MANAGED GLOB" },
  { ticker: "EXI", name: "ISHARES GLOBAL INDUSTRIALS E" },
  { ticker: "EXIV", name: "VELOCITYSHARES 1X DAILY INVE" },
  { ticker: "EXK", name: "ENDEAVOUR SILVER CORP" },
  { ticker: "EXLS", name: "EXLSERVICE HOLDINGS INC" },
  { ticker: "EXP", name: "EAGLE MATERIALS INC" },
  { ticker: "EXPC", name: "EXPERIENCE INVESTMENT CORP-A" },
  { ticker: "EXPCU", name: "EXPERIENCE INVESTMENT CORP" },
  { ticker: "EXPD", name: "EXPEDITORS INTL WASH INC" },
  { ticker: "EXPE", name: "EXPEDIA GROUP INC" },
  { ticker: "EXPI", name: "EXP WORLD HOLDINGS INC" },
  { ticker: "EXPO", name: "EXPONENT INC" },
  { ticker: "EXPR", name: "EXPRESS INC" },
  { ticker: "EXR", name: "EXTRA SPACE STORAGE INC" },
  { ticker: "EXT", name: "WISDOMTREE US TOTAL MARKET F" },
  { ticker: "EXTN", name: "EXTERRAN CORP" },
  { ticker: "EXTR", name: "EXTREME NETWORKS INC" },
  { ticker: "EYE", name: "NATIONAL VISION HOLDINGS INC" },
  { ticker: "EYEG", name: "EYEGATE PHARMACEUTICALS" },
  { ticker: "EYEN", name: "EYENOVIA INC" },
  { ticker: "EYES", name: "SECOND SIGHT MEDICAL PRODUCT" },
  { ticker: "EYLD", name: "CAMBRIA EMERG SHRHLDR YIELD" },
  { ticker: "EYPT", name: "EYEPOINT PHARMACEUTICALS INC" },
  { ticker: "EZA", name: "ISHARES MSCI SOUTH AFRICA ET" },
  { ticker: "EZJ", name: "PROSHARES ULTRA MSCI JAPAN" },
  { ticker: "EZM", name: "WISDOMTREE US MIDCAP FUND" },
  { ticker: "EZPW", name: "EZCORP INC-CL A" },
  { ticker: "EZU", name: "ISHARES MSCI EUROZONE ETF" },
  { ticker: "F", name: "FORD MOTOR CO" },
  { ticker: "FAAR", name: "FIRST TRUST ALT ABS RET ETF" },
  { ticker: "FAB", name: "FIRST TRUST MULTI CAP VALUE" },
  { ticker: "FAD", name: "FIRST TRUST MULTI CAP GROWTH" },
  { ticker: "FAF", name: "FIRST AMERICAN FINANCIAL" },
  { ticker: "FALN", name: "ISHARES FALLEN ANGELS ETF" },
  { ticker: "FAM", name: "FIRST TRUST ABERDEEN GLOBAL" },
  { ticker: "FAMI", name: "FARMMI INC" },
  { ticker: "FAN", name: "FIRST TRUST GLOBAL WIND ENER" },
  { ticker: "FANG", name: "DIAMONDBACK ENERGY INC" },
  { ticker: "FANH", name: "FANHUA INC-SPONSORED ADR" },
  { ticker: "FARM", name: "FARMER BROS CO" },
  { ticker: "FARO", name: "FARO TECHNOLOGIES INC" },
  { ticker: "FAS", name: "DIREXION DAILY FIN BULL 3X" },
  { ticker: "FAST", name: "FASTENAL CO" },
  { ticker: "FAT", name: "FAT BRANDS INC" },
  { ticker: "FATE", name: "FATE THERAPEUTICS INC" },
  { ticker: "FAUG", name: "FT CBOE VEST US EQUITY BUFF" },
  { ticker: "FAUS", name: "FIRST TRUST AUSTRALIA" },
  { ticker: "FAX", name: "ABERDEEN ASIA-PAC INCOME FD" },
  { ticker: "FAZ", name: "DIREXION DAILY FIN BEAR 3X" },
  { ticker: "FB", name: "FACEBOOK INC-CLASS A" },
  { ticker: "FBC", name: "FLAGSTAR BANCORP INC" },
  { ticker: "FBGX", name: "FI ENHANCED LARGE CAP GROWTH" },
  { ticker: "FBHS", name: "FORTUNE BRANDS HOME & SECURI" },
  { ticker: "FBIO", name: "FORTRESS BIOTECH INC" },
  { ticker: "FBIZ", name: "FIRST BUSINESS FINANCIAL SER" },
  { ticker: "FBK", name: "FB FINANCIAL CORP" },
  { ticker: "FBM", name: "FOUNDATION BUILDING MATERIAL" },
  { ticker: "FBMS", name: "FIRST BANCSHARES INC/MS" },
  { ticker: "FBNC", name: "FIRST BANCORP/NC" },
  { ticker: "FBND", name: "FIDELITY TOTAL BOND ETF" },
  { ticker: "FBP", name: "FIRST BANCORP PUERTO RICO" },
  { ticker: "FBSS", name: "FAUQUIER BANKSHARES INC" },
  { ticker: "FBT", name: "FIRST TRUST NYSE ARCA BIOTEC" },
  { ticker: "FBZ", name: "FIRST TRUST BRAZIL" },
  { ticker: "FC", name: "FRANKLIN COVEY CO" },
  { ticker: "FCA", name: "FIRST TRUST CHINA" },
  { ticker: "FCAL", name: "FIRST TRUST CALIFORNIA MUNIC" },
  { ticker: "FCAN", name: "FIRST TRUST CANADA" },
  { ticker: "FCAP", name: "FIRST CAPITAL INC" },
  { ticker: "FCAU", name: "FIAT CHRYSLER AUTOMOBILES NV" },
  { ticker: "FCBC", name: "FIRST COMMUNITY BANKSHARES" },
  { ticker: "FCBP", name: "FIRST CHOICE BANCORP" },
  { ticker: "FCCO", name: "FIRST COMMUNITY CORP" },
  { ticker: "FCCY", name: "1ST CONSTITUTION BANCORP" },
  { ticker: "FCEF", name: "FIRST TRUST CEF INCOME OPP" },
  { ticker: "FCEL", name: "FUELCELL ENERGY INC" },
  { ticker: "FCF", name: "FIRST COMMONWEALTH FINL CORP" },
  { ticker: "FCFS", name: "FIRSTCASH INC" },
  { ticker: "FCG", name: "FIRST TRUST NATURAL GAS ETF" },
  { ticker: "FCN", name: "FTI CONSULTING INC" },
  { ticker: "FCNCA", name: "FIRST CITIZENS BCSHS  -CL A" },
  { ticker: "FCO", name: "ABERDEEN GLOBAL INCOME FUND" },
  { ticker: "FCOM", name: "FIDELITY MSCI COMMUNICATION" },
  { ticker: "FCOR", name: "FIDELITY CORPORATE BOND ETF" },
  { ticker: "FCPI", name: "FIDELITY STOCKS FOR INFL ETF" },
  { ticker: "FCPT", name: "FOUR CORNERS PROPERTY TRUST" },
  { ticker: "FCT", name: "FIRST TRUST SENIOR FLOATING" },
  { ticker: "FCTR", name: "FIRST TRUST LUNT US FACTOR" },
  { ticker: "FCVT", name: "FIRST TRUST SSI STRATEGI ETF" },
  { ticker: "FCX", name: "FREEPORT-MCMORAN INC" },
  { ticker: "FDBC", name: "FIDELITY D&D BANCORP INC" },
  { ticker: "FDD", name: "FIRST TRUST STOXX EUROPE" },
  { ticker: "FDEF", name: "FIRST DEFIANCE FINL CORP" },
  { ticker: "FDEM", name: "FIDELITY TGT EMRG MKT FACTOR" },
  { ticker: "FDEU", name: "FIRST TR DYN EUR EQTY INC" },
  { ticker: "FDEV", name: "FIDELITY TGT INTL FACTOR ETF" },
  { ticker: "FDHY", name: "FIDELITY HI YIELD FACTOR ETF" },
  { ticker: "FDIS", name: "FIDELITY CON DISCRET ETF" },
  { ticker: "FDIV", name: "FIRST TRUST STRATEGIC INCOME" },
  { ticker: "FDL", name: "FIRST TRUST MORN DVD LEAD IN" },
  { ticker: "FDLO", name: "FIDELITY LOW VOLATILITY FACT" },
  { ticker: "FDM", name: "FIRST TRUST DOW J SELECT MIC" },
  { ticker: "FDMO", name: "FIDELITY MOMENTUM FACTOR ETF" },
  { ticker: "FDN", name: "FIRST TRUST DJ INTERNET IND" },
  { ticker: "FDNI", name: "FIRST TRUST DOW JONES INTL" },
  { ticker: "FDP", name: "FRESH DEL MONTE PRODUCE INC" },
  { ticker: "FDRR", name: "FIDELITY DIV ETF RISE RATES" },
  { ticker: "FDS", name: "FACTSET RESEARCH SYSTEMS INC" },
  { ticker: "FDT", name: "FIRST TRUST DEVELP MKT EX-US" },
  { ticker: "FDTS", name: "FIRST TRUST DEVELOPED MARKET" },
  { ticker: "FDUS", name: "FIDUS INVESTMENT CORP" },
  { ticker: "FDVV", name: "FIDELITY HIGH DIVIDEND ETF" },
  { ticker: "FDX", name: "FEDEX CORP" },
  { ticker: "FE", name: "FIRSTENERGY CORP" },
  { ticker: "FEAC=", name: "FLYING EAGLE ACQUISITION COR" },
  { ticker: "FEDU", name: "FOUR SEASONS EDUCATION CAYMA" },
  { ticker: "FEI", name: "FIRST TRUST MLP AND ENERGY I" },
  { ticker: "FEIM", name: "FREQUENCY ELECTRONICS INC" },
  { ticker: "FELE", name: "FRANKLIN ELECTRIC CO INC" },
  { ticker: "FEM", name: "FIRST TRUST EMERGING MARKETS" },
  { ticker: "FEMB", name: "FIRST TRUST EMERGING MARKETS" },
  { ticker: "FEMS", name: "FIRST TRUST EMERGING MARKETS" },
  { ticker: "FEN", name: "FIRST TR ENRGY INC & GRW" },
  { ticker: "FENC", name: "FENNEC PHARMACEUTICALS INC" },
  { ticker: "FENG", name: "PHOENIX NEW MEDIA LTD -ADR" },
  { ticker: "FENY", name: "FIDELITY MSCI ENERGY ETF" },
  { ticker: "FEO", name: "FIRST TRUST ABERDEEN EMG OPP" },
  { ticker: "FEP", name: "FIRST TRUST EUROPE" },
  { ticker: "FET", name: "FORUM ENERGY TECHNOLOGIES IN" },
  { ticker: "FEUL", name: "CREDIT SUISSE FI ENHANCED EU" },
  { ticker: "FEUZ", name: "FIRST TRUST EUROZONE ALPHADE" },
  { ticker: "FEX", name: "FIRST TRUST LARGE CAP CORE A" },
  { ticker: "FEYE", name: "FIREEYE INC" },
  { ticker: "FEZ", name: "SPDR EURO STOXX 50 ETF" },
  { ticker: "FF", name: "FUTUREFUEL CORP" },
  { ticker: "FFA", name: "FIRST TRUST ENH EQUITY INC" },
  { ticker: "FFBC", name: "FIRST FINANCIAL BANCORP" },
  { ticker: "FFBW", name: "FFBW INC" },
  { ticker: "FFC", name: "FLAHERTY & CRUMRINE PREFERRE" },
  { ticker: "FFEB", name: "FT CBOE VEST US EQY BUFF-FEB" },
  { ticker: "FFEU", name: "BARCLAYS ETN+ FI ENHANCED EU" },
  { ticker: "FFG", name: "FBL FINANCIAL GROUP INC-CL A" },
  { ticker: "FFHG", name: "FORMULA FOLIOS HEDGED GROWTH" },
  { ticker: "FFHL", name: "FUWEI FILMS HOLDINGS CO LTD" },
  { ticker: "FFIC", name: "FLUSHING FINANCIAL CORP" },
  { ticker: "FFIN", name: "FIRST FINL BANKSHARES INC" },
  { ticker: "FFIU", name: "UVA UNCONSTRAINED MEDIUM-TER" },
  { ticker: "FFIV", name: "F5 NETWORKS INC" },
  { ticker: "FFNW", name: "FIRST FINANCIAL NORTHWEST" },
  { ticker: "FFR", name: "FT FTSE EPRA/NAREIT REAL EST" },
  { ticker: "FFSG", name: "FORMULAFOLIOS SMART GROWTH" },
  { ticker: "FFTG", name: "FORMULAFOLIOS TACTICAL GROWT" },
  { ticker: "FFTI", name: "FORMULAFOLIOS TACTICAL INCOM" },
  { ticker: "FFTY", name: "INNOVATOR IBD 50 ETF" },
  { ticker: "FFWM", name: "FIRST FOUNDATION INC" },
  { ticker: "FG", name: "FGL HOLDINGS" },
  { ticker: "FGB", name: "FIRST TRUST SPECIALTY FINANC" },
  { ticker: "FGBI", name: "FIRST GUARANTY BANCSHARES IN" },
  { ticker: "FGD", name: "FIRST TRUST DJ GL SEL DVD" },
  { ticker: "FGEN", name: "FIBROGEN INC" },
  { ticker: "FGM", name: "FIRST TRUST GERMANY" },
  { ticker: "FHB", name: "FIRST HAWAIIAN INC" },
  { ticker: "FHI", name: "FEDERATED HERMES INC" },
  { ticker: "FHK", name: "FIRST TRUST HONG KONG" },
  { ticker: "FHLC", name: "FIDELITY HEALTH CARE ETF" },
  { ticker: "FHN", name: "FIRST HORIZON NATIONAL CORP" },
  { ticker: "FI", name: "FRANK'S INTERNATIONAL NV" },
  { ticker: "FIBK", name: "FIRST INTERSTATE BANCSYS-A" },
  { ticker: "FIBR", name: "ISHRS EDGE US FIXED INC BAL" },
  { ticker: "FICO", name: "FAIR ISAAC CORP" },
  { ticker: "FID", name: "FIRST TRUST S&P INTERNATIONA" },
  { ticker: "FIDI", name: "FIDELITY INTL HIGH DIV ETF" },
  { ticker: "FIDU", name: "FIDELITY INDUSTRIALS ETF" },
  { ticker: "FIEE", name: "UBS FI ENHANCED EUR 50 ETN" },
  { ticker: "FIF", name: "FIRST TRUST ENERGY INFRASTRU" },
  { ticker: "FIHD", name: "UBS FI ENH GL HI YLD ETN" },
  { ticker: "FILL", name: "ISHARES MSCI GLOBAL ENERGY P" },
  { ticker: "FINS", name: "ANGEL OAK FINANC STRAT INC" },
  { ticker: "FINU", name: "PROSHARES ULTRAPRO FINANCIAL" },
  { ticker: "FINV", name: "FINVOLUTION GROUP" },
  { ticker: "FINX", name: "GLOBAL X FINTECH ETF" },
  { ticker: "FINZ", name: "PROSHARES ULTRAPRO SHORT FIN" },
  { ticker: "FIS", name: "FIDELITY NATIONAL INFO SERV" },
  { ticker: "FISI", name: "FINANCIAL INSTITUTIONS INC" },
  { ticker: "FISK", name: "EMPIRE STATE REALTY OP -S250" },
  { ticker: "FISR", name: "SPDR SSGA F/I SECTOR ROTATE" },
  { ticker: "FISV", name: "FISERV INC" },
  { ticker: "FIT", name: "FITBIT INC - A" },
  { ticker: "FITB", name: "FIFTH THIRD BANCORP" },
  { ticker: "FITE", name: "SPDR S&P KENSHO FUTURE SECUR" },
  { ticker: "FIV", name: "FIRST TRUST SENIOR FLOATING" },
  { ticker: "FIVA", name: "FIDELITY INTL VAL FACTOR ETF" },
  { ticker: "FIVE", name: "FIVE BELOW" },
  { ticker: "FIVG", name: "DEFIANCE NEXT GEN CONNECT ET" },
  { ticker: "FIVN", name: "FIVE9 INC" },
  { ticker: "FIW", name: "FIRST TRUST WATER ETF" },
  { ticker: "FIX", name: "COMFORT SYSTEMS USA INC" },
  { ticker: "FIXD", name: "FIRST TRUST TCW OPP FIXED IN" },
  { ticker: "FIXX", name: "HOMOLOGY MEDICINES INC" },
  { ticker: "FIYY", name: "BARCLAYS ETN+ FI ENHNCD GL-B" },
  { ticker: "FIZZ", name: "NATIONAL BEVERAGE CORP" },
  { ticker: "FJNK", name: "PACIFIC GLOBAL FOCUSED HIGH" },
  { ticker: "FJP", name: "FIRST TRUST JAPAN" },
  { ticker: "FKO", name: "FIRST TRUST SOUTH KOREA" },
  { ticker: "FKU", name: "FIRST TRUST UNITED KINGDOM" },
  { ticker: "FL", name: "FOOT LOCKER INC" },
  { ticker: "FLAG", name: "FLAG-FORENSIC ACCT LONG-SHRT" },
  { ticker: "FLAT", name: "IPATH US TSY FLATTENER" },
  { ticker: "FLAU", name: "FRANKLIN FTSE AUSTRALIA ETF" },
  { ticker: "FLAX", name: "FRANKLIN FTSE ASIA EX JAPAN" },
  { ticker: "FLBL", name: "FRANKLIN LIBERTY SENIOR LOAN" },
  { ticker: "FLBR", name: "FRANKLIN FTSE BRAZIL ETF" },
  { ticker: "FLC", name: "FLAH & CRUM TTL RTRN FND" },
  { ticker: "FLCA", name: "FRANKLIN FTSE CANADA ETF" },
  { ticker: "FLCB", name: "FRANKLIN LIBERTY US CORE BND" },
  { ticker: "FLCH", name: "FRANKLIN FTSE CHINA ETF" },
  { ticker: "FLCO", name: "FRANKLIN LIBERTY IG CORP ETF" },
  { ticker: "FLDM", name: "FLUIDIGM CORP" },
  { ticker: "FLDR", name: "FIDELITY LOW DURATION ETF" },
  { ticker: "FLEE", name: "FRANKLIN FTSE EUROPE ETF" },
  { ticker: "FLEH", name: "FRANKLIN FTSE EUROPE HEDGED" },
  { ticker: "FLEU", name: "FI ENHANCE EUR50 ETN SERIESB" },
  { ticker: "FLEX", name: "FLEX LTD" },
  { ticker: "FLFR", name: "FRANKLIN FTSE FRANCE ETF" },
  { ticker: "FLGB", name: "FRANKLIN FTSE UNITED KINGDOM" },
  { ticker: "FLGE", name: "FI LARGE CAP GROWTH ENHANCED" },
  { ticker: "FLGR", name: "FRANKLIN FTSE GERMANY ETF" },
  { ticker: "FLGT", name: "FULGENT GENETICS INC" },
  { ticker: "FLHK", name: "FRANKLIN FTSE HONG KONG ETF" },
  { ticker: "FLHY", name: "FRANKLIN LIBERTY HIGH YIELD" },
  { ticker: "FLIA", name: "FRANKLIN LIBERTY INTL AGG BO" },
  { ticker: "FLIC", name: "FIRST OF LONG ISLAND CORP" },
  { ticker: "FLIN", name: "FRANKLIN FTSE INDIA ETF" },
  { ticker: "FLIO", name: "FRANKLIN LIB INTL OPPS ETF" },
  { ticker: "FLIR", name: "FLIR SYSTEMS INC" },
  { ticker: "FLIY", name: "FRANKLIN FTSE ITALY ETF" },
  { ticker: "FLJH", name: "FRANKLIN FTSE JPN HEDGED ETF" },
  { ticker: "FLJP", name: "FRANKLIN FTSE JAPAN ETF" },
  { ticker: "FLKR", name: "FRANKLIN FTSE SOUTH KOREA" },
  { ticker: "FLL", name: "FULL HOUSE RESORTS INC" },
  { ticker: "FLLA", name: "FRANKLIN FTSE LATIN AMERICA" },
  { ticker: "FLLV", name: "FRANKLIN LIBERTY US LOW VOL" },
  { ticker: "FLM", name: "FIRST TRUST GLOBAL ENGINEERI" },
  { ticker: "FLMB", name: "FRANKLIN LIBERTY MUNI BD ETF" },
  { ticker: "FLMI", name: "FRANKLIN LIB INTER MUNI OPP" },
  { ticker: "FLMN", name: "FALCON MINERALS CORP" },
  { ticker: "FLMX", name: "FRANKLIN FTSE MEXICO ETF" },
  { ticker: "FLN", name: "FIRST TRUST LATIN AMERICA" },
  { ticker: "FLNG", name: "FLEX LNG LTD" },
  { ticker: "FLNT", name: "FLUENT INC" },
  { ticker: "FLO", name: "FLOWERS FOODS INC" },
  { ticker: "FLOT", name: "ISHARES FLOATING RATE BOND E" },
  { ticker: "FLOW", name: "SPX FLOW INC" },
  { ticker: "FLQD", name: "FRANKLIN LIBERTYQ GLOBAL DIV" },
  { ticker: "FLQE", name: "FRANKLIN LIBERTYQ EMERGING" },
  { ticker: "FLQG", name: "FRANKLIN LIBERTYQ GLOBAL EQ" },
  { ticker: "FLQH", name: "FRANKLIN LIBERTYQ INT EQ HED" },
  { ticker: "FLQL", name: "FRANKLIN LIBERTYQ US EQUITY" },
  { ticker: "FLQM", name: "FRANKLIN LIBERTYQ MID CAP EQ" },
  { ticker: "FLQS", name: "FRANKLIN LIBERTYQ SMALL CAP" },
  { ticker: "FLR", name: "FLUOR CORP" },
  { ticker: "FLRN", name: "SPDR BBG BARC IG FLOATING RT" },
  { ticker: "FLRT", name: "PACIFIC GLOBAL SENIOR LOAN E" },
  { ticker: "FLRU", name: "FRANKLIN FTSE RUSSIA ETF" },
  { ticker: "FLS", name: "FLOWSERVE CORP" },
  { ticker: "FLSA", name: "FRANKLIN FTSE SAUDI ARABIA" },
  { ticker: "FLSP", name: "FRANKLIN LIBERTY SYSTEMATIC" },
  { ticker: "FLSW", name: "FRANKLIN FTSE SWITZERLAND" },
  { ticker: "FLT", name: "FLEETCOR TECHNOLOGIES INC" },
  { ticker: "FLTB", name: "FIDELITY LTD TERM BOND ETF" },
  { ticker: "FLTR", name: "VANECK INVGRD FLOATING RATE" },
  { ticker: "FLTW", name: "FRANKLIN FTSE TAIWAN ETF" },
  { ticker: "FLWS", name: "1-800-FLOWERS.COM INC-CL A" },
  { ticker: "FLXN", name: "FLEXION THERAPEUTICS INC" },
  { ticker: "FLXS", name: "FLEXSTEEL INDS" },
  { ticker: "FLY", name: "FLY LEASING LTD-ADR" },
  { ticker: "FLYT", name: "DIREXION FLIGHT TO SAFETY ST" },
  { ticker: "FLZA", name: "FRANKLIN FTSE SOUTH AFRICA" },
  { ticker: "FM", name: "ISHARES MSCI FRONTIER 100" },
  { ticker: "FMAO", name: "FARMERS & MERCHANTS BANCO/OH" },
  { ticker: "FMAT", name: "FIDELITY MATERIALS ETF" },
  { ticker: "FMB", name: "FIRST TRUST MANAGED MUNICIPA" },
  { ticker: "FMBH", name: "FIRST MID BANCSHARES INC" },
  { ticker: "FMBI", name: "FIRST MIDWEST BANCORP INC/IL" },
  { ticker: "FMC", name: "FMC CORP" },
  { ticker: "FMCI", name: "FORUM MERGER II CORP-CL A" },
  { ticker: "FMCIU", name: "FORUM MERGER II CORP" },
  { ticker: "FMF", name: "FIRST TRUST MANAGED FUTURES" },
  { ticker: "FMHI", name: "FIRST TRUST MUNICIPAL HIGH I" },
  { ticker: "FMK", name: "FT MEGA CAP ALPHADEX FUND" },
  { ticker: "FMN", name: "FEDERATED PREMIER MUNI INC" },
  { ticker: "FMNB", name: "FARMERS NATL BANC CORP" },
  { ticker: "FMO", name: "FIDUCIARY/CLAYM ENRGY INFRA" },
  { ticker: "FMS", name: "FRESENIUS MEDICAL CARE-ADR" },
  { ticker: "FMX", name: "FOMENTO ECONOMICO MEX-SP ADR" },
  { ticker: "FMY", name: "FIRST TRUST MORTGAGE INCOME" },
  { ticker: "FN", name: "FABRINET" },
  { ticker: "FNB", name: "FNB CORP" },
  { ticker: "FNCB", name: "FNCB BANCORP INC" },
  { ticker: "FNCL", name: "FIDELITY FINANCIALS ETF" },
  { ticker: "FND", name: "FLOOR & DECOR HOLDINGS INC-A" },
  { ticker: "FNDA", name: "SCHWAB FUNDAMENTAL SMALL CAP" },
  { ticker: "FNDB", name: "SCHWAB FUNDAMENTAL BROAD MKT" },
  { ticker: "FNDC", name: "SCHWAB FUNDAMENTAL INTL S/C" },
  { ticker: "FNDE", name: "SCHWAB FUNDAMENTAL EM L/C" },
  { ticker: "FNDF", name: "SCHWAB FUNDAMENTAL INTL L/C" },
  { ticker: "FNDX", name: "SCHWAB FUNDAMENTAL LARGE CAP" },
  { ticker: "FNF", name: "FIDELITY NATIONAL FINANCIAL" },
  { ticker: "FNGD", name: "MICROSECTORS FANG+ INDEX -3X" },
  { ticker: "FNGO", name: "MICROSECTORS FANG INDEX 2X L" },
  { ticker: "FNGS", name: "MICROSECTORS FANG+ ETNS" },
  { ticker: "FNGU", name: "MICROSECTORS FANG+ INDEX 3X" },
  { ticker: "FNGZ", name: "MICROSECTORS FANG INDEX 2X I" },
  { ticker: "FNHC", name: "FEDNAT HOLDING CO" },
  { ticker: "FNI", name: "FIRST TRUST CHINDIA ETF" },
  { ticker: "FNJN", name: "FINJAN HOLDINGS INC" },
  { ticker: "FNK", name: "FIRST TRUST MID CAP VALUE" },
  { ticker: "FNKO", name: "FUNKO INC-CLASS A" },
  { ticker: "FNLC", name: "FIRST BANCORP INC/ME" },
  { ticker: "FNOV", name: "FT CBOE VEST US EQUITY BUFF" },
  { ticker: "FNV", name: "FRANCO-NEVADA CORP" },
  { ticker: "FNWB", name: "FIRST NORTHWEST BANCORP" },
  { ticker: "FNX", name: "FIRST TRUST MID CAP CORE ALP" },
  { ticker: "FNY", name: "FIRST TRUST MID CAP GROWTH" },
  { ticker: "FOCS", name: "FOCUS FINANCIAL PARTNERS-A" },
  { ticker: "FOE", name: "FERRO CORP" },
  { ticker: "FOF", name: "COHEN & STEERS CLOSED-END OP" },
  { ticker: "FOLD", name: "AMICUS THERAPEUTICS INC" },
  { ticker: "FONR", name: "FONAR CORP" },
  { ticker: "FOR", name: "FORESTAR GROUP INC" },
  { ticker: "FORD", name: "FORWARD INDUSTRIES INC" },
  { ticker: "FORK", name: "FULING GLOBAL INC" },
  { ticker: "FORM", name: "FORMFACTOR INC" },
  { ticker: "FORR", name: "FORRESTER RESEARCH INC" },
  { ticker: "FORTY", name: "FORMULA SYSTEMS 1985-SP ADR" },
  { ticker: "FOSL", name: "FOSSIL GROUP INC" },
  { ticker: "FOVL", name: "ISHARES FOCUSED VALUE FACTOR" },
  { ticker: "FOX", name: "FOX CORP - CLASS B" },
  { ticker: "FOXA", name: "FOX CORP - CLASS A" },
  { ticker: "FOXF", name: "FOX FACTORY HOLDING CORP" },
  { ticker: "FPA", name: "FIRST TRUST ASIA PAC EX-JAPN" },
  { ticker: "FPAC", name: "FAR POINT ACQUISITION CORP-A" },
  { ticker: "FPAC=", name: "FAR POINT ACQUISITION CORP" },
  { ticker: "FPAY", name: "FLEXSHOPPER INC" },
  { ticker: "FPE", name: "FT-PREFERRED SECUR & INC ETF" },
  { ticker: "FPEI", name: "FIRST TRUST INSTITUTIONAL PR" },
  { ticker: "FPF", name: "FIRST TRUST INTERMEDIATE DUR" },
  { ticker: "FPH", name: "FIVE POINT HOLDINGS LLC-CL A" },
  { ticker: "FPI", name: "FARMLAND PARTNERS INC" },
  { ticker: "FPL", name: "FIRST TRUST NEW OPPORTUNITIE" },
  { ticker: "FPRX", name: "FIVE PRIME THERAPEUTICS INC" },
  { ticker: "FPX", name: "FIRST TRUST US EQUITY OPPORT" },
  { ticker: "FPXE", name: "FIRST TRUST IPOX EUROPE EQUI" },
  { ticker: "FPXI", name: "FIRST TRUST INTERNATIONAL EQ" },
  { ticker: "FQAL", name: "FIDELITY QUALITY FACTOR ETF" },
  { ticker: "FR", name: "FIRST INDUSTRIAL REALTY TR" },
  { ticker: "FRA", name: "BLACKROCK FLOAT RT INCOME ST" },
  { ticker: "FRAF", name: "FRANKLIN FINANCIAL SERVICES" },
  { ticker: "FRAK", name: "VANECK UNCONVENT OIL & GAS" },
  { ticker: "FRAN", name: "FRANCESCAS HOLDINGS CORP" },
  { ticker: "FRBA", name: "FIRST BANK/HAMILTON NJ" },
  { ticker: "FRBK", name: "REPUBLIC FIRST BANCORP INC" },
  { ticker: "FRC", name: "FIRST REPUBLIC BANK/CA" },
  { ticker: "FRD", name: "FRIEDMAN INDUSTRIES" },
  { ticker: "FRDM", name: "FREEDOM 100 EMEGING MRKT ETF" },
  { ticker: "FREL", name: "FIDELITY REAL ESTATE ETF" },
  { ticker: "FREQ", name: "FREQUENCY THERAPEUTICS INC" },
  { ticker: "FRG", name: "FRANCHISE GROUP INC" },
  { ticker: "FRGI", name: "FIESTA RESTAURANT GROUP" },
  { ticker: "FRHC", name: "FREEDOM HOLDING CORP/NV" },
  { ticker: "FRI", name: "FIRST TRUST S&P REIT INDEX F" },
  { ticker: "FRLG", name: "LARGE CAP GROWTH INDEX-LINKE" },
  { ticker: "FRME", name: "FIRST MERCHANTS CORP" },
  { ticker: "FRO", name: "FRONTLINE LTD" },
  { ticker: "FRPH", name: "FRP HOLDINGS INC" },
  { ticker: "FRPT", name: "FRESHPET INC" },
  { ticker: "FRSX", name: "FORESIGHT AUTONOMOUS-SP ADR" },
  { ticker: "FRT", name: "FEDERAL REALTY INVS TRUST" },
  { ticker: "FRTA", name: "FORTERRA INC" },
  { ticker: "FSB", name: "FRANKLIN FINANCIAL NETWORK" },
  { ticker: "FSBC", name: "FSB BANCORP INC/NY" },
  { ticker: "FSBW", name: "FS BANCORP INC" },
  { ticker: "FSCT", name: "FORESCOUT TECHNOLOGIES INC" },
  { ticker: "FSD", name: "FIRST TRUST HIGH INCOME LONG" },
  { ticker: "FSEA", name: "FIRST SEACOAST BANCORP" },
  { ticker: "FSFG", name: "FIRST SAVINGS FINANCIAL GRP" },
  { ticker: "FSI", name: "FLEXIBLE SOLUTIONS INTL INC" },
  { ticker: "FSK", name: "FS KKR CAPITAL CORP" },
  { ticker: "FSLR", name: "FIRST SOLAR INC" },
  { ticker: "FSLY", name: "FASTLY INC - CLASS A" },
  { ticker: "FSM", name: "FORTUNA SILVER MINES INC" },
  { ticker: "FSMB", name: "FIRST TRUST SHORT DUR MANAG" },
  { ticker: "FSMD", name: "FIDELITY SMALL-MID FACTOR" },
  { ticker: "FSP", name: "FRANKLIN STREET PROPERTIES C" },
  { ticker: "FSRV", name: "FINSERV ACQUISITION CORP-A" },
  { ticker: "FSRVU", name: "FINSERV ACQUISITION CORP" },
  { ticker: "FSS", name: "FEDERAL SIGNAL CORP" },
  { ticker: "FSTA", name: "FIDELITY CON STAPLES ETF" },
  { ticker: "FSTR", name: "FOSTER (LB) CO-A" },
  { ticker: "FSV", name: "FIRSTSERVICE CORP" },
  { ticker: "FSZ", name: "FIRST TRUST SWITZERLAND" },
  { ticker: "FT", name: "FRANKLIN UNIVERSAL TRUST" },
  { ticker: "FTA", name: "FIRST TRUST L C VAL ALP" },
  { ticker: "FTAC", name: "FINTECH ACQUISITION CORP - A" },
  { ticker: "FTACU", name: "FINTECH ACQUISITION CORP III" },
  { ticker: "FTAG", name: "FIRST TRUST INDXX GLOBAL AGR" },
  { ticker: "FTAI", name: "FORTRESS TRANSPORTATION & IN" },
  { ticker: "FTC", name: "FIRST TRUST LARGE CAP GROWTH" },
  { ticker: "FTCH", name: "FARFETCH LTD-CLASS A" },
  { ticker: "FTCS", name: "FIRST TRUST CAPITAL STRENGTH" },
  { ticker: "FTDR", name: "FRONTDOOR INC" },
  { ticker: "FTEC", name: "FIDELITY MSCI INFO TECH ETF" },
  { ticker: "FTEK", name: "FUEL TECH INC" },
  { ticker: "FTF", name: "FRANKLIN LTD DUR INC TR" },
  { ticker: "FTFT", name: "FUTURE FINTECH GROUP INC" },
  { ticker: "FTGC", name: "FIRST TRUST GLOBAL TACTICAL" },
  { ticker: "FTHI", name: "FTHI/FIRST TRUST EXCHANGE-TR" },
  { ticker: "FTI", name: "TECHNIPFMC PLC" },
  { ticker: "FTK", name: "FLOTEK INDUSTRIES INC" },
  { ticker: "FTLB", name: "FIRST TRUST EXCHANGE-TRADED" },
  { ticker: "FTLS", name: "FIRST TRUST LONG/SHORT EQTY" },
  { ticker: "FTNT", name: "FORTINET INC" },
  { ticker: "FTR", name: "FRONTIER COMMUNICATIONS CORP" },
  { ticker: "FTRI", name: "FIRST TRUST INDXX GLOBAL NAT" },
  { ticker: "FTS", name: "FORTIS INC" },
  { ticker: "FTSD", name: "FRANKLIN LIBERTY SHORT DURAT" },
  { ticker: "FTSI", name: "FTS INTERNATIONAL INC" },
  { ticker: "FTSL", name: "FIRST TRUST SENIOR LOAN ETF" },
  { ticker: "FTSM", name: "FIRST TRUST ENH SHORT MAT FD" },
  { ticker: "FTSV", name: "FORTY SEVEN INC" },
  { ticker: "FTV", name: "FORTIVE CORP" },
  { ticker: "FTXD", name: "FIRST TRUST NASDAQ RETAIL ET" },
  { ticker: "FTXG", name: "FIRST TRUST NASDAQ FOOD & BE" },
  { ticker: "FTXH", name: "FIRST TRUST NASDAQ PHARMACEU" },
  { ticker: "FTXL", name: "FIRST TRUST NASDAQ SEMICONDU" },
  { ticker: "FTXN", name: "FIRST TRUST NASDAQ OIL & GAS" },
  { ticker: "FTXO", name: "FIRST TRUST NASDAQ BANK ETF" },
  { ticker: "FTXR", name: "FIRST TRUST NASDAQ TRANSPORT" },
  { ticker: "FUD", name: "ETRACS CMCI FOOD TR ETN" },
  { ticker: "FUE", name: "ELEMENTS-MLCX BIOFUELS INDX" },
  { ticker: "FUL", name: "H.B. FULLER CO." },
  { ticker: "FULC", name: "FULCRUM THERAPEUTICS INC" },
  { ticker: "FULT", name: "FULTON FINANCIAL CORP" },
  { ticker: "FUMB", name: "FIRST TRUST ULTRA SHORT DUR" },
  { ticker: "FUN", name: "CEDAR FAIR LP" },
  { ticker: "FUNC", name: "FIRST UNITED CORP" },
  { ticker: "FUND", name: "SPROTT FOCUS TRUST INC" },
  { ticker: "FUSB", name: "FIRST US BANCSHARES INC" },
  { ticker: "FUT", name: "PROSHARES MANAGED FUTURES ST" },
  { ticker: "FUTU", name: "FUTU HOLDINGS LTD-ADR" },
  { ticker: "FUTY", name: "FIDELITY US UTILITIES ETF" },
  { ticker: "FUV", name: "ARCIMOTO INC" },
  { ticker: "FV", name: "FIRST TRUST DW FOCUS 5 FUND" },
  { ticker: "FVAL", name: "FIDELITY VALUE FACTOR ETF" },
  { ticker: "FVC", name: "FIRST TRUST DORSEY WRIGHT DY" },
  { ticker: "FVCB", name: "FVCBANKCORP INC" },
  { ticker: "FVD", name: "FIRST TRUST VALUE LINE DVD" },
  { ticker: "FVE", name: "FIVE STAR SENIOR LIVING INC" },
  { ticker: "FVL", name: "FIRST TRUST VALUE LINE 100" },
  { ticker: "FVRR", name: "FIVERR INTERNATIONAL LTD" },
  { ticker: "FWDB", name: "ADVISORSHARES FOLIOBEYOND SM" },
  { ticker: "FWONA", name: "LIBERTY MEDIA CORP-LIBERTY-A" },
  { ticker: "FWONK", name: "LIBERTY MEDIA CORP-LIBERTY-C" },
  { ticker: "FWP", name: "FORWARD PHARMA A/S-ADR" },
  { ticker: "FWRD", name: "FORWARD AIR CORP" },
  { ticker: "FXA", name: "INVESCO CURRENCYSHARES AUSTR" },
  { ticker: "FXB", name: "INVESCO CURRENCYSHARES BRITI" },
  { ticker: "FXC", name: "INVESCO CURRENCYSHARES CANAD" },
  { ticker: "FXD", name: "FIRST TRUST CONSUMER DISCRET" },
  { ticker: "FXE", name: "INVESCO CURRENCYSHARES EURO" },
  { ticker: "FXF", name: "INVESCO CURRENCYSHARES SWISS" },
  { ticker: "FXG", name: "FIRST TRUST CONSUMER STAPLES" },
  { ticker: "FXH", name: "FIRST TRUST HEALTH CARE ALPH" },
  { ticker: "FXI", name: "ISHARES CHINA LARGE-CAP ETF" },
  { ticker: "FXL", name: "FIRST TRUST TECHNOLOGY ALPHA" },
  { ticker: "FXN", name: "FIRST TRUST ENERGY ALPHADEX" },
  { ticker: "FXNC", name: "FIRST NATIONAL CORP/VA" },
  { ticker: "FXO", name: "FIRST TRUST FINANCIAL ALPHAD" },
  { ticker: "FXP", name: "PROSHARES ULTRASHORT FTSE CH" },
  { ticker: "FXR", name: "FIRST TRUST INDST/PRODUCERS" },
  { ticker: "FXU", name: "FIRST TRUST UTILITIES ALPHAD" },
  { ticker: "FXY", name: "INVESCO CURRENCYSHARES JAPAN" },
  { ticker: "FXZ", name: "FIRST TRUST MATERIALS ALPHAD" },
  { ticker: "FYC", name: "FIRST TRUST SMALL CAP GROWTH" },
  { ticker: "FYLD", name: "CAMBRIA FOREIGN SHAREHOLDER" },
  { ticker: "FYT", name: "FIRST TRUST SMALL CAP VAL" },
  { ticker: "FYX", name: "FIRST TRUST SMALL CAP CORE A" },
  { ticker: "G", name: "GENPACT LTD" },
  { ticker: "GAA", name: "CAMBRIA GLOBAL ASSET ALLOCAT" },
  { ticker: "GAB", name: "GABELLI EQUITY TRUST" },
  { ticker: "GABC", name: "GERMAN AMERICAN BANCORP" },
  { ticker: "GAIA", name: "GAIA INC" },
  { ticker: "GAIN", name: "GLADSTONE INVESTMENT CORP" },
  { ticker: "GAL", name: "SPDR SSGA GLOBAL ALLOCATION" },
  { ticker: "GALT", name: "GALECTIN THERAPEUTICS INC" },
  { ticker: "GAM", name: "GENERAL AMERICAN INVESTORS" },
  { ticker: "GAMR", name: "ETFMG VIDEO GAME TECH ETF" },
  { ticker: "GARD", name: "REALITY SH DIVCON DVD GD ETF" },
  { ticker: "GARS", name: "GARRISON CAPITAL INC" },
  { ticker: "GASL", name: "DIREXION DLY NAT GAS BULL 3X" },
  { ticker: "GASS", name: "STEALTHGAS INC" },
  { ticker: "GASX", name: "DIREXION DLY NAT GAS BEAR 3X" },
  { ticker: "GATX", name: "GATX CORP" },
  { ticker: "GAZ", name: "IPATH SER B BBG NAT GAS TR" },
  { ticker: "GBAB", name: "GUGGENHEIM TAXABLE MUNICIPAL" },
  { ticker: "GBCI", name: "GLACIER BANCORP INC" },
  { ticker: "GBDC", name: "GOLUB CAPITAL BDC INC" },
  { ticker: "GBDV", name: "GLOBAL BETA SMART INCOME ETF" },
  { ticker: "GBF", name: "ISHARES GOVERNMENT/CREDIT BO" },
  { ticker: "GBIL", name: "GOLDMAN SACHS ACCESS TREASUR" },
  { ticker: "GBL", name: "GAMCO INVESTORS INC-A" },
  { ticker: "GBLI", name: "GLOBAL INDEMNITY LTD" },
  { ticker: "GBR", name: "NEW CONCEPT ENERGY INC" },
  { ticker: "GBT", name: "GLOBAL BLOOD THERAPEUTICS IN" },
  { ticker: "GBUG", name: "IPATH GOLD ETN" },
  { ticker: "GBUY", name: "GS MOTIF NEW AGE CONSUMER" },
  { ticker: "GBX", name: "GREENBRIER COMPANIES INC" },
  { ticker: "GCAP", name: "GAIN CAPITAL HOLDINGS INC" },
  { ticker: "GCBC", name: "GREENE COUNTY BANCORP INC" },
  { ticker: "GCC", name: "WISDOMTREE CONTINUOUS COMMOD" },
  { ticker: "GCE", name: "CLAYMORE CEF GS CONNECT ETN" },
  { ticker: "GCI", name: "GANNETT CO INC" },
  { ticker: "GCO", name: "GENESCO INC" },
  { ticker: "GCOW", name: "PACER GLOBAL CASH COWS DIVID" },
  { ticker: "GCP", name: "GCP APPLIED TECHNOLOGIES" },
  { ticker: "GCV", name: "GABELLI CONV AND INCOME SEC" },
  { ticker: "GD", name: "GENERAL DYNAMICS CORP" },
  { ticker: "GDAT", name: "GS MOTIF DATA-DRIVEN WD ETF" },
  { ticker: "GDDY", name: "GODADDY INC - CLASS A" },
  { ticker: "GDEN", name: "GOLDEN ENTERTAINMENT INC" },
  { ticker: "GDL", name: "GDL FUND/THE" },
  { ticker: "GDMA", name: "GADSDEN DYNAMIC MULTI-ASSET" },
  { ticker: "GDNA", name: "GS MOTIF HUMAN EVOLUTION ETF" },
  { ticker: "GDO", name: "WESTERN ASSET GL CORP DEF OP" },
  { ticker: "GDOT", name: "GREEN DOT CORP-CLASS A" },
  { ticker: "GDP", name: "GOODRICH PETROLEUM CORP" },
  { ticker: "GDS", name: "GDS HOLDINGS LTD - ADR" },
  { ticker: "GDV", name: "GABELLI DIVIDEND & INCOME TR" },
  { ticker: "GDVD", name: "PRNCPAL ACTV GLBL DV INC ETF" },
  { ticker: "GDX", name: "VANECK GOLD MINERS" },
  { ticker: "GDXJ", name: "VANECK JR GOLD MINERS" },
  { ticker: "GDYN", name: "GRID DYNAMICS HOLDINGS INC" },
  { ticker: "GE", name: "GENERAL ELECTRIC CO" },
  { ticker: "GEC", name: "GREAT ELM CAPITAL GROUP INC" },
  { ticker: "GECC", name: "GREAT ELM CAPITAL CORP" },
  { ticker: "GEF", name: "GREIF INC-CL A" },
  { ticker: "GEF.B", name: "GREIF INC-CL B" },
  { ticker: "GEL", name: "GENESIS ENERGY L.P." },
  { ticker: "GEM", name: "GOLDMAN SACHS ACTIVEBETA EM" },
  { ticker: "GEN", name: "GENESIS HEALTHCARE INC" },
  { ticker: "GENC", name: "GENCOR INDUSTRIES INC" },
  { ticker: "GENE", name: "GENETIC TECH LTD-SP ADR" },
  { ticker: "GENY", name: "PRINCIPAL MILLENNIALS ETF" },
  { ticker: "GEO", name: "GEO GROUP INC/THE" },
  { ticker: "GEOS", name: "GEOSPACE TECHNOLOGIES CORP" },
  { ticker: "GER", name: "GOLDMAN SACHS MLP & ENERGY R" },
  { ticker: "GERN", name: "GERON CORP" },
  { ticker: "GES", name: "GUESS? INC" },
  { ticker: "GEVO", name: "GEVO INC" },
  { ticker: "GF", name: "NEW GERMANY FUND" },
  { ticker: "GFED", name: "GUARANTY FEDERAL BNCSHS INC" },
  { ticker: "GFF", name: "GRIFFON CORP" },
  { ticker: "GFI", name: "GOLD FIELDS LTD-SPONS ADR" },
  { ticker: "GFIN", name: "GS MOTIF FINANCE REIMAGINED" },
  { ticker: "GFL", name: "GFL ENVIRONMENTAL INC-SUB VT" },
  { ticker: "GFN", name: "GENERAL FINANCE CORP" },
  { ticker: "GFY", name: "WESTERN ASSET VAR RT STRAT" },
  { ticker: "GGAL", name: "GRUPO FINANCIERO GALICIA-ADR" },
  { ticker: "GGB", name: "GERDAU SA -SPON ADR" },
  { ticker: "GGG", name: "GRACO INC" },
  { ticker: "GGM", name: "GUGGENHEIM CREDIT ALLOCATION" },
  { ticker: "GGN", name: "GAMCO GLOBAL GOLD NATURAL RE" },
  { ticker: "GGO", name: "GABELLI GO ANYWHERE TRUST" },
  { ticker: "GGT", name: "GABELLI MULTIMEDIA TRUST INC" },
  { ticker: "GGZ", name: "GABELLI GLOBAL SMALL & M" },
  { ticker: "GH", name: "GUARDANT HEALTH INC" },
  { ticker: "GHC", name: "GRAHAM HOLDINGS CO-CLASS B" },
  { ticker: "GHG", name: "GREENTREE HOSPITALITY GR-ADR" },
  { ticker: "GHIVU", name: "GORES HOLDINGS IV INC" },
  { ticker: "GHL", name: "GREENHILL & CO INC" },
  { ticker: "GHM", name: "GRAHAM CORP" },
  { ticker: "GHSI", name: "GUARDION HEALTH SCIENCES INC" },
  { ticker: "GHY", name: "PGIM GLOBAL HIGH YIELD FUND" },
  { ticker: "GHYB", name: "GOLDMAN SACHS ACCESS HY CORP" },
  { ticker: "GHYG", name: "ISHARES US&INTL HIGH YIELD C" },
  { ticker: "GIB", name: "CGI INC" },
  { ticker: "GIFI", name: "GULF ISLAND FABRICATION INC" },
  { ticker: "GIGB", name: "GOLDMAN SACHS INV GRD CORP" },
  { ticker: "GIGE", name: "SOFI GIG ECONOMY ETF" },
  { ticker: "GIGM", name: "GIGAMEDIA LTD" },
  { ticker: "GII", name: "SPDR S&P GLOBAL INFRASTRUCTU" },
  { ticker: "GIII", name: "G-III APPAREL GROUP LTD" },
  { ticker: "GIL", name: "GILDAN ACTIVEWEAR INC" },
  { ticker: "GILD", name: "GILEAD SCIENCES INC" },
  { ticker: "GILT", name: "GILAT SATELLITE NETWORKS LTD" },
  { ticker: "GIM", name: "TEMPLETON GLOBAL INCOME FUND" },
  { ticker: "GIS", name: "GENERAL MILLS INC" },
  { ticker: "GIX", name: "GIGCAPITAL2 INC" },
  { ticker: "GIX=", name: "GIGCAPITAL2 INC" },
  { ticker: "GIX^", name: "GIGCAPITAL2 INC - RIGHTS" },
  { ticker: "GKOS", name: "GLAUKOS CORP" },
  { ticker: "GL", name: "GLOBE LIFE INC" },
  { ticker: "GLAD", name: "GLADSTONE CAPITAL CORP" },
  { ticker: "GLBS", name: "GLOBUS MARITIME LIMITED" },
  { ticker: "GLBY", name: "WISDOMTREE YL EHD GBL AGG BD" },
  { ticker: "GLBZ", name: "GLEN BURNIE BANCORP" },
  { ticker: "GLD", name: "SPDR GOLD SHARES" },
  { ticker: "GLDD", name: "GREAT LAKES DREDGE & DOCK CO" },
  { ticker: "GLDI", name: "X-LINKS GOLD SHRS COVRD CALL" },
  { ticker: "GLDM", name: "SPDR GOLD MINISHARES TRUST" },
  { ticker: "GLEO", name: "GALILEO ACQUISITION CORP" },
  { ticker: "GLEO=", name: "GALILEO ACQUISITION CORP" },
  { ticker: "GLG", name: "BAT GROUP INC" },
  { ticker: "GLIBA", name: "GCI LIBERTY INC - CLASS A" },
  { ticker: "GLIF", name: "AGFIQ GLOBAL INFRASTRUCTURE" },
  { ticker: "GLL", name: "PROSHARES ULTRASHORT GOLD" },
  { ticker: "GLMD", name: "GALMED PHARMACEUTICALS LTD" },
  { ticker: "GLNG", name: "GOLAR LNG LTD" },
  { ticker: "GLO", name: "CLOUGH GLBL OPPORTUNITIES FD" },
  { ticker: "GLOB", name: "GLOBANT SA" },
  { ticker: "GLOG", name: "GASLOG LTD" },
  { ticker: "GLOP", name: "GASLOG PARTNERS LP" },
  { ticker: "GLP", name: "GLOBAL PARTNERS LP" },
  { ticker: "GLPG", name: "GALAPAGOS NV-SPON ADR" },
  { ticker: "GLPI", name: "GAMING AND LEISURE PROPERTIE" },
  { ticker: "GLQ", name: "CLOUGH GLOBAL EQUITY FUND" },
  { ticker: "GLRE", name: "GREENLIGHT CAPITAL RE LTD-A" },
  { ticker: "GLT", name: "GLATFELTER" },
  { ticker: "GLTR", name: "ABERDEEN STANDARD PHYSICAL P" },
  { ticker: "GLU", name: "GABELLI GLOBAL UTIL & INCOME" },
  { ticker: "GLUU", name: "GLU MOBILE INC" },
  { ticker: "GLV", name: "CLOUGH GLOBAL DIVIDEND AND I" },
  { ticker: "GLW", name: "CORNING INC" },
  { ticker: "GLYC", name: "GLYCOMIMETICS INC" },
  { ticker: "GM", name: "GENERAL MOTORS CO" },
  { ticker: "GMAB", name: "GENMAB A/S -SP ADR" },
  { ticker: "GMAN", name: "GS MOTIF MANUFACT REVOLUTION" },
  { ticker: "GMDA", name: "GAMIDA CELL LTD" },
  { ticker: "GME", name: "GAMESTOP CORP-CLASS A" },
  { ticker: "GMED", name: "GLOBUS MEDICAL INC - A" },
  { ticker: "GMF", name: "SPDR S&P EMERGING ASIA PACIF" },
  { ticker: "GMHI", name: "GORES METROPOULOS INC-CL A" },
  { ticker: "GMHIU", name: "GORES METROPOULOS INC" },
  { ticker: "GMLP", name: "GOLAR LNG PARTNERS LP" },
  { ticker: "GMO", name: "GENERAL MOLY INC" },
  { ticker: "GMOM", name: "CAMBRIA GLOBAL MOMENTUM ETF" },
  { ticker: "GMRE", name: "GLOBAL MEDICAL REIT INC" },
  { ticker: "GMS", name: "GMS INC" },
  { ticker: "GMZ", name: "GOLDMAN SACHS MLP INCOME OPP" },
  { ticker: "GNAF", name: "MICROSECTORS FANG INDEX INVE" },
  { ticker: "GNC", name: "GNC HOLDINGS INC-CL A" },
  { ticker: "GNCA", name: "GENOCEA BIOSCIENCES INC" },
  { ticker: "GNE", name: "GENIE ENERGY LTD-B" },
  { ticker: "GNFT", name: "GENFIT" },
  { ticker: "GNK", name: "GENCO SHIPPING & TRADING LTD" },
  { ticker: "GNL", name: "GLOBAL NET LEASE INC" },
  { ticker: "GNLN", name: "GREENLANE HOLDINGS INC - A" },
  { ticker: "GNMA", name: "ISHARES GNMA BOND ETF" },
  { ticker: "GNMK", name: "GENMARK DIAGNOSTICS INC" },
  { ticker: "GNOM", name: "GLOBAL X GENOMICS & BIOTECHN" },
  { ticker: "GNPX", name: "GENPREX INC" },
  { ticker: "GNR", name: "SPDR S&P GL NAT RESOURCES" },
  { ticker: "GNRC", name: "GENERAC HOLDINGS INC" },
  { ticker: "GNRSU", name: "GREENROSE ACQUISITION CORP" },
  { ticker: "GNSS", name: "GENASYS INC" },
  { ticker: "GNT", name: "GAMCO NATURAL RESOURCES GOLD" },
  { ticker: "GNTX", name: "GENTEX CORP" },
  { ticker: "GNTY", name: "GUARANTY BANCSHARES INC" },
  { ticker: "GNUS", name: "GENIUS BRANDS INTERNATIONAL" },
  { ticker: "GNW", name: "GENWORTH FINANCIAL INC-CL A" },
  { ticker: "GO", name: "GROCERY OUTLET HOLDING CORP" },
  { ticker: "GOAT", name: "VANECK GLBL WIDE MOAT" },
  { ticker: "GOAU", name: "US GLB GLD & METAL MNRS ETF" },
  { ticker: "GOEX", name: "GLOBAL X GOLD EXPLORERS ETF" },
  { ticker: "GOF", name: "GUGGENHEIM STRATEGIC OPPORTU" },
  { ticker: "GOGL", name: "GOLDEN OCEAN GROUP LTD" },
  { ticker: "GOGO", name: "GOGO INC" },
  { ticker: "GOL", name: "GOL LINHAS AEREAS INTEL-ADR" },
  { ticker: "GOLD", name: "BARRICK GOLD CORP" },
  { ticker: "GOLF", name: "ACUSHNET HOLDINGS CORP" },
  { ticker: "GOOD", name: "GLADSTONE COMMERCIAL CORP" },
  { ticker: "GOOG", name: "ALPHABET INC-CL C" },
  { ticker: "GOOGL", name: "ALPHABET INC-CL A" },
  { ticker: "GOOS", name: "CANADA GOOSE HOLDINGS INC" },
  { ticker: "GORO", name: "GOLD RESOURCE CORP" },
  { ticker: "GOSS", name: "GOSSAMER BIO INC" },
  { ticker: "GOVT", name: "ISHARES US TREASURY BOND ETF" },
  { ticker: "GPAQ", name: "GORDON POINTE ACQUISITION CO" },
  { ticker: "GPAQU", name: "GORDON POINTE ACQUISITION CO" },
  { ticker: "GPC", name: "GENUINE PARTS CO" },
  { ticker: "GPI", name: "GROUP 1 AUTOMOTIVE INC" },
  { ticker: "GPK", name: "GRAPHIC PACKAGING HOLDING CO" },
  { ticker: "GPL", name: "GREAT PANTHER MINING LTD" },
  { ticker: "GPM", name: "GUGGENHEIM ENHANCED EQUITY I" },
  { ticker: "GPMT", name: "GRANITE POINT MORTGAGE TRUST" },
  { ticker: "GPN", name: "GLOBAL PAYMENTS INC" },
  { ticker: "GPOR", name: "GULFPORT ENERGY CORP" },
  { ticker: "GPP", name: "GREEN PLAINS PARTNERS LP" },
  { ticker: "GPRE", name: "GREEN PLAINS INC" },
  { ticker: "GPRK", name: "GEOPARK LTD" },
  { ticker: "GPRO", name: "GOPRO INC-CLASS A" },
  { ticker: "GPS", name: "GAP INC/THE" },
  { ticker: "GPX", name: "GP STRATEGIES CORP" },
  { ticker: "GQRE", name: "FLEXSHARES GLOBAL QUALITY RE" },
  { ticker: "GRA", name: "WR GRACE & CO" },
  { ticker: "GRAF", name: "GRAF INDUSTRIAL CORP" },
  { ticker: "GRAF=", name: "GRAF INDUSTRIAL CORP" },
  { ticker: "GRAM", name: "GRANA Y MONTERO SA -SPON ADR" },
  { ticker: "GRBK", name: "GREEN BRICK PARTNERS INC" },
  { ticker: "GRC", name: "GORMAN-RUPP CO" },
  { ticker: "GREK", name: "GLOBAL X MSCI GREECE ETF" },
  { ticker: "GRES", name: "IQ GLOBAL RESOURCES ETF" },
  { ticker: "GRF", name: "EAGLE CAPITAL GROWTH FUND" },
  { ticker: "GRFS", name: "GRIFOLS SA-ADR" },
  { ticker: "GRID", name: "FIRST TRST NASD CL EDG SGIIF" },
  { ticker: "GRIF", name: "GRIFFIN INDUSTRIAL REALTY IN" },
  { ticker: "GRIL", name: "MUSCLE MAKER INC" },
  { ticker: "GRIN", name: "GRINDROD SHIPPING HOLDINGS L" },
  { ticker: "GRMN", name: "GARMIN LTD" },
  { ticker: "GRN", name: "IPATH SERIES B CARBON ETN" },
  { ticker: "GRNB", name: "VANECK GREEN BOND" },
  { ticker: "GRNQ", name: "GREENPRO CAPITAL CORP" },
  { ticker: "GRNV", name: "GREENVISION ACQUISITION CORP" },
  { ticker: "GRNVR", name: "GREENVISION ACQU CORP-RGT" },
  { ticker: "GRNVU", name: "GREENVISION ACQUISITION CORP" },
  { ticker: "GROW", name: "U.S. GLOBAL INVESTORS INC-A" },
  { ticker: "GRP=", name: "GRANITE REAL ESTATE INVESTME" },
  { ticker: "GRPN", name: "GROUPON INC" },
  { ticker: "GRTS", name: "GRITSTONE ONCOLOGY INC" },
  { ticker: "GRTX", name: "GALERA THERAPEUTICS INC" },
  { ticker: "GRU", name: "ELEMENTS-MLCX GRAINS INDX TR" },
  { ticker: "GRUB", name: "GRUBHUB INC" },
  { ticker: "GRVY", name: "GRAVITY CO LTD-SPONSORED ADR" },
  { ticker: "GRWG", name: "GROWGENERATION CORP" },
  { ticker: "GRX", name: "GABELLI HEALTHCARE&WELLNESS" },
  { ticker: "GS", name: "GOLDMAN SACHS GROUP INC" },
  { ticker: "GSAT", name: "GLOBALSTAR INC" },
  { ticker: "GSB", name: "GLOBALSCAPE INC" },
  { ticker: "GSBC", name: "GREAT SOUTHERN BANCORP INC" },
  { ticker: "GSBD", name: "GOLDMAN SACHS BDC INC" },
  { ticker: "GSC", name: "GS CONNECT S&P GSCI ENH COMM" },
  { ticker: "GSEU", name: "GOLDMAN ACTIVEBTA EUR EQ ETF" },
  { ticker: "GSEW", name: "GOLDMAN SACHS EQL WGHT LARGE" },
  { ticker: "GSG", name: "ISHARES S&P GSCI COMMODITY I" },
  { ticker: "GSH", name: "GUANGSHEN RAILWAY-SPONS ADR" },
  { ticker: "GSHD", name: "GOOSEHEAD INSURANCE INC -A" },
  { ticker: "GSIE", name: "GOLDMAN SACHS ACTIVEBETA INT" },
  { ticker: "GSIT", name: "GSI TECHNOLOGY INC" },
  { ticker: "GSJY", name: "GOLDMAN ACTIVEBETA JP EQ ETF" },
  { ticker: "GSK", name: "GLAXOSMITHKLINE PLC-SPON ADR" },
  { ticker: "GSKY", name: "GREENSKY INC-CLASS A" },
  { ticker: "GSL", name: "GLOBAL SHIP LEASE INC-CL A" },
  { ticker: "GSLC", name: "GOLDMAN ACTIVEBETA US LC ETF" },
  { ticker: "GSM", name: "FERROGLOBE PLC" },
  { ticker: "GSMG", name: "GLORY STAR NEW MEDIA GROUP H" },
  { ticker: "GSP", name: "IPATH GSCI TOTAL RETURN INDX" },
  { ticker: "GSS", name: "GOLDEN STAR RESOURCES LTD" },
  { ticker: "GSSC", name: "GOLDMAN SACHS ACTIVEBETA US" },
  { ticker: "GSST", name: "GOLDMAN SACHS ACCESS U/SHORT" },
  { ticker: "GSUM", name: "GRIDSUM HOLDING INC-ADR" },
  { ticker: "GSV", name: "GOLD STANDARD VENTURES CORP" },
  { ticker: "GSX", name: "GSX TECHEDU INC- ADR" },
  { ticker: "GSY", name: "INVESCO ULTRA SHORT DURATION" },
  { ticker: "GT", name: "GOODYEAR TIRE & RUBBER CO" },
  { ticker: "GTE", name: "GRAN TIERRA ENERGY INC" },
  { ticker: "GTEC", name: "GREENLAND TECHNOLOGIES HOLDI" },
  { ticker: "GTES", name: "GATES INDUSTRIAL CORP PLC" },
  { ticker: "GTHX", name: "G1 THERAPEUTICS INC" },
  { ticker: "GTIM", name: "GOOD TIMES RESTAURANTS INC" },
  { ticker: "GTIP", name: "GOLDMAN SACHS ACCESS INFLATI" },
  { ticker: "GTLS", name: "CHART INDUSTRIES INC" },
  { ticker: "GTN", name: "GRAY TELEVISION INC" },
  { ticker: "GTN.A", name: "GRAY TELEVISION INC-A" },
  { ticker: "GTO", name: "INVESCO TOTAL RETURN BOND ET" },
  { ticker: "GTS", name: "TRIPLE-S MANAGEMENT CORP-B" },
  { ticker: "GTT", name: "GTT COMMUNICATIONS INC" },
  { ticker: "GTX", name: "GARRETT MOTION INC" },
  { ticker: "GTY", name: "GETTY REALTY CORP" },
  { ticker: "GTYH", name: "GTY TECHNOLOGY HOLDINGS INC" },
  { ticker: "GUDB", name: "SAGE ESG INTERMEDIATE CREDIT" },
  { ticker: "GULF", name: "WISDOMTREE MIDDLE EAST DVD" },
  { ticker: "GUNR", name: "FLEXSHARES GLOBAL UPSTREAM N" },
  { ticker: "GURE", name: "GULF RESOURCES INC" },
  { ticker: "GURU", name: "GLOBAL X GURU INDEX ETF" },
  { ticker: "GUSH", name: "DRX DLY OIL & GAS BULL 3X" },
  { ticker: "GUT", name: "GABELLI UTILITY TRUST" },
  { ticker: "GV", name: "GOLDFIELD CORP" },
  { ticker: "GVA", name: "GRANITE CONSTRUCTION INC" },
  { ticker: "GVAL", name: "CAMBRIA GLOBAL VALUE ETF" },
  { ticker: "GVI", name: "ISHARES INTERMEDIATE GOVERNM" },
  { ticker: "GVIP", name: "GOLD SACHS HEDGE IND VIP ETF" },
  { ticker: "GVP", name: "GSE SYSTEMS INC" },
  { ticker: "GWB", name: "GREAT WESTERN BANCORP INC" },
  { ticker: "GWGH", name: "GWG HOLDINGS INC" },
  { ticker: "GWPH", name: "GW PHARMACEUTICALS -ADR" },
  { ticker: "GWRE", name: "GUIDEWIRE SOFTWARE INC" },
  { ticker: "GWRS", name: "GLOBAL WATER RESOURCES INC" },
  { ticker: "GWW", name: "WW GRAINGER INC" },
  { ticker: "GWX", name: "SPDR S&P INTL SMALL CAP" },
  { ticker: "GXC", name: "SPDR S&P CHINA ETF" },
  { ticker: "GXF", name: "GLOBAL X FTSE NORDIC REGION" },
  { ticker: "GXG", name: "GLOBAL X MSCI COLOMBIA ETF" },
  { ticker: "GXGX", name: "GX ACQUISITION CORP - CL A" },
  { ticker: "GXGXU", name: "GX ACQUISITION CORP" },
  { ticker: "GXTG", name: "GLOBAL X THEMATIC GROWTH ETF" },
  { ticker: "GYLD", name: "ARROW DOW JONES GLOBAL YIELD" },
  { ticker: "GYRO", name: "GYRODYNE LLC" },
  { ticker: "H", name: "HYATT HOTELS CORP - CL A" },
  { ticker: "HA", name: "HAWAIIAN HOLDINGS INC" },
  { ticker: "HABT", name: "HABIT RESTAURANTS INC/THE-A" },
  { ticker: "HACK", name: "ETFMG PRIME CYBER SECURITY E" },
  { ticker: "HAE", name: "HAEMONETICS CORP/MASS" },
  { ticker: "HAFC", name: "HANMI FINANCIAL CORPORATION" },
  { ticker: "HAIL", name: "SPDR S&P KENSHO SMART MOBILI" },
  { ticker: "HAIN", name: "HAIN CELESTIAL GROUP INC" },
  { ticker: "HAL", name: "HALLIBURTON CO" },
  { ticker: "HALL", name: "HALLMARK FINL SERVICES INC" },
  { ticker: "HALO", name: "HALOZYME THERAPEUTICS INC" },
  { ticker: "HAP", name: "VANECK NATURAL RESOURCES" },
  { ticker: "HAPP", name: "HAPPINESS BIOTECH GROUP LTD" },
  { ticker: "HARP", name: "HARPOON THERAPEUTICS INC" },
  { ticker: "HAS", name: "HASBRO INC" },
  { ticker: "HASI", name: "HANNON ARMSTRONG SUSTAINABLE" },
  { ticker: "HAUD", name: "ISHARES CURR HDG MSCI AUSTRA" },
  { ticker: "HAUZ", name: "XTRACKERS INTL REAL ESTATE" },
  { ticker: "HAWX", name: "ISHARES CUR HDG MSCI ACWI-X" },
  { ticker: "HAYN", name: "HAYNES INTERNATIONAL INC" },
  { ticker: "HBAN", name: "HUNTINGTON BANCSHARES INC" },
  { ticker: "HBB", name: "HAMILTON BEACH BRAND-A" },
  { ticker: "HBCP", name: "HOME BANCORP INC" },
  { ticker: "HBI", name: "HANESBRANDS INC" },
  { ticker: "HBIO", name: "HARVARD BIOSCIENCE INC" },
  { ticker: "HBM", name: "HUDBAY MINERALS INC" },
  { ticker: "HBMD", name: "HOWARD BANCORP INC" },
  { ticker: "HBNC", name: "HORIZON BANCORP INC/IN" },
  { ticker: "HBP", name: "HUTTIG BUILDING PRODUCTS INC" },
  { ticker: "HBT", name: "HBT FINANCIAL INC/DE" },
  { ticker: "HCA", name: "HCA HEALTHCARE INC" },
  { ticker: "HCAC", name: "HENNESSY CAPITAL ACQUISITI-A" },
  { ticker: "HCACU", name: "HENNESSY CAPITAL ACQUISITION" },
  { ticker: "HCAP", name: "HARVEST CAPITAL CREDIT CORP" },
  { ticker: "HCAT", name: "HEALTH CATALYST INC" },
  { ticker: "HCC", name: "WARRIOR MET COAL INC" },
  { ticker: "HCCH", name: "HL ACQUISITIONS CORP" },
  { ticker: "HCCHR", name: "HL ACQUISITIONS CORP-RIGHTS" },
  { ticker: "HCCHU", name: "HL ACQUISITIONS CORP" },
  { ticker: "HCCI", name: "HERITAGE-CRYSTAL CLEAN INC" },
  { ticker: "HCCO", name: "HEALTHCARE MERGER CORP-A" },
  { ticker: "HCCOU", name: "HEALTHCARE MERGER CORP" },
  { ticker: "HCFT", name: "HUNT COMPANIES FINANCE TRUST" },
  { ticker: "HCHC", name: "HC2 HOLDINGS INC" },
  { ticker: "HCI", name: "HCI GROUP INC" },
  { ticker: "HCKT", name: "HACKETT GROUP INC/THE" },
  { ticker: "HCM", name: "HUTCHISON CHINA MEDITECH-ADR" },
  { ticker: "HCR", name: "HI-CRUSH INC" },
  { ticker: "HCRB", name: "HARTFORD CORE BOND ETF" },
  { ticker: "HCSG", name: "HEALTHCARE SERVICES GROUP" },
  { ticker: "HD", name: "HOME DEPOT INC" },
  { ticker: "HDAW", name: "XTRACKERS MSCI ALL WORLD EX" },
  { ticker: "HDB", name: "HDFC BANK LTD-ADR" },
  { ticker: "HDEF", name: "XTRACKERS MSCI EAFE HIGH DIV" },
  { ticker: "HDG", name: "PROSHARES HEDGE REPLICAT ETF" },
  { ticker: "HDGE", name: "ADVISORSHARES RANGER EQ BEAR" },
  { ticker: "HDIV", name: "QRAFT AI-ENHANCED US HIGH DI" },
  { ticker: "HDLB", name: "ETRACS 2X HI DIV L-VOL ETN-B" },
  { ticker: "HDLV", name: "ETRACS 2X HI DIV LOW VOL ETN" },
  { ticker: "HDMV", name: "FT HORIZON MGD VOL D INT ETF" },
  { ticker: "HDS", name: "HD SUPPLY HOLDINGS INC" },
  { ticker: "HDSN", name: "HUDSON TECHNOLOGIES INC" },
  { ticker: "HDV", name: "ISHARES CORE HIGH DIVIDEND E" },
  { ticker: "HE", name: "HAWAIIAN ELECTRIC INDS" },
  { ticker: "HEAR", name: "TURTLE BEACH CORP" },
  { ticker: "HEBT", name: "HEBRON TECHNOLOGY CO LTD - A" },
  { ticker: "HECO", name: "STRATEGY SHARES ECOLOGICAL S" },
  { ticker: "HEDJ", name: "WISDOMTREE EUROPE HEDGED EQU" },
  { ticker: "HEEM", name: "ISHARES CRNCY HEDGD MSCI EM" },
  { ticker: "HEES", name: "H&E EQUIPMENT SERVICES INC" },
  { ticker: "HEFA", name: "ISHA CURR HEDGED MSCI EAFE" },
  { ticker: "HEI", name: "HEICO CORP" },
  { ticker: "HEI.A", name: "HEICO CORP-CLASS A" },
  { ticker: "HELE", name: "HELEN OF TROY LTD" },
  { ticker: "HELX", name: "FRANKLIN GENOMIC ADVANCEMENT" },
  { ticker: "HEP", name: "HOLLY ENERGY PARTNERS LP" },
  { ticker: "HEPA", name: "HEPION PHARMACEUTICALS INC" },
  { ticker: "HEQ", name: "JOHN HANCOCK HEDGED EQUITY &" },
  { ticker: "HERD", name: "PACER CASH COWS FUND OF FUND" },
  { ticker: "HERO", name: "GLOBAL X VIDEO GAMES& ESPORT" },
  { ticker: "HES", name: "HESS CORP" },
  { ticker: "HESM", name: "HESS MIDSTREAM LP - CLASS A" },
  { ticker: "HEWC", name: "ISHARES CURR HDG MSCI CANADA" },
  { ticker: "HEWG", name: "ISHA HEDGED MSCI GERMANY" },
  { ticker: "HEWI", name: "ISHARES CURR HEDG MSCI ITALY" },
  { ticker: "HEWJ", name: "ISHA CURR HEDGED MSCI JAPAN" },
  { ticker: "HEWL", name: "ISHARES CURR HEDG MSCI SWITZ" },
  { ticker: "HEWP", name: "ISHARES CURR HEDG MSCI SPAIN" },
  { ticker: "HEWU", name: "ISHARES CURR HEDGED MSCI UK" },
  { ticker: "HEWW", name: "ISHARES CURR HDG MSCI MEXICO" },
  { ticker: "HEWY", name: "ISHARES CUR HDG MSCI S KOREA" },
  { ticker: "HEXO", name: "HEXO CORP" },
  { ticker: "HEZU", name: "ISHARES CURRENCY HEDGED MSCI" },
  { ticker: "HFBL", name: "HOME FEDERAL BANCORP INC/LA" },
  { ticker: "HFC", name: "HOLLYFRONTIER CORP" },
  { ticker: "HFFG", name: "HF FOODS GROUP INC" },
  { ticker: "HFRO", name: "HIGHLAND INCOME FUND" },
  { ticker: "HFWA", name: "HERITAGE FINANCIAL CORP" },
  { ticker: "HFXE", name: "IQ 50PCT HEDGED FTSE EUR ETF" },
  { ticker: "HFXI", name: "IQ 50PCT HEDGED FTSE INT ETF" },
  { ticker: "HFXJ", name: "IQ 50PCT HEDGED FTSE JPN ETF" },
  { ticker: "HGLB", name: "HIGHLAND GLOBAL ALLOCATION" },
  { ticker: "HGSH", name: "CHINA HGS REAL ESTATE INC" },
  { ticker: "HGV", name: "HILTON GRAND VACATIONS INC" },
  { ticker: "HHC", name: "HOWARD HUGHES CORP/THE" },
  { ticker: "HHHH", name: "WEALTHBRIDGE ACQUISITION LTD" },
  { ticker: "HHHHR", name: "WEALTHBRIDGE ACQUISITION-RTS" },
  { ticker: "HHHHU", name: "WEALTHBRIDGE ACQUISITION LTD" },
  { ticker: "HHR", name: "HEADHUNTER GROUP PLC-ADR" },
  { ticker: "HHS", name: "HARTE-HANKS INC" },
  { ticker: "HHT", name: "HUITAO TECHNOLOGY CO LTD" },
  { ticker: "HI", name: "HILLENBRAND INC" },
  { ticker: "HIBB", name: "HIBBETT SPORTS INC" },
  { ticker: "HIBL", name: "DIREXION DAILY S&P 500 HIGH" },
  { ticker: "HIBS", name: "DIREXION DAILY S&P 500 HIGH" },
  { ticker: "HIE", name: "MILLER/HOWARD HIGH INCOME EQ" },
  { ticker: "HIFS", name: "HINGHAM INSTITUTION FOR SVGS" },
  { ticker: "HIG", name: "HARTFORD FINANCIAL SVCS GRP" },
  { ticker: "HIHO", name: "HIGHWAY HOLDINGS LTD" },
  { ticker: "HII", name: "HUNTINGTON INGALLS INDUSTRIE" },
  { ticker: "HIL", name: "HILL INTERNATIONAL INC" },
  { ticker: "HIMX", name: "HIMAX TECHNOLOGIES INC-ADR" },
  { ticker: "HIO", name: "WESTERN ASSET HI INC OPPORT" },
  { ticker: "HIPS", name: "GRANITESHARES HIPS US HIGH I" },
  { ticker: "HIW", name: "HIGHWOODS PROPERTIES INC" },
  { ticker: "HIX", name: "WESTERN ASSET HIGH INC II" },
  { ticker: "HJLI", name: "HANCOCK JAFFE LABORATORIES I" },
  { ticker: "HJPX", name: "ISHARES CUR HEDG JPX-NIK 400" },
  { ticker: "HKIB", name: "AMTD INTERNATIONAL INC -ADR" },
  { ticker: "HL", name: "HECLA MINING CO" },
  { ticker: "HLAL", name: "WAHED FTSE USA SHARIAH ETF" },
  { ticker: "HLF", name: "HERBALIFE NUTRITION LTD" },
  { ticker: "HLG", name: "HAILIANG EDUCATION GROUP-ADR" },
  { ticker: "HLI", name: "HOULIHAN LOKEY INC" },
  { ticker: "HLIO", name: "HELIOS TECHNOLOGIES INC" },
  { ticker: "HLIT", name: "HARMONIC INC" },
  { ticker: "HLNE", name: "HAMILTON LANE INC-CLASS A" },
  { ticker: "HLT", name: "HILTON WORLDWIDE HOLDINGS IN" },
  { ticker: "HLX", name: "HELIX ENERGY SOLUTIONS GROUP" },
  { ticker: "HMC", name: "HONDA MOTOR CO LTD-SPONS ADR" },
  { ticker: "HMG", name: "HMG COURTLAND PROPERTIES" },
  { ticker: "HMHC", name: "HOUGHTON MIFFLIN HARCOURT CO" },
  { ticker: "HMI", name: "HUAMI CORP - ADR" },
  { ticker: "HMLP", name: "HOEGH LNG PARTNERS LP" },
  { ticker: "HMN", name: "HORACE MANN EDUCATORS" },
  { ticker: "HMNF", name: "HMN FINANCIAL INC" },
  { ticker: "HMOP", name: "HARTFORD MUNI OPPORTUNITY ET" },
  { ticker: "HMST", name: "HOMESTREET INC" },
  { ticker: "HMSY", name: "HMS HOLDINGS CORP" },
  { ticker: "HMTV", name: "HEMISPHERE MEDIA GROUP INC" },
  { ticker: "HMY", name: "HARMONY GOLD MNG-SPON ADR" },
  { ticker: "HNDL", name: "NASDAQ 7 HANDL INDEX ETF" },
  { ticker: "HNGR", name: "HANGER INC" },
  { ticker: "HNI", name: "HNI CORP" },
  { ticker: "HNNA", name: "HENNESSY ADVISORS INC" },
  { ticker: "HNP", name: "HUANENG POWER INTL-SPONS ADR" },
  { ticker: "HNRG", name: "HALLADOR ENERGY CO" },
  { ticker: "HNW", name: "PIONEER DIVERSIFIED HIGH INC" },
  { ticker: "HOFT", name: "HOOKER FURNITURE CORP" },
  { ticker: "HOG", name: "HARLEY-DAVIDSON INC" },
  { ticker: "HOLD", name: "ADVISORSHARES SAGE CORE RESE" },
  { ticker: "HOLI", name: "HOLLYSYS AUTOMATION TECHNOLO" },
  { ticker: "HOLX", name: "HOLOGIC INC" },
  { ticker: "HOMB", name: "HOME BANCSHARES INC" },
  { ticker: "HOME", name: "AT HOME GROUP INC" },
  { ticker: "HOML", name: "ETRACS MON RST 2XLEV ISE EX" },
  { ticker: "HOMZ", name: "HOYA CAPITAL HOUSING ETF" },
  { ticker: "HON", name: "HONEYWELL INTERNATIONAL INC" },
  { ticker: "HONE", name: "HARBORONE BANCORP INC" },
  { ticker: "HOOK", name: "HOOKIPA PHARMA INC" },
  { ticker: "HOPE", name: "HOPE BANCORP INC" },
  { ticker: "HOTH", name: "HOTH THERAPEUTICS INC" },
  { ticker: "HOV", name: "HOVNANIAN ENTERPRISES-A" },
  { ticker: "HP", name: "HELMERICH & PAYNE" },
  { ticker: "HPE", name: "HEWLETT PACKARD ENTERPRISE" },
  { ticker: "HPF", name: "JOHN HANCOCK PFD INCOME II" },
  { ticker: "HPI", name: "JOHN HANCOCK PFD INCOME FD" },
  { ticker: "HPP", name: "HUDSON PACIFIC PROPERTIES IN" },
  { ticker: "HPQ", name: "HP INC" },
  { ticker: "HPR", name: "HIGHPOINT RESOURCES CORP" },
  { ticker: "HPS", name: "JOHN HANCOCK PFD INCOME III" },
  { ticker: "HQH", name: "TEKLA HEALTHCARE INVESTORS" },
  { ticker: "HQI", name: "HIREQUEST INC" },
  { ticker: "HQL", name: "TEKLA LIFE SCIENCES INVESTOR" },
  { ticker: "HQY", name: "HEALTHEQUITY INC" },
  { ticker: "HR", name: "HEALTHCARE REALTY TRUST INC" },
  { ticker: "HRB", name: "H&R BLOCK INC" },
  { ticker: "HRC", name: "HILL-ROM HOLDINGS INC" },
  { ticker: "HRI", name: "HERC HOLDINGS INC" },
  { ticker: "HRL", name: "HORMEL FOODS CORP" },
  { ticker: "HROW", name: "HARROW HEALTH INC" },
  { ticker: "HRTG", name: "HERITAGE INSURANCE HOLDINGS" },
  { ticker: "HRTX", name: "HERON THERAPEUTICS INC" },
  { ticker: "HRZN", name: "HORIZON TECHNOLOGY FINANCE C" },
  { ticker: "HSBC", name: "HSBC HOLDINGS PLC-SPONS ADR" },
  { ticker: "HSC", name: "HARSCO CORP" },
  { ticker: "HSCZ", name: "ISHARES CUR HDG MSCI EAFE SM" },
  { ticker: "HSDT", name: "HELIUS MEDICAL TECHNOLOGIES" },
  { ticker: "HSIC", name: "HENRY SCHEIN INC" },
  { ticker: "HSII", name: "HEIDRICK & STRUGGLES INTL" },
  { ticker: "HSKA", name: "HESKA CORP" },
  { ticker: "HSON", name: "HUDSON GLOBAL INC" },
  { ticker: "HSPX", name: "GLOBAL X S&P 500 COVE CALL E" },
  { ticker: "HSRT", name: "HARTFORD SHORT DURATION ETF" },
  { ticker: "HST", name: "HOST HOTELS & RESORTS INC" },
  { ticker: "HSTM", name: "HEALTHSTREAM INC" },
  { ticker: "HSY", name: "HERSHEY CO/THE" },
  { ticker: "HT", name: "HERSHA HOSPITALITY TRUST" },
  { ticker: "HTA", name: "HEALTHCARE TRUST OF AME-CL A" },
  { ticker: "HTAB", name: "HARTFORD SCHRODERS TAX AWARE" },
  { ticker: "HTBI", name: "HOMETRUST BANCSHARES INC" },
  { ticker: "HTBK", name: "HERITAGE COMMERCE CORP" },
  { ticker: "HTBX", name: "HEAT BIOLOGICS INC" },
  { ticker: "HTD", name: "JOHN HANCOCK T/A DVD INCOME" },
  { ticker: "HTEC", name: "ROBO GLOBAL HEALTHCARE TECH" },
  { ticker: "HTGC", name: "HERCULES CAPITAL INC" },
  { ticker: "HTGM", name: "HTG MOLECULAR DIAGNOSTICS" },
  { ticker: "HTH", name: "HILLTOP HOLDINGS INC" },
  { ticker: "HTHT", name: "HUAZHU GROUP LTD-ADR" },
  { ticker: "HTLD", name: "HEARTLAND EXPRESS INC" },
  { ticker: "HTLF", name: "HEARTLAND FINANCIAL USA INC" },
  { ticker: "HTRB", name: "HARTFORD TOTAL RTRN BOND ETF" },
  { ticker: "HTUS", name: "HULL TACTICAL US ETF" },
  { ticker: "HTY", name: "JOHN HANCOCK TAX-ADVANTAGED" },
  { ticker: "HTZ", name: "HERTZ GLOBAL HOLDINGS INC" },
  { ticker: "HUBB", name: "HUBBELL INC" },
  { ticker: "HUBG", name: "HUB GROUP INC-CL A" },
  { ticker: "HUBS", name: "HUBSPOT INC" },
  { ticker: "HUD", name: "HUDSON LTD-CLASS A" },
  { ticker: "HUGE", name: "FSD PHARMA INC-CLASS B" },
  { ticker: "HUIZ", name: "HUIZE HOLDING LTD-ADR" },
  { ticker: "HUM", name: "HUMANA INC" },
  { ticker: "HUN", name: "HUNTSMAN CORP" },
  { ticker: "HURC", name: "HURCO COMPANIES INC" },
  { ticker: "HURN", name: "HURON CONSULTING GROUP INC" },
  { ticker: "HUSA", name: "HOUSTON AMERICAN ENERGY CORP" },
  { ticker: "HUSE", name: "STRATEGY SHARES US MARKET RO" },
  { ticker: "HUSV", name: "FT HORIZON MNGD VOL DOM ETF" },
  { ticker: "HUYA", name: "HUYA INC-ADR" },
  { ticker: "HVBC", name: "HV BANCORP INC" },
  { ticker: "HVT", name: "HAVERTY FURNITURE" },
  { ticker: "HVT.A", name: "HAVERTY FURNITURE COS-CL A" },
  { ticker: "HWBK", name: "HAWTHORN BANCSHARES INC" },
  { ticker: "HWC", name: "HANCOCK WHITNEY CORP" },
  { ticker: "HWCC", name: "HOUSTON WIRE & CABLE CO" },
  { ticker: "HWKN", name: "HAWKINS INC" },
  { ticker: "HX", name: "HEXINDAI INC-ADR" },
  { ticker: "HXL", name: "HEXCEL CORP" },
  { ticker: "HY", name: "HYSTER-YALE MATERIALS" },
  { ticker: "HYAC", name: "HAYMAKER ACQ CORP II - CL A" },
  { ticker: "HYACU", name: "HAYMAKER ACQUISITION CORP II" },
  { ticker: "HYB", name: "NEW AMERICA HIGH INCOME FUND" },
  { ticker: "HYD", name: "VANECK HIGH-YIELD MUNICIPAL" },
  { ticker: "HYDB", name: "ISHARES EDGE HIGH YIELD DEFE" },
  { ticker: "HYDW", name: "XTRS LOW BETA HI YIELD ETF" },
  { ticker: "HYEM", name: "VANECK EM HIGH YIELD BOND" },
  { ticker: "HYG", name: "ISHARES IBOXX HIGH YLD CORP" },
  { ticker: "HYGH", name: "ISHARES INT RATE HEDG HY ETF" },
  { ticker: "HYGV", name: "FLEXSHARES HIGH YIELD VALUE" },
  { ticker: "HYHG", name: "PROSHARES HIGH YIELD INTERES" },
  { ticker: "HYI", name: "WESTERN ASSET HIGH YIELD DEF" },
  { ticker: "HYIH", name: "HY CORP BOND INT RATE HD" },
  { ticker: "HYLB", name: "XTRACKERS USD HIGH YIELD COR" },
  { ticker: "HYLD", name: "HIGH YIELD ETF" },
  { ticker: "HYLS", name: "FT TACTICAL HIGH YIELD ETF" },
  { ticker: "HYLV", name: "IQ S&P HY LOW VOL BOND ETF" },
  { ticker: "HYMB", name: "SPDR NUVEEN BLOOMBERG H/Y M" },
  { ticker: "HYND", name: "WISDOMTREE WISDOMTREE NEGATI" },
  { ticker: "HYRE", name: "HYRECAR INC" },
  { ticker: "HYS", name: "PIMCO 0-5 YEAR H/Y CORP BOND" },
  { ticker: "HYT", name: "BLACKROCK CORP HI YLD" },
  { ticker: "HYTR", name: "CP HIGH YIELD TREND ETF" },
  { ticker: "HYUP", name: "XTRS HI BETA HI YIELD ETF" },
  { ticker: "HYXE", name: "ISHARES IBOXX HY EX-O&G ETF" },
  { ticker: "HYXU", name: "ISHARES INTERNATIONAL HIGH Y" },
  { ticker: "HYZD", name: "WISDOMTREE INTEREST RATE HED" },
  { ticker: "HZN", name: "HORIZON GLOBAL CORP" },
  { ticker: "HZNP", name: "HORIZON THERAPEUTICS PLC" },
  { ticker: "HZO", name: "MARINEMAX INC" },
  { ticker: "I", name: "INTELSAT SA" },
  { ticker: "IAA", name: "IAA INC" },
  { ticker: "IAC", name: "IAC/INTERACTIVECORP" },
  { ticker: "IAE", name: "VOYA ASIA PAC HI DVD EQ INC" },
  { ticker: "IAF", name: "ABERDEEN AUSTRALIA EQUITY FD" },
  { ticker: "IAG", name: "IAMGOLD CORP" },
  { ticker: "IAGG", name: "ISHARES INTL AGGREGATE BOND" },
  { ticker: "IAI", name: "ISHARES U.S. BROKER-DEALERS" },
  { ticker: "IAK", name: "ISHARES U.S. INSURANCE ETF" },
  { ticker: "IART", name: "INTEGRA LIFESCIENCES HOLDING" },
  { ticker: "IAT", name: "ISHARES US REGIONAL BANKS ET" },
  { ticker: "IAU", name: "ISHARES GOLD TRUST" },
  { ticker: "IAUF", name: "ISHARES GOLD STRATEGY ETF" },
  { ticker: "IBA", name: "INDUSTRIAS BACHOCO SAB SP AD" },
  { ticker: "IBB", name: "ISHARES NASDAQ BIOTECHNOLOGY" },
  { ticker: "IBCD", name: "ISHARES IBONDS MAR 2020 TERM" },
  { ticker: "IBCE", name: "ISHARES IBONDS MAR 2023 TERM" },
  { ticker: "IBCP", name: "INDEPENDENT BANK CORP - MICH" },
  { ticker: "IBD", name: "INSPIRE CORPORATE BOND IMPAC" },
  { ticker: "IBDC", name: "ISHARES IBONDS MAR 2020 TERM" },
  { ticker: "IBDD", name: "ISHARES IBONDS MAR 2023 TERM" },
  { ticker: "IBDL", name: "ISHARES IBONDS DEC 2020 TERM" },
  { ticker: "IBDM", name: "ISHARES IBONDS DEC 2021 TERM" },
  { ticker: "IBDN", name: "ISHARES IBONDS DEC 2022 TERM" },
  { ticker: "IBDO", name: "ISHARES IBONDS DEC 2023 TERM" },
  { ticker: "IBDP", name: "ISHARES IBONDS DEC 2024 TERM" },
  { ticker: "IBDQ", name: "ISHARES IBONDS DEC 2025 TERM" },
  { ticker: "IBDR", name: "ISHARES IBONDS DEC 2026 TERM" },
  { ticker: "IBDS", name: "ISHRS IBNDS DEC 27 CORP ETF" },
  { ticker: "IBDT", name: "ISHARES IBONDS DEC 2028 ETF" },
  { ticker: "IBDU", name: "ISHARES IBONDS DEC 2029 TERM" },
  { ticker: "IBHA", name: "ISHARES IBONDS 2021 H/Y INC" },
  { ticker: "IBHB", name: "ISHARES IBONDS 2022 H/Y INC" },
  { ticker: "IBHC", name: "ISHARES IBONDS 2023 H/Y INC" },
  { ticker: "IBHD", name: "ISHARES IBONDS 2024 H/Y INC" },
  { ticker: "IBHE", name: "ISHARES IBONDS 2025 H/Y INC" },
  { ticker: "IBIO", name: "IBIO INC" },
  { ticker: "IBKC", name: "IBERIABANK CORP" },
  { ticker: "IBKR", name: "INTERACTIVE BROKERS GRO-CL A" },
  { ticker: "IBM", name: "INTL BUSINESS MACHINES CORP" },
  { ticker: "IBMI", name: "ISHARES IBONDS SEP 2020 TERM" },
  { ticker: "IBMJ", name: "ISHARES IBONDS DEC 2021 TERM" },
  { ticker: "IBMK", name: "ISHARES IBONDS DEC 2022 TERM" },
  { ticker: "IBML", name: "ISHARES DEC 2023 MUNI BD ETF" },
  { ticker: "IBMM", name: "ISHARES IBONDS DEC 2024 TERM" },
  { ticker: "IBMN", name: "ISHARES IBONDS DEC 2025 TERM" },
  { ticker: "IBMO", name: "ISHARES IBONDS DEC 2026 TERM" },
  { ticker: "IBMP", name: "ISHARES IBONDS DEC 2027 TERM" },
  { ticker: "IBMQ", name: "ISHARES IBONDS DEC 2028 MUNI" },
  { ticker: "IBN", name: "ICICI BANK LTD-SPON ADR" },
  { ticker: "IBND", name: "SPDR BBG BARC INTL CORP BOND" },
  { ticker: "IBOC", name: "INTERNATIONAL BANCSHARES CRP" },
  { ticker: "IBP", name: "INSTALLED BUILDING PRODUCTS" },
  { ticker: "IBTA", name: "ISHARES IBONDS DEC 2021 TERM" },
  { ticker: "IBTB", name: "ISHARES IBONDS DEC 2022 TERM" },
  { ticker: "IBTD", name: "ISHARES IBONDS DEC 2023 TERM" },
  { ticker: "IBTE", name: "ISHARES IBONDS DEC 2024 TERM" },
  { ticker: "IBTF", name: "ISHARES IBONDS DEC 2025 TERM" },
  { ticker: "IBTG", name: "ISHARES IBONDS DEC 2026 TERM" },
  { ticker: "IBTH", name: "ISHARES IBONDS DEC 2027 TERM" },
  { ticker: "IBTI", name: "ISHARES IBONDS DEC 2028 TERM" },
  { ticker: "IBTJ", name: "ISHARES IBONDS DEC 2029 TERM" },
  { ticker: "IBTX", name: "INDEPENDENT BANK GROUP INC" },
  { ticker: "IBUY", name: "AMPLIFY ONLINE RETAIL ETF" },
  { ticker: "ICAD", name: "ICAD INC" },
  { ticker: "ICBK", name: "COUNTY BANCORP INC" },
  { ticker: "ICCC", name: "IMMUCELL CORP" },
  { ticker: "ICCH", name: "ICC HOLDINGS INC" },
  { ticker: "ICD", name: "INDEPENDENCE CONTRACT DRILLI" },
  { ticker: "ICE", name: "INTERCONTINENTAL EXCHANGE IN" },
  { ticker: "ICF", name: "ISHARES COHEN & STEERS REIT" },
  { ticker: "ICFI", name: "ICF INTERNATIONAL INC" },
  { ticker: "ICHR", name: "ICHOR HOLDINGS LTD" },
  { ticker: "ICL", name: "ISRAEL CHEMICALS LTD" },
  { ticker: "ICLK", name: "ICLICK INTERACTIVE ASIA-ADR" },
  { ticker: "ICLN", name: "ISHARES GLOBAL CLEAN ENERGY" },
  { ticker: "ICLR", name: "ICON PLC" },
  { ticker: "ICMB", name: "INVESTCORP CREDIT MANAGEMENT" },
  { ticker: "ICOL", name: "ISHARES MSCI COLOMBIA ETF" },
  { ticker: "ICON", name: "ICONIX BRAND GROUP INC" },
  { ticker: "ICOW", name: "PACER DEVELOPED MARKETS INTE" },
  { ticker: "ICPT", name: "INTERCEPT PHARMACEUTICALS IN" },
  { ticker: "ICSH", name: "ISHARES ULTRA SHORT-TERM BON" },
  { ticker: "ICUI", name: "ICU MEDICAL INC" },
  { ticker: "ICVT", name: "ISHARES CONVERTIBLE BOND ETF" },
  { ticker: "IDA", name: "IDACORP INC" },
  { ticker: "IDCC", name: "INTERDIGITAL INC" },
  { ticker: "IDE", name: "VOYA INFRASTRUCTURE INDUSTRI" },
  { ticker: "IDEV", name: "ISHARES CORE MSCI DEV MKTS" },
  { ticker: "IDEX", name: "IDEANOMICS INC" },
  { ticker: "IDHD", name: "INVESCO S&P INTERNATIONAL DE" },
  { ticker: "IDHQ", name: "INVESCO S&P INTERNATIONAL DE" },
  { ticker: "IDIV", name: "METAURUS US EQUITY CUMULATIV" },
  { ticker: "IDLB", name: "INVESCO FTSE INTERNATIONAL L" },
  { ticker: "IDLV", name: "INVESCO S&P INTERNATIONAL DE" },
  { ticker: "IDMO", name: "INVESCO S&P INTERNATIONAL DE" },
  { ticker: "IDN", name: "INTELLICHECK INC" },
  { ticker: "IDNA", name: "ISHARES GEN-IMMUNOLOGY HLTH" },
  { ticker: "IDOG", name: "ALPS INTERNATIONAL SECTOR DI" },
  { ticker: "IDRA", name: "IDERA PHARMACEUTICALS INC" },
  { ticker: "IDRV", name: "ISHARES SELF-DRIVING EV&TECH" },
  { ticker: "IDT", name: "IDT CORP-CLASS B" },
  { ticker: "IDU", name: "ISHARES US UTILITIES ETF" },
  { ticker: "IDV", name: "ISHARES INTERNATIONAL SELECT" },
  { ticker: "IDX", name: "VANECK INDONESIA INDEX" },
  { ticker: "IDXG", name: "INTERPACE BIOSCIENCES INC" },
  { ticker: "IDXX", name: "IDEXX LABORATORIES INC" },
  { ticker: "IDY", name: "PACIFIC GLOBAL INTERNATIONAL" },
  { ticker: "IDYA", name: "IDEAYA BIOSCIENCES INC" },
  { ticker: "IEA", name: "INFRASTRUCTURE AND ENERGY AL" },
  { ticker: "IEC", name: "IEC ELECTRONICS CORP" },
  { ticker: "IECS", name: "ISHARES EVOLVED US CONSUMER" },
  { ticker: "IEDI", name: "ISHARES EVOLVED US DISCRETIO" },
  { ticker: "IEF", name: "ISHARES 7-10 YEAR TREASURY B" },
  { ticker: "IEFA", name: "ISHARES CORE MSCI EAFE ETF" },
  { ticker: "IEFN", name: "ISHARES EVOLVED US FINANCIAL" },
  { ticker: "IEHS", name: "ISHARES EVOLVED US HEALTHCAR" },
  { ticker: "IEI", name: "ISHARES 3-7 YEAR TREASURY BO" },
  { ticker: "IEIH", name: "ISHARES EVOLVED US INNOVATIV" },
  { ticker: "IEME", name: "ISHARES EVOLVED US MEDIA AND" },
  { ticker: "IEMG", name: "ISHARES CORE MSCI EMERGING" },
  { ticker: "IEO", name: "ISHARES U.S. OIL & GAS EXPLO" },
  { ticker: "IEP", name: "ICAHN ENTERPRISES LP" },
  { ticker: "IESC", name: "IES HOLDINGS INC" },
  { ticker: "IETC", name: "ISHARES EVOLVED US TECHNOLOG" },
  { ticker: "IEUR", name: "ISHARES CORE MSCI EUROPE" },
  { ticker: "IEUS", name: "ISHARES MSCI EUROPE SMALL-CA" },
  { ticker: "IEV", name: "ISHARES EUROPE ETF" },
  { ticker: "IEX", name: "IDEX CORP" },
  { ticker: "IEZ", name: "ISHARES U.S. OIL EQUIPMENT &" },
  { ticker: "IFEU", name: "ISHARES EUROPE DEVELOPED REA" },
  { ticker: "IFF", name: "INTL FLAVORS & FRAGRANCES" },
  { ticker: "IFGL", name: "ISHARES INTERNATIONAL DEVELO" },
  { ticker: "IFLY", name: "ETFMG DRONE ECONOMY STRATEGY" },
  { ticker: "IFMK", name: "IFRESH INC" },
  { ticker: "IFN", name: "INDIA FUND INC" },
  { ticker: "IFRA", name: "ISHARES US INFRASTRUCTURE" },
  { ticker: "IFRX", name: "INFLARX NV" },
  { ticker: "IFS", name: "INTERCORP FINANCIAL SERVICES" },
  { ticker: "IFV", name: "FIRST TRUST DW FOCUS 5 INTL" },
  { ticker: "IG", name: "PRINCIPAL INVESTMENT GRADE" },
  { ticker: "IGA", name: "VOYA GLOBAL ADVANTAGE AND PR" },
  { ticker: "IGBH", name: "ISHARES INTEREST RATE HEDGED" },
  { ticker: "IGC", name: "INDIA GLOBALIZATION CAPITAL" },
  { ticker: "IGD", name: "VOYA GLBL EQTY DVD & PRM OPP" },
  { ticker: "IGE", name: "ISHARES NORTH AMERICAN NATUR" },
  { ticker: "IGEB", name: "ISHARES EDGE INVESTMENT GRAD" },
  { ticker: "IGF", name: "ISHARES GLOBAL INFRASTRUCTUR" },
  { ticker: "IGHG", name: "PROSHARES IG HEDGED" },
  { ticker: "IGI", name: "WESTERN ASSET INV GRA DEF OP" },
  { ticker: "IGIB", name: "ISHARES INTERMEDIATE-TERM CO" },
  { ticker: "IGIH", name: "INV GRADE BOND INT RATE" },
  { ticker: "IGLB", name: "ISHARES LONG-TERM CORPORATE" },
  { ticker: "IGM", name: "ISHARES EXPANDED TECH SECTOR" },
  { ticker: "IGMS", name: "IGM BIOSCIENCES INC" },
  { ticker: "IGN", name: "ISHARES NORTH AMERICAN TECH-" },
  { ticker: "IGOV", name: "ISHARES INTERNATIONAL TREASU" },
  { ticker: "IGR", name: "CBRE CLARION GL R/E INCOME F" },
  { ticker: "IGRO", name: "ISHARES INTL DIV GROWTH ETF" },
  { ticker: "IGSB", name: "ISHARES SHORT-TERM CORPORATE" },
  { ticker: "IGT", name: "INTERNATIONAL GAME TECHNOLOG" },
  { ticker: "IGV", name: "ISHARES EXPANDED TECH-SOFTWA" },
  { ticker: "IHAK", name: "ISHARES CYBERSECURITY & TECH" },
  { ticker: "IHC", name: "INDEPENDENCE HOLDING CO" },
  { ticker: "IHD", name: "VOYA EMRG MRKTS HI INC DVD" },
  { ticker: "IHDG", name: "WISDOMTREE INTERNATIONAL HED" },
  { ticker: "IHE", name: "ISHARES US PHARMACEUTICALS E" },
  { ticker: "IHF", name: "ISHARES U.S. HEALTHCARE PROV" },
  { ticker: "IHG", name: "INTERCONTINENTAL HOTELS-ADR" },
  { ticker: "IHI", name: "ISHARES U.S. MEDICAL DEVICES" },
  { ticker: "IHIT", name: "INVESCO HI INC 2023 TRGT TRM" },
  { ticker: "IHRT", name: "IHEARTMEDIA INC - CLASS A" },
  { ticker: "IHT", name: "INNSUITES HOSPITALITY TRUST" },
  { ticker: "IHTA", name: "INVESCO HI INC 2024 TRGT TRM" },
  { ticker: "IHY", name: "VANECK INTL HIGH YIELD BOND" },
  { ticker: "IID", name: "VOYA INTL HI DVD" },
  { ticker: "IIF", name: "MORGAN STANLEY INDIA INVEST" },
  { ticker: "IIGD", name: "INVESCO INV GRADE DEFENSIVE" },
  { ticker: "IIGV", name: "INVESCO INVEST GRADE VALUE" },
  { ticker: "III", name: "INFORMATION SERVICES GROUP" },
  { ticker: "IIIN", name: "INSTEEL INDUSTRIES INC" },
  { ticker: "IIIV", name: "I3 VERTICALS INC-CLASS A" },
  { ticker: "IIM", name: "INVESCO VALUE MUNICIPAL INCO" },
  { ticker: "IIN", name: "INTRICON CORP" },
  { ticker: "IIPR", name: "INNOVATIVE INDUSTRIAL PROPER" },
  { ticker: "IIVI", name: "II-VI INC" },
  { ticker: "IJAN", name: "INNOVATOR MSCI EAFE PB - JAN" },
  { ticker: "IJH", name: "ISHARES CORE S&P MIDCAP ETF" },
  { ticker: "IJJ", name: "ISHARES S&P MID-CAP 400 VALU" },
  { ticker: "IJK", name: "ISHARES S&P MID-CAP 400 GROW" },
  { ticker: "IJR", name: "ISHARES CORE S&P SMALL-CAP E" },
  { ticker: "IJS", name: "ISHARES S&P SMALL-CAP 600 VA" },
  { ticker: "IJT", name: "ISHARES S&P SMALL-CAP 600 GR" },
  { ticker: "IJUL", name: "INNOVATOR MSCI EAFE POWER BF" },
  { ticker: "IKNX", name: "IKONICS CORP" },
  { ticker: "ILF", name: "ISHARES LATIN AMERICA 40 ETF" },
  { ticker: "ILMN", name: "ILLUMINA INC" },
  { ticker: "ILPT", name: "INDUSTRIAL LOGISTICS PROPERT" },
  { ticker: "ILTB", name: "ISHARES CORE 10+ YEAR USD BO" },
  { ticker: "IMAB", name: "I-MAB" },
  { ticker: "IMAC", name: "IMAC HOLDINGS INC" },
  { ticker: "IMAX", name: "IMAX CORP" },
  { ticker: "IMBI", name: "IMEDIA BRANDS INC" },
  { ticker: "IMGN", name: "IMMUNOGEN INC" },
  { ticker: "IMH", name: "IMPAC MORTGAGE HOLDINGS INC" },
  { ticker: "IMKTA", name: "INGLES MARKETS INC-CLASS A" },
  { ticker: "IMLP", name: "IPATH S&P MLP ETN" },
  { ticker: "IMMP", name: "IMMUTEP LTD-SP ADR" },
  { ticker: "IMMR", name: "IMMERSION CORPORATION" },
  { ticker: "IMMU", name: "IMMUNOMEDICS INC" },
  { ticker: "IMO", name: "IMPERIAL OIL LTD" },
  { ticker: "IMOM", name: "ALPHA INTL QUANT MOM ETF" },
  { ticker: "IMOS", name: "CHIPMOS TECHNOLOGIES INC-ADR" },
  { ticker: "IMRN", name: "IMMURON LTD-SPON ADR" },
  { ticker: "IMTB", name: "ISHARES CORE 5-10 YEAR USD" },
  { ticker: "IMTE", name: "INTEGRATED MEDIA TECHNOLOGY" },
  { ticker: "IMTM", name: "ISHARES EDGE MSCI INTL MOMEN" },
  { ticker: "IMUX", name: "IMMUNIC INC" },
  { ticker: "IMV", name: "IMV INC" },
  { ticker: "IMVT", name: "IMMUNOVANT INC" },
  { ticker: "IMVTU", name: "IMMUNOVANT INC" },
  { ticker: "IMXI", name: "INTERNATIONAL MONEY EXPRESS" },
  { ticker: "INAP", name: "INTERNAP CORP" },
  { ticker: "INBK", name: "FIRST INTERNET BANCORP" },
  { ticker: "INCO", name: "COLUMBIA INDIA CONSUMER ETF" },
  { ticker: "INCY", name: "INCYTE CORP" },
  { ticker: "INDA", name: "ISHARES MSCI INDIA ETF" },
  { ticker: "INDB", name: "INDEPENDENT BANK CORP/MA" },
  { ticker: "INDL", name: "DRX DLY MSCI INDIA BULL 3X" },
  { ticker: "INDO", name: "INDONESIA ENERGY CORP LTD" },
  { ticker: "INDS", name: "PACER BENCHMARK INDUSTRIAL" },
  { ticker: "INDY", name: "ISHARES INDIA 50 ETF" },
  { ticker: "INFI", name: "INFINITY PHARMACEUTICALS INC" },
  { ticker: "INFN", name: "INFINERA CORP" },
  { ticker: "INFO", name: "IHS MARKIT LTD" },
  { ticker: "INFR", name: "LEGG MASON GLOBAL INFRASTR" },
  { ticker: "INFU", name: "INFUSYSTEM HOLDINGS INC" },
  { ticker: "INFY", name: "INFOSYS LTD-SP ADR" },
  { ticker: "ING", name: "ING GROEP N.V.-SPONSORED ADR" },
  { ticker: "INGN", name: "INOGEN INC" },
  { ticker: "INGR", name: "INGREDION INC" },
  { ticker: "INKM", name: "SPDR SSGA INCOME ALLOCATION" },
  { ticker: "INMB", name: "INMUNE BIO INC" },
  { ticker: "INMD", name: "INMODE LTD" },
  { ticker: "INN", name: "SUMMIT HOTEL PROPERTIES INC" },
  { ticker: "INNT", name: "INNOVATE BIOPHARMACEUTICALS" },
  { ticker: "INO", name: "INOVIO PHARMACEUTICALS INC" },
  { ticker: "INOD", name: "INNODATA INC" },
  { ticker: "INOV", name: "INOVALON HOLDINGS INC - A" },
  { ticker: "INPX", name: "INPIXON" },
  { ticker: "INR", name: "MARKET VECTORS RUPEE/USD ETN" },
  { ticker: "INS", name: "INTELLIGENT SYSTEMS CORP" },
  { ticker: "INSE", name: "INSPIRED ENTERTAINMENT INC" },
  { ticker: "INSG", name: "INSEEGO CORP" },
  { ticker: "INSI", name: "INSIGHT SELECT INCOME FUND" },
  { ticker: "INSM", name: "INSMED INC" },
  { ticker: "INSP", name: "INSPIRE MEDICAL SYSTEMS INC" },
  { ticker: "INST", name: "INSTRUCTURE INC" },
  { ticker: "INSU", name: "INSURANCE ACQUISITION CORP-A" },
  { ticker: "INSUU", name: "INSURANCE ACQUISITION CORP" },
  { ticker: "INSW", name: "INTERNATIONAL SEAWAYS INC" },
  { ticker: "INT", name: "WORLD FUEL SERVICES CORP" },
  { ticker: "INTC", name: "INTEL CORP" },
  { ticker: "INTF", name: "ISHARES EDGE MSCI MF INTL" },
  { ticker: "INTG", name: "INTERGROUP CORP" },
  { ticker: "INTL", name: "INTL FCSTONE INC" },
  { ticker: "INTT", name: "INTEST CORP" },
  { ticker: "INTU", name: "INTUIT INC" },
  { ticker: "INUV", name: "INUVO INC" },
  { ticker: "INVA", name: "INNOVIVA INC" },
  { ticker: "INVE", name: "IDENTIV INC" },
  { ticker: "INVH", name: "INVITATION HOMES INC" },
  { ticker: "INWK", name: "INNERWORKINGS INC" },
  { ticker: "INXN", name: "INTERXION HOLDING NV" },
  { ticker: "IO", name: "ION GEOPHYSICAL CORP" },
  { ticker: "IONS", name: "IONIS PHARMACEUTICALS INC" },
  { ticker: "IOO", name: "ISHARES GLOBAL 100 ETF" },
  { ticker: "IOR", name: "INCOME OPP REALTY INVESTORS" },
  { ticker: "IOSP", name: "INNOSPEC INC" },
  { ticker: "IOTS", name: "ADESTO TECHNOLOGIES CORP" },
  { ticker: "IOVA", name: "IOVANCE BIOTHERAPEUTICS INC" },
  { ticker: "IP", name: "INTERNATIONAL PAPER CO" },
  { ticker: "IPAC", name: "ISHARES CORE MSCI PACIFIC ET" },
  { ticker: "IPAR", name: "INTER PARFUMS INC" },
  { ticker: "IPAY", name: "ETFMG PRIME MOBILE PAYMENTS" },
  { ticker: "IPDN", name: "PROFESSIONAL DIVERSITY NETWO" },
  { ticker: "IPFF", name: "ISHARES INTERNATIONAL PREFER" },
  { ticker: "IPG", name: "INTERPUBLIC GROUP OF COS INC" },
  { ticker: "IPGP", name: "IPG PHOTONICS CORP" },
  { ticker: "IPHA", name: "INNATE PHARMA SA-SPONS ADR" },
  { ticker: "IPHI", name: "INPHI CORP" },
  { ticker: "IPI", name: "INTREPID POTASH INC" },
  { ticker: "IPKW", name: "INVESCO INTERNATIONAL BUYBAC" },
  { ticker: "IPO", name: "RENAISSANCE IPO ETF" },
  { ticker: "IPOS", name: "RENAISSANCE INTL IPO ETF" },
  { ticker: "IPV", name: "INTERPRIVATE ACQUISITION COR" },
  { ticker: "IPV=", name: "INTERPRIVATE ACQUISITION COR" },
  { ticker: "IPWR", name: "IDEAL POWER INC" },
  { ticker: "IQ", name: "IQIYI INC-ADR" },
  { ticker: "IQDE", name: "FLEXSHARES-INT QUAL DVD DEFE" },
  { ticker: "IQDF", name: "FLEXSHARES-INT QUAL DVD INDE" },
  { ticker: "IQDG", name: "WISDOMTREE INTL QLTY DVD GRW" },
  { ticker: "IQDY", name: "FLEXSHARES INT QUAL DVD DYN" },
  { ticker: "IQI", name: "INVESCO QUALITY MUNI INC TR" },
  { ticker: "IQIN", name: "IQ 500 INTERNATIONAL ETF" },
  { ticker: "IQLT", name: "ISHARES EDGE MSCI INTL QUALI" },
  { ticker: "IQM", name: "FRANKLIN INTELLIGENT MACHINE" },
  { ticker: "IQSI", name: "IQ CANDRIAM ESG INTERNATIONA" },
  { ticker: "IQSU", name: "IQ CANDRIAM ESG US EQUITY ET" },
  { ticker: "IQV", name: "IQVIA HOLDINGS INC" },
  { ticker: "IR", name: "INGERSOLL-RAND INC" },
  { ticker: "IRBO", name: "ISHARES ROBOTICS & ARTIFICIA" },
  { ticker: "IRBT", name: "IROBOT CORP" },
  { ticker: "IRCP", name: "IRSA PROPIEDADES COMERCI-ADR" },
  { ticker: "IRDM", name: "IRIDIUM COMMUNICATIONS INC" },
  { ticker: "IRET", name: "INVESTORS REAL ESTATE TRUST" },
  { ticker: "IRIX", name: "IRIDEX CORP" },
  { ticker: "IRL", name: "NEW IRELAND FUND INC" },
  { ticker: "IRM", name: "IRON MOUNTAIN INC" },
  { ticker: "IRMD", name: "IRADIMED CORP" },
  { ticker: "IROQ", name: "IF BANCORP INC" },
  { ticker: "IRR", name: "VOYA NATURAL RESOURCES EQUIT" },
  { ticker: "IRS", name: "IRSA -SP ADR" },
  { ticker: "IRT", name: "INDEPENDENCE REALTY TRUST IN" },
  { ticker: "IRTC", name: "IRHYTHM TECHNOLOGIES INC" },
  { ticker: "IRWD", name: "IRONWOOD PHARMACEUTICALS INC" },
  { ticker: "ISBC", name: "INVESTORS BANCORP INC" },
  { ticker: "ISCF", name: "ISHARES EDGE MSCI MULTIFACTO" },
  { ticker: "ISD", name: "PGIM HIGH YIELD BOND FUND" },
  { ticker: "ISDR", name: "ISSUER DIRECT CORP" },
  { ticker: "ISDS", name: "INVESCO RAFI STRATEGIC DEVEL" },
  { ticker: "ISDX", name: "INVESCO RAFI STRATEGIC DEVE" },
  { ticker: "ISEE", name: "IVERIC BIO INC" },
  { ticker: "ISEM", name: "INVESCO RAFI STRATEGIC EMERG" },
  { ticker: "ISHG", name: "ISHARES -3 YEAR INTERNATIONA" },
  { ticker: "ISIG", name: "INSIGNIA SYSTEMS INC" },
  { ticker: "ISMD", name: "INSPIRE SMALL/MID CAP IMPACT" },
  { ticker: "ISNS", name: "IMAGE SENSING SYSTEMS INC" },
  { ticker: "ISR", name: "ISORAY INC" },
  { ticker: "ISRA", name: "VANECK ISRAEL" },
  { ticker: "ISRG", name: "INTUITIVE SURGICAL INC" },
  { ticker: "ISSC", name: "INNOVATIVE SOLUTIONS & SUPP" },
  { ticker: "ISTB", name: "ISHARES CORE 1-5 YEAR USD BO" },
  { ticker: "ISTR", name: "INVESTAR HOLDING CORP" },
  { ticker: "ISZE", name: "ISHARES EDGE MSCI INTL SIZE" },
  { ticker: "IT", name: "GARTNER INC" },
  { ticker: "ITA", name: "ISHARES U.S. AEROSPACE & DEF" },
  { ticker: "ITB", name: "ISHARES U.S. HOME CONSTRUCTI" },
  { ticker: "ITCB", name: "ITAU CORPBANCA" },
  { ticker: "ITCI", name: "INTRA-CELLULAR THERAPIES INC" },
  { ticker: "ITEQ", name: "BLUESTAR ISRAEL TECHNOLOGY E" },
  { ticker: "ITGR", name: "INTEGER HOLDINGS CORP" },
  { ticker: "ITI", name: "ITERIS INC" },
  { ticker: "ITIC", name: "INVESTORS TITLE CO" },
  { ticker: "ITM", name: "VANECK AMT-FREE INTERM MUNI" },
  { ticker: "ITMR", name: "ITAMAR MEDICAL LTD-SPON ADR" },
  { ticker: "ITOT", name: "ISHARES CORE S&P TOTAL U.S." },
  { ticker: "ITP", name: "IT TECH PACKAGING INC" },
  { ticker: "ITRI", name: "ITRON INC" },
  { ticker: "ITRM", name: "ITERUM THERAPEUTICS PLC" },
  { ticker: "ITRN", name: "ITURAN LOCATION AND CONTROL" },
  { ticker: "ITT", name: "ITT INC" },
  { ticker: "ITUB", name: "ITAU UNIBANCO H-SPON PRF ADR" },
  { ticker: "ITW", name: "ILLINOIS TOOL WORKS" },
  { ticker: "IUS", name: "INVESCO RAFI STRATEGIC US ET" },
  { ticker: "IUSB", name: "ISHARES CORE TOTAL BOND ETF" },
  { ticker: "IUSG", name: "ISHARES CORE S&P U.S. GROWTH" },
  { ticker: "IUSS", name: "INVESCO RAFI STRATEGIC US SM" },
  { ticker: "IUSV", name: "ISHARES CORE S&P U.S. VALUE" },
  { ticker: "IVAC", name: "INTEVAC INC" },
  { ticker: "IVAL", name: "ALPHA ARCHI INTL QT VLU ETF" },
  { ticker: "IVC", name: "INVACARE CORP" },
  { ticker: "IVE", name: "ISHARES S&P 500 VALUE ETF" },
  { ticker: "IVH", name: "IVY HIGH INCOME OPPORTUNITY" },
  { ticker: "IVLU", name: "ISHARES EDGE MSCI INTL VALUE" },
  { ticker: "IVOG", name: "VANGUARD S&P MID-CAP 400 GRO" },
  { ticker: "IVOL", name: "QUADRATIC INT RTE VOL INFL H" },
  { ticker: "IVOO", name: "VANGUARD S&P MID-CAP 400 ETF" },
  { ticker: "IVOV", name: "VANGUARD S&P MID-CAP 400 VAL" },
  { ticker: "IVR", name: "INVESCO MORTGAGE CAPITAL" },
  { ticker: "IVV", name: "ISHARES CORE S&P 500 ETF" },
  { ticker: "IVW", name: "ISHARES S&P 500 GROWTH ETF" },
  { ticker: "IVZ", name: "INVESCO LTD" },
  { ticker: "IWB", name: "ISHARES RUSSELL 1000 ETF" },
  { ticker: "IWC", name: "ISHARES MICRO-CAP ETF" },
  { ticker: "IWD", name: "ISHARES RUSSELL 1000 VALUE E" },
  { ticker: "IWF", name: "ISHARES RUSSELL 1000 GROWTH" },
  { ticker: "IWL", name: "ISHARES RUSSELL TOP 200 ETF" },
  { ticker: "IWM", name: "ISHARES RUSSELL 2000 ETF" },
  { ticker: "IWN", name: "ISHARES RUSSELL 2000 VALUE E" },
  { ticker: "IWO", name: "ISHARES RUSSELL 2000 GROWTH" },
  { ticker: "IWP", name: "ISHARES RUSSELL MID-CAP GROW" },
  { ticker: "IWR", name: "ISHARES RUSSELL MID-CAP ETF" },
  { ticker: "IWS", name: "ISHARES RUSSELL MID-CAP VALU" },
  { ticker: "IWV", name: "ISHARES RUSSELL 3000 ETF" },
  { ticker: "IWX", name: "ISHARES RUSSELL TOP 200 VALU" },
  { ticker: "IWY", name: "ISHARES RUSSELL TOP 200 GROW" },
  { ticker: "IX", name: "ORIX  - SPONSORED ADR" },
  { ticker: "IXC", name: "ISHARES GLOBAL ENERGY ETF" },
  { ticker: "IXG", name: "ISHARES GLOBAL FINANCIALS ET" },
  { ticker: "IXJ", name: "ISHARES GLOBAL HEALTHCARE ET" },
  { ticker: "IXN", name: "ISHARES GLOBAL TECH ETF" },
  { ticker: "IXP", name: "ISHARES GLOBAL COMM SERVICES" },
  { ticker: "IXSE", name: "WISDOMTREE INDIA EX-STATE FD" },
  { ticker: "IXUS", name: "ISHARES CORE INTL STOCK ETF" },
  { ticker: "IYC", name: "ISHARES U.S. CONSUMER SERVIC" },
  { ticker: "IYE", name: "ISHARES U.S. ENERGY ETF" },
  { ticker: "IYF", name: "ISHARES US FINANCIALS ETF" },
  { ticker: "IYG", name: "ISHARES U.S. FINANCIAL SERVI" },
  { ticker: "IYH", name: "ISHARES U.S. HEALTHCARE ETF" },
  { ticker: "IYJ", name: "ISHARES U.S. INDUSTRIALS ETF" },
  { ticker: "IYK", name: "ISHARES US CONSUMER GOODS ET" },
  { ticker: "IYLD", name: "ISHARES MORNINGSTAR MULTI-AS" },
  { ticker: "IYM", name: "ISHARES U.S. BASIC MATERIALS" },
  { ticker: "IYR", name: "ISHARES US REAL ESTATE ETF" },
  { ticker: "IYT", name: "ISHARES TRANSPORTATION AVERA" },
  { ticker: "IYW", name: "ISHARES USTECHNOLOGY ETF" },
  { ticker: "IYY", name: "ISHARES DOW JONES U.S. ETF" },
  { ticker: "IYZ", name: "ISHARES US TELECOMMUNICATION" },
  { ticker: "IZEA", name: "IZEA WORLDWIDE INC" },
  { ticker: "IZRL", name: "ARK ISRAEL INNOVATIVE TECHNO" },
  { ticker: "J", name: "JACOBS ENGINEERING GROUP INC" },
  { ticker: "JACK", name: "JACK IN THE BOX INC" },
  { ticker: "JAGG", name: "JPM US AGG BOND ETF" },
  { ticker: "JAGX", name: "JAGUAR HEALTH INC" },
  { ticker: "JAKK", name: "JAKKS PACIFIC INC" },
  { ticker: "JAN", name: "JANONE INC" },
  { ticker: "JAX", name: "J ALEXANDER'S HOLDINGS" },
  { ticker: "JAZZ", name: "JAZZ PHARMACEUTICALS PLC" },
  { ticker: "JBGS", name: "JBG SMITH PROPERTIES" },
  { ticker: "JBHT", name: "HUNT (JB) TRANSPRT SVCS INC" },
  { ticker: "JBL", name: "JABIL INC" },
  { ticker: "JBLU", name: "JETBLUE AIRWAYS CORP" },
  { ticker: "JBSS", name: "JOHN B. SANFILIPPO & SON INC" },
  { ticker: "JBT", name: "JOHN BEAN TECHNOLOGIES CORP" },
  { ticker: "JCAP", name: "JERNIGAN CAPITAL INC" },
  { ticker: "JCE", name: "NUVEEN CORE EQU ALPHA FD" },
  { ticker: "JCI", name: "JOHNSON CONTROLS INTERNATION" },
  { ticker: "JCO", name: "NUVEEN CREDIT OPP 2022 TARGE" },
  { ticker: "JCOM", name: "J2 GLOBAL INC" },
  { ticker: "JCP", name: "J.C. PENNEY CO INC" },
  { ticker: "JCPB", name: "JPMORGAN CORE PLUS BOND ETF" },
  { ticker: "JCS", name: "COMMUNICATIONS SYSTEMS INC" },
  { ticker: "JCTCF", name: "JEWETT-CAMERON TRADING LTD" },
  { ticker: "JD", name: "JD.COM INC-ADR" },
  { ticker: "JDD", name: "NUVEEN DIVER DIV AND INC FD" },
  { ticker: "JDIV", name: "JPMORGAN US DIVIDEND ETF" },
  { ticker: "JDST", name: "DIREXION DLY JR GOLD BEAR 3X" },
  { ticker: "JE", name: "JUST ENERGY GROUP INC" },
  { ticker: "JEF", name: "JEFFERIES FINANCIAL GROUP IN" },
  { ticker: "JELD", name: "JELD-WEN HOLDING INC" },
  { ticker: "JEMD", name: "NUVEEN EMERGING MARKETS DEBT" },
  { ticker: "JEQ", name: "ABERDEEN JAPAN EQUITY FUND I" },
  { ticker: "JETS", name: "US GLOBAL JETS ETF" },
  { ticker: "JFIN", name: "JIAYIN GROUP INC-ADR" },
  { ticker: "JFK", name: "8I ENTERPRISES ACQUISITION C" },
  { ticker: "JFKKR", name: "8I ENTERPRISES ACQUISITI-RTS" },
  { ticker: "JFKKU", name: "8I ENTERPRISES ACQUISITION C" },
  { ticker: "JFR", name: "NUVEEN FLOAT RATE INC FD" },
  { ticker: "JFU", name: "9F INC - ADR" },
  { ticker: "JG", name: "AURORA MOBILE LTD-ADR" },
  { ticker: "JGH", name: "NUVEEN GLOBAL H-I FD" },
  { ticker: "JHAA", name: "NUVEEN HIGH INC 2023 TRGT" },
  { ticker: "JHB", name: "NUVEEN H-I NOV 21 TGT TRM FD" },
  { ticker: "JHCS", name: "JOHN HANCOCK MULTI MEDIA COM" },
  { ticker: "JHEM", name: "JOHN HANCOCK MULTI EM MRK ET" },
  { ticker: "JHG", name: "JANUS HENDERSON GROUP PLC" },
  { ticker: "JHI", name: "JOHN HANCOCK INVEST TRUST" },
  { ticker: "JHMA", name: "JOHN HANCOCK MULT FACT MATER" },
  { ticker: "JHMC", name: "JOHN HANCOCK MULT FACT CONSU" },
  { ticker: "JHMD", name: "JOHN HANCOCK MF DEV INTL ETF" },
  { ticker: "JHME", name: "JOHN HANCOCK MULT FACT ENRGY" },
  { ticker: "JHMF", name: "JOHN HANCOCK MULTI FACT FIN" },
  { ticker: "JHMH", name: "JOHN HANCOCK MULT FACT HEALT" },
  { ticker: "JHMI", name: "JOHN HANCOCK MULT FACT INDUS" },
  { ticker: "JHML", name: "JOHN HANCOCK MULTI FACT LRG" },
  { ticker: "JHMM", name: "JOHN HANCOCK MULTI FACT MID" },
  { ticker: "JHMS", name: "JOHN HANCOCK MULT FACT CONS" },
  { ticker: "JHMT", name: "JOHN HANCOCK MULTI FACT TECH" },
  { ticker: "JHMU", name: "JOHN HANCOCK MULT FACT UTIL" },
  { ticker: "JHS", name: "JOHN HANCOCK INCOME SECS TR" },
  { ticker: "JHSC", name: "JOHN HANCOCK MULTI SMALL CAP" },
  { ticker: "JHX", name: "JAMES HARDIE IND PLC-SP ADR" },
  { ticker: "JHY", name: "NUVEEN H-I 2020 TGT TRM FD" },
  { ticker: "JIGB", name: "JPM CORP BOND RES EHD ETF" },
  { ticker: "JIH", name: "JUNIPER INDUSTRIAL HLDGS - A" },
  { ticker: "JIH=", name: "JUNIPER INDUSTRIAL HOLDINGS" },
  { ticker: "JILL", name: "J. JILL INC" },
  { ticker: "JJA", name: "IPATH SERIES B BLOOMBERG AGR" },
  { ticker: "JJC", name: "IPATH SERIES B BLOOMBERG COP" },
  { ticker: "JJE", name: "IPATH SERIES B BLOOMBERG ENE" },
  { ticker: "JJG", name: "IPATH SERIES B BLOOMBERG GRA" },
  { ticker: "JJM", name: "IPATH SER B BLOOMBERG INDUST" },
  { ticker: "JJN", name: "IPATH SERIES B BLOOMBERG NIC" },
  { ticker: "JJP", name: "IPATH SERIES B BBG PRECIOUS" },
  { ticker: "JJS", name: "IPATH SERIES B BBG SOFTS SUB" },
  { ticker: "JJSF", name: "J & J SNACK FOODS CORP" },
  { ticker: "JJT", name: "IPATH SERIES B BBG TIN SUBIN" },
  { ticker: "JJU", name: "IPATH SERIES B BLOOMBERG ALU" },
  { ticker: "JKD", name: "ISHARES MORNINGSTAR LARGE-CA" },
  { ticker: "JKE", name: "ISHARES MORNINGSTAR LARGE-CA" },
  { ticker: "JKF", name: "ISHARES MORNINGSTAR LARGE-CA" },
  { ticker: "JKG", name: "ISHARES MORNINGSTAR MID-CAP" },
  { ticker: "JKH", name: "ISHARES MORNINGSTAR MID-CAP" },
  { ticker: "JKHY", name: "JACK HENRY & ASSOCIATES INC" },
  { ticker: "JKI", name: "ISHARES MORNINGSTAR MID-CAP" },
  { ticker: "JKJ", name: "ISHARES MORNINGSTAR SMALL-CA" },
  { ticker: "JKK", name: "ISHARES MORNINGSTAR SMALL-CA" },
  { ticker: "JKL", name: "ISHARES MORNINGSTAR SMALL-CA" },
  { ticker: "JKS", name: "JINKOSOLAR HOLDING CO-ADR" },
  { ticker: "JLL", name: "JONES LANG LASALLE INC" },
  { ticker: "JLS", name: "NUVEEN MORTGAGE & INCOME" },
  { ticker: "JMBS", name: "JANUS HENDERSON MORTG BACKED" },
  { ticker: "JMEI", name: "JUMEI INTERNATIONAL-ADR" },
  { ticker: "JMF", name: "NUVEEN ENERGY MLP TOT RET FD" },
  { ticker: "JMIA", name: "JUMIA TECHNOLOGIES AG-ADR" },
  { ticker: "JMIN", name: "JPMORGAN US MIN VOLATILITY" },
  { ticker: "JMLP", name: "NUVEEN A-C ENERGY MLP OPP FD" },
  { ticker: "JMM", name: "NUVEEN MULTI-MKT INC FD" },
  { ticker: "JMOM", name: "JPMORGAN US MOMENTUM FACTOR" },
  { ticker: "JMP", name: "JMP GROUP LLC" },
  { ticker: "JMST", name: "JPM ULTRA-SHORT MUNI INCOME" },
  { ticker: "JMUB", name: "JPMORGAN MUNICIPAL ETF" },
  { ticker: "JNCE", name: "JOUNCE THERAPEUTICS INC" },
  { ticker: "JNJ", name: "JOHNSON & JOHNSON" },
  { ticker: "JNK", name: "SPDR BBG BARC HIGH YIELD BND" },
  { ticker: "JNMF", name: "WISDOMTREE JAPAN MULTIFACTOR" },
  { ticker: "JNPR", name: "JUNIPER NETWORKS INC" },
  { ticker: "JNUG", name: "DIREXION DLY JR GOLD BULL 3X" },
  { ticker: "JO", name: "IPATH SERIES B BLOOMBERG COF" },
  { ticker: "JOB", name: "GEE GROUP INC" },
  { ticker: "JOBS", name: "51JOB INC-ADR" },
  { ticker: "JOE", name: "ST JOE CO/THE" },
  { ticker: "JOF", name: "JAPAN SMALLER CAPITALIZATION" },
  { ticker: "JOUT", name: "JOHNSON OUTDOORS INC-A" },
  { ticker: "JOYY", name: "INFUSIVE COMPOUNDING GLB EQY" },
  { ticker: "JP", name: "JUPAI HOLDINGS LTD-ADR" },
  { ticker: "JPC", name: "NUVEEN PREFERRED & INCOME OP" },
  { ticker: "JPED", name: "JPMORGAN EVENT DRIVEN ETF" },
  { ticker: "JPEM", name: "JPM DIVERSIFIED RET EM EQUIT" },
  { ticker: "JPEU", name: "JPM DIVERSIFIED RET EUR EQUI" },
  { ticker: "JPGB", name: "JPMORGAN GLOBAL BOND OPPS" },
  { ticker: "JPGE", name: "JPM DIVERSIFIED RET GLB EQ" },
  { ticker: "JPHF", name: "JPM DIVERSIFIED ALTERNATIVES" },
  { ticker: "JPHY", name: "JPM HY RESEARCH ENHANCED" },
  { ticker: "JPI", name: "NUVEEN PFD AND INC TERM FD" },
  { ticker: "JPIN", name: "JPM DIVERSIFIED RET INTL EQ" },
  { ticker: "JPLS", name: "JPMORGAN LONG/SHORT ETF" },
  { ticker: "JPM", name: "JPMORGAN CHASE & CO" },
  { ticker: "JPMB", name: "JPMORGAN USD EMERGING MARKET" },
  { ticker: "JPME", name: "JPM DIVER RET US MC EQUITY" },
  { ticker: "JPMF", name: "JPMORGAN MANAGED FUTURES ETF" },
  { ticker: "JPMV", name: "ISHARES EDGE MSCI MIN VOL JA" },
  { ticker: "JPN", name: "XTRACKERS JAPAN JPX-NIKKEI 4" },
  { ticker: "JPNL", name: "DIREXION DLY JAPAN BULL 3X" },
  { ticker: "JPS", name: "NUVEEN PREFERRED & INCOME SE" },
  { ticker: "JPSE", name: "JPM DIVERSIFIED RT SMALL CAP" },
  { ticker: "JPST", name: "JPMORGAN ULTRA-SHORT INCOME" },
  { ticker: "JPT", name: "NUVEEN PREFERRED INCOME 2022" },
  { ticker: "JPUS", name: "JPMORGAN DIV RET US EQ ETF" },
  { ticker: "JPXN", name: "ISHARES JPX-NIKKEI 400 ETF" },
  { ticker: "JQC", name: "NUVEEN CREDIT STRAT INCM" },
  { ticker: "JQUA", name: "JPMORGAN US QUALITY FACTOR" },
  { ticker: "JRI", name: "NUVEEN REAL ASST INC & GR FD" },
  { ticker: "JRJC", name: "CHINA FINANCE ONLINE CO-ADR" },
  { ticker: "JRO", name: "NUVEEN FLT RATE INC OPP FD" },
  { ticker: "JRS", name: "NUVEEN REAL EST INC FD" },
  { ticker: "JRSH", name: "JERASH HOLDINGS US INC" },
  { ticker: "JRVR", name: "JAMES RIVER GROUP HOLDINGS L" },
  { ticker: "JSD", name: "NUVEEN SHT DUR CRED OPP FD" },
  { ticker: "JSMD", name: "JANUS SMALL MID CAP GR ALP" },
  { ticker: "JSML", name: "JANUS DETROIT STREET TRUST J" },
  { ticker: "JT", name: "JIANPU TECHNOLOGY INC-SP ADR" },
  { ticker: "JTA", name: "NUVEEN TX-ADV TOT RET STR FD" },
  { ticker: "JTD", name: "NUVEEN TAX-ADV DIV GRWTH FD" },
  { ticker: "JUST", name: "GOLDMAN SACHS JUST US LRG" },
  { ticker: "JVA", name: "COFFEE HOLDING CO INC" },
  { ticker: "JVAL", name: "JPMORGAN US VALUE FACTOR ETF" },
  { ticker: "JW.A", name: "WILEY (JOHN) & SONS-CLASS A" },
  { ticker: "JW.B", name: "WILEY (JOHN) & SONS-CL B" },
  { ticker: "JWN", name: "NORDSTROM INC" },
  { ticker: "JXI", name: "ISHARES GLOBAL UTILITIES ETF" },
  { ticker: "JYNT", name: "JOINT CORP/THE" },
  { ticker: "K", name: "KELLOGG CO" },
  { ticker: "KAI", name: "KADANT INC" },
  { ticker: "KALA", name: "KALA PHARMACEUTICALS INC" },
  { ticker: "KALL", name: "KRANESHARES MSCI ALL CHINA I" },
  { ticker: "KALU", name: "KAISER ALUMINUM CORP" },
  { ticker: "KALV", name: "KALVISTA PHARMACEUTICALS INC" },
  { ticker: "KAMN", name: "KAMAN CORP" },
  { ticker: "KAR", name: "KAR AUCTION SERVICES INC" },
  { ticker: "KARS", name: "KRANESHARES ELECTRIC VEHICLE" },
  { ticker: "KB", name: "KB FINANCIAL GROUP INC-ADR" },
  { ticker: "KBA", name: "KRANESH BOSERA MSCI CHINA A" },
  { ticker: "KBAL", name: "KIMBALL INTERNATIONAL-B" },
  { ticker: "KBE", name: "SPDR S&P BANK ETF" },
  { ticker: "KBH", name: "KB HOME" },
  { ticker: "KBLM", name: "KBL MERGER CORP IV" },
  { ticker: "KBLMR", name: "KBL MERGER CORP IV - RTS" },
  { ticker: "KBLMU", name: "KBL MERGER CORP IV" },
  { ticker: "KBR", name: "KBR INC" },
  { ticker: "KBSF", name: "KBS FASHION GROUP LTD" },
  { ticker: "KBWB", name: "INVESCO KBW BANK ETF" },
  { ticker: "KBWD", name: "INVESCO KBW HIGH DIVIDEND YI" },
  { ticker: "KBWP", name: "INVESCO KBW PROPERTY & CASUA" },
  { ticker: "KBWR", name: "INVESCO KBW REGIONAL BANKING" },
  { ticker: "KBWY", name: "INVESCO KBW PREMIUM YIELD EQ" },
  { ticker: "KCCB", name: "KRANSHARES CCBS CHINA CORPOR" },
  { ticker: "KCE", name: "SPDR S&P CAPITAL MARKETS ETF" },
  { ticker: "KCNY", name: "KRANESHARES E FUND CHINA COM" },
  { ticker: "KDFI", name: "KFA DYNAMIC FIXED INCOME ETF" },
  { ticker: "KDMN", name: "KADMON HOLDINGS INC" },
  { ticker: "KDP", name: "KEURIG DR PEPPER INC" },
  { ticker: "KE", name: "KIMBALL ELECTRONICS INC" },
  { ticker: "KELYA", name: "KELLY SERVICES INC -A" },
  { ticker: "KELYB", name: "KELLY SERVICES INC -CL B" },
  { ticker: "KEM", name: "KEMET CORP" },
  { ticker: "KEMQ", name: "KRANESHARES EMERGING MARKETS" },
  { ticker: "KEMX", name: "KRANESHARES MSCI E-MKT EX-CH" },
  { ticker: "KEN", name: "KENON HOLDINGS LTD" },
  { ticker: "KEP", name: "KOREA ELEC POWER CORP-SP ADR" },
  { ticker: "KEQU", name: "KEWAUNEE SCIENTIFIC CP" },
  { ticker: "KERN", name: "AKERNA CORP" },
  { ticker: "KEX", name: "KIRBY CORP" },
  { ticker: "KEY", name: "KEYCORP" },
  { ticker: "KEYS", name: "KEYSIGHT TECHNOLOGIES IN" },
  { ticker: "KF", name: "KOREA FUND INC" },
  { ticker: "KFFB", name: "KENTUCKY FIRST FEDERAL BANCO" },
  { ticker: "KFRC", name: "KFORCE INC" },
  { ticker: "KFS", name: "KINGSWAY FINANCIAL SERVICES" },
  { ticker: "KFY", name: "KORN FERRY" },
  { ticker: "KFYP", name: "KRANESHARES CICC CHINA LEADE" },
  { ticker: "KGC", name: "KINROSS GOLD CORP" },
  { ticker: "KGJI", name: "KINGOLD JEWELRY INC" },
  { ticker: "KGRN", name: "KRANESHARES MSCI CHN ENVIRON" },
  { ticker: "KHC", name: "KRAFT HEINZ CO/THE" },
  { ticker: "KIDS", name: "ORTHOPEDIATRICS CORP" },
  { ticker: "KIE", name: "SPDR S&P INSURANCE ETF" },
  { ticker: "KIM", name: "KIMCO REALTY CORP" },
  { ticker: "KIN", name: "KINDRED BIOSCIENCES INC" },
  { ticker: "KINS", name: "KINGSTONE COS INC" },
  { ticker: "KIO", name: "KKR INCOME OPPORTUNITIES" },
  { ticker: "KIQ", name: "KELSO TECHNOLOGIES INC" },
  { ticker: "KIRK", name: "KIRKLAND'S INC" },
  { ticker: "KJAN", name: "INNOVATOR RUSSELL 2000 P BUF" },
  { ticker: "KKR", name: "KKR & CO INC -A" },
  { ticker: "KL", name: "KIRKLAND LAKE GOLD LTD" },
  { ticker: "KLAC", name: "KLA CORP" },
  { ticker: "KLCD", name: "KFA LRG CAP QUAL DIV NDX ETF" },
  { ticker: "KLDO", name: "KALEIDO BIOSCIENCES INC" },
  { ticker: "KLDW", name: "KNOWLEDGE LEADERS DEVELOPED" },
  { ticker: "KLIC", name: "KULICKE & SOFFA INDUSTRIES" },
  { ticker: "KLR", name: "KALEYRA INC" },
  { ticker: "KLXE", name: "KLX ENERGY SERVICES HOLDING" },
  { ticker: "KMB", name: "KIMBERLY-CLARK CORP" },
  { ticker: "KMDA", name: "KAMADA LTD" },
  { ticker: "KMED", name: "KRANESHARES EMERGING MARKETS" },
  { ticker: "KMF", name: "KAYNE ANDERSON MIDSTREAM/ENE" },
  { ticker: "KMI", name: "KINDER MORGAN INC" },
  { ticker: "KMPH", name: "KEMPHARM INC" },
  { ticker: "KMPR", name: "KEMPER CORP" },
  { ticker: "KMT", name: "KENNAMETAL INC" },
  { ticker: "KMX", name: "CARMAX INC" },
  { ticker: "KN", name: "KNOWLES CORP" },
  { ticker: "KNAB", name: "MICROSECTORS US BIG BANK INV" },
  { ticker: "KNDI", name: "KANDI TECHNOLOGIES GROUP INC" },
  { ticker: "KNG", name: "CBOE VEST S&P 500 DIV ARI TR" },
  { ticker: "KNL", name: "KNOLL INC" },
  { ticker: "KNOP", name: "KNOT OFFSHORE PARTNERS LP" },
  { ticker: "KNOW", name: "DIREXION ALL CAP INSIDER SEN" },
  { ticker: "KNSA", name: "KINIKSA PHARMACEUTICALS-A" },
  { ticker: "KNSL", name: "KINSALE CAPITAL GROUP INC" },
  { ticker: "KNX", name: "KNIGHT-SWIFT TRANSPORTATION" },
  { ticker: "KO", name: "COCA-COLA CO/THE" },
  { ticker: "KOCT", name: "INNOVATOR RUSSELL 2000 POWER" },
  { ticker: "KOD", name: "KODIAK SCIENCES INC" },
  { ticker: "KODK", name: "EASTMAN KODAK CO" },
  { ticker: "KOF", name: "COCA-COLA FEMSA SAB-SP ADR" },
  { ticker: "KOIN", name: "INNOVATION SHARES NEXTGEN PR" },
  { ticker: "KOL", name: "VANECK COAL ETF" },
  { ticker: "KOLD", name: "PROSHARES ULTRASHORT BLOOMBE" },
  { ticker: "KOMP", name: "SPDR S&P KENSHO NEW ECONOMIE" },
  { ticker: "KOP", name: "KOPPERS HOLDINGS INC" },
  { ticker: "KOPN", name: "KOPIN CORP" },
  { ticker: "KORP", name: "AMERICAN CENTURY DIVERSIFIED" },
  { ticker: "KORU", name: "DIREXION DLY S KOREA BULL 3X" },
  { ticker: "KOS", name: "KOSMOS ENERGY LTD" },
  { ticker: "KOSS", name: "KOSS CORP" },
  { ticker: "KPTI", name: "KARYOPHARM THERAPEUTICS INC" },
  { ticker: "KR", name: "KROGER CO" },
  { ticker: "KRA", name: "KRATON CORP" },
  { ticker: "KRC", name: "KILROY REALTY CORP" },
  { ticker: "KRE", name: "SPDR S&P REGIONAL BANKING" },
  { ticker: "KREF", name: "KKR REAL ESTATE FINANCE TRUS" },
  { ticker: "KRG", name: "KITE REALTY GROUP TRUST" },
  { ticker: "KRKR", name: "36KR HOLDINGS INC" },
  { ticker: "KRMA", name: "GLOBAL X CONSCIOUS COMPANIES" },
  { ticker: "KRMD", name: "REPRO MEDSYSTEMS INC" },
  { ticker: "KRNT", name: "KORNIT DIGITAL LTD" },
  { ticker: "KRNY", name: "KEARNY FINANCIAL CORP/MD" },
  { ticker: "KRO", name: "KRONOS WORLDWIDE INC" },
  { ticker: "KRP", name: "KIMBELL ROYALTY PARTNERS LP" },
  { ticker: "KRTX", name: "KARUNA THERAPEUTICS INC" },
  { ticker: "KRUS", name: "KURA SUSHI USA INC-CLASS A" },
  { ticker: "KRYS", name: "KRYSTAL BIOTECH INC" },
  { ticker: "KSA", name: "ISHARES MSCI SAUDI ARABIA ET" },
  { ticker: "KSCD", name: "KFA SML CAP QUAL DIV NDX ETF" },
  { ticker: "KSM", name: "DWS STRATEGIC MUNICIPAL INCO" },
  { ticker: "KSS", name: "KOHLS CORP" },
  { ticker: "KSU", name: "KANSAS CITY SOUTHERN" },
  { ticker: "KT", name: "KT CORP-SP ADR" },
  { ticker: "KTB", name: "KONTOOR BRANDS INC" },
  { ticker: "KTCC", name: "KEY TRONIC CORP" },
  { ticker: "KTF", name: "DWS MUNICIPAL INCOME TRUST" },
  { ticker: "KTOS", name: "KRATOS DEFENSE & SECURITY" },
  { ticker: "KTOV", name: "KITOV PHARMA LTD-ADR" },
  { ticker: "KURA", name: "KURA ONCOLOGY INC" },
  { ticker: "KURE", name: "KRANESHARES MSCI ALL CHINA H" },
  { ticker: "KVHI", name: "KVH INDUSTRIES INC" },
  { ticker: "KW", name: "KENNEDY-WILSON HOLDINGS INC" },
  { ticker: "KWEB", name: "KRANESHARES CSI CHINA INTERN" },
  { ticker: "KWR", name: "QUAKER CHEMICAL CORP" },
  { ticker: "KXI", name: "ISHARES GLOBAL CONSUMER STAP" },
  { ticker: "KXIN", name: "KAIXIN AUTO HOLDINGS" },
  { ticker: "KYN", name: "KAYNE ANDERSON MLP/MIDSTREAM" },
  { ticker: "KZIA", name: "KAZIA THERAPEUTICS-SPON ADR" },
  { ticker: "KZR", name: "KEZAR LIFE SCIENCES INC" },
  { ticker: "L", name: "LOEWS CORP" },
  { ticker: "LABD", name: "DRX DLY S&P BIOTECH BEAR 3X" },
  { ticker: "LABU", name: "DRX DLY S&P BIOTECH BULL 3X" },
  { ticker: "LAC", name: "LITHIUM AMERICAS CORP" },
  { ticker: "LACK", name: "DRX DLY CONS STAPLES BEAR 3X" },
  { ticker: "LACQ", name: "LEISURE ACQUISITION CORP" },
  { ticker: "LACQU", name: "LEISURE ACQUISITION CORP" },
  { ticker: "LAD", name: "LITHIA MOTORS INC-CL A" },
  { ticker: "LADR", name: "LADDER CAPITAL CORP-REIT" },
  { ticker: "LAIX", name: "LAIX INC - ADR" },
  { ticker: "LAKE", name: "LAKELAND INDUSTRIES INC" },
  { ticker: "LAMR", name: "LAMAR ADVERTISING CO-A" },
  { ticker: "LANC", name: "LANCASTER COLONY CORP" },
  { ticker: "LAND", name: "GLADSTONE LAND CORP" },
  { ticker: "LARK", name: "LANDMARK BANCORP INC" },
  { ticker: "LASR", name: "NLIGHT INC" },
  { ticker: "LATN", name: "UNION ACQUISITION CORP II" },
  { ticker: "LATNU", name: "UNION ACQUISITION CORP II" },
  { ticker: "LAUR", name: "LAUREATE EDUCATION INC-A" },
  { ticker: "LAWS", name: "LAWSON PRODUCTS INC" },
  { ticker: "LAZ", name: "LAZARD LTD-CL A" },
  { ticker: "LAZY", name: "LAZYDAYS HOLDINGS INC" },
  { ticker: "LB", name: "L BRANDS INC" },
  { ticker: "LBAI", name: "LAKELAND BANCORP INC" },
  { ticker: "LBC", name: "LUTHER BURBANK CORP" },
  { ticker: "LBDC", name: "ETRACS 2XM LEV WF BDC SER B" },
  { ticker: "LBJ", name: "DIREXION DLY LAT AM BULL 3X" },
  { ticker: "LBRDA", name: "LIBERTY BROADBAND-A" },
  { ticker: "LBRDK", name: "LIBERTY BROADBAND-C" },
  { ticker: "LBRT", name: "LIBERTY OILFIELD SERVICES -A" },
  { ticker: "LBTYA", name: "LIBERTY GLOBAL PLC-A" },
  { ticker: "LBTYB", name: "LIBERTY GLOBAL PLC-B" },
  { ticker: "LBTYK", name: "LIBERTY GLOBAL PLC- C" },
  { ticker: "LBY", name: "LIBBEY INC" },
  { ticker: "LC", name: "LENDINGCLUB CORP" },
  { ticker: "LCA", name: "LANDCADIA HOLDINGS II INC-A" },
  { ticker: "LCAHU", name: "LANDCADIA HOLDINGS II INC" },
  { ticker: "LCI", name: "LANNETT CO INC" },
  { ticker: "LCII", name: "LCI INDUSTRIES" },
  { ticker: "LCNB", name: "LCNB CORPORATION" },
  { ticker: "LCR", name: "LEUTHOLD CORE ETF" },
  { ticker: "LCTX", name: "LINEAGE CELL THERAPEUTICS IN" },
  { ticker: "LCUT", name: "LIFETIME BRANDS INC" },
  { ticker: "LD", name: "IPATH BLOOMBERG LEAD SUBINDE" },
  { ticker: "LDEM", name: "ISHARES ESG MSCI EM LEADERS" },
  { ticker: "LDL", name: "LYDALL INC" },
  { ticker: "LDOS", name: "LEIDOS HOLDINGS INC" },
  { ticker: "LDP", name: "COHEN & STEERS LIMITED DURAT" },
  { ticker: "LDRS", name: "INNOVATOR IBD ETF LEADERS ET" },
  { ticker: "LDSF", name: "FIRST TR LOW DURATION ST-ETF" },
  { ticker: "LDUR", name: "PIMCO ENHANCED LOW DURATION" },
  { ticker: "LE", name: "LANDS' END INC" },
  { ticker: "LEA", name: "LEAR CORP" },
  { ticker: "LEAD", name: "REALITY SHARES DIVCON LEADER" },
  { ticker: "LEAF", name: "LEAF GROUP LTD" },
  { ticker: "LECO", name: "LINCOLN ELECTRIC HOLDINGS" },
  { ticker: "LEDS", name: "SEMILEDS CORP/TW" },
  { ticker: "LEE", name: "LEE ENTERPRISES" },
  { ticker: "LEG", name: "LEGGETT & PLATT INC" },
  { ticker: "LEGH", name: "LEGACY HOUSING CORP" },
  { ticker: "LEGR", name: "FIRST TRUST INDXX INNOVATIVE" },
  { ticker: "LEJU", name: "LEJU HOLDINGS LTD-ADR" },
  { ticker: "LEMB", name: "ISHARES JP MORGAN EM LOCAL C" },
  { ticker: "LEN", name: "LENNAR CORP-A" },
  { ticker: "LEN.B", name: "LENNAR CORP - B SHS" },
  { ticker: "LEND", name: "AMP CROWDBUREAU PEER TO PEER" },
  { ticker: "LEO", name: "BNYM STRAT MUNI" },
  { ticker: "LEU", name: "CENTRUS ENERGY CORP-CLASS A" },
  { ticker: "LEVI", name: "LEVI STRAUSS & CO- CLASS A" },
  { ticker: "LEVL", name: "LEVEL ONE BANCORP INC" },
  { ticker: "LFAC", name: "LF CAPITAL ACQUISITION COR-A" },
  { ticker: "LFACU", name: "LF CAPITAL ACQUISITION CORP" },
  { ticker: "LFC", name: "CHINA LIFE INSURANCE CO-ADR" },
  { ticker: "LFEQ", name: "VANECK VECTORS LONG/FLAT TRE" },
  { ticker: "LFUS", name: "LITTELFUSE INC" },
  { ticker: "LFVN", name: "LIFEVANTAGE CORP" },
  { ticker: "LGC", name: "LEGACY ACQUISITION CORP-CL A" },
  { ticker: "LGC=", name: "LEGACY ACQUISITION CORP" },
  { ticker: "LGF.A", name: "LIONS GATE ENTERTAINMENT-A" },
  { ticker: "LGF.B", name: "LIONS GATE ENTERTAINMENT-B" },
  { ticker: "LGH", name: "HCM DEFENDER 500 INDEX ETF" },
  { ticker: "LGI", name: "LAZARD GLOBAL TOT RT & INC" },
  { ticker: "LGIH", name: "LGI HOMES INC" },
  { ticker: "LGL", name: "LGL GROUP INC/THE" },
  { ticker: "LGLV", name: "SPDR SSGA US LARGE CAP LOW V" },
  { ticker: "LGND", name: "LIGAND PHARMACEUTICALS" },
  { ticker: "LGOV", name: "FIRST TR LONG DURATION O-ETF" },
  { ticker: "LH", name: "LABORATORY CRP OF AMER HLDGS" },
  { ticker: "LHC", name: "LEO HOLDINGS CORP -A" },
  { ticker: "LHC=", name: "LEO HOLDINGS CORP" },
  { ticker: "LHCG", name: "LHC GROUP INC" },
  { ticker: "LHX", name: "L3HARRIS TECHNOLOGIES INC" },
  { ticker: "LIFE", name: "ATYR PHARMA INC" },
  { ticker: "LII", name: "LENNOX INTERNATIONAL INC" },
  { ticker: "LILA", name: "LIBERTY LATIN AMERIC-CL A" },
  { ticker: "LILAK", name: "LIBERTY LATIN AMERIC-CL C" },
  { ticker: "LIN", name: "LINDE PLC" },
  { ticker: "LINC", name: "LINCOLN EDUCATIONAL SERVICES" },
  { ticker: "LIND", name: "LINDBLAD EXPEDITIONS HOLDING" },
  { ticker: "LINX", name: "LINX SA - ADR" },
  { ticker: "LIQT", name: "LIQTECH INTERNATIONAL INC" },
  { ticker: "LIT", name: "GLOBAL X LITHIUM & BATTERY T" },
  { ticker: "LITB", name: "LIGHTINTHEBOX HOLDING-ADR" },
  { ticker: "LITE", name: "LUMENTUM HOLDINGS INC" },
  { ticker: "LIVE", name: "LIVE VENTURES INC" },
  { ticker: "LIVK", name: "LIV CAPITAL ACQUISITION CO-A" },
  { ticker: "LIVKU", name: "LIV CAPITAL ACQUISITION CORP" },
  { ticker: "LIVN", name: "LIVANOVA PLC" },
  { ticker: "LIVX", name: "LIVEXLIVE MEDIA INC" },
  { ticker: "LIZI", name: "LIZHI INC" },
  { ticker: "LJPC", name: "LA JOLLA PHARMACEUTICAL CO" },
  { ticker: "LK", name: "LUCKIN COFFEE INC - ADR" },
  { ticker: "LKCO", name: "LUOKUNG TECHNOLOGY CORP" },
  { ticker: "LKFN", name: "LAKELAND FINANCIAL CORP" },
  { ticker: "LKOR", name: "FLEXSHARES CREDIT-SCORED LON" },
  { ticker: "LKQ", name: "LKQ CORP" },
  { ticker: "LL", name: "LUMBER LIQUIDATORS HOLDINGS" },
  { ticker: "LLEX", name: "LILIS ENERGY INC" },
  { ticker: "LLIT", name: "LIANLUO SMART LTD-A" },
  { ticker: "LLNW", name: "LIMELIGHT NETWORKS INC" },
  { ticker: "LLY", name: "ELI LILLY & CO" },
  { ticker: "LM", name: "LEGG MASON INC" },
  { ticker: "LMAT", name: "LEMAITRE VASCULAR INC" },
  { ticker: "LMB", name: "LIMBACH HOLDINGS INC" },
  { ticker: "LMBS", name: "FIRST TRUST LOW DURATION OPP" },
  { ticker: "LMFA", name: "LM FUNDING AMERICA INC" },
  { ticker: "LMLB", name: "ETRACS MNTH PAY 2XL WF MLP-B" },
  { ticker: "LMLP", name: "ETRACS MNTH PAY 2XL WF MLP" },
  { ticker: "LMNL", name: "LIMINAL BIOSCIENCES INC" },
  { ticker: "LMNR", name: "LIMONEIRA CO" },
  { ticker: "LMNX", name: "LUMINEX CORP" },
  { ticker: "LMPX", name: "LMP AUTOMOTIVE HOLDINGS INC" },
  { ticker: "LMRK", name: "LANDMARK INFRASTRUCTURE PART" },
  { ticker: "LMST", name: "LIMESTONE BANCORP INC" },
  { ticker: "LMT", name: "LOCKHEED MARTIN CORP" },
  { ticker: "LN", name: "LINE CORP-SPONSORED ADR" },
  { ticker: "LNC", name: "LINCOLN NATIONAL CORP" },
  { ticker: "LND", name: "BRASILAGRO-CIA BRA - SPN ADR" },
  { ticker: "LNDC", name: "LANDEC CORP" },
  { ticker: "LNG", name: "CHENIERE ENERGY INC" },
  { ticker: "LNGR", name: "GLOBAL X LONGEVITY THEMATIC" },
  { ticker: "LNN", name: "LINDSAY CORP" },
  { ticker: "LNT", name: "ALLIANT ENERGY CORP" },
  { ticker: "LNTH", name: "LANTHEUS HOLDINGS INC" },
  { ticker: "LOAC", name: "LONGEVITY ACQUISITION CORP" },
  { ticker: "LOACR", name: "LONGEVITY ACQUISITION CO-RTS" },
  { ticker: "LOACU", name: "LONGEVITY ACQUISITION CORP" },
  { ticker: "LOAN", name: "MANHATTAN BRIDGE CAPITAL INC" },
  { ticker: "LOB", name: "LIVE OAK BANCSHARES INC" },
  { ticker: "LOCO", name: "EL POLLO LOCO HOLDINGS INC" },
  { ticker: "LODE", name: "COMSTOCK MINING INC" },
  { ticker: "LOGC", name: "LOGICBIO THERAPEUTICS INC" },
  { ticker: "LOGI", name: "LOGITECH INTERNATIONAL-REG" },
  { ticker: "LOGM", name: "LOGMEIN INC" },
  { ticker: "LOMA", name: "LOMA NEGRA CIA IND-SPON ADR" },
  { ticker: "LONE", name: "LONESTAR RESOURCES US I-CL A" },
  { ticker: "LOOP", name: "LOOP INDUSTRIES INC" },
  { ticker: "LOPE", name: "GRAND CANYON EDUCATION INC" },
  { ticker: "LORL", name: "LORAL SPACE & COMMUNICATIONS" },
  { ticker: "LOUP", name: "INNOVATOR LOUP FRONTIER TECH" },
  { ticker: "LOV", name: "SPARK NETWORKS SE-ADR" },
  { ticker: "LOVE", name: "LOVESAC CO/THE" },
  { ticker: "LOW", name: "LOWE'S COS INC" },
  { ticker: "LOWC", name: "SPDR MSCI ACWI LOW CARBON" },
  { ticker: "LPCN", name: "LIPOCINE INC" },
  { ticker: "LPG", name: "DORIAN LPG LTD" },
  { ticker: "LPI", name: "LAREDO PETROLEUM INC" },
  { ticker: "LPL", name: "LG DISPLAY CO LTD-ADR" },
  { ticker: "LPLA", name: "LPL FINANCIAL HOLDINGS INC" },
  { ticker: "LPSN", name: "LIVEPERSON INC" },
  { ticker: "LPTH", name: "LIGHTPATH TECHNOLOGIES INC-A" },
  { ticker: "LPTX", name: "LEAP THERAPEUTICS INC" },
  { ticker: "LPX", name: "LOUISIANA-PACIFIC CORP" },
  { ticker: "LQD", name: "ISHARES IBOXX INVESTMENT GRA" },
  { ticker: "LQDA", name: "LIQUIDIA TECHNOLOGIES INC" },
  { ticker: "LQDH", name: "ISHARES INT HEDG CORP BD ETF" },
  { ticker: "LQDI", name: "ISHARES INFLATION HEDGED COR" },
  { ticker: "LQDT", name: "LIQUIDITY SERVICES INC" },
  { ticker: "LRCX", name: "LAM RESEARCH CORP" },
  { ticker: "LRET", name: "ETRACS MONTHLY PAY 2XLEVERAG" },
  { ticker: "LRGE", name: "CLRBRDG LRG CAP GWTH ESG ETF" },
  { ticker: "LRGF", name: "ISHARES EDGE MSCI MF USA" },
  { ticker: "LRN", name: "K12 INC" },
  { ticker: "LRNZ", name: "TRUEMARK AI & DEEP LEARNING" },
  { ticker: "LSACU", name: "LIFESCI ACQUISITION CORP" },
  { ticker: "LSAF", name: "LEADERSHARES ALPHAFACTOR US" },
  { ticker: "LSBK", name: "LAKE SHORE BANCORP INC" },
  { ticker: "LSCC", name: "LATTICE SEMICONDUCTOR CORP" },
  { ticker: "LSI", name: "LIFE STORAGE INC" },
  { ticker: "LSLT", name: "SALT LOW TRUBETA US MARKET E" },
  { ticker: "LSST", name: "NATIXIS LOOMIS SAYLES SHRT D" },
  { ticker: "LSTR", name: "LANDSTAR SYSTEM INC" },
  { ticker: "LSXMA", name: "LIBERTY MEDIA COR-SIRIUSXM A" },
  { ticker: "LSXMB", name: "LIBERTY MEDIA COR-SIRIUSXM B" },
  { ticker: "LSXMK", name: "LIBERTY MEDIA COR-SIRIUSXM C" },
  { ticker: "LTBR", name: "LIGHTBRIDGE CORP" },
  { ticker: "LTC", name: "LTC PROPERTIES INC" },
  { ticker: "LTHM", name: "LIVENT CORP" },
  { ticker: "LTL", name: "PROSHARES ULTRA TELECOMMUNIC" },
  { ticker: "LTM", name: "LATAM AIRLINES GROUP-SP ADR" },
  { ticker: "LTPZ", name: "PIMCO 15+ YR US TIPS INDX" },
  { ticker: "LTRPA", name: "LIBERTY TRIPADVISOR HDG-A" },
  { ticker: "LTRPB", name: "LIBERTY TRIPADVISOR HDG-B" },
  { ticker: "LTRX", name: "LANTRONIX INC" },
  { ticker: "LUB", name: "LUBY'S INC" },
  { ticker: "LULU", name: "LULULEMON ATHLETICA INC" },
  { ticker: "LUNA", name: "LUNA INNOVATIONS INC" },
  { ticker: "LUV", name: "SOUTHWEST AIRLINES CO" },
  { ticker: "LVGO", name: "LIVONGO HEALTH INC" },
  { ticker: "LVHB", name: "INNOVAT LUNT LOW VOL/HIGH BE" },
  { ticker: "LVHD", name: "LEGG MASON LO VOL HI DIV ETF" },
  { ticker: "LVHE", name: "LEGG MASON EM LOW VOL HI DIV" },
  { ticker: "LVHI", name: "LEGG MASON INTL LOW VOL DIV" },
  { ticker: "LVS", name: "LAS VEGAS SANDS CORP" },
  { ticker: "LVUS", name: "HARTFORD MULTIFACTOR LOW VOL" },
  { ticker: "LW", name: "LAMB WESTON HOLDINGS INC" },
  { ticker: "LWAY", name: "LIFEWAY FOODS INC" },
  { ticker: "LX", name: "LEXINFINTECH HOLDINGS L-ADR" },
  { ticker: "LXFR", name: "LUXFER HOLDINGS PLC" },
  { ticker: "LXP", name: "LEXINGTON REALTY TRUST" },
  { ticker: "LXRX", name: "LEXICON PHARMACEUTICALS INC" },
  { ticker: "LXU", name: "LSB INDUSTRIES INC" },
  { ticker: "LYB", name: "LYONDELLBASELL INDU-CL A" },
  { ticker: "LYFT", name: "LYFT INC-A" },
  { ticker: "LYG", name: "LLOYDS BANKING GROUP PLC-ADR" },
  { ticker: "LYL", name: "DRAGON VICTORY INTERNATIONAL" },
  { ticker: "LYTS", name: "LSI INDUSTRIES INC" },
  { ticker: "LYV", name: "LIVE NATION ENTERTAINMENT IN" },
  { ticker: "LZB", name: "LA-Z-BOY INC" },
  { ticker: "M", name: "MACY'S INC" },
  { ticker: "MA", name: "MASTERCARD INC - A" },
  { ticker: "MAA", name: "MID-AMERICA APARTMENT COMM" },
  { ticker: "MAAX", name: "VANECK VECTORS MUNICIPAL ALL" },
  { ticker: "MAC", name: "MACERICH CO/THE" },
  { ticker: "MACK", name: "MERRIMACK PHARMACEUTICALS IN" },
  { ticker: "MAG", name: "MAG SILVER CORP" },
  { ticker: "MAGA", name: "POINT BRIDGE GOP STOCK T ETF" },
  { ticker: "MAGS", name: "MAGAL SECURITY SYS LTD" },
  { ticker: "MAIN", name: "MAIN STREET CAPITAL CORP" },
  { ticker: "MAN", name: "MANPOWERGROUP INC" },
  { ticker: "MANH", name: "MANHATTAN ASSOCIATES INC" },
  { ticker: "MANT", name: "MANTECH INTERNATIONAL CORP-A" },
  { ticker: "MANU", name: "MANCHESTER UNITED PLC-CL A" },
  { ticker: "MAR", name: "MARRIOTT INTERNATIONAL -CL A" },
  { ticker: "MARA", name: "MARATHON PATENT GROUP INC" },
  { ticker: "MARB", name: "FIRST TRUST MERGER ARBITRAGE" },
  { ticker: "MARK", name: "REMARK HOLDINGS INC" },
  { ticker: "MARPS", name: "MARINE PETROLEUM TRUST" },
  { ticker: "MAS", name: "MASCO CORP" },
  { ticker: "MASI", name: "MASIMO CORP" },
  { ticker: "MAT", name: "MATTEL INC" },
  { ticker: "MATW", name: "MATTHEWS INTL CORP-CLASS A" },
  { ticker: "MATX", name: "MATSON INC" },
  { ticker: "MAV", name: "PIONEER MUNI HI INC ADV TRST" },
  { ticker: "MAXR", name: "MAXAR TECHNOLOGIES INC" },
  { ticker: "MAYS", name: "MAYS (J.W.) INC" },
  { ticker: "MBB", name: "ISHARES MBS ETF" },
  { ticker: "MBCN", name: "MIDDLEFIELD BANC CORP" },
  { ticker: "MBI", name: "MBIA INC" },
  { ticker: "MBII", name: "MARRONE BIO INNOVATIONS INC" },
  { ticker: "MBIN", name: "MERCHANTS BANCORP/IN" },
  { ticker: "MBIO", name: "MUSTANG BIO INC" },
  { ticker: "MBOT", name: "MICROBOT MEDICAL INC" },
  { ticker: "MBRX", name: "MOLECULIN BIOTECH INC" },
  { ticker: "MBSD", name: "FLEXSHARES DISCIPLINED DURAT" },
  { ticker: "MBT", name: "MOBILE TELESYSTEMS-SP ADR" },
  { ticker: "MBUU", name: "MALIBU BOATS INC - A" },
  { ticker: "MBWM", name: "MERCANTILE BANK CORP" },
  { ticker: "MC", name: "MOELIS & CO - CLASS A" },
  { ticker: "MCA", name: "BLACKROCK MUNIYIELD CA QUALI" },
  { ticker: "MCB", name: "METROPOLITAN BANK HOLDING CO" },
  { ticker: "MCBC", name: "MACATAWA BANK CORP" },
  { ticker: "MCBS", name: "METROCITY BANKSHARES INC" },
  { ticker: "MCC", name: "MEDLEY CAPITAL CORP" },
  { ticker: "MCD", name: "MCDONALD'S CORP" },
  { ticker: "MCEF", name: "FIRST TRUST MUNICIPAL CEF IN" },
  { ticker: "MCEP", name: "MID-CON ENERGY PARTNERS LP" },
  { ticker: "MCF", name: "CONTANGO OIL & GAS" },
  { ticker: "MCFT", name: "MASTERCRAFT BOAT HOLDINGS IN" },
  { ticker: "MCHI", name: "ISHARES MSCI CHINA ETF" },
  { ticker: "MCHP", name: "MICROCHIP TECHNOLOGY INC" },
  { ticker: "MCHX", name: "MARCHEX INC-CLASS B" },
  { ticker: "MCI", name: "BARINGS CORPORATE INVESTORS" },
  { ticker: "MCK", name: "MCKESSON CORP" },
  { ticker: "MCMJ", name: "MERIDA MERGER CORP I" },
  { ticker: "MCN", name: "MADISON COVERED CALL & EQUIT" },
  { ticker: "MCO", name: "MOODY'S CORP" },
  { ticker: "MCR", name: "MFS CHARTER INCOME TRUST" },
  { ticker: "MCRB", name: "SERES THERAPEUTICS INC" },
  { ticker: "MCRI", name: "MONARCH CASINO & RESORT INC" },
  { ticker: "MCRO", name: "IQ HEDGE MACRO TRACKER ETF" },
  { ticker: "MCS", name: "MARCUS CORPORATION" },
  { ticker: "MCY", name: "MERCURY GENERAL CORP" },
  { ticker: "MD", name: "MEDNAX INC" },
  { ticker: "MDB", name: "MONGODB INC" },
  { ticker: "MDC", name: "MDC HOLDINGS INC" },
  { ticker: "MDCA", name: "MDC PARTNERS INC-A" },
  { ticker: "MDGL", name: "MADRIGAL PHARMACEUTICALS INC" },
  { ticker: "MDGS", name: "MEDIGUS LTD - SPON ADR" },
  { ticker: "MDIA", name: "MEDIACO HOLDING INC-CL A-WI" },
  { ticker: "MDIV", name: "FT MULTI-ASSET DIVERS INC" },
  { ticker: "MDJH", name: "MDJM LTD" },
  { ticker: "MDLA", name: "MEDALLIA INC" },
  { ticker: "MDLY", name: "MEDLEY MANAGEMENT INC - A" },
  { ticker: "MDLZ", name: "MONDELEZ INTERNATIONAL INC-A" },
  { ticker: "MDP", name: "MEREDITH CORP" },
  { ticker: "MDRR", name: "MEDALIST DIVERSIFIED REIT IN" },
  { ticker: "MDRX", name: "ALLSCRIPTS HEALTHCARE SOLUTI" },
  { ticker: "MDT", name: "MEDTRONIC PLC" },
  { ticker: "MDU", name: "MDU RESOURCES GROUP INC" },
  { ticker: "MDWD", name: "MEDIWOUND LTD" },
  { ticker: "MDY", name: "SPDR S&P MIDCAP 400 ETF TRST" },
  { ticker: "MDYG", name: "SPDR S&P 400 MID CAPGROWTH E" },
  { ticker: "MDYV", name: "SPDR S&P 400 MID CAP VALUE E" },
  { ticker: "MEAR", name: "ISHARES SHRT MATURITY MUNI" },
  { ticker: "MEC", name: "MAYVILLE ENGINEERING CO INC" },
  { ticker: "MED", name: "MEDIFAST INC" },
  { ticker: "MEDP", name: "MEDPACE HOLDINGS INC" },
  { ticker: "MEDS", name: "TRXADE GROUP INC" },
  { ticker: "MEET", name: "MEET GROUP INC/THE" },
  { ticker: "MEI", name: "METHODE ELECTRONICS INC" },
  { ticker: "MEIP", name: "MEI PHARMA INC" },
  { ticker: "MELI", name: "MERCADOLIBRE INC" },
  { ticker: "MEN", name: "BLACKROCK MUNIENHANCED FUND" },
  { ticker: "MEOH", name: "METHANEX CORP" },
  { ticker: "MERC", name: "MERCER INTERNATIONAL INC" },
  { ticker: "MESA", name: "MESA AIR GROUP INC" },
  { ticker: "MESO", name: "MESOBLAST LTD- SPON ADR" },
  { ticker: "MET", name: "METLIFE INC" },
  { ticker: "METC", name: "RAMACO RESOURCES INC" },
  { ticker: "MEXX", name: "DIREXION DLY MEXICO BULL 3X" },
  { ticker: "MFA", name: "MFA FINANCIAL INC" },
  { ticker: "MFAC", name: "MEGALITH FINANCIAL ACQUISI-A" },
  { ticker: "MFAC=", name: "MEGALITH FINANCIAL ACQUISITI" },
  { ticker: "MFC", name: "MANULIFE FINANCIAL CORP" },
  { ticker: "MFD", name: "MACQUARIE/FT GL INT/UT DV IN" },
  { ticker: "MFDX", name: "PIMCO RAFI DYNAMIC MULTI-FAC" },
  { ticker: "MFEM", name: "PIMCO RAFI DYNAMIC MULTI-FAC" },
  { ticker: "MFG", name: "MIZUHO FINANCIAL GROUP-ADR" },
  { ticker: "MFGP", name: "MICRO FOCUS INTL-SPN ADR" },
  { ticker: "MFH", name: "JMU LTD-SPON ADR" },
  { ticker: "MFIN", name: "MEDALLION FINANCIAL CORP" },
  { ticker: "MFL", name: "BLACKROCK MUNIHOLDINGS QUALI" },
  { ticker: "MFM", name: "MFS MUNICIPAL INCOME TRUST" },
  { ticker: "MFMS", name: "MFAM SMALL-CAP GROWTH ETF" },
  { ticker: "MFNC", name: "MACKINAC FINANCIAL CORP" },
  { ticker: "MFSF", name: "MUTUALFIRST FINANCIAL INC" },
  { ticker: "MFT", name: "BLACKROCK MUNIYIELD QUALITY" },
  { ticker: "MFUS", name: "PIMCO RAFI DYNAMIC MULTI-FAC" },
  { ticker: "MFV", name: "MFS SPECIAL VALUE TRUST" },
  { ticker: "MG", name: "MISTRAS GROUP INC" },
  { ticker: "MGA", name: "MAGNA INTERNATIONAL INC" },
  { ticker: "MGC", name: "VANGUARD MEGA CAP ETF" },
  { ticker: "MGEE", name: "MGE ENERGY INC" },
  { ticker: "MGEN", name: "MIRAGEN THERAPEUTICS INC" },
  { ticker: "MGF", name: "MFS GOVT MARKETS INC TRUST" },
  { ticker: "MGI", name: "MONEYGRAM INTERNATIONAL INC" },
  { ticker: "MGIC", name: "MAGIC SOFTWARE ENTERPRISES" },
  { ticker: "MGK", name: "VANGUARD MEGA CAP GROWTH ETF" },
  { ticker: "MGLN", name: "MAGELLAN HEALTH INC" },
  { ticker: "MGM", name: "MGM RESORTS INTERNATIONAL" },
  { ticker: "MGNX", name: "MACROGENICS INC" },
  { ticker: "MGP", name: "MGM GROWTH PROPERTIES LLC-A" },
  { ticker: "MGPI", name: "MGP INGREDIENTS INC" },
  { ticker: "MGRC", name: "MCGRATH RENTCORP" },
  { ticker: "MGTA", name: "MAGENTA THERAPEUTICS INC" },
  { ticker: "MGTX", name: "MEIRAGTX HOLDINGS PLC" },
  { ticker: "MGU", name: "MACQUARIE GLOBAL INFR TOT RT" },
  { ticker: "MGV", name: "VANGUARD MEGA CAP VALUE ETF" },
  { ticker: "MGY", name: "MAGNOLIA OIL & GAS CORP - A" },
  { ticker: "MGYR", name: "MAGYAR BANCORP INC" },
  { ticker: "MHD", name: "BLACKROCK MUNIHOLDINGS FUND" },
  { ticker: "MHE", name: "BLACKROCK MASS TAX EMEMPT" },
  { ticker: "MHF", name: "WESTERN ASSET MUNICIPAL HIGH" },
  { ticker: "MHH", name: "MASTECH DIGITAL INC" },
  { ticker: "MHI", name: "PIONEER MUNI HI INCOME TRUST" },
  { ticker: "MHK", name: "MOHAWK INDUSTRIES INC" },
  { ticker: "MHLD", name: "MAIDEN HOLDINGS LTD" },
  { ticker: "MHN", name: "BLACKROCK MUNIHOLDINGS NY QU" },
  { ticker: "MHO", name: "M/I HOMES INC" },
  { ticker: "MIC", name: "MACQUARIE INFRASTRUCTURE COR" },
  { ticker: "MICR", name: "MICRON SOLUTIONS INC" },
  { ticker: "MICT", name: "MICT INC" },
  { ticker: "MIDD", name: "MIDDLEBY CORP" },
  { ticker: "MIDF", name: "ISHARES EDGE MSCI M/F US M/C" },
  { ticker: "MIDU", name: "DIREXION DLY MID CAP BULL 3X" },
  { ticker: "MIDZ", name: "DIREXION DLY MID CAP BEAR 3X" },
  { ticker: "MIE", name: "COHEN & STEERS MLP INCOME AN" },
  { ticker: "MIK", name: "MICHAELS COS INC/THE" },
  { ticker: "MILN", name: "GLOBAL X MILLENNIALS THEMATI" },
  { ticker: "MIME", name: "MIMECAST LTD" },
  { ticker: "MIN", name: "MFS INTERMEDIATE INC TRUST" },
  { ticker: "MINC", name: "ADVSHRS NEWFLEET MULTSEC INC" },
  { ticker: "MIND", name: "MITCHAM INDUSTRIES INC" },
  { ticker: "MINI", name: "MOBILE MINI INC" },
  { ticker: "MINT", name: "PIMCO ENHANCED SHORT MATURIT" },
  { ticker: "MIRM", name: "MIRUM PHARMACEUTICALS INC" },
  { ticker: "MIST", name: "MILESTONE PHARMACEUTICALS IN" },
  { ticker: "MITK", name: "MITEK SYSTEMS INC" },
  { ticker: "MITO", name: "STEALTH BIOTHERAPEUTICS CORP" },
  { ticker: "MITT", name: "AG MORTGAGE INVESTMENT TRUST" },
  { ticker: "MIXT", name: "MIX TELEMATICS LTD-SP ADR" },
  { ticker: "MIY", name: "BLACKROCK MUNIYIELD MI QUALI" },
  { ticker: "MJ", name: "ETFMG ALTERNATIVE HARVEST" },
  { ticker: "MJCO", name: "MAJESCO" },
  { ticker: "MJJ", name: "MICROSECTORS CANNABIS ETN" },
  { ticker: "MJO", name: "MICROSECTORS CANNABIS 2X LEV" },
  { ticker: "MKC", name: "MCCORMICK & CO-NON VTG SHRS" },
  { ticker: "MKC.V", name: "MCCORMICK & CO INC VTG COM" },
  { ticker: "MKD", name: "MOLECULAR DATA INC - ADR" },
  { ticker: "MKGI", name: "MONAKER GROUP INC" },
  { ticker: "MKL", name: "MARKEL CORP" },
  { ticker: "MKSI", name: "MKS INSTRUMENTS INC" },
  { ticker: "MKTX", name: "MARKETAXESS HOLDINGS INC" },
  { ticker: "MLAB", name: "MESA LABORATORIES INC" },
  { ticker: "MLCO", name: "MELCO RESORTS & ENTERT-ADR" },
  { ticker: "MLHR", name: "HERMAN MILLER INC" },
  { ticker: "MLI", name: "MUELLER INDUSTRIES INC" },
  { ticker: "MLM", name: "MARTIN MARIETTA MATERIALS" },
  { ticker: "MLN", name: "VANECK AMT-FREE LONG MUNI" },
  { ticker: "MLND", name: "MILLENDO THERAPEUTICS INC" },
  { ticker: "MLNX", name: "MELLANOX TECHNOLOGIES LTD" },
  { ticker: "MLP", name: "MAUI LAND & PINEAPPLE CO" },
  { ticker: "MLPA", name: "GLOBAL X MLP ETF" },
  { ticker: "MLPB", name: "ETRACS ALERIAN INFRAST SER B" },
  { ticker: "MLPC", name: "C-TRACKS ETNS BASED ON PERFO" },
  { ticker: "MLPE", name: "C-TRACKS ETNS ON THE MILLER" },
  { ticker: "MLPG", name: "ETRACS ALERIAN NAT GAS MLP" },
  { ticker: "MLPI", name: "ETRACS ALERIAN INFRASTRUCTUR" },
  { ticker: "MLPO", name: "CS S&P MLP INDEX ETN" },
  { ticker: "MLPQ", name: "ETRACS 2X LEV LG ALER MLP-B" },
  { ticker: "MLPX", name: "GLOBAL X MLP & ENERGY INFRAS" },
  { ticker: "MLPY", name: "MS CUSH MLP HI INC INDX ETN" },
  { ticker: "MLPZ", name: "ETRACS 2XMTHLY LEV S&P MLP B" },
  { ticker: "MLR", name: "MILLER INDUSTRIES INC/TENN" },
  { ticker: "MLSS", name: "MILESTONE SCIENTIFIC INC" },
  { ticker: "MLTI", name: "X-LINKS MULTI-ASSET HIGH INC" },
  { ticker: "MLVF", name: "MALVERN BANCORP INC" },
  { ticker: "MMAC", name: "MMA CAPITAL HOLDINGS INC" },
  { ticker: "MMC", name: "MARSH & MCLENNAN COS" },
  { ticker: "MMD", name: "MAINSTAY MACKAY DEFINEDTERM" },
  { ticker: "MMI", name: "MARCUS & MILLICHAP INC" },
  { ticker: "MMIN", name: "IQ MACKAY MUNICIPAL INSURED" },
  { ticker: "MMIT", name: "IQ MACKAY MUNICIPAL INTERMED" },
  { ticker: "MMLP", name: "MARTIN MIDSTREAM PARTNERS LP" },
  { ticker: "MMM", name: "3M CO" },
  { ticker: "MMP", name: "MAGELLAN MIDSTREAM PARTNERS" },
  { ticker: "MMS", name: "MAXIMUS INC" },
  { ticker: "MMSI", name: "MERIT MEDICAL SYSTEMS INC" },
  { ticker: "MMT", name: "MFS MULTIMARKET INC TRUST" },
  { ticker: "MMTM", name: "SPDR S&P1500 MOMENTUM TILT" },
  { ticker: "MMU", name: "WESTERN ASSET MANAGED MUNICI" },
  { ticker: "MMX", name: "MAVERIX METALS INC" },
  { ticker: "MMYT", name: "MAKEMYTRIP LTD" },
  { ticker: "MN", name: "MANNING & NAPIER INC" },
  { ticker: "MNA", name: "IQ MERGER ARBITRAGE ETF" },
  { ticker: "MNCL", name: "MONOCLE ACQUISITION CORP" },
  { ticker: "MNCLU", name: "MONOCLE ACQUISITION CORP" },
  { ticker: "MNDO", name: "MIND CTI LTD" },
  { ticker: "MNE", name: "BLACKROCK MUNI NY INT DUR FD" },
  { ticker: "MNK", name: "MALLINCKRODT PLC" },
  { ticker: "MNKD", name: "MANNKIND CORP" },
  { ticker: "MNLO", name: "MENLO THERAPEUTICS INC" },
  { ticker: "MNOV", name: "MEDICINOVA INC" },
  { ticker: "MNP", name: "WESTERN ASSET MUNICIPAL PART" },
  { ticker: "MNPR", name: "MONOPAR THERAPEUTICS INC" },
  { ticker: "MNR", name: "MONMOUTH REAL ESTATE INV COR" },
  { ticker: "MNRL", name: "BRIGHAM MINERALS INC-CL A" },
  { ticker: "MNRO", name: "MONRO INC" },
  { ticker: "MNSB", name: "MAINSTREET BANCSHARES INC" },
  { ticker: "MNST", name: "MONSTER BEVERAGE CORP" },
  { ticker: "MNTA", name: "MOMENTA PHARMACEUTICALS INC" },
  { ticker: "MNTX", name: "MANITEX INTERNATIONAL INC" },
  { ticker: "MO", name: "ALTRIA GROUP INC" },
  { ticker: "MOAT", name: "VANECK MORNINGSTAR WIDE MOAT" },
  { ticker: "MOBL", name: "MOBILEIRON INC" },
  { ticker: "MOD", name: "MODINE MANUFACTURING CO" },
  { ticker: "MODN", name: "MODEL N INC" },
  { ticker: "MOFG", name: "MIDWESTONE FINANCIAL GROUP I" },
  { ticker: "MOG.A", name: "MOOG INC-CLASS A" },
  { ticker: "MOG.B", name: "MOOG INC-CLASS B" },
  { ticker: "MOGO", name: "MOGO INC" },
  { ticker: "MOGU", name: "MOGU INC-ADR" },
  { ticker: "MOH", name: "MOLINA HEALTHCARE INC" },
  { ticker: "MOHO", name: "ECMOHO LTD" },
  { ticker: "MOM", name: "AGFIQ US MARKET NEUTRAL MOME" },
  { ticker: "MOMO", name: "MOMO INC-SPON ADR" },
  { ticker: "MOO", name: "VANECK AGRIBUSINESS" },
  { ticker: "MOR", name: "MORPHOSYS AG ADR" },
  { ticker: "MORF", name: "MORPHIC HOLDING INC" },
  { ticker: "MORL", name: "ETRACS MONTHLY PAY 2XLEVERAG" },
  { ticker: "MORN", name: "MORNINGSTAR INC" },
  { ticker: "MORT", name: "VANECK MORTGAGE REIT INCOME" },
  { ticker: "MOS", name: "MOSAIC CO/THE" },
  { ticker: "MOSY", name: "MOSYS INC" },
  { ticker: "MOTI", name: "VANECK MORNINGSTAR INTL MOAT" },
  { ticker: "MOTO", name: "SMARTETFS SMART TRANSP/TECH" },
  { ticker: "MOTS", name: "MOTUS GI HOLDINGS INC" },
  { ticker: "MOV", name: "MOVADO GROUP INC" },
  { ticker: "MOXC", name: "MOXIAN INC" },
  { ticker: "MPA", name: "BLACKROCK MUNIYIELD PENNSYLV" },
  { ticker: "MPAA", name: "MOTORCAR PARTS OF AMERICA IN" },
  { ticker: "MPB", name: "MID PENN BANCORP INC" },
  { ticker: "MPC", name: "MARATHON PETROLEUM CORP" },
  { ticker: "MPLX", name: "MPLX LP" },
  { ticker: "MPV", name: "BARINGS PARTICIPATION INVEST" },
  { ticker: "MPW", name: "MEDICAL PROPERTIES TRUST INC" },
  { ticker: "MPWR", name: "MONOLITHIC POWER SYSTEMS INC" },
  { ticker: "MPX", name: "MARINE PRODUCTS CORP" },
  { ticker: "MQT", name: "BLACKROCK MUNIYIELD QUAL II" },
  { ticker: "MQY", name: "BLACKROCK MUNIYIELD QUALITY" },
  { ticker: "MR", name: "MONTAGE RESOURCES CORP" },
  { ticker: "MRAM", name: "EVERSPIN TECHNOLOGIES INC" },
  { ticker: "MRBK", name: "MERIDIAN CORP" },
  { ticker: "MRC", name: "MRC GLOBAL INC" },
  { ticker: "MRCC", name: "MONROE CAPITAL CORP" },
  { ticker: "MRCY", name: "MERCURY SYSTEMS INC" },
  { ticker: "MREO", name: "MEREO BIOPHARMA GROUP PL-ADR" },
  { ticker: "MRGR", name: "PROSHARES MERGER ETF" },
  { ticker: "MRIN", name: "MARIN SOFTWARE INC" },
  { ticker: "MRK", name: "MERCK & CO. INC." },
  { ticker: "MRKR", name: "MARKER THERAPEUTICS INC" },
  { ticker: "MRLN", name: "MARLIN BUSINESS SERVICES INC" },
  { ticker: "MRNA", name: "MODERNA INC" },
  { ticker: "MRNS", name: "MARINUS PHARMACEUTICALS INC" },
  { ticker: "MRO", name: "MARATHON OIL CORP" },
  { ticker: "MRRL", name: "ETRACS MONTHLY PAY 2XL SER B" },
  { ticker: "MRSN", name: "MERSANA THERAPEUTICS INC" },
  { ticker: "MRTN", name: "MARTEN TRANSPORT LTD" },
  { ticker: "MRTX", name: "MIRATI THERAPEUTICS INC" },
  { ticker: "MRUS", name: "MERUS NV" },
  { ticker: "MRVL", name: "MARVELL TECHNOLOGY GROUP LTD" },
  { ticker: "MS", name: "MORGAN STANLEY" },
  { ticker: "MSA", name: "MSA SAFETY INC" },
  { ticker: "MSB", name: "MESABI TRUST" },
  { ticker: "MSBF", name: "MSB FINANCIAL CORP/MD" },
  { ticker: "MSBI", name: "MIDLAND STATES BANCORP INC" },
  { ticker: "MSC", name: "STUDIO CITY INTERNATIONA-ADR" },
  { ticker: "MSCI", name: "MSCI INC" },
  { ticker: "MSD", name: "MORGAN STANLEY EMRG MKT DEBT" },
  { ticker: "MSEX", name: "MIDDLESEX WATER CO" },
  { ticker: "MSFT", name: "MICROSOFT CORP" },
  { ticker: "MSG", name: "MADISON SQUARE GARDEN CO- A" },
  { ticker: "MSGN", name: "MSG NETWORKS INC- A" },
  { ticker: "MSI", name: "MOTOROLA SOLUTIONS INC" },
  { ticker: "MSM", name: "MSC INDUSTRIAL DIRECT CO-A" },
  { ticker: "MSN", name: "EMERSON RADIO CORP" },
  { ticker: "MSON", name: "MISONIX INC" },
  { ticker: "MSTR", name: "MICROSTRATEGY INC-CL A" },
  { ticker: "MSUS", name: "LHA MARKET STATE US TACTICAL" },
  { ticker: "MSVB", name: "MID-SOUTHERN BANCORP INC" },
  { ticker: "MT", name: "ARCELORMITTAL-NY REGISTERED" },
  { ticker: "MTA", name: "METALLA ROYALTY & STREAMING" },
  { ticker: "MTB", name: "M & T BANK CORP" },
  { ticker: "MTBC", name: "MTBC INC" },
  { ticker: "MTC", name: "MMTEC INC" },
  { ticker: "MTCH", name: "MATCH GROUP INC" },
  { ticker: "MTD", name: "METTLER-TOLEDO INTERNATIONAL" },
  { ticker: "MTDR", name: "MATADOR RESOURCES CO" },
  { ticker: "MTEM", name: "MOLECULAR TEMPLATES INC" },
  { ticker: "MTEX", name: "MANNATECH INC" },
  { ticker: "MTG", name: "MGIC INVESTMENT CORP" },
  { ticker: "MTGP", name: "WISDOMTREE MTGE PLUS BOND" },
  { ticker: "MTH", name: "MERITAGE HOMES CORP" },
  { ticker: "MTL", name: "MECHEL PJSC-SPONSORED ADR" },
  { ticker: "MTL-", name: "MECHEL-PREF SPON ADR" },
  { ticker: "MTLS", name: "MATERIALISE NV-ADR" },
  { ticker: "MTN", name: "VAIL RESORTS INC" },
  { ticker: "MTNB", name: "MATINAS BIOPHARMA HOLDINGS I" },
  { ticker: "MTOR", name: "MERITOR INC" },
  { ticker: "MTP", name: "MIDATECH PHARMA PLC-ADR" },
  { ticker: "MTR", name: "MESA ROYALTY TRUST" },
  { ticker: "MTRN", name: "MATERION CORP" },
  { ticker: "MTRX", name: "MATRIX SERVICE CO" },
  { ticker: "MTSC", name: "MTS SYSTEMS CORP" },
  { ticker: "MTSI", name: "MACOM TECHNOLOGY SOLUTIONS H" },
  { ticker: "MTSL", name: "MER TELEMANAGEMENT SOLUTIONS" },
  { ticker: "MTT", name: "WESTERN ASSET MUNCIPAL DEFIN" },
  { ticker: "MTUM", name: "ISHARES EDGE MSCI USA MOMENT" },
  { ticker: "MTW", name: "MANITOWOC COMPANY INC" },
  { ticker: "MTX", name: "MINERALS TECHNOLOGIES INC" },
  { ticker: "MTZ", name: "MASTEC INC" },
  { ticker: "MU", name: "MICRON TECHNOLOGY INC" },
  { ticker: "MUA", name: "BLACKROCK MUNIASSETS FUND" },
  { ticker: "MUB", name: "ISHARES NATIONAL MUNI BOND E" },
  { ticker: "MUC", name: "BLACKROCK MUNIHOLDINGS CA QU" },
  { ticker: "MUDS", name: "MUDRICK CAPITAL ACQUISI - A" },
  { ticker: "MUDSU", name: "MUDRICK CAPITAL ACQUISITION" },
  { ticker: "MUE", name: "BLACKROCK MUNIHOLDINGS QU II" },
  { ticker: "MUFG", name: "MITSUBISHI UFJ FINL-SPON ADR" },
  { ticker: "MUH", name: "BLACKROCK MUNIHOLDINGS FD II" },
  { ticker: "MUI", name: "BLACKROCK MUNI INTERM DURATI" },
  { ticker: "MUJ", name: "BLACKROCK MUNIHOLDINGS NJ QU" },
  { ticker: "MUNI", name: "PIMCO INTERMEDIATE MUNICIPAL" },
  { ticker: "MUR", name: "MURPHY OIL CORP" },
  { ticker: "MUS", name: "BLACKROCK MUNIHOLDINGS QUALI" },
  { ticker: "MUSA", name: "MURPHY USA INC" },
  { ticker: "MUST", name: "COLUMBIA MULTI-SECTOR MUNICI" },
  { ticker: "MUTE", name: "DIREXION DAILY COMM BEAR 3X" },
  { ticker: "MUX", name: "MCEWEN MINING INC" },
  { ticker: "MVBF", name: "MVB FINANCIAL CORP" },
  { ticker: "MVC", name: "MVC CAPITAL INC" },
  { ticker: "MVF", name: "BLACKROCK MUNIVEST FUND" },
  { ticker: "MVIN", name: "NATIXIS SEEYOND INTL MIN VOL" },
  { ticker: "MVIS", name: "MICROVISION INC" },
  { ticker: "MVO", name: "MV OIL TRUST" },
  { ticker: "MVT", name: "BLACKROCK MUNIVEST FUND II" },
  { ticker: "MVV", name: "PROSHARES ULTRA MIDCAP400" },
  { ticker: "MWA", name: "MUELLER WATER PRODUCTS INC-A" },
  { ticker: "MWK", name: "MOHAWK GROUP HOLDINGS INC" },
  { ticker: "MX", name: "MAGNACHIP SEMICONDUCT" },
  { ticker: "MXC", name: "MEXCO ENERGY CORP" },
  { ticker: "MXDE", name: "NATIONWIDE MAXIMUM DIVERS EM" },
  { ticker: "MXDU", name: "NATIONWIDE MAXIMUM DIVERSIFI" },
  { ticker: "MXE", name: "MEXICO EQUITY AND INCOME FD" },
  { ticker: "MXF", name: "MEXICO FUND INC" },
  { ticker: "MXI", name: "ISHARES GLOBAL MATERIALS ETF" },
  { ticker: "MXIM", name: "MAXIM INTEGRATED PRODUCTS" },
  { ticker: "MXL", name: "MAXLINEAR INC" },
  { ticker: "MYC", name: "BLACKROCK MUNIYIELD CALI FD" },
  { ticker: "MYD", name: "BLACKROCK MUNIYIELD FUND" },
  { ticker: "MYE", name: "MYERS INDUSTRIES INC" },
  { ticker: "MYF", name: "BLACKROCK MUNIYIELD INVESTME" },
  { ticker: "MYFW", name: "FIRST WESTERN FINANCIAL INC" },
  { ticker: "MYGN", name: "MYRIAD GENETICS INC" },
  { ticker: "MYI", name: "BLACKROCK MUNIYIELD QUALITY" },
  { ticker: "MYJ", name: "BLACKROCK MUNIYIELD NJ FUND" },
  { ticker: "MYL", name: "MYLAN NV" },
  { ticker: "MYN", name: "BLACKROCK MUNIYIELD NY QUALI" },
  { ticker: "MYO", name: "MYOMO INC" },
  { ticker: "MYOK", name: "MYOKARDIA INC" },
  { ticker: "MYOS", name: "MYOS RENS TECHNOLOGY INC" },
  { ticker: "MYOV", name: "MYOVANT SCIENCES LTD" },
  { ticker: "MYRG", name: "MYR GROUP INC/DELAWARE" },
  { ticker: "MYSZ", name: "MY SIZE INC" },
  { ticker: "MYT", name: "URBAN TEA INC" },
  { ticker: "MYY", name: "PROSHARES SHORT MIDCAP 400" },
  { ticker: "MZA", name: "BLACKROCK MUNIYIELD ARIZONA" },
  { ticker: "MZZ", name: "PROSHARES ULTSHRT MIDCAP400" },
  { ticker: "NAC", name: "NUVEEN CA QUAL MUNI INC FD" },
  { ticker: "NACP", name: "IMPACT SHARES NAACP MINORITY" },
  { ticker: "NAD", name: "NUVEEN QUAL MUNI INCOME FD" },
  { ticker: "NAII", name: "NATURAL ALTERNATIVES INTL" },
  { ticker: "NAIL", name: "DRX DLY HOMEBUILDERS BULL 3X" },
  { ticker: "NAK", name: "NORTHERN DYNASTY MINERALS" },
  { ticker: "NAKD", name: "NAKED BRAND GROUP LTD" },
  { ticker: "NAN", name: "NUVEEN NY QUAL MUNI INC FD" },
  { ticker: "NANR", name: "SPDR S&P NORTH AMERICAN NATU" },
  { ticker: "NAOV", name: "NANOVIBRONIX INC" },
  { ticker: "NAT", name: "NORDIC AMERICAN TANKERS LTD" },
  { ticker: "NATH", name: "NATHAN'S FAMOUS INC" },
  { ticker: "NATI", name: "NATIONAL INSTRUMENTS CORP" },
  { ticker: "NATR", name: "NATURES SUNSHINE PRODS INC" },
  { ticker: "NAV", name: "NAVISTAR INTERNATIONAL CORP" },
  { ticker: "NAVB", name: "NAVIDEA BIOPHARMACEUTICALS I" },
  { ticker: "NAVI", name: "NAVIENT CORP" },
  { ticker: "NAZ", name: "NUVEEN AZ QUAL MUNI INC FD" },
  { ticker: "NBACU", name: "NEWBORN ACQUISITION CORP" },
  { ticker: "NBB", name: "NUVEEN TAX MUNI INC" },
  { ticker: "NBEV", name: "NEW AGE BEVERAGES CORP" },
  { ticker: "NBH", name: "NEUBERGER BERMAN MUNICIPAL" },
  { ticker: "NBHC", name: "NATIONAL BANK HOLD-CL A" },
  { ticker: "NBIX", name: "NEUROCRINE BIOSCIENCES INC" },
  { ticker: "NBL", name: "NOBLE ENERGY INC" },
  { ticker: "NBLX", name: "NOBLE MIDSTREAM PARTNERS LP" },
  { ticker: "NBN", name: "NORTHEAST BANK" },
  { ticker: "NBO", name: "NEUBERGER BERMAN NY MUNI" },
  { ticker: "NBR", name: "NABORS INDUSTRIES LTD" },
  { ticker: "NBRV", name: "NABRIVA THERAPEUTICS PLC" },
  { ticker: "NBSE", name: "NEUBASE THERAPEUTICS INC" },
  { ticker: "NBTB", name: "N B T BANCORP INC" },
  { ticker: "NBW", name: "NEUBERGER BERMAN CA MUNI" },
  { ticker: "NBY", name: "NOVABAY PHARMACEUTICALS INC" },
  { ticker: "NC", name: "NACCO INDUSTRIES-CL A" },
  { ticker: "NCA", name: "NUVEEN CA MUNI VALUE FD" },
  { ticker: "NCB", name: "NUVEEN CA MUNI VALUE FD 2" },
  { ticker: "NCBS", name: "NICOLET BANKSHARES INC" },
  { ticker: "NCLH", name: "NORWEGIAN CRUISE LINE HOLDIN" },
  { ticker: "NCMI", name: "NATIONAL CINEMEDIA INC" },
  { ticker: "NCNA", name: "NUCANA PLC-ADR" },
  { ticker: "NCR", name: "NCR CORPORATION" },
  { ticker: "NCSM", name: "NCS MULTISTAGE HOLDINGS INC" },
  { ticker: "NCTY", name: "THE9 LTD-ADR" },
  { ticker: "NCV", name: "ALLIANZGI CONVERTIBLE & INCO" },
  { ticker: "NCZ", name: "ALLIANZGI CONV & INCOME II" },
  { ticker: "NDAQ", name: "NASDAQ INC" },
  { ticker: "NDLS", name: "NOODLES & CO" },
  { ticker: "NDP", name: "TORTOISE ENERGY INDEPENDENCE" },
  { ticker: "NDRA", name: "ENDRA LIFE SCIENCES INC" },
  { ticker: "NDSN", name: "NORDSON CORP" },
  { ticker: "NE", name: "NOBLE CORP PLC" },
  { ticker: "NEA", name: "NUVEEN AMT-FR QU MUNI INC FD" },
  { ticker: "NEAR", name: "ISHARES SHORT MATURITY BOND" },
  { ticker: "NEBU", name: "NEBULA ACQUISITION CORP-CL A" },
  { ticker: "NEBUU", name: "NEBULA ACQUISITION CORP" },
  { ticker: "NEE", name: "NEXTERA ENERGY INC" },
  { ticker: "NEED", name: "DRX DLY CONS STAPLES BULL 3X" },
  { ticker: "NEM", name: "NEWMONT CORP" },
  { ticker: "NEN", name: "NEW ENGLAND REALTY ASSOC-LP" },
  { ticker: "NEO", name: "NEOGENOMICS INC" },
  { ticker: "NEOG", name: "NEOGEN CORP" },
  { ticker: "NEON", name: "NEONODE INC" },
  { ticker: "NEOS", name: "NEOS THERAPEUTICS INC" },
  { ticker: "NEP", name: "NEXTERA ENERGY PARTNERS LP" },
  { ticker: "NEPH", name: "NEPHROS INC" },
  { ticker: "NEPT", name: "NEPTUNE WELLNESS SOLUTIONS I" },
  { ticker: "NERD", name: "ROUNDHILL BITKRAFT ESPORTS" },
  { ticker: "NERV", name: "MINERVA NEUROSCIENCES INC" },
  { ticker: "NES", name: "NUVERRA ENVIRONMENTAL SOLUTI" },
  { ticker: "NESR", name: "NATIONAL ENERGY SERVICES REU" },
  { ticker: "NET", name: "CLOUDFLARE INC - CLASS A" },
  { ticker: "NETE", name: "NET ELEMENT INC" },
  { ticker: "NETL", name: "NETLEASE CORPORATE REAL ESTA" },
  { ticker: "NEU", name: "NEWMARKET CORP" },
  { ticker: "NEV", name: "NUVEEN ENH MUNI VALUE FD" },
  { ticker: "NEW", name: "PUXIN LTD-ADR" },
  { ticker: "NEWA", name: "NEWATER TECHNOLOGY INC" },
  { ticker: "NEWR", name: "NEW RELIC INC" },
  { ticker: "NEWT", name: "NEWTEK BUSINESS SERVICES COR" },
  { ticker: "NEX", name: "NEXTIER OILFIELD SOLUTIONS I" },
  { ticker: "NEXA", name: "NEXA RESOURCES SA" },
  { ticker: "NEXT", name: "NEXTDECADE CORP" },
  { ticker: "NFBK", name: "NORTHFIELD BANCORP INC" },
  { ticker: "NFE", name: "NEW FORTRESS ENERGY LLC" },
  { ticker: "NFG", name: "NATIONAL FUEL GAS CO" },
  { ticker: "NFH", name: "NEW FRONTIER HEALTH CORP" },
  { ticker: "NFIN", name: "NETFIN ACQUISITION CORP-CL A" },
  { ticker: "NFINU", name: "NETFIN AQUISITION CORP" },
  { ticker: "NFJ", name: "ALLIANZGI NFJ DIVIDEND INTER" },
  { ticker: "NFLT", name: "VIRTUS NEWFLEET MULTI-SECTOR" },
  { ticker: "NFLX", name: "NETFLIX INC" },
  { ticker: "NFRA", name: "FLEXSHARES STOXX GLOBAL BROA" },
  { ticker: "NFTY", name: "FIRST TRUST INDIA NIFTY 50 E" },
  { ticker: "NG", name: "NOVAGOLD RESOURCES INC" },
  { ticker: "NGD", name: "NEW GOLD INC" },
  { ticker: "NGE", name: "GLOBAL X MSCI NIGERIA ETF" },
  { ticker: "NGG", name: "NATIONAL GRID PLC-SP ADR" },
  { ticker: "NGHC", name: "NATIONAL GENERAL HLDGS" },
  { ticker: "NGL", name: "NGL ENERGY PARTNERS LP" },
  { ticker: "NGM", name: "NGM BIOPHARMACEUTICALS INC" },
  { ticker: "NGS", name: "NATURAL GAS SERVICES GROUP" },
  { ticker: "NGVC", name: "NATURAL GROCERS BY VITAMIN C" },
  { ticker: "NGVT", name: "INGEVITY CORP" },
  { ticker: "NH", name: "NANTHEALTH INC" },
  { ticker: "NHA", name: "NUVEEN MUNI 2021 TGT TRM FD" },
  { ticker: "NHC", name: "NATIONAL HEALTHCARE CORP" },
  { ticker: "NHF", name: "NEXPOINT STRATEGIC OPPORTUNI" },
  { ticker: "NHI", name: "NATL HEALTH INVESTORS INC" },
  { ticker: "NHLD", name: "NATIONAL HOLDINGS CORP" },
  { ticker: "NHS", name: "NEUBERGER BERMAN HIGH YIELD" },
  { ticker: "NHTC", name: "NATURAL HEALTH TRENDS CORP" },
  { ticker: "NI", name: "NISOURCE INC" },
  { ticker: "NIB", name: "IPATH BLOOMBERG COCOA SUBIND" },
  { ticker: "NICE", name: "NICE LTD - SPON ADR" },
  { ticker: "NICK", name: "NICHOLAS FINANCIAL INC" },
  { ticker: "NID", name: "NUVEEN INT DUR MUNI TERM FD" },
  { ticker: "NIE", name: "ALLIANZGI EQUITY & CONVERTIB" },
  { ticker: "NIM", name: "NUVEEN SEL MAT MUNI FD" },
  { ticker: "NINE", name: "NINE ENERGY SERVICE INC" },
  { ticker: "NIO", name: "NIO INC - ADR" },
  { ticker: "NIQ", name: "NUVEEN INT DUR QU MUN TRM FD" },
  { ticker: "NIU", name: "NIU TECHNOLOGIES-SPONS ADR" },
  { ticker: "NJAN", name: "INNOVATOR NASDAQ 100 PB ETF" },
  { ticker: "NJR", name: "NEW JERSEY RESOURCES CORP" },
  { ticker: "NJV", name: "NUVEEN NJ MUNI VALUE FD" },
  { ticker: "NK", name: "NANTKWEST INC" },
  { ticker: "NKE", name: "NIKE INC -CL B" },
  { ticker: "NKG", name: "NUVEEN GA QUAL MUNI INC FD" },
  { ticker: "NKSH", name: "NATIONAL BANKSHARES INC/VA" },
  { ticker: "NKTR", name: "NEKTAR THERAPEUTICS" },
  { ticker: "NKX", name: "NUVEEN CA AMT-FR MUNI INC FD" },
  { ticker: "NL", name: "NL INDUSTRIES" },
  { ticker: "NLNK", name: "NEWLINK GENETICS CORP" },
  { ticker: "NLOK", name: "NORTONLIFELOCK INC" },
  { ticker: "NLR", name: "VANECK URANIUM+NUCLR ENERGY" },
  { ticker: "NLS", name: "NAUTILUS INC" },
  { ticker: "NLSN", name: "NIELSEN HOLDINGS PLC" },
  { ticker: "NLTX", name: "NEOLEUKIN THERAPEUTICS INC" },
  { ticker: "NLY", name: "ANNALY CAPITAL MANAGEMENT IN" },
  { ticker: "NM", name: "NAVIOS MARITIME HOLDINGS INC" },
  { ticker: "NMCI", name: "NAVIOS MARITIME CONTAINERS L" },
  { ticker: "NMCO", name: "NUVEEN MUNICIPAL CREDIT OPP" },
  { ticker: "NMFC", name: "NEW MOUNTAIN FINANCE CORP" },
  { ticker: "NMI", name: "NUVEEN MUNI INC FD" },
  { ticker: "NMIH", name: "NMI HOLDINGS INC-CLASS A" },
  { ticker: "NML", name: "NB MLP & ENERGY INCOME" },
  { ticker: "NMM", name: "NAVIOS MARITIME PARTNERS LP" },
  { ticker: "NMR", name: "NOMURA HOLDINGS INC-SPON ADR" },
  { ticker: "NMRD", name: "NEMAURA MEDICAL INC" },
  { ticker: "NMRK", name: "NEWMARK GROUP INC-CLASS A" },
  { ticker: "NMS", name: "NUVEEN MN QUAL MUNI INC FD" },
  { ticker: "NMT", name: "NUVEEN MA QUAL MUNI INC FD" },
  { ticker: "NMY", name: "NUVEEN MD QUAL MUNI INC FD" },
  { ticker: "NMZ", name: "NUVEEN MUNI HIGH INC OPP FD" },
  { ticker: "NNA", name: "NAVIOS MARITIME ACQUISITION" },
  { ticker: "NNBR", name: "NN INC" },
  { ticker: "NNDM", name: "NANO DIMENSION LTD - ADR" },
  { ticker: "NNI", name: "NELNET INC-CL A" },
  { ticker: "NNN", name: "NATIONAL RETAIL PROPERTIES" },
  { ticker: "NNVC", name: "NANOVIRICIDES INC" },
  { ticker: "NNY", name: "NUVEEN NY MUNI VALUE FD" },
  { ticker: "NOA", name: "NORTH AMERICAN CONSTRUCTION" },
  { ticker: "NOAH", name: "NOAH HOLDINGS LTD-SPON ADS" },
  { ticker: "NOBL", name: "PROSHARES S&P 500 DIVIDEND A" },
  { ticker: "NOC", name: "NORTHROP GRUMMAN CORP" },
  { ticker: "NOCT", name: "INNOVATOR NASDAQ 100 POWER" },
  { ticker: "NODK", name: "NI HOLDINGS INC" },
  { ticker: "NOG", name: "NORTHERN OIL AND GAS INC" },
  { ticker: "NOK", name: "NOKIA CORP-SPON ADR" },
  { ticker: "NOM", name: "NUVEEN MO QUAL MUNI INC FD" },
  { ticker: "NOMD", name: "NOMAD FOODS LTD" },
  { ticker: "NORW", name: "GLOBAL X MSCI NORWAY ETF" },
  { ticker: "NOV", name: "NATIONAL OILWELL VARCO INC" },
  { ticker: "NOVA", name: "SUNNOVA ENERGY INTERNATIONAL" },
  { ticker: "NOVN", name: "NOVAN INC" },
  { ticker: "NOVT", name: "NOVANTA INC" },
  { ticker: "NOW", name: "SERVICENOW INC" },
  { ticker: "NP", name: "NEENAH INC" },
  { ticker: "NPA", name: "NEW PROVIDENCE ACQUISIT-CL A" },
  { ticker: "NPAUU", name: "NEW PROVIDENCE ACQUISITION C" },
  { ticker: "NPK", name: "NATIONAL PRESTO INDS INC" },
  { ticker: "NPN", name: "NUVEEN PA MUNI VALUE FD" },
  { ticker: "NPO", name: "ENPRO INDUSTRIES INC" },
  { ticker: "NPTN", name: "NEOPHOTONICS CORP" },
  { ticker: "NPV", name: "NUVEEN VA QUAL MUNI INC FD" },
  { ticker: "NQP", name: "NUVEEN PA QUAL MUNI INC FD" },
  { ticker: "NR", name: "NEWPARK RESOURCES INC" },
  { ticker: "NRBO", name: "NEUROBO PHARMACEUTICALS INC" },
  { ticker: "NRC", name: "NATIONAL RESEARCH CORP" },
  { ticker: "NREF", name: "NEXPOINT REAL ESTATE FINANCE" },
  { ticker: "NRG", name: "NRG ENERGY INC" },
  { ticker: "NRGD", name: "MICROSECTORS US BIG OIL -3X" },
  { ticker: "NRGO", name: "MICROSECTORS US BIG OIL 2X L" },
  { ticker: "NRGU", name: "MICROSECTORS US BIG OIL 3X" },
  { ticker: "NRGX", name: "PIMCO ENRGY & TACT CRDT" },
  { ticker: "NRGZ", name: "MICROSECTORS US BIG OIL -2X" },
  { ticker: "NRIM", name: "NORTHRIM BANCORP INC" },
  { ticker: "NRK", name: "NUVEEN NY AMT-FR MUNI INC FD" },
  { ticker: "NRO", name: "NEUBERGER BERMAN REAL ESTATE" },
  { ticker: "NRP", name: "NATURAL RESOURCE PARTNERS LP" },
  { ticker: "NRT", name: "NORTH EUROPEAN OIL RTY TRUST" },
  { ticker: "NRZ", name: "NEW RESIDENTIAL INVESTMENT" },
  { ticker: "NS", name: "NUSTAR ENERGY LP" },
  { ticker: "NSA", name: "NATIONAL STORAGE AFFILIATES" },
  { ticker: "NSC", name: "NORFOLK SOUTHERN CORP" },
  { ticker: "NSCO", name: "NESCO HOLDINGS INC" },
  { ticker: "NSEC", name: "NATIONAL SECURITY GROUP INC" },
  { ticker: "NSIT", name: "INSIGHT ENTERPRISES INC" },
  { ticker: "NSL", name: "NUVEEN SENIOR INC FD" },
  { ticker: "NSP", name: "INSPERITY INC" },
  { ticker: "NSPR", name: "INSPIREMD INC" },
  { ticker: "NSSC", name: "NAPCO SECURITY TECHNOLOGIES" },
  { ticker: "NSTG", name: "NANOSTRING TECHNOLOGIES INC" },
  { ticker: "NSYS", name: "NORTECH SYSTEMS INC" },
  { ticker: "NTAP", name: "NETAPP INC" },
  { ticker: "NTB", name: "BANK OF N.T. BUTTERFIELD&SON" },
  { ticker: "NTCO", name: "NATURA &CO HOLDING-ADR" },
  { ticker: "NTCT", name: "NETSCOUT SYSTEMS INC" },
  { ticker: "NTEC", name: "INTEC PHARMA LTD" },
  { ticker: "NTES", name: "NETEASE INC-ADR" },
  { ticker: "NTG", name: "TORTOISE MIDSTREAM ENERGY" },
  { ticker: "NTGN", name: "NEON THERAPEUTICS INC" },
  { ticker: "NTGR", name: "NETGEAR INC" },
  { ticker: "NTIC", name: "NORTHERN TECHNOLOGIES INTL" },
  { ticker: "NTIP", name: "NETWORK-1 TECHNOLOGIES INC" },
  { ticker: "NTLA", name: "INTELLIA THERAPEUTICS INC" },
  { ticker: "NTN", name: "NTN BUZZTIME INC" },
  { ticker: "NTNX", name: "NUTANIX INC - A" },
  { ticker: "NTP", name: "NAM TAI PROPERTY INC" },
  { ticker: "NTR", name: "NUTRIEN LTD" },
  { ticker: "NTRA", name: "NATERA INC" },
  { ticker: "NTRP", name: "NEUROTROPE INC" },
  { ticker: "NTRS", name: "NORTHERN TRUST CORP" },
  { ticker: "NTSX", name: "WISDOMTREE 90/60 US BAL FUND" },
  { ticker: "NTUS", name: "NATUS MEDICAL INC" },
  { ticker: "NTWK", name: "NETSOL TECHNOLOGIES INC" },
  { ticker: "NTZ", name: "NATUZZI SPA-SP ADR" },
  { ticker: "NUAG", name: "NUVEEN ENHANCED YIELD U.S. A" },
  { ticker: "NUAN", name: "NUANCE COMMUNICATIONS INC" },
  { ticker: "NUBD", name: "NUVEEN ESG US AGGREGATE BOND" },
  { ticker: "NUDM", name: "NUVEEN ESG INTERNATIONAL DEV" },
  { ticker: "NUE", name: "NUCOR CORP" },
  { ticker: "NUEM", name: "NUVEEN ESG EMERGING MARKETS" },
  { ticker: "NUGT", name: "DRX DLY GOLD MINERS BULL 3X" },
  { ticker: "NUHY", name: "NUVEEN ESG HIGH YIELD CORP B" },
  { ticker: "NULC", name: "NUVEEN ESG LARGE-CAP ETF" },
  { ticker: "NULG", name: "NUVEEN ESG LARGE-CAP GROWTH" },
  { ticker: "NULV", name: "NUVEEN ESG LARGE-CAP VALUE E" },
  { ticker: "NUM", name: "NUVEEN MI QUAL MUNI INC FD" },
  { ticker: "NUMG", name: "NUVEEN ESG MID-CAP GROWTH ET" },
  { ticker: "NUMV", name: "NUVEEN ESG MID-CAP VALUE ETF" },
  { ticker: "NUO", name: "NUVEEN OH QUAL MUNI INC FD" },
  { ticker: "NURE", name: "NUVEEN SHORTTERM REIT ETF" },
  { ticker: "NURO", name: "NEUROMETRIX INC" },
  { ticker: "NUS", name: "NU SKIN ENTERPRISES INC - A" },
  { ticker: "NUSA", name: "NUVEEN ENHANCED YIELD 1-5 YE" },
  { ticker: "NUSC", name: "NUVEEN ESG SMALL-CAP ETF" },
  { ticker: "NUSI", name: "NATIONWIDE RISK-MANAGED INCO" },
  { ticker: "NUV", name: "NUVEEN MUNI VALUE FD" },
  { ticker: "NUVA", name: "NUVASIVE INC" },
  { ticker: "NUW", name: "NUVEEN AMT-FREE MUNI VAL FD" },
  { ticker: "NVAX", name: "NOVAVAX INC" },
  { ticker: "NVCN", name: "NEOVASC INC" },
  { ticker: "NVCR", name: "NOVOCURE LTD" },
  { ticker: "NVDA", name: "NVIDIA CORP" },
  { ticker: "NVEC", name: "NVE CORP" },
  { ticker: "NVEE", name: "NV5 GLOBAL INC" },
  { ticker: "NVFY", name: "NOVA LIFESTYLE INC" },
  { ticker: "NVG", name: "NUVEEN AMT-FR MUNI CREDIT FD" },
  { ticker: "NVGS", name: "NAVIGATOR HOLDINGS LTD" },
  { ticker: "NVIV", name: "INVIVO THERAPEUTICS HOLDINGS" },
  { ticker: "NVMI", name: "NOVA MEASURING INSTRUMENTS" },
  { ticker: "NVO", name: "NOVO-NORDISK A/S-SPONS ADR" },
  { ticker: "NVR", name: "NVR INC" },
  { ticker: "NVRO", name: "NEVRO CORP" },
  { ticker: "NVS", name: "NOVARTIS AG-SPONSORED ADR" },
  { ticker: "NVST", name: "ENVISTA HOLDINGS CORP" },
  { ticker: "NVT", name: "NVENT ELECTRIC PLC" },
  { ticker: "NVTA", name: "INVITAE CORP" },
  { ticker: "NVUS", name: "NOVUS THERAPEUTICS INC" },
  { ticker: "NWBI", name: "NORTHWEST BANCSHARES INC" },
  { ticker: "NWE", name: "NORTHWESTERN CORP" },
  { ticker: "NWFL", name: "NORWOOD FINANCIAL CORP" },
  { ticker: "NWGI", name: "NEWGIOCO GROUP INC" },
  { ticker: "NWHM", name: "NEW HOME CO INC/THE" },
  { ticker: "NWL", name: "NEWELL BRANDS INC" },
  { ticker: "NWLI", name: "NATIONAL WESTERN LIFE GROU-A" },
  { ticker: "NWN", name: "NORTHWEST NATURAL HOLDING CO" },
  { ticker: "NWPX", name: "NORTHWEST PIPE CO" },
  { ticker: "NWS", name: "NEWS CORP - CLASS B" },
  { ticker: "NWSA", name: "NEWS CORP - CLASS A" },
  { ticker: "NX", name: "QUANEX BUILDING PRODUCTS" },
  { ticker: "NXC", name: "NUVEEN CA SEL TX-FR INC PORT" },
  { ticker: "NXE", name: "NEXGEN ENERGY LTD" },
  { ticker: "NXGN", name: "NEXTGEN HEALTHCARE INC" },
  { ticker: "NXJ", name: "NUVEEN NJ QUAL MUNI INC FD" },
  { ticker: "NXN", name: "NUVEEN NY SEL TX-FR INC PORT" },
  { ticker: "NXP", name: "NUVEEN SEL TX-FR INC PORT" },
  { ticker: "NXPI", name: "NXP SEMICONDUCTORS NV" },
  { ticker: "NXQ", name: "NUVEEN SEL TX-FR INC PORT 2" },
  { ticker: "NXR", name: "NUVEEN SEL TX-FR INC PORT 3" },
  { ticker: "NXRT", name: "NEXPOINT RESIDENTIAL" },
  { ticker: "NXST", name: "NEXSTAR MEDIA GROUP INC-CL A" },
  { ticker: "NXTC", name: "NEXTCURE INC" },
  { ticker: "NXTD", name: "NXT-ID INC" },
  { ticker: "NXTG", name: "FIRST TRUST INDXX NEXTG ETF" },
  { ticker: "NYCB", name: "NEW YORK COMMUNITY BANCORP" },
  { ticker: "NYF", name: "ISHARES NEW YORK MUNI BOND E" },
  { ticker: "NYMT", name: "NEW YORK MORTGAGE TRUST INC" },
  { ticker: "NYMX", name: "NYMOX PHARMACEUTICAL CORP" },
  { ticker: "NYT", name: "NEW YORK TIMES CO-A" },
  { ticker: "NYV", name: "NUVEEN NY MUNI VALUE FD 2" },
  { ticker: "NZF", name: "NUVEEN MUNI CREDIT INC FD" },
  { ticker: "O", name: "REALTY INCOME CORP" },
  { ticker: "OAC", name: "OAKTREE ACQUISITION CORP-A" },
  { ticker: "OAC=", name: "OAKTREE ACQUISITION CORP" },
  { ticker: "OAS", name: "OASIS PETROLEUM INC" },
  { ticker: "OBAS", name: "OPTIBASE LTD" },
  { ticker: "OBCI", name: "OCEAN BIO-CHEM INC" },
  { ticker: "OBE", name: "OBSIDIAN ENERGY LTD" },
  { ticker: "OBLG", name: "OBLONG INC" },
  { ticker: "OBLN", name: "OBALON THERAPEUTICS INC" },
  { ticker: "OBNK", name: "ORIGIN BANCORP INC" },
  { ticker: "OBOR", name: "Kraneshares Msci One Belt ON" },
  { ticker: "OBSV", name: "OBSEVA SA" },
  { ticker: "OC", name: "OWENS CORNING" },
  { ticker: "OCC", name: "OPTICAL CABLE CORP" },
  { ticker: "OCCI", name: "OFS CREDIT CO INC" },
  { ticker: "OCFC", name: "OCEANFIRST FINANCIAL CORP" },
  { ticker: "OCFT", name: "ONECONNECT FINANCIAL TECHNO" },
  { ticker: "OCGN", name: "OCUGEN INC" },
  { ticker: "OCIO", name: "CLEARSHARES OCIO ETF" },
  { ticker: "OCN", name: "OCWEN FINANCIAL CORP" },
  { ticker: "OCSI", name: "OAKTREE STRATEGIC INCOME COR" },
  { ticker: "OCSL", name: "OAKTREE SPECIALTY LENDING CO" },
  { ticker: "OCUL", name: "OCULAR THERAPEUTIX INC" },
  { ticker: "OCX", name: "ONCOCYTE CORP" },
  { ticker: "ODC", name: "OIL-DRI CORP OF AMERICA" },
  { ticker: "ODFL", name: "OLD DOMINION FREIGHT LINE" },
  { ticker: "ODP", name: "OFFICE DEPOT INC" },
  { ticker: "ODT", name: "ODONATE THERAPEUTICS INC" },
  { ticker: "OEC", name: "ORION ENGINEERED CARBONS SA" },
  { ticker: "OEF", name: "ISHARES S&P 100 ETF" },
  { ticker: "OESX", name: "ORION ENERGY SYSTEMS INC" },
  { ticker: "OEUR", name: "O'SHARES FTSE EUROPE QUALITY" },
  { ticker: "OFC", name: "CORPORATE OFFICE PROPERTIES" },
  { ticker: "OFED", name: "OCONEE FEDERAL FINANCIAL COR" },
  { ticker: "OFG", name: "OFG BANCORP" },
  { ticker: "OFIX", name: "ORTHOFIX MEDICAL INC" },
  { ticker: "OFLX", name: "OMEGA FLEX INC" },
  { ticker: "OFS", name: "OFS CAPITAL CORP" },
  { ticker: "OGCP", name: "EMPIRE STATE REALTY OP-S60" },
  { ticker: "OGE", name: "OGE ENERGY CORP" },
  { ticker: "OGEN", name: "ORAGENICS INC" },
  { ticker: "OGI", name: "ORGANIGRAM HOLDINGS INC" },
  { ticker: "OGIG", name: "O'SHARES GLOBAL INTERNET GIA" },
  { ticker: "OGS", name: "ONE GAS INC" },
  { ticker: "OHI", name: "OMEGA HEALTHCARE INVESTORS" },
  { ticker: "OI", name: "O-I GLASS INC" },
  { ticker: "OIA", name: "INVESCO MUNICIPAL INCOME OPP" },
  { ticker: "OIBR.C", name: "OI SA-ADR" },
  { ticker: "OIH", name: "VANECK OIL SERVICES" },
  { ticker: "OII", name: "OCEANEERING INTL INC" },
  { ticker: "OIIM", name: "O2MICRO INTERNATIONAL-ADR" },
  { ticker: "OIL", name: "IPATH SERIES B S&P GSCI CRUD" },
  { ticker: "OILD", name: "PROSHARES ULTRAPRO 3X SHORT" },
  { ticker: "OILK", name: "PROSHARES K-1 FREE CRUDE ETF" },
  { ticker: "OILU", name: "PROSHARES ULTRAPRO 3X CRUDE" },
  { ticker: "OILX", name: "ETRACS S&P GSCI CRUDE OIL TO" },
  { ticker: "OIS", name: "OIL STATES INTERNATIONAL INC" },
  { ticker: "OKE", name: "ONEOK INC" },
  { ticker: "OKTA", name: "OKTA INC" },
  { ticker: "OLD", name: "THE LONG TERM CARE ETF" },
  { ticker: "OLED", name: "UNIVERSAL DISPLAY CORP" },
  { ticker: "OLEM", name: "IPATH PURE BETA CRUDE OIL" },
  { ticker: "OLLI", name: "OLLIE'S BARGAIN OUTLET HOLDI" },
  { ticker: "OLN", name: "OLIN CORP" },
  { ticker: "OLP", name: "ONE LIBERTY PROPERTIES INC" },
  { ticker: "OMAB", name: "GRUPO AEROPORTUARIO CEN-ADR" },
  { ticker: "OMC", name: "OMNICOM GROUP" },
  { ticker: "OMCL", name: "OMNICELL INC" },
  { ticker: "OMER", name: "OMEROS CORP" },
  { ticker: "OMEX", name: "ODYSSEY MARINE EXPLORATION" },
  { ticker: "OMF", name: "ONEMAIN HOLDINGS INC" },
  { ticker: "OMFL", name: "INVESCO RUSSELL 1000 DYN M/F" },
  { ticker: "OMFS", name: "INVESCO RSL 2000 DYN MLTFCTR" },
  { ticker: "OMI", name: "OWENS & MINOR INC" },
  { ticker: "OMN", name: "OMNOVA SOLUTIONS INC" },
  { ticker: "OMP", name: "OASIS MIDSTREAM PARTNERS LP" },
  { ticker: "ON", name: "ON SEMICONDUCTOR CORP" },
  { ticker: "ONB", name: "OLD NATIONAL BANCORP" },
  { ticker: "ONCS", name: "ONCOSEC MEDICAL INC" },
  { ticker: "ONCT", name: "ONCTERNAL THERAPEUTICS INC" },
  { ticker: "ONCY", name: "ONCOLYTICS BIOTECH INC" },
  { ticker: "ONDK", name: "ON DECK CAPITAL INC" },
  { ticker: "ONE", name: "ONESMART INTERNATIONAL -ADR" },
  { ticker: "ONEM", name: "1LIFE HEALTHCARE INC" },
  { ticker: "ONEO", name: "SPDR RUSSELL 1000 MOMEN FOCU" },
  { ticker: "ONEQ", name: "FIDELITY NASDAQ COMP INDX TS" },
  { ticker: "ONEV", name: "SPDR RUSSELL LOW VOL FOCUS" },
  { ticker: "ONEW", name: "ONEWATER MARINE INC-CL A" },
  { ticker: "ONEY", name: "SPDR RUSSELL 1000 YIELD FOCU" },
  { ticker: "ONLN", name: "PROSHARES ONLINE RETAIL ETF" },
  { ticker: "ONTO", name: "ONTO INNOVATION INC" },
  { ticker: "ONTX", name: "ONCONOVA THERAPEUTICS INC" },
  { ticker: "ONVO", name: "ORGANOVO HOLDINGS INC" },
  { ticker: "OOMA", name: "OOMA INC" },
  { ticker: "OPB", name: "OPUS BANK" },
  { ticker: "OPBK", name: "OP BANCORP" },
  { ticker: "OPCH", name: "OPTION CARE HEALTH INC" },
  { ticker: "OPER", name: "CLEARSHARES ULTRA-SHORT MATU" },
  { ticker: "OPES", name: "OPES ACQUISITION CORP" },
  { ticker: "OPESU", name: "OPES ACQUISITION CORP" },
  { ticker: "OPGN", name: "OPGEN INC" },
  { ticker: "OPHC", name: "OPTIMUMBANK HOLDINGS INC" },
  { ticker: "OPI", name: "OFFICE PROPERTIES INCOME TRU" },
  { ticker: "OPK", name: "OPKO HEALTH INC" },
  { ticker: "OPNT", name: "OPIANT PHARMACEUTICALS INC" },
  { ticker: "OPOF", name: "OLD POINT FINANCIAL CORP" },
  { ticker: "OPP", name: "RIVERNRTH/DOUBLELNE STR OPP" },
  { ticker: "OPRA", name: "OPERA LTD-ADR" },
  { ticker: "OPRT", name: "OPORTUN FINANCIAL CORP" },
  { ticker: "OPRX", name: "OPTIMIZERX CORP" },
  { ticker: "OPTN", name: "OPTINOSE INC" },
  { ticker: "OPTT", name: "OCEAN POWER TECHNOLOGIES INC" },
  { ticker: "OPY", name: "OPPENHEIMER HOLDINGS-CL A" },
  { ticker: "OR", name: "OSISKO GOLD ROYALTIES LTD" },
  { ticker: "ORA", name: "ORMAT TECHNOLOGIES INC" },
  { ticker: "ORAN", name: "ORANGE-SPON ADR" },
  { ticker: "ORBC", name: "ORBCOMM INC" },
  { ticker: "ORC", name: "ORCHID ISLAND CAPITAL INC" },
  { ticker: "ORCC", name: "OWL ROCK CAPITAL CORP" },
  { ticker: "ORCL", name: "ORACLE CORP" },
  { ticker: "ORG", name: "THE ORGANICS ETF" },
  { ticker: "ORGO", name: "ORGANOGENESIS HOLDINGS INC" },
  { ticker: "ORGS", name: "ORGENESIS INC" },
  { ticker: "ORI", name: "OLD REPUBLIC INTL CORP" },
  { ticker: "ORLY", name: "O'REILLY AUTOMOTIVE INC" },
  { ticker: "ORMP", name: "ORAMED PHARMACEUTICALS INC" },
  { ticker: "ORN", name: "ORION GROUP HOLDINGS INC" },
  { ticker: "ORRF", name: "ORRSTOWN FINL SERVICES INC" },
  { ticker: "ORSN", name: "ORISUN ACQUISITION CORP" },
  { ticker: "ORSNR", name: "RISUN ACQUISITION CORP-RTS" },
  { ticker: "ORSNU", name: "ORISUN ACQUISITION CORP" },
  { ticker: "ORTX", name: "ORCHARD THERAPEUTICS PLC" },
  { ticker: "OSB", name: "NORBORD INC" },
  { ticker: "OSBC", name: "OLD SECOND BANCORP INC" },
  { ticker: "OSCV", name: "OPUS SMALL CAP VALUE ETF" },
  { ticker: "OSG", name: "OVERSEAS SHIPHOLDING GROUP-A" },
  { ticker: "OSIS", name: "OSI SYSTEMS INC" },
  { ticker: "OSK", name: "OSHKOSH CORP" },
  { ticker: "OSMT", name: "OSMOTICA PHARMACEUTICALS PLC" },
  { ticker: "OSN", name: "OSSEN INNOVATION CO-SPON ADR" },
  { ticker: "OSPN", name: "ONESPAN INC" },
  { ticker: "OSS", name: "ONE STOP SYSTEMS INC" },
  { ticker: "OSTK", name: "OVERSTOCK.COM INC" },
  { ticker: "OSUR", name: "ORASURE TECHNOLOGIES INC" },
  { ticker: "OSW", name: "ONESPAWORLD HOLDINGS LTD" },
  { ticker: "OTEL", name: "OTELCO INC-A" },
  { ticker: "OTEX", name: "OPEN TEXT CORP" },
  { ticker: "OTIC", name: "OTONOMY INC" },
  { ticker: "OTLK", name: "OUTLOOK THERAPEUTICS INC" },
  { ticker: "OTTR", name: "OTTER TAIL CORP" },
  { ticker: "OTTW", name: "OTTAWA BANCORP INC" },
  { ticker: "OUNZ", name: "VANECK MERK GOLD TRUST" },
  { ticker: "OUSA", name: "O'SHARES FTSE US QUALITY D" },
  { ticker: "OUSM", name: "O'SHARES FTSE R SCQD ETF" },
  { ticker: "OUT", name: "OUTFRONT MEDIA INC" },
  { ticker: "OVB", name: "OVERLAY SHARES CORE BOND ETF" },
  { ticker: "OVBC", name: "OHIO VALLEY BANC CORP" },
  { ticker: "OVF", name: "OVERLAY SHARES FOREIGN EQUIT" },
  { ticker: "OVID", name: "OVID THERAPEUTICS INC" },
  { ticker: "OVL", name: "OVERLAY SHARES LARGE CAP EQ" },
  { ticker: "OVLY", name: "OAK VALLEY BANCORP" },
  { ticker: "OVM", name: "OVERLAY SHARES MUNICIPAL BON" },
  { ticker: "OVS", name: "OVERLAY SHARES SMALL CAP EQ" },
  { ticker: "OVV", name: "OVINTIV INC" },
  { ticker: "OXBR", name: "OXBRIDGE RE HOLDINGS LTD" },
  { ticker: "OXFD", name: "OXFORD IMMUNOTEC GLOBAL PLC" },
  { ticker: "OXLC", name: "OXFORD LANE CAPITAL CORP" },
  { ticker: "OXM", name: "OXFORD INDUSTRIES INC" },
  { ticker: "OXSQ", name: "OXFORD SQUARE CAPITAL CORP" },
  { ticker: "OXY", name: "OCCIDENTAL PETROLEUM CORP" },
  { ticker: "OYST", name: "OYSTER POINT PHARMA INC" },
  { ticker: "OZK", name: "BANK OZK" },
  { ticker: "PAA", name: "PLAINS ALL AMER PIPELINE LP" },
  { ticker: "PAAC", name: "PROFICIENT ALPHA ACQUISITION" },
  { ticker: "PAACR", name: "PROFICIENT ALPHA ACQ-RGT" },
  { ticker: "PAACU", name: "PROFICIENT ALPHA ACQUISITION" },
  { ticker: "PAAS", name: "PAN AMERICAN SILVER CORP" },
  { ticker: "PAC", name: "GRUPO AEROPORTUARIO PAC-ADR" },
  { ticker: "PACA", name: "XTRACKERS MSCI LATIN AMERICA" },
  { ticker: "PACB", name: "PACIFIC BIOSCIENCES OF CALIF" },
  { ticker: "PACD", name: "PACIFIC DRILLING SA" },
  { ticker: "PACK", name: "RANPAK HOLDINGS CORP" },
  { ticker: "PACQ", name: "PURE ACQUISITION CORP" },
  { ticker: "PACQU", name: "PURE ACQUISITION CORP" },
  { ticker: "PACW", name: "PACWEST BANCORP" },
  { ticker: "PAE", name: "PAE INC" },
  { ticker: "PAG", name: "PENSKE AUTOMOTIVE GROUP INC" },
  { ticker: "PAGP", name: "PLAINS GP HOLDINGS LP-CL A" },
  { ticker: "PAGS", name: "PAGSEGURO DIGITAL LTD-CL A" },
  { ticker: "PAHC", name: "PHIBRO ANIMAL HEALTH CORP-A" },
  { ticker: "PAI", name: "WESTERN ASSET INVESTMENT GRA" },
  { ticker: "PAK", name: "GLOBAL X MSCI PAKISTAN ETF" },
  { ticker: "PALL", name: "ABERDEEN STANDARD PHYSICAL P" },
  { ticker: "PAM", name: "PAMPA ENERGIA SA-SPON ADR" },
  { ticker: "PANL", name: "PANGAEA LOGISTICS SOLUTIONS" },
  { ticker: "PANW", name: "PALO ALTO NETWORKS INC" },
  { ticker: "PAPR", name: "INNOVATOR S&P 500 POWER BUFF" },
  { ticker: "PAR", name: "PAR TECHNOLOGY CORP/DEL" },
  { ticker: "PARR", name: "PAR PACIFIC HOLDINGS INC" },
  { ticker: "PASG", name: "PASSAGE BIO INC" },
  { ticker: "PASS", name: "DRX DLY CONS DISC BEAR 3X" },
  { ticker: "PATI", name: "PATRIOT TRANSPORTATION HOLDI" },
  { ticker: "PATK", name: "PATRICK INDUSTRIES INC" },
  { ticker: "PAUG", name: "INNOVATOR S&P 500 POWER-AUG" },
  { ticker: "PAVE", name: "GLOBAL X US INFRASTRUCTURE" },
  { ticker: "PAVM", name: "PAVMED INC" },
  { ticker: "PAWZ", name: "PROSHARES PET CARE ETF" },
  { ticker: "PAYC", name: "PAYCOM SOFTWARE INC" },
  { ticker: "PAYS", name: "PAYSIGN INC" },
  { ticker: "PAYX", name: "PAYCHEX INC" },
  { ticker: "PB", name: "PROSPERITY BANCSHARES INC" },
  { ticker: "PBA", name: "PEMBINA PIPELINE CORP" },
  { ticker: "PBBI", name: "PB BANCORP INC" },
  { ticker: "PBCT", name: "PEOPLE'S UNITED FINANCIAL" },
  { ticker: "PBD", name: "INVESCO GLOBAL CLEAN ENERGY" },
  { ticker: "PBDM", name: "INVESCO PUREBETA FTSE DEVELO" },
  { ticker: "PBE", name: "INVESCO DYNAMIC BIOTECHNOLOG" },
  { ticker: "PBEE", name: "INVESCO PUREBETA FTSE EMERGI" },
  { ticker: "PBF", name: "PBF ENERGY INC-CLASS A" },
  { ticker: "PBFS", name: "PIONEER BANCORP INC/NY" },
  { ticker: "PBFX", name: "PBF LOGISTICS LP" },
  { ticker: "PBH", name: "PRESTIGE CONSUMER HEALTHCARE" },
  { ticker: "PBHC", name: "PATHFINDER BANCORP INC" },
  { ticker: "PBI", name: "PITNEY BOWES INC" },
  { ticker: "PBIP", name: "PRUDENTIAL BANCORP INC" },
  { ticker: "PBJ", name: "INVESCO DYNAMIC FOOD & BEVER" },
  { ticker: "PBND", name: "INVESCO PUREBETA US AGGREGAT" },
  { ticker: "PBP", name: "INVESCO S&P 500 BUYWRITE ETF" },
  { ticker: "PBPB", name: "POTBELLY CORP" },
  { ticker: "PBR", name: "PETROLEO BRASILEIRO-SPON ADR" },
  { ticker: "PBR.A", name: "PETROLEO BRASIL-SP PREF  ADR" },
  { ticker: "PBS", name: "INVESCO DYNAMIC MEDIA ETF" },
  { ticker: "PBSM", name: "INVESCO PUREBETA MSCI USA SM" },
  { ticker: "PBT", name: "PERMIAN BASIN ROYALTY TRUST" },
  { ticker: "PBTP", name: "INVESCO PUREBETA 0-5 YR US T" },
  { ticker: "PBTS", name: "POWERBRIDGE TECHNOLOGIES CO" },
  { ticker: "PBUS", name: "INVESCO PUREBETA MSCI USA ET" },
  { ticker: "PBW", name: "INVESCO WILDERHILL CLEAN ENE" },
  { ticker: "PBYI", name: "PUMA BIOTECHNOLOGY INC" },
  { ticker: "PCAR", name: "PACCAR INC" },
  { ticker: "PCB", name: "PCB BANCORP" },
  { ticker: "PCEF", name: "INVESCO CEF INCOME COMPOSITE" },
  { ticker: "PCF", name: "HIGH INCOME SECURITIES FUND" },
  { ticker: "PCG", name: "P G & E CORP" },
  { ticker: "PCH", name: "POTLATCHDELTIC CORP" },
  { ticker: "PCI", name: "PIMCO DYNAMIC CREDIT AND MOR" },
  { ticker: "PCK", name: "PIMCO CALIFORNIA MUNICIPA II" },
  { ticker: "PCM", name: "PCM FUND INC" },
  { ticker: "PCN", name: "PIMCO CORPORATE & INCOME STR" },
  { ticker: "PCOM", name: "POINTS INTERNATIONAL LTD" },
  { ticker: "PCQ", name: "PIMCO CALIFORNIA MUNI INC FD" },
  { ticker: "PCRX", name: "PACIRA BIOSCIENCES INC" },
  { ticker: "PCSB", name: "PCSB FINANCIAL CORP" },
  { ticker: "PCTI", name: "PC-TEL INC" },
  { ticker: "PCTY", name: "PAYLOCITY HOLDING CORP" },
  { ticker: "PCY", name: "INVESCO EMERGING MARKETS SOV" },
  { ticker: "PCYG", name: "PARK CITY GROUP INC" },
  { ticker: "PCYO", name: "PURE CYCLE CORP" },
  { ticker: "PD", name: "PAGERDUTY INC" },
  { ticker: "PDBC", name: "INVESCO OPTIMUM YIELD DIVERS" },
  { ticker: "PDCE", name: "PDC ENERGY INC" },
  { ticker: "PDCO", name: "PATTERSON COS INC" },
  { ticker: "PDD", name: "PINDUODUO INC-ADR" },
  { ticker: "PDEC", name: "INNOVATOR S&P POWER BUFF-DEC" },
  { ticker: "PDEV", name: "PRINCIPAL INTERNATIONAL CORE" },
  { ticker: "PDEX", name: "PRO-DEX INC" },
  { ticker: "PDFS", name: "PDF SOLUTIONS INC" },
  { ticker: "PDI", name: "PIMCO DYNAMIC INCOME FUND" },
  { ticker: "PDLB", name: "PDL COMMUNITY BANCORP" },
  { ticker: "PDLI", name: "PDL BIOPHARMA INC" },
  { ticker: "PDM", name: "PIEDMONT OFFICE REALTY TRU-A" },
  { ticker: "PDN", name: "INVESCO FTSE RAFI DEVELOPED" },
  { ticker: "PDP", name: "INVESCO DWA MOMENTUM ETF" },
  { ticker: "PDS", name: "PRECISION DRILLING CORP" },
  { ticker: "PDSB", name: "PDS BIOTECHNOLOGY CORP" },
  { ticker: "PDT", name: "JOHN HAN PREMIUM DIVIDEND FD" },
  { ticker: "PE", name: "PARSLEY ENERGY INC-CLASS A" },
  { ticker: "PEAK", name: "HEALTHPEAK PROPERTIES INC" },
  { ticker: "PEB", name: "PEBBLEBROOK HOTEL TRUST" },
  { ticker: "PEBK", name: "PEOPLES BANCORP OF NC" },
  { ticker: "PEBO", name: "PEOPLES BANCORP INC" },
  { ticker: "PECK", name: "PECK CO HOLDINGS INC/THE" },
  { ticker: "PED", name: "PEDEVCO CORP" },
  { ticker: "PEG", name: "PUBLIC SERVICE ENTERPRISE GP" },
  { ticker: "PEGA", name: "PEGASYSTEMS INC" },
  { ticker: "PEGI", name: "PATTERN ENERGY GROUP INC -A" },
  { ticker: "PEI", name: "PENN REAL ESTATE INVEST TST" },
  { ticker: "PEIX", name: "PACIFIC ETHANOL INC" },
  { ticker: "PEJ", name: "INVESCO DYNAMIC LEISURE AND" },
  { ticker: "PEK", name: "VANECK CHINCA AMC CSI 300" },
  { ticker: "PEN", name: "PENUMBRA INC" },
  { ticker: "PENN", name: "PENN NATIONAL GAMING INC" },
  { ticker: "PEO", name: "ADAMS NATURAL RESOURCES FUND" },
  { ticker: "PEP", name: "PEPSICO INC" },
  { ticker: "PER", name: "SANDRIDGE PERMIAN TRUST" },
  { ticker: "PERI", name: "PERION NETWORK LTD" },
  { ticker: "PESI", name: "PERMA-FIX ENVIRONMENTAL SVCS" },
  { ticker: "PETQ", name: "PETIQ INC" },
  { ticker: "PETS", name: "PETMED EXPRESS INC" },
  { ticker: "PETZ", name: "TDH HOLDINGS INC" },
  { ticker: "PEX", name: "PROSHARES GLOBAL LISTED PRIV" },
  { ticker: "PEXL", name: "PACER US EXPORT LEADERS ETF" },
  { ticker: "PEY", name: "INVESCO HIGH YIELD EQUITY DI" },
  { ticker: "PEZ", name: "INVESCO DWA CONSUMER CYCLICA" },
  { ticker: "PFBC", name: "PREFERRED BANK/LOS ANGELES" },
  { ticker: "PFBI", name: "PREMIER FINANCIAL BANCORP" },
  { ticker: "PFD", name: "FLAHERTY & CRUMRINE PREFERRE" },
  { ticker: "PFE", name: "PFIZER INC" },
  { ticker: "PFEB", name: "INNOVATOR SP 500 POWER - FEB" },
  { ticker: "PFF", name: "ISHARES PREFERRED & INCOME S" },
  { ticker: "PFFA", name: "Virtus InfraCap US PFD ETF" },
  { ticker: "PFFD", name: "GLOBAL X US PREFERRED ETF" },
  { ticker: "PFFL", name: "ETRACS MON PAY 2XLEV PREFER" },
  { ticker: "PFFR", name: "INFRACAP REIT PREFERRED ETF" },
  { ticker: "PFG", name: "PRINCIPAL FINANCIAL GROUP" },
  { ticker: "PFGC", name: "PERFORMANCE FOOD GROUP CO" },
  { ticker: "PFHD", name: "PROFESSIONAL HOLDING CORP-A" },
  { ticker: "PFI", name: "INVESCO DWA FINANCIAL MOMENT" },
  { ticker: "PFIE", name: "PROFIRE ENERGY INC" },
  { ticker: "PFIG", name: "INVESCO FUNDAMENTAL INVESTME" },
  { ticker: "PFIN", name: "P & F INDUSTRIES -CL A" },
  { ticker: "PFIS", name: "PEOPLES FINANCIAL SERVICES" },
  { ticker: "PFL", name: "PIMCO INCOME STRATEGY FUND" },
  { ticker: "PFLD", name: "AAM LOW DUR PREF & INC SEC" },
  { ticker: "PFLT", name: "PENNANTPARK FLOATING RATE CA" },
  { ticker: "PFM", name: "INVESCO DIVIDEND ACHIEVERS E" },
  { ticker: "PFMT", name: "PERFORMANT FINANCIAL CORP" },
  { ticker: "PFN", name: "PIMCO INCOME STRATEGY FD II" },
  { ticker: "PFNX", name: "PFENEX INC" },
  { ticker: "PFO", name: "FLAHERTY & CRUMRINE PREFERRE" },
  { ticker: "PFPT", name: "PROOFPOINT INC" },
  { ticker: "PFS", name: "PROVIDENT FINANCIAL SERVICES" },
  { ticker: "PFSI", name: "PENNYMAC FINANCIAL SERVICES" },
  { ticker: "PFSW", name: "PFSWEB INC" },
  { ticker: "PFXF", name: "VANECK PRFD SEC EX FINANCIAL" },
  { ticker: "PG", name: "PROCTER & GAMBLE CO/THE" },
  { ticker: "PGAL", name: "GLOBAL X MSCI PORTUGAL ETF" },
  { ticker: "PGC", name: "PEAPACK GLADSTONE FINL CORP" },
  { ticker: "PGEN", name: "PRECIGEN INC" },
  { ticker: "PGF", name: "INVESCO FINANCIAL PREFERRED" },
  { ticker: "PGHY", name: "INVESCO GLOBAL SHORT TERM HI" },
  { ticker: "PGJ", name: "INVESCO GOLDEN DRAGON CHINA" },
  { ticker: "PGM", name: "IPATH SERIES B BBG PLATINUM" },
  { ticker: "PGNX", name: "PROGENICS PHARMACEUTICALS" },
  { ticker: "PGNY", name: "PROGYNY INC" },
  { ticker: "PGP", name: "PIMCO GLOBAL STOCKSPLUS & IN" },
  { ticker: "PGR", name: "PROGRESSIVE CORP" },
  { ticker: "PGRE", name: "PARAMOUNT GROUP INC" },
  { ticker: "PGTI", name: "PGT INNOVATIONS INC" },
  { ticker: "PGX", name: "INVESCO PREFERRED ETF" },
  { ticker: "PGZ", name: "PRINCIPAL REAL ESTATE INCOME" },
  { ticker: "PH", name: "PARKER HANNIFIN CORP" },
  { ticker: "PHAS", name: "PHASEBIO PHARMACEUTICALS INC" },
  { ticker: "PHAT", name: "PHATHOM PHARMACEUTICALS INC" },
  { ticker: "PHB", name: "INVESCO FUNDAMENTAL HIGH YIE" },
  { ticker: "PHCF", name: "PUHUI WEALTH INVESTMENT MANA" },
  { ticker: "PHD", name: "PIONEER FLOATING RATE TRUST" },
  { ticker: "PHDG", name: "INVESCO S&P 500 DOWNSIDE HED" },
  { ticker: "PHG", name: "KONINKLIJKE PHILIPS NVR- NY" },
  { ticker: "PHGE", name: "BIOMX INC" },
  { ticker: "PHGE=", name: "BIOMX INC" },
  { ticker: "PHI", name: "PLDT INC-SPON ADR" },
  { ticker: "PHIO", name: "PHIO PHARMACEUTICALS CORP" },
  { ticker: "PHK", name: "PIMCO HIGH INCOME FUND" },
  { ticker: "PHM", name: "PULTEGROUP INC" },
  { ticker: "PHO", name: "INVESCO WATER RESOURCES ETF" },
  { ticker: "PHR", name: "PHREESIA INC" },
  { ticker: "PHT", name: "PIONEER HIGH INCOME TRUST" },
  { ticker: "PHUN", name: "PHUNWARE INC" },
  { ticker: "PHX", name: "PANHANDLE OIL AND GAS INC-A" },
  { ticker: "PHYL", name: "PGIM ACTIVE HIGH YIELD BOND" },
  { ticker: "PHYS", name: "SPROTT PHYSICAL GOLD TRUST" },
  { ticker: "PI", name: "IMPINJ INC" },
  { ticker: "PIC", name: "PIVOTAL INVESTMENT CORP II-A" },
  { ticker: "PIC=", name: "PIVOTAL INVESTMENT CORP II" },
  { ticker: "PICB", name: "INVESCO INTERNATIONAL CORPOR" },
  { ticker: "PICK", name: "ISHARES MSCI GLOBAL METALS &" },
  { ticker: "PICO", name: "PICO HOLDINGS INC" },
  { ticker: "PID", name: "INVESCO INTERNATIONAL DIVIDE" },
  { ticker: "PIE", name: "INVESCO DWA EMERGING MARKETS" },
  { ticker: "PIH", name: "1347 PROPERTY INSURANCE HOLD" },
  { ticker: "PII", name: "POLARIS INC" },
  { ticker: "PILL", name: "DIREXION DLY PHARMA BULL 3X" },
  { ticker: "PIM", name: "PUTNAM MASTER INTER INC TST" },
  { ticker: "PIN", name: "INVESCO INDIA EXCHANGE-TRADE" },
  { ticker: "PINC", name: "PREMIER INC-CLASS A" },
  { ticker: "PINE", name: "ALPINE INCOME PROPERTY TRUST" },
  { ticker: "PING", name: "PING IDENTITY HOLDING CORP" },
  { ticker: "PINS", name: "PINTEREST INC- CLASS A" },
  { ticker: "PIO", name: "INVESCO GLOBAL WATER ETF" },
  { ticker: "PIPR", name: "PIPER SANDLER COS" },
  { ticker: "PIRS", name: "PIERIS PHARMACEUTICALS INC" },
  { ticker: "PIXY", name: "SHIFTPIXY INC" },
  { ticker: "PIZ", name: "INVESCO DWA DEVELOPED MARKET" },
  { ticker: "PJAN", name: "INNOVATOR S&P 500 POWER-JAN" },
  { ticker: "PJP", name: "INVESCO DYNAMIC PHARMACEUTIC" },
  { ticker: "PJT", name: "PJT PARTNERS INC - A" },
  { ticker: "PJUL", name: "INNOVATOR S&P 500 POWER BUFF" },
  { ticker: "PJUN", name: "Innovator S&P500 P-BUFF JUNE" },
  { ticker: "PK", name: "PARK HOTELS & RESORTS INC" },
  { ticker: "PKB", name: "INVESCO DYNAMIC BUILDING & C" },
  { ticker: "PKBK", name: "PARKE BANCORP INC" },
  { ticker: "PKE", name: "PARK AEROSPACE CORP" },
  { ticker: "PKG", name: "PACKAGING CORP OF AMERICA" },
  { ticker: "PKI", name: "PERKINELMER INC" },
  { ticker: "PKO", name: "PIMCO INCOME OPPORTUNITY FND" },
  { ticker: "PKOH", name: "PARK-OHIO HOLDINGS CORP" },
  { ticker: "PKW", name: "INVESCO BUYBACK ACHIEVERS ET" },
  { ticker: "PKX", name: "POSCO- SPON ADR" },
  { ticker: "PLAB", name: "PHOTRONICS INC" },
  { ticker: "PLAG", name: "PLANET GREEN HOLDINGS CORP" },
  { ticker: "PLAN", name: "ANAPLAN INC" },
  { ticker: "PLAT", name: "WISDOMTREE MDRN TECH PLAT FD" },
  { ticker: "PLAY", name: "DAVE & BUSTER'S ENTERTAINMEN" },
  { ticker: "PLBC", name: "PLUMAS BANCORP" },
  { ticker: "PLC", name: "PRINCIPAL US LARGE CAP INDEX" },
  { ticker: "PLCE", name: "CHILDREN'S PLACE INC/THE" },
  { ticker: "PLCY", name: "EVENTSHARES US LEGISLATIVE O" },
  { ticker: "PLD", name: "PROLOGIS INC" },
  { ticker: "PLG", name: "PLATINUM GROUP METALS LTD" },
  { ticker: "PLIN", name: "CHINA XIANGTAI FOOD CO LTD" },
  { ticker: "PLL", name: "PIEDMONT LITHIUM LTD-ADR" },
  { ticker: "PLM", name: "POLYMET MINING CORP" },
  { ticker: "PLMR", name: "PALOMAR HOLDINGS INC" },
  { ticker: "PLNT", name: "PLANET FITNESS INC - CL A" },
  { ticker: "PLOW", name: "DOUGLAS DYNAMICS INC" },
  { ticker: "PLPC", name: "PREFORMED LINE PRODUCTS CO" },
  { ticker: "PLSE", name: "PULSE BIOSCIENCES INC" },
  { ticker: "PLT", name: "PLANTRONICS INC" },
  { ticker: "PLTM", name: "GRANITESHARES PLATINUM TRUST" },
  { ticker: "PLUG", name: "PLUG POWER INC" },
  { ticker: "PLUS", name: "EPLUS INC" },
  { ticker: "PLW", name: "INVESCO 1-30 LADDERED TREASU" },
  { ticker: "PLX", name: "PROTALIX BIOTHERAPEUTICS INC" },
  { ticker: "PLXP", name: "PLX PHARMA INC" },
  { ticker: "PLXS", name: "PLEXUS CORP" },
  { ticker: "PLYA", name: "PLAYA HOTELS & RESORTS NV" },
  { ticker: "PLYM", name: "PLYMOUTH INDUSTRIAL REIT INC" },
  { ticker: "PM", name: "PHILIP MORRIS INTERNATIONAL" },
  { ticker: "PMAR", name: "INNOVATOR S&P 500 POWER BUFF" },
  { ticker: "PMBC", name: "PACIFIC MERCANTILE BANCORP" },
  { ticker: "PMD", name: "PSYCHEMEDICS CORP" },
  { ticker: "PME", name: "PINGTAN MARINE ENTERPRISE LT" },
  { ticker: "PMF", name: "PIMCO MUNICIPAL INCOME FUND" },
  { ticker: "PML", name: "PIMCO MUNICIPAL INCOME FD II" },
  { ticker: "PMM", name: "PUTNAM MANAGED MUNI INCM TRS" },
  { ticker: "PMO", name: "PUTNAM MUNI OPPORTUNITIES TR" },
  { ticker: "PMOM", name: "PRINCIPAL SUST MOMENTUM ETF" },
  { ticker: "PMT", name: "PENNYMAC MORTGAGE INVESTMENT" },
  { ticker: "PMX", name: "PIMCO MUNI INCOME FUND III" },
  { ticker: "PNBK", name: "PATRIOT NATIONAL BANCORP INC" },
  { ticker: "PNC", name: "PNC FINANCIAL SERVICES GROUP" },
  { ticker: "PNF", name: "PIMCO NEW YORK MUNI INC FD" },
  { ticker: "PNFP", name: "PINNACLE FINANCIAL PARTNERS" },
  { ticker: "PNI", name: "PIMCO NEW YORK MUNICIPAL II" },
  { ticker: "PNM", name: "PNM RESOURCES INC" },
  { ticker: "PNNT", name: "PENNANTPARK INVESTMENT CORP" },
  { ticker: "PNOV", name: "INNOVATOR S&P POWER BUFFER-N" },
  { ticker: "PNQI", name: "INVESCO NASDAQ INTERNET ETF" },
  { ticker: "PNR", name: "PENTAIR PLC" },
  { ticker: "PNRG", name: "PRIMEENERGY RESOURCES CORP" },
  { ticker: "PNTG", name: "PENNANT GROUP INC/THE" },
  { ticker: "PNW", name: "PINNACLE WEST CAPITAL" },
  { ticker: "POAI", name: "PREDICTIVE ONCOLOGY INC" },
  { ticker: "POCT", name: "INNOVATOR S&P 500 POWER OCT" },
  { ticker: "PODD", name: "INSULET CORP" },
  { ticker: "POL", name: "POLYONE CORPORATION" },
  { ticker: "POLA", name: "POLAR POWER INC" },
  { ticker: "POOL", name: "POOL CORP" },
  { ticker: "POPE", name: "POPE RESOURCES" },
  { ticker: "POR", name: "PORTLAND GENERAL ELECTRIC CO" },
  { ticker: "POST", name: "POST HOLDINGS INC" },
  { ticker: "POTX", name: "GLOBAL X CANNABIS ETF" },
  { ticker: "POWI", name: "POWER INTEGRATIONS INC" },
  { ticker: "POWL", name: "POWELL INDUSTRIES INC" },
  { ticker: "PPA", name: "INVESCO AEROSPACE & DEFENSE" },
  { ticker: "PPBI", name: "PACIFIC PREMIER BANCORP INC" },
  { ticker: "PPC", name: "PILGRIM'S PRIDE CORP" },
  { ticker: "PPD", name: "PPD INC" },
  { ticker: "PPDM", name: "PORTFOLIO PLUS DEV MKT ETF" },
  { ticker: "PPEM", name: "PORTFOLIO PLUS EMG MKT ETF" },
  { ticker: "PPG", name: "PPG INDUSTRIES INC" },
  { ticker: "PPH", name: "VANECK PHARMACEUTICAL" },
  { ticker: "PPHI", name: "POSITIVE PHYSICIANS HOLDINGS" },
  { ticker: "PPIH", name: "PERMA-PIPE INTERNATIONAL HOL" },
  { ticker: "PPL", name: "PPL CORP" },
  { ticker: "PPLC", name: "PORTFOLIO PLUS S&P 500 ETF" },
  { ticker: "PPLT", name: "ABERDEEN STANDARD PHYSICAL P" },
  { ticker: "PPMC", name: "PORTFOLIO PLUS S&P MDCAP ETF" },
  { ticker: "PPR", name: "VOYA PRIME RATE TRUST" },
  { ticker: "PPSC", name: "PORTFOLIO PLUS S&P SMCAP ETF" },
  { ticker: "PPSI", name: "PIONEER POWER SOLUTIONS INC" },
  { ticker: "PPT", name: "PUTNAM PREMIER INCOME TRUST" },
  { ticker: "PPTY", name: "US DIVERSIFIED REAL ESTATE" },
  { ticker: "PQG", name: "PQ GROUP HOLDINGS INC" },
  { ticker: "PQIN", name: "PGIM QMA STRATEGIC ALPHA INT" },
  { ticker: "PQLC", name: "PGIM QMA STRATEGIC ALPHA LAR" },
  { ticker: "PQSG", name: "PGIM QMA STRATEGIC ALPHA SMA" },
  { ticker: "PQSV", name: "PGIM QMA STRATEGIC ALPHA SMA" },
  { ticker: "PRA", name: "PROASSURANCE CORP" },
  { ticker: "PRAA", name: "PRA GROUP INC" },
  { ticker: "PRAH", name: "PRA HEALTH SCIENCES INC" },
  { ticker: "PRCP", name: "PERCEPTRON INC" },
  { ticker: "PRDO", name: "PERDOCEO EDUCATION CORP" },
  { ticker: "PREF", name: "PRNCPAL SPCTRM PREF SEC ACTV" },
  { ticker: "PRF", name: "INVESCO FTSE RAFI US 1000 ET" },
  { ticker: "PRFT", name: "PERFICIENT INC" },
  { ticker: "PRFZ", name: "INVESCO FTSE RAFI US 1500 SM" },
  { ticker: "PRGO", name: "PERRIGO CO PLC" },
  { ticker: "PRGS", name: "PROGRESS SOFTWARE CORP" },
  { ticker: "PRGX", name: "PRGX GLOBAL INC" },
  { ticker: "PRI", name: "PRIMERICA INC" },
  { ticker: "PRIM", name: "PRIMORIS SERVICES CORP" },
  { ticker: "PRK", name: "PARK NATIONAL CORP" },
  { ticker: "PRLB", name: "PROTO LABS INC" },
  { ticker: "PRMW", name: "PRIMO WATER CORP" },
  { ticker: "PRN", name: "INVESCO DWA INDUSTRIALS MOME" },
  { ticker: "PRNB", name: "PRINCIPIA BIOPHARMA INC" },
  { ticker: "PRNT", name: "THE 3D PRINTING ETF" },
  { ticker: "PRO", name: "PROS HOLDINGS INC" },
  { ticker: "PROF", name: "PROFOUND MEDICAL CORP" },
  { ticker: "PROS", name: "PROSIGHT GLOBAL INC" },
  { ticker: "PROV", name: "PROVIDENT FINANCIAL HLDGS" },
  { ticker: "PRPH", name: "PROPHASE LABS INC" },
  { ticker: "PRPL", name: "PURPLE INNOVATION INC" },
  { ticker: "PRPO", name: "PRECIPIO INC" },
  { ticker: "PRQR", name: "PROQR THERAPEUTICS NV" },
  { ticker: "PRSC", name: "PROVIDENCE SERVICE CORP" },
  { ticker: "PRSP", name: "PERSPECTA INC" },
  { ticker: "PRT", name: "PERMROCK ROYALTY TRUST" },
  { ticker: "PRTA", name: "PROTHENA CORP PLC" },
  { ticker: "PRTH", name: "PRIORITY TECHNOLOGY HOLDINGS" },
  { ticker: "PRTK", name: "PARATEK PHARMACEUTICALS INC" },
  { ticker: "PRTS", name: "US AUTO PARTS NETWORK INC" },
  { ticker: "PRTY", name: "PARTY CITY HOLDCO INC" },
  { ticker: "PRU", name: "PRUDENTIAL FINANCIAL INC" },
  { ticker: "PRVB", name: "PROVENTION BIO INC" },
  { ticker: "PRVL", name: "PREVAIL THERAPEUTICS INC" },
  { ticker: "PS", name: "PLURALSIGHT INC - A" },
  { ticker: "PSA", name: "PUBLIC STORAGE" },
  { ticker: "PSB", name: "PS BUSINESS PARKS INC/CA" },
  { ticker: "PSC", name: "PRINCIPAL US SMALL CAP INDEX" },
  { ticker: "PSCC", name: "INVESCO S&P SMALLCAP CONSUME" },
  { ticker: "PSCD", name: "INVESCO S&P SMALLCAP CONSUME" },
  { ticker: "PSCE", name: "INVESCO S&P SMALLCAP ENERGY" },
  { ticker: "PSCF", name: "INVESCO S&P SMALLCAP FINANCI" },
  { ticker: "PSCH", name: "INVESCO S&P SMALLCAP HEALTH" },
  { ticker: "PSCI", name: "INVESCO S&P SMALLCAP INDUSTR" },
  { ticker: "PSCM", name: "INVESCO S&P SMALLCAP MATERIA" },
  { ticker: "PSCT", name: "INVESCO S&P SMALLCAP INFORMA" },
  { ticker: "PSCU", name: "INVESCO S&P S C U&C SER ETF" },
  { ticker: "PSEC", name: "PROSPECT CAPITAL CORP" },
  { ticker: "PSEP", name: "INNOVATOR S&P 500 POWER-SEPT" },
  { ticker: "PSET", name: "PRINCIPAL PRICE SET IND ETF" },
  { ticker: "PSF", name: "COHEN & STEERS SELECT PREFER" },
  { ticker: "PSI", name: "INVESCO DYNAMIC SEMICONDUCTO" },
  { ticker: "PSJ", name: "INVESCO DYNAMIC SOFTWARE ETF" },
  { ticker: "PSK", name: "SPDR WELLS FARGO PREFERRED" },
  { ticker: "PSL", name: "INVESCO DWA CONSUMER STAPLES" },
  { ticker: "PSLV", name: "SPROTT PHYSICAL SILVER TRUST" },
  { ticker: "PSM", name: "PRINCIPAL US SMALL CORE INDX" },
  { ticker: "PSMB", name: "INVESCO BALANCED MULTI-ASSET" },
  { ticker: "PSMC", name: "INVESCO CONSERVATIVE MULTI-A" },
  { ticker: "PSMG", name: "INVESCO GROWTH MULTI-ASSET A" },
  { ticker: "PSMM", name: "INVESCO MODERATELY CONSERVAT" },
  { ticker: "PSMT", name: "PRICESMART INC" },
  { ticker: "PSN", name: "PARSONS CORP" },
  { ticker: "PSNL", name: "PERSONALIS INC" },
  { ticker: "PSO", name: "PEARSON PLC-SPONSORED ADR" },
  { ticker: "PSP", name: "INVESCO GLOBAL LISTED PRIVAT" },
  { ticker: "PSQ", name: "PROSHARES SHORT QQQ" },
  { ticker: "PSR", name: "INVESCO ACTIVE US REAL ESTAT" },
  { ticker: "PST", name: "PROSHARES ULTRASHORT 7-10 YR" },
  { ticker: "PSTG", name: "PURE STORAGE INC - CLASS A" },
  { ticker: "PSTI", name: "PLURISTEM THERAPEUTICS INC" },
  { ticker: "PSTL", name: "POSTAL REALTY TRUST INC- A" },
  { ticker: "PSTV", name: "PLUS THERAPEUTICS INC" },
  { ticker: "PSV", name: "HERMITAGE OFFSHORE SERVICES" },
  { ticker: "PSX", name: "PHILLIPS 66" },
  { ticker: "PSXP", name: "PHILLIPS 66 PARTNERS LP" },
  { ticker: "PT", name: "PINTEC TECHNOLOGY HOLDINGS L" },
  { ticker: "PTAC", name: "PROPTECH ACQUISITION CORP-A" },
  { ticker: "PTACU", name: "PROPTECH ACQUISITION CORPORA" },
  { ticker: "PTBD", name: "PACER TRENDPILOT US BOND ETF" },
  { ticker: "PTC", name: "PTC INC" },
  { ticker: "PTCT", name: "PTC THERAPEUTICS INC" },
  { ticker: "PTE", name: "POLARITYTE INC" },
  { ticker: "PTEN", name: "PATTERSON-UTI ENERGY INC" },
  { ticker: "PTEU", name: "PACER TRENDPILOT EUROPEAN ET" },
  { ticker: "PTF", name: "INVESCO DWA TECHNOLOGY MOMEN" },
  { ticker: "PTGX", name: "PROTAGONIST THERAPEUTICS INC" },
  { ticker: "PTH", name: "INVESCO DWA HEALTHCARE MOMEN" },
  { ticker: "PTI", name: "PROTEOSTASIS THERAPEUTICS IN" },
  { ticker: "PTIN", name: "PACER TRENDPILOT INTL ETF" },
  { ticker: "PTLA", name: "PORTOLA PHARMACEUTICALS INC" },
  { ticker: "PTLC", name: "PACER TRENDPILOT US LARGE CA" },
  { ticker: "PTMC", name: "PACER TRENDPILOT US MID CAP" },
  { ticker: "PTMN", name: "PORTMAN RIDGE FINANCE CORP" },
  { ticker: "PTN", name: "PALATIN TECHNOLOGIES INC" },
  { ticker: "PTNQ", name: "PACER TRENDPILOT 100 ETF" },
  { ticker: "PTNR", name: "PARTNER COMMUNICATIONS-ADR" },
  { ticker: "PTON", name: "PELOTON INTERACTIVE INC-A" },
  { ticker: "PTR", name: "PETROCHINA CO LTD -ADR" },
  { ticker: "PTSI", name: "P.A.M. TRANSPORTATION SVCS" },
  { ticker: "PTVCA", name: "PROTECTIVE INSURANCE CORP- A" },
  { ticker: "PTVCB", name: "PROTECTIVE INSURANCE CORP- B" },
  { ticker: "PTY", name: "PIMCO CORPORATE & INCOME OPP" },
  { ticker: "PUB", name: "PEOPLE'S UTAH BANCORP" },
  { ticker: "PUI", name: "INVESCO DWA UTILITIES MOMENT" },
  { ticker: "PUK", name: "PRUDENTIAL PLC-ADR" },
  { ticker: "PULM", name: "PULMATRIX INC" },
  { ticker: "PULS", name: "PGIM ULTRA SHORT BOND ETF" },
  { ticker: "PUMP", name: "PROPETRO HOLDING CORP" },
  { ticker: "PUTW", name: "WISDOMTREE CBOE S&P 500 PTWT" },
  { ticker: "PUYI", name: "PUYI INC - ADR" },
  { ticker: "PVAC", name: "PENN VIRGINIA CORP" },
  { ticker: "PVAL", name: "PRINCIPAL CONTRARIAN VALUE" },
  { ticker: "PVBC", name: "PROVIDENT BANCORP INC" },
  { ticker: "PVG", name: "PRETIUM RESOURCES INC" },
  { ticker: "PVH", name: "PVH CORP" },
  { ticker: "PVI", name: "INVESCO VRDO TAX-FREE WEEKLY" },
  { ticker: "PVL", name: "PERMIANVILLE ROYALTY TRUST" },
  { ticker: "PW", name: "POWER REIT" },
  { ticker: "PWB", name: "INVESCO DYNAMIC LARGE CAP GR" },
  { ticker: "PWC", name: "INVESCO DYNAMIC MARKET ETF" },
  { ticker: "PWFL", name: "POWERFLEET INC" },
  { ticker: "PWOD", name: "PENNS WOODS BANCORP INC" },
  { ticker: "PWR", name: "QUANTA SERVICES INC" },
  { ticker: "PWS", name: "PACER WEALTHSHIELD ETF" },
  { ticker: "PWV", name: "INVESCO DYNAMIC LARGE CAP VA" },
  { ticker: "PWZ", name: "INVESCO CALIFORNIA AMT-FREE" },
  { ticker: "PXD", name: "PIONEER NATURAL RESOURCES CO" },
  { ticker: "PXE", name: "INVESCO DYNAMIC ENERGY EXPLO" },
  { ticker: "PXF", name: "INVESCO FTSE RAFI DEVELOPED" },
  { ticker: "PXH", name: "INVESCO FTSE RAFI EMERGING M" },
  { ticker: "PXI", name: "INVESCO DWA ENERGY MOMENTUM" },
  { ticker: "PXJ", name: "INVESCO DYNAMIC OIL & GAS SE" },
  { ticker: "PXLW", name: "PIXELWORKS INC" },
  { ticker: "PXQ", name: "INVESCO DYNAMIC NETWORKING E" },
  { ticker: "PXS", name: "PYXIS TANKERS INC" },
  { ticker: "PY", name: "PRINCIPAL SHAREHLD YIELD ETF" },
  { ticker: "PYN", name: "PIMCO NEW YORK MUNICIPAL III" },
  { ticker: "PYPE", name: "ETRACS NYSE PICKENS CORE ETN" },
  { ticker: "PYPL", name: "PAYPAL HOLDINGS INC" },
  { ticker: "PYX", name: "PYXUS INTERNATIONAL INC" },
  { ticker: "PYZ", name: "INVESCO DWA BASIC MATERIALS" },
  { ticker: "PZA", name: "INVESCO NATIONAL AMT-FREE MU" },
  { ticker: "PZC", name: "PIMCO CALIFORNIA MUNICIP III" },
  { ticker: "PZD", name: "INVESCO CLEANTECH ETF" },
  { ticker: "PZG", name: "PARAMOUNT GOLD NEVADA CORP" },
  { ticker: "PZN", name: "PZENA INVESTMENT MANAGM-CL A" },
  { ticker: "PZT", name: "INVESCO NEW YORK AMT-FREE MU" },
  { ticker: "PZZA", name: "PAPA JOHN'S INTL INC" },
  { ticker: "QABA", name: "FIRST TRUST NASDAQ ABA CBIF" },
  { ticker: "QADA", name: "QAD INC-A" },
  { ticker: "QADB", name: "QAD INC-B" },
  { ticker: "QAI", name: "IQ HEDGE MULTI-STRAT TRACKER" },
  { ticker: "QARP", name: "DBX ETF TRUST - XTRACKERS RU" },
  { ticker: "QAT", name: "ISHARES MSCI QATAR ETF" },
  { ticker: "QBAK", name: "QUALSTAR CORP" },
  { ticker: "QCLN", name: "FIRST TRUST NASDAQ CLEAN EDG" },
  { ticker: "QCOM", name: "QUALCOMM INC" },
  { ticker: "QCRH", name: "QCR HOLDINGS INC" },
  { ticker: "QD", name: "QUDIAN INC-SPON ADR" },
  { ticker: "QDEF", name: "FLEXSHARES QUALITY DIVIDEND" },
  { ticker: "QDEL", name: "QUIDEL CORP" },
  { ticker: "QDF", name: "FLEXSHARES QUALITY DIVIDEND" },
  { ticker: "QDIV", name: "GLOBAL X S&P 500 QLTY DIV" },
  { ticker: "QDYN", name: "FLEXSHARES QUALITY DIVIDEND" },
  { ticker: "QED", name: "IQ HEDGE EVENT-DRIVEN TRACKE" },
  { ticker: "QEFA", name: "SPDR MSCI EAFE STRATEGICFACT" },
  { ticker: "QEMM", name: "SPDR MSCI EMERGING MARKETS S" },
  { ticker: "QEP", name: "QEP RESOURCES INC" },
  { ticker: "QES", name: "QUINTANA ENERGY SERVICES INC" },
  { ticker: "QFIN", name: "360 FINANCE INC -ADR" },
  { ticker: "QGEN", name: "QIAGEN N.V." },
  { ticker: "QGRO", name: "AMERICAN CENTURY STOXX US QL" },
  { ticker: "QGTA", name: "IQ LEADERS GTAA TRACKER ETF" },
  { ticker: "QHC", name: "QUORUM HEALTH CORP" },
  { ticker: "QID", name: "PROSHARES ULTRASHORT QQQ" },
  { ticker: "QINT", name: "AMERICAN CENTURY QLTY DIVER" },
  { ticker: "QIWI", name: "QIWI PLC-SPONSORED ADR" },
  { ticker: "QK", name: "Q&K INTERNATIONAL GROUP-ADR" },
  { ticker: "QLC", name: "FLEXSHARES US QUALITY LG CAP" },
  { ticker: "QLD", name: "PROSHARES ULTRA QQQ" },
  { ticker: "QLS", name: "IQ HEDGE LONG/SHORT TRACKER" },
  { ticker: "QLTA", name: "ISHARES AAA - A RATED CORPOR" },
  { ticker: "QLV", name: "FLEXSHARES US QUALITY LOW VO" },
  { ticker: "QLVD", name: "FLEXSHARES DEVELOPED MARKETS" },
  { ticker: "QLVE", name: "FLEXSHARES EMERGING MARKETS" },
  { ticker: "QLYS", name: "QUALYS INC" },
  { ticker: "QMCO", name: "QUANTUM CORP" },
  { ticker: "QMJ", name: "DIREXION S&P 500 HIGH - LOW" },
  { ticker: "QMN", name: "IQ HEDGE MARKET NEUT TRACK" },
  { ticker: "QMOM", name: "ALPHA US QUANT MOM ETF" },
  { ticker: "QNST", name: "QUINSTREET INC" },
  { ticker: "QQEW", name: "FIRST TRUST NASDQ 100 EQ WEI" },
  { ticker: "QQH", name: "HCM DEFENDER 100 INDEX ETF" },
  { ticker: "QQQ", name: "INVESCO QQQ TRUST SERIES 1" },
  { ticker: "QQQE", name: "DIREXION NASDAQ-100 EQ WEIGH" },
  { ticker: "QQQX", name: "NUVEEN NAS100 DYN OVERWRT FD" },
  { ticker: "QQXT", name: "FIRST TRUST NASDAQ-100 EX-TE" },
  { ticker: "QRFT", name: "QRAFT AI-ENH US LRG CAP ETF" },
  { ticker: "QRHC", name: "QUEST RESOURCE HOLDING CORP" },
  { ticker: "QRTEA", name: "QURATE RETAIL INC-SERIES A" },
  { ticker: "QRTEB", name: "QURATE RETAIL GROUP INC-B" },
  { ticker: "QRVO", name: "QORVO INC" },
  { ticker: "QSR", name: "RESTAURANT BRANDS INTERN" },
  { ticker: "QSY", name: "WISDOMTREE US QUALITY SHAREH" },
  { ticker: "QTEC", name: "FIRST TRUST NASDQ 100 TECH I" },
  { ticker: "QTNT", name: "QUOTIENT LTD" },
  { ticker: "QTRX", name: "QUANTERIX CORP" },
  { ticker: "QTS", name: "QTS REALTY TRUST INC-CL A" },
  { ticker: "QTT", name: "QUTOUTIAO INC-ADR" },
  { ticker: "QTUM", name: "DEFIANCE QUANTUM ETF" },
  { ticker: "QTWO", name: "Q2 HOLDINGS INC" },
  { ticker: "QUAD", name: "QUAD GRAPHICS INC" },
  { ticker: "QUAL", name: "ISHARES EDGE MSCI USA QUALIT" },
  { ticker: "QUIK", name: "QUICKLOGIC CORP" },
  { ticker: "QUMU", name: "QUMU CORP" },
  { ticker: "QUOT", name: "QUOTIENT TECHNOLOGY INC" },
  { ticker: "QURE", name: "UNIQURE NV" },
  { ticker: "QUS", name: "SPDR MSCI USA STRATEGICFACTO" },
  { ticker: "QVAL", name: "ALPHA ARCH US QNT VALUE ETF" },
  { ticker: "QVM", name: "ARROW QVM EQUITY FACTOR ETF" },
  { ticker: "QWLD", name: "SPDR MSCI WORLD STRATEGICFAC" },
  { ticker: "QYLD", name: "GLOBAL X NASD 100 COV CALL" },
  { ticker: "R", name: "RYDER SYSTEM INC" },
  { ticker: "RA", name: "BROOKFIELD REAL ASSETS INCOM" },
  { ticker: "RAAX", name: "VANECK REAL ASSET ALLOCATION" },
  { ticker: "RACE", name: "FERRARI NV" },
  { ticker: "RAD", name: "RITE AID CORP" },
  { ticker: "RADA", name: "RADA ELECTRONIC INDS LTD" },
  { ticker: "RAFE", name: "PIMCO RAFI ESG US ETF" },
  { ticker: "RAIL", name: "FREIGHTCAR AMERICA INC" },
  { ticker: "RALS", name: "PROSHARES RAFI LONG/SHORT" },
  { ticker: "RAMP", name: "LIVERAMP HOLDINGS INC" },
  { ticker: "RAND", name: "RAND CAPITAL CORP" },
  { ticker: "RAPT", name: "RAPT THERAPEUTICS INC" },
  { ticker: "RARE", name: "ULTRAGENYX PHARMACEUTICAL IN" },
  { ticker: "RARX", name: "RA PHARMACEUTICALS INC" },
  { ticker: "RAVE", name: "RAVE RESTAURANT GROUP INC" },
  { ticker: "RAVI", name: "FLEXSHARES READY ACCESS VARI" },
  { ticker: "RAVN", name: "RAVEN INDUSTRIES INC" },
  { ticker: "RBA", name: "RITCHIE BROS AUCTIONEERS" },
  { ticker: "RBB", name: "RBB BANCORP" },
  { ticker: "RBBN", name: "RIBBON COMMUNICATIONS INC" },
  { ticker: "RBC", name: "REGAL BELOIT CORP" },
  { ticker: "RBCAA", name: "REPUBLIC BANCORP INC-CLASS A" },
  { ticker: "RBCN", name: "RUBICON TECHNOLOGY INC" },
  { ticker: "RBIN", name: "NATIONWIDE RISK-BASED INTL E" },
  { ticker: "RBKB", name: "RHINEBECK BANCORP INC" },
  { ticker: "RBNC", name: "RELIANT BANCORP INC" },
  { ticker: "RBS", name: "ROYAL BANK OF SCOT-SPON ADR" },
  { ticker: "RBUS", name: "NATIONWIDE RISK-BASED US EQ" },
  { ticker: "RBZ", name: "REEBONZ HOLDING LTD" },
  { ticker: "RC", name: "READY CAPITAL CORP" },
  { ticker: "RCD", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RCEL", name: "AVITA MEDICAL LTD-SPONS ADR" },
  { ticker: "RCG", name: "RENN FUND INC" },
  { ticker: "RCI", name: "ROGERS COMMUNICATIONS INC-B" },
  { ticker: "RCII", name: "RENT-A-CENTER INC" },
  { ticker: "RCKT", name: "ROCKET PHARMACEUTICALS INC" },
  { ticker: "RCKY", name: "ROCKY BRANDS INC" },
  { ticker: "RCL", name: "ROYAL CARIBBEAN CRUISES LTD" },
  { ticker: "RCM", name: "R1 RCM INC" },
  { ticker: "RCMT", name: "RCM TECHNOLOGIES INC" },
  { ticker: "RCON", name: "RECON TECHNOLOGY LTD" },
  { ticker: "RCS", name: "PIMCO STRATEGIC INCOME FUND" },
  { ticker: "RCUS", name: "ARCUS BIOSCIENCES INC" },
  { ticker: "RDCM", name: "RADCOM LTD" },
  { ticker: "RDFN", name: "REDFIN CORP" },
  { ticker: "RDHL", name: "REDHILL BIOPHARMA LTD-SP ADR" },
  { ticker: "RDI", name: "READING INTERNATIONAL INC-A" },
  { ticker: "RDIB", name: "READING INTERNATIONAL INC-B" },
  { ticker: "RDIV", name: "INVESCO S&P ULTRA DIVIDEND" },
  { ticker: "RDN", name: "RADIAN GROUP INC" },
  { ticker: "RDNT", name: "RADNET INC" },
  { ticker: "RDOG", name: "ALPS REIT DIVIDEND DOGS ETF" },
  { ticker: "RDS.A", name: "ROYAL DUTCH SHELL-SPON ADR-A" },
  { ticker: "RDS.B", name: "ROYAL DUTCH SHELL-SPON ADR-B" },
  { ticker: "RDUS", name: "RADIUS HEALTH INC" },
  { ticker: "RDVT", name: "RED VIOLET INC" },
  { ticker: "RDVY", name: "FIRST TRUST RISING DIVIDEND" },
  { ticker: "RDWR", name: "RADWARE LTD" },
  { ticker: "RDY", name: "DR. REDDY'S LABORATORIES-ADR" },
  { ticker: "RE", name: "EVEREST RE GROUP LTD" },
  { ticker: "REAL", name: "REALREAL INC/THE" },
  { ticker: "RECN", name: "RESOURCES CONNECTION INC" },
  { ticker: "RECS", name: "COLUMBIA RESRH ENHANCED CORE" },
  { ticker: "REDU", name: "RISE EDUCATION CAYMAN - ADR" },
  { ticker: "REED", name: "REED'S INC" },
  { ticker: "REET", name: "ISHARES GLOBAL REIT ETF" },
  { ticker: "REFR", name: "RESEARCH FRONTIERS INC" },
  { ticker: "REG", name: "REGENCY CENTERS CORP" },
  { ticker: "REGI", name: "RENEWABLE ENERGY GROUP INC" },
  { ticker: "REGL", name: "PRSHRS S&P MID 400 DVD ARIST" },
  { ticker: "REGN", name: "REGENERON PHARMACEUTICALS" },
  { ticker: "REI", name: "RING ENERGY INC" },
  { ticker: "REK", name: "PROSHARES SHORT REAL ESTATE" },
  { ticker: "REKR", name: "REKOR SYSTEMS INC" },
  { ticker: "RELL", name: "RICHARDSON ELEC LTD" },
  { ticker: "RELV", name: "RELIV INTERNATIONAL INC" },
  { ticker: "RELX", name: "RELX PLC - SPON ADR" },
  { ticker: "REM", name: "ISHARES MORTGAGE REAL ESTATE" },
  { ticker: "REML", name: "X-LINKS 2X MORTG REIT ETN" },
  { ticker: "REMX", name: "VANECK RARE EARTH/STR METAL" },
  { ticker: "RENN", name: "RENREN INC-ADR" },
  { ticker: "REPH", name: "RECRO PHARMA INC" },
  { ticker: "REPL", name: "REPLIMUNE GROUP INC" },
  { ticker: "RES", name: "RPC INC" },
  { ticker: "RESI", name: "FRONT YARD RESIDENTIAL CORP" },
  { ticker: "RESN", name: "RESONANT INC" },
  { ticker: "RETA", name: "REATA PHARMACEUTICALS INC-A" },
  { ticker: "RETL", name: "DIREXION DLY RETAIL BULL 3X" },
  { ticker: "RETO", name: "RETO ECO-SOLUTIONS INC" },
  { ticker: "REV", name: "REVLON INC-CLASS A" },
  { ticker: "REVG", name: "REV GROUP INC" },
  { ticker: "REVS", name: "COLUMBIA RESEARCH ENHANCED V" },
  { ticker: "REW", name: "PROSHARES ULTRASHORT TECH" },
  { ticker: "REX", name: "REX AMERICAN RESOURCES CORP" },
  { ticker: "REXN", name: "REXAHN PHARMACEUTICALS INC" },
  { ticker: "REXR", name: "REXFORD INDUSTRIAL REALTY IN" },
  { ticker: "REYN", name: "REYNOLDS CONSUMER PRODUCTS I" },
  { ticker: "REZ", name: "ISHARES RESIDENTIAL REAL EST" },
  { ticker: "REZI", name: "RESIDEO TECHNOLOGIES INC" },
  { ticker: "RF", name: "REGIONS FINANCIAL CORP" },
  { ticker: "RFAP", name: "FIRST TRUST DYNAMIC APAC ETF" },
  { ticker: "RFCI", name: "RIVERFRONT DYNAMIC CORE INCO" },
  { ticker: "RFDA", name: "RIVERFRONT DYNAMIC US DV ETF" },
  { ticker: "RFDI", name: "FIRST TRST DYN DEVEL INT ETF" },
  { ticker: "RFEM", name: "FIRST TRUST RIVERFRONT DYNAM" },
  { ticker: "RFEU", name: "FIRST TR DYNAMIC EUROPE ETF" },
  { ticker: "RFFC", name: "RIVERFRONT DYNAMIC US FC ETF" },
  { ticker: "RFG", name: "INVESCO S&P MIDCAP 400 PURE" },
  { ticker: "RFI", name: "COHEN & STEERS TOT RET RLTY" },
  { ticker: "RFIL", name: "RF INDUSTRIES LTD" },
  { ticker: "RFL", name: "RAFAEL HOLDINGS INC-CLASS B" },
  { ticker: "RFP", name: "RESOLUTE FOREST PRODUCTS" },
  { ticker: "RFUN", name: "RIVERFRONT DYNAMIC UNCONSTRA" },
  { ticker: "RFV", name: "INVESCO S&P MIDCAP 400 PURE" },
  { ticker: "RGA", name: "REINSURANCE GROUP OF AMERICA" },
  { ticker: "RGCO", name: "RGC RESOURCES INC" },
  { ticker: "RGEN", name: "REPLIGEN CORP" },
  { ticker: "RGI", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RGLD", name: "ROYAL GOLD INC" },
  { ticker: "RGLS", name: "REGULUS THERAPEUTICS INC" },
  { ticker: "RGNX", name: "REGENXBIO INC" },
  { ticker: "RGR", name: "STURM RUGER & CO INC" },
  { ticker: "RGS", name: "REGIS CORP" },
  { ticker: "RGT", name: "ROYCE GLOBAL VALUE TRUST" },
  { ticker: "RH", name: "RH" },
  { ticker: "RHE", name: "REGIONAL HEALTH PROPERTIES" },
  { ticker: "RHI", name: "ROBERT HALF INTL INC" },
  { ticker: "RHP", name: "RYMAN HOSPITALITY PROPERTIES" },
  { ticker: "RHS", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RIBT", name: "RICEBRAN TECHNOLOGIES" },
  { ticker: "RICK", name: "RCI HOSPITALITY HOLDINGS INC" },
  { ticker: "RIF", name: "RMR REAL ESTATE INCOME FUND" },
  { ticker: "RIG", name: "TRANSOCEAN LTD" },
  { ticker: "RIGL", name: "RIGEL PHARMACEUTICALS INC" },
  { ticker: "RIGS", name: "RIVERFRONT STRATEGIC INCOME" },
  { ticker: "RILY", name: "B. RILEY FINANCIAL INC" },
  { ticker: "RINF", name: "PROSHARES INFLATION EXPECTAT" },
  { ticker: "RING", name: "ISHARES MSCI GLOBAL GOLD MIN" },
  { ticker: "RIO", name: "RIO TINTO PLC-SPON ADR" },
  { ticker: "RIOT", name: "RIOT BLOCKCHAIN INC" },
  { ticker: "RISE", name: "SIT RISING RATE ETF" },
  { ticker: "RIV", name: "RIVERNORTH OPPORTUNITIES FND" },
  { ticker: "RIVE", name: "RIVERVIEW FINANCIAL CORP" },
  { ticker: "RJA", name: "ELEMENTS ROGERS AGRI TOT RET" },
  { ticker: "RJF", name: "RAYMOND JAMES FINANCIAL INC" },
  { ticker: "RJI", name: "ELEMENTS ROGERS TOTAL RETURN" },
  { ticker: "RJN", name: "ELEMENTS ROGERS ENERGY TR" },
  { ticker: "RJZ", name: "ELEMENTS ROGERS METALS TR" },
  { ticker: "RKDA", name: "ARCADIA BIOSCIENCES INC" },
  { ticker: "RL", name: "RALPH LAUREN CORP" },
  { ticker: "RLGT", name: "RADIANT LOGISTICS INC" },
  { ticker: "RLGY", name: "REALOGY HOLDINGS CORP" },
  { ticker: "RLH", name: "RED LION HOTELS CORP" },
  { ticker: "RLI", name: "RLI CORP" },
  { ticker: "RLJ", name: "RLJ LODGING TRUST" },
  { ticker: "RLMD", name: "RELMADA THERAPEUTICS INC" },
  { ticker: "RLY", name: "SPDR SSGA MULTI-ASSET REAL R" },
  { ticker: "RM", name: "REGIONAL MANAGEMENT CORP" },
  { ticker: "RMAX", name: "RE/MAX HOLDINGS INC-CL A" },
  { ticker: "RMBI", name: "RICHMOND MUTUAL BANCORPORATI" },
  { ticker: "RMBL", name: "RUMBLEON INC-B" },
  { ticker: "RMBS", name: "RAMBUS INC" },
  { ticker: "RMCF", name: "ROCKY MOUNTAIN CHOC FACT INC" },
  { ticker: "RMD", name: "RESMED INC" },
  { ticker: "RMED", name: "RA MEDICAL SYSTEMS INC" },
  { ticker: "RMG", name: "RMG ACQUISITION CORP-CL A" },
  { ticker: "RMG=", name: "RMG ACQUISITION CORP" },
  { ticker: "RMI", name: "RIVERNORTH OPPORT MUNI INC" },
  { ticker: "RMM", name: "RIVERNORTH MANAGED DURATION" },
  { ticker: "RMNI", name: "RIMINI STREET INC" },
  { ticker: "RMR", name: "RMR GROUP INC/THE - A" },
  { ticker: "RMT", name: "ROYCE MICRO-CAP TRUST INC" },
  { ticker: "RMTI", name: "ROCKWELL MEDICAL INC" },
  { ticker: "RNDB", name: "RANDOLPH BANCORP INC" },
  { ticker: "RNDM", name: "DEVELOPED INT SELECT EQUITY" },
  { ticker: "RNDV", name: "US EQUITY DIVIDEND SELECT" },
  { ticker: "RNEM", name: "EMERGING MARKETS EQTY SELECT" },
  { ticker: "RNET", name: "RIGNET INC" },
  { ticker: "RNG", name: "RINGCENTRAL INC-CLASS A" },
  { ticker: "RNGR", name: "RANGER ENERGY SERVICES INC" },
  { ticker: "RNLC", name: "LARGE CAP US EQUITY SELECT" },
  { ticker: "RNMC", name: "MID CAP US EQUITY SELECT ETF" },
  { ticker: "RNP", name: "COHEN & STEERS REIT AND PREF" },
  { ticker: "RNR", name: "RENAISSANCERE HOLDINGS LTD" },
  { ticker: "RNSC", name: "SMALL CAP US EQUITY SELECT" },
  { ticker: "RNST", name: "RENASANT CORP" },
  { ticker: "RNWK", name: "REALNETWORKS INC" },
  { ticker: "ROAD", name: "CONSTRUCTION PARTNERS INC-A" },
  { ticker: "ROAM", name: "HARTFORD MULTIFACTOR EMERGIN" },
  { ticker: "ROBO", name: "ROBO GLOBAL ROBOTICS AND AUT" },
  { ticker: "ROBT", name: "FIRST TRUST NASDAQ ARTIFICIA" },
  { ticker: "ROCK", name: "GIBRALTAR INDUSTRIES INC" },
  { ticker: "RODE", name: "HARTFORD MULTIFACTOR DIVERSI" },
  { ticker: "RODI", name: "IPATH RETURN ON DISABILITY E" },
  { ticker: "RODM", name: "HARTFORD MULTIFACTOR DEVELOP" },
  { ticker: "ROG", name: "ROGERS CORP" },
  { ticker: "ROIC", name: "RETAIL OPPORTUNITY INVESTMEN" },
  { ticker: "ROK", name: "ROCKWELL AUTOMATION INC" },
  { ticker: "ROKT", name: "SPDR S&P KENSHO FINAL FRONTI" },
  { ticker: "ROKU", name: "ROKU INC" },
  { ticker: "ROL", name: "ROLLINS INC" },
  { ticker: "ROLL", name: "RBC BEARINGS INC" },
  { ticker: "ROM", name: "PROSHARES ULTRA TECHNOLOGY" },
  { ticker: "ROMO", name: "STRATEGY SHARES NEWFOUND/RES" },
  { ticker: "ROOF", name: "IQ US REAL ESTATE SMALL CAP" },
  { ticker: "ROP", name: "ROPER TECHNOLOGIES INC" },
  { ticker: "RORE", name: "HARTFORD MULTIFACTOR REIT ET" },
  { ticker: "ROSC", name: "HARTFORD MULTIFACTOR SMALL C" },
  { ticker: "ROSE", name: "ROSEHILL RESOURCES INC" },
  { ticker: "ROSEU", name: "ROSEHILL RESOURCES INC" },
  { ticker: "ROST", name: "ROSS STORES INC" },
  { ticker: "ROUS", name: "HARTFORD MULTIFACTOR US EQUI" },
  { ticker: "ROYT", name: "PACIFIC COAST OIL TRUST" },
  { ticker: "RP", name: "REALPAGE INC" },
  { ticker: "RPAI", name: "RETAIL PROPERTIES OF AME - A" },
  { ticker: "RPAR", name: "RPAR RISK PARITY ETF" },
  { ticker: "RPAY", name: "REPAY HOLDINGS CORP" },
  { ticker: "RPD", name: "RAPID7 INC" },
  { ticker: "RPG", name: "INVESCO S&P 500 PURE GROWTH" },
  { ticker: "RPLA", name: "REPLAY ACQUISITION CORP" },
  { ticker: "RPLA=", name: "REPLAY ACQUISITION CORP" },
  { ticker: "RPM", name: "RPM INTERNATIONAL INC" },
  { ticker: "RPT", name: "RPT REALTY" },
  { ticker: "RPV", name: "INVESCO S&P 500 PURE VALUE E" },
  { ticker: "RQI", name: "COHEN & STEERS QUAL INC RLTY" },
  { ticker: "RRBI", name: "RED RIVER BANCSHARES INC" },
  { ticker: "RRC", name: "RANGE RESOURCES CORP" },
  { ticker: "RRD", name: "RR DONNELLEY & SONS CO" },
  { ticker: "RRGB", name: "RED ROBIN GOURMET BURGERS" },
  { ticker: "RRR", name: "RED ROCK RESORTS INC-CLASS A" },
  { ticker: "RRTS", name: "ROADRUNNER TRANSPORTATION SY" },
  { ticker: "RS", name: "RELIANCE STEEL & ALUMINUM" },
  { ticker: "RSF", name: "RIVERNORTH MRKTPL LND CORP" },
  { ticker: "RSG", name: "REPUBLIC SERVICES INC" },
  { ticker: "RSP", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RST", name: "ROSETTA STONE INC" },
  { ticker: "RSX", name: "VANECK RUSSIA ETF" },
  { ticker: "RSXJ", name: "VANECK RUSSIA SMALL-CAP" },
  { ticker: "RTH", name: "VANECK RETAIL ETF" },
  { ticker: "RTIX", name: "RTI SURGICAL HDS INC" },
  { ticker: "RTL", name: "PACER BENCHMARK RETAIL REAL" },
  { ticker: "RTLR", name: "RATTLER MIDSTREAM LP" },
  { ticker: "RTM", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RTN", name: "RAYTHEON COMPANY" },
  { ticker: "RTRX", name: "RETROPHIN INC" },
  { ticker: "RTTR", name: "RITTER PHARMACEUTICALS INC" },
  { ticker: "RTW", name: "RTW RETAILWINDS INC" },
  { ticker: "RUBI", name: "RUBICON PROJECT INC/THE" },
  { ticker: "RUBY", name: "RUBIUS THERAPEUTICS INC" },
  { ticker: "RUHN", name: "RUHNN HOLDING LTD-ADR" },
  { ticker: "RUN", name: "SUNRUN INC" },
  { ticker: "RUSHA", name: "RUSH ENTERPRISES INC-CL A" },
  { ticker: "RUSHB", name: "RUSH ENTERPRISES INC - CL B" },
  { ticker: "RUSL", name: "DIREXION DLY RUSSIA BULL 3X" },
  { ticker: "RUSS", name: "DIREXION DLY RUSSIA BEAR 3X" },
  { ticker: "RUTH", name: "RUTH'S HOSPITALITY GROUP INC" },
  { ticker: "RVI", name: "RETAIL VALUE INC" },
  { ticker: "RVLV", name: "REVOLVE GROUP INC" },
  { ticker: "RVMD", name: "REVOLUTION MEDICINES INC" },
  { ticker: "RVNC", name: "REVANCE THERAPEUTICS INC" },
  { ticker: "RVNU", name: "XTRACKERS MUNICIPAL INFRASTR" },
  { ticker: "RVP", name: "RETRACTABLE TECHNOLOGIES INC" },
  { ticker: "RVRS", name: "REVERSE CAP WEIGHTED US LARG" },
  { ticker: "RVSB", name: "RIVERVIEW BANCORP INC" },
  { ticker: "RVT", name: "ROYCE VALUE TRUST" },
  { ticker: "RWCD", name: "DIREXION MSCI CYC/DEF" },
  { ticker: "RWDC", name: "DIREXION MSCI DEF/CYC" },
  { ticker: "RWDE", name: "DIREXION MSCI DM/EM" },
  { ticker: "RWED", name: "DIREXION MSCI EM/DM" },
  { ticker: "RWGV", name: "DIREXION RUSSELL G/V" },
  { ticker: "RWIU", name: "DIREXION FTSE/RUSSELL INT/US" },
  { ticker: "RWJ", name: "INVESCO S&P SMALLCAP 600" },
  { ticker: "RWK", name: "INVESCO S&P MIDCAP 400 REV" },
  { ticker: "RWL", name: "INVESCO S&P 500 REVENUE ETF" },
  { ticker: "RWLK", name: "REWALK ROBOTICS LTD" },
  { ticker: "RWLS", name: "DIREXION RUSSELL LARGE/SMALL" },
  { ticker: "RWM", name: "PROSHARES SHORT RUSSELL2000" },
  { ticker: "RWO", name: "SPDR DJ GLOBAL REAL ESTATE E" },
  { ticker: "RWR", name: "SPDR DOW JONES REIT ETF" },
  { ticker: "RWSL", name: "DIREXION RUSSELL SMALL/LARGE" },
  { ticker: "RWT", name: "REDWOOD TRUST INC" },
  { ticker: "RWUI", name: "DIREXION FTSE/RUSSELL US/INT" },
  { ticker: "RWVG", name: "DIREXION RUSSELL V/G" },
  { ticker: "RWX", name: "SPDR DR INTERNATIONAL RL EST" },
  { ticker: "RXD", name: "PROSHARES ULTRASHORT HEALTH" },
  { ticker: "RXI", name: "ISHARES GLOBAL CONSUMER DISC" },
  { ticker: "RXL", name: "PROSHARES ULTRA HEALTH CARE" },
  { ticker: "RXN", name: "REXNORD CORP" },
  { ticker: "RY", name: "ROYAL BANK OF CANADA" },
  { ticker: "RYAAY", name: "RYANAIR HOLDINGS PLC-SP ADR" },
  { ticker: "RYAM", name: "RAYONIER ADVANCED MATERIALS" },
  { ticker: "RYB", name: "RYB EDUCATION INC-ADR" },
  { ticker: "RYCE", name: "AMIRA NATURE FOODS LTD" },
  { ticker: "RYE", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RYF", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RYH", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RYI", name: "RYERSON HOLDING CORP" },
  { ticker: "RYJ", name: "INVESCO RAYMOND JAMES SB-1 E" },
  { ticker: "RYLD", name: "GLOBAL X RUSSELL 2000 COV CL" },
  { ticker: "RYN", name: "RAYONIER INC" },
  { ticker: "RYT", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RYTM", name: "RHYTHM PHARMACEUTICALS INC" },
  { ticker: "RYU", name: "INVESCO S&P 500 EQUAL WEIGHT" },
  { ticker: "RYZZ", name: "RYZZ MNGED FUT STRAT PLUS" },
  { ticker: "RZG", name: "INVESCO S&P SMALLCAP 600 PUR" },
  { ticker: "RZV", name: "INVESCO S&P SMALLCAP 600 PUR" },
  { ticker: "S", name: "SPRINT CORP" },
  { ticker: "SA", name: "SEABRIDGE GOLD INC" },
  { ticker: "SAA", name: "PROSHARES ULTRA SMALLCAP600" },
  { ticker: "SABR", name: "SABRE CORP" },
  { ticker: "SACH", name: "SACHEM CAPITAL CORP" },
  { ticker: "SAEX", name: "SAEXPLORATION HOLDINGS INC" },
  { ticker: "SAFE", name: "SAFEHOLD INC" },
  { ticker: "SAFM", name: "SANDERSON FARMS INC" },
  { ticker: "SAFT", name: "SAFETY INSURANCE GROUP INC" },
  { ticker: "SAGE", name: "SAGE THERAPEUTICS INC" },
  { ticker: "SAGG", name: "DRX DLY TOTAL BOND BEAR 1X" },
  { ticker: "SAH", name: "SONIC AUTOMOTIVE INC-CLASS A" },
  { ticker: "SAIA", name: "SAIA INC" },
  { ticker: "SAIC", name: "SCIENCE APPLICATIONS INTE" },
  { ticker: "SAIL", name: "SAILPOINT TECHNOLOGIES HOLDI" },
  { ticker: "SAL", name: "SALISBURY BANCORP INC" },
  { ticker: "SALM", name: "SALEM MEDIA GROUP INC" },
  { ticker: "SALT", name: "SCORPIO BULKERS INC" },
  { ticker: "SAM", name: "BOSTON BEER COMPANY INC-A" },
  { ticker: "SAMA", name: "SCHULTZE SPECIAL PURPOSE ACQ" },
  { ticker: "SAMAU", name: "SCHULTZE SPECIAL PURPOSE ACQ" },
  { ticker: "SAMG", name: "SILVERCREST ASSET MANAGEME-A" },
  { ticker: "SAN", name: "BANCO SANTANDER SA-SPON ADR" },
  { ticker: "SAND", name: "SANDSTORM GOLD LTD" },
  { ticker: "SANM", name: "SANMINA CORP" },
  { ticker: "SANW", name: "S&W SEED CO" },
  { ticker: "SAP", name: "SAP SE-SPONSORED ADR" },
  { ticker: "SAQN", name: "SOFTWARE ACQUISITION GROUP-A" },
  { ticker: "SAQNU", name: "SOFTWARE ACQUISITION GROUP I" },
  { ticker: "SAR", name: "SARATOGA INVESTMENT CORP" },
  { ticker: "SASR", name: "SANDY SPRING BANCORP INC" },
  { ticker: "SATS", name: "ECHOSTAR CORP-A" },
  { ticker: "SAVA", name: "CASSAVA SCIENCES INC" },
  { ticker: "SAVE", name: "SPIRIT AIRLINES INC" },
  { ticker: "SB", name: "SAFE BULKERS INC" },
  { ticker: "SBAC", name: "SBA COMMUNICATIONS CORP" },
  { ticker: "SBB", name: "PROSHARES SHORT SMALLCAP600" },
  { ticker: "SBBP", name: "STRONGBRIDGE BIOPHARMA PLC" },
  { ticker: "SBBX", name: "SB ONE BANCORP" },
  { ticker: "SBCF", name: "SEACOAST BANKING CORP/FL" },
  { ticker: "SBE", name: "SWITCHBACK ENERGY ACQUISIT-A" },
  { ticker: "SBE=", name: "SWITCHBACK ENERGY ACQUISITIO" },
  { ticker: "SBFG", name: "SB FINANCIAL GROUP INC" },
  { ticker: "SBGI", name: "SINCLAIR BROADCAST GROUP -A" },
  { ticker: "SBH", name: "SALLY BEAUTY HOLDINGS INC" },
  { ticker: "SBI", name: "WESTERN ASSET INTERMEDIATE" },
  { ticker: "SBIO", name: "ALPS MEDICAL BREAKTHROUGHS" },
  { ticker: "SBLK", name: "STAR BULK CARRIERS CORP" },
  { ticker: "SBM", name: "PROSHARES SHORT BASIC MAT" },
  { ticker: "SBNY", name: "SIGNATURE BANK" },
  { ticker: "SBOW", name: "SILVERBOW RESOURCES INC" },
  { ticker: "SBPH", name: "SPRING BANK PHARMACEUTICALS" },
  { ticker: "SBR", name: "SABINE ROYALTY TRUST" },
  { ticker: "SBRA", name: "SABRA HEALTH CARE REIT INC" },
  { ticker: "SBS", name: "CIA SANEAMENTO BASICO DE-ADR" },
  { ticker: "SBSI", name: "SOUTHSIDE BANCSHARES INC" },
  { ticker: "SBSW", name: "SIBANYE-STILLWATER LTD-ADR" },
  { ticker: "SBT", name: "STERLING BANCORP INC/MI" },
  { ticker: "SBUG", name: "IPATH SILVER ETN" },
  { ticker: "SBUX", name: "STARBUCKS CORP" },
  { ticker: "SC", name: "SANTANDER CONSUMER USA HOLDI" },
  { ticker: "SCAP", name: "ADVISORSHARES CORNERSTONE SM" },
  { ticker: "SCC", name: "PROSHARES ULTRASHORT CONS SV" },
  { ticker: "SCCO", name: "SOUTHERN COPPER CORP" },
  { ticker: "SCD", name: "LMP CAPITAL AND INCOME FUND" },
  { ticker: "SCHA", name: "SCHWAB US SMALL-CAP ETF" },
  { ticker: "SCHB", name: "SCHWAB US BROAD MARKET ETF" },
  { ticker: "SCHC", name: "SCHWAB INTL SMALL-CAP EQUITY" },
  { ticker: "SCHD", name: "SCHWAB US DVD EQUITY ETF" },
  { ticker: "SCHE", name: "SCHWAB EMERGING MARKETS EQUI" },
  { ticker: "SCHF", name: "SCHWAB INTL EQUITY ETF" },
  { ticker: "SCHG", name: "SCHWAB U.S. LARGE-CAP GROWTH" },
  { ticker: "SCHH", name: "SCHWAB US REIT ETF" },
  { ticker: "SCHI", name: "SCHWAB 5-10 YEAR CORPORATE B" },
  { ticker: "SCHJ", name: "SCHWAB 1-5 YEAR CORPORATE BO" },
  { ticker: "SCHK", name: "SCHWAB 1000 INDEX ETF" },
  { ticker: "SCHL", name: "SCHOLASTIC CORP" },
  { ticker: "SCHM", name: "SCHWAB US MID CAP ETF" },
  { ticker: "SCHN", name: "SCHNITZER STEEL INDS INC-A" },
  { ticker: "SCHO", name: "SCHWAB SHORT-TERM US TREAS" },
  { ticker: "SCHP", name: "SCHWAB U.S. TIPS ETF" },
  { ticker: "SCHQ", name: "SCHWAB LONG-TERM US TREASURY" },
  { ticker: "SCHR", name: "SCHWAB INTERMEDIATE-TERM US" },
  { ticker: "SCHV", name: "SCHWAB US LARGE-CAP VALUE" },
  { ticker: "SCHW", name: "SCHWAB (CHARLES) CORP" },
  { ticker: "SCHX", name: "SCHWAB US LARGE-CAP ETF" },
  { ticker: "SCHZ", name: "SCHWAB US AGGREGATE BOND ETF" },
  { ticker: "SCI", name: "SERVICE CORP INTERNATIONAL" },
  { ticker: "SCID", name: "GLOBAL X SCIENTIFIC EUR BETA" },
  { ticker: "SCIF", name: "VANECK INDIA SMALL-CAP" },
  { ticker: "SCIJ", name: "GLOBAL X SCIENTIFIC BETA JPN" },
  { ticker: "SCIU", name: "GLOBAL X SCIENTIFIC BETA ETF" },
  { ticker: "SCIX", name: "GLOBAL X SCIENTIFIC BETA ASI" },
  { ticker: "SCJ", name: "ISHARES MSCI JAPAN SMALL-CAP" },
  { ticker: "SCKT", name: "SOCKET MOBILE INC" },
  { ticker: "SCL", name: "STEPAN CO" },
  { ticker: "SCM", name: "STELLUS CAPITAL INVESTMENT C" },
  { ticker: "SCO", name: "PROSHARES ULTRASHORT BLOOMBE" },
  { ticker: "SCOM", name: "PROSHARES ULTRAPRO SHORT COM" },
  { ticker: "SCON", name: "SUPERCONDUCTOR TECHNOLOGIES" },
  { ticker: "SCOR", name: "COMSCORE INC" },
  { ticker: "SCPE", name: "SC HEALTH CORP-A" },
  { ticker: "SCPE=", name: "SC HEALTH CORP" },
  { ticker: "SCPH", name: "SCPHARMACEUTICALS INC" },
  { ticker: "SCPL", name: "SCIPLAY CORP-CLASS A" },
  { ticker: "SCS", name: "STEELCASE INC-CL A" },
  { ticker: "SCSC", name: "SCANSOURCE INC" },
  { ticker: "SCU", name: "SCULPTOR CAPITAL MANAGEMENT" },
  { ticker: "SCVL", name: "SHOE CARNIVAL INC" },
  { ticker: "SCVX=", name: "SCVX CORP" },
  { ticker: "SCWX", name: "SECUREWORKS CORP - A" },
  { ticker: "SCX", name: "STARRETT (L.S.) CO  -CL A" },
  { ticker: "SCYX", name: "SCYNEXIS INC" },
  { ticker: "SCZ", name: "ISHARES MSCI EAFE SMALL-CAP" },
  { ticker: "SD", name: "SANDRIDGE ENERGY INC" },
  { ticker: "SDAG", name: "IQ-ST DURR EHD CORE BOND US" },
  { ticker: "SDC", name: "SMILEDIRECTCLUB INC" },
  { ticker: "SDCI", name: "USCF SUMMERHAVEN DYNAMIC ETF" },
  { ticker: "SDD", name: "PROSHARES ULTRASHORT SC600" },
  { ticker: "SDEM", name: "GLOBAL X MSCI SUPERDIVIDEND" },
  { ticker: "SDG", name: "ISHARES SUSTAINABLE MSCI GLB" },
  { ticker: "SDGA", name: "IMPACT SHARES SUSTAINABLE DE" },
  { ticker: "SDGR", name: "SCHRODINGER INC" },
  { ticker: "SDI", name: "STANDARD DIVERSIFIED INC" },
  { ticker: "SDIV", name: "GLOBAL X SUPERDIVIDEND ETF" },
  { ticker: "SDOG", name: "ALPS SECTOR DIVIDEND DOGS" },
  { ticker: "SDOW", name: "PROSHARES ULTPRO SHRT DOW30" },
  { ticker: "SDP", name: "PROSHARES ULTSHRT UTILITIES" },
  { ticker: "SDPI", name: "SUPERIOR DRILLING PRODUCTS I" },
  { ticker: "SDRL", name: "SEADRILL LTD" },
  { ticker: "SDS", name: "PROSHARES ULTRASHORT S&P500" },
  { ticker: "SDVY", name: "FIRST TRUST SMID CAP RISING" },
  { ticker: "SDY", name: "SPDR S&P DIVIDEND ETF" },
  { ticker: "SDYL", name: "ETRACS 2X S&P DVD ETN" },
  { ticker: "SE", name: "SEA LTD-ADR" },
  { ticker: "SEAC", name: "SEACHANGE INTERNATIONAL INC" },
  { ticker: "SEAS", name: "SEAWORLD ENTERTAINMENT INC" },
  { ticker: "SEB", name: "SEABOARD CORP" },
  { ticker: "SECO", name: "SECOO HOLDING LTD - ADR" },
  { ticker: "SECT", name: "MAIN SECTOR ROTATION ETF" },
  { ticker: "SEDG", name: "SOLAREDGE TECHNOLOGIES INC" },
  { ticker: "SEE", name: "SEALED AIR CORP" },
  { ticker: "SEED", name: "ORIGIN AGRITECH LTD" },
  { ticker: "SEEL", name: "SEELOS THERAPEUTICS INC" },
  { ticker: "SEF", name: "PROSHARES SHORT FINANCIALS" },
  { ticker: "SEIC", name: "SEI INVESTMENTS COMPANY" },
  { ticker: "SEIX", name: "VIRTUS SEIX SENIOR LOAN ETF" },
  { ticker: "SELB", name: "SELECTA BIOSCIENCES INC" },
  { ticker: "SELF", name: "GLOBAL SELF STORAGE INC" },
  { ticker: "SEM", name: "SELECT MEDICAL HOLDINGS CORP" },
  { ticker: "SENEA", name: "SENECA FOODS CORP - CL A" },
  { ticker: "SENEB", name: "SENECA FOODS CORP - CL B" },
  { ticker: "SENS", name: "SENSEONICS HOLDINGS INC" },
  { ticker: "SERV", name: "SERVICEMASTER GLOBAL HOLDING" },
  { ticker: "SES", name: "SYNTHESIS ENERGY SYSTEMS INC" },
  { ticker: "SESN", name: "SESEN BIO INC" },
  { ticker: "SF", name: "STIFEL FINANCIAL CORP" },
  { ticker: "SFBC", name: "SOUND FINANCIAL BANCORP INC" },
  { ticker: "SFBS", name: "SERVISFIRST BANCSHARES INC" },
  { ticker: "SFE", name: "SAFEGUARD SCIENTIFICS INC" },
  { ticker: "SFET", name: "SAFE-T GROUP LTD-ADR" },
  { ticker: "SFHY", name: "WISDOMTREE U.S. SHORT TERM H" },
  { ticker: "SFIG", name: "WISDOMTREE US SHORT TERM COR" },
  { ticker: "SFIX", name: "STITCH FIX INC-CLASS A" },
  { ticker: "SFL", name: "SFL CORP LTD" },
  { ticker: "SFM", name: "SPROUTS FARMERS MARKET INC" },
  { ticker: "SFNC", name: "SIMMONS FIRST NATL CORP-CL A" },
  { ticker: "SFST", name: "SOUTHERN FIRST BANCSHARES" },
  { ticker: "SFTW", name: "OSPREY TECHNOLOGY ACQUISIT-A" },
  { ticker: "SFTW=", name: "OSPREY TECHNOLOGY ACQUISITIO" },
  { ticker: "SFUN", name: "FANG HOLDINGS LTD - ADR" },
  { ticker: "SFY", name: "SOFI SELECT 500 ETF" },
  { ticker: "SFYF", name: "SOFI 50 ETF" },
  { ticker: "SFYX", name: "SOFI NEXT 500 ETF" },
  { ticker: "SG", name: "SIRIUS INTERNATIONAL INSURAN" },
  { ticker: "SGA", name: "SAGA COMMUNICATIONS INC-CL A" },
  { ticker: "SGB", name: "SOUTHWEST GEORGIA FINL CORP" },
  { ticker: "SGBX", name: "SG BLOCKS INC" },
  { ticker: "SGC", name: "SUPERIOR GROUP OF COS INC" },
  { ticker: "SGDJ", name: "SPROTT JR. GOLD MINERS ETF" },
  { ticker: "SGDM", name: "SPROTT GOLD MINERS ETF" },
  { ticker: "SGEN", name: "SEATTLE GENETICS INC" },
  { ticker: "SGG", name: "IPATH SERIES B BBG SUGAR SUB" },
  { ticker: "SGH", name: "SMART GLOBAL HOLDINGS INC" },
  { ticker: "SGLB", name: "SIGMA LABS INC" },
  { ticker: "SGMA", name: "SIGMATRON INTERNATIONAL INC" },
  { ticker: "SGMO", name: "SANGAMO THERAPEUTICS INC" },
  { ticker: "SGMS", name: "SCIENTIFIC GAMES CORP" },
  { ticker: "SGOC", name: "SGOCO GROUP LTD" },
  { ticker: "SGOL", name: "ABERDEEN STNDRD PHYSIC GLD" },
  { ticker: "SGRP", name: "SPAR GROUP INC" },
  { ticker: "SGRY", name: "SURGERY PARTNERS INC" },
  { ticker: "SGU", name: "STAR GROUP LP" },
  { ticker: "SH", name: "PROSHARES SHORT S&P500" },
  { ticker: "SHAG", name: "WSDMTREE YLD EN US ST AGG" },
  { ticker: "SHAK", name: "SHAKE SHACK INC - CLASS A" },
  { ticker: "SHBI", name: "SHORE BANCSHARES INC" },
  { ticker: "SHE", name: "SPDR GENDER DIVERSITY ETF" },
  { ticker: "SHEN", name: "SHENANDOAH TELECOMMUNICATION" },
  { ticker: "SHG", name: "SHINHAN FINANCIAL GROUP-ADR" },
  { ticker: "SHI", name: "SINOPEC SHANGHAI-SPONS ADR" },
  { ticker: "SHIP", name: "SEANERGY MARITIME HOLDINGS" },
  { ticker: "SHLL", name: "TORTOISE ACQUISITION CORP-A" },
  { ticker: "SHLL=", name: "TORTOISE ACQUISITION CORP" },
  { ticker: "SHLO", name: "SHILOH INDUSTRIES INC" },
  { ticker: "SHLX", name: "SHELL MIDSTREAM PARTNERS LP" },
  { ticker: "SHM", name: "SPDR NUVEEN BBG BARC ST MUNI" },
  { ticker: "SHO", name: "SUNSTONE HOTEL INVESTORS INC" },
  { ticker: "SHOO", name: "STEVEN MADDEN LTD" },
  { ticker: "SHOP", name: "SHOPIFY INC - CLASS A" },
  { ticker: "SHSP", name: "SHARPSPRING INC" },
  { ticker: "SHV", name: "ISHARES SHORT TREASURY BOND" },
  { ticker: "SHW", name: "SHERWIN-WILLIAMS CO/THE" },
  { ticker: "SHY", name: "ISHARES 1-3 YEAR TREASURY BO" },
  { ticker: "SHYD", name: "VANECK SHORT HIGH-YLD MUNI" },
  { ticker: "SHYG", name: "ISHARES 0-5 YR HY CORP BOND" },
  { ticker: "SHYL", name: "XTRACKERS SHRT DUR HIGH YIEL" },
  { ticker: "SI", name: "SILVERGATE CAPITAL CORP-CL A" },
  { ticker: "SIBN", name: "SI-BONE INC" },
  { ticker: "SIC", name: "SELECT INTERIOR CONCEPTS-A" },
  { ticker: "SID", name: "CIA SIDERURGICA NACL-SP ADR" },
  { ticker: "SIEB", name: "SIEBERT FINANCIAL CORP" },
  { ticker: "SIEN", name: "SIENTRA INC" },
  { ticker: "SIF", name: "SIFCO INDUSTRIES" },
  { ticker: "SIFY", name: "SIFY TECHNOLOGIES-SPON ADR" },
  { ticker: "SIG", name: "SIGNET JEWELERS LTD" },
  { ticker: "SIGA", name: "SIGA TECHNOLOGIES INC" },
  { ticker: "SIGI", name: "SELECTIVE INSURANCE GROUP" },
  { ticker: "SIJ", name: "PROSHARES ULTSHRT INDUSTRIAL" },
  { ticker: "SIL", name: "GLOBAL X SILVER MINERS ETF" },
  { ticker: "SILC", name: "SILICOM LTD" },
  { ticker: "SILJ", name: "ETFMG PRIME JUNIOR SILVER MI" },
  { ticker: "SILK", name: "SILK ROAD MEDICAL INC" },
  { ticker: "SILV", name: "SILVERCREST METALS INC" },
  { ticker: "SIM", name: "GRUPO SIMEC SAB-SPON ADR" },
  { ticker: "SIMO", name: "SILICON MOTION TECHNOL-ADR" },
  { ticker: "SIMS", name: "SPDR S&P KENSHO INTELLIGENT" },
  { ticker: "SINA", name: "SINA CORP" },
  { ticker: "SINO", name: "SINO-GLOBAL SHIPPING AMERICA" },
  { ticker: "SINT", name: "SINTX TECHNOLOGIES INC" },
  { ticker: "SIRI", name: "SIRIUS XM HOLDINGS INC" },
  { ticker: "SITC", name: "SITE CENTERS CORP" },
  { ticker: "SITE", name: "SITEONE LANDSCAPE SUPPLY INC" },
  { ticker: "SITM", name: "SITIME CORP" },
  { ticker: "SIVB", name: "SVB FINANCIAL GROUP" },
  { ticker: "SIVR", name: "ABERDEEN STANDARD PHYSICAL S" },
  { ticker: "SIX", name: "SIX FLAGS ENTERTAINMENT CORP" },
  { ticker: "SIZE", name: "ISHARES EDGE MSCI USA SIZE F" },
  { ticker: "SJB", name: "PROSHARES SHORT HIGH YIELD" },
  { ticker: "SJI", name: "SOUTH JERSEY INDUSTRIES" },
  { ticker: "SJM", name: "JM SMUCKER CO/THE" },
  { ticker: "SJNK", name: "SPDR BBG BARC ST HIGH YIELD" },
  { ticker: "SJR", name: "SHAW COMMUNICATIONS INC-B" },
  { ticker: "SJT", name: "SAN JUAN BASIN ROYALTY TR" },
  { ticker: "SJW", name: "SJW GROUP" },
  { ticker: "SKF", name: "PROSHARES ULTSHRT FINANCIALS" },
  { ticker: "SKM", name: "SK TELECOM CO LTD-SPON ADR" },
  { ticker: "SKOR", name: "FLEXSHARES CREDIT-SCORED US" },
  { ticker: "SKT", name: "TANGER FACTORY OUTLET CENTER" },
  { ticker: "SKX", name: "SKECHERS USA INC-CL A" },
  { ticker: "SKY", name: "SKYLINE CHAMPION CORP" },
  { ticker: "SKYS", name: "SKY SOLAR HOLDINGS-SPON ADR" },
  { ticker: "SKYW", name: "SKYWEST INC" },
  { ticker: "SKYY", name: "FIRST TRUST CLOUD COMPUTING" },
  { ticker: "SLAB", name: "SILICON LABORATORIES INC" },
  { ticker: "SLB", name: "SCHLUMBERGER LTD" },
  { ticker: "SLCA", name: "US SILICA HOLDINGS INC" },
  { ticker: "SLCT", name: "SELECT BANCORP INC" },
  { ticker: "SLDB", name: "SOLID BIOSCIENCES INC" },
  { ticker: "SLF", name: "SUN LIFE FINANCIAL INC" },
  { ticker: "SLG", name: "SL GREEN REALTY CORP" },
  { ticker: "SLGG", name: "SUPER LEAGUE GAMING INC" },
  { ticker: "SLGL", name: "SOL-GEL TECHNOLOGIES LTD" },
  { ticker: "SLGN", name: "SILGAN HOLDINGS INC" },
  { ticker: "SLIM", name: "THE OBESITY ETF" },
  { ticker: "SLM", name: "SLM CORP" },
  { ticker: "SLNO", name: "SOLENO THERAPEUTICS INC" },
  { ticker: "SLP", name: "SIMULATIONS PLUS INC" },
  { ticker: "SLQD", name: "ISHARES 0-5 YR INV GRD CORP" },
  { ticker: "SLRC", name: "SOLAR CAPITAL LTD" },
  { ticker: "SLRX", name: "SALARIUS PHARMACEUTICALS INC" },
  { ticker: "SLS", name: "SELLAS LIFE SCIENCES GROUP I" },
  { ticker: "SLT", name: "SALT HIGH TRUBETA US MKT ETF" },
  { ticker: "SLV", name: "ISHARES SILVER TRUST" },
  { ticker: "SLVO", name: "X-LINKS SLVR SHRS COVRD CALL" },
  { ticker: "SLVP", name: "ISHARES MSCI GLOBAL SILVER A" },
  { ticker: "SLX", name: "VANECK STEEL ETF" },
  { ticker: "SLY", name: "SPDR S&P 600 SMALL CAP ETF" },
  { ticker: "SLYG", name: "SPDR S&P 600 SMALL CAP GROWT" },
  { ticker: "SLYV", name: "SPDR S&P 600 SMALL CAP VALUE" },
  { ticker: "SM", name: "SM ENERGY CO" },
  { ticker: "SMAR", name: "SMARTSHEET INC-CLASS A" },
  { ticker: "SMB", name: "VANECK AMT-FREE SHORT MUNI" },
  { ticker: "SMBC", name: "SOUTHERN MISSOURI BANCORP" },
  { ticker: "SMBK", name: "SMARTFINANCIAL INC" },
  { ticker: "SMCI", name: "SUPER MICRO COMPUTER INC" },
  { ticker: "SMCP", name: "ALPHAMARK ACT MANAGED SMALL" },
  { ticker: "SMDD", name: "PROSHARES ULTPRO SHRT MC400" },
  { ticker: "SMDV", name: "PROSHRS RSL 2000 DVD GRW ETF" },
  { ticker: "SMDY", name: "SYNTAX STRATIFIED MIDCAP ETF" },
  { ticker: "SMED", name: "SHARPS COMPLIANCE CORP" },
  { ticker: "SMEZ", name: "SPDR EURO STOXX SMALL CAP ET" },
  { ticker: "SMFG", name: "SUMITOMO MITSUI-SPONS ADR" },
  { ticker: "SMG", name: "SCOTTS MIRACLE-GRO CO" },
  { ticker: "SMH", name: "VANECK SEMICONDUCTOR" },
  { ticker: "SMHB", name: "ETRACS MON PAY 2X LEV US -B" },
  { ticker: "SMHD", name: "ETRACS MON PAY 2X LEV US SM" },
  { ticker: "SMHI", name: "SEACOR MARINE HOLDINGS INC" },
  { ticker: "SMIN", name: "ISHARES MSCI INDIA SMALL-CAP" },
  { ticker: "SMIT", name: "SCHMITT INDUSTRIES INC" },
  { ticker: "SMLF", name: "ISHARES EDGE MSCI MF US SMCP" },
  { ticker: "SMLL", name: "DIREXION DLY SM CAP BULL 2X" },
  { ticker: "SMLP", name: "SUMMIT MIDSTREAM PARTNERS LP" },
  { ticker: "SMLV", name: "SPDR SSGA US SMALL CAP LOW" },
  { ticker: "SMM", name: "SALIENT MIDSTREAM & MLP FUND" },
  { ticker: "SMMC", name: "SOUTH MOUNTAIN MERGER CORP-A" },
  { ticker: "SMMCU", name: "SOUTH MOUNTAIN MERGER CORP" },
  { ticker: "SMMD", name: "ISHARES RUSSELL 2500 ETF" },
  { ticker: "SMMF", name: "SUMMIT FINANCIAL GROUP INC" },
  { ticker: "SMMT", name: "SUMMIT THERAPEUTICS-SPON ADR" },
  { ticker: "SMMU", name: "PIMCO SHORT TERM MUNICIPAL B" },
  { ticker: "SMMV", name: "ISHARES EDGE MSCI MIN VOL SC" },
  { ticker: "SMN", name: "PROSHARES ULTSHRT BASIC MAT" },
  { ticker: "SMOG", name: "VANECK VECTORS LOW CARBON EN" },
  { ticker: "SMP", name: "STANDARD MOTOR PRODS" },
  { ticker: "SMPL", name: "SIMPLY GOOD FOODS CO/THE" },
  { ticker: "SMRT", name: "STEIN MART INC" },
  { ticker: "SMSI", name: "SMITH MICRO SOFTWARE INC" },
  { ticker: "SMTC", name: "SEMTECH CORP" },
  { ticker: "SMTS", name: "SIERRA METALS INC" },
  { ticker: "SMTX", name: "SMTC CORPORATION" },
  { ticker: "SNA", name: "SNAP-ON INC" },
  { ticker: "SNAP", name: "SNAP INC - A" },
  { ticker: "SNBR", name: "SLEEP NUMBER CORP" },
  { ticker: "SNCA", name: "SENECA BIOPHARMA INC" },
  { ticker: "SNCR", name: "SYNCHRONOSS TECHNOLOGIES INC" },
  { ticker: "SND", name: "SMART SAND INC" },
  { ticker: "SNDE", name: "SUNDANCE ENERGY INC" },
  { ticker: "SNDL", name: "SUNDIAL GROWERS INC" },
  { ticker: "SNDR", name: "SCHNEIDER NATIONAL INC-CL B" },
  { ticker: "SNDX", name: "SYNDAX PHARMACEUTICALS INC" },
  { ticker: "SNE", name: "SONY CORP-SPONSORED ADR" },
  { ticker: "SNES", name: "SENESTECH INC" },
  { ticker: "SNFCA", name: "SECURITY NATL FINL CORP-CL A" },
  { ticker: "SNGX", name: "SOLIGENIX INC" },
  { ticker: "SNLN", name: "HIGHLAND/IBOXX SENIOR LOAN E" },
  { ticker: "SNMP", name: "SANCHEZ MIDSTREAM PARTNERS L" },
  { ticker: "SNN", name: "SMITH & NEPHEW PLC -SPON ADR" },
  { ticker: "SNOA", name: "SONOMA PHARMACEUTICALS INC" },
  { ticker: "SNP", name: "CHINA PETROLEUM & CHEM-ADR" },
  { ticker: "SNPE", name: "XTRACKERS S&P 500 ESG ETF" },
  { ticker: "SNPS", name: "SYNOPSYS INC" },
  { ticker: "SNR", name: "NEW SENIOR INVESTMENT GROUP" },
  { ticker: "SNSR", name: "GLOBAL X INTERNET OF THINGS" },
  { ticker: "SNSS", name: "SUNESIS PHARMACEUTICALS INC" },
  { ticker: "SNUG", name: "MERLYN AI TACTICAL GRW & INC" },
  { ticker: "SNV", name: "SYNOVUS FINANCIAL CORP" },
  { ticker: "SNX", name: "SYNNEX CORP" },
  { ticker: "SNY", name: "SANOFI-ADR" },
  { ticker: "SO", name: "SOUTHERN CO/THE" },
  { ticker: "SOCL", name: "GLOBAL X SOCIAL MEDIA ETF" },
  { ticker: "SOGO", name: "SOGOU INC-ADR" },
  { ticker: "SOHO", name: "SOTHERLY HOTELS INC" },
  { ticker: "SOHU", name: "SOHU.COM LTD-ADR" },
  { ticker: "SOI", name: "SOLARIS OILFIELD INFRAST-A" },
  { ticker: "SOIL", name: "GLOBAL X FERTILIZERS/POTASH" },
  { ticker: "SOL", name: "RENESOLA LTD-ADR" },
  { ticker: "SOLO", name: "ELECTRAMECCANICA VEHICLES CO" },
  { ticker: "SOLY", name: "SOLITON INC" },
  { ticker: "SON", name: "SONOCO PRODUCTS CO" },
  { ticker: "SONA", name: "SOUTHERN NATL BANCORP OF VA" },
  { ticker: "SONG", name: "AKAZOO SA" },
  { ticker: "SONM", name: "SONIM TECHNOLOGIES INC" },
  { ticker: "SONO", name: "SONOS INC" },
  { ticker: "SOR", name: "SOURCE CAPITAL INC" },
  { ticker: "SORL", name: "SORL AUTO PARTS INC" },
  { ticker: "SOVB", name: "CAMBRIA SOVEREIGN BOND ETF" },
  { ticker: "SOXL", name: "DIREXION DAILY SEMI BULL 3X" },
  { ticker: "SOXS", name: "DIREXION DAILY SEMI BEAR 3X" },
  { ticker: "SOXX", name: "ISHARES PHLX SEMICONDUCTOR E" },
  { ticker: "SOYB", name: "TEUCRIUM SOYBEAN FUND" },
  { ticker: "SP", name: "SP PLUS CORP" },
  { ticker: "SPAB", name: "SPDR PORTFOLIO AGGREGATE BON" },
  { ticker: "SPAQ", name: "SPARTAN ENERGY ACQUISITION-A" },
  { ticker: "SPAQ=", name: "SPARTAN ENERGY ACQUISITION C" },
  { ticker: "SPAR", name: "SPARTAN MOTORS INC" },
  { ticker: "SPB", name: "SPECTRUM BRANDS HOLDINGS INC" },
  { ticker: "SPBO", name: "SPDR PORTFOLIO CORPORATE BON" },
  { ticker: "SPCB", name: "SUPERCOM LTD" },
  { ticker: "SPCE", name: "VIRGIN GALACTIC HOLDINGS INC" },
  { ticker: "SPCE=", name: "VIRGIN GALACTIC HOLDINGS INC" },
  { ticker: "SPDN", name: "DIREXION DLY S&P 500 BEAR 1X" },
  { ticker: "SPDV", name: "AAM S&P 500 HIGH DIV VAL ETF" },
  { ticker: "SPDW", name: "SPDR PORTFOLIO DEVELOPED WOR" },
  { ticker: "SPE", name: "SPECIAL OPPORTUNITIES FUND" },
  { ticker: "SPEM", name: "SPDR PORTFOLIO EMERGING MARK" },
  { ticker: "SPEU", name: "SPDR PORTFOLIO EUROPE ETF" },
  { ticker: "SPEX", name: "SPHERIX INC" },
  { ticker: "SPFF", name: "GLOBAL X SUPERINCOME PREFER" },
  { ticker: "SPFI", name: "SOUTH PLAINS FINANCIAL INC" },
  { ticker: "SPG", name: "SIMON PROPERTY GROUP INC" },
  { ticker: "SPGI", name: "S&P GLOBAL INC" },
  { ticker: "SPGM", name: "SPDR PORTFOLIO MSCI GLOBAL S" },
  { ticker: "SPGP", name: "INVESCO S&P 500 GARP ETF" },
  { ticker: "SPH", name: "SUBURBAN PROPANE PARTNERS LP" },
  { ticker: "SPHB", name: "INVESCO S&P 500 HIGH BETA ET" },
  { ticker: "SPHD", name: "INVESCO S&P 500 HIGH DIVIDEN" },
  { ticker: "SPHQ", name: "INVESCO S&P 500 QUALITY ETF" },
  { ticker: "SPHS", name: "SOPHIRIS BIO INC" },
  { ticker: "SPHY", name: "SPDR PORTFOLIO HIGH YIELD BO" },
  { ticker: "SPI", name: "SPI ENERGY CO LTD" },
  { ticker: "SPIB", name: "SPDR PORTFOLIO INTERMEDIATE" },
  { ticker: "SPIP", name: "SPDR PORTFOLIO TIPS ETF" },
  { ticker: "SPKE", name: "SPARK ENERGY INC-CLASS A" },
  { ticker: "SPLB", name: "SPDR PORT LNG TRM CORP BND" },
  { ticker: "SPLG", name: "SPDR PORTFOLIO S&P 500 ETF" },
  { ticker: "SPLK", name: "SPLUNK INC" },
  { ticker: "SPLP", name: "STEEL PARTNERS HOLDINGS LP" },
  { ticker: "SPLV", name: "INVESCO S&P 500 LOW VOLATILI" },
  { ticker: "SPMB", name: "SPDR PORTFOLIO MORTGAGE BACK" },
  { ticker: "SPMD", name: "SPDR PORTFOLIO S&P 400 MID C" },
  { ticker: "SPMO", name: "INVESCO S&P 500 MOMENTUM ETF" },
  { ticker: "SPMV", name: "INVESCO S&P 500 MINIMUM VARI" },
  { ticker: "SPN", name: "SUPERIOR ENERGY SERVICES INC" },
  { ticker: "SPNE", name: "SEASPINE HOLDINGS CORP" },
  { ticker: "SPNS", name: "SAPIENS INTERNATIONAL CORP" },
  { ticker: "SPOK", name: "SPOK HOLDINGS INC" },
  { ticker: "SPOT", name: "SPOTIFY TECHNOLOGY SA" },
  { ticker: "SPPI", name: "SPECTRUM PHARMACEUTICALS INC" },
  { ticker: "SPPP", name: "SPROTT PHYSICAL PLATINUM AND" },
  { ticker: "SPR", name: "SPIRIT AEROSYSTEMS HOLD-CL A" },
  { ticker: "SPRO", name: "SPERO THERAPEUTICS INC" },
  { ticker: "SPRT", name: "SUPPORT.COM INC" },
  { ticker: "SPSB", name: "SPDR PORT SHRT TRM CORP BND" },
  { ticker: "SPSC", name: "SPS COMMERCE INC" },
  { ticker: "SPSK", name: "SP FUNDS DOW JONES GLOBAL SU" },
  { ticker: "SPSM", name: "SPDR PORTFOLIO S&P 600 SMALL" },
  { ticker: "SPT", name: "SPROUT SOCIAL INC - CLASS A" },
  { ticker: "SPTI", name: "SPDR PORTFOLIO INTERMEDIATE" },
  { ticker: "SPTL", name: "SPDR PORT LNG TRM TRSRY" },
  { ticker: "SPTM", name: "SPDR PORTFOLIO S&P 1500 COMP" },
  { ticker: "SPTN", name: "SPARTANNASH CO" },
  { ticker: "SPTS", name: "SPDR PORT SHRT TRM TRSRY" },
  { ticker: "SPUS", name: "SP FUNDS S&P SHARIA INDUSTRY" },
  { ticker: "SPUU", name: "DIREXION DLY S&P 500 BULL 2X" },
  { ticker: "SPVM", name: "INVESCO S&P 500 VALUE WITH M" },
  { ticker: "SPVU", name: "INVESCO S&P 500 ENHANCED VAL" },
  { ticker: "SPWH", name: "SPORTSMAN'S WAREHOUSE HOLDIN" },
  { ticker: "SPWR", name: "SUNPOWER CORP" },
  { ticker: "SPXB", name: "PROSHARES S&P 500 BOND ETF" },
  { ticker: "SPXC", name: "SPX CORP" },
  { ticker: "SPXE", name: "PROSHARES S&P 500 EX-ENERGY" },
  { ticker: "SPXL", name: "DIREXION DLY S&P 500 BULL 3X" },
  { ticker: "SPXN", name: "PROSHARES S&P 500 EX-FINANCI" },
  { ticker: "SPXS", name: "DIREXION DLY S&P 500 BEAR 3X" },
  { ticker: "SPXT", name: "PROSHARES S&P 500 EX-TECHNOL" },
  { ticker: "SPXU", name: "PROSH ULTRAPRO SHORT S&P 500" },
  { ticker: "SPXV", name: "PROSHARES TRUST-S&P 500 EX-H" },
  { ticker: "SPXX", name: "NUVEEN S&P500 DYNAM OVERWR" },
  { ticker: "SPY", name: "SPDR S&P 500 ETF TRUST" },
  { ticker: "SPYB", name: "SPDR S&P 500 BUYBACK ETF" },
  { ticker: "SPYD", name: "SPDR PORTFOLIO S&P 500 HIGH" },
  { ticker: "SPYG", name: "SPDR PORTFOLIO S&P 500 GROWT" },
  { ticker: "SPYV", name: "SPDR PORT S&P 500 VALUE" },
  { ticker: "SPYX", name: "SPDR S&P 500 FOSSIL FUEL RES" },
  { ticker: "SQ", name: "SQUARE INC - A" },
  { ticker: "SQBG", name: "SEQUENTIAL BRANDS GROUP INC" },
  { ticker: "SQLV", name: "LEGG MASON SMALL-CAP QUALITY" },
  { ticker: "SQM", name: "QUIMICA Y MINERA CHIL-SP ADR" },
  { ticker: "SQNS", name: "SEQUANS COMMUNICATIONS-ADR" },
  { ticker: "SQQQ", name: "PROSHARES ULTRAPRO SHORT QQQ" },
  { ticker: "SR", name: "SPIRE INC" },
  { ticker: "SRAC", name: "STABLE ROAD ACQUISITION-CL A" },
  { ticker: "SRACU", name: "STABLE ROAD ACQUISITION CORP" },
  { ticker: "SRAX", name: "SRAX INC" },
  { ticker: "SRC", name: "SPIRIT REALTY CAPITAL INC" },
  { ticker: "SRCE", name: "1ST SOURCE CORP" },
  { ticker: "SRCL", name: "STERICYCLE INC" },
  { ticker: "SRDX", name: "SURMODICS INC" },
  { ticker: "SRE", name: "SEMPRA ENERGY" },
  { ticker: "SRET", name: "GLOBAL X SUPERDIVIDEND REIT" },
  { ticker: "SREV", name: "SERVICESOURCE INTERNATIONAL" },
  { ticker: "SRF", name: "CUSHING ENERGY INCOME FUND/T" },
  { ticker: "SRG", name: "SERITAGE GROWTH PROP- A REIT" },
  { ticker: "SRI", name: "STONERIDGE INC" },
  { ticker: "SRL", name: "SCULLY ROYALTY LTD" },
  { ticker: "SRLN", name: "SPDR BLACKSTONE/GSO SEN LOAN" },
  { ticker: "SRLP", name: "SPRAGUE RESOURCES LP" },
  { ticker: "SRNE", name: "SORRENTO THERAPEUTICS INC" },
  { ticker: "SRPT", name: "SAREPTA THERAPEUTICS INC" },
  { ticker: "SRRA", name: "SIERRA ONCOLOGY INC" },
  { ticker: "SRRK", name: "SCHOLAR ROCK HOLDING CORP" },
  { ticker: "SRS", name: "PROSHARES ULTRASHORT RE" },
  { ticker: "SRT", name: "STARTEK INC" },
  { ticker: "SRTS", name: "SENSUS HEALTHCARE INC" },
  { ticker: "SRTY", name: "PROSHARES ULTRAPRO SHRT R2K" },
  { ticker: "SRV", name: "CUSHING MLP & INFRASTRUCTURE" },
  { ticker: "SRVR", name: "PACER BENCHMARK DATA INFRA" },
  { ticker: "SSB", name: "SOUTH STATE CORP" },
  { ticker: "SSBI", name: "SUMMIT STATE BANK" },
  { ticker: "SSD", name: "SIMPSON MANUFACTURING CO INC" },
  { ticker: "SSG", name: "PROSHARES ULTSHRT SEMICONDUC" },
  { ticker: "SSI", name: "STAGE STORES INC" },
  { ticker: "SSKN", name: "STRATA SKIN SCIENCES INC" },
  { ticker: "SSL", name: "SASOL LTD-SPONSORED ADR" },
  { ticker: "SSNC", name: "SS&C TECHNOLOGIES HOLDINGS" },
  { ticker: "SSNT", name: "SILVERSUN TECHNOLOGIES INC" },
  { ticker: "SSO", name: "PROSHARES ULTRA S&P500" },
  { ticker: "SSP", name: "EW SCRIPPS CO/THE-A" },
  { ticker: "SSPK", name: "SILVER SPIKE ACQUISITION-A" },
  { ticker: "SSPKU", name: "SILVER SPIKE ACQUISITION COR" },
  { ticker: "SSPY", name: "SYNTAX STRATIFIED LARGE CAP" },
  { ticker: "SSRM", name: "SSR MINING INC" },
  { ticker: "SSSS", name: "SUTTER ROCK CAPITAL CORP" },
  { ticker: "SSTI", name: "SHOTSPOTTER INC" },
  { ticker: "SSTK", name: "SHUTTERSTOCK INC" },
  { ticker: "SSUS", name: "DAY HAGAN/NED DAVIS RESEARCH" },
  { ticker: "SSY", name: "SUNLINK HEALTH SYSTEMS INC" },
  { ticker: "SSYS", name: "STRATASYS LTD" },
  { ticker: "ST", name: "SENSATA TECHNOLOGIES HOLDING" },
  { ticker: "STAA", name: "STAAR SURGICAL CO" },
  { ticker: "STAF", name: "STAFFING 360 SOLUTIONS INC" },
  { ticker: "STAG", name: "STAG INDUSTRIAL INC" },
  { ticker: "STAR", name: "ISTAR INC" },
  { ticker: "STAY", name: "EXTENDED STAY AMERICA INC" },
  { ticker: "STBA", name: "S & T BANCORP INC" },
  { ticker: "STC", name: "STEWART INFORMATION SERVICES" },
  { ticker: "STCN", name: "STEEL CONNECT INC" },
  { ticker: "STE", name: "STERIS PLC" },
  { ticker: "STFC", name: "STATE AUTO FINANCIAL CORP" },
  { ticker: "STG", name: "SUNLANDS TECHNOLOGY GROUP" },
  { ticker: "STIM", name: "NEURONETICS INC" },
  { ticker: "STIP", name: "ISHARES 0-5 YEAR TIPS BOND E" },
  { ticker: "STK", name: "COLUMBIA SELIG PREM TECH GW" },
  { ticker: "STKL", name: "SUNOPTA INC" },
  { ticker: "STKS", name: "ONE GROUP HOSPITALITY INC/TH" },
  { ticker: "STL", name: "STERLING BANCORP/DE" },
  { ticker: "STLD", name: "STEEL DYNAMICS INC" },
  { ticker: "STLG", name: "ISHARES FACTORS US GWTH STYL" },
  { ticker: "STLV", name: "ISHARES FACTORS US VAL STYLE" },
  { ticker: "STM", name: "STMICROELECTRONICS NV-NY SHS" },
  { ticker: "STML", name: "STEMLINE THERAPEUTICS INC" },
  { ticker: "STMP", name: "STAMPS.COM INC" },
  { ticker: "STN", name: "STANTEC INC" },
  { ticker: "STND", name: "STANDARD AVB FINANCIAL CORP" },
  { ticker: "STNE", name: "STONECO LTD-A" },
  { ticker: "STNG", name: "SCORPIO TANKERS INC" },
  { ticker: "STOK", name: "STOKE THERAPEUTICS INC" },
  { ticker: "STON", name: "STONEMOR INC" },
  { ticker: "STOR", name: "STORE CAPITAL CORP" },
  { ticker: "STOT", name: "SPDR DL SHRT DURATION TR ETF" },
  { ticker: "STPP", name: "IPATH US TREASURY STEEPENER" },
  { ticker: "STPZ", name: "PIMCO 1-5 YEAR US TIPS INDEX" },
  { ticker: "STRA", name: "STRATEGIC EDUCATION INC" },
  { ticker: "STRL", name: "STERLING CONSTRUCTION CO" },
  { ticker: "STRM", name: "STREAMLINE HEALTH SOLUTIONS" },
  { ticker: "STRO", name: "SUTRO BIOPHARMA INC" },
  { ticker: "STRS", name: "STRATUS PROPERTIES INC" },
  { ticker: "STRT", name: "STRATTEC SECURITY CORP" },
  { ticker: "STSA", name: "SATSUMA PHARMACEUTICALS INC" },
  { ticker: "STT", name: "STATE STREET CORP" },
  { ticker: "STWD", name: "STARWOOD PROPERTY TRUST INC" },
  { ticker: "STX", name: "SEAGATE TECHNOLOGY" },
  { ticker: "STXB", name: "SPIRIT OF TEXAS BANCSHARES I" },
  { ticker: "STXS", name: "STEREOTAXIS INC" },
  { ticker: "STZ", name: "CONSTELLATION BRANDS INC-A" },
  { ticker: "STZ.B", name: "CONSTELLATION BRANDS INC-B" },
  { ticker: "SU", name: "SUNCOR ENERGY INC" },
  { ticker: "SUB", name: "ISHARES SHORT-TERM NATIONAL" },
  { ticker: "SUI", name: "SUN COMMUNITIES INC" },
  { ticker: "SUM", name: "SUMMIT MATERIALS INC -CL A" },
  { ticker: "SUMR", name: "SUMMER INFANT INC" },
  { ticker: "SUN", name: "SUNOCO LP" },
  { ticker: "SUNS", name: "SOLAR SENIOR CAPITAL LTD" },
  { ticker: "SUNW", name: "SUNWORKS INC" },
  { ticker: "SUP", name: "SUPERIOR INDUSTRIES INTL" },
  { ticker: "SUPN", name: "SUPERNUS PHARMACEUTICALS INC" },
  { ticker: "SUPV", name: "GRUPO SUPERVIELLE SA-SP ADR" },
  { ticker: "SURF", name: "SURFACE ONCOLOGY INC" },
  { ticker: "SUSA", name: "ISHARES MSCI USA ESG SELECT" },
  { ticker: "SUSB", name: "ISHARES ESG 1-5 YEAR USD COR" },
  { ticker: "SUSC", name: "ISHARES ESG USD CORPORATE BO" },
  { ticker: "SUSL", name: "ISHARES ESG MSCI USA LEADERS" },
  { ticker: "SUZ", name: "SUZANO SA - SPON ADR" },
  { ticker: "SVA", name: "SINOVAC BIOTECH LTD" },
  { ticker: "SVBI", name: "SEVERN BANCORP INC/MD" },
  { ticker: "SVC", name: "SERVICE PROPERTIES TRUST" },
  { ticker: "SVM", name: "SILVERCORP METALS INC" },
  { ticker: "SVMK", name: "SVMK INC" },
  { ticker: "SVRA", name: "SAVARA INC" },
  { ticker: "SVT", name: "SERVOTRONICS INC" },
  { ticker: "SVVC", name: "FIRSTHAND TECHNOLOGY VALUE" },
  { ticker: "SVXY", name: "PROSHARES SHORT VIX ST FUTUR" },
  { ticker: "SWAN", name: "AMPLIFY BLACKSWAN GROWTH TSY" },
  { ticker: "SWAV", name: "SHOCKWAVE MEDICAL INC" },
  { ticker: "SWCH", name: "SWITCH INC - A" },
  { ticker: "SWI", name: "SOLARWINDS CORP" },
  { ticker: "SWIR", name: "SIERRA WIRELESS INC" },
  { ticker: "SWK", name: "STANLEY BLACK & DECKER INC" },
  { ticker: "SWKH", name: "SWK HOLDINGS CORP" },
  { ticker: "SWKS", name: "SKYWORKS SOLUTIONS INC" },
  { ticker: "SWM", name: "SCHWEITZER-MAUDUIT INTL INC" },
  { ticker: "SWN", name: "SOUTHWESTERN ENERGY CO" },
  { ticker: "SWTX", name: "SPRINGWORKS THERAPEUTICS INC" },
  { ticker: "SWX", name: "SOUTHWEST GAS HOLDINGS INC" },
  { ticker: "SWZ", name: "SWISS HELVETIA FUND" },
  { ticker: "SXC", name: "SUNCOKE ENERGY INC" },
  { ticker: "SXI", name: "STANDEX INTERNATIONAL CORP" },
  { ticker: "SXT", name: "SENSIENT TECHNOLOGIES CORP" },
  { ticker: "SXTC", name: "CHINA SXT PHARMACEUTICALS IN" },
  { ticker: "SY", name: "SO-YOUNG INTERNATIONAL-ADR" },
  { ticker: "SYBT", name: "STOCK YARDS BANCORP INC" },
  { ticker: "SYBX", name: "SYNLOGIC INC" },
  { ticker: "SYE", name: "SPDR MFS SYSTEMATIC EQUITY" },
  { ticker: "SYF", name: "SYNCHRONY FINANCIAL" },
  { ticker: "SYG", name: "SPDR MFS SYSTEMATIC GR EQUIT" },
  { ticker: "SYK", name: "STRYKER CORP" },
  { ticker: "SYKE", name: "SYKES ENTERPRISES INC" },
  { ticker: "SYLD", name: "CAMBRIA SHAREHOLDER YIELD ET" },
  { ticker: "SYN", name: "SYNTHETIC BIOLOGICS INC" },
  { ticker: "SYNA", name: "SYNAPTICS INC" },
  { ticker: "SYNC", name: "SYNACOR INC" },
  { ticker: "SYNH", name: "SYNEOS HEALTH INC" },
  { ticker: "SYNL", name: "SYNALLOY CORP" },
  { ticker: "SYPR", name: "SYPRIS SOLUTIONS INC" },
  { ticker: "SYRS", name: "SYROS PHARMACEUTICALS INC" },
  { ticker: "SYV", name: "SPDR MFS SYSTEMATIC VALUE EQ" },
  { ticker: "SYX", name: "SYSTEMAX INC" },
  { ticker: "SYY", name: "SYSCO CORP" },
  { ticker: "SZC", name: "CUSHING RENAISSANCE FUND/THE" },
  { ticker: "SZK", name: "PROSHARES ULTSHRT CONS GOODS" },
  { ticker: "SZNE", name: "PACER CFRA STOVALL EQUAL" },
  { ticker: "T", name: "AT&T INC" },
  { ticker: "TA", name: "TRAVELCENTERS OF AMERICA INC" },
  { ticker: "TAC", name: "TRANSALTA CORP" },
  { ticker: "TACO", name: "DEL TACO RESTAURANTS INC" },
  { ticker: "TACT", name: "TRANSACT TECHNOLOGIES INC" },
  { ticker: "TAGS", name: "TEUCRIUM AGRICULTURAL FUND" },
  { ticker: "TAIL", name: "CAMBRIA TAIL RISK ETF" },
  { ticker: "TAIT", name: "TAITRON COMPONENTS INC-CL A" },
  { ticker: "TAK", name: "TAKEDA PHARMACEUTIC-SP ADR" },
  { ticker: "TAL", name: "TAL EDUCATION GROUP- ADR" },
  { ticker: "TALO", name: "TALOS ENERGY INC" },
  { ticker: "TAN", name: "INVESCO SOLAR ETF" },
  { ticker: "TANH", name: "TANTECH HOLDINGS LTD" },
  { ticker: "TAOP", name: "TAOPING INC" },
  { ticker: "TAP", name: "MOLSON COORS BEVERAGE CO - B" },
  { ticker: "TAP.A", name: "MOLSON COORS BEVERAGE CO - A" },
  { ticker: "TAPR", name: "BARCLAYS INVERSE US TREASURY" },
  { ticker: "TARA", name: "ARTARA THERAPEUTICS INC" },
  { ticker: "TARO", name: "TARO PHARMACEUTICAL INDUS" },
  { ticker: "TAST", name: "CARROLS RESTAURANT GROUP INC" },
  { ticker: "TAT", name: "TRANSATLANTIC PETROLEUM LTD" },
  { ticker: "TATT", name: "TAT TECHNOLOGIES LTD" },
  { ticker: "TAWK", name: "DIREXION DAILY COMM BULL 3X" },
  { ticker: "TAXF", name: "AMERICAN CENTURY DIVER MUNI" },
  { ticker: "TAYD", name: "TAYLOR DEVICES INC" },
  { ticker: "TBBK", name: "BANCORP INC/THE" },
  { ticker: "TBF", name: "PROSHARES SHORT 20+ TREASURY" },
  { ticker: "TBI", name: "TRUEBLUE INC" },
  { ticker: "TBIO", name: "TRANSLATE BIO INC" },
  { ticker: "TBK", name: "TRIUMPH BANCORP INC" },
  { ticker: "TBLT", name: "TOUGHBUILT INDUSTRIES INC" },
  { ticker: "TBLU", name: "TORTOISE GLOBAL WATER ESG FU" },
  { ticker: "TBND", name: "TACTICAL INCOME ETF" },
  { ticker: "TBNK", name: "TERRITORIAL BANCORP INC" },
  { ticker: "TBPH", name: "THERAVANCE BIOPHARMA INC" },
  { ticker: "TBT", name: "PROSHARES ULTRASHORT 20+Y TR" },
  { ticker: "TBX", name: "PROSHARES SHORT 7-10 YR TSY" },
  { ticker: "TC", name: "TUANCHE LTD-ADR" },
  { ticker: "TCBI", name: "TEXAS CAPITAL BANCSHARES INC" },
  { ticker: "TCBK", name: "TRICO BANCSHARES" },
  { ticker: "TCCO", name: "TECHNICAL COMMUNICATIONS" },
  { ticker: "TCDA", name: "TRICIDA INC" },
  { ticker: "TCF", name: "TCF FINANCIAL CORP" },
  { ticker: "TCFC", name: "COMMUNITY FINANCIAL CORP/THE" },
  { ticker: "TCI", name: "TRANSCONTINENTAL REALTY INV" },
  { ticker: "TCMD", name: "TACTILE SYSTEMS TECHNOLOGY I" },
  { ticker: "TCO", name: "TAUBMAN CENTERS INC" },
  { ticker: "TCOM", name: "TRIP.COM GROUP LTD-ADR" },
  { ticker: "TCON", name: "TRACON PHARMACEUTICALS INC" },
  { ticker: "TCP", name: "TC PIPELINES LP" },
  { ticker: "TCPC", name: "BLACKROCK TCP CAPITAL CORP" },
  { ticker: "TCRD", name: "THL CREDIT INC" },
  { ticker: "TCRR", name: "TCR2 THERAPEUTICS INC" },
  { ticker: "TCS", name: "CONTAINER STORE GROUP INC/TH" },
  { ticker: "TCTL", name: "PREMISE CAPITAL DIVERSIFIED" },
  { ticker: "TCX", name: "TUCOWS INC-CLASS A" },
  { ticker: "TD", name: "TORONTO-DOMINION BANK" },
  { ticker: "TDAC", name: "TRIDENT ACQUISITIONS CORP" },
  { ticker: "TDACU", name: "TRIDENT ACQUISITIONS CORP" },
  { ticker: "TDC", name: "TERADATA CORP" },
  { ticker: "TDF", name: "TEMPLETON DRAGON FUND INC" },
  { ticker: "TDG", name: "TRANSDIGM GROUP INC" },
  { ticker: "TDIV", name: "FT NASDAQ TECH DVD INDEX FD" },
  { ticker: "TDOC", name: "TELADOC HEALTH INC" },
  { ticker: "TDS", name: "TELEPHONE AND DATA SYSTEMS" },
  { ticker: "TDTF", name: "FLEXSHARES IBOXX 5-YEAR TARG" },
  { ticker: "TDTT", name: "FLEXSHARES IBOXX 3-YEAR TARG" },
  { ticker: "TDV", name: "PROSHARES S&P TECH DVD ARIST" },
  { ticker: "TDW", name: "TIDEWATER INC" },
  { ticker: "TDY", name: "TELEDYNE TECHNOLOGIES INC" },
  { ticker: "TEAF", name: "TORTOISE ESSENT ASSTS INC" },
  { ticker: "TEAM", name: "ATLASSIAN CORP PLC-CLASS A" },
  { ticker: "TECB", name: "ISHARES US TECH BREAKTHROUGH" },
  { ticker: "TECD", name: "TECH DATA CORP" },
  { ticker: "TECH", name: "BIO-TECHNE CORP" },
  { ticker: "TECK", name: "TECK RESOURCES LTD-CLS B" },
  { ticker: "TECL", name: "DIREXION DAILY TECH BULL 3X" },
  { ticker: "TECS", name: "DIREXION DAILY TECH BEAR 3X" },
  { ticker: "TEDU", name: "TARENA INTERNATIONAL INC-ADR" },
  { ticker: "TEF", name: "TELEFONICA SA-SPON ADR" },
  { ticker: "TEI", name: "TEMPLETON EMERG MKTS INC FD" },
  { ticker: "TEL", name: "TE CONNECTIVITY LTD" },
  { ticker: "TELA", name: "TELA BIO INC" },
  { ticker: "TELL", name: "TELLURIAN INC" },
  { ticker: "TEN", name: "TENNECO INC-CLASS A" },
  { ticker: "TENB", name: "TENABLE HOLDINGS INC" },
  { ticker: "TENX", name: "TENAX THERAPEUTICS INC" },
  { ticker: "TEO", name: "TELECOM ARGENTINA SA-SP ADR" },
  { ticker: "TER", name: "TERADYNE INC" },
  { ticker: "TERM", name: "EQUITYCOMP TCTL RISK M ETF" },
  { ticker: "TERP", name: "TERRAFORM POWER INC - A" },
  { ticker: "TESS", name: "TESSCO TECHNOLOGIES INC" },
  { ticker: "TEUM", name: "PARETEUM CORP" },
  { ticker: "TEVA", name: "TEVA PHARMACEUTICAL-SP ADR" },
  { ticker: "TEX", name: "TEREX CORP" },
  { ticker: "TFC", name: "TRUIST FINANCIAL CORP" },
  { ticker: "TFFP", name: "TFF PHARMACEUTICALS INC" },
  { ticker: "TFI", name: "SPDR NUV BBG BARC MUNI BOND" },
  { ticker: "TFII", name: "TFI INTERNATIONAL INC" },
  { ticker: "TFIV", name: "GLOBAL X TARGETINCOME 5 ETF" },
  { ticker: "TFLO", name: "ISHARES TREASURY FLOATING RA" },
  { ticker: "TFLT", name: "GLOBAL X TARGETINCOME PLUS 2" },
  { ticker: "TFSL", name: "TFS FINANCIAL CORP" },
  { ticker: "TFX", name: "TELEFLEX INC" },
  { ticker: "TG", name: "TREDEGAR CORP" },
  { ticker: "TGA", name: "TRANSGLOBE ENERGY CORP" },
  { ticker: "TGB", name: "TASEKO MINES LTD" },
  { ticker: "TGC", name: "TENGASCO INC" },
  { ticker: "TGE", name: "TALLGRASS ENERGY LP-CLASS A" },
  { ticker: "TGEN", name: "TECOGEN INC/WALTHAM MA" },
  { ticker: "TGH", name: "TEXTAINER GROUP HOLDINGS LTD" },
  { ticker: "TGI", name: "TRIUMPH GROUP INC" },
  { ticker: "TGLS", name: "TECNOGLASS INC" },
  { ticker: "TGNA", name: "TEGNA INC" },
  { ticker: "TGP", name: "TEEKAY LNG PARTNERS LP" },
  { ticker: "TGS", name: "TRANSPORTADOR GAS SUR-SP B" },
  { ticker: "TGT", name: "TARGET CORP" },
  { ticker: "TGTX", name: "TG THERAPEUTICS INC" },
  { ticker: "TH", name: "TARGET HOSPITALITY CORP" },
  { ticker: "THBR", name: "THUNDER BRIDGE ACQUISITION-A" },
  { ticker: "THBRU", name: "THUNDER BRIDGE ACQUISITION I" },
  { ticker: "THC", name: "TENET HEALTHCARE CORP" },
  { ticker: "THCA", name: "TUSCAN HOLDINGS CORP II" },
  { ticker: "THCAU", name: "TUSCAN HOLDINGS CORP II" },
  { ticker: "THCB", name: "TUSCAN HOLDINGS CORP" },
  { ticker: "THCBU", name: "TUSCAN HOLDINGS CORP" },
  { ticker: "THCX", name: "THE CANNABIS ETF" },
  { ticker: "THD", name: "ISHARES MSCI THAILAND ETF" },
  { ticker: "THFF", name: "FIRST FINANCIAL CORP/INDIANA" },
  { ticker: "THG", name: "HANOVER INSURANCE GROUP INC/" },
  { ticker: "THM", name: "INTL TOWER HILL MINES LTD" },
  { ticker: "THMO", name: "THERMOGENESIS HOLDINGS INC" },
  { ticker: "THO", name: "THOR INDUSTRIES INC" },
  { ticker: "THQ", name: "TEKLA HEALTHCARE OPPORTUNITI" },
  { ticker: "THR", name: "THERMON GROUP HOLDINGS INC" },
  { ticker: "THRM", name: "GENTHERM INC" },
  { ticker: "THS", name: "TREEHOUSE FOODS INC" },
  { ticker: "THTX", name: "THERATECHNOLOGIES INC" },
  { ticker: "THW", name: "TEKLA WORLD HEALTHCARE FUND" },
  { ticker: "TIBR", name: "TIBERIUS ACQUISITION CORP" },
  { ticker: "TIBRU", name: "TIBERIUS ACQUISITION CORP" },
  { ticker: "TIF", name: "TIFFANY & CO" },
  { ticker: "TIGO", name: "MILLICOM INTL CELLULAR S.A." },
  { ticker: "TIGR", name: "UP FINTECH HOLDING LTD - ADR" },
  { ticker: "TILE", name: "INTERFACE INC" },
  { ticker: "TILT", name: "FLEXSHARES MORNINGSTAR US MA" },
  { ticker: "TIP", name: "ISHARES TIPS BOND ETF" },
  { ticker: "TIPT", name: "TIPTREE INC" },
  { ticker: "TIPX", name: "SPDR BBG BARC 1-10YR TIPS" },
  { ticker: "TIPZ", name: "PIMCO BROAD US TIPS INDEX" },
  { ticker: "TISI", name: "TEAM INC" },
  { ticker: "TITN", name: "TITAN MACHINERY INC" },
  { ticker: "TIVO", name: "TIVO CORP" },
  { ticker: "TJX", name: "TJX COMPANIES INC" },
  { ticker: "TK", name: "TEEKAY CORP" },
  { ticker: "TKAT", name: "TAKUNG ART CO LTD" },
  { ticker: "TKC", name: "TURKCELL ILETISIM HIZMET-ADR" },
  { ticker: "TKR", name: "TIMKEN CO" },
  { ticker: "TLC", name: "TAIWAN LIPOSOME CO LTD-ADR" },
  { ticker: "TLDH", name: "FLEXSHARES CURRENCY HEDGED M" },
  { ticker: "TLEH", name: "FLEXSHARES CURRENCY HEDGED M" },
  { ticker: "TLF", name: "TANDY LEATHER FACTORY INC" },
  { ticker: "TLGT", name: "TELIGENT INC" },
  { ticker: "TLH", name: "ISHARES 10-20 YEAR TREASURY" },
  { ticker: "TLI", name: "WESTERN ASSET CORPORATE LOAN" },
  { ticker: "TLK", name: "TELEKOMUNIK INDONESIA-SP ADR" },
  { ticker: "TLND", name: "TALEND SA  - ADR" },
  { ticker: "TLRA", name: "TELARIA INC" },
  { ticker: "TLRD", name: "TAILORED BRANDS INC" },
  { ticker: "TLRY", name: "TILRAY INC-CLASS 2 COMMON" },
  { ticker: "TLSA", name: "TIZIANA LIFE SCIENCES P-ADR" },
  { ticker: "TLT", name: "ISHARES 20+ YEAR TREASURY BO" },
  { ticker: "TLTD", name: "FLEXSHARES MORNINGSTAR DEVEL" },
  { ticker: "TLTE", name: "FLEXSHARES MORNINGSTAR EMERG" },
  { ticker: "TLYS", name: "TILLY'S INC-CLASS A SHRS" },
  { ticker: "TM", name: "TOYOTA MOTOR CORP -SPON ADR" },
  { ticker: "TMDI", name: "TITAN MEDICAL INC" },
  { ticker: "TMDV", name: "PROSHARES RUSSELL US DVD GRW" },
  { ticker: "TMDX", name: "TRANSMEDICS GROUP INC" },
  { ticker: "TME", name: "TENCENT MUSIC ENTERTAINM-ADR" },
  { ticker: "TMF", name: "DRX DLY 20+ YR TREAS BULL 3X" },
  { ticker: "TMFC", name: "MOTLEY FOOL 100 INDEX ETF" },
  { ticker: "TMHC", name: "TAYLOR MORRISON HOME CORP" },
  { ticker: "TMO", name: "THERMO FISHER SCIENTIFIC INC" },
  { ticker: "TMP", name: "TOMPKINS FINANCIAL CORP" },
  { ticker: "TMQ", name: "TRILOGY METALS INC" },
  { ticker: "TMSR", name: "TMSR HOLDING CO LTD" },
  { ticker: "TMST", name: "TIMKENSTEEL CORP" },
  { ticker: "TMUS", name: "T-MOBILE US INC" },
  { ticker: "TMV", name: "DRX DLY 20+ YR TREAS BEAR 3X" },
  { ticker: "TNA", name: "DIREXION DLY SM CAP BULL 3X" },
  { ticker: "TNAV", name: "TELENAV INC" },
  { ticker: "TNC", name: "TENNANT CO" },
  { ticker: "TNDM", name: "TANDEM DIABETES CARE INC" },
  { ticker: "TNET", name: "TRINET GROUP INC" },
  { ticker: "TNK", name: "TEEKAY TANKERS LTD-CLASS A" },
  { ticker: "TNP", name: "TSAKOS ENERGY NAVIGATION LTD" },
  { ticker: "TNXP", name: "TONIX PHARMACEUTICALS HOLDIN" },
  { ticker: "TOCA", name: "TOCAGEN INC" },
  { ticker: "TOK", name: "ISHARES MSCI KOKUSAI ETF" },
  { ticker: "TOKE", name: "CAMBRIA CANNABIS ETF" },
  { ticker: "TOL", name: "TOLL BROTHERS INC" },
  { ticker: "TOLZ", name: "PROSHARES GLB INFRASTRUCTURE" },
  { ticker: "TOPS", name: "TOP SHIPS INC" },
  { ticker: "TORC", name: "RESTORBIO INC" },
  { ticker: "TOT", name: "TOTAL SA-SPON ADR" },
  { ticker: "TOTA", name: "TOTTENHAM ACQUISITION I LTD" },
  { ticker: "TOTAR", name: "TENHAM ACQUISITION I - RTS" },
  { ticker: "TOTAU", name: "TOTTENHAM ACQUISITION I LTD" },
  { ticker: "TOTL", name: "SPDR DOUBLELINE TR TACT ETF" },
  { ticker: "TOUR", name: "TUNIU CORP-SPON ADR" },
  { ticker: "TOWN", name: "TOWNE BANK" },
  { ticker: "TPAY", name: "TORTOISE DIGITAL PAYMENTS IN" },
  { ticker: "TPB", name: "TURNING POINT BRANDS INC" },
  { ticker: "TPC", name: "TUTOR PERINI CORP" },
  { ticker: "TPCO", name: "TRIBUNE PUBLISHING CO" },
  { ticker: "TPH", name: "TRI POINTE GROUP INC" },
  { ticker: "TPHD", name: "TIMOTHY PLAN HIGH DVD STOCK" },
  { ticker: "TPHS", name: "TRINITY PLACE HOLDINGS INC" },
  { ticker: "TPIC", name: "TPI COMPOSITES INC" },
  { ticker: "TPIF", name: "TIMOTHY PLAN INTERNATIONAL E" },
  { ticker: "TPL", name: "TEXAS PACIFIC LAND TRUST" },
  { ticker: "TPLC", name: "TIMOTHY PLAN US LARGE CAP CO" },
  { ticker: "TPOR", name: "DRX DLY TRANSPORTS BULL 3X" },
  { ticker: "TPR", name: "TAPESTRY INC" },
  { ticker: "TPRE", name: "THIRD POINT REINSURANCE LTD" },
  { ticker: "TPSC", name: "TIMOTHY PLAN US SMALL CAP CO" },
  { ticker: "TPTX", name: "TURNING POINT THERAPEUTICS I" },
  { ticker: "TPVG", name: "TRIPLEPOINT VENTURE GROWTH B" },
  { ticker: "TPX", name: "TEMPUR SEALY INTERNATIONAL I" },
  { ticker: "TPYP", name: "TORTOISE NORTH AMERICAN PIPE" },
  { ticker: "TPZ", name: "TORTOISE POWER & ENRGY INFRA" },
  { ticker: "TQQQ", name: "PROSHARES ULTRAPRO QQQ" },
  { ticker: "TR", name: "TOOTSIE ROLL INDS" },
  { ticker: "TRC", name: "TEJON RANCH CO" },
  { ticker: "TRCH", name: "TORCHLIGHT ENERGY RESOURCES" },
  { ticker: "TREC", name: "TRECORA RESOURCES" },
  { ticker: "TREE", name: "LENDINGTREE INC" },
  { ticker: "TREX", name: "TREX COMPANY INC" },
  { ticker: "TRGP", name: "TARGA RESOURCES CORP" },
  { ticker: "TRHC", name: "TABULA RASA HEALTHCARE INC" },
  { ticker: "TRI", name: "THOMSON REUTERS CORP" },
  { ticker: "TRIB", name: "TRINITY BIOTECH PLC-SPON ADR" },
  { ticker: "TRIL", name: "TRILLIUM THERAPEUTICS INC" },
  { ticker: "TRIP", name: "TRIPADVISOR INC" },
  { ticker: "TRMB", name: "TRIMBLE INC" },
  { ticker: "TRMD", name: "TORM PLC" },
  { ticker: "TRMK", name: "TRUSTMARK CORP" },
  { ticker: "TRMT", name: "TREMONT MORTGAGE TRUST" },
  { ticker: "TRN", name: "TRINITY INDUSTRIES INC" },
  { ticker: "TRND", name: "PACER TRENDPILOT FUNDS OF FD" },
  { ticker: "TRNE", name: "TRINE ACQUISITION CORP-A" },
  { ticker: "TRNE=", name: "TRINE ACQUISITION CORP" },
  { ticker: "TRNO", name: "TERRENO REALTY CORP" },
  { ticker: "TRNS", name: "TRANSCAT INC" },
  { ticker: "TRNX", name: "TARONIS TECHNOLOGIES INC" },
  { ticker: "TROV", name: "TROVAGENE INC" },
  { ticker: "TROW", name: "T ROWE PRICE GROUP INC" },
  { ticker: "TROX", name: "TRONOX HOLDINGS PLC- A" },
  { ticker: "TRP", name: "TC ENERGY CORP" },
  { ticker: "TRPX", name: "THERAPIX BIOSCIENCES LTD-ADR" },
  { ticker: "TRQ", name: "TURQUOISE HILL RESOURCES LTD" },
  { ticker: "TRS", name: "TRIMAS CORP" },
  { ticker: "TRST", name: "TRUSTCO BANK CORP NY" },
  { ticker: "TRT", name: "TRIO-TECH INTERNATIONAL" },
  { ticker: "TRTN", name: "TRITON INTERNATIONAL LTD/BER" },
  { ticker: "TRTX", name: "TPG RE FINANCE TRUST INC" },
  { ticker: "TRTY", name: "CAMBRIA TRINITY ETF" },
  { ticker: "TRU", name: "TRANSUNION" },
  { ticker: "TRUE", name: "TRUECAR INC" },
  { ticker: "TRUP", name: "TRUPANION INC" },
  { ticker: "TRV", name: "TRAVELERS COS INC/THE" },
  { ticker: "TRVG", name: "TRIVAGO NV - ADR" },
  { ticker: "TRVI", name: "TREVI THERAPEUTICS INC" },
  { ticker: "TRVN", name: "TREVENA INC" },
  { ticker: "TRWH", name: "TWIN RIVER WORLDWIDE HOLDING" },
  { ticker: "TRX", name: "TANZANIAN GOLD CORP" },
  { ticker: "TRXC", name: "TRANSENTERIX INC" },
  { ticker: "TS", name: "TENARIS SA-ADR" },
  { ticker: "TSBK", name: "TIMBERLAND BANCORP INC" },
  { ticker: "TSC", name: "TRISTATE CAPITAL HLDGS INC" },
  { ticker: "TSCO", name: "TRACTOR SUPPLY COMPANY" },
  { ticker: "TSE", name: "TRINSEO SA" },
  { ticker: "TSEM", name: "TOWER SEMICONDUCTOR LTD" },
  { ticker: "TSG", name: "STARS GROUP INC/THE" },
  { ticker: "TSI", name: "TCW STRATEGIC INCOME FUND IN" },
  { ticker: "TSLA", name: "TESLA INC" },
  { ticker: "TSLF", name: "THL CREDIT SENIOR LOAN FUND" },
  { ticker: "TSLX", name: "TPG SPECIALTY LENDING INC" },
  { ticker: "TSM", name: "TAIWAN SEMICONDUCTOR-SP ADR" },
  { ticker: "TSN", name: "TYSON FOODS INC-CL A" },
  { ticker: "TSQ", name: "TOWNSQUARE MEDIA INC - CL A" },
  { ticker: "TSRI", name: "TSR INC" },
  { ticker: "TSU", name: "TIM PARTICIPACOES SA-ADR" },
  { ticker: "TT", name: "TRANE TECHNOLOGIES PLC" },
  { ticker: "TTAC", name: "TRIMTABS ALL CAP US FREE-CAS" },
  { ticker: "TTAI", name: "TRIMTABS ALL CAP INT CSH FLO" },
  { ticker: "TTC", name: "TORO CO" },
  { ticker: "TTD", name: "TRADE DESK INC/THE -CLASS A" },
  { ticker: "TTEC", name: "TTEC HOLDINGS INC" },
  { ticker: "TTEK", name: "TETRA TECH INC" },
  { ticker: "TTGT", name: "TECHTARGET" },
  { ticker: "TTI", name: "TETRA TECHNOLOGIES INC" },
  { ticker: "TTM", name: "TATA MOTORS LTD-SPON ADR" },
  { ticker: "TTMI", name: "TTM TECHNOLOGIES" },
  { ticker: "TTNP", name: "TITAN PHARMACEUTICALS INC" },
  { ticker: "TTOO", name: "T2 BIOSYSTEMS INC" },
  { ticker: "TTP", name: "TORTOISE PIPELINE & ENERGY" },
  { ticker: "TTPH", name: "TETRAPHASE PHARMACEUTICALS I" },
  { ticker: "TTT", name: "PROSHARES ULT -3X 20+ YR TSY" },
  { ticker: "TTTN", name: "UP FINTECH CHINA-US INTERNET" },
  { ticker: "TTWO", name: "TAKE-TWO INTERACTIVE SOFTWRE" },
  { ticker: "TU", name: "TELUS CORP" },
  { ticker: "TUES", name: "TUESDAY MORNING CORP" },
  { ticker: "TUFN", name: "TUFIN SOFTWARE TECHNOLOGIES" },
  { ticker: "TUP", name: "TUPPERWARE BRANDS CORP" },
  { ticker: "TUR", name: "ISHARES MSCI TURKEY ETF" },
  { ticker: "TURN", name: "180 DEGREE CAPITAL CORP" },
  { ticker: "TUSA", name: "FT TOTAL US MARKET ALPHADEX" },
  { ticker: "TUSK", name: "MAMMOTH ENERGY SERVICES INC" },
  { ticker: "TV", name: "GRUPO TELEVISA SA-SPON ADR" },
  { ticker: "TVIX", name: "VELOCITYSHARES 2X VIX SH-TRM" },
  { ticker: "TVTY", name: "TIVITY HEALTH INC" },
  { ticker: "TW", name: "TRADEWEB MARKETS INC-CLASS A" },
  { ticker: "TWI", name: "TITAN INTERNATIONAL INC" },
  { ticker: "TWIN", name: "TWIN DISC INC" },
  { ticker: "TWLO", name: "TWILIO INC - A" },
  { ticker: "TWM", name: "PROSHARES ULTRASHORT R2000" },
  { ticker: "TWMC", name: "TRANS WORLD ENTERTAINMENT CP" },
  { ticker: "TWN", name: "TAIWAN FUND INC" },
  { ticker: "TWNK", name: "HOSTESS BRANDS INC" },
  { ticker: "TWO", name: "TWO HARBORS INVESTMENT CORP" },
  { ticker: "TWOU", name: "2U INC" },
  { ticker: "TWST", name: "TWIST BIOSCIENCE CORP" },
  { ticker: "TWTR", name: "TWITTER INC" },
  { ticker: "TX", name: "TERNIUM SA-SPONSORED ADR" },
  { ticker: "TXG", name: "10X GENOMICS INC-CLASS A" },
  { ticker: "TXMD", name: "THERAPEUTICSMD INC" },
  { ticker: "TXN", name: "TEXAS INSTRUMENTS INC" },
  { ticker: "TXRH", name: "TEXAS ROADHOUSE INC" },
  { ticker: "TXT", name: "TEXTRON INC" },
  { ticker: "TY", name: "TRI-CONTINENTAL CORP" },
  { ticker: "TYBS", name: "DRX DLY 20+ YR TREAS BEAR 1X" },
  { ticker: "TYD", name: "DRX DLY 7-10 Y TREAS BULL 3X" },
  { ticker: "TYG", name: "TORTOISE ENERGY INFRASTRUCT" },
  { ticker: "TYHT", name: "SHINECO INC" },
  { ticker: "TYL", name: "TYLER TECHNOLOGIES INC" },
  { ticker: "TYME", name: "TYME TECHNOLOGIES INC" },
  { ticker: "TYO", name: "DRX DLY 7-10 Y TREAS BEAR 3X" },
  { ticker: "TZA", name: "DIREXION DLY SM CAP BEAR 3X" },
  { ticker: "TZAC", name: "TENZING ACQUISITION CORP" },
  { ticker: "TZACU", name: "TENZING ACQUISITION CORP" },
  { ticker: "TZOO", name: "TRAVELZOO" },
  { ticker: "UA", name: "UNDER ARMOUR INC-CLASS C" },
  { ticker: "UAA", name: "UNDER ARMOUR INC-CLASS A" },
  { ticker: "UAE", name: "ISHARES MSCI UAE ETF" },
  { ticker: "UAG", name: "ETRACS CMCI AGRICULTURE TR" },
  { ticker: "UAL", name: "UNITED AIRLINES HOLDINGS INC" },
  { ticker: "UAMY", name: "UNITED STATES ANTIMONY CORP" },
  { ticker: "UAN", name: "CVR PARTNERS LP" },
  { ticker: "UAPR", name: "INNOVATOR SP 5OO ULTRA BUFFE" },
  { ticker: "UAUD", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "UAUG", name: "INNOVATOR SP500 ULTR BUF-AUG" },
  { ticker: "UAVS", name: "AGEAGLE AERIAL SYSTEMS INC" },
  { ticker: "UBA", name: "URSTADT BIDDLE - CLASS A" },
  { ticker: "UBCP", name: "UNITED BANCORP INC/OHIO" },
  { ticker: "UBER", name: "UBER TECHNOLOGIES INC" },
  { ticker: "UBFO", name: "UNITED SECURITY BANCSHARE/CA" },
  { ticker: "UBG", name: "ETRACS CMCI GOLD TR ETN" },
  { ticker: "UBIO", name: "PROSHARES ULTRAPRO NASDAQ BI" },
  { ticker: "UBOH", name: "UNITED BANCSHARES INC/OHIO" },
  { ticker: "UBOT", name: "DRX DLY ROBO & A.I. BULL 3X" },
  { ticker: "UBP", name: "URSTADT BIDDLE PROPERTIES" },
  { ticker: "UBR", name: "PROSHARES ULTRA MSCI BRAZIL" },
  { ticker: "UBS", name: "UBS GROUP AG-REG" },
  { ticker: "UBSI", name: "UNITED BANKSHARES INC" },
  { ticker: "UBT", name: "PROSHARES ULTRA 20+ YEAR TSY" },
  { ticker: "UBX", name: "UNITY BIOTECHNOLOGY INC" },
  { ticker: "UCBI", name: "UNITED COMMUNITY BANKS/GA" },
  { ticker: "UCC", name: "PROSHARES ULTRA CONS SERVICE" },
  { ticker: "UCHF", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "UCI", name: "ETRACS CMCI TOTAL RETURN ETN" },
  { ticker: "UCIB", name: "ETRACS UBS BBG CONS MA SER B" },
  { ticker: "UCO", name: "PROSHARES ULTRA BLOOMBERG CR" },
  { ticker: "UCOM", name: "PROSHARES ULTRAPRO COMMUNICA" },
  { ticker: "UCON", name: "FIRST TRUST TCW UNCONSTRAINE" },
  { ticker: "UCTT", name: "ULTRA CLEAN HOLDINGS INC" },
  { ticker: "UDEC", name: "INNOVATOR S&P ULTRA BUFF-DEC" },
  { ticker: "UDN", name: "INVESCO DB US DOLLAR INDEX B" },
  { ticker: "UDOW", name: "PROSHARES ULTRAPRO DOW30" },
  { ticker: "UDR", name: "UDR INC" },
  { ticker: "UE", name: "URBAN EDGE PROPERTIES" },
  { ticker: "UEC", name: "URANIUM ENERGY CORP" },
  { ticker: "UEIC", name: "UNIVERSAL ELECTRONICS INC" },
  { ticker: "UEPS", name: "NET 1 UEPS TECHNOLOGIES INC" },
  { ticker: "UEUR", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "UEVM", name: "VICTORYSHARES USAA MSCI EMER" },
  { ticker: "UFAB", name: "UNIQUE FABRICATING INC" },
  { ticker: "UFCS", name: "UNITED FIRE GROUP INC" },
  { ticker: "UFEB", name: "INNOVATOR SP 500 ULTRA - FEB" },
  { ticker: "UFI", name: "UNIFI INC" },
  { ticker: "UFO", name: "PROCURE SPACE ETF" },
  { ticker: "UFPI", name: "UNIVERSAL FOREST PRODUCTS" },
  { ticker: "UFPT", name: "UFP TECHNOLOGIES INC" },
  { ticker: "UFS", name: "DOMTAR CORP" },
  { ticker: "UG", name: "UNITED GUARDIAN INC" },
  { ticker: "UGA", name: "UNITED STATES GAS FUND LP" },
  { ticker: "UGAZ", name: "VELOCITYSHARES 3X LG NAT GAS" },
  { ticker: "UGBP", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "UGE", name: "PROSHARES ULTRA CONSUM GOODS" },
  { ticker: "UGI", name: "UGI CORP" },
  { ticker: "UGL", name: "PROSHARES ULTRA GOLD" },
  { ticker: "UGLD", name: "VELOCITYSHARES 3X LONG GOLD" },
  { ticker: "UGP", name: "ULTRAPAR PARTICPAC-SPON ADR" },
  { ticker: "UHAL", name: "AMERCO" },
  { ticker: "UHS", name: "UNIVERSAL HEALTH SERVICES-B" },
  { ticker: "UHT", name: "UNIVERSAL HEALTH RLTY INCOME" },
  { ticker: "UI", name: "UBIQUITI INC" },
  { ticker: "UIHC", name: "UNITED INSURANCE HOLDINGS CO" },
  { ticker: "UIS", name: "UNISYS CORP" },
  { ticker: "UITB", name: "VICTORYSHARES USAA CORE INTE" },
  { ticker: "UIVM", name: "VICTORYSHARES USAA MSCI INTE" },
  { ticker: "UJAN", name: "INNOVATOR S&P 500 ULTRA-JAN" },
  { ticker: "UJB", name: "PROSHARES ULTRA HIGH YIELD" },
  { ticker: "UJPY", name: "VELOCITYSHARES DAILY 4X LONG" },
  { ticker: "UJUL", name: "INNOVATOR S&P 500 ULTRA BUFF" },
  { ticker: "UJUN", name: "INNOVATOR S&P 500 ULTRA JUNE" },
  { ticker: "UL", name: "UNILEVER PLC-SPONSORED ADR" },
  { ticker: "ULBI", name: "ULTRALIFE CORP" },
  { ticker: "ULBR", name: "VELOCITYSHARES LNG LIBOR ETN" },
  { ticker: "ULE", name: "PROSHARES ULTRA EURO" },
  { ticker: "ULH", name: "UNIVERSAL LOGISTICS HOLDINGS" },
  { ticker: "ULST", name: "SPDR ULTRA SHORT-TERM BOND" },
  { ticker: "ULTA", name: "ULTA BEAUTY INC" },
  { ticker: "ULTR", name: "IQ ULTRA SHORT DURATION ETF" },
  { ticker: "ULVM", name: "VICTORYSHARES USAA MSCI USA" },
  { ticker: "UMAR", name: "INNOVATOR S&P 500 ULTRA BUFF" },
  { ticker: "UMBF", name: "UMB FINANCIAL CORP" },
  { ticker: "UMC", name: "UNITED MICROELECTRON-SP ADR" },
  { ticker: "UMDD", name: "PROSHARES ULTRAPRO MIDCAP400" },
  { ticker: "UMH", name: "UMH PROPERTIES INC" },
  { ticker: "UMPQ", name: "UMPQUA HOLDINGS CORP" },
  { ticker: "UMRX", name: "UNUM THERAPEUTICS INC" },
  { ticker: "UN", name: "UNILEVER N V  -NY SHARES" },
  { ticker: "UNAM", name: "UNICO AMERICAN CORP" },
  { ticker: "UNB", name: "UNION BANKSHARES INC /VT" },
  { ticker: "UNF", name: "UNIFIRST CORP/MA" },
  { ticker: "UNFI", name: "UNITED NATURAL FOODS INC" },
  { ticker: "UNG", name: "US NATURAL GAS FUND LP" },
  { ticker: "UNH", name: "UNITEDHEALTH GROUP INC" },
  { ticker: "UNIT", name: "UNITI GROUP INC" },
  { ticker: "UNL", name: "UNITED STATES 12 MONTH NATUR" },
  { ticker: "UNM", name: "UNUM GROUP" },
  { ticker: "UNOV", name: "INNOVATOR S&P ULTRA BUFF-NOV" },
  { ticker: "UNP", name: "UNION PACIFIC CORP" },
  { ticker: "UNT", name: "UNIT CORP" },
  { ticker: "UNTY", name: "UNITY BANCORP INC" },
  { ticker: "UNVR", name: "UNIVAR SOLUTIONS INC" },
  { ticker: "UOCT", name: "INNOVATOR S&P 500 ULTRA OCTO" },
  { ticker: "UONE", name: "URBAN ONE INC" },
  { ticker: "UONEK", name: "URBAN ONE INC" },
  { ticker: "UPLD", name: "UPLAND SOFTWARE INC" },
  { ticker: "UPRO", name: "PROSHARES ULTRAPRO S&P 500" },
  { ticker: "UPS", name: "UNITED PARCEL SERVICE-CL B" },
  { ticker: "UPV", name: "PROSHARES ULTRA FTSE EUROPE" },
  { ticker: "UPW", name: "PROSHARES ULTRA UTILITIES" },
  { ticker: "UPWK", name: "UPWORK INC" },
  { ticker: "URA", name: "GLOBAL X URANIUM ETF" },
  { ticker: "URBN", name: "URBAN OUTFITTERS INC" },
  { ticker: "URE", name: "PROSHARES ULTRA REAL ESTATE" },
  { ticker: "URG", name: "UR-ENERGY INC" },
  { ticker: "URGN", name: "UROGEN PHARMA LTD" },
  { ticker: "URI", name: "UNITED RENTALS INC" },
  { ticker: "URNM", name: "NORTHSHORE GLOBAL URANIUM MI" },
  { ticker: "UROV", name: "UROVANT SCIENCES LTD" },
  { ticker: "URR", name: "MARKET VECTORS DBLE LNG EURO" },
  { ticker: "URTH", name: "ISHARES MSCI WORLD ETF" },
  { ticker: "URTY", name: "PROSHARES ULTRAPRO RUSS2000" },
  { ticker: "USA", name: "LIBERTY ALL STAR EQUITY FUND" },
  { ticker: "USAC", name: "USA COMPRESSION PARTNERS LP" },
  { ticker: "USAI", name: "PACER AMERICAN ENERGY INDEPE" },
  { ticker: "USAK", name: "USA TRUCK INC" },
  { ticker: "USAP", name: "UNIVERSAL STAINLESS & ALLOY" },
  { ticker: "USAS", name: "AMERICAS GOLD AND SILVER COR" },
  { ticker: "USAU", name: "US GOLD CORP" },
  { ticker: "USB", name: "US BANCORP" },
  { ticker: "USCI", name: "UNITED STATES COMMODITY INDE" },
  { ticker: "USCR", name: "US CONCRETE INC" },
  { ticker: "USD", name: "PROSHARES ULTRA SEMICONDUCT" },
  { ticker: "USDP", name: "USD PARTNERS LP" },
  { ticker: "USDU", name: "WISDOMTREE BBG USD BULLISH" },
  { ticker: "USDY", name: "PACIFIC GLOBAL US EQUITY INC" },
  { ticker: "USEG", name: "U S ENERGY CORP - WYOMING" },
  { ticker: "USEP", name: "INNOVATOR SP500 ULTR BUF-SEP" },
  { ticker: "USEQ", name: "INVESCO RUSSELL 1000 ENHANCE" },
  { ticker: "USFD", name: "US FOODS HOLDING CORP" },
  { ticker: "USFR", name: "WISDOMTREE FLOATING RATE TRE" },
  { ticker: "USHG", name: "AGFIQ DYNAMIC HEDGED US EQTY" },
  { ticker: "USHY", name: "ISHARES BROAD USD HIGH YIELD" },
  { ticker: "USI", name: "PRINCIPAL ULTRA-SHORT ACTIVE" },
  { ticker: "USIG", name: "ISHARES BROAD USD INVESTMENT" },
  { ticker: "USIO", name: "USIO INC" },
  { ticker: "USL", name: "UNITED STATES 12 MONTH OIL" },
  { ticker: "USLB", name: "INVESCO RUSSELL 1000 LOW BET" },
  { ticker: "USLM", name: "UNITED STATES LIME & MINERAL" },
  { ticker: "USLV", name: "VELOCITYSHARES 3X LNG SILVER" },
  { ticker: "USM", name: "US CELLULAR CORP" },
  { ticker: "USMC", name: "PRINCIPAL US MEGACAP MF ETF" },
  { ticker: "USMF", name: "WISDOMTREE US MULTIFACTOR FU" },
  { ticker: "USMV", name: "ISHARES EDGE MSCI MIN VOL US" },
  { ticker: "USNA", name: "USANA HEALTH SCIENCES INC" },
  { ticker: "USO", name: "UNITED STATES OIL FUND LP" },
  { ticker: "USOI", name: "X-LINKS CRUDE OIL COV CALL" },
  { ticker: "USPH", name: "U.S. PHYSICAL THERAPY INC" },
  { ticker: "USRT", name: "ISHARES CORE US REIT ETF" },
  { ticker: "USSG", name: "XTRACKERS MSCI USA ESG LDRS" },
  { ticker: "UST", name: "PROSHARES ULTRA 7-10 YEAR TR" },
  { ticker: "USTB", name: "VICTORYSHARES USAA CORE SHOR" },
  { ticker: "USV", name: "ETRACS CMCI SILVER TR ETN" },
  { ticker: "USVM", name: "VICTORYSHARES USAA MSCI USA" },
  { ticker: "USWS", name: "US WELL SERVICES INC" },
  { ticker: "USX", name: "US XPRESS ENTERPRISES INC -A" },
  { ticker: "UTES", name: "VIRTUS REAVES UTILITIES ETF" },
  { ticker: "UTF", name: "COHEN & STEERS INFRASTRUCTUR" },
  { ticker: "UTG", name: "REAVES UTILITY INCOME FUND" },
  { ticker: "UTHR", name: "UNITED THERAPEUTICS CORP" },
  { ticker: "UTI", name: "UNIVERSAL TECHNICAL INSTITUT" },
  { ticker: "UTL", name: "UNITIL CORP" },
  { ticker: "UTMD", name: "UTAH MEDICAL PRODUCTS INC" },
  { ticker: "UTRN", name: "VESPER US LARGE CAP SHORT TE" },
  { ticker: "UTSI", name: "UTSTARCOM HOLDINGS CORP" },
  { ticker: "UTSL", name: "DRX DLY UTILITIES BULL 3X" },
  { ticker: "UTX", name: "UNITED TECHNOLOGIES CORP" },
  { ticker: "UUP", name: "INVESCO DB US DOLLAR INDEX B" },
  { ticker: "UUU", name: "UNIVERSAL SECURITY INSTRUMNT" },
  { ticker: "UUUU", name: "ENERGY FUELS INC" },
  { ticker: "UVE", name: "UNIVERSAL INSURANCE HOLDINGS" },
  { ticker: "UVSP", name: "UNIVEST FINANCIAL CORP" },
  { ticker: "UVV", name: "UNIVERSAL CORP/VA" },
  { ticker: "UVXY", name: "PROSHARES ULTRA VIX ST FUTUR" },
  { ticker: "UWM", name: "PROSHARES ULTRA RUSSELL2000" },
  { ticker: "UWT", name: "VELOCITYSHARES 3X LONG CRUDE" },
  { ticker: "UXI", name: "PROSHARES ULTRA INDUSTRIALS" },
  { ticker: "UXIN", name: "UXIN LTD - ADR" },
  { ticker: "UYG", name: "PROSHARES ULTRA FINANCIALS" },
  { ticker: "UYM", name: "PROSHARES ULTRA BASIC MATERI" },
  { ticker: "V", name: "VISA INC-CLASS A SHARES" },
  { ticker: "VAC", name: "MARRIOTT VACATIONS WORLD" },
  { ticker: "VAL", name: "VALARIS PLC" },
  { ticker: "VALE", name: "VALE SA-SP ADR" },
  { ticker: "VALQ", name: "AMERICAN CENTURY STOXX US" },
  { ticker: "VALT", name: "ETFMG SIT ULTRA SHORT ETF" },
  { ticker: "VALU", name: "VALUE LINE INC" },
  { ticker: "VALX", name: "VALIDEA MARKET LEGENDS ETF" },
  { ticker: "VAM", name: "VIVALDI OPPORTUNITIES FUND" },
  { ticker: "VAMO", name: "CAMBRIA VALUE & MOMENTUM ETF" },
  { ticker: "VAPO", name: "VAPOTHERM INC" },
  { ticker: "VAR", name: "VARIAN MEDICAL SYSTEMS INC" },
  { ticker: "VAW", name: "VANGUARD MATERIALS ETF" },
  { ticker: "VB", name: "VANGUARD SMALL-CAP ETF" },
  { ticker: "VBF", name: "INVESCO BOND FUND" },
  { ticker: "VBFC", name: "VILLAGE BANK AND TRUST FINAN" },
  { ticker: "VBIV", name: "VBI VACCINES INC" },
  { ticker: "VBK", name: "VANGUARD SMALL-CAP GRWTH ETF" },
  { ticker: "VBLT", name: "VASCULAR BIOGENICS LTD" },
  { ticker: "VBND", name: "VIDENT CORE US BOND STRATEGY" },
  { ticker: "VBR", name: "VANGUARD SMALL-CAP VALUE ETF" },
  { ticker: "VBTX", name: "VERITEX HOLDINGS INC" },
  { ticker: "VC", name: "VISTEON CORP" },
  { ticker: "VCEL", name: "VERICEL CORP" },
  { ticker: "VCF", name: "DELAWARE INV CO MUNI INC FD" },
  { ticker: "VCIF", name: "VERTICAL CAPITAL INCOME" },
  { ticker: "VCIT", name: "VANGUARD INT-TERM CORPORATE" },
  { ticker: "VCLT", name: "VANGUARD LONG-TERM CORP BOND" },
  { ticker: "VCNX", name: "VACCINEX INC" },
  { ticker: "VCR", name: "VANGUARD CONSUMER DISCRE ETF" },
  { ticker: "VCRA", name: "VOCERA COMMUNICATIONS INC" },
  { ticker: "VCSH", name: "VANGUARD S/T CORP BOND ETF" },
  { ticker: "VCTR", name: "VICTORY CAPITAL HOLDING - A" },
  { ticker: "VCV", name: "INVESCO CA V M I" },
  { ticker: "VCYT", name: "VERACYTE INC" },
  { ticker: "VDC", name: "VANGUARD CONSUMER STAPLE ETF" },
  { ticker: "VDE", name: "VANGUARD ENERGY ETF" },
  { ticker: "VEA", name: "VANGUARD FTSE DEVELOPED ETF" },
  { ticker: "VEC", name: "VECTRUS INC" },
  { ticker: "VECO", name: "VEECO INSTRUMENTS INC" },
  { ticker: "VEDL", name: "VEDANTA LTD-ADR" },
  { ticker: "VEEV", name: "VEEVA SYSTEMS INC-CLASS A" },
  { ticker: "VEGA", name: "ADVSHRS STAR GBL BUY-WRITE" },
  { ticker: "VEGI", name: "ISHARES MSCI GLOBAL AGRICULT" },
  { ticker: "VEGN", name: "US VEGAN CLIMATE ETF" },
  { ticker: "VEL", name: "VELOCITY FINANCIAL INC" },
  { ticker: "VEON", name: "VEON LTD" },
  { ticker: "VER", name: "VEREIT INC" },
  { ticker: "VERB", name: "VERB TECHNOLOGY CO INC" },
  { ticker: "VERI", name: "VERITONE INC" },
  { ticker: "VERO", name: "VENUS CONCEPT INC" },
  { ticker: "VERT=", name: "VERTIV HOLDINGS LLC" },
  { ticker: "VERU", name: "VERU INC" },
  { ticker: "VERY", name: "VERICITY INC" },
  { ticker: "VET", name: "VERMILION ENERGY INC" },
  { ticker: "VETS", name: "PACER MILITARY TIMES BES ETF" },
  { ticker: "VEU", name: "VANGUARD FTSE ALL-WORLD EX-U" },
  { ticker: "VFC", name: "VF CORP" },
  { ticker: "VFF", name: "VILLAGE FARMS INTERNATIONAL" },
  { ticker: "VFH", name: "VANGUARD FINANCIALS ETF" },
  { ticker: "VFL", name: "DELAWARE INV NATL MUNI INC F" },
  { ticker: "VFLQ", name: "VANGUARD US LIQUIDITY FACTOR" },
  { ticker: "VFMF", name: "VANGUARD US MULTIFACTOR ETF" },
  { ticker: "VFMO", name: "VANGUARD US MOMENTUM FACTOR" },
  { ticker: "VFMV", name: "VANGUARD US MINIMUM VOLATILI" },
  { ticker: "VFQY", name: "VANGUARD U.S. QUALITY FACTOR" },
  { ticker: "VFVA", name: "VANGUARD U.S. VALUE FACTOR" },
  { ticker: "VG", name: "VONAGE HOLDINGS CORP" },
  { ticker: "VGFO", name: "VIRTUS WMC GLOBAL FACTOR OPP" },
  { ticker: "VGI", name: "VIRTUS GLOBAL MULTI-SECTOR" },
  { ticker: "VGIT", name: "VANGUARD INTERMEDIATE-TERM T" },
  { ticker: "VGK", name: "VANGUARD FTSE EUROPE ETF" },
  { ticker: "VGLT", name: "VANGUARD LONG-TERM TREASURY" },
  { ticker: "VGM", name: "INVESCO TRUST FOR INVESTMEN" },
  { ticker: "VGR", name: "VECTOR GROUP LTD" },
  { ticker: "VGSH", name: "VANGUARD SHORT-TERM TREASURY" },
  { ticker: "VGT", name: "VANGUARD INFO TECH ETF" },
  { ticker: "VGZ", name: "VISTA GOLD CORP" },
  { ticker: "VHC", name: "VIRNETX HOLDING CORP" },
  { ticker: "VHI", name: "VALHI INC" },
  { ticker: "VHT", name: "VANGUARD HEALTH CARE ETF" },
  { ticker: "VIAC", name: "VIACOMCBS INC - CLASS B" },
  { ticker: "VIACA", name: "VIACOMCBS INC - CLASS A" },
  { ticker: "VIAV", name: "VIAVI SOLUTIONS INC" },
  { ticker: "VICI", name: "VICI PROPERTIES INC" },
  { ticker: "VICR", name: "VICOR CORP" },
  { ticker: "VIDI", name: "VIDENT INTERNATIONAL EQUITY" },
  { ticker: "VIE", name: "VIELA BIO INC" },
  { ticker: "VIG", name: "VANGUARD DIVIDEND APPREC ETF" },
  { ticker: "VIGI", name: "VANGUARD INT DIV APP INDX FD" },
  { ticker: "VIIX", name: "VELOCITYSHARES VIX SHORT-TRM" },
  { ticker: "VIOG", name: "VANGUARD S&P SMALL-CAP 600 G" },
  { ticker: "VIOO", name: "VANGUARD S&P SMALL-CAP 600 E" },
  { ticker: "VIOT", name: "VIOMI TECHNOLOGY CO LTD-ADR" },
  { ticker: "VIOV", name: "VANGUARD S&P SMALL-CAP 600 V" },
  { ticker: "VIPS", name: "VIPSHOP HOLDINGS LTD - ADR" },
  { ticker: "VIR", name: "VIR BIOTECHNOLOGY INC" },
  { ticker: "VIRC", name: "VIRCO MFG CORPORATION" },
  { ticker: "VIRT", name: "VIRTU FINANCIAL INC-CLASS A" },
  { ticker: "VIS", name: "VANGUARD INDUSTRIALS ETF" },
  { ticker: "VISL", name: "VISLINK TECHNOLOGIES INC" },
  { ticker: "VIST", name: "VISTA OIL & GAS SAB DE CV" },
  { ticker: "VIV", name: "TELEFONICA BRASIL-ADR PREF" },
  { ticker: "VIVE", name: "VIVEVE MEDICAL INC" },
  { ticker: "VIVO", name: "MERIDIAN BIOSCIENCE INC" },
  { ticker: "VIXM", name: "PROSHARES VIX MID-TERM FUT" },
  { ticker: "VIXY", name: "PROSHARES VIX SHORT-TERM FUT" },
  { ticker: "VJET", name: "VOXELJET AG-ADR" },
  { ticker: "VKI", name: "INVESCO AD MIT II" },
  { ticker: "VKQ", name: "INVESCO MUNICIPAL TRUST" },
  { ticker: "VKTX", name: "VIKING THERAPEUTICS INC" },
  { ticker: "VLGEA", name: "VILLAGE SUPER MARKET-CLASS A" },
  { ticker: "VLO", name: "VALERO ENERGY CORP" },
  { ticker: "VLRS", name: "CONTROLADORA VUELA CIA-ADR" },
  { ticker: "VLT", name: "INVESCO HIGH INCOME TRUST II" },
  { ticker: "VLU", name: "SPDR S&P 1500 VALUE TILT ETF" },
  { ticker: "VLUE", name: "ISHARES EDGE MSCI USA VALUE" },
  { ticker: "VLY", name: "VALLEY NATIONAL BANCORP" },
  { ticker: "VMBS", name: "VANGUARD MORTGAGE-BACKED SEC" },
  { ticker: "VMC", name: "VULCAN MATERIALS CO" },
  { ticker: "VMD", name: "VIEMED HEALTHCARE INC" },
  { ticker: "VMI", name: "VALMONT INDUSTRIES" },
  { ticker: "VMM", name: "DELAWARE INV MINN MUNI II" },
  { ticker: "VMO", name: "INVESCO MUNICIPAL OPPORTUNI" },
  { ticker: "VMOT", name: "ALPHA VALUE MOM TREND ETF" },
  { ticker: "VMW", name: "VMWARE INC-CLASS A" },
  { ticker: "VNCE", name: "VINCE HOLDING CORP" },
  { ticker: "VNDA", name: "VANDA PHARMACEUTICALS INC" },
  { ticker: "VNE", name: "VEONEER INC" },
  { ticker: "VNET", name: "21VIANET GROUP INC-ADR" },
  { ticker: "VNLA", name: "JANUS HENDERSON SHORT DURATI" },
  { ticker: "VNM", name: "VANECK VIETNAM ETF" },
  { ticker: "VNO", name: "VORNADO REALTY TRUST" },
  { ticker: "VNOM", name: "VIPER ENERGY PARTNERS LP" },
  { ticker: "VNQ", name: "VANGUARD REAL ESTATE ETF" },
  { ticker: "VNQI", name: "VANGUARD GLBL EX-US REAL EST" },
  { ticker: "VNRX", name: "VOLITIONRX LTD" },
  { ticker: "VNTR", name: "VENATOR MATERIALS PLC" },
  { ticker: "VO", name: "VANGUARD MID-CAP ETF" },
  { ticker: "VOC", name: "VOC ENERGY TRUST" },
  { ticker: "VOD", name: "VODAFONE GROUP PLC-SP ADR" },
  { ticker: "VOE", name: "VANGUARD MID-CAP VALUE ETF" },
  { ticker: "VOLT", name: "VOLT INFO SCIENCES INC" },
  { ticker: "VONE", name: "VANGUARD RUSSELL 1000" },
  { ticker: "VONG", name: "VANGUARD RUSSELL 1000 GROWTH" },
  { ticker: "VONV", name: "VANGUARD RUSSELL 1000 VALUE" },
  { ticker: "VOO", name: "VANGUARD S&P 500 ETF" },
  { ticker: "VOOG", name: "VANGUARD S&P 500 GROWTH ETF" },
  { ticker: "VOOV", name: "VANGUARD S&P 500 VALUE ETF" },
  { ticker: "VOT", name: "VANGUARD MID-CAP GROWTH ETF" },
  { ticker: "VOX", name: "VANGUARD COMMUNICATION SERVI" },
  { ticker: "VOXX", name: "VOXX INTERNATIONAL CORP" },
  { ticker: "VOYA", name: "VOYA FINANCIAL INC" },
  { ticker: "VPC", name: "VIRTUS PRIVATE CREDIT STRATE" },
  { ticker: "VPG", name: "VISHAY PRECISION GROUP" },
  { ticker: "VPL", name: "VANGUARD FTSE PACIFIC ETF" },
  { ticker: "VPU", name: "VANGUARD UTILITIES ETF" },
  { ticker: "VPV", name: "INVESCO PENNSYLVANIA VALUE M" },
  { ticker: "VQT", name: "IPATH S&P VEQTOR ETN" },
  { ticker: "VRA", name: "VERA BRADLEY INC" },
  { ticker: "VRAI", name: "VIRTUS REAL ASSET INCOME ETF" },
  { ticker: "VRAY", name: "VIEWRAY INC" },
  { ticker: "VRCA", name: "VERRICA PHARMACEUTICALS INC" },
  { ticker: "VREX", name: "VAREX IMAGING CORP" },
  { ticker: "VRIG", name: "INVESCO VARIABLE RATE INVEST" },
  { ticker: "VRML", name: "VERMILLION INC" },
  { ticker: "VRNA", name: "VERONA PHARMA PLC - ADR" },
  { ticker: "VRNS", name: "VARONIS SYSTEMS INC" },
  { ticker: "VRNT", name: "VERINT SYSTEMS INC" },
  { ticker: "VRP", name: "INVESCO VARIABLE RATE PREFER" },
  { ticker: "VRRM", name: "VERRA MOBILITY CORP" },
  { ticker: "VRS", name: "VERSO CORP - A" },
  { ticker: "VRSK", name: "VERISK ANALYTICS INC" },
  { ticker: "VRSN", name: "VERISIGN INC" },
  { ticker: "VRT", name: "VERTIV HOLDINGS CO" },
  { ticker: "VRTS", name: "VIRTUS INVESTMENT PARTNERS" },
  { ticker: "VRTU", name: "VIRTUSA CORP" },
  { ticker: "VRTV", name: "VERITIV CORP" },
  { ticker: "VRTX", name: "VERTEX PHARMACEUTICALS INC" },
  { ticker: "VSAT", name: "VIASAT INC" },
  { ticker: "VSDA", name: "VICTORYSHARES DIVIDEND ACCEL" },
  { ticker: "VSEC", name: "VSE CORP" },
  { ticker: "VSGX", name: "VANGUARD ESG INTL STOCK ETF" },
  { ticker: "VSH", name: "VISHAY INTERTECHNOLOGY INC" },
  { ticker: "VSL", name: "VOLSHARES LARGE CAP ETF" },
  { ticker: "VSLR", name: "VIVINT SOLAR INC" },
  { ticker: "VSMV", name: "VICTORYSHARES US MIN VOL ETF" },
  { ticker: "VSS", name: "VANGUARD FTSE ALL WO X-US SC" },
  { ticker: "VST", name: "VISTRA ENERGY CORP" },
  { ticker: "VSTM", name: "VERASTEM INC" },
  { ticker: "VSTO", name: "VISTA OUTDOOR INC" },
  { ticker: "VT", name: "VANGUARD TOT WORLD STK ETF" },
  { ticker: "VTA", name: "INVESCO DYNAMIC CREDIT OPP" },
  { ticker: "VTC", name: "VANGUARD TOTAL CORPORATE BND" },
  { ticker: "VTEB", name: "VANGUARD TAX-EXEMPT BOND ETF" },
  { ticker: "VTGN", name: "VISTAGEN THERAPEUTICS INC" },
  { ticker: "VTHR", name: "VANGUARD RUSSELL 3000" },
  { ticker: "VTI", name: "VANGUARD TOTAL STOCK MKT ETF" },
  { ticker: "VTIP", name: "VANGUARD SHORT-TERM TIPS" },
  { ticker: "VTIQ", name: "VECTOIQ ACQUISITION CORP" },
  { ticker: "VTIQU", name: "VECTOIQ ACQUISITION CORP" },
  { ticker: "VTN", name: "INVESCO TRUST FOR INVESTMENT" },
  { ticker: "VTNR", name: "VERTEX ENERGY INC" },
  { ticker: "VTR", name: "VENTAS INC" },
  { ticker: "VTSI", name: "VIRTRA INC" },
  { ticker: "VTV", name: "VANGUARD VALUE ETF" },
  { ticker: "VTVT", name: "VTV THERAPEUTICS INC- CL A" },
  { ticker: "VTWG", name: "VANGUARD RUSSELL 2000 GROWTH" },
  { ticker: "VTWO", name: "VANGUARD RUSSELL 2000 ETF" },
  { ticker: "VTWV", name: "VANGUARD RUSSELL 2000 VALUE" },
  { ticker: "VUG", name: "VANGUARD GROWTH ETF" },
  { ticker: "VUSE", name: "VIDENT CORE US EQUITY ETF" },
  { ticker: "VUZI", name: "VUZIX CORP" },
  { ticker: "VV", name: "VANGUARD LARGE-CAP ETF" },
  { ticker: "VVI", name: "VIAD CORP" },
  { ticker: "VVNT", name: "VIVINT SMART HOME INC" },
  { ticker: "VVPR", name: "VIVOPOWER INTERNATIONAL PLC" },
  { ticker: "VVR", name: "INVESCO SENIOR INCOME TRUST" },
  { ticker: "VVUS", name: "VIVUS INC" },
  { ticker: "VVV", name: "VALVOLINE INC" },
  { ticker: "VWO", name: "VANGUARD FTSE EMERGING MARKE" },
  { ticker: "VWOB", name: "VANGUARD EMERG MKTS GOV BND" },
  { ticker: "VXF", name: "VANGUARD EXTENDED MARKET ETF" },
  { ticker: "VXRT", name: "VAXART INC" },
  { ticker: "VXUS", name: "VANGUARD TOTAL INTL STOCK" },
  { ticker: "VXX", name: "IPATH SERIES B S&P 500 VIX" },
  { ticker: "VXZ", name: "IPATH SER B S&P 500 VIX M/T" },
  { ticker: "VYGR", name: "VOYAGER THERAPEUTICS INC" },
  { ticker: "VYM", name: "VANGUARD HIGH DVD YIELD ETF" },
  { ticker: "VYMI", name: "VANGUARD INT HIGH DVD YLD IN" },
  { ticker: "VZ", name: "VERIZON COMMUNICATIONS INC" },
  { ticker: "W", name: "WAYFAIR INC- CLASS A" },
  { ticker: "WAAS", name: "AQUAVENTURE HOLDINGS LTD" },
  { ticker: "WAB", name: "WABTEC CORP" },
  { ticker: "WABC", name: "WESTAMERICA BANCORPORATION" },
  { ticker: "WAFD", name: "WASHINGTON FEDERAL INC" },
  { ticker: "WAFU", name: "WAH FU EDUCATION GROUP LTD" },
  { ticker: "WAL", name: "WESTERN ALLIANCE BANCORP" },
  { ticker: "WANT", name: "DRX DLY CONS DISC BULL 3X" },
  { ticker: "WASH", name: "WASHINGTON TRUST BANCORP" },
  { ticker: "WAT", name: "WATERS CORP" },
  { ticker: "WATT", name: "ENERGOUS CORP" },
  { ticker: "WB", name: "WEIBO CORP-SPON ADR" },
  { ticker: "WBA", name: "WALGREENS BOOTS ALLIANCE INC" },
  { ticker: "WBAI", name: "500.COM LTD-CLASS A-ADR" },
  { ticker: "WBC", name: "WABCO HOLDINGS INC" },
  { ticker: "WBIE", name: "WBI BULLBEAR RISING INCOME 3" },
  { ticker: "WBIF", name: "WBI BULLBEAR VALUE 3000 ETF" },
  { ticker: "WBIG", name: "WBI BULLBEAR YIELD 3000 ETF" },
  { ticker: "WBII", name: "WBI BULLBEAR GLOBAL INCOME E" },
  { ticker: "WBIL", name: "WBI BULLBEAR QUALITY 3000 ET" },
  { ticker: "WBIN", name: "WBI BULLBEAR TREND US TOTAL" },
  { ticker: "WBIT", name: "WBI BULLBEAR TREND S US 3000" },
  { ticker: "WBIY", name: "WBI POWER FACTOR HIGH DIV" },
  { ticker: "WBK", name: "WESTPAC BANKING CORP-SP ADR" },
  { ticker: "WBND", name: "WESTERN ASSET TOTAL RETURN E" },
  { ticker: "WBS", name: "WEBSTER FINANCIAL CORP" },
  { ticker: "WBT", name: "WELBILT INC" },
  { ticker: "WCC", name: "WESCO INTERNATIONAL INC" },
  { ticker: "WCHN", name: "WISDOMTREE ICBCCS SP 500" },
  { ticker: "WCLD", name: "WISDOMTREE CLOUD COMPUTING" },
  { ticker: "WCN", name: "WASTE CONNECTIONS INC" },
  { ticker: "WD", name: "WALKER & DUNLOP INC" },
  { ticker: "WDAY", name: "WORKDAY INC-CLASS A" },
  { ticker: "WDC", name: "WESTERN DIGITAL CORP" },
  { ticker: "WDFC", name: "WD-40 CO" },
  { ticker: "WDIV", name: "SPDR S&P GLOBAL DIVIDEND ETF" },
  { ticker: "WDR", name: "WADDELL & REED FINANCIAL-A" },
  { ticker: "WDRW", name: "DRX DLY REG BANK BEAR 3X" },
  { ticker: "WEA", name: "WESTERN ASSET PREMIER BOND" },
  { ticker: "WEAT", name: "TEUCRIUM WHEAT FUND" },
  { ticker: "WEBK", name: "WELLESLEY BANK" },
  { ticker: "WEBL", name: "DIREXION DAILY DOW JONES INT" },
  { ticker: "WEBS", name: "DIREXION DAILY DOW JONES INT" },
  { ticker: "WEC", name: "WEC ENERGY GROUP INC" },
  { ticker: "WEI", name: "WEIDAI LTD-ADR" },
  { ticker: "WELL", name: "WELLTOWER INC" },
  { ticker: "WEN", name: "WENDY'S CO/THE" },
  { ticker: "WERN", name: "WERNER ENTERPRISES INC" },
  { ticker: "WES", name: "WESTERN MIDSTREAM PARTNERS L" },
  { ticker: "WETF", name: "WISDOMTREE INVESTMENTS INC" },
  { ticker: "WEX", name: "WEX INC" },
  { ticker: "WEYS", name: "WEYCO GROUP INC" },
  { ticker: "WF", name: "WOORI FINANCIAL-SPON ADR" },
  { ticker: "WFC", name: "WELLS FARGO & CO" },
  { ticker: "WFHY", name: "WISDOMTREE US HIGH YIELD COR" },
  { ticker: "WFIG", name: "WISDOMTREE US CORPORATE BOND" },
  { ticker: "WGO", name: "WINNEBAGO INDUSTRIES" },
  { ticker: "WH", name: "WYNDHAM HOTELS & RESORTS INC" },
  { ticker: "WHD", name: "CACTUS INC - A" },
  { ticker: "WHF", name: "WHITEHORSE FINANCE INC" },
  { ticker: "WHG", name: "WESTWOOD HOLDINGS GROUP INC" },
  { ticker: "WHLM", name: "WILHELMINA INTERNATIONAL INC" },
  { ticker: "WHLR", name: "WHEELER REAL ESTATE INVESTME" },
  { ticker: "WHR", name: "WHIRLPOOL CORP" },
  { ticker: "WIA", name: "WESTERN ASSET INFL-LINK SEC" },
  { ticker: "WIFI", name: "BOINGO WIRELESS INC" },
  { ticker: "WIL", name: "IPATH WOMEN IN LEADERSHIP ET" },
  { ticker: "WILC", name: "G. WILLI-FOOD INTERNATIONAL" },
  { ticker: "WINA", name: "WINMARK CORP" },
  { ticker: "WINC", name: "WESTERN ASSET ST DUR INC ETF" },
  { ticker: "WING", name: "WINGSTOP INC" },
  { ticker: "WINS", name: "WINS FINANCE HOLDINGS INC" },
  { ticker: "WIP", name: "SPDR FTSE INTERNATIONAL GOVE" },
  { ticker: "WIRE", name: "ENCORE WIRE CORP" },
  { ticker: "WISA", name: "SUMMIT WIRELESS TECHNOLOGIES" },
  { ticker: "WIT", name: "WIPRO LTD-ADR" },
  { ticker: "WIW", name: "WESTERN ASSET INFL-LINK OPPS" },
  { ticker: "WIX", name: "WIX.COM LTD" },
  { ticker: "WIZ", name: "MERLYN AI BULL-RIDER BEAR-FI" },
  { ticker: "WK", name: "WORKIVA INC" },
  { ticker: "WKEY", name: "WISEKEY INTL HOLDINGS-ADR" },
  { ticker: "WKHS", name: "WORKHORSE GROUP INC" },
  { ticker: "WLDN", name: "WILLDAN GROUP INC" },
  { ticker: "WLDR", name: "AFFINITY WORLD LEADERS EQUIT" },
  { ticker: "WLFC", name: "WILLIS LEASE FINANCE CORP" },
  { ticker: "WLK", name: "WESTLAKE CHEMICAL CORP" },
  { ticker: "WLKP", name: "WESTLAKE CHEMICAL PARTNERS L" },
  { ticker: "WLL", name: "WHITING PETROLEUM CORP" },
  { ticker: "WLTW", name: "WILLIS TOWERS WATSON PLC" },
  { ticker: "WM", name: "WASTE MANAGEMENT INC" },
  { ticker: "WMB", name: "WILLIAMS COS INC" },
  { ticker: "WMC", name: "WESTERN ASSET MORTGAGE CAPIT" },
  { ticker: "WMGI", name: "WRIGHT MEDICAL GROUP NV" },
  { ticker: "WMK", name: "WEIS MARKETS INC" },
  { ticker: "WMS", name: "ADVANCED DRAINAGE SYSTEMS IN" },
  { ticker: "WMT", name: "WALMART INC" },
  { ticker: "WNC", name: "WABASH NATIONAL CORP" },
  { ticker: "WNEB", name: "WESTERN NEW ENGLAND BANCORP" },
  { ticker: "WNS", name: "WNS HOLDINGS LTD-ADR" },
  { ticker: "WOMN", name: "IMPACT SHARES YWCA WOMEN'S E" },
  { ticker: "WOOD", name: "ISHARES GLOBAL TIMBER & FORE" },
  { ticker: "WOR", name: "WORTHINGTON INDUSTRIES" },
  { ticker: "WORK", name: "SLACK TECHNOLOGIES INC- CL A" },
  { ticker: "WORX", name: "SCWORX CORP" },
  { ticker: "WOW", name: "WIDEOPENWEST INC" },
  { ticker: "WPC", name: "WP CAREY INC" },
  { ticker: "WPG", name: "WASHINGTON PRIME GROUP INC" },
  { ticker: "WPM", name: "WHEATON PRECIOUS METALS CORP" },
  { ticker: "WPP", name: "WPP PLC-SPONSORED ADR" },
  { ticker: "WPRT", name: "WESTPORT FUEL SYSTEMS INC" },
  { ticker: "WPS", name: "ISHARES INTERNATIONAL DEVELO" },
  { ticker: "WPX", name: "WPX ENERGY INC" },
  { ticker: "WRB", name: "WR BERKLEY CORP" },
  { ticker: "WRE", name: "WASHINGTON REIT" },
  { ticker: "WRI", name: "WEINGARTEN REALTY INVESTORS" },
  { ticker: "WRK", name: "WESTROCK CO" },
  { ticker: "WRLD", name: "WORLD ACCEPTANCE CORP" },
  { ticker: "WRLS", name: "PENSARE ACQUISITION CORP" },
  { ticker: "WRLSR", name: "PENSARE ACQUISITION CORP-RTS" },
  { ticker: "WRLSU", name: "PENSARE ACQUISITION CORP" },
  { ticker: "WRN", name: "WESTERN COPPER AND GOLD CORP" },
  { ticker: "WRTC", name: "WRAP TECHNOLOGIES INC" },
  { ticker: "WSBC", name: "WESBANCO INC" },
  { ticker: "WSBF", name: "WATERSTONE FINANCIAL INC" },
  { ticker: "WSC", name: "WILLSCOT CORP" },
  { ticker: "WSFS", name: "WSFS FINANCIAL CORP" },
  { ticker: "WSG", name: "WANDA SPORTS GROUP CO LT-ADR" },
  { ticker: "WSM", name: "WILLIAMS-SONOMA INC" },
  { ticker: "WSO", name: "WATSCO INC" },
  { ticker: "WSO.B", name: "WATSCO INC  -CL B" },
  { ticker: "WSR", name: "WHITESTONE REIT" },
  { ticker: "WST", name: "WEST PHARMACEUTICAL SERVICES" },
  { ticker: "WSTG", name: "WAYSIDE TECHNOLOGY GROUP INC" },
  { ticker: "WSTL", name: "WESTELL TECHNOLOGIES INC-A" },
  { ticker: "WTBA", name: "WEST BANCORPORATION" },
  { ticker: "WTER", name: "ALKALINE WATER CO INC/THE" },
  { ticker: "WTFC", name: "WINTRUST FINANCIAL CORP" },
  { ticker: "WTI", name: "W&T OFFSHORE INC" },
  { ticker: "WTID", name: "UBS-PROSHARES 3X INVRSE CRUD" },
  { ticker: "WTIU", name: "UBS-PROSHARES 3X LONG CRUDE" },
  { ticker: "WTM", name: "WHITE MOUNTAINS INSURANCE GP" },
  { ticker: "WTMF", name: "WISDOMTREE MGD FUTURES STRAT" },
  { ticker: "WTRE", name: "WATFORD HOLDINGS LTD" },
  { ticker: "WTRG", name: "ESSENTIAL UTILITIES INC" },
  { ticker: "WTRH", name: "WAITR HOLDINGS INC" },
  { ticker: "WTS", name: "WATTS WATER TECHNOLOGIES-A" },
  { ticker: "WTT", name: "WIRELESS TELECOM GROUP INC" },
  { ticker: "WTTR", name: "SELECT ENERGY SERVICES INC-A" },
  { ticker: "WU", name: "WESTERN UNION CO" },
  { ticker: "WUBA", name: "58.COM INC-ADR" },
  { ticker: "WVE", name: "WAVE LIFE SCIENCES LTD" },
  { ticker: "WVFC", name: "WVS FINANCIAL CORP" },
  { ticker: "WVVI", name: "WILLAMETTE VALLEY VINEYARDS" },
  { ticker: "WW", name: "WW INTERNATIONAL INC" },
  { ticker: "WWD", name: "WOODWARD INC" },
  { ticker: "WWE", name: "WORLD WRESTLING ENTERTAIN-A" },
  { ticker: "WWJD", name: "INSPIRE INTERNATIONAL ESG ET" },
  { ticker: "WWR", name: "WESTWATER RESOURCES INC" },
  { ticker: "WWW", name: "WOLVERINE WORLD WIDE INC" },
  { ticker: "WY", name: "WEYERHAEUSER CO" },
  { ticker: "WYND", name: "WYNDHAM DESTINATIONS INC" },
  { ticker: "WYNN", name: "WYNN RESORTS LTD" },
  { ticker: "WYY", name: "WIDEPOINT CORP" },
  { ticker: "X", name: "UNITED STATES STEEL CORP" },
  { ticker: "XAIR", name: "BEYOND AIR INC" },
  { ticker: "XAN", name: "EXANTAS CAPITAL CORP" },
  { ticker: "XAR", name: "SPDR S&P AEROSPACE & DEF ETF" },
  { ticker: "XBI", name: "SPDR S&P BIOTECH ETF" },
  { ticker: "XBIO", name: "XENETIC BIOSCIENCES INC" },
  { ticker: "XBIT", name: "XBIOTECH INC" },
  { ticker: "XBUY", name: "AMPLIFY INTL ONLINE RETAIL" },
  { ticker: "XCEM", name: "COLUMBIA EM CORE EX-CHINA ET" },
  { ticker: "XCOM", name: "PROSHARES ULTRA COMMUNICATIO" },
  { ticker: "XCUR", name: "EXICURE INC" },
  { ticker: "XDIV", name: "METAURUS US EQUITY EX DIVIDE" },
  { ticker: "XEC", name: "CIMAREX ENERGY CO" },
  { ticker: "XEL", name: "XCEL ENERGY INC" },
  { ticker: "XELA", name: "EXELA TECHNOLOGIES INC" },
  { ticker: "XELB", name: "XCEL BRANDS INC" },
  { ticker: "XENE", name: "XENON PHARMACEUTICALS INC" },
  { ticker: "XENT", name: "INTERSECT ENT INC" },
  { ticker: "XERS", name: "XERIS PHARMACEUTICALS INC" },
  { ticker: "XES", name: "SPDR OIL & GAS EQUIP & SERV" },
  { ticker: "XFLT", name: "XAI OCTAGON FLOATING RATE AL" },
  { ticker: "XFOR", name: "X4 PHARMACEUTICALS INC" },
  { ticker: "XGN", name: "EXAGEN INC" },
  { ticker: "XHB", name: "SPDR S&P HOMEBUILDERS ETF" },
  { ticker: "XHE", name: "SPDR S&P HEALTH CARE EQUIPME" },
  { ticker: "XHR", name: "XENIA HOTELS & RESORTS INC" },
  { ticker: "XHS", name: "SPDR S&P HEALTH CARE SER ETF" },
  { ticker: "XIN", name: "XINYUAN REAL ESTATE CO L-ADR" },
  { ticker: "XITK", name: "SPDR FACTSET INNOVATIVE TECH" },
  { ticker: "XLB", name: "MATERIALS SELECT SECTOR SPDR" },
  { ticker: "XLC", name: "COMM SERV SELECT SECTOR SPDR" },
  { ticker: "XLE", name: "ENERGY SELECT SECTOR SPDR" },
  { ticker: "XLF", name: "FINANCIAL SELECT SECTOR SPDR" },
  { ticker: "XLG", name: "INVESCO S&P 500 TOP 50 ETF" },
  { ticker: "XLI", name: "INDUSTRIAL SELECT SECT SPDR" },
  { ticker: "XLK", name: "TECHNOLOGY SELECT SECT SPDR" },
  { ticker: "XLNX", name: "XILINX INC" },
  { ticker: "XLP", name: "CONSUMER STAPLES SPDR" },
  { ticker: "XLRE", name: "REAL ESTATE SELECT SECT SPDR" },
  { ticker: "XLRN", name: "ACCELERON PHARMA INC" },
  { ticker: "XLSR", name: "SPDR SSGA US SECTOR ROTATE" },
  { ticker: "XLU", name: "UTILITIES SELECT SECTOR SPDR" },
  { ticker: "XLV", name: "HEALTH CARE SELECT SECTOR" },
  { ticker: "XLY", name: "CONSUMER DISCRETIONARY SELT" },
  { ticker: "XME", name: "SPDR S&P METALS & MINING ETF" },
  { ticker: "XMHQ", name: "INVESCO S&P MIDCAP QUALITY E" },
  { ticker: "XMLV", name: "INVESCO S&P MIDCAP LOW VOLAT" },
  { ticker: "XMMO", name: "INVESCO S&P MIDCAP MOMENTUM" },
  { ticker: "XMPT", name: "VANECK CEF MUNI INCOME" },
  { ticker: "XMVM", name: "INVESCO S&P MIDCAP VALUE WIT" },
  { ticker: "XNCR", name: "XENCOR INC" },
  { ticker: "XNET", name: "XUNLEI LTD-ADR" },
  { ticker: "XNTK", name: "SPDR NYSE TECHNOLOGY ETF" },
  { ticker: "XOG", name: "EXTRACTION OIL & GAS INC" },
  { ticker: "XOM", name: "EXXON MOBIL CORP" },
  { ticker: "XOMA", name: "XOMA CORP" },
  { ticker: "XONE", name: "EXONE CO/THE" },
  { ticker: "XOP", name: "SPDR S&P OIL & GAS EXP & PR" },
  { ticker: "XOUT", name: "GRANITESHARES XOUT US L/C" },
  { ticker: "XP", name: "XP INC - CLASS A" },
  { ticker: "XPEL", name: "XPEL INC" },
  { ticker: "XPER", name: "XPERI CORP" },
  { ticker: "XPH", name: "SPDR S&P PHARMACEUTICALS ETF" },
  { ticker: "XPL", name: "SOLITARIO ZINC CORP" },
  { ticker: "XPO", name: "XPO LOGISTICS INC" },
  { ticker: "XPP", name: "PROSHARES ULTRA FTSE CHINA50" },
  { ticker: "XRAY", name: "DENTSPLY SIRONA INC" },
  { ticker: "XRF", name: "CHINA RAPID FINANCE LTD-ADR" },
  { ticker: "XRLV", name: "INVESCO S&P 500 EX-RATE SENS" },
  { ticker: "XRT", name: "SPDR S&P RETAIL ETF" },
  { ticker: "XRX", name: "XEROX HOLDINGS CORP" },
  { ticker: "XSD", name: "SPDR S&P SEMICONDUCTOR ETF" },
  { ticker: "XSHD", name: "INVESCO S&P SMALLCAP HIGH DI" },
  { ticker: "XSHQ", name: "INVESCO S&P SMALLCAP QUALITY" },
  { ticker: "XSLV", name: "INVESCO S&P SMALLCAP LOW VOL" },
  { ticker: "XSMO", name: "INVESCO S&P SMALLCAP MOMENTU" },
  { ticker: "XSOE", name: "WISDMTREE EMERG MKT EX-ST OW" },
  { ticker: "XSPA", name: "XPRESSPA GROUP INC" },
  { ticker: "XSVM", name: "INVESCO S&P SMALLCAP VALUE W" },
  { ticker: "XSW", name: "SPDR S&P SOFTWARE & SER ETF" },
  { ticker: "XT", name: "ISHARES EXPONENTIAL TECH ETF" },
  { ticker: "XTH", name: "SPDR S&P TECH HARDWARE ETF" },
  { ticker: "XTL", name: "SPDR S&P TELECOM ETF" },
  { ticker: "XTLB", name: "XTL BIOPHARMACEUTICALS-ADR" },
  { ticker: "XTN", name: "SPDR S&P TRANSPORTATION ETF" },
  { ticker: "XTNT", name: "XTANT MEDICAL HOLDINGS INC" },
  { ticker: "XVZ", name: "IPATH S&P 500 DYN VIX ETN" },
  { ticker: "XWEB", name: "SPDR S&P INTERNET ETF" },
  { ticker: "XXII", name: "22ND CENTURY GROUP INC" },
  { ticker: "XYF", name: "X FINANCIAL-ADR" },
  { ticker: "XYL", name: "XYLEM INC" },
  { ticker: "Y", name: "ALLEGHANY CORP" },
  { ticker: "YANG", name: "DRX DLY FTSE CHINA BEAR 3X" },
  { ticker: "YCBD", name: "CBDMD INC" },
  { ticker: "YCL", name: "PROSHARES ULTRA YEN" },
  { ticker: "YCOM", name: "PROSHARES ULTRASHORT COMMUNI" },
  { ticker: "YCS", name: "PROSHARES ULTRASHORT YEN" },
  { ticker: "YELP", name: "YELP INC" },
  { ticker: "YETI", name: "YETI HOLDINGS INC" },
  { ticker: "YEXT", name: "YEXT INC" },
  { ticker: "YGRN", name: "MICROSECTORS US BIG OIL IVSR" },
  { ticker: "YGYI", name: "YOUNGEVITY INTERNATIONAL INC" },
  { ticker: "YI", name: "111 INC" },
  { ticker: "YIN", name: "YINTECH INVESTMENT HOLD-ADR" },
  { ticker: "YINN", name: "DRX DLY FTSE CHINA BULL 3X" },
  { ticker: "YJ", name: "YUNJI INC-ADR" },
  { ticker: "YLCO", name: "GLOBAL X FUNDS GLOBAL X YIEL" },
  { ticker: "YLD", name: "PRINCIPAL ACTIVE INCOME ETF" },
  { ticker: "YLDE", name: "CLEARBRIDG DIV STRGY ESG ETF" },
  { ticker: "YMAB", name: "Y-MABS THERAPEUTICS INC" },
  { ticker: "YNDX", name: "YANDEX NV-A" },
  { ticker: "YOLO", name: "ADVISORSHARES PURE CANNABIS" },
  { ticker: "YORW", name: "YORK WATER CO" },
  { ticker: "YPF", name: "YPF S.A.-SPONSORED ADR" },
  { ticker: "YRCW", name: "YRC WORLDWIDE INC" },
  { ticker: "YRD", name: "YIREN DIGITAL LTD - SPS ADR" },
  { ticker: "YTEN", name: "YIELD10 BIOSCIENCE INC" },
  { ticker: "YTRA", name: "YATRA ONLINE INC" },
  { ticker: "YUM", name: "YUM! BRANDS INC" },
  { ticker: "YUMA", name: "YUMA ENERGY INC" },
  { ticker: "YUMC", name: "YUM CHINA HOLDINGS INC" },
  { ticker: "YVR", name: "LIQUID MEDIA GROUP LTD" },
  { ticker: "YXI", name: "PROSHARES SHORT FTSE CHINA50" },
  { ticker: "YY", name: "JOYY INC" },
  { ticker: "YYY", name: "AMPLIFY HIGH INCOME ETF" },
  { ticker: "Z", name: "ZILLOW GROUP INC - C" },
  { ticker: "ZAGG", name: "ZAGG INC" },
  { ticker: "ZBH", name: "ZIMMER BIOMET HOLDINGS INC" },
  { ticker: "ZBIO", name: "PROSHARES U/P SH NASDAQ BIOT" },
  { ticker: "ZBRA", name: "ZEBRA TECHNOLOGIES CORP-CL A" },
  { ticker: "ZCAN", name: "SPDR SOLACTIVE CANADA ETF" },
  { ticker: "ZCMD", name: "ZHONGCHAO INC-CLASS A" },
  { ticker: "ZDEU", name: "SPDR SOLACTIVE GERMANY ETF" },
  { ticker: "ZDGE", name: "ZEDGE INC-CL B" },
  { ticker: "ZEAL", name: "ZEALAND PHARMA A/S-ADR" },
  { ticker: "ZEN", name: "ZENDESK INC" },
  { ticker: "ZEUS", name: "OLYMPIC STEEL INC" },
  { ticker: "ZFGN", name: "ZAFGEN INC" },
  { ticker: "ZG", name: "ZILLOW GROUP INC - A" },
  { ticker: "ZGBR", name: "SPDR SOLACTIVE UNITED KINGDO" },
  { ticker: "ZGNX", name: "ZOGENIX INC" },
  { ticker: "ZGYHU", name: "YUNHONG INTERNATIONAL CO LTD" },
  { ticker: "ZHOK", name: "SPDR SOLACTIVE HONG KONG ETF" },
  { ticker: "ZIG", name: "THE ACQUIRERS FUND ETF" },
  { ticker: "ZION", name: "ZIONS BANCORP NA" },
  { ticker: "ZIOP", name: "ZIOPHARM ONCOLOGY INC" },
  { ticker: "ZIV", name: "VELOCITYSHARES INV VIX MEDIU" },
  { ticker: "ZIXI", name: "ZIX CORP" },
  { ticker: "ZJPN", name: "SPDR SOLACTIVE JAPAN ETF" },
  { ticker: "ZKIN", name: "ZK INTERNATIONAL GROUP CO LT" },
  { ticker: "ZLAB", name: "ZAI LAB LTD-ADR" },
  { ticker: "ZM", name: "ZOOM VIDEO COMMUNICATIONS-A" },
  { ticker: "ZMLP", name: "DIREXION ZACKS MLP HIGH INCO" },
  { ticker: "ZN", name: "ZION OIL & GAS INC" },
  { ticker: "ZNGA", name: "ZYNGA INC - CL A" },
  { ticker: "ZNH", name: "CHINA SOUTHERN AIR-SPONS ADR" },
  { ticker: "ZOM", name: "ZOMEDICA PHARMACEUTICALS COR" },
  { ticker: "ZROZ", name: "PIMCO 25+ YR ZERO CPN US TIF" },
  { ticker: "ZS", name: "ZSCALER INC" },
  { ticker: "ZSAN", name: "ZOSANO PHARMA CORP" },
  { ticker: "ZSL", name: "PROSHARES ULTRASHORT SILVER" },
  { ticker: "ZTO", name: "ZTO EXPRESS CAYMAN INC-ADR" },
  { ticker: "ZTR", name: "VIRTUS TOTAL RETURN FUND INC" },
  { ticker: "ZTS", name: "ZOETIS INC" },
  { ticker: "ZUMZ", name: "ZUMIEZ INC" },
  { ticker: "ZUO", name: "ZUORA INC - CLASS A" },
  { ticker: "ZVO", name: "ZOVIO INC" },
  { ticker: "ZYME", name: "ZYMEWORKS INC" },
  { ticker: "ZYNE", name: "ZYNERBA PHARMACEUTICALS INC" },
  { ticker: "ZYXI", name: "ZYNEX INC" },
  { ticker: "ALL_C", name: "All Symbols (Component Weighted)" },
  {
    ticker: "DIA_C",
    name: "SPDR Dow Jones Industrial Average ETF (Component Weighted)"
  },
  {
    ticker: "DJX_C",
    name: "Dow Jones Industrial Average (Component Weighted)"
  },
  {
    ticker: "GDX_C",
    name: "VanEck Vectors Gold Miners ETF (Component Weighted)"
  },
  {
    ticker: "IBB_C",
    name: "iShares NASDAQ Biotechnology Index (Component Weighted)"
  },
  {
    ticker: "IGN_C",
    name:
      "iShares North American Tech-Multimedia Networking ETF (Component Weighted)"
  },
  {
    ticker: "ITB_C",
    name: "iShares Dow Jones US Home Construction (Component Weighted)"
  },
  { ticker: "IWM_C", name: "iShares Russell 2000 Index (Component Weighted)" },
  { ticker: "IYR_C", name: "iShares US Real Estate ETF (Component Weighted)" },
  { ticker: "KBE_C", name: "SPDR S&P Bank ETF (Component Weighted)" },
  { ticker: "KRE_C", name: "SPDR KBW Regional Banking (Component Weighted)" },
  { ticker: "MDY_C", name: "SPDR S&P MidCap 400 ETF (Component Weighted)" },
  { ticker: "NDX_C", name: "NASDAQ 100 (Component Weighted)" },
  {
    ticker: "NQX_C",
    name: "NASDAQ-100 Reduced Value Index (Component Weighted)"
  },
  { ticker: "OIH_C", name: "VANECK VECTORS/OIL SVCS ETF (Component Weighted)" },
  { ticker: "QQQ_C", name: "PowerShares QQQ Trust Ser 1 (Component Weighted)" },
  { ticker: "RUT_C", name: "Russell 2000 Index (Component Weighted)" },
  {
    ticker: "SMH_C",
    name: "VANECK VECTORS/SEMICONDUCTOR ETF (Component Weighted)"
  },
  { ticker: "SPX_C", name: "S&P 500 (Component Weighted)" },
  { ticker: "SPY_C", name: "SPDR S&P 500 ETF Trust (Component Weighted)" },
  { ticker: "XBI_C", name: "SPDR S&P Biotech ETF (Component Weighted)" },
  { ticker: "XHB_C", name: "SPDR S&P Homebuilders ETF (Component Weighted)" },
  {
    ticker: "XLB_C",
    name: "Materials Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLC_C",
    name: "SELECT SECTOR S/COMM SVCS SELECT SE (Component Weighted)"
  },
  {
    ticker: "XLE_C",
    name: "Energy Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLF_C",
    name: "Financial Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLI_C",
    name: "Industrial Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLK_C",
    name: "Technology Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLP_C",
    name: "Consumer Staples Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLRE_C",
    name: "The Real Estate Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLU_C",
    name: "Utilities Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLV_C",
    name: "Health Care Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XLY_C",
    name: "Consumer Discretionary Select Sector SPDR Fund (Component Weighted)"
  },
  {
    ticker: "XOP_C",
    name: "SPDR S&P Oil & Gas Exploration & Production ETF (Component Weighted)"
  },
  {
    ticker: "XSP_C",
    name: "S&P 500 MINI SPX OPTIONS INDEX (Component Weighted)"
  }
]
