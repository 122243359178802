import React, { Component } from "react"
import { GenericChartComponent, utils } from "react-stockcharts"
import Moment from "moment"
import find from "lodash/find"
import map from "lodash/map"
import padStart from "lodash/padStart"

import util from "../../util"

const { noop } = utils

const getStyles = isBuy => {
  return {
    fill: isBuy ? "LIMEGREEN" : "#ff6159",
    almostBlack: isBuy ? "DARKOLIVEGREEN" : "DARKRED",
    capsule: {
      bg: isBuy ? "LIMEGREEN" : "#ff6159",
      height: 24,
      width: 60,
      padding: 12,
      fontFamily: "Roboto Condensed",
      fontSize: "12pt",
      fill: "white"
    },
    dateBlock: {
      width: 48,
      height: 17,
      fontSize: "10pt"
    }
  }
}

const Leg = ({ leg, x, y, chartHeight }) => {
  const styles = getStyles(leg.ratio === 1)

  const capsuleText = `${leg.strike} ${leg.optionType === "put" ? "P" : "C"}`
  const expText = Moment(
    `${leg.year}-${padStart(leg.month, 2, "0")}-${padStart(leg.day, 2, "0")}`
  )
    .startOf("day")
    .format("MMM DD")
    .toUpperCase()
  const textWidth =
    util.getTextWidth(capsuleText, "13px Roboto Condensed") +
    styles.capsule.padding * 2

  return (
    <g>
      <line x1={x} y1="0" x2={x} y2={chartHeight} stroke={styles.fill} />
      <g transform={`translate(${x}, ${y})`}>
        <g
          transform={`translate(${(textWidth + 8) * -1}, ${styles.capsule
            .height /
            2 *
            -1})`}>
          <rect
            x="0"
            y="0"
            rx="12"
            ry="12"
            width={textWidth}
            height={styles.capsule.height}
            fill={styles.capsule.bg}
            strokeWidth="3"
            stroke={styles.fill}
          />
          <text
            x={styles.capsule.padding / 2 + 2}
            y="17.5"
            fill={styles.capsule.fill}
            fontFamily={styles.capsule.fontFamily}
            fontSize={styles.capsule.fontSize}>
            {capsuleText}
          </text>
        </g>
        <circle
          cx="0"
          cy="0"
          r="7"
          stroke={styles.fill}
          strokeWidth="2"
          fill={styles.almostBlack}
        />
        <rect fill={styles.fill} x="-3" y="-1" width="6" height="2" />
      </g>
      <g
        transform={`translate(${x}, ${chartHeight -
          (styles.dateBlock.height + 1)})`}>
        <rect
          strokeWidth="2"
          stroke={styles.fill}
          fill="white"
          x={styles.dateBlock.width / 2 * -1}
          y="0"
          width={styles.dateBlock.width}
          height={styles.dateBlock.height}
        />
        <text
          x="-19"
          y="13"
          fill="black"
          fontFamily={styles.capsule.fontFamily}
          fontSize={styles.dateBlock.fontSize}>
          {expText}
        </text>
      </g>
    </g>
  )
}

export default class Trade extends Component {
  saveNode = node => {
    this.node = node
  }

  renderSVG = moreProps => {
    const { xScale, chartConfig: { yScale, height }, plotData } = moreProps
    const { xAccessor } = moreProps
    const { selectedTrade } = this.props
    return (
      <g>
        {map(selectedTrade.legs, (l, i) => {
          const expiration = `${l.year}-${padStart(l.month, 2, "0")}-${padStart(
            l.day,
            2,
            "0"
          )}` //Moment(`${leg.year}-${_.padStart(leg.month, 2, '0')}-${_.padStart(leg.day, 2, '0')}`).startOf('day')
          const x = xScale(xAccessor(find(plotData, { tradeDate: expiration })))
          if (isNaN(x)) {
            return null
          }
          const y = yScale(l.strike)
          return <Leg key={i} leg={l} x={x} y={y} chartHeight={height} />
        })}
      </g>
    )
  }

  render() {
    return (
      <GenericChartComponent
        ref={this.saveNode}
        svgDraw={this.renderSVG}
        drawOnPan
      />
    )
  }
}

Trade.drawOnCanvas = noop
