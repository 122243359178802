import _ from "lodash"
import Papa from "papaparse"

export const backtestTemplate = legs => {
  let processed = _.map(legs, (leg, i) => {
    return _.reduce(
      _.keys(leg),
      (result, k) => {
        const key = _.startsWith(k, "dte")
          ? _.replace(_.upperFirst(k), "te", "TE")
          : _.upperFirst(k)
        result[key] = leg[k]
        return result
      },
      {}
    )
  })

  const rez = Papa.unparse({
    fields: _.keys(processed[0]),
    data: _.map(processed, p => _.values(p))
  })

  return rez
}
