import React from "react"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"
import { ChartCanvas, Chart } from "react-stockcharts"
import {
  CandlestickSeries,
  LineSeries,
  ScatterSeries,
  CircleMarker
} from "react-stockcharts/lib/series"
import { format } from "d3-format"
import { timeFormat } from "d3-time-format"
import OutlookControl from "./OutlookControl"
import ExpirationArea from "./ExpirationArea"
import DistributionBars from "./DistributionBars"

import Trade from "./Trade"
import Moment from "moment"
import last from "lodash/last"
import slice from "lodash/slice"
import findIndex from "lodash/findIndex"
import flatten from "lodash/flatten"
import max from "lodash/max"
import min from "lodash/min"
import keys from "lodash/keys"
import values from "lodash/values"
import maxBy from "lodash/maxBy"
import minBy from "lodash/minBy"

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale"
import { XAxis, YAxis } from "react-stockcharts/lib/axes"
import {
  CrossHairCursor,
  MouseCoordinateX,
  MouseCoordinateY
} from "react-stockcharts/lib/coordinates"
import { Annotate, SvgPathAnnotation } from "react-stockcharts/lib/annotation"
import { fitWidth } from "react-stockcharts/lib/helper"
import { OHLCTooltip, SingleValueTooltip } from "react-stockcharts/lib/tooltip"

const margin = { left: 10, right: 40, top: 30, bottom: 30 }
const height = 600
const gridHeight = height - margin.top - margin.bottom

let xGrid = {
  innerTickSize: -1 * gridHeight,
  tickStrokeDasharray: "Solid",
  tickStrokeOpacity: 0.2,
  tickStrokeWidth: 1
}

const expPath = ({ x, y }) => {
  return `M ${x - 6} ${y + 0.5}
          L ${x + 6} ${y + 0.5}
          L ${x} ${y + 10}
          Z`
}

// TODO:
// - refactor chartExtents to return the max & min values for top trades and dist
// - distribution bars and outlook control are not being repoaitioned when the chart redraws (or, chart redraw is forcing the chart to re-centre (pan needs to be sticky))
// - y axis pan is not re-rendering distribution bars (i guess they are getting stale X/Y Scales?)
@inject("store")
@observer
class CandleStickStockScaleChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pctChg: 0,
      dollarValue: null
    }
  }

  vol50yAccessor = d => {
    const {
      store: { implieds }
    } = this.props
    const vol50 = implieds[d.tradeDate]
    return vol50 || -100 //implieds[d.tradeDate].vol50 ? implieds[d.tradeDate].vol50 || -10 : -10
  }

  iv30dyAccessor = d => {
    const {
      store: { historicalVol }
    } = this.props

    return historicalVol[d.tradeDate]
      ? historicalVol[d.tradeDate][0]
      : undefined
  }

  or20dHvyAccessor = d => {
    const {
      store: { historicalVol }
    } = this.props
    return historicalVol[d.tradeDate]
      ? historicalVol[d.tradeDate][1]
      : undefined
  }

  volYExtentsCalculator = (chartData, propName, componentName) => {
    const {
      store: { historicalVol, implieds }
    } = this.props

    const histVolsStartIndex = findIndex(
      keys(historicalVol),
      k => k === chartData[0].tradeDate
    )
    const histVolsSlice = flatten(
      slice(values(toJS(historicalVol)), histVolsStartIndex)
    )
    const impliedVolsStartIndex = findIndex(
      keys(implieds),
      k => k === last(chartData).tradeDate
    )
    const impliedVolsSlice = slice(values(implieds), impliedVolsStartIndex)

    return [
      min([min(histVolsSlice), min(impliedVolsSlice)]),
      max([max(histVolsSlice), max(impliedVolsSlice)])
    ]
  }

  yExtentsCalculator = (chartData, propName, componentName) => {
    const {
      store: { selectedDistribution, topTradesMinMax }
    } = this.props

    const step = selectedDistribution[2].bin
    const currentPrice = selectedDistribution[3].calcPrice
    const distributionMax =
      currentPrice +
      (currentPrice * (selectedDistribution[0].bin + step / 2)) / 100
    const distributionMin =
      currentPrice +
      (currentPrice * (selectedDistribution[6].bin - step / 2)) / 100

    // const [topTradesMin, topTradesMax] = topTradesMinMax

    const chartMax = maxBy(chartData, "high") // it's here that we need to calc based on the visible subset of history
    const chartMin = minBy(chartData, "low")

    return [
      min([distributionMin, chartMin && chartMin.low]),
      max([chartMax && chartMax.high, topTradesMinMax[1], distributionMax])
    ]
  }

  saveChartCanvasNode = node => {
    this.node = node
  }

  componentDidMount() {
    this.node.subscribe("canvasWidget", (eventType, chartProps, e) => {
      this.eventChange(eventType, chartProps, e)
    })
  }

  eventChange = (eventType, chartProps, e) => {
    switch (eventType) {
      case "zoom":
        this.props.store.chartPanEnded([
          Moment(this.node.state.plotData[0].tradeDate)
            .startOf("day")
            .toDate(),
          Moment(last(this.node.state.plotData).tradeDate)
            .startOf("day")
            .toDate()
        ])
        break
      case "panend":
        this.props.store.chartPanEnded([
          Moment(chartProps.plotData[0].tradeDate)
            .startOf("day")
            .toDate(),
          Moment(last(chartProps.plotData).tradeDate)
            .startOf("day")
            .toDate()
        ])
        break
      default:
        console.log()
    }
  }

  render() {
    try {
      const { width, ratio, store } = this.props
      const {
        expirationIndeces,
        chartExtents: { xExtents },
        selectedTrade,
        selectedExpiration,
        zeroDriftPrice,
        selectedDistribution,
        chartData,
        underlying,
        historicalVol
      } = store
      const axisFont = { fontSize: 12, fontFamily: "Roboto Condensed" }
      const tooltipFont = { fontSize: 11, fontFamily: "Roboto Condensed" }
      const expirationAnnotationProps = {
        y: () => 0,
        fill: "#ccc",
        stroke: "none",
        path: expPath,
        onClick: d => store.setSelectedExpiration(d.datum.hasExp)
      }

      const selectedExpirationAnnotationProps = {
        ...expirationAnnotationProps,
        fill: "orange"
      }

      const expTickFormat = d => {
        const m = Moment(chartData[d].date).startOf("day")
        return `${m.format("MMM-DD")} (${m.diff(
          Moment().startOf("day"),
          "days"
        )})`
      }

      return (
        <ChartCanvas
          ratio={ratio}
          width={width}
          height={height}
          ref={this.saveChartCanvasNode}
          margin={margin}
          type="svg"
          seriesName={underlying.ticker}
          data={chartData}
          xAccessor={d => d.date}
          xScaleProvider={discontinuousTimeScaleProvider}
          xExtents={xExtents}>
          <Chart
            id={1}
            yExtentsCalculator={this.yExtentsCalculator}
            height={400}
            padding={{ top: 30, bottom: 30 }}>
            <ExpirationArea expiration={selectedExpiration.expiration} />
            <XAxis
              axisAt="top"
              orient="top"
              {...axisFont}
              showGrid={true}
              {...xGrid}
              tickFormat={d => expTickFormat(d)}
              tickValues={expirationIndeces}
            />
            <XAxis axisAt="bottom" orient="bottom" showTicks={false} />
            <YAxis axisAt="right" {...axisFont} orient="left" ticks={5} />

            <CandlestickSeries />
            <OHLCTooltip {...tooltipFont} origin={[0, 10]} />
            {/* this represents an exp */}
            <Annotate
              with={SvgPathAnnotation}
              when={d => d.hasExp && d.hasExp !== selectedExpiration.expiration}
              usingProps={expirationAnnotationProps}
            />
            <Annotate
              with={SvgPathAnnotation}
              when={d => d.hasExp && d.hasExp === selectedExpiration.expiration}
              usingProps={selectedExpirationAnnotationProps}
            />
            <DistributionBars
              selectedDistribution={selectedDistribution}
              selectedExpiration={selectedExpiration}
            />
            <OutlookControl
              enabled={true}
              store={store}
              expiration={selectedExpiration.expiration}
              zeroDriftPrice={zeroDriftPrice}
            />

            <CrossHairCursor />
            <MouseCoordinateX
              snapX={true}
              at="bottom"
              orient="top"
              rectWidth={80}
              displayFormat={timeFormat("%Y-%m-%d")}
            />
            <MouseCoordinateY
              at="right"
              orient="left"
              displayFormat={format(".2f")}
            />
            {selectedTrade !== null && <Trade selectedTrade={selectedTrade} />}
          </Chart>
          <Chart
            id={2}
            origin={(w, h) => [0, h - 140]}
            padding={{ top: 10, bottom: 10 }}
            height={140}
            yExtentsCalculator={this.volYExtentsCalculator}>
            <XAxis axisAt="bottom" orient="bottom" {...axisFont} ticks={6} />
            <YAxis axisAt="right" {...axisFont} orient="left" ticks={5} />

            <ScatterSeries
              yAccessor={this.vol50yAccessor}
              marker={CircleMarker}
              markerProps={{ r: 3 }}
            />
            <LineSeries yAccessor={this.iv30dyAccessor} stroke="#2ca02c" />
            <LineSeries yAccessor={this.or20dHvyAccessor} stroke="orange" />
            <SingleValueTooltip
              {...tooltipFont}
              yLabel="30 day interpolated IV"
              labelStroke="#2ca02c"
              yAccessor={this.iv30dyAccessor}
              origin={[0, 14]}
            />
            <SingleValueTooltip
              {...tooltipFont}
              yLabel="20 day historical tick volatility"
              labelStroke="orange"
              yAccessor={this.or20dHvyAccessor}
              origin={[0, 28]}
            />
            <SingleValueTooltip
              {...tooltipFont}
              yLabel="Seed volatility at the 50 call delta"
              yAccessor={d => d.vol50}
              origin={[width / 2 - 24, 14]}
            />
            <MouseCoordinateY
              at="right"
              orient="left"
              displayFormat={format(".2f")}
            />
          </Chart>
        </ChartCanvas>
      )
    } catch (ex) {
      this.props.store.logException(ex)
    }
  }
}

CandleStickStockScaleChart = fitWidth(CandleStickStockScaleChart)

export default CandleStickStockScaleChart
