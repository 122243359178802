/**
- OK, so this is working, but I'm still not all that happy with it
  - can't generate name / title from combined forms
  - still feels very brittle
  - maybe form should become a class (with behaviours, computeds etc.) (should be a lot easier to test)
- form is still being persisted to the backend as "legs" (Array), but all of the view now work with form (Object)
 */

import _ from "lodash"
import { BT_FORM_GROUPS } from "../../constants"

const STANDARD_FIELDS = [
  "symbol",
  "endDate",
  "startDate",
  "leg",
  "ratio",
  "optionType",
  "strategyName"
]

export const formGroupMap = () => {
  return _.zipObject(
    _.map(BT_FORM_GROUPS, "key"),
    _.map(BT_FORM_GROUPS, g => _.omit(g, "key"))
  )
}

export const fieldsForGroups = groups => {
  return _.reduce(
    groups,
    (result, groupKey) => {
      result = _.concat(result, formGroupMap()[groupKey].fields)
      return result
    },
    []
  )
}

export const legacyLegsToForm = (legs, name) => {
  if (_.has(legs[0], "id")) {
    return [
      _.zipObject(_.keys(legs[0]), [_.map(legs, "weight"), _.map(legs, "id")]),
      null
    ]
  }

  const enabledGroups = _.map(
    _.filter(
      BT_FORM_GROUPS,
      g => g.abrv !== undefined && name.match(new RegExp(g.abrv, "g"))
    ),
    "key"
  )

  const props = _.concat(STANDARD_FIELDS, fieldsForGroups(enabledGroups))

  const form = _.reduce(
    props,
    (result, key, i) => {
      const values = _.map(legs, key)
      result[key] = values.length === 0 ? [""] : values
      if (key === "exitProfitLossPctMin" || key === "exitProfitLossPctMax") {
        result[key] =
          values.length === 0
            ? [""]
            : _.map(values, (v, i) => {
                if (i === 0) {
                  return v === "none" || v === "" ? "" : Math.abs(+v) / 0.01
                } else {
                  return ""
                }
              })
      }

      return result
    },
    {}
  )
  if (_.includes(enabledGroups, "symEntryExit")) {
    form.symbol = [""]
  }
  return [form, enabledGroups]
}

export const backtestNameComponents = form => {
  if (_.has(form, "id")) {
    return {
      params: null,
      symbol: null,
      strategy: null,
      paramSigs: null,
      formMeta: null
    }
  }

  const params = _.omit(form, STANDARD_FIELDS) // but we need to groupBy
  const paramSigs = _.reduce(
    params,
    (result, value, key) => {
      const group = _.find(BT_FORM_GROUPS, g => {
        return _.includes(g.fields, key)
      })
      if (_.includes(["absDelta", "stockOTMPct", "dte"], group.key)) {
        const paramVal = _.filter(
          _.map(group.fields, f => (params[f] ? params[f][0] : "")),
          v => v !== ""
        ).join(",")

        result.push(`${group.abrv}:${paramVal}`)
      } else {
        result.push(group.abrv)
      }
      return result
    },
    []
  )

  let index = 0
  const formMeta = _.reduce(
    _.filter(form.leg, l => l !== ""),
    (result, value, key) => {
      const paramData = _.map(paramSigs, sig => {
        const [abrv] = sig.split(":")
        const { title, fields } = _.find(BT_FORM_GROUPS, { abrv })
        const vals = _.map(fields, f => {
          return form[f][index]
        })
        return { title, abrv, vals: vals.join(",") }
      })
      const obj = {
        optionType: form.optionType[index],
        ratio: form.ratio[index],
        params:
          key === 0
            ? _.uniqBy(paramData, "abrv")
            : _.uniqBy(
                _.filter(paramData, ({ abrv }) =>
                  _.includes(
                    [
                      "Dlt",
                      "StkOTMPct",
                      "Dte",
                      "AdjLgDlt",
                      "AdjLg2Dlt",
                      "AdjMnMxDys",
                      "AdjTdLgDlt",
                      "AdjWLgNm"
                    ],
                    abrv
                  )
                ),
                "abrv"
              )
      }
      result.push(obj)
      index++
      return result
    },
    []
  )

  // console.log("***********") // OK< i think this is OK
  // console.log(formMeta)
  // console.log("***********")

  let symbol = _.filter(form.symbol, s => s !== "").join("+")
  if (_.has(form, "signalSymbol")) {
    symbol = _.uniq(form.signalSymbol).join("+")
  }
  const strategy = form.strategyName[0]
  return { params, symbol, strategy, paramSigs: _.uniq(paramSigs), formMeta }
}
