import React from "react"
import { inject } from "mobx-react"
import { Grid } from "semantic-ui-react"
import { Provider } from "rebass"
import { Router } from "react-router-dom"
import { Routes } from "./Routes"
import history from "../history"
import Nav from "./Nav"
import theme from "./theme"

@inject("userStore")
export default class App extends React.Component {
  render() {
    const { isLoggedIn } = this.props.userStore
    return (
      <Provider theme={theme}>
        <div>
          <Router history={history}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Nav />
                  <Routes />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Router>
        </div>
      </Provider>
    )
  }
}
