import { observable, runInAction, computed, action, toJS } from "mobx"
import { api } from "../services"
import _ from "lodash"
import Moment from "moment"
import { BACKTEST_URL } from "../config"
import { BT_STAT_PROP_NAMES } from "../constants"
import FORM_META from "../models/FormMeta"
import { track } from "../services/analytics"
import { legacyLegsToForm } from "../components/Backtest/FormUtils"
import { Strategy, Holding } from "../models"
import Papa from "papaparse"
import numeral from "numeral"

const STATUS_VALUE_MAP = {
  queued: "QUEUED",
  running: "PROCESSING",
  completed: "COMPLETE",
  "no-results": "COMPLETE",
  error: "COMPLETE"
}

const POSITION_CSV_HEADERS = [
  "ticker",
  "optionType",
  "expirDate",
  "leg",
  "qty",
  "strike",
  "tradeOptPx"
]

const VOL_SYMBOLS = ["VXX", "VIX", "SVXY", "XIV", "UVXY", "ZIV", "TVIX", "VIXY"]
// const tradeSignature = legs => {
//   const ls = _.reduce(
//     legs,
//     (result, { strike, expirDate, optionType, ratio }) => {
//       result.push(`${strike}:${expirDate}:${ratio}:${optionType}`)
//       return result
//     },
//     []
//   )
//   return ls.join("|")
// }

const PAGE_COUNT = 100

export class StrategyStore {
  @observable
  report = {
    loading: false,
    meta: {},
    graphReturns: [],
    optReturns: [],
    perYearData: [],
    startYear: null,
    stockReturns: [],
    trades: [],
    symbol: "",
    strategy: "",
    name: "",
    form: []
  }
  @observable
  strategies = []

  // @observable positions = []

  @observable
  errors = []
  @observable
  messages = []
  @observable
  activeStrategyId = null

  // @observable selectedExitTrade = null
  // @observable selectedPositionIndex = null

  // Backtest
  @observable
  backtestTrades = []
  @observable
  isBusy = false
  @observable
  isDeleting = false
  @observable
  isCombining = false
  @observable
  backtestStartEndDates = []
  @observable
  selectedBacktestIds = []
  @observable
  focusGroup = null
  @observable
  backtests = []
  @observable
  backtestsCount = null
  @observable
  backtestsPageNumber = 1

  // Scanning
  @observable
  scans = []
  @observable
  isScanning = false
  @observable
  isLoadingTrades = false
  @observable
  selectedTradeIndex = null
  @observable
  symbolQtyMap = null
  @observable
  preferredScanOrderQty = null

  // Holdings
  @observable
  isLoadingHoldings = false
  @observable
  selectedHoldingId = null
  @observable
  selectedHoldingIds = []
  @observable
  holdings = []

  @observable
  isPollingForExits = false
  @observable
  updatedHoldings = []

  holdingsUpdateCallback = undefined
  holdingsBackup = []

  backtestPaginationTokens = [
    Moment()
      .add(1, "days")
      .toISOString()
  ]

  constructor(userStore) {
    this.userStore = userStore
  }

  // COMPUTED

  // Backtest
  @computed
  get selectedBacktest() {
    return (
      _.find(this.backtests, {
        id: this.activeStrategyId
      }) || {}
    )
  }

  @computed
  get allSelected() {
    return (
      this.selectedBacktestIds.length !== 0 &&
      this.backtests.length === this.selectedBacktestIds.length
    )
  }

  @computed
  get backtestsForRender() {
    return _.map(this.backtests, b => {
      return { ...b, isChecked: _.includes(this.selectedBacktestIds, b.id) }
    })
  }

  @computed
  get backtestsForChooser() {
    return _.filter(
      this.backtests,
      ({ status, messages }) => status === "COMPLETE" && !messages
    )
  }

  @computed
  get canCombine() {
    const { isProOrEntPlan } = this.userStore
    if (!isProOrEntPlan) return false

    const selected = _.filter(this.backtests, b =>
      _.includes(this.selectedBacktestIds, b.id)
    )

    return (
      selected.length > 1 &&
      _.filter(
        selected,
        ({ name, messages }) => messages !== null || /wtgs/.test(name)
      ).length === 0
    )
  }

  @computed
  get backtestsPageNumberInfo() {
    const last =
      _.ceil(this.backtestsCount / 10) === this.backtestsPageNumber
        ? this.backtestsCount
        : this.backtestsPageNumber * PAGE_COUNT
    return [this.backtestsPageNumber * PAGE_COUNT - (PAGE_COUNT - 1), last]
  }

  // Scanning
  @computed
  get selectedTrade() {
    return this.scans.length && this.selectedTradeIndex !== null
      ? this.scans[this.selectedTradeIndex]
      : null
  }

  @computed
  get exitScanPayload() {
    const positions = _.map(
      _.reject(this.holdings, h => h.id === null || h.isStock),
      ({ legs, exitParams: exitParameters, id: positionId }) => {
        const trade = _.map(legs, l => {
          const leg = _.pick(l, [
            "ticker",
            "leg",
            "ratio",
            "optionType",
            "expirDate",
            "strike",
            "tradeOptPx"
          ])
          leg.optionType = leg.optionType.toLowerCase()
          return leg
        })
        return { positionId, trade, exitParameters }
      }
    )
    return { positions, dataSource: "nxcore" }
  }

  // action	symbol	tradeSize	strike	type	expire	tradeOptPx	delta	stockPx	minStock	maxStock	date
  @computed
  get scansForExport() {
    const data = this._csvExport("entry")
    return Papa.unparse(data)
  }

  // symbol, option type, expiration date, leg number, quantity, strike, option price
  @computed
  get positionsForExport() {
    const rez = _.reduce(
      this.holdings,
      (result, holding) => {
        const legs = _.map(holding.legs, l => {
          const expirDate = l.expirDate
            ? Moment(l.expirDate).format("DD-MMM-YY")
            : ""
          const optionType = l.optionType
            ? l.optionType.substr(0, 1).toUpperCase()
            : ""
          return [
            l.ticker,
            optionType,
            expirDate,
            l.leg,
            l.qty,
            l.strike,
            l.tradeOptPx
          ]
        })
        return [...result, ...legs]
      },
      []
    )
    // return rez

    return Papa.unparse(rez)
  }

  @computed
  get exitScansForExport() {
    const data = this._csvExport("exit")
    return Papa.unparse(data)
  }

  _csvExport(source) {
    console.log("ok...  _csvExport")
    const scans = source === "entry" ? this.scans : this.exitTrades
    return _.reduce(
      scans,
      (result, { qty, legs }, index) => {
        const ls = _.map(legs, l => {
          const tradeSize =
            source === "entry"
              ? (this.symbolQtyMap && this.symbolQtyMap[l.ticker]) || qty
              : qty
          console.log(`this is the quantity: ${qty}`)
          const { stockPx, stockMax, stockMin } = scans[index]
          return {
            action: l.ratio < 0 ? "SELL" : "BUY",
            symbol: l.ticker,
            tradeSize,
            strike: l.strike,
            type: l.optionType,
            expire: Moment(l.expirDate).format("MM/DD/YYYY"),
            tradeOptPx: l.tradeOptPx,
            delta: l.delta,
            stockPx,
            stockMin,
            stockMax,
            date: Moment().format("MM/DD/YYYY")
          }
        })
        result = [...result, ...ls]
        return result
      },
      []
    )
  }

  @computed
  get exitTrades() {
    return _.map(this.holdingsWithExits, ({ exit: { trade } }) => {
      return trade
    })
  }

  // Holdings
  @computed
  get holdingsSummary() {
    if (
      this.holdings.length &&
      !_.includes(_.map(this.holdings, "market"), null)
    ) {
      const grouped = _.groupBy(this.holdings, "ticker")
      const result = _.map(grouped, (holdings, key) => {
        let data = _.reduce(
          holdings,
          (result, { market }) => {
            _.forEach(_.keys(result), key => {
              result[key] += market[`spread${_.upperFirst(key)}`] || 0
            })
            result.stockPx = market.stockPx
            return result
          },
          {
            delta: 0,
            totalDelta: 0,
            ripDelta: 0,
            totalRipDelta: 0,
            gamma: 0,
            vega: 0,
            stockPx: 0
          }
        )
        data = _.mapValues(data, (v, k) => (k !== "stockPx" ? v * 100 : v))
        return { ticker: key, ...data }
      })

      return result
    } else {
      return []
    }
  }

  @computed
  get savedHoldings() {
    return _.reject(this.holdings, ({ id }) => id === null)
  }

  @computed
  get allHoldingsChecked() {
    return this.selectedHoldingIds.length === 0
      ? false
      : this.selectedHoldingIds.length === this.savedHoldings.length
  }

  @computed
  get selectedHolding() {
    return this.holdings.length && this.selectedHoldingId !== null
      ? _.find(this.holdings, { id: this.selectedHoldingId })
      : null
  }

  @computed
  get holdingToBacktestMap() {
    return this.holdings.length > 0
      ? _.reduce(
          this.holdings,
          (result, { backtestId, exitParams, signature }) => {
            if (backtestId && exitParams) {
              result[signature] = [backtestId, exitParams]
            }
            return result
          },
          {}
        )
      : {}
  }

  @computed
  get holdingsForAGGrid() {
    return _.map(this.holdings, holding => holding.toJSON())
  }

  @computed
  get holdingsWithExits() {
    return _.filter(
      this.holdings,
      ({ exit, isStock }) => !isStock && exit !== null && exit.reason !== null
    )
  }

  // ACTIONS

  // General

  @action("appInit")
  async appInit() {
    await Promise.all([this.getBacktestsCount(), this.getBacktests()])
    this.setupSubscriptions()
    // this.pollForCompletedBacktests()
  }

  @action("loadGlobals")
  async loadGlobals() {
    const {
      data: {
        getGlobals: { enterpriseUsers, startDate, endDate }
      }
    } = await api.loadGlobals()
    this.userStore.enterpriseUsers = enterpriseUsers
    this.backtestStartEndDates = [startDate, endDate]
    return Promise.resolve()
  }

  @action("clearMessages")
  clearMessages = async () => {
    this.messages = []
  }

  // Backtest
  @action("setActiveBacktest")
  setActiveBacktest(backtestId) {
    this.activeStrategyId = backtestId
  }

  @action("setFocusGroup")
  setFocusGroup = key => {
    if (key === "exitAtSignal") {
      this.focusGroup = "symbol"
    } else if (_.find(FORM_META, g => g.group === key)) {
      this.focusGroup = key
    } else if (key === "all") {
      this.focusGroup = "all"
    } else {
      this.focusGroup = FORM_META[key].group
    }
  }

  @action("toggleSelectAllBacktests")
  toggleSelectAllBacktests() {
    this.selectedBacktestIds =
      this.selectedBacktestIds.length === this.backtests.length
        ? []
        : _.map(this.backtests, "id")
  }

  @action("toggleBacktestSelect")
  toggleBacktestSelect(id) {
    if (_.includes(this.selectedBacktestIds, id)) {
      this.selectedBacktestIds = _.without(this.selectedBacktestIds, id)
    } else {
      this.selectedBacktestIds.push(id)
    }
  }

  @action("deleteSelectedBacktests")
  async archiveSelectedBacktests() {
    this.isDeleting = true
    const selected = _.filter(this.backtests, b =>
      _.includes(this.selectedBacktestIds, b.id)
    )
    const payload = _.map(selected, ({ id, messages, stats }) => ({
      id,
      status: "ARCHIVED"
    }))

    const updates = await api.updateBacktests(payload)
    const archivedIds = _.map(updates, "data.updateBacktest.id")
    this.backtests = _.reject(toJS(this.backtests), ({ id }) =>
      _.includes(archivedIds, id)
    )
    this.selectedBacktestIds = []
    this.isDeleting = false
  }

  // TODO: send a metric when we've had to update this way
  @action("pollForCompletedBacktests")
  pollForCompletedBacktests() {
    const poll = async () => {
      const toResolve = _.filter(this.backtests, b => {
        const olderThanFiveMins = Moment(b.createdAt).isBefore(
          Moment().subtract(3, "minutes")
        )
        return b.status === "PROCESSING" && olderThanFiveMins
      })
      if (toResolve.length) {
        const ids = _.map(toResolve, "id")
        let statuses = await api.getBacktestStatus(ids)
        statuses = _.reject(
          statuses,
          ({ status }) => status === "queued" || status === "running"
        )

        if (statuses.length) {
          const { id: ownerId } = this.userStore.user
          let payload = _.map(statuses, async ({ status, id }) => {
            const messages = _.includes(["no-results", "error"], status)
              ? ["no results"]
              : null

            const {
              data: {
                getBacktest: { stats }
              }
            } = await api.getBacktestStats({ id, ownerId })

            return {
              id,
              ownerId,
              status: STATUS_VALUE_MAP[status],
              messages,
              stats
            }
          })

          payload = await Promise.all(payload)
          const updates = await api.updateBacktests(payload)
          _.forEach(
            updates,
            ({
              data: {
                updateBacktest: { id, status, messages, stats }
              }
            }) => {
              let bt = _.find(this.backtests, { id })
              if (bt) {
                bt.status = status
                bt.messages = messages
                bt.stats = _.zipObject(_.keys(BT_STAT_PROP_NAMES), stats)
              }
            }
          )
        }
      }
      setTimeout(poll, 180000)
    }
    poll()
  }

  @action("getBacktestForm")
  getBacktestForm = async (taskId, format = null) => {
    const form = await api.getBacktestForm(taskId, format)
    return Promise.resolve(form)
  }

  @action("getBacktestName")
  getBacktestName = async taskId => {
    const { id: ownerId } = this.userStore.user
    const {
      data: {
        getBacktest: { name }
      }
    } = await api.getBacktestName({ id: taskId, ownerId })
    return Promise.resolve(name)
  }

  @action("getBacktestsCount")
  getBacktestsCount = async () => {
    const { id: ownerId } = this.userStore.user
    const {
      data: {
        getUserBacktestsCount: { count }
      }
    } = await api.getBacktestsCount({ ownerId, status_not: "ARCHIVED" })
    this.backtestsCount = count
    return Promise.resolve(count)
  }

  @action("getBacktests")
  getBacktests = async (count = 1) => {
    this.isBusy = true
    let nextToken = null
    if (count !== null) {
      if (count < 0) {
        this.backtestPaginationTokens = _.slice(
          this.backtestPaginationTokens,
          0,
          this.backtestPaginationTokens.length - 2
        )
      }
      nextToken = _.last(this.backtestPaginationTokens) || null
    }

    const { id: ownerId } = this.userStore.user
    try {
      const {
        data: {
          getUserBacktestsLam: { backtests }
        }
      } = await api.getBacktests({
        ownerId,
        count: count ? Math.abs(count * PAGE_COUNT) : null,
        nextToken
      })
      if (backtests.length) {
        this.backtestPaginationTokens.push(_.last(backtests).createdAt)
        // this.backtests = backtests
        this.backtests = _.map(backtests, bt => {
          if (bt.stats && bt.stats.length) {
            const stats = _.zipObject(_.keys(BT_STAT_PROP_NAMES), bt.stats)
            return { ...bt, stats, form: JSON.parse(bt.form) }
          } else {
            return bt
          }
        })
        this.backtestsPageNumber = this.backtestPaginationTokens.length - 1
        this.isBusy = false
      }
      return Promise.resolve()
    } catch (error) {
      this.isBusy = false
      console.log(error)
      return Promise.reject()
    }

    // TODO: - revisit this
    //       - For each Backtest that doesn't have a user record, add one
    //       - you could do this in init

    // const [userBacktestCount, backtestsResult] = await Promise.all([
    //   api.getBacktestsCount(userId),
    //   api.getBacktests(auth0UserId)
    // ])
    //
    // // how about getting both as counts first
    // const { data: { User: { _backtestsMeta: { count } } } } = userBacktestCount
    // const { data: { allBacktests } } = backtestsResult
    //
    // this.backtests = allBacktests
    //
    // if (allBacktests.length > count) {
    //   const ids = _.map(allBacktests, "id")
    //   api.addBacktestsToUser(userId, ids)
    // }
    //
    // return Promise.resolve("done")
  }
  // NIU July 2018
  // @action("getBacktestsCount")
  // getBacktestsCount = userId => {
  //   return api.getBacktestsCount(userId)
  // }

  @action("postJSONBacktestRequest")
  postJSONBacktestRequest = async (form, name) => {
    console.log(`postBacktestRequest: ${name}`)
    const { id: ownerId } = this.userStore.currentUser
    const { id } = await api.postJSONBacktestRequest(form, name)
    console.log(`Backtest id: ${id}`)

    track("Backtest Create", {
      backtestId: id,
      url: `${BACKTEST_URL}/${id}`
    })

    // we're posting form as null here because we not handling weighted with this method
    // (and we stopped posting forms to GQL)
    try {
      const {
        data: { createBacktest }
      } = await api.createBacktestRecord({
        input: {
          id,
          ownerId,
          name,
          form: null,
          status: "PROCESSING"
        }
      })
      this.backtests = _.unionBy([createBacktest], this.backtests, "id")

      console.log(_.map(this.backtests, "id"))
      return Promise.resolve({ id })
    } catch (e) {
      console.log(e)
    }
  }

  @action("postBacktestRequest")
  postBacktestRequest = async (form, name) => {
    console.log(`postBacktestRequest: ${name}`)
    const { id: ownerId } = this.userStore.currentUser
    const { id } = await api.postBacktestRequest(form)
    console.log(`Backtest id: ${id}`)

    track("Backtest Create", {
      backtestId: id,
      url: `${BACKTEST_URL}/${id}`
    })

    const isCombinedPost = _.has(form[0], "weight")
    try {
      const {
        data: { createBacktest }
      } = await api.createBacktestRecord({
        input: {
          id,
          ownerId,
          name,
          form: isCombinedPost ? form : null,
          status: "PROCESSING"
        }
      })

      const {
        data: {
          getBacktest: { form }
        }
      } = await api.getBacktest({ id, ownerId })

      this.backtests = _.unionBy(
        [{ ...createBacktest, form }],
        this.backtests,
        "id"
      )
      return Promise.resolve({ id })
    } catch (e) {
      console.log(e)
    }
  }

  @action("combineBacktests")
  combineBacktests = async data => {
    console.log("data", data)
    this.isCombining = true
    const { id: userId } = this.userStore.currentUser

    const [payload, name] = _.reduce(
      data,
      (result, { weight, id }) => {
        const { name } = _.find(this.backtests, { id })
        result[0].push({ weight, id })
        result[1] = result[1].length === 0 ? name : `${result[1]}|${name}`
        return result
      },
      [[], ""]
    )

    console.log("paayload", payload)
    const { id } = await api.postBacktestRequest(payload)
    console.log(`Backtest id: ${id}`)

    track("Backtest Create", {
      backtestId: id,
      url: `${BACKTEST_URL}/${id}`
    })

    try {
      const {
        data: { createBacktest }
      } = await api.createBacktestRecord({
        input: {
          ownerId: userId,
          id,
          name,
          form: null,
          status: "PROCESSING"
        }
      })
      this.isCombining = false
      this.selectedBacktestIds = []

      this.backtests = _.unionBy(
        [{ ...createBacktest, form: null }],
        this.backtests,
        "id"
      )

      return Promise.resolve({ id })
    } catch (e) {
      console.log(e)
    }
  }

  // TODO: quite a lot of legacyLegsToForm work to do here
  @action("loadBacktest")
  loadBacktest = async id => {
    this.report.name = ""
    this.report.title = ""
    this.report.loading = true
    this.isLoadingTrades = true
    let combinedTaskIds,
      combinedForms = undefined

    const { id: ownerId } = this.userStore.user

    const bt = _.find(this.backtests, { id })
    const backtestPromise = bt
      ? Promise.resolve({
          data: { getBacktest: bt }
        })
      : api.getBacktest({ id, ownerId })

    let {
      data: {
        getBacktest: { name, form }
      }
    } = await backtestPromise

    const isCombined = name.split("|").length > 1

    if (isCombined) {
      form = await api.getcombinedBacktestIds(id)
      combinedTaskIds = _.map(form, "id")
      combinedForms = await Promise.all(
        _.map(combinedTaskIds, id => api.getBacktestForm(id, "json"))
      )

      let names = await Promise.all(
        _.map(combinedTaskIds, id => api.getBacktestName({ id }))
      )

      combinedForms = _.map(combinedForms, (form, i) => {
        const {
          data: {
            getBacktest: { name }
          }
        } = names[i]
        return [name, form]
      })
    }

    const { symbol } = isCombined ? { symbol: null } : form.general.symbols[0]

    let fetched = await api.fetchBacktestResult(id)

    const { optReturns, stockReturns } = fetched

    const earliestStart = Moment(stockReturns[0]["Date"]).isAfter(
      Moment(optReturns[0]["Date"])
    )
      ? stockReturns[0]["Date"]
      : optReturns[0]["Date"]

    const stocks = _.slice(
      stockReturns,
      _.findIndex(stockReturns, { Date: earliestStart })
    )

    const graphReturns = _.reduce(
      stocks,
      (results, d, i) => {
        const stock = _.includes(VOL_SYMBOLS, symbol)
          ? (1 + d["Return"]) * results[i].stock
          : results[i].stock + d["Return"] * 100000
        const optItm = _.find(optReturns, { Date: d["Date"] })
        const optRtrn = optItm ? optItm["Return"] : 0
        const option = results[i].option + optRtrn * 100000

        results.push({
          date: Moment(d["Date"]).toDate(),
          dateString: d["Date"],
          stock,
          option
        })
        return results
      },
      [
        {
          date: "",
          dateString: "",
          stock: 100000,
          option: 100000
        }
      ]
    )

    this.report = {
      ...fetched,
      graphReturns,
      combinedForms,
      name,
      form,
      loading: false
    }

    const {
      data: {
        getBacktestTrades: { items: trades }
      }
    } = await api.fetchBacktestResultTrades(id)

    this.backtestTrades = { trades, raw: null }
    this.isLoadingTrades = false
  }

  @action("getBacktestDataZip")
  getBacktestDataZip = id => {
    return api.getBacktestDataZip(id)
  }

  @action("setupSubscriptions")
  setupSubscriptions = () => {
    const { id: ownerId } = this.userStore.user

    api.subscribeBacktests(ownerId, async backtest => {
      if (!backtest) {
        console.log("Backtest Subscription error:")
      } else {
        console.log(`BT callback: ${backtest.id}`)
        const {
          data: {
            getBacktest: { status, messages, stats }
          }
        } = await api.getBacktest({ id: backtest.id, ownerId })
        let bt = _.find(this.backtests, { id: backtest.id })
        if (bt) {
          bt.status = status
          bt.messages = messages
          bt.stats = _.zipObject(_.keys(BT_STAT_PROP_NAMES), stats)
        }
      }
    })
  }

  // Scanning
  @action("setSelectedTradeIndex")
  setSelectedTradeIndex(index) {
    this.selectedTradeIndex = index
  }

  _generateOverrides = tickers => {
    // const tickers = _.keys(this.symbolQtyMap)
    const weight = +numeral(1.0 / tickers.length).format("0.00")
    return {
      symbols: _.map(tickers, symbol => ({
        symbol,
        weight,
        signals: null
      }))
    }
  }

  @action("getScanResult")
  getScanResult = async backtestId => {
    let scanPayload = { backtest: backtestId }

    // are we only doing this for NJ?
    // need better condition here
    // TODO: factor out the overides code (It will be used for both entries and exits)
    if (this.symbolQtyMap) {
      console.log("yup, looks like we have a symbolQtyMap")
      const overrides = this._generateOverrides(_.keys(this.symbolQtyMap))
      scanPayload = {
        ...scanPayload,
        scannerInput: { tradesPerSymbol: 1 },
        overrides
      }
    }

    try {
      this.isScanning = true
      let [scans, form] = await Promise.all([
        api.getScanResult(scanPayload),
        this.getBacktestForm(backtestId, "json")
      ])

      if (_.isArray(form)) {
        form = new Strategy(null, null, null, form).toJS()
      }
      const { exit: exitParams } = form

      // const DATE_FORMATS = ["DD MMM YY", "DD-MMM-YY", "M/D/YYYY"]
      this.scans = _.map(scans, trade => {
        const legs = _.map(trade, leg => {
          const exp = Moment(leg.expirDate)
            .format("MMM DD")
            .toUpperCase()
          console.log(`leg.expirDate: ${leg.expirDate} : formatted: ${exp}`)
          return { ...leg, exp }
        })
        const holding = new Holding({
          legs,
          ticker: legs[0].ticker,
          backtestId,
          exitParams
        })
        if (this.symbolQtyMap && this.symbolQtyMap[trade[0].ticker]) {
          holding.qty = this.symbolQtyMap[trade[0].ticker]
        } else if (this.preferredScanOrderQty !== null) {
          holding.qty = this.preferredScanOrderQty
        }
        return holding
      })
      if (scans.length === 0) {
        this.messages = ["Your scan returned no results"]
      }
    } catch (e) {
      this.messages = ["An application error occured"]
    }
    this.preferredScanOrderQty = null
    this.isScanning = false
  }

  @action("getScanExitResult")
  getScanExitResult = async (source = "user") => {
    if (source === "poll") {
      this.isPollingForExits = true
    } else {
      this.isScanning = true
    }

    let overrides = {}
    if (this.userStore.isNJ) {
      const tickers = _.uniq(_.map(this.holdings, "ticker"))
      overrides = _.omit(this._generateOverrides(tickers), "symbols")
    }

    const exits = await api.getScanExitResult(this.exitScanPayload)
    const symbols = _.map(_.filter(this.holdings, { isStock: true }), "ticker")

    const stockPricesPromise =
      symbols.length > 0 ? api.fetchLastTrade(symbols) : Promise.resolve([])
    const { data: stockPrices } = await stockPricesPromise

    const toReplace = _.map(this.holdings, h => {
      const exit = _.find(exits, ({ positionId }) => positionId === h.id)
      if (exit && exit.trade) {
        const legs = _.map(h.legs, (l, i) => {
          let leg = { ...l, ...exit.trade[i] }
          // flip these as we want an exit
          leg.ratio *= -1
          leg.qty *= -1
          return leg
        })
        // this trade is (also) used for rendering market
        exit.trade = new Holding({
          legs,
          qty: h.qty * -1,
          positionType: "closing",
          id: h.id
        })
        h.exit = exit
      }
      if (h.isStock) {
        const { price } = _.find(stockPrices, { symbol: h.ticker })
        const delta = h.spreadTradePrice < 0 ? -1 : 1
        h.exit = {
          trade: {
            stockPx: price,
            mark: price,
            optionDelta: delta,
            spreadTotalDelta: delta * h.qty,
            spreadTotalRipDelta: delta * h.qty
          }
        }
      }
      return h
    })
    this.holdings.replace(toReplace)
    this.isScanning = false
    this.isPollingForExits = false
  }

  @action("getFilterHits")
  getFilterHits() {
    const tickers = _.keys(this.symbolQtyMap)
    if (tickers.length) {
      return api.getFilterHits(tickers)
    } else {
      return Promise.resolve()
    }
  }

  @action("setQtyForScan")
  setQtyForScan(id, qty) {
    const toreplace = _.map(this.scans, s => {
      if (s.id === id) {
        console.log("are we coming in here?")
        console.log(s)
        s.qty = qty

        return s
      } else {
        return s
      }
    })
    this.scans.replace(toreplace)
  }

  // Holdings
  @action("setSelectedExitTrade")
  setSelectedExitTrade(trade) {
    this.selectedExitTrade = trade
  }

  @action("getHoldings")
  getHoldings = async () => {
    this.isLoadingHoldings = true
    const { id: ownerId } = this.userStore.currentUser
    const userHoldings = await api.getHoldings(ownerId)
    const expired = _.filter(userHoldings, ({ legs }) => {
      const exp = _.find(legs, ({ expirDate }) => {
        return Moment(expirDate)
          .endOf("d")
          .isBefore(Moment())
      })
      return exp !== undefined
    })

    if (expired.length) {
      console.log(`archiving expired holdings count: ${expired.length}`)
      const inputs = _.map(expired, ({ id }) => ({
        input: { id, ownerId, status: "ARCHIVED" }
      }))
      await api.updateHoldings(inputs)
    }

    this.holdings = _.map(
      _.filter(userHoldings, ({ id }) => !_.includes(_.map(expired, "id"), id)),
      h => new Holding(h)
    )
    this.isLoadingHoldings = false
    return Promise.resolve()
  }

  @action("archiveSelectedHoldings")
  async archiveSelectedHoldings() {
    const { id: ownerId } = this.userStore.currentUser
    const inputs = _.map(this.selectedHoldingIds, id => ({
      input: { id, ownerId, status: "ARCHIVED" }
    }))
    const archived = await api.updateHoldings(inputs)
    this.selectedHoldingId = null
    const archivedIds = _.map(archived, "data.updateHolding.id")
    this.selectedHoldingIds = _.reject(this.selectedHoldingIds, id =>
      _.includes(archivedIds, id)
    )
    this.holdings = _.reject(this.holdings, ({ id }) =>
      _.includes(archivedIds, id)
    )
    return Promise.resolve()
  }

  // setHoldingsUpdateCallback = cb => {
  //   this.holdingsUpdateCallback = cb
  // }

  @action("createSpiderRockOrder")
  createSpiderRockOrder = async trade => {
    let orderId = null
    try {
      const { data } = await api.createSpiderRockOrder(trade)
      console.log(data)
      orderId = data.orderId
    } catch (e) {
      this.messages = ["An application error occured"]
    }
    return Promise.resolve({ orderId })
  }

  @action("pollForSpiderRockFills")
  pollForSpiderRockFills() {
    const poll = async () => {
      if (this.holdings.length) {
        console.log("poll fills")
        const { id: ownerId } = this.userStore.currentUser
        let { data: fills } = await api.pollForSpiderRockFills()
        const updated = []
        _.forEach(fills, ({ orderId, fillQty, avgFillPrice }) => {
          const hol = _.find(this.holdings, { orderId })
          if (hol && fillQty !== 0 && hol.qty !== fillQty) {
            hol.avgFillPrice = avgFillPrice
            hol.qty = fillQty
            if (fillQty > 0) {
              hol.status = "ACTIVE"
            }
            const { id, status, qty } = hol
            api.updateHolding({ input: { id, ownerId, qty, status } })
            updated.push(hol.id)
          }
        })
        this.updatedHoldings = updated
      }
      setTimeout(poll, 5000)
    }
    poll()
  }

  @action("pollForExits")
  pollForExits() {
    const poll = async () => {
      if (this.holdings.length) {
        console.log("poll exits")
        this.getScanExitResult("poll")
      }
      setTimeout(poll, 5000)
    }
    poll()
  }

  @action("parseHoldingsCsv")
  async parseHoldingsCsv(text) {
    this.holdingsBackup = this.holdings.slice()
    const parsed = Papa.parse(text, {
      skipEmptyLines: true,
      dynamicTyping: true
    }).data

    let data = _.map(parsed, leg => {
      const result = _.zipObject(POSITION_CSV_HEADERS, leg)
      const expirDate =
        result.expirDate !== ""
          ? Moment(result.expirDate, [
              "DD MMM YY",
              "DD-MMM-YY",
              "M/D/YYYY"
            ]).format("DD MMM YY")
          : null
      const legNum = result.leg === "" ? 1 : result.leg
      return { ...result, expirDate, leg: legNum }
    })
    const incoming = []
    // group legs into trades on leg num
    do {
      const legs = []
      const legOne = data.shift()
      legs.push(legOne)
      while (data[0] && data[0].leg - legOne.leg >= 1) {
        legs.push(data.shift())
      }
      const holding = Holding.holdingFromArray(legs)

      // if (this.holdingToBacktestMap
      const [backtestId, exitParams] = this.holdingToBacktestMap[
        holding.signature
      ] || [null, null]
      if (backtestId && exitParams) {
        holding.backtestId = backtestId
        holding.exitParams = exitParams
      }
      if (!holding.isExpired) {
        incoming.push(holding)
      }
    } while (data.length > 0)

    this.holdings.replace(incoming)
  }

  @action("clearPastedCvsHoldings")
  clearPastedCvsHoldings() {
    // this.holdings = _.reject(this.holdings, ({ id }) => id === null)
    // this.holdings.replace(this.holdingsBackup)
  }

  @action("setSymbolsQtyMap")
  setSymbolsQtyMap(map) {
    this.symbolQtyMap = map
  }

  @action("clearSymbolsQtyMap")
  clearSymbolsQtyMap() {
    this.symbolQtyMap = null
  }

  @action("replaceHoldings")
  replaceHoldings = async () => {
    const { id: ownerId } = this.userStore.currentUser
    // archive existing
    const ids = _.map(this.holdingsBackup, "id")
    if (ids.length > 0) {
      const inputs = _.map(ids, id => ({
        input: { id, ownerId, status: "ARCHIVED" }
      }))
      await api.updateHoldings(inputs)
    }

    const results = await api.createHoldings(ownerId, this.holdings)
    // console.log(results)

    const toReplace = _.map(this.holdings, (h, i) => {
      const {
        data: {
          createHolding: { id }
        }
      } = results[i]
      return new Holding({ ...h, id })
    })

    this.holdings.replace(toReplace)

    return Promise.resolve()
  }

  @action("createStagedHolding")
  createStagedHolding = async holding => {
    const { id: ownerId } = this.userStore.currentUser
    holding.qty = 0 // SR callbacks will increment this on fill
    const orderInput = _.omit(holding, [
      "id",
      "tradePrice",
      "exit",
      "avgFillPrice",
      "userId"
    ])
    const {
      data: {
        createHolding: { id }
      }
    } = await api.createHolding({
      input: {
        ...orderInput,
        ownerId,
        legs: JSON.stringify(orderInput.legs),
        exitParams: JSON.stringify(orderInput.exitParams),
        signature: holding.signature,
        status: "STAGED"
      }
    })
    holding.id = id
    this.holdings.push(holding)
    return Promise.resolve()
  }

  @action("setSelectedHoldingId")
  setSelectedHoldingId(id) {
    this.selectedHoldingId = id
  }

  @action("setSelectedHoldingIds")
  setSelectedHoldingIds(ids) {
    this.selectedHoldingIds = ids
  }

  @action("updateHolding")
  updateHolding = async ({ qty }) => {
    const { id: ownerId } = this.userStore.currentUser
    this.selectedHolding.qty = qty
    await api.updateHolding({
      input: {
        id: this.selectedHolding.id,
        ownerId,
        qty
      }
    })
    return Promise.resolve()
  }

  @action("pairBacktestToHoldings")
  pairBacktestToHoldings = async ({ holdingIds, backtestId }) => {
    let form = await this.getBacktestForm(backtestId)
    if (_.isArray(form)) {
      form = new Strategy(null, null, null, form).toJS()
    }
    const { exit: exitParams } = form
    const { id: ownerId } = this.userStore.currentUser
    // JSON.stringify(exitParams)
    const inputs = _.map(holdingIds, id => ({
      input: {
        id,
        ownerId,
        backtestId,
        exitParams: JSON.stringify(exitParams)
      }
    }))

    await api.updateHoldings(inputs)

    this.holdings.replace(
      _.map(this.holdings, h => {
        const { id } = h
        return _.includes(holdingIds, id)
          ? new Holding({
              ...h,
              backtestId,
              exitParams
            })
          : h
      })
    )

    this.selectedHoldingIds = []
    return Promise.resolve()
  }

  @action("copyTradeToClipboard")
  copyTradeToClipboard = ({ id, exit } = { exit: false }) => {
    const holding = _.find(this.holdings, { id })
    if (exit) {
      holding.exit.trade.copyToClipboard()
    } else {
      holding.copyToClipboard()
    }
  }

  // TradeFinder
  createStrategy = async (legs, name, plan) => {
    const { userId: ownerId } = this.userStore.currentUser
    const {
      data: { createStrategy }
    } = await api.createStrategy(legs, ownerId, name)
    this.strategies.push(createStrategy)
  }
}
