import _ from "lodash"
import mixpanel from "mixpanel-browser"

if (process.env.REACT_APP_ENV === "production") {
  mixpanel.init("2920fcd7727be6fb6da9d440ffd6e793")
}

const _hsq = (window._hsq = window._hsq || [])

export const track = async (event, properties = null) => {
  if (process.env.REACT_APP_ENV === "production") {
    mixpanel.track(event, properties)
    _hsq.push(["setPath", _.camelCase(event)])
    _hsq.push(["trackPageView"])
  } else {
    console.log(`TRACK :: ${event}`)
    console.log(properties)
    console.log("________________________")
  }
}

export const identify = async (userId, traits = null) => {
  // const promise = new Promise()
  const { email, plan } = traits
  if (process.env.REACT_APP_ENV === "production") {
    console.log(`identify: ${userId} traits: ${JSON.stringify(traits)}`)
    mixpanel.identify(userId)
    mixpanel.people.set({ $email: email })
    _hsq.push(["identify", { email }])
  } else {
    console.log(`IDENTIFY :: ${userId}`)
    console.log(traits)
    console.log("________________________")
  }
}

export const alias = userId => {
  if (process.env.REACT_APP_ENV === "production") {
    mixpanel.alias(userId)
  } else {
    console.log(`ALIAS :: ${userId}`)
    console.log("________________________")
  }
}
