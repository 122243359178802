// REFACTOR TODO:
// - user paste one column into symbols

import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { toJS, computed, observable, runInAction } from "mobx"
import { NavLink, Link } from "react-router-dom"
import {
  Dropdown,
  Grid,
  Message,
  Form,
  Input,
  List,
  Label,
  Checkbox,
  Icon,
  Popup,
  Button,
  Loader,
  Modal,
  Breadcrumb,
  Segment,
  Header,
  Table,
  TextArea
} from "semantic-ui-react"

import { FormGroup } from "../Styled"
import _ from "lodash"
import Moment from "moment"
import symbols from "../../util/btSymbols"
import {
  legacyLegsToForm,
  fieldsForGroups,
  formGroupMap,
  backtestNameComponents
} from "./FormUtils"

import Baby from "papaparse"

import { HELP_SNIPPETS } from "./Help"
import {
  STRATEGIES,
  BT_FORM_GROUPS,
  legsForStrategy,
  CONSTRAINTS,
  VALID_DATE_FORMATS
} from "../../constants"

import { backtestTemplate } from "../../util/templates"

import validate from "validate.js"
validate.validators.startDateBoundary = (value, options, key, attributes) => {
  return !Moment(value).isSameOrAfter(options.start)
    ? ` must be on or after ${options.start}`
    : null
}
validate.validators.endDateBoundary = (value, options, key, attributes) => {
  return !Moment(value).isSameOrBefore(options.end)
    ? ` must be on or before ${options.end}`
    : null
}
validate.validators.startDateBeforeEndInput = (
  value,
  options,
  key,
  attributes
) => {
  return !Moment(value).isBefore(options.end)
    ? ` must be before your chosen end date: ${options.end}`
    : null
}
validate.validators.endDateAfterStartInput = (
  value,
  options,
  key,
  attributes
) => {
  return !Moment(value).isAfter(options.start)
    ? ` must be after your chosen start date: ${options.start}`
    : null
}
validate.validators.dateFormat = (value, options, key, attributes) => {
  return !(
    Moment(value, "YYYY-MM-DD", true).isValid() ||
    Moment(value, "M/D/YYYY", true).isValid()
  )
    ? ` value must be input as either YYYY-MM-DD or M/D/YYYY`
    : null
}

validate.validators.minMax = (value, options, key, attributes) => {
  const [, field, minOrMax] = key.match(/(\w+)(Max|Min)/)
  if (minOrMax === "Max") {
    const minValue = attributes[`${field}Min`]
    if (minValue === "none" || value === "none") {
      return null
    }
    return +value <= +minValue ? ` must be greater than ${field} min` : null
  } else {
    const maxValue = attributes[`${field}Max`]
    if (maxValue === "none" || value === "none") {
      return null
    }
    return +value >= +maxValue ? ` must be less than ${field} max` : null
  }
}

validate.validators.numericOr = (value, options, key, attributes) => {
  if (value === options.text) return null
  let results = []
  if (!validate.isNumber(+value)) {
    results.push(`^value must be either "${options.text}" or a valid number`)
  }

  if (options.range) {
    let [min, max] = options.range
    if (max === "maxLegs") {
      const { maxLegs } = attributes
      if (+value < min || +value > maxLegs) {
        results.push(
          `^value must be a number between ${
            options.range[0]
          } and the number of legs in the strategy (${maxLegs})`
        )
      }
    } else {
      if (+value < min || +value > max) {
        results.push(
          `^value must be a number between ${options.range[0]} and ${
            options.range[1]
          }`
        )
      }
    }
  }

  if (options.mustBeNegative && +value > 0) {
    results.push(`^${options.fieldName} must be negative`)
  }

  if (options.mustBePositive && +value < 0) {
    results.push(`^${options.fieldName} must be positive`)
  }

  if (options.mustBeInteger && !validate.isInteger(+value)) {
    results.push(`^${options.fieldName} value must be a whole number`)
  }

  return results
}

const optionsGen = arr => {
  return _.map(arr, k => ({
    key: k,
    text: _.startCase(k),
    value: k
  }))
}

const labelStyle = {
  width: "20em",
  verticalAlign: "middle",
  textDecoration: "underline"
}

const CSV_FEILD_MAP = {
  symbol: ["symbol", "weightings"],
  signalSymbol: ["signalSymbol", "signalEntryDate", "signalExitDate"]
}

const MULTI_LEG_GROUPS = _.map(
  _.filter(BT_FORM_GROUPS, { isMultiLeg: true }),
  "key"
)

const MULTI_LEG_FIELDS = _.flatten(
  _.map(
    _.filter(BT_FORM_GROUPS, ({ isMultiLeg, key }) => {
      return isMultiLeg && !_.includes(["dte", "stockOTMPct", "absDelta"], key)
    }),
    "fields"
  )
)

const ErrorMessage = ({ error, k }) => {
  const group = _.find(BT_FORM_GROUPS, g => _.includes(g.fields, k))
  const title = group ? `${group.title} — ` : ""
  const msg = _.replace(error.join("; "), /::\d/, "")
  return <Message.Item key={k}> {`${title}${msg}`} </Message.Item>
}

const Errors = ({ errors }) => {
  return (
    <Message>
      <Message.Header> There are errors with your input </Message.Header>
      <Message.List>
        {_.map(errors, (error, key) => {
          return <ErrorMessage k={key} key={key} error={error} />
        })}
      </Message.List>
    </Message>
  )
}

@observer
class CsvModal extends Component {
  handleChange = (columnIndex, rowIndex) => {
    const { propsToRender } = this.props
    return {
      value: propsToRender[columnIndex][rowIndex],
      onChange: (e, { value }) => {
        propsToRender[columnIndex].splice(rowIndex, 1, value)
      }
    }
  }

  render() {
    const {
      trigger,
      groupMeta,
      onOKClick,
      onClearClick,
      propsToRender,
      open
    } = this.props
    const { csvHeaders: headerRow, title } = groupMeta
    const rowCount = propsToRender[0].length
    const columnCount = headerRow.length

    return (
      <Modal dimmer="inverted" open={open} trigger={trigger}>
        <Modal.Header> {title} </Modal.Header>
        <Modal.Content scrolling>
          <Table celled>
            <Table.Header>
              <Table.Row>
                {_.map(headerRow, h => (
                  <Table.HeaderCell key={h} content={h} />
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.times(rowCount, rowIndex => (
                <Table.Row key={`row-${rowIndex}`}>
                  {_.times(columnCount, columnIndex => (
                    <Table.Cell
                      key={`cell-${columnIndex}`}
                      content={
                        <Input {...this.handleChange(columnIndex, rowIndex)} />
                      }
                    />
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="OK" onClick={onOKClick} />
          <Button negative content="Clear Values" onClick={onClearClick} />
        </Modal.Actions>
      </Modal>
    )
  }
}

@inject("userStore", "strategyStore")
@observer
export default class Frm extends Component {
  @observable
  legs = []
  @observable
  form = observable.map()
  @observable
  enabledGroups = ["dte", "absDelta"]
  @observable
  isPosting = false
  @observable
  isLoading = false
  @observable
  errors = undefined
  @observable
  selectedFormAddition = null
  @observable
  showParamsModal = false
  @observable
  showCsvModal = false
  firstRenderComplete = false

  constructor(props) {
    super(props)
    this.mergeStrategyDefaults("LongCall")
    this.populateForm(props.match.params.id)
  }

  componentDidMount() {
    this.firstRenderComplete = true
    document.title = "WHEEL | Backtest Create"
  }

  populateForm = async taskId => {
    if (taskId) {
      this.isLoading = true
      const { getBacktestForm, getBacktestName } = this.props.strategyStore
      const [legs, name] = await Promise.all([
        getBacktestForm(taskId),
        getBacktestName(taskId)
      ])
      this.isLoading = false
      if (legs.length === 0) {
        console.log("no form found to duplicate")
      }
      runInAction(() => {
        const [form, enabledGroups] = legacyLegsToForm(legs, name)
        this.enabledGroups = enabledGroups
        this.form.replace(form)
      })
    } else {
      const [start, end] = this.props.strategyStore.backtestStartEndDates
      this.form.set("startDate", [start])
      this.form.set("endDate", [end])
    }
  }

  mergeStrategyDefaults = strategy => {
    const [, strategyDefaults] = legsForStrategy(strategy)
    let fieldsToSourceFromDefaults = [
      "leg",
      "ratio",
      "optionType",
      "strategyName"
    ]

    if (!this.form.has("symbol")) {
      // new form
      fieldsToSourceFromDefaults.push("symbol")
      _.forEach(this.enabledGroups, g => {
        fieldsToSourceFromDefaults = _.concat(
          fieldsToSourceFromDefaults,
          this.formGroupMap[g].fields
        )
      })
    } else {
      // handle a strategyName change (preserve input / selected parms)
      const replaceCandidates = fieldsForGroups([
        "dte",
        "stockOTMPct",
        "absDelta"
      ])
      const fieldsToReplace = _.filter(this.form.keys(), k => {
        return _.includes(replaceCandidates, k)
      })
      fieldsToSourceFromDefaults = _.concat(
        fieldsToSourceFromDefaults,
        fieldsToReplace
      )
    }

    // not happy with this at all...
    // defaults to not get initialized (from csv) with array vals the length of legs (for multileg props)
    // TODO: handle case where the field is being shortened
    if (strategyDefaults.leg.length > 0) {
      _.forEach(this.form.keys(), k => {
        if (
          _.includes(MULTI_LEG_FIELDS, k) &&
          this.form.get(k).length !== strategyDefaults.leg.length
        ) {
          const lengthDiff =
            strategyDefaults.leg.length - this.form.get(k).length
          if (lengthDiff > 0) {
            this.form.set(
              k,
              _.concat(
                this.form.get(k).toJS(),
                _.times(lengthDiff, _.constant(""))
              )
            )
          } else {
            this.form.set(k, this.form.get(k).slice(0, lengthDiff))
          }
        }
      })
    }

    this.form.merge(_.pick(strategyDefaults, fieldsToSourceFromDefaults))
  }

  @computed
  get isUsingSignals() {
    return this.form.has("signalSymbol")
  }

  @computed
  get isUsingWeightings() {
    return this.form.has("weightings")
  }

  @computed
  get canSubmit() {
    // return this.symbolMatch //&& this.errors === undefined
    return this.errors === undefined
  }

  // might it not be better to handle this through this.errors ?
  @computed
  get symbolMatch() {
    const symbols = _.filter(this.form.get("symbol"), s => s !== null)
    if (symbols.length === 0) return false
    const matches = _.map(symbols, symbol => {
      return _.findIndex(symbols, s => s === symbol.toUpperCase()) >= 0
    })
    return _.filter(matches, m => m === false).length === 0
  }

  @computed
  get backtestName() {
    const { symbol, strategy, paramSigs } = backtestNameComponents(
      this.form.toJS()
    )
    return `${symbol}:${strategy}:${paramSigs}`
  }

  @computed
  get paramAdditionOptions() {
    const { isProOrEntPlan } = this.props.userStore

    let result = _.map(
      _.reject(
        BT_FORM_GROUPS,
        g =>
          _.includes(this.enabledGroups, g.key) ||
          _.includes(
            [
              "absDelta",
              "stockOTMPct",
              "dte",
              "general",
              "weightings",
              "exitBizDaysBeforeEarn",
              "exitBizDaysAfterEarn"
            ],
            g.key
          ) ||
          (!isProOrEntPlan && g.key === "symEntryExit")
      ),
      ({ key: value, title: text }) => {
        return {
          value,
          text
        }
      }
    )

    if (
      !_.includes(this.enabledGroups, "exitBizDaysBeforeEarn") &&
      !_.includes(this.enabledGroups, "exitBizDaysAfterEarn")
    ) {
      result.push({
        value: "exitRelToEarnings",
        text: "Exit Relative to Earnings"
      })
    }

    return result
  }

  @computed
  get canEditStrategy() {
    return this.props.match.params.id === undefined
  }

  @computed
  get formGroupMap() {
    return formGroupMap()
  }

  @computed
  get weightingsSlug() {
    return _.map(this.form.get("symbol"), (s, i) => {
      return `${s}: ${this.form.get("weightings")[i]}`
    }).join(", ")
  }

  @computed
  get weightingsTableData() {
    const symbols = this.form.get("symbol")
    const weightings = this.form.get("weightings")
    return _.map(weightings, (w, i) => [symbols[i], w])
  }

  signalInputErrors() {
    let results = []

    const uniqSymbols = _.uniq(this.form.get("signalSymbol"))
    if (uniqSymbols.length > 1) {
      results.push(
        `Symbol Entry & Exit: More than one symbol found ${uniqSymbols.join(
          ", "
        )}`
      )
    }

    const badSymbols = _.filter(
      this.form.get("signalSymbol"),
      s => _.findIndex(symbols, sym => sym === s.toUpperCase()) < 0
    )
    if (badSymbols.length) {
      results.push(
        `Symbol Entry & Exit: Invalid symbols found: ${badSymbols.join(", ")}`
      )
    }

    const badEntryDates = _.filter(
      this.form.get("signalEntryDate"),
      d => !Moment(d, VALID_DATE_FORMATS, true).isValid()
    )
    if (badEntryDates.length) {
      results.push(
        "Symbol Entry & Exit: Please specify entry date values as either YYYY-MM-DD or M/D/YYYY"
      )
    }

    const badExitDates = _.filter(
      this.form.get("signalExitDate"),
      d => !Moment(d, VALID_DATE_FORMATS, true).isValid()
    )
    if (badExitDates.length) {
      results.push(
        "Symbol Entry & Exit: Please specify exit date values as either YYYY-MM-DD or M/D/YYYY"
      )
    }

    const overlapingDates = _.filter(this.form.get("signalEntryDate"), (d, i) =>
      Moment(d).isAfter(Moment(this.form.get("signalExitDate")[i]))
    )
    if (overlapingDates.length) {
      results.push("Symbol Entry & Exit: Entry Dates must preceed Exit Dates")
    }

    return results.length > 0 ? results : null
  }

  weightingInputErrors() {
    let results = []

    console.log(`length of symbols: ${this.form.get("symbol").length}`)
    if (this.form.get("symbol").length > 40) {
      results.push(
        `${
          this.form.get("symbol").length
        } symbols found, which exceeds the maximum of 40`
      )
    }
    const badSymbols = _.filter(
      this.form.get("symbol"),
      s => _.findIndex(symbols, sym => sym === s.toUpperCase()) < 0
    )
    if (badSymbols.length) {
      results.push(`Invalid symbols found: ${badSymbols.join(", ")}`)
    }
    const badWeightings = _.filter(this.form.get("weightings"), w => {
      return w === "" || isNaN(w)
    })
    if (badWeightings.length) {
      results.push(`Invalid weightings found: ${badWeightings.join(", ")}`)
    }

    return results.length > 0 ? results : null
  }

  handleSumbit = async (e, data) => {
    const { history, strategyStore } = this.props

    const strategyName = this.form.get("strategyName")[0]
    const [defaultLegs] = legsForStrategy(strategyName)

    const rowCount = _.max(_.map(this.form.values(), v => v.length))

    if (this.isUsingSignals) {
      const exitAtSignal = _.map(this.form.get("exitAtSignal"), v => {
        return v === "" ? "no" : v
      })
      this.form.set("exitAtSignal", exitAtSignal)
      this.form.set("symbol", [this.form.get("signalSymbol")[0]])
    }

    const payload = _.times(rowCount, row => {
      const objectForThisRow = _.zipObject(
        this.form.keys(),
        _.map(this.form.values(), v => (v.length > row ? v[row] : ""))
      )

      let leg = { ...defaultLegs[0], ...objectForThisRow }
      if (row === 0) {
        leg = _.mapValues(leg, (value, key) => {
          if (key === "exitProfitLossPctMin" && this.form.has(key)) {
            return value !== "" ? Math.abs(+value) * -0.01 : "none"
          } else if (key === "exitProfitLossPctMax" && this.form.has(key)) {
            return value !== "" ? +value * 0.01 : "none"
          } else {
            return value === "" ? "none" : value
          }
        })
      } else {
        leg = _.mapValues(leg, (value, key) => {
          if (this.form.has(key)) {
            if (_.includes(MULTI_LEG_FIELDS, key) && value === "") {
              return "none"
            } else {
              return value
            }
          } else {
            return ""
          }
        })
      }

      const exitRuleFieldsToNullify = _.includes(this.enabledGroups, "absDelta")
        ? ["stockOTMPct", "stockOTMPctMax", "stockOTMPctMin"]
        : ["absDelta", "absDeltaMax", "absDeltaMin"]

      _.forEach(exitRuleFieldsToNullify, f => {
        leg[f] = row === 0 ? "none" : ""
      })

      return leg
    })

    const altValues = {
      LongWayOTMPut: "LongPut",
      LongWayOTMCall: "LongCall",
      LongOTMCall: "LongCall",
      LongOTMPut: "LongPut",
      LongOTMPutVertical: "LongPutSpread",
      ShortOTMPut: "ShortPut",
      ShortOTMCall: "ShortCall"
    }

    const canonicalStrategyName = altValues[payload[0].strategyName]
    if (canonicalStrategyName) {
      payload[0].strategyName = canonicalStrategyName
    }

    this.validateForm(payload)
    if (this.errors) return

    console.log(backtestTemplate(payload))
    console.log(payload)
    console.log(this.form.toJS())

    if (!window.doNotPost) {
      console.log("POST")
      this.isPosting = true
      await strategyStore.postBacktestRequest(payload, this.backtestName)
      this.isPosting = false
      history.push("/backtest")
    } else {
      console.log("NOPOST")
    }
  }

  validateForm = payload => {
    console.log("VALIDATE")
    this.errors = undefined
    const { strategyStore } = this.props
    const [start, end] = strategyStore.backtestStartEndDates

    const enabledGroupFields = _.flatten(
      _.concat(
        _.map(toJS(this.enabledGroups), g => this.formGroupMap[g].fields),
        ["startDate", "endDate"]
      )
    )

    // TODO: you are only validating leg1
    const userInputs = _.pick(payload[0], enabledGroupFields)

    let constraints = _.pick(CONSTRAINTS, enabledGroupFields)

    constraints["startDate"] = {
      presence: true,
      startDateBoundary: {
        start
      },
      startDateBeforeEndInput: {
        end: userInputs.endDate
      },
      dateFormat: {}
    }

    constraints["endDate"] = {
      presence: true,
      endDateBoundary: {
        end
      },
      endDateAfterStartInput: {
        start: userInputs.startDate
      },
      dateFormat: {}
    }

    this.errors = validate(
      { ...userInputs, maxLegs: payload.length },
      constraints
    )

    // come back and properly identify these by key (signalsCsv, weightingsCsv)
    if (this.isUsingSignals) {
      const sigErrors = this.signalInputErrors() // not happy with these methods
      if (sigErrors) {
        this.errors = this.errors
          ? {
              ...this.errors,
              signalsCsv: sigErrors
            }
          : {
              signalsCsv: sigErrors
            }
      }
    }

    if (this.isUsingWeightings) {
      const weightingErrors = this.weightingInputErrors() // not happy with these methods
      if (weightingErrors) {
        this.errors = this.errors
          ? {
              ...this.errors,
              weightingsCsv: weightingErrors
            }
          : {
              weightingsCsv: weightingErrors
            }
      }
    }

    if (_.includes(this.enabledGroups, "adjustLegDelta")) {
      const legs = this.form.get("adjDeltaMin").length

      _.times(legs, i => {
        const hasAdjustmentTrigger =
          _.reduce(
            ["adjDeltaMin", "adjDeltaMax"],
            (rez, key) => {
              if (this.form.get(key)[i] !== "") rez.push(this.form.get(key))
              return rez
            },
            []
          ).length > 0

        const badAdjustLegToDeltaKeys = _.reduce(
          ["adjToDelta", "adjToDeltaMin", "adjToDeltaMax"],
          (rez, key) => {
            if (this.form.get(key)[i] === "") rez.push(key)
            return rez
          },
          []
        )

        const hasAdjustLegToDelta = badAdjustLegToDeltaKeys.length === 0
        if (hasAdjustmentTrigger && !hasAdjustLegToDelta) {
          _.forEach(badAdjustLegToDeltaKeys, k => {
            this.errors = {
              ...this.errors,
              [k]: [
                `You must provide target min, and max values for Adjust Leg to Delta when choosing Adjust Leg Delta (Trigger)::${i}`
              ]
            }
          })
        }
      })

      // const triggerSet = _.reject(this.form.get("adjDeltaMin"), this.form.get("adjDeltaMax")), v => v === "")
      // console.log(triggerSet)
      //
      //
      // // const triggerSet = this.form.get("adjDeltaMin") || this.form.get("adjDeltaMax")
      // const legToDeltaNotSet = _.filter(_.map(["adjToDelta", "adjToDeltaMin", "adjToDeltaMax"], k => this.form.get(k)), v => v == "")
      // console.log(legToDeltaNotSet)

      // if () {
      //
      // }
    }
  }

  handleDismiss = (e, data) => {
    const { history } = this.props
    history.push("/backtest")
  }

  handleFormParamAdd = (e, { value }) => {
    const { linkedTo } = this.formGroupMap[value]
    runInAction(() => {
      let keysToAssign = this.formGroupMap[value].fields
      if (value === "exitRelToEarnings") {
        this.enabledGroups.push("exitBizDaysBeforeEarn")
      } else {
        if (linkedTo) {
          this.enabledGroups = _.concat(this.enabledGroups.toJS(), linkedTo)
          _.forEach(linkedTo, g => {
            keysToAssign = _.concat(keysToAssign, this.formGroupMap[g].fields)
          })
        } else {
          this.enabledGroups.push(value)
          keysToAssign = this.formGroupMap[value].fields
        }
      }
      if (value === "symEntryExit") {
        this.form.set("exitAtSignal", ["yes"])
      }
      this.selectedFormAddition = null
      const strategyName = this.form.get("strategyName")[0]
      const [, strategyDefaults] = legsForStrategy(strategyName)

      // not happy with this at all...
      // defaults to not get initialized (from csv) with array vals the length of legs (for multileg props)
      if (this.form.get("dte").length > 0) {
        _.forEach(keysToAssign, k => {
          if (_.includes(MULTI_LEG_FIELDS, k)) {
            strategyDefaults[k] = _.times(
              this.form.get("dte").length,
              _.constant("")
            )
          }
        })
      }

      this.form.merge(_.pick(strategyDefaults, keysToAssign))
    })
  }

  handleFormParamRemove = (key, e, data) => {
    runInAction(() => {
      this.errors = undefined
      const { linkedTo } = this.formGroupMap[key]
      if (linkedTo) {
        _.forEach(linkedTo, g => {
          this.enabledGroups = _.without(this.enabledGroups, g)
          _.forEach(this.formGroupMap[g].fields, k => this.form.delete(k))
        })
      } else {
        this.enabledGroups = _.without(this.enabledGroups, key)
        _.forEach(this.formGroupMap[key].fields, k => this.form.delete(k))
      }
    })
  }

  handleParamsModalCheckboxChange = (key, e, { checked }) => {
    if (_.includes(["absDelta", "stockOTMPct"], key)) {
      if (_.includes(this.enabledGroups, key) && !checked) {
        return
      }
      this.formGroupAlternateChange(null, {
        value: key
      })
      return
    }

    if (
      _.includes(["exitBizDaysAfterEarn", "exitBizDaysBeforeEarn"], key) &&
      !_.includes(this.enabledGroups, key)
    ) {
      this.formGroupAlternateChange(null, {
        value: key
      })
      return
    }

    if (checked) {
      this.handleFormParamAdd(null, {
        value: key
      })
    } else {
      this.handleFormParamRemove(key, null, null)
    }
  }

  handleChange = (key, legIndex = 0) => {
    // console.log(
    //   `key: ${key}, legIndex: ${legIndex} length: ${this.form.get(key).length}`
    // )
    let value = this.form.has(key) ? this.form.get(key)[legIndex] : ""
    if (key === "signalSymbol") {
      value = ""
    }
    return {
      value,
      onChange: (e, data) => {
        // if (legIndex > this.form.get(key).length - 1) {
        //   console.log(key)
        //   this.form.set(key, ["", ""])
        // }
        this.errors = undefined
        if (_.includes(["weightings", "signalSymbol"], key)) {
          console.log("SHOULD NOT BE COMING IN HERE")
        } else {
          let value = data ? data.value : e.target.value
          this.form.get(key).splice(legIndex, 1, value) // +value
          if (key === "symbol") {
            this.form.get(key).splice(legIndex, 1, value.toUpperCase())
          }
          if (key === "strategyName" && !this.props.match.params.id) {
            this.mergeStrategyDefaults(value)
          }
          if (_.includes(["standardExpiration", "exitAtSignal"], key)) {
            this.form.set(key, data.checked ? ["yes"] : ["no"])
          }
        }
      },
      onPaste: e => {
        this.errors = undefined
        const { clipboardData } = e
        const text = clipboardData.getData("Text")
        if (text.split("\n").length > 1) {
          e.preventDefault()
          if (_.includes(["symbol", "signalSymbol"], key)) {
            let parsed = Baby.parse(text, {
              skipEmptyLines: true
            }).data
            const toMerge = _.reduce(
              CSV_FEILD_MAP[key],
              (result, field, index) => {
                result[field] = _.map(parsed, arr => {
                  return field === "weightings" && !arr[index]
                    ? `${_.round(1 / parsed.length, 4)}`
                    : arr[index]
                })
                return result
              },
              {}
            )
            if (key === "symbol") {
              this.enabledGroups.push("weightings")
            }
            this.form.merge(toMerge)
          }
        }
      },
      onBlur: e => {
        const { value } = e.target
        if (key === "symbol" && value.split(",").length > 1) {
          let [symbols, weightings] = _.partition(
            _.map(value.split(","), v => _.trim(v)),
            v => isNaN(+v)
          )
          if (symbols.length !== weightings.length) {
            weightings = _.times(symbols.length, i =>
              _.round(1 / symbols.length, 4)
            )
          }
          this.enabledGroups.push("weightings")
          this.form.merge({
            symbol: symbols,
            weightings
          })
        }
      }
    }
  }

  // handleModalChange = ()

  handleModalChange = (key, columnIndex, rowIndex, e, { value }) => {
    this.form.get(key).splice(rowIndex, 1, value)
  }

  handleClearWeightings = () => {
    this.errors = undefined
    this.showCsvModal = false
    runInAction(() => {
      this.enabledGroups = _.without(this.enabledGroups, "weightings")
      this.form.delete("weightings")
      this.form.set("symbol", ["IBM"])
    })
  }

  handleClearSignals = () => {
    this.errors = undefined
    this.showCsvModal = false
    runInAction(() => {
      const keysToRemove = this.formGroupMap["symEntryExit"].fields
      _.forEach(keysToRemove, k => this.form.set(k, [""]))
    })
  }

  formGroupAlternateChange = (e, { value }) => {
    const group = this.formGroupMap[value] //_.find(BT_FORM_GROUPS, { key: value })
    const toRemove = _.reject(
      _.map(group.options, "value"),
      v => v === value
    )[0]

    runInAction(() => {
      const removeKeys = this.formGroupMap[toRemove].fields
      _.forEach(removeKeys, k => this.form.delete(k))

      const keysToAssign = this.formGroupMap[value].fields
      const strategyName = this.form.get("strategyName")[0]
      const [, strategyDefaults] = legsForStrategy(strategyName)
      this.form.merge(_.pick(strategyDefaults, keysToAssign))

      this.enabledGroups.remove(toRemove)
      this.enabledGroups.push(value)
    })
  }

  renderFormRow = ({
    groupKey,
    legNumber,
    legIndex = 0,
    showRemove = false
  }) => {
    const group = this.formGroupMap[groupKey]
    if (group.hidden) {
      return
    }

    return (
      <FormGroup
        key={`${groupKey}-${legNumber}`}
        inline
        className={`animate:${this.firstRenderComplete ? 1 : 0}`}>
        {_.map(group.fields, (f, i) => {
          let inputLabel = group.labels
            ? group.labels[i]
            : _.endsWith(f, "Min") || _.endsWith(f, "Max")
            ? f.slice(-3).toLowerCase()
            : "ideal"

          if (inputLabel === "leg") {
            inputLabel = `leg ${legNumber}`
          }

          if (groupKey === "standardExpiration") {
            inputLabel =
              this.form.get("standardExpiration")[0] === "yes"
                ? "Standard Expirations ONLY"
                : "Standard Expirations NOT used"
          }

          let placeholder = group.placeholders ? group.placeholders[i] : null
          if (placeholder === "legNum") {
            placeholder = legNumber
          }
          const inputType = group.inputTypes ? group.inputTypes[i] : "input"

          const fieldHasError = _.has(this.errors, f)
          let legHasError = fieldHasError

          if (fieldHasError && this.errors[f][0].split("::").length > 1) {
            legHasError = +this.errors[f][0].split("::")[1] === +legIndex
          }

          const hasError = fieldHasError && legHasError

          const ratio = this.form.get("ratio")[legIndex]
          const optionType = this.form.get("optionType")[legIndex]

          return (
            <Form.Field key={`${groupKey}-${f}`} inline>
              {i === 0 && (
                <Popup
                  size="small"
                  trigger={
                    <label
                      style={
                        inputType === "csv"
                          ? {
                              ...labelStyle,
                              marginTop: 10
                            }
                          : labelStyle
                      }>
                      {group.options ? (
                        legIndex === 0 ? (
                          <Dropdown
                            inline
                            selectOnBlur={false}
                            style={{
                              color: "#4183C4"
                            }}
                            value={groupKey}
                            onChange={this.formGroupAlternateChange}
                            options={group.options}
                          />
                        ) : (
                          <span />
                        )
                      ) : (
                        <span> {legIndex === 0 ? group.title : ""} </span>
                      )}
                      {_.includes(MULTI_LEG_GROUPS, groupKey) && (
                        <Label
                          circular
                          color={ratio >= 1 ? "green" : "red"}
                          content={optionType === "call" ? "C" : "P"}
                          style={{
                            float: "right"
                          }}
                        />
                      )}
                    </label>
                  }
                  content={HELP_SNIPPETS[groupKey].content}
                />
              )}
              {inputType === "input" && (
                <Input
                  label={
                    <Label
                      color={hasError ? "red" : null}
                      content={inputLabel}
                    />
                  }
                  placeholder={placeholder}
                  error={hasError}
                  {...this.handleChange(f, legIndex)}
                />
              )}
              {inputType === "date" && (
                <Input
                  label={inputLabel}
                  placeholder="YYYY-MM-DD"
                  {...this.handleChange(f, legIndex)}
                />
              )}
              {inputType === "csv" && (
                <div
                  style={{
                    float: "right",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: 12
                    }}>
                    <TextArea
                      rows={1}
                      placeholder="paste here"
                      {...this.handleChange(f)}
                    />
                  </div>
                  {this.form.has(f) &&
                    _.filter(this.form.get(f), i => i !== "").length > 0 && (
                      <CsvModal
                        onClearClick={this.handleClearSignals}
                        onOKClick={() => {
                          this.errors = undefined
                          this.showCsvModal = false
                        }}
                        handleChange={this.handleModalChange}
                        groupMeta={group}
                        open={this.showCsvModal}
                        propsToRender={[
                          this.form.get("signalSymbol"),
                          this.form.get("signalEntryDate"),
                          this.form.get("signalExitDate")
                        ]}
                        trigger={
                          <List horizontal>
                            <List.Item
                              content={`${this.form.get(f).length} entries `}
                            />
                            <List.Item> | </List.Item>
                            <List.Item
                              content={
                                <a onClick={() => (this.showCsvModal = true)}>
                                  View / Edit
                                </a>
                              }
                            />
                            <List.Item> | </List.Item>
                            <List.Item as="a" onClick={this.handleClearSignals}>
                              Clear
                            </List.Item>
                            <List.Item> | </List.Item>
                          </List>
                        }
                      />
                    )}
                </div>
              )}
              {inputType === "checkbox" && (
                <Checkbox
                  checked={this.form.get(f)[0] === "yes"}
                  label={inputLabel}
                  {...this.handleChange(f, legIndex)} // legIndex
                />
              )}
            </Form.Field>
          )
        })}
        {showRemove && (
          <Form.Field>
            <Button
              basic
              size="small"
              as="a"
              circular
              icon="remove"
              onClick={this.handleFormParamRemove.bind(null, groupKey)}
            />
          </Form.Field>
        )}
      </FormGroup>
    )
  }

  render() {
    const {
      userStore: { isFreePlan }
    } = this.props
    const symbolDisabled =
      _.includes(this.enabledGroups, "symEntryExit") ||
      isFreePlan ||
      _.includes(this.enabledGroups, "weightings")
    const legs = _.filter(this.form.get("leg"), l => l !== "")
    return this.isLoading || this.isPosting ? (
      <Loader active size="small" inline="centered">
        LOADING
      </Loader>
    ) : (
      // <di>HH</di>
      <div>
        <Grid verticalAlign="middle" padded={false} columns="equal">
          <Grid.Column
            floated="left"
            style={{
              paddingTop: 0,
              paddingBottom: 0
            }}>
            <Breadcrumb size="large">
              <Breadcrumb.Section as={NavLink} to="/backtest" link>
                My Backtests
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active> Create </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>
                {this.backtestName}
              </Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column
            style={{
              paddingTop: 0,
              paddingBottom: 0
            }}>
            <List
              floated="right"
              style={{
                paddingBottom: 10
              }}
              horizontal>
              <List.Item
                style={{
                  marginLeft: 2
                }}>
                <Button
                  disabled={this.isPosting}
                  content="Cancel"
                  id="cancelBacktestCreate"
                  onClick={this.handleDismiss}
                  size="small"
                />
              </List.Item>
              <List.Item
                style={{
                  marginLeft: 2
                }}>
                <Button
                  disabled={!this.canSubmit}
                  content="Submit"
                  id="submitBacktest"
                  loading={this.isPosting}
                  onClick={this.handleSumbit}
                  primary
                  size="small"
                />
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
        {this.errors !== undefined && <Errors errors={this.errors} />}
        <Segment.Group>
          <Segment>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <Popup
                    size="small"
                    position="bottom left"
                    trigger={<label style={labelStyle}> Symbol </label>}
                    content={HELP_SNIPPETS["symbol"].content}
                  />
                  {!this.isUsingWeightings && (
                    <Input
                      placeholder="Symbol"
                      disabled={symbolDisabled}
                      icon={this.symbolMatch && <Icon name="checkmark" />}
                      {...this.handleChange("symbol")}
                    />
                  )}
                  {!this.isUsingWeightings && isFreePlan && (
                    <span
                      style={{
                        marginLeft: 10
                      }}>
                      <Link to="/pricing">
                        Unlock all symbols with a Wheel subscription
                      </Link>
                    </span>
                  )}
                  {this.isUsingWeightings && (
                    <List horizontal>
                      <List.Item>
                        {`Weighted: ${_.truncate(this.weightingsSlug)}`}
                      </List.Item>
                      <List.Item> | </List.Item>
                      <List.Item>
                        <CsvModal
                          onClearClick={this.handleClearWeightings}
                          onOKClick={() => {
                            this.errors = undefined
                            this.showCsvModal = false
                          }}
                          groupMeta={this.formGroupMap["weightings"]}
                          open={this.showCsvModal}
                          propsToRender={[
                            this.form.get("symbol"),
                            this.form.get("weightings")
                          ]}
                          trigger={
                            <a onClick={() => (this.showCsvModal = true)}>
                              View / Edit
                            </a>
                          }
                        />
                      </List.Item>
                      <List.Item> | </List.Item>
                      <List.Item as="a" onClick={this.handleClearWeightings}>
                        Clear
                      </List.Item>
                    </List>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field>
                  <Popup
                    size="small"
                    trigger={<label style={labelStyle}> Strategy </label>}
                    content={HELP_SNIPPETS["strategy"].content}
                  />
                  <Dropdown
                    disabled={!this.canEditStrategy}
                    selection
                    labeled
                    {...this.handleChange("strategyName")}
                    options={optionsGen(_.keys(STRATEGIES))}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field>
                  <Popup
                    size="small"
                    trigger={<label style={labelStyle}> Date Range </label>}
                    content={HELP_SNIPPETS["dateRange"].content}
                  />
                  <Input
                    label={
                      <Label
                        color={_.has(this.errors, "startDate") ? "red" : null}
                        content={"start"}
                      />
                    }
                    {...this.handleChange("startDate")}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={
                      <Label
                        color={_.has(this.errors, "endDate") ? "red" : null}
                        content={"end"}
                      />
                    }
                    {...this.handleChange("endDate")}
                  />
                </Form.Field>
              </Form.Group>
              {_.map(legs, (legNumber, legIndex) =>
                this.renderFormRow({
                  groupKey: "dte",
                  legNumber,
                  legIndex
                })
              )}
              {_.map(legs, (legNumber, legIndex) =>
                this.renderFormRow({
                  groupKey: _.includes(this.enabledGroups, "absDelta")
                    ? "absDelta"
                    : "stockOTMPct",
                  legNumber,
                  legIndex
                })
              )}
              {_.map(
                _.without(this.enabledGroups, "absDelta", "stockOTMPct", "dte"),
                (key, i) => {
                  const { isMultiLeg } = this.formGroupMap[key]
                  return isMultiLeg
                    ? _.map(legs, l =>
                        this.renderFormRow({
                          groupKey: key,
                          legNumber: l,
                          legIndex: l - 1,
                          showRemove: true
                        })
                      )
                    : this.renderFormRow({
                        groupKey: key,
                        legNumber: 1,
                        showRemove: true
                      })
                }
              )}
              <Form.Group inline>
                <Form.Field>
                  <label style={labelStyle} />
                  <Dropdown
                    selection
                    search
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    placeholder="Add a new parameter"
                    onChange={this.handleFormParamAdd}
                    value={this.selectedFormAddition}
                    options={this.paramAdditionOptions}
                  />
                  <Modal
                    dimmer="inverted"
                    open={this.showParamsModal}
                    trigger={
                      <Label
                        style={{
                          marginLeft: 14
                        }}
                        as="a"
                        basic
                        color="green"
                        id="showBacktestParams"
                        content="Show me all Backtest Filter Parameters"
                        onClick={() => (this.showParamsModal = true)}
                      />
                    }
                    size="small">
                    <Header content="Backtest Filter Parameters" />
                    <Modal.Content
                      scrolling
                      style={{
                        padding: 10
                      }}
                      size="fullscreen">
                      <Table basic="very" compact padded={false}>
                        <Table.Body>
                          {_.map(
                            _.reject(BT_FORM_GROUPS, {
                              key: "weightings"
                            }),
                            ({ key, title }, i) => {
                              return (
                                <Table.Row key={i}>
                                  <Table.Cell
                                    content={
                                      _.includes(
                                        ["general", "dte"],
                                        key
                                      ) ? null : (
                                        <Checkbox
                                          checked={_.includes(
                                            this.enabledGroups,
                                            key
                                          )}
                                          onChange={this.handleParamsModalCheckboxChange.bind(
                                            null,
                                            key
                                          )}
                                        />
                                      )
                                    }
                                  />
                                  <Table.Cell content={title} singleLine />
                                  <Table.Cell
                                    content={
                                      _.includes(["symEntryExit"], key) ? (
                                        <Label size="mini" color="teal">
                                          PRO
                                        </Label>
                                      ) : (
                                        ""
                                      )
                                    }
                                  />
                                  <Table.Cell
                                    content={HELP_SNIPPETS[key].content}
                                  />
                                </Table.Row>
                              )
                            }
                          )}
                        </Table.Body>
                      </Table>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button onClick={() => (this.showParamsModal = false)}>
                        Cancel
                      </Button>
                      <Button
                        color="green"
                        content="OK"
                        onClick={() => (this.showParamsModal = false)}
                      />
                    </Modal.Actions>
                  </Modal>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Segment.Group>
      </div>
    )
  }
}
