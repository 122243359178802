import _ from "lodash"
import Papa from "papaparse"
import { api } from "./services"

export let STRATEGIES = undefined

api.loadStrategyDefaults().then(result => {
  const { data } = Papa.parse(result.data, {
    header: true,
    skipEmptyLines: true,
    dynamicTyping: true
  })

  const rez = _.mapValues(
    _.groupBy(
      _.reject(data, d => d.strategyName === ""),
      "strategyName"
    ),
    arr => {
      return _.map(arr, (obj, i) => {
        return _.mapValues(obj, (val, key) =>
          (i > 0 && key === "strategyName") || val === "none" ? "" : val
        )
      })
    }
  )
  STRATEGIES = rez
})

export const BT_UNIT_PRICE = 1000
export const BT_UNIT_VOLUME = 20

export const AUTH_TOKEN_KEY = "authToken"

export const KEY_LABELS = {
  strategyName: "Strategy Name",
  symbol: "Symbol",
  dte: "Optimal",
  dteMin: "Minimum",
  dteMax: "Maximum",
  absDelta: "Optimal",
  absDeltaMin: "Minimum",
  absDeltaMax: "Maximum"
}

export const ANNUAL_RESULTS_HEADERS = [
  "Year",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Ann Rtn",
  "Sharpe"
]

export const BT_STAT_PROP_NAMES = {
  AnnReturn: "Annual Return",
  AnnSharpe: "Annual Sharpe Ratio",
  "MaxDrawDown%": "Max Drawdown Percentage",
  DrawDownDays: "Drawdown Days",
  "P&L$PerTradeAvg": "P&L Per Trade Average",
  "P&L$PerDayAvg": "P&L Per Day Average",
  "P&L%PerTradeAvg": "P&L Percentage Per Trade Average",
  "P&L%PerDayAvg": "P&L Percentage Per Day Average",
  StratWinRate: "Strategy Win Rate",
  DaysInTradeAvg: "Number of Days in Trade Average",
  TotStratTrades: "Total Strategy Trades",
  "TotStratP&L$": "Total Strategy P&L",
  "TotStratP&L%": "Total Strategy P&L Percentage",
  "%OfTimeInMarket": "Percent of Days in Market"
}

export const BT_FEATURES = [
  "Easy custom interface with fast calculations of your strategy",
  "Best market data enhanced with smoothed market values and forecasted values on all US equity options expirations going back to 2007",
  "Define your option trading strategy using days to expiration, delta or \n percentage out-of-the-money",
  "Filter by yield percentages and market width",
  "Exit rules that use delta, out-of-the-money percentages, profit percentage or days left in the trade. Indicate your special reentry rule for exit triggers",
  "Utilize delta hedging with flexible hedging dates or delta tolerances"
]

export const legsForStrategy = strategy => {
  const legs = STRATEGIES[strategy]
  const form = _.reduce(
    _.keys(legs[0]),
    (result, key, i) => {
      const values = _.filter(_.map(legs, key), v => v !== "")
      result[key] = values.length === 0 ? [""] : values
      return result
    },
    {}
  )
  return [legs, form]
}

export const BT_FORM_GROUPS = [
  {
    key: "general",
    fields: ["symbol", "strategyName"],
    title: ""
  },
  {
    key: "dte",
    fields: ["dte", "dteMin", "dteMax"],
    title: "Days to Expiration",
    isMultiLeg: true,
    abrv: "Dte"
  },
  {
    key: "absDelta",
    fields: ["absDelta", "absDeltaMin", "absDeltaMax"],
    title: "Absolute Delta",
    isMultiLeg: true,
    abrv: "Dlt",
    options: [
      {
        text: "Absolute Delta",
        value: "absDelta"
      },
      {
        text: "Strike % of Stock Price",
        value: "stockOTMPct"
      }
    ]
    // options: map(range(0.05, 1.0, 0.05), n => numeral(n).format("'0.00"))
  },
  {
    key: "stockOTMPct",
    fields: ["stockOTMPct", "stockOTMPctMin", "stockOTMPctMax"],
    title: "Strike % of Stock Price",
    abrv: "StkOTMPct",
    isMultiLeg: true,
    options: [
      {
        text: "Absolute Delta",
        value: "absDelta"
      },
      {
        text: "Strike % of Stock Price",
        value: "stockOTMPct"
      }
    ]
  },
  {
    key: "spreadPx",
    fields: ["spreadPx", "spreadPxMin", "spreadPxMax"],
    title: "Spread Price",
    abrv: "SpdPx"
  },
  {
    key: "spreadDelta",
    fields: ["spreadDelta", "spreadDeltaMin", "spreadDeltaMax"],
    title: "Spread Delta",
    abrv: "SpdDlt"
  },
  {
    key: "spreadYieldPct",
    fields: ["spreadYieldPctMin", "spreadYieldPctMax"],
    title: "Spread Yield",
    abrv: "SpdYld"
  },
  {
    key: "exitDTEDays",
    fields: ["exitDTEDays"],
    title: "Exit Days to Expiration Days",
    abrv: "ExDteDys"
  },
  {
    key: "exitHoldDays",
    fields: ["exitHoldDays"],
    title: "Exit Hold Days",
    abrv: "ExHldDys"
  },
  {
    key: "exitLeg1Delta",
    fields: ["exitLeg1DeltaMin", "exitLeg1DeltaMax"],
    title: "Exit Leg 1 Delta",
    abrv: "ExL1Dlt"
  },
  {
    key: "exitLeg1OTMPct",
    fields: ["exitLeg1OTMPctMin", "exitLeg1OTMPctMax"],
    title: "Exit Leg 1 OTM %",
    abrv: "ExL1OTMPct"
  },
  {
    key: "exitProfitLossPct",
    fields: ["exitProfitLossPctMin", "exitProfitLossPctMax"],
    labels: ["stop loss", "profit target"],
    placeholders: [50, 50],
    title: "Exit Profit / Stop Loss Percent",
    abrv: "ExPLPct"
  },
  {
    key: "exitSpreadDelta",
    fields: ["exitSpreadDeltaMin", "exitSpreadDeltaMax"],
    title: "Exit Spread Delta",
    abrv: "ExSpdDlt"
  },
  {
    key: "exitSpreadPx",
    fields: ["exitSpreadPxMin", "exitSpreadPxMax"],
    title: "Exit Spread Price",
    abrv: "ExSpdPx"
  },
  {
    key: "exitStrikeDiffPctValue",
    fields: ["exitStrikeDiffPctValueMin", "exitStrikeDiffPctValueMax"],
    title: "Exit Strike Diff %",
    abrv: "ExStkDfPct"
  },
  {
    key: "hedgeDays",
    fields: ["hedgeDays"],
    title: "Hedge Days",
    abrv: "HDys"
  },
  {
    key: "entryDays",
    fields: ["entryDays"],
    title: "Entry Days",
    abrv: "EDys"
  },
  {
    key: "standardExpiration",
    fields: ["standardExpiration"],
    inputTypes: ["checkbox"],
    title: "Standard Expirations",
    abrv: "StdExp"
  },
  {
    key: "contractSize",
    fields: ["contractSize"],
    title: "Contract Size",
    abrv: "CtrSz"
  },
  {
    key: "leg1Leg2DeltaTotal",
    fields: ["leg1Leg2DeltaTotalMin", "leg1Leg2DeltaTotalMax"],
    title: "Leg1 - Leg2 Delta Total",
    abrv: "L1L2DltTtl"
  },
  {
    key: "leg2Leg3DeltaTotal",
    fields: ["leg2Leg3DeltaTotalMin", "leg2Leg3DeltaTotalMax"],
    title: "Leg2 - Leg3 Delta Total",
    abrv: "L2L3DltTtl"
  },
  {
    key: "leg3Leg4DeltaTotal",
    fields: ["leg3Leg4DeltaTotalMin", "leg3Leg4DeltaTotalMax"],
    title: "Leg3 - Leg4 Delta Total",
    abrv: "L3L4DltTtl"
  },
  {
    key: "leg1Leg2DteDiff",
    fields: ["leg1Leg2DteDiffMin", "leg1Leg2DteDiffMax"],
    title: "Leg1 - Leg2 DTE Diff",
    abrv: "L1L2DteDf"
  },
  {
    key: "leg2Leg3DteDiff",
    fields: ["leg2Leg3DteDiffMin", "leg2Leg3DteDiffMax"],
    title: "Leg2 - Leg3 DTE Diff",
    abrv: "L2L3DteDf"
  },
  {
    key: "leg3Leg4DteDiff",
    fields: ["leg3Leg4DteDiffMin", "leg3Leg4DteDiffMax"],
    title: "Leg3 - Leg4 DTE Diff",
    abrv: "L3L4DteDf"
  },
  {
    key: "leg1Leg2StrikeWidth",
    fields: ["leg1Leg2StrikeWidthMin", "leg1Leg2StrikeWidthMax"],
    title: "Leg1 - Leg2 Strike Width",
    abrv: "L1L2StkWth"
  },
  {
    key: "leg2Leg3StrikeWidth",
    fields: ["leg2Leg3StrikeWidthMin", "leg2Leg3StrikeWidthMax"],
    title: "Leg2 - Leg3 Strike Width",
    abrv: "L2L3StkWth"
  },
  {
    key: "leg3Leg4StrikeWidth",
    fields: ["leg3Leg4StrikeWidthMin", "leg3Leg4StrikeWidthMax"],
    title: "Leg3 - Leg4 Strike Width",
    abrv: "L3L4StkWth"
  },
  {
    key: "mktWidthPc",
    fields: ["mktWidthPctMin", "mktWidthPctMax"],
    title: "Market Width Ratio",
    abrv: "MktWthPc"
  },
  {
    key: "symEntryExit",
    // fields: ["signalsCsv", "exitAtSignal"],
    fields: [
      "signalSymbol",
      "exitAtSignal",
      "signalEntryDate",
      "signalExitDate"
    ],
    labels: ["data", "exit at signal"],
    inputTypes: ["csv", "checkbox", "hidden", "hidden"],
    title: "Symbol Entry & Exit",
    csvHeaders: ["Symbol", "Entry Date", "Exit Date"],
    abrv: "SymEntExt"
  },
  {
    key: "iVRank",
    fields: ["iVRankMin", "iVRankMax"],
    labels: ["min", "max"],
    // placeholders: ["0 - 100"],
    title: "IV Rank",
    abrv: "ivRnk"
  },
  {
    key: "entryDaysToEarn",
    fields: ["entryDaysToEarnMin", "entryDaysToEarnMax"],
    labels: ["min", "max"],
    title: "Entry Days to Earnings",
    abrv: "entDys2Ern"
  },
  {
    key: "exitBizDaysBeforeEarn",
    fields: ["exitBizDaysBeforeEarn"],
    title: "Exit business days pre earnings",
    abrv: "exBzDysPreErn",
    options: [
      {
        text: "Exit Business Days Pre-Earnings",
        value: "exitBizDaysBeforeEarn"
      },
      {
        text: "Exit Business Days Post-Earnings",
        value: "exitBizDaysAfterEarn"
      }
    ]
  },
  {
    key: "exitBizDaysAfterEarn",
    fields: ["exitBizDaysAfterEarn"],
    title: "Exit business days post earnings",
    abrv: "exBzDysPstErn",
    options: [
      {
        text: "Exit Business Days Pre-Earnings",
        value: "exitBizDaysBeforeEarn"
      },
      {
        text: "Exit Business Days Post-Earnings",
        value: "exitBizDaysAfterEarn"
      }
    ]
  },
  {
    key: "iBidVol",
    fields: ["iBidVolMin", "iBidVolMax"],
    labels: ["min", "max"],
    title: "Implied Bid Volatility",
    abrv: "iBidVol"
  },
  {
    key: "iAskVol",
    fields: ["iAskVolMin", "iAskVolMax"],
    labels: ["min", "max"],
    title: "Implied Ask Volatility",
    abrv: "iAskVol"
  },
  {
    key: "weightings",
    fields: ["symbol", "weightings"],
    labels: ["data"],
    inputTypes: ["csv"],
    title: "Portfolio Weightings",
    csvHeaders: ["Symbol", "Weighting"],
    abrv: "wtgs",
    hidden: true
  },
  {
    key: "adjustLegDelta",
    fields: ["adjDeltaMin", "adjDeltaMax", "tiedToLegNum"],
    title: "Adjust Leg Delta (Trigger)",
    isMultiLeg: true,
    placeholders: ["", "", "legNum"],
    labels: ["min", "max", "tied to leg"],
    linkedTo: [
      "adjustLegDelta",
      "adjustLegToDelta",
      "adjustTiedLegDelta",
      "adjustWithLegNumber",
      "adjustMinMaxDays"
    ],
    abrv: "AdjLgDlt"
  },
  {
    key: "adjustLegToDelta",
    fields: ["adjToDelta", "adjToDeltaMin", "adjToDeltaMax"],
    title: "Adjust Leg to Delta",
    isMultiLeg: true,
    linkedTo: [
      "adjustLegDelta",
      "adjustLegToDelta",
      "adjustTiedLegDelta",
      "adjustWithLegNumber",
      "adjustMinMaxDays"
    ],
    abrv: "AdjLg2Dlt"
  },
  {
    key: "adjustMinMaxDays",
    fields: ["minDaysForAdjusting", "maxDaysForAdjusting"],
    labels: ["min", "max"],
    linkedTo: [
      "adjustLegDelta",
      "adjustLegToDelta",
      "adjustTiedLegDelta",
      "adjustWithLegNumber",
      "adjustMinMaxDays"
    ],
    title: "Adjust Min Max Days",
    abrv: "AdjMnMxDys"
  },
  // {
  //   key: "adjustTiedLegNumber",
  //   fields: ["tiedToLegNum"],
  //   title: "Adjust Tied Leg Number",
  //   linkedTo: ["adjustTiedLegNumber", "adjustTiedLegDelta"],
  //   labels: ["leg"],
  //   isMultiLeg: true,
  //   abrv: "AdjTdLgNm"
  // },
  {
    key: "adjustTiedLegDelta",
    fields: ["tiedDeltaMin", "tiedDeltaMax"],
    title: "Adjust Tied Leg Delta",
    linkedTo: [
      "adjustLegDelta",
      "adjustLegToDelta",
      "adjustTiedLegDelta",
      "adjustWithLegNumber",
      "adjustMinMaxDays"
    ],
    isMultiLeg: true,
    abrv: "AdjTdLgDlt"
  },
  {
    key: "adjustWithLegNumber",
    fields: ["rollWithLeg"],
    title: "Adjust With Leg Number",
    linkedTo: [
      "adjustLegDelta",
      "adjustLegToDelta",
      "adjustTiedLegDelta",
      "adjustWithLegNumber",
      "adjustMinMaxDays"
    ],
    isMultiLeg: true,
    abrv: "AdjWLgNm"
  }
]

// Leg2:	Ratio	OptionType	DTE	DTEMin	DTEMax StockOTMPct	StockOTMPctMin	StockOTMPctMax AbsDelta	AbsDeltaMin	AbsDeltaMax

export const BT_FORM_STRING_TYPES = [
  "email",
  "strategyName",
  "returnType",
  "compoundReturns",
  "startDate",
  "endDate",
  "optionType",
  "symbol",
  "signalSymbol",
  "signalEntryDate",
  "signalExitDate",
  "componentSymbol",
  "componentDate",
  "exitAtSignal",
  "standardExpiration"
]

export const CHAIN_PROPS = [
  "ask",
  "asksize",
  "bid",
  "bidsize",
  "calibration_option_ask",
  "calibration_option_bid",
  "calibration_theo_forecast",
  "calibration_theo_value",
  "calibration_vol_ask",
  "calibration_vol_bid",
  "calibration_vol_midpoint",
  "delta",
  "external_delta_forecast",
  "external_gamma_forecast",
  "external_theo_forecast",
  "external_theta_forecast",
  "external_vega_forecast",
  "external_vol_forecast",
  "gamma",
  "implied_vol_ask",
  "implied_vol_bid",
  "implied_vol_midpoint",
  "option_volume",
  "rho",
  "smv_delta",
  "smv_theo_value",
  "smv_theo_vol",
  "theta",
  "ticker",
  "vega"
]

export const SCANNER_PARAMS = {
  LC: {
    options: [
      {
        ratio: 1,
        optionType: "call",
        leg: 1,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.3,
              min: 0.2,
              max: 0.5
            }
          }
        }
      }
    ]
  },
  SC: {
    options: [
      {
        ratio: -1,
        optionType: "call",
        leg: 1,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.3,
              min: 0.2,
              max: 0.5
            }
          }
        }
      }
    ]
  },
  LP: {
    options: [
      {
        ratio: 1,
        optionType: "put",
        leg: 1,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.3,
              min: 0.2,
              max: 0.5
            }
          }
        }
      }
    ]
  },
  SP: {
    options: [
      {
        ratio: -1,
        optionType: "put",
        leg: 1,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.3,
              min: 0.2,
              max: 0.5
            }
          }
        }
      }
    ]
  },
  LCV: {
    options: [
      {
        ratio: 1,
        optionType: "call",
        leg: 1,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.3,
              min: 0.2,
              max: 0.5
            }
          }
        }
      },
      {
        ratio: -1,
        optionType: "call",
        leg: 2,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.15,
              min: 0.1,
              max: 0.3
            }
          }
        }
      }
    ],
    legRelation: {
      deltaTotal: {
        leg1Leg2: {
          min: null,
          max: null
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      },
      dteDiff: {
        leg1Leg2: {
          min: 0,
          max: 0
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      },
      strikeWidth: {
        leg1Leg2: {
          min: null,
          max: -2
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      }
    }
  },
  SCV: {
    options: [
      {
        ratio: -1,
        optionType: "call",
        leg: 1,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.3,
              min: 0.2,
              max: 0.5
            }
          }
        }
      },
      {
        ratio: 1,
        optionType: "call",
        leg: 2,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.15,
              min: 0.1,
              max: 0.3
            }
          }
        }
      }
    ],
    legRelation: {
      deltaTotal: {
        leg1Leg2: {
          min: null,
          max: null
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      },
      dteDiff: {
        leg1Leg2: {
          min: 0,
          max: 0
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      },
      strikeWidth: {
        leg1Leg2: {
          min: null,
          max: -2
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      }
    }
  },
  LPV: {
    options: [
      {
        ratio: 1,
        optionType: "put",
        leg: 1,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.3,
              min: 0.2,
              max: 0.5
            }
          }
        }
      },
      {
        ratio: -1,
        optionType: "put",
        leg: 2,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.15,
              min: 0.1,
              max: 0.3
            }
          }
        }
      }
    ],
    legRelation: {
      deltaTotal: {
        leg1Leg2: {
          min: null,
          max: null
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      },
      dteDiff: {
        leg1Leg2: {
          min: 0,
          max: 0
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      },
      strikeWidth: {
        leg1Leg2: {
          min: 2,
          max: null
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      }
    }
  },
  SPV: {
    options: [
      {
        ratio: -1,
        optionType: "put",
        leg: 1,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.3,
              min: 0.2,
              max: 0.5
            }
          }
        }
      },
      {
        ratio: 1,
        optionType: "put",
        leg: 2,
        opening: {
          strikeSelection: {
            type: "absDelta",
            value: {
              target: 0.15,
              min: 0.1,
              max: 0.3
            }
          }
        }
      }
    ],
    legRelation: {
      deltaTotal: {
        leg1Leg2: {
          min: null,
          max: null
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      },
      dteDiff: {
        leg1Leg2: {
          min: 0,
          max: 0
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      },
      strikeWidth: {
        leg1Leg2: {
          min: 2,
          max: null
        },
        leg2Leg3: {
          min: null,
          max: null
        },
        leg3Leg4: {
          min: null,
          max: null
        }
      }
    }
  }
}

export const CONSTRAINTS = {
  dte: {
    presence: true,
    numericality: true
  },
  dteMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  dteMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  absDelta: {
    presence: true,
    numericality: true
  },
  absDeltaMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  absDeltaMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  contractSize: {
    presence: true,
    numericality: true
  },
  entryDays: {
    presence: true,
    numericality: true
  },
  entryDaysToEarnMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  entryDaysToEarnMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitBizDaysAfterEarn: {
    numericOr: {
      text: "none"
    }
  },
  exitBizDaysBeforeEarn: {
    numericOr: {
      text: "none"
    }
  },
  exitDTEDays: {
    presence: true,
    numericOr: {
      text: "expire"
    }
  },
  exitHoldDays: {
    presence: true,
    numericality: true
  },
  exitLeg1DeltaMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitLeg1DeltaMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitLeg1OTMPctMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitLeg1OTMPctMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitProfitLossPctMin: {
    numericOr: {
      text: "none",
      fieldName: "Stop Loss",
      range: [-500, 500]
    },
    minMax: true
  },
  exitProfitLossPctMax: {
    numericOr: {
      text: "none",
      mustBePositive: true,
      fieldName: "Profit Target",
      range: [0, 500]
    },
    minMax: true
  },
  exitSpreadDeltaMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitSpreadDeltaMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitSpreadPxMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitSpreadPxMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitStrikeDiffPctValueMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  exitStrikeDiffPctValueMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  hedgeDays: {
    presence: true,
    numericality: true
  },
  iBidVolMin: {
    numericOr: {
      text: "none",
      mustBeInteger: true,
      fieldName: "Min",
      mustBePositive: true,
      range: [3, 500]
    },
    minMax: true
  },
  iBidVolMax: {
    numericOr: {
      text: "none",
      mustBeInteger: true,
      fieldName: "Max",
      mustBePositive: true,
      range: [3, 500]
    },
    minMax: true
  },
  iAskVolMin: {
    numericOr: {
      text: "none",
      mustBeInteger: true,
      fieldName: "Min",
      mustBePositive: true,
      range: [3, 500]
    },
    minMax: true
  },
  iAskVolMax: {
    numericOr: {
      text: "none",
      mustBeInteger: true,
      fieldName: "Max",
      mustBePositive: true,
      range: [3, 500]
    },
    minMax: true
  },
  iVRankMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  iVRankMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg1Leg2DeltaTotalMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg1Leg2DeltaTotalMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg1Leg2DteDiffMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg1Leg2DteDiffMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg1Leg2StrikeWidthMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg1Leg2StrikeWidthMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg2Leg3DeltaTotalMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg2Leg3DeltaTotalMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg2Leg3DteDiffMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg2Leg3DteDiffMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg2Leg3StrikeWidthMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg2Leg3StrikeWidthMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg3Leg4DeltaTotalMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg3Leg4DeltaTotalMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg3Leg4DteDiffMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg3Leg4DteDiffMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg3Leg4StrikeWidthMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  leg3Leg4StrikeWidthMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  mktWidthPctMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  mktWidthPctMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  spreadDelta: {
    presence: true,
    numericality: true
  },
  spreadDeltaMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  spreadDeltaMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  spreadPx: {
    presence: true,
    numericality: true
  },
  spreadPxMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  spreadPxMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  spreadYieldPctMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  spreadYieldPctMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  stockOTMPct: {
    presence: true,
    numericality: true
  },
  stockOTMPctMax: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  stockOTMPctMin: {
    numericOr: {
      text: "none"
    },
    minMax: true
  },
  adjDeltaMin: {
    numericOr: {
      text: "none",
      range: [0, 1]
    },
    minMax: true
  },
  adjDeltaMax: {
    numericOr: {
      text: "none",
      range: [0, 1]
    },
    minMax: true
  },
  minDaysForAdjusting: {
    numericOr: {
      text: "none",
      mustBeInteger: true,
      range: [1, 1000]
    }
  },
  maxDaysForAdjusting: {
    numericOr: {
      text: "none",
      mustBeInteger: true,
      range: [1, 1000]
    }
  },
  adjToDelta: {
    numericOr: {
      text: "none",
      range: [0, 1]
    }
  },
  adjToDeltaMin: {
    numericOr: {
      text: "none",
      range: [0, 1]
    },
    minMax: true
  },
  adjToDeltaMax: {
    numericOr: {
      text: "none",
      range: [0, 1]
    },
    minMax: true
  },
  tiedToLegNum: {
    numericOr: {
      text: "none",
      mustBeInteger: true,
      range: [0, "maxLegs"]
    }
  },
  tiedDeltaMin: {
    numericOr: {
      text: "none",
      range: [0, 1]
    },
    minMax: true
  },
  tiedDeltaMax: {
    numericOr: {
      text: "none",
      range: [0, 1]
    },
    minMax: true
  },
  rollWithLeg: {
    numericOr: {
      text: "none",
      mustBeInteger: true,
      range: [0, "maxLegs"]
    }
  }
}

export const VALID_DATE_FORMATS = ["YYYY-MM-DD", "M/D/YYYY"]
