import React, { Component } from "react"
import theme from "../theme"
import _ from "lodash"
import { hover, borderColor, borders } from "styled-system"
import { Button as SRButton, Segment, Menu, Message } from "semantic-ui-react"
import { NavLink as RouterNavLink } from "react-router-dom"
import { track } from "../../services/analytics"
import {
  Provider,
  Flex,
  Link as RebassLink,
  Box,
  Button as RebassButton,
  Heading,
  Container,
  Text,
  Image,
  Row,
  Column
} from "rebass"
import { inject } from "mobx-react"
import styled from "styled-components"
import Hero from "../../images/wheelHero.svg"
const FEATURE_ICON_SIZE = 34
const FEATURE_ICON_COLOR = "e98649"

const IconImage = ({ icon, color: c }) => {
  const [color, index] = c ? c.split(".") : [null, null]
  const src = `https://icongr.am/material/${icon}.svg?size=27&color=${
    color ? theme.colors[color][index].substring(1) : "FFFFFF"
  }`
  return (
    <Image
      mt={"-4px"}
      style={{ display: "inline-block", verticalAlign: "middle" }}
      src={src}
    />
  )
}

const IconBox = ({ icon }) => {
  const src = `https://icongr.am/material/${icon}.svg?size=${FEATURE_ICON_SIZE}&color=${FEATURE_ICON_COLOR}`
  return (
    <Box
      mr={3}
      style={{
        borderRadius: 6,
        display: "inline-block"
      }}
      bg="gray.1"
      p={2}>
      <Image
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          minWidth: FEATURE_ICON_SIZE
        }}
        src={src}
      />
    </Box>
  )
}

const Button = RebassButton.extend`
  cursor: pointer;
  ${hover};
  ${borderColor};
`
RebassButton.defaultProps.blacklist = [
  ...Object.keys(Button.propTypes),
  "hover",
  "borderColor"
]

const FooterHeading = Heading.extend`
  ${borders};
  ${hover};
  ${borderColor};
`
FooterHeading.defaultProps.blacklist = [
  ...Object.keys(Heading.propTypes),
  "borderBottom",
  "borderColor"
]

const Link = RebassLink.extend`
  ${hover};
`
RebassLink.defaultProps.blacklist = [
  ...Object.keys(RebassLink.propTypes),
  "hover"
]

const BorderBox = Box.extend`
  ${borders};
  ${borderColor};
`

const FeatureListItem = styled.li`
  padding: 0.8rem 0;
  padding-left: 1.5rem;
  position: relative;
  line-height: 1.5;
  color: #ab9d94;
  &:before {
    background-image: url(https://icongr.am/feather/check.svg?size=16&color=ab9d94);
    background-position: center center;
    background-repeat: no-repeat;
    content: "";
    display: block;
    height: 16px;
    left: -4px;
    top: calc(0.8rem + 0.2em);
    position: absolute;
    width: 16px;
  }
  &:after {
    clear: both;
    content: "";
    display: block;
  }
`

const FeatureBlock = ({ heading, icon, text }) => {
  return (
    <table>
      <tbody>
        <tr>
          <td />
          <td>
            <Heading fontSize={3} mb={2} children={heading} />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            <IconBox icon={icon} />
          </td>
          <td>
            <Text color="gray.8">{text}</Text>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

@inject("userStore")
export default class Landing extends Component {
  componentWillMount() {
    const { history, auth } = this.props
    if (auth.isAuthenticated()) {
      history.push("/backtest")
    }
  }

  componentDidMount() {
    track("Landing Page View")
  }

  onRequestDemoClick = () => {
    track("Click Request Demo")
    window.open("https://meetme.so/MattAmberson", "_blank")
  }

  talkSales = (e, data) => {
    window.location = "mailto:support@orats.com"
  }

  render() {
    const logoUrl = `/images/logo5.svg`
    return (
      <Provider theme={theme}>
        <Box bg="white">
          <Container pt={4} mb={5}>
            <Heading fontSize={6} pb={4} textAlign="center">
              <span>Pre-Test Your Trading Strategy</span>
              <br />
              <span>For Consistent Results</span>
            </Heading>
            <Flex alignItems="center" justifyContent="center">
              <Image width={340} pb={5} src={Hero} />
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              <Button
                is={RouterNavLink}
                to="/auth?signUp"
                fontSize="4"
                fontWeight="300"
                color="orange.7"
                border="2px solid"
                borderColor="orange.7"
                bg="white"
                hover={{
                  color: "orange.7",
                  borderColor: "orange.6",
                  backgroundColor: "orange.1",
                  boxShadow: 1
                }}
                children={"Free Trial"}
              />
              <Box mx={2} />
              <Button
                fontSize="4"
                fontWeight="300"
                color="blue.0"
                border="2px solid"
                borderColor="blue.7"
                bg="blue.7"
                hover={{
                  color: "white",
                  borderColor: "blue.6",
                  backgroundColor: "blue.6",
                  boxShadow: 1
                }}
                onClick={this.onRequestDemoClick}
                children={"Request a Demo"}
              />
            </Flex>
            <Flex alignItems="center" justifyContent="center">
              {/* <Text fontSize="3" pt={4}>
                Watch a demo
              </Text> */}
              <Button
                is={RouterNavLink}
                to="/video"
                mt={4}
                fontSize="3"
                fontWeight="300"
                color="gray.7"
                borderColor="gray.7"
                bg="white"
                hover={{
                  color: "gray.7"
                }}
                children={
                  <span>
                    <IconImage icon="play" color="gray.7" /> Watch a video
                  </span>
                }
              />
            </Flex>
          </Container>
          <Box bg="gray.2" pt={100} pb={120}>
            <Container>
              <Heading
                textAlign="center"
                fontSize={5}
                children="The challenge of testing all market conditions"
              />
              <Heading
                mt={3}
                textAlign="center"
                fontWeight={300}
                mb={4}
                mr="auto"
                ml="auto"
                style={{ maxWidth: 800 }}
                fontSize={4}
                children="Backtesting has always been either hard to use or crippled by incomplete or insufficient data. The ORATS Wheel solves this with:"
              />
              <Row>
                <Column w={1 / 3}>
                  <Heading fontSize={3} mb={2} children="Data Quality" />
                  <Text>
                    Founded on the floor of the CBOE for market makers over a
                    decade ago, ORATS delivers proven historical volatilities,
                    dividends and earnings modeling techniques.
                  </Text>
                </Column>
                <Column w={1 / 3}>
                  <Heading fontSize={3} mb={2} children="Ease of Use" />
                  <Text>
                    Our convenient tool to identify profitable strategies and to
                    prove out ORATS alpha.
                  </Text>
                </Column>
                <Column w={1 / 3}>
                  <Heading fontSize={3} mb={2} children="Depth of Options" />
                  <Text>
                    Give yourself an edge with unique implied volatility
                    summarizations, ex-earnings calculations and forecasts.
                  </Text>
                </Column>
              </Row>
            </Container>
          </Box>
          <Box pt={80} pb={40} bg="white">
            <Container>
              <Row>
                <Column w={1 / 2}>
                  <FeatureBlock
                    icon="check-all"
                    heading="Easy Backtesting"
                    text="With our intuitive backtest form, you can easily set up a backtest in seconds."
                  />
                </Column>
                <Column w={1 / 2}>
                  <FeatureBlock
                    icon="library-books"
                    heading="Powerful Reporting"
                    text="See all the standard statistical financial figures, a
                  graph of the options strategy returns with the
                  underlying stock, monthly returns and the trades for
                  the strategy. You can edit the backtest parameters and
                  download the statistics and trades."
                  />
                </Column>
              </Row>
              <Row>
                <Column w={1 / 2}>
                  <FeatureBlock
                    icon="keyboard"
                    heading="Advanced Backtest Parameters"
                    text="Exits rules that use delta, out-of-the-money
                  percentages, profit percentage or days left in the
                  trade. Indicate your special reentry rule for exit
                  triggers. Utilize delta hedging with flexible hedging
                  dates or delta tolerances. Portfolio weighting to
                  backtest a group of stocks together."
                  />
                </Column>
                <Column w={1 / 2}>
                  <FeatureBlock
                    icon="chart-line"
                    heading="10 Years of Deep Clean Data"
                    text="Perhaps the most important and least appreciated part
                  of backtesting is the underlying options data. ORATS
                  utilizes our decades of experience as successful
                  options traders to apply sophisticated quote cleaning,
                  smoothing algorithms and calculations of greeks and
                  theoretical values to aid the ORATS tools and data.
                  Indexes like SPX and VIX and weekly expirations are
                  included."
                  />
                </Column>
              </Row>
              <Row>
                <Column w={1 / 2}>
                  <FeatureBlock
                    icon="radar"
                    heading="Signals"
                    text="The ability to test strategies in various technical
                  environments is incredibly important to successful
                  options trading. You have the ability to input entry
                  and exit dates."
                  />
                </Column>
                <Column w={1 / 2}>
                  <FeatureBlock
                    icon="sync"
                    heading="Scanner, Executions and Exiting Integrations"
                    text="After you set up your backtest, you can use the same
                  inputs to scan in real-time. After reviewing the
                  current market in the TradeFinder Scanner, send
                  options to the Execution Monitor for delivering to
                  brokers. You can track filled (or paper traded)
                  options to the Exit Monitor to track profit and exit
                  alerts."
                  />
                </Column>
              </Row>
            </Container>
          </Box>
          <BorderBox
            pb={80}
            pt={40}
            bg="white"
            borderTop="1px solid"
            borderColor="gray.1">
            <Container>
              <Heading textAlign="center" fontSize={5} children="Pricing" />
              <Heading
                pb={40}
                textAlign="center"
                fontSize={4}
                fontWeight={300}
                children="Plans that scale with you or your organization's needs"
              />

              <Row>
                <Column w={1 / 4}>
                  <Segment.Group piled>
                    <Segment textAlign="center">
                      <span>FREE</span>
                      <Heading fontWeight={300}>Free</Heading>
                    </Segment>
                    <Segment>
                      <ul
                        style={{
                          listStyle: "none",
                          padding: 0,
                          margin: 0
                        }}>
                        <FeatureListItem>One Symbol</FeatureListItem>
                      </ul>
                    </Segment>
                  </Segment.Group>
                </Column>
                <Column w={1 / 4}>
                  <Segment.Group piled>
                    <Segment textAlign="center">
                      <span>BASIC</span>
                      <Heading fontWeight={300}>
                        $99
                        <span style={{ fontSize: 18, color: "rgba(0,0,0,.6)" }}>
                          /mo
                        </span>
                      </Heading>
                    </Segment>
                    <Segment>
                      <ul
                        style={{
                          listStyle: "none",
                          padding: 0,
                          margin: 0
                        }}>
                        <FeatureListItem>
                          Non-professional subscribers only
                        </FeatureListItem>
                        <FeatureListItem>Unlimited Symbols</FeatureListItem>
                      </ul>
                    </Segment>
                  </Segment.Group>
                </Column>
                <Column w={1 / 4}>
                  <Segment.Group piled>
                    <Segment textAlign="center">
                      <span>PRO</span>
                      <Heading fontWeight={300}>
                        $299
                        <span style={{ fontSize: 18, color: "#616A6B" }}>
                          /mo
                        </span>
                      </Heading>
                    </Segment>
                    <Segment>
                      <ul
                        style={{
                          listStyle: "none",
                          padding: 0,
                          margin: 0
                        }}>
                        <FeatureListItem>
                          Professional subscribers or non-professional
                          subscribers wanting access to additional features
                        </FeatureListItem>
                        <FeatureListItem>Unlimited Symbols</FeatureListItem>
                        <FeatureListItem>
                          Combine multiple backtests into one report
                        </FeatureListItem>
                        <FeatureListItem>
                          Apply signal dates to enter and exit trades
                        </FeatureListItem>
                      </ul>
                    </Segment>
                  </Segment.Group>
                </Column>
                <Column w={1 / 4}>
                  <Segment.Group piled>
                    <Segment textAlign="center">
                      <Flex justifyContent="center">
                        <Image src="https://icongr.am/clarity/building.svg?size=40" />
                      </Flex>
                      <span>ENTERPRISE</span>
                    </Segment>
                    <Segment>
                      <Text pt={12} color="gray.6">
                        We offer custom packages tailored to suit your specific
                        requirements
                      </Text>
                      <Flex justifyContent="center">
                        <Box pt={20}>
                          <Button
                            color="blue.0"
                            border="2px solid"
                            borderColor="blue.7"
                            bg="blue.7"
                            hover={{
                              color: "white",
                              borderColor: "blue.6",
                              backgroundColor: "blue.6",
                              boxShadow: 1
                            }}
                            onClick={this.talkSales}
                            children={"Contact Us"}
                          />
                        </Box>
                      </Flex>
                    </Segment>
                  </Segment.Group>
                </Column>
              </Row>
              <Text
                pt={20}
                pb={20}
                color="gray.6"
                style={{ cursor: "pointer" }}
                textAlign="center"
                fontSize={4}>
                <Link
                  is={RouterNavLink}
                  to="/auth?signUp"
                  hover={{ color: "orange.6" }}
                  href=""
                  color="orange.7">
                  Get Started with a Free Trial
                </Link>
              </Text>

              <Heading
                pt={20}
                textAlign="center"
                fontSize={4}
                children="Core Features"
              />
              <Heading
                pb={20}
                textAlign="center"
                fontSize={3}
                fontWeight={300}
                children="In all plans, including Free"
              />
              <Flex justifyContent="center">
                <Box width={640}>
                  <Text color="gray.6" fontSize={2} textAlign="center">
                    <Text
                      pb={3}
                      children="Easy custom interface with fast calculations of your
                  strategy"
                    />
                    <Text
                      pb={3}
                      children="Best market data enhanced with smoothed market values and
                  forecasted values on all US equity options expirations going
                  back to 2007"
                    />
                    <Text
                      pb={3}
                      children="Define your option trading strategy using days to
                  expiration, delta or percentage out-of-the-money"
                    />
                    <Text
                      pb={3}
                      children="Filter by yield percentages and market width"
                    />
                    <Text
                      pb={3}
                      children="Exit rules that use delta, out-of-the-money percentages,
                  profit percentage or days left in the trade. Indicate your
                  special reentry rule for exit triggers"
                    />
                    <Text
                      pb={3}
                      children="Utilize delta hedging with flexible hedging dates or delta
                  tolerances"
                    />
                  </Text>
                </Box>
              </Flex>
            </Container>
          </BorderBox>
          <footer>
            <Box bg="gray.3" pt={5} pb={2}>
              <Container>
                <Row>
                  <Column>
                    <FooterHeading
                      py={2}
                      fontSize={1}
                      borderBottom="1px solid"
                      borderColor="gray.5"
                      color="gray.8"
                      children="COMPANY"
                    />
                    {_.map(
                      [
                        ["About", "https://orats.com/about"],
                        ["Products", "https://orats.com/products"],
                        ["Blog", "http://blog.orats.com"]
                      ],
                      ([text, href], i) => {
                        return (
                          <Link
                            key={`comp-${i}`}
                            mb={1}
                            mt={1}
                            style={{ display: "block" }}
                            hover={{ color: "orange.6" }}
                            color="orange.7"
                            href={href}>
                            {text}
                          </Link>
                        )
                      }
                    )}
                  </Column>
                  <Column>
                    <FooterHeading
                      py={2}
                      fontSize={1}
                      borderBottom="1px solid"
                      borderColor="gray.5"
                      color="gray.8"
                      children="CONTACT US"
                    />
                    <table>
                      <tbody>
                        {_.map(
                          [
                            [
                              "Twitter",
                              "@optionrats",
                              "https://twitter.com/optionrats"
                            ],
                            [
                              "Info",
                              "contactus@orats.com",
                              "mailto:contactus@orats.com"
                            ],
                            [
                              "Support",
                              "support@orats.com",
                              "mailto:support@orats.com"
                            ],
                            ["Phone", "(312) 986-1060", "tel:+1-312-986-1060"]
                          ],
                          ([type, text, href], i) => {
                            return (
                              <tr key={`contact-${i}`}>
                                <td style={{ minWidth: 80 }}>
                                  <Text fontWeight="bold" color="gray.8">
                                    {type}
                                  </Text>
                                </td>
                                <td>
                                  <Link
                                    hover={{ color: "orange.6" }}
                                    color="orange.7"
                                    href={href}>
                                    {text}
                                  </Link>
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                  </Column>
                  <Column />
                </Row>
                <Text pt={4} pb={4}>
                  © 2018{" "}
                  <Link
                    hover={{ color: "orange.6" }}
                    color="orange.7"
                    href="https://www.orats.com/">
                    {" "}
                    Option Research & Technology Services
                  </Link>
                </Text>
              </Container>
            </Box>
          </footer>
        </Box>
      </Provider>
    )
  }
}
