import React, { Component } from "react"
import { GenericChartComponent, utils } from "react-stockcharts"
import find from "lodash/find"
import map from "lodash/map"
import maxBy from "lodash/maxBy"
import minBy from "lodash/minBy"

import numeral from "numeral"

const { noop } = utils

const fills = [
  "rgb(150, 196, 117)",
  "rgb(150, 196, 117)",
  "rgb(150, 196, 117)",
  "#B3B6B7",
  "rgb(226, 107, 115)",
  "rgb(226, 107, 115)",
  "rgb(226, 107, 115)"
]

// not good here
const topMidBottomValues = (currentPrice, step, bin) => {
  return {
    top: currentPrice + (currentPrice * (bin + step / 2)) / 100,
    mid: currentPrice + (currentPrice * bin) / 100,
    bottom: currentPrice + (currentPrice * (bin - step / 2)) / 100
  }
}

export default class DistributionBars extends Component {
  saveNode = node => {
    this.node = node
  }

  renderSVG = moreProps => {
    const {
      xScale,
      chartConfig: { yScale },
      plotData
    } = moreProps
    const { xAccessor } = moreProps
    const { selectedDistribution, selectedExpiration } = this.props

    const dateIndex = find(plotData, {
      tradeDate: selectedExpiration.expiration
    })

    if (dateIndex === undefined) {
      return null
    }

    const x = xScale(xAccessor(dateIndex))

    const step = selectedDistribution[2].bin
    const currentPrice = selectedDistribution[3].calcPrice
    const boxTop = yScale(
      currentPrice +
        (currentPrice * (maxBy(selectedDistribution, "bin").bin + step / 2)) /
          100
    )
    const boxBottom = yScale(
      currentPrice +
        (currentPrice * (minBy(selectedDistribution, "bin").bin - step / 2)) /
          100
    )

    const bars = map(selectedDistribution, (d, i) => {
      const { top: topValue, bottom: bottomValue } = topMidBottomValues(
        currentPrice,
        step,
        d.bin
      )
      const { probability, bin } = d

      const top = yScale(topValue)
      const height = yScale(bottomValue) - top - 2
      const prob = probability * 100 < 0 ? 0 : probability * 100
      return (
        <g key={i}>
          <rect
            x={0}
            y={top + 1}
            width={prob}
            height={height < 0 ? 0 : height}
            fill={fills[i]}
          />
          <text
            x={105}
            y={top + height / 2 + 5}
            fill="#666"
            fontFamily="Roboto"
            fontSize="8pt">
            {numeral(probability).format("0%")}
          </text>
          <text
            x={-4}
            y={top + height / 2 + 5}
            fill="#666"
            textAnchor="end"
            fontFamily="Roboto"
            fontSize="8pt">
            {numeral(bin / 100).format("0.0%")}
          </text>
        </g>
      )
    })

    // const boxTop = yScale(selectedDistribution[0].bar.top)-3
    return (
      <g transform={`translate(${x}, 0)`}>
        <text
          x={4}
          y={boxTop - 5}
          fill="#666"
          fontFamily="Roboto"
          fontSize="8pt">
          Historical distribution of stock prices
        </text>
        <rect
          x={0}
          y={boxTop - 3}
          height={boxBottom - boxTop + 6}
          width="103"
          fill="whitesmoke"
        />
        {bars}
      </g>
    )
  }

  render() {
    return (
      <GenericChartComponent
        ref={this.saveNode}
        svgDraw={this.renderSVG}
        drawOnPan
      />
    )
  }
}

DistributionBars.drawOnCanvas = noop
