import React from "react"
import { observer, inject } from "mobx-react"
import { Dropdown, Grid, Menu, Image, Button } from "semantic-ui-react"
import { Flex, Box, Text } from "rebass"
import SymbolSlug from "./Scan/SymbolSlug"
import { NavLink, withRouter } from "react-router-dom"
import _ from "lodash"
import Logo from "../images/logo5.svg"

const PUBLIC_ROUTES = ["/", "/reset", "/video", "/bt"]
const NO_NAV = ["/auth", "/video", "/reset"]

@withRouter
@inject("userStore")
@observer
export default class Nav extends React.Component {
  render() {
    const {
      userStore: {
        user: { email },
        isEnterpriseUser,
        canSeePositions
      }
    } = this.props

    const {
      location: { pathname }
    } = this.props
    const noNav = _.includes(NO_NAV, pathname) || /^\/bt/.test(pathname)
    return (
      <Box p={noNav ? 0 : 3}>
        {noNav ? null : (
          <Menu>
            <Menu.Item fitted style={{ padding: 4 }}>
              <Image width={120} href="/backtest" src={Logo} alt="orats logo" />
            </Menu.Item>
            {pathname === "/scan" && <SymbolSlug />}

            {!_.includes(PUBLIC_ROUTES, pathname) && (
              <Menu.Menu position="right">
                {!isEnterpriseUser && (
                  <Menu.Item
                    href="http://info.orats.com/pricing"
                    name="Pricing"
                  />
                )}
                <Menu.Item as={NavLink} to={`/scan`} name="TradeFinder" />
                <Dropdown text={email} className="link item">
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={this.props.userStore.logout}
                      text="Logout"
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            )}

            {pathname === "/" && (
              <Menu.Menu position="right">
                <Flex px={2} py={2} alignItems="center">
                  <Box mx="auto" />
                  <Button
                    as={NavLink}
                    to={`/auth`}
                    basic
                    content="Login"
                    color="orange"
                  />
                  <Box ml={2} />
                  <Button
                    as={NavLink}
                    to={`/auth?signUp`}
                    content="Signup"
                    color="orange"
                  />
                </Flex>
              </Menu.Menu>
            )}
          </Menu>
        )}
      </Box>
    )
  }
}
