import React, { Component } from "react"
import { NavLink as RouterNavLink } from "react-router-dom"
import { hover, borderColor } from "styled-system"
import { track } from "../../services/analytics"

import {
  NavLink as RebassNavLink,
  Embed,
  Box,
  Button as RebassButton,
  Image
} from "rebass"

const Button = RebassButton.extend`
  cursor: pointer;
  ${hover};
  ${borderColor};
`
RebassButton.defaultProps.blacklist = [
  ...Object.keys(Button.propTypes),
  "hover",
  "borderColor"
]

const NavLink = RebassNavLink.extend`
  ${hover};
`
RebassNavLink.defaultProps.blacklist = [
  ...Object.keys(RebassNavLink.propTypes),
  "hover"
]

export default class Video extends Component {
  componentDidMount() {
    track("Watch Video")
  }
  render() {
    return (
      <div>
        <Box p={3}>
          <NavLink is={RouterNavLink} to="/">
            <Image src="https://icongr.am/material/close.svg?color=5D6D7E" />
          </NavLink>
        </Box>
        <Embed>
          <iframe
            title="orats wheel promo video"
            height="315"
            src="https://www.youtube.com/embed/PSdd20L4vfU?start=51"
            frameBorder="0"
            allowFullScreen
          />
        </Embed>
      </div>
    )
  }
}
