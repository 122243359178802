import { observable, computed, toJS } from "mobx"
import _ from "lodash"
import Moment from "moment"
import numeral from "numeral"
import copy from "copy-to-clipboard"
import { v4 as uuid } from "uuid"

const STRATSIGMAP = {
  "2SDSS": "VERTICAL",
  "2SDDS": "BACKRATIO",
  "2DSSS": "CALENDAR",
  "2DDSS": "DIAGONAL",
  "2SSSD": "STRADDLE",
  "2SDSD": "STRANGLE",
  "3SDSSD": "BUTTERFLY",
  "4SDSSDSDS": "CONDOR",
  "4SDSSDDDS": "IRON CONDOR"
}

const MAX_DLT_CHG = 0.03

// maybe rename `status` to `type`
export default class Holding {
  @observable
  id = null
  @observable
  ticker = null
  @observable
  legs = []
  @observable
  qty = 0
  @observable
  exitParams = null
  @observable
  backtestId = null
  @observable
  orderId = null
  @observable
  tradePrice = null
  @observable
  exit = null
  @observable
  avgFillPrice = null
  @observable
  status = null

  // constructor() {}
  constructor({
    id,
    status,
    ticker,
    legs,
    qty,
    exitParams,
    backtestId,
    orderId
  }) {
    this.id = id || `temp-${uuid()}`
    this.status = status || "ACTIVE"
    this.ticker = _.trim(ticker)
    this.legs = legs
    this.qty = isNaN(Math.abs(qty)) ? 1 : Math.abs(qty)
    this.exitParams = exitParams || null
    this.backtestId = backtestId || null
    this.orderId = orderId ? `${orderId}` : uuid()
  }

  static holdingFromArray(arr) {
    const { ticker, orderId, qty } = arr[0]

    const legs = _.map(arr, ({ orderId, ...leg }) => ({
      ...leg,
      ticker: _.trim(leg.ticker),
      expirDate: leg.expirDate
        ? Moment(leg.expirDate, [
            "DD MMM YY",
            "DD-MMM-YY",
            "YYYY-MM-DD"
          ]).format("YYYY-MM-DD")
        : null,
      optionType:
        leg.optionType === ""
          ? null
          : leg.optionType === "CALL" || leg.optionType === "C"
          ? "CALL"
          : "PUT",
      ratio: leg.qty < 0 ? -1 : 1
    }))
    const hldg = new Holding({
      ticker,
      orderId,
      legs,
      qty
    })
    hldg.tradePrice = hldg.spreadPrice
    return hldg
  }

  @computed
  get isSaved() {
    return this.id !== null
  }

  @computed
  get spreadTradePrice() {
    return _.reduce(
      this.legs,
      (sum, { ratio, tradeOptPx }, i) => {
        return sum + tradeOptPx * ratio
      },
      0
    )
  }

  @computed
  get mark() {
    return this.spreadTradePrice * -1
  }

  // @computed
  // get orderSide() {
  //   return this.positionType === "opening"
  //     ? this.action === "BUY" ? "BUY" : "SELL"
  //     : this.action === "BUY" ? "SELL" : "BUY"
  // }

  @computed
  get spreadDelta() {
    return (
      _.reduce(
        this.legs,
        (sum, { ratio, delta }, i) => {
          return sum + delta * ratio
        },
        0
      ) * 100
    )
  }

  @computed
  get optionDelta() {
    return _.reduce(
      this.legs,
      (sum, { ratio, delta }, i) => {
        return sum + delta * ratio
      },
      0
    )
  }

  // spreadDelta is sourced from the exit trade, so we need to negate
  @computed
  get spreadTotalDelta() {
    return this.qty * this.spreadDelta * -1
  }

  @computed
  get spreadGamma() {
    return (
      _.reduce(
        this.legs,
        (sum, { ratio, gamma }, i) => {
          return sum + gamma * ratio
        },
        0
      ) * 100
    )
  }

  @computed
  get spreadTotalGamma() {
    return this.qty * this.spreadGamma * -1
  }

  @computed
  get spreadVega() {
    return (
      _.reduce(
        this.legs,
        (sum, { ratio, vega }, i) => {
          return sum + vega * ratio
        },
        0
      ) * 100
    )
  }

  @computed
  get spreadTotalVega() {
    return this.qty * this.spreadVega * -1
  }

  @computed
  get spreadRipDelta() {
    return _.reduce(
      this.legs,
      (sum, { ratio, ripDelta }, i) => {
        return sum + ripDelta * ratio * 100
      },
      0
    )
  }

  @computed
  get spreadTotalRipDelta() {
    return this.qty * this.spreadRipDelta * -1
  }

  @computed
  get market() {
    // if we are Stock return {stockPx: currentPrice}
    return this.exit
      ? _.pick(this.exit.trade, [
          "optionDelta",
          "mark",
          "spreadTotalDelta",
          "spreadTotalVega",
          "spreadTotalGamma",
          "spreadTotalRipDelta",
          "spreadTradePrice",
          "stockPx"
        ])
      : null
  }

  @computed
  get exitTrade() {}

  @computed
  get stockPx() {
    return this.legs[0].stockPx
  }

  // @computed
  // get filled() {
  //   return `${this.fillQty || 0} / ${this.orderQty}`
  // }

  // @computed
  // get ticker() {
  //   return this.legs[0].ticker
  // }

  @computed
  get orderSide() {
    return this.spreadTradePrice > 0 ? "BUY" : "SELL"
  }

  @computed
  get legCount() {
    return this.legs.length
  }

  @computed
  get oneTwoDTE() {
    return this.legs[0].expirDate === this.legs[1].expirDate ? "S" : "D"
  }

  @computed
  get oneTwoStrike() {
    return this.legs[0].strike === this.legs[1].strike ? "S" : "D"
  }

  @computed
  get twoThreeStrike() {
    return this.legs[1].strike === this.legs[2].strike ? "S" : "D"
  }

  @computed
  get threeFourStrike() {
    return this.legs[2].strike === this.legs[3].strike ? "S" : "D"
  }

  @computed
  get oneTwoQty() {
    return Math.abs(this.legs[0].ratio) === Math.abs(this.legs[1].ratio)
      ? "S"
      : "D"
  }

  @computed
  get oneTwoOptionType() {
    return this.legs[0].optionType === this.legs[1].optionType ? "S" : "D"
  }

  @computed
  get twoThreeOptionType() {
    return this.legs[1].optionType === this.legs[2].optionType ? "S" : "D"
  }

  @computed
  get threeFourOptionType() {
    return this.legs[2].optionType === this.legs[3].optionType ? "S" : "D"
  }

  @computed
  get strategyLabel() {
    let result = ""

    if (this.legCount === 2) {
      const sig = [
        this.legCount,
        this.oneTwoDTE,
        this.oneTwoStrike,
        this.oneTwoQty,
        this.oneTwoOptionType
      ].join("")
      result = STRATSIGMAP[sig]
    } else if (this.legCount === 3) {
      const sig = [
        this.legCount,
        this.oneTwoDTE,
        this.oneTwoStrike,
        this.oneTwoQty,
        this.oneTwoOptionType,
        this.twoThreeStrike
      ].join("")
      result = STRATSIGMAP[sig]
    } else if (this.legCount === 4) {
      const sig = [
        this.legCount,
        this.oneTwoDTE,
        this.oneTwoStrike,
        this.oneTwoQty,
        this.oneTwoOptionType,
        this.twoThreeStrike,
        this.twoThreeOptionType,
        this.threeFourStrike,
        this.threeFourOptionType
      ].join("")
      result = STRATSIGMAP[sig]
    }

    return result
  }

  @computed
  get action() {
    return this.spreadTradePrice > 0 ? "BUY" : "SELL"
  }

  @computed
  get thinkOrSwimSlug() {
    if (this.isStock) {
      return ""
    } else {
      const { legs } = this
      const { ratio, expirDate, optionType, ticker } = legs[0]
      let qty = this.qty
      const [day, month, year] = Moment(expirDate)
        .format("DD-MMM-YY")
        .split("-")
      if (legs.length > 1 && this.oneTwoQty === "D") {
        qty = `${qty} ${Math.abs(ratio)}/${Math.abs(legs[1].ratio)}`
      }
      const strikes = _.map(legs, "strike").join("/")
      const result = `${this.orderSide} ${
        this.spreadTradePrice < 0 ? "-" : ""
      }${qty} ${
        this.strategyLabel
      } ${ticker} 100 ${day} ${month.toUpperCase()} ${year} ${strikes} ${optionType.toUpperCase()} @${numeral(
        Math.abs(this.spreadTradePrice)
      ).format("0.00")} LMT`
      return result.replace("  ", " ")
    }
  }

  @computed
  get srOrder() {
    const { ticker, expirDate, strike, optionType } = this.legs[0]
    const { qty, spreadTradePrice, positionType, orderSide } = this
    return {
      ticker,
      expirDate,
      orderSide,
      strike,
      optionType,
      orderQty: qty,
      price: spreadTradePrice,
      positionType
    }
  }

  @computed
  get signature() {
    return _.map(this.legs, ({ ticker, optionType, expirDate, strike }) => {
      return `${ticker}.${optionType}.${expirDate}.${strike}`
    }).join(":")
  }

  _gammaRange = () => {
    const G = this.spreadGamma / 100
    return Math.max(0.1, MAX_DLT_CHG / Math.abs(G))
  }

  @computed
  get stockMax() {
    return numeral(this.stockPx + this._gammaRange()).format("0.00")
  }

  @computed
  get stockMin() {
    return numeral(this.stockPx - this._gammaRange()).format("0.00")
  }

  @computed
  get profit() {
    if (this.isStock) {
      return this.market
        ? (this.market.mark - Math.abs(this.spreadTradePrice)) * (this.qty * 1)
        : null
    } else {
      return this.market
        ? (this.market.mark - this.spreadTradePrice) * this.qty * 100
        : null
    }
  }

  @computed
  get profitDay() {
    return null
  }

  // not a very complete implementation here.
  // the whole trade gets labeled as expired even if some of the legs are not
  @computed
  get isExpired() {
    const exp = _.find(this.legs, ({ expirDate }) => {
      return Moment(expirDate)
        .endOf("d")
        .isBefore(Moment())
    })
    return exp !== undefined
  }

  @computed
  get isStock() {
    return _.reject(this.legs, l => l.expirDate === null).length === 0
  }

  copyToClipboard() {
    copy(this.thinkOrSwimSlug)
  }

  // adjustQuantity(q) {
  //   this.qty = q
  // console.log(this.legs)
  // this.legs = _.map(this.legs, leg => {
  //   const qty = leg.qty < 0 ? Math.abs(q) * -1 : Math.abs(q)
  //   return { ...leg, qty }
  // })
  // console.log(this.legs)
  // }

  toJSON() {
    return {
      ..._.pick(this, [
        "id",
        "ticker",
        "market",
        "thinkOrSwimSlug",
        "qty",
        "ratio",
        "orderId",
        "spreadTradePrice",
        "profit",
        "profitDay",
        "exitParams",
        "exit"
      ])
    }
  }
}
