import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { toJS, observable, computed } from "mobx"

import {
  Label,
  Input,
  Table,
  Modal,
  Icon,
  Button,
  Form,
  Checkbox,
  Tab,
  Loader
} from "semantic-ui-react"
import { Text } from "rebass"
import { api } from "../../services"
import _ from "lodash"
import Papa from "papaparse"

@inject("strategyStore")
@observer
class SymbolQtyPane extends Component {
  @observable
  form = {
    symbols: [this.props.symbol]
  }
  @observable
  symbolQtyMap = { [this.props.symbol]: 10 }

  @observable
  symbolsQty = ""

  componentWillMount() {
    this.props.setQuantityHandler(this.symbolQtyMap)
  }

  parseSymbolsQtyCsv = text => {
    const parsed = Papa.parse(text, {
      skipEmptyLines: true,
      dynamicTyping: true
    }).data
    return _.fromPairs(parsed)
  }

  handleChange = key => {
    const value = key === "symbols" ? this.symbolsQty : this.symbolQtyMap[key]
    return {
      value,
      onChange: (e, { value }) => {
        if (key === "symbols") {
          // this.symbolsQty = value
        } else {
          this.symbolQtyMap = { ...this.symbolQtyMap, [key]: value }
          this.props.setQuantityHandler(this.symbolQtyMap)
        }
      },
      onPaste: e => {
        const { clipboardData } = e
        const text = clipboardData.getData("Text")
        this.symbolQtyMap = this.parseSymbolsQtyCsv(text)
        this.props.setQuantityHandler(this.symbolQtyMap)
      }
    }
  }

  renderBodyRow = (symbol, i) => {
    // const { symbolQtyMap } = this.props.strategyStore
    return {
      key: symbol || `row-${i}`,
      cells: [symbol, this.symbolQtyMap[symbol]]
    }
  }

  render() {
    return (
      <Tab.Pane>
        <Input
          fluid
          type="text"
          placeholder="Paste symbol and quantity rows here"
          {...this.handleChange("symbols")}
          action
        />
        <Table fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Symbol</Table.HeaderCell>
              <Table.HeaderCell>Qty</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(this.symbolQtyMap, (qty, symbol) => {
              return (
                <Table.Row key={`symbolqty-${symbol}`}>
                  <Table.Cell content={symbol} />
                  <Table.Cell>
                    <Form.Input fluid {...this.handleChange(symbol)} />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Tab.Pane>
    )
  }
}

@inject("strategyStore")
@observer
class NotionalPane extends Component {
  @observable
  form = {
    dollarHoldings: 14
  }

  @computed
  get notional() {
    return this.props.lastTradePrice === null
      ? ""
      : _.ceil(this.form.dollarHoldings / (this.props.lastTradePrice * 100))
  }

  componentWillMount() {
    const { symbol } = this.props
    const dollarHoldings = window.localStorage.getItem(
      `dollarHoldings:${symbol}`
    )
    if (dollarHoldings !== null) {
      this.form.dollarHoldings = dollarHoldings
    }
    // in case the user does not adjust dollarHoldings
    this.props.setQuantityHandler(this.notional)
  }

  handleChange = key => {
    const { symbol } = this.props
    return {
      value: this.form[key],
      onChange: (e, { value }) => {
        localStorage.setItem(`dollarHoldings:${symbol}`, value)
        this.form[key] = value
        this.props.setQuantityHandler(this.notional)
      }
    }
  }

  render() {
    const {
      strategyStore: { symbolQtyMap },
      lastTradePrice,
      isFetching,
      symbol
    } = this.props
    return (
      <Tab.Pane>
        {isFetching ? (
          <Loader size="mini" inline="centered" />
        ) : (
          <Text mb={2} color="grey">
            {`Last trade for ${symbol}: $${lastTradePrice}`}
          </Text>
        )}
        <Form>
          <Form.Group widths={2}>
            <Form.Input
              disabled={isFetching}
              label="Dollar Value of Holdings"
              placeholder="1"
              {...this.handleChange("dollarHoldings")}
            />
            <Form.Input
              disabled={isFetching}
              label="Notional"
              value={this.notional}
              placeholder="1"
              readOnly
            />
          </Form.Group>
        </Form>
      </Tab.Pane>
    )
  }
}

@inject("strategyStore")
@observer
export default class ScanQuantity extends Component {
  @observable
  modalOpen = false
  @observable
  symbol = ""
  @observable
  lastTradePrice = null
  @observable
  error
  @observable
  isFetching

  _quantity = null

  handleOpen = () => {
    this.fetchPrice()
    this.modalOpen = true
  }

  handleOkClose = e => {
    const { strategyStore } = this.props
    // you need to call this.setQuantityHandler on componentWillMount for each pane
    // if _quantity === null and you are on activePaneIndex 0    =>  do nothing
    // if _quantity is a Map BUT one key and key is same as symbol   =>  strategyStore.preferredScanOrderQty = _quantity
    // if _quantity is a Map    =>  strategyStore.setSymbolsQtyMap(_quantity)
    // if _quantity is an int   =>  strategyStore.preferredScanOrderQty = _quantity
    if (this._quantity === null) {
      // do nothing
    } else if (typeof this._quantity === "number") {
      console.log("we are dealing with Notional")
      strategyStore.preferredScanOrderQty = this._quantity
    } else {
      console.log(_.keys(this._quantity))
      if (
        _.keys(this._quantity).length === 1 &&
        _.keys(this._quantity)[0] === this.symbol
      ) {
        strategyStore.preferredScanOrderQty = this._quantity[this.symbol]
      } else {
        strategyStore.setSymbolsQtyMap({ ...this._quantity })
      }
      console.log("we are dealing with SymbolQty")
    }
    this.modalOpen = false
  }

  // here is the previous handleClose:
  // handleClose = () => {
  //   localStorage.setItem("usingNotional", this.form.usingNotional)
  //   localStorage.setItem("scanQty", this.form.qty)
  //   this.props.strategyStore.preferredScanOrderQty = this.form.usingNotional
  //     ? this.notional
  //     : this.form.qty
  //   this.modalOpen = false
  // }

  handleCancelClose = e => {
    this.modalOpen = false
  }

  setQuantityHandler = qty => {
    this._quantity = qty
  }

  fetchPrice = async () => {
    this.isFetching = true
    try {
      const {
        strategyStore: {
          selectedBacktest: { name }
        }
      } = this.props

      const symbol = name.split(":")[0]
      this.symbol = symbol
      const { data } = await api.fetchLastTrade(symbol)
      if (data && data[0].price) {
        this.lastTradePrice = data[0].price
      } else {
        this.error =
          "Error fetching last trade price. Unable to calculate notional"
      }
    } catch (error) {
      console.log(error)
      this.error =
        "Error fetching last trade price. Unable to calculate notional"
    }
    this.isFetching = false
  }

  render() {
    const { disabled } = this.props
    return (
      <Modal
        dimmer="inverted"
        open={this.modalOpen}
        onClose={this.handleClose}
        trigger={
          <Button
            color="green"
            disabled={disabled}
            onClick={this.handleOpen}
            content="Set Symbol / Quantity"
          />
        }>
        <Modal.Header>Set Symbol / Quantity</Modal.Header>
        <Modal.Content>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={[
              {
                menuItem: "Symbol / Qty",
                render: () => (
                  <SymbolQtyPane
                    setQuantityHandler={this.setQuantityHandler}
                    symbol={this.symbol}
                  />
                )
              },
              {
                menuItem: "Notional",
                render: () => (
                  <NotionalPane
                    lastTradePrice={this.lastTradePrice}
                    isFetching={this.isFetching}
                    symbol={this.symbol}
                    setQuantityHandler={this.setQuantityHandler}
                  />
                )
              }
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancelClose}>Cancel</Button>
          <Button color="green" onClick={this.handleOkClose}>
            <Icon name="checkmark" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
