import React from "react"
import { Route } from "react-router-dom"
import Report from "./Report"

export default props => (
  <Route
    path="/bt/:id"
    render={props => (
      <div style={{ padding: 20 }}>
        <Report {...props} />
      </div>
    )}
  />
)
