import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "mobx-react"
import Raven from "raven-js"
import App from "./components/App"
import { AppState } from "./stores/AppState"
import { StrategyStore } from "./stores/Strategy"
import userStore from "./stores/UserStore"
import DevTools from "mobx-react-devtools"
import { LicenseManager } from "ag-grid-enterprise"
// import "./sass/main.scss"

LicenseManager.setLicenseKey(
  "Option_Research_&_Technology_Services_wheel.orats.com__1Devs3_August_2019__MTU2NDc4NjgwMDAwMA==407638ee76e2da2159a6890ad63b85ce"
)
console.log(`env: ${process.env.REACT_APP_ENV}`)
const store = new AppState()
const strategyStore = new StrategyStore(userStore)

window._____APP_STATE_____ = { strategyStore }

ReactDOM.render(
  <Provider store={store} userStore={userStore} strategyStore={strategyStore}>
    <div>
      <App />
      {process.env.REACT_APP_ENV === "development" && (
        <DevTools position={{ bottom: 0, right: 20 }} />
      )}
    </div>
  </Provider>,
  document.getElementById("root")
)

window.onunhandledrejection = function(evt) {
  if (process.env.REACT_APP_ENV !== "development") {
    Raven.captureException(evt.reason)
  }
}
