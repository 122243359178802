import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { observable, computed } from "mobx"
import {
  Input,
  Modal,
  Table,
  Label,
  Icon,
  Button,
  Form,
  Divider,
  Checkbox
} from "semantic-ui-react"
import { Text, Flex, Box } from "rebass"
import Moment from "moment"
import _ from "lodash"
import ScanQuantity from "./ScanQuantity"

export const BacktestName = ({ name }) => {
  const isCombined = name.split("|").length > 1
  if (isCombined) {
    const combined = _.reduce(
      name.split("|"),
      (result, nm) => {
        const [symbol, strategyName] = nm.split(":")
        result = result.length
          ? `${result} + ${symbol}:${strategyName}`
          : `${symbol}:${strategyName}`
        return result
      },
      ""
    )
    return <Text>{combined}</Text>
  }

  let [, symbolStrat, dteSs] = name.match(
    /(^[\w+]+:\w+):(\w+:[0-9.,]+\w+:[0-9.,]+)/
  ) || [null, null, null]

  if (symbolStrat === null) {
    const [sym, strat, dte, ss] = name.split(":")
    symbolStrat = `${sym}:${strat}`
    dteSs = `${dte},${ss}`
  }

  return (
    <div>
      {<Text textAlign="left">{symbolStrat}</Text>}
      <div
        style={{
          textAlign: "left",
          fontSize: 11,
          color: "rgba(40,40,40,.3)",
          lineHeight: "11px"
        }}>
        {_.trimEnd(dteSs, ",")}
      </div>
    </div>
  )
}

@inject("userStore", "strategyStore")
@observer
export default class BacktestChooser extends Component {
  @observable
  symbolsQty = ""

  handleRowClick = (id, e) => {
    const { strategyStore } = this.props
    strategyStore.setActiveBacktest(id)
  }

  clearSymbolsQty = () => {
    const { strategyStore } = this.props
    strategyStore.clearSymbolsQtyMap()
  }

  nextPageHandler = () => {
    this.props.strategyStore.getBacktests(1)
  }

  previousPageHandler = () => {
    this.props.strategyStore.getBacktests(-1)
  }

  @computed
  get canSubmit() {
    const {
      strategyStore: { selectedBacktest }
    } = this.props
    return selectedBacktest.name && this.symbolsQty === ""
  }

  @computed
  get canEnableQty() {
    const {
      strategyStore: {
        selectedBacktest: { name }
      }
    } = this.props
    if (name === undefined) {
      return false
    }
    const symbol = name.split(":")[0]
    const isMultiSymbol = /\+/.test(symbol)
    return name && !isMultiSymbol
  }

  render() {
    const {
      userStore: { isNJ },
      strategyStore,
      open,
      handleOpen,
      handleCancel,
      handleSelectBacktest,
      triggerLabel,
      actionLabel,
      loading
    } = this.props
    const {
      backtestsForChooser: backtests,
      activeStrategyId,
      selectedBacktest,
      symbolQtyMap,
      preferredScanOrderQty,
      isBusy,
      backtestsPageNumberInfo,
      backtestsPageNumber,
      backtestsCount
    } = strategyStore

    return (
      <Modal
        open={open}
        dimmer="inverted"
        trigger={
          <Button
            size="tiny"
            primary
            loading={loading}
            onClick={handleOpen}
            content={triggerLabel}
          />
        }>
        <Modal.Header>Select a Backtest to Scan</Modal.Header>
        <Modal.Content scrolling>
          <Table basic="very" compact selectable>
            <Table.Header>
              <Table.Row>
                {_.map(
                  ["Name", "Return (Ann)", "Sharpe (Ann)", "Run Date"],
                  (t, i) => (
                    <Table.HeaderCell key={`h-${i}`} content={t} />
                  )
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(backtests, ({ name, stats, createdAt, id }, i) => {
                const { AnnReturn, AnnSharpe } = stats || {
                  AnnReturn: "",
                  AnnSharpe: ""
                }
                const active = id === activeStrategyId
                return (
                  <Table.Row
                    style={{ cursor: "pointer" }}
                    active={active}
                    key={`row-${i}`}
                    onClick={this.handleRowClick.bind(null, id)}>
                    <Table.Cell
                      style={{ userSelect: "none" }}
                      content={<BacktestName name={name} />}
                    />
                    <Table.Cell content={AnnReturn} />
                    <Table.Cell content={AnnSharpe} />
                    <Table.Cell content={Moment(createdAt).calendar()} />
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Flex alignItems="center">
            <Box ml="auto" />
            <Box mr={2}>
              <Text color={isBusy ? "gray.2" : "grey"} fontWeight="bold">{`${
                backtestsPageNumberInfo[0]
              }-${backtestsPageNumberInfo[1]} of ${backtestsCount}`}</Text>
            </Box>
            <Box>
              <div>
                <Button
                  basic
                  size="tiny"
                  icon="left chevron"
                  disabled={isBusy || backtestsPageNumber === 1}
                  onClick={this.previousPageHandler}
                />
                <Button
                  basic
                  size="tiny"
                  icon="right chevron"
                  disabled={
                    isBusy || backtestsCount === backtestsPageNumberInfo[1]
                  }
                  onClick={this.nextPageHandler}
                />
              </div>
            </Box>
          </Flex>
        </Modal.Content>
        <Modal.Actions>
          <Flex align="center">
            {selectedBacktest.name && (
              <BacktestName name={selectedBacktest.name} />
            )}
            <Box ml={5} />
            {preferredScanOrderQty && (
              <span>{`Scanner will show ${preferredScanOrderQty} trades`}</span>
            )}
            <Box ml="auto" />
            <Button content="Cancel" onClick={handleCancel} />
            <ScanQuantity disabled={!this.canEnableQty} />
            <Button
              primary
              disabled={!this.canSubmit}
              content={actionLabel}
              onClick={handleSelectBacktest}
            />
          </Flex>
        </Modal.Actions>
      </Modal>
    )
  }
}
