module.exports = {
  symbols: {
    path: "general.symbols",
    memberOf: "hidden"
  },
  weight: {
    path: "general.symbols[n].weight",
    memberOf: "hidden"
  },
  signals: {
    path: "general.symbols[n].signals",
    memberOf: "hidden"
  },
  exitAtSignal: {
    path: "general.exitAtSignal",
    control: "checkbox",
    memberOf: "hidden"
  },
  perTradeReturnType: {
    path: "general.returnType.perTrade",
    memberOf: "hidden"
  },
  dailyReturnType: {
    path: "general.returnType.daily",
    memberOf: "hidden"
  },
  optionCommission: {
    path: "general.commission.option",
    memberOf: "hidden"
  },
  stockCommission: {
    path: "general.commission.stock",
    memberOf: "hidden"
  },
  ratio: {
    field: "ratio",
    path: "entry.options[n].ratio",
    memberOf: "hidden"
  },
  optionType: {
    field: "optionType",
    path: "entry.options[n].optionType",
    memberOf: "hidden"
  },
  leg: {
    field: "leg",
    path: "entry.options[n].leg",
    memberOf: "hidden"
  },
  backtestName: {
    field: "backtestName",
    path: "general.backtestName",
    memberOf: "hidden"
  },
  symbol: {
    field: "symbol",
    group: "symbol",
    path: "general.symbols[n].symbol",
    memberOf: "General",
    constraints: {
      presence: { allowEmpty: false },
      validSymbol: true
    },
    isBasic: true
  },
  strategyName: {
    field: "strategyName",
    group: "strategy",
    control: "select",
    path: "general.strategyName",
    memberOf: "General",
    isBasic: true
  },
  // signalEntryDate: {
  //   field: "signalEntryDate",
  //   group: "symEntryExit",
  //   path: "general.symbols.signals.entryDate",
  //   memberOf: "Stock"
  // },
  // signalExitDate: {
  //   field: "signalExitDate",
  //   group: "symEntryExit",
  //   path: "general.symbols.signals.exitDate",
  //   memberOf: "Stock"
  // },
  startDate: {
    field: "startDate",
    group: "dateRange",
    path: "general.startDate",
    memberOf: "General",
    constraints: {
      presence: { allowEmpty: false },
      dates: { range: true, before: "endDate" }
    }
  },
  endDate: {
    field: "endDate",
    group: "dateRange",
    path: "general.endDate",
    memberOf: "General",
    constraints: {
      presence: { allowEmpty: false },
      dates: { range: true, after: "startDate" }
    }
  },
  standardExpiration: {
    field: "standardExpiration",
    group: "standardExpiration",
    path: "general.standardExpiration",
    control: "checkbox",
    memberOf: "General"
  },
  stockPositionType: {
    field: "stockPositionType",
    group: "stockPosition",
    path: "general.stockPosition.type",
    memberOf: "General",
    control: "select"
  },
  stockPositionRatio: {
    field: "stockPositionRatio",
    group: "stockPosition",
    path: "general.stockPosition.ratio",
    memberOf: "General",
    control: "select"
  },
  dte: {
    field: "dte",
    label: "DTE",
    group: "dte",
    path: "entry.options[n].opening.dte.target",
    memberOf: "Options",
    isBasic: true,
    constraints: {
      presence: { allowEmpty: false },
      numeric: true
    }
  },
  dteMin: {
    field: "dteMin",
    label: "DTE Min",
    group: "dte",
    path: "entry.options[n].opening.dte.min",
    memberOf: "Options",
    isBasic: true,
    constraints: { numeric: { lessThan: "dteMax" } }
  },
  dteMax: {
    field: "dteMax",
    label: "DTE Max",
    group: "dte",
    path: "entry.options[n].opening.dte.max",
    memberOf: "Options",
    isBasic: true,
    constraints: { numeric: { greaterThan: "dteMin" } }
  },

  strikeSelectionType: {
    field: "strikeSelectionType",
    group: "strikeSelection",
    path: "entry.options[n].opening.strikeSelection.type",
    memberOf: "Options",
    control: "select",
    isBasic: true
  },

  strikeSelectionTarget: {
    field: "strikeSelectionTarget",
    group: "strikeSelection",
    path: "entry.options[n].opening.strikeSelection.value.target",
    memberOf: "Options",
    isBasic: true
  },

  strikeSelectionMin: {
    field: "strikeSelectionMin",
    group: "strikeSelection",
    path: "entry.options[n].opening.strikeSelection.value.min",
    memberOf: "Options",
    isBasic: true
  },

  strikeSelectionMax: {
    field: "strikeSelectionMax",
    group: "strikeSelection",
    path: "entry.options[n].opening.strikeSelection.value.max",
    memberOf: "Options",
    isBasic: true
  },
  reEnterDte: {
    field: "reEnterDte",
    label: "Re-Enter DTE",
    group: "reEnter",
    path: "entry.options[n].reEnter.dte.target",
    memberOf: "Options",
    constraints: {
      numeric: true
    }
  },
  reEnterDteMin: {
    field: "reEnterDteMin",
    label: "Re-Enter DTE Min",
    group: "reEnter",
    path: "entry.options[n].reEnter.dte.min",
    memberOf: "Options",
    constraints: { numeric: { lessThan: "reEnterDteMax" } }
  },
  reEnterDteMax: {
    field: "reEnterDteMax",
    label: "Re-Enter DTE Max",
    group: "reEnter",
    path: "entry.options[n].reEnter.dte.max",
    memberOf: "Options",
    constraints: { numeric: { greaterThan: "reEnterDteMin" } }
  },

  reEnterStrikeSelectionType: {
    field: "reEnterStrikeSelectionType",
    label: "Re-Enter Strike Selection Type",
    group: "reEnter",
    path: "entry.options[n].reEnter.strikeSelection.type",
    memberOf: "Options",
    control: "select"
  },

  reEnterStrikeSelectionTarget: {
    field: "reEnterStrikeSelectionTarget",
    label: "Re-Enter Strike Selection Target",
    group: "reEnter",
    path: "entry.options[n].reEnter.strikeSelection.value.target",
    memberOf: "Options"
  },

  reEnterStrikeSelectionMin: {
    field: "reEnterStrikeSelectionMin",
    label: "Re-Enter Strike Selection Min",
    group: "reEnter",
    path: "entry.options[n].reEnter.strikeSelection.value.min",
    memberOf: "Options"
  },

  reEnterStrikeSelectionMax: {
    field: "reEnterStrikeSelectionMax",
    label: "Re-Enter Strike Selection Max",
    group: "reEnter",
    path: "entry.options[n].reEnter.strikeSelection.value.max",
    memberOf: "Options"
  },
  spreadPxTarget: {
    field: "spreadPxTarget",
    group: "spreadPx",
    path: "entry.spread.price.target",
    memberOf: "Spreads",
    constraints: { numeric: true }
  },
  spreadPxMin: {
    field: "spreadPxMin",
    group: "spreadPx",
    path: "entry.spread.price.min",
    memberOf: "Spreads",
    constraints: { numeric: { lessThan: "spreadPxMax" } }
  },
  spreadPxMax: {
    field: "spreadPxMax",
    group: "spreadPx",
    path: "entry.spread.price.max",
    memberOf: "Spreads",
    constraints: { numeric: { greaterThan: "spreadPxMin" } }
  },
  spreadDeltaTarget: {
    field: "spreadDeltaTarget",
    group: "spreadDelta",
    path: "entry.spread.delta.target",
    memberOf: "Spreads",
    constraints: {
      numeric: true
    }
  },
  spreadDeltaMin: {
    field: "spreadDeltaMin",
    group: "spreadDelta",
    path: "entry.spread.delta.min",
    memberOf: "Spreads",
    constraints: { numeric: { lessThan: "spreadDeltaMax" } }
  },
  spreadDeltaMax: {
    field: "spreadDeltaMax",
    group: "spreadDelta",
    path: "entry.spread.delta.max",
    memberOf: "Spreads",
    constraints: { numeric: { greaterThan: "spreadDeltaMin" } }
  },
  spreadYieldPctMin: {
    field: "spreadYieldPctMin",
    group: "spreadYieldPct",
    path: "entry.spread.yieldPct.min",
    memberOf: "Spreads",
    constraints: { numeric: { lessThan: "spreadYieldPctMax" } }
  },
  spreadYieldPctMax: {
    field: "spreadYieldPctMax",
    group: "spreadYieldPct",
    path: "entry.spread.yieldPct.max",
    memberOf: "Spreads",
    constraints: { numeric: { greaterThan: "spreadYieldPctMin" } }
  },
  exitDTEDays: {
    field: "exitDTEDays",
    group: "exitDTEDays",
    path: "exit.dteDays",
    memberOf: "Exits",
    constraints: {
      numeric: { allowText: "expire" }
    }
  },
  exitHoldDays: {
    field: "exitHoldDays",
    group: "exitHoldDays",
    path: "exit.holdDays",
    memberOf: "Exits",
    constraints: { numeric: true }
  },

  exitTriggerLeg: {
    field: "exitTriggerLeg",
    group: "exitTrigger",
    path: "exit.options[n].leg",
    memberOf: "Exits",
    control: "select"
  },
  exitTriggerType: {
    field: "exitTriggerType",
    group: "exitTrigger",
    path: "exit.options[n].trigger.type",
    memberOf: "Exits",
    control: "select"
  },
  exitTriggerMin: {
    field: "exitTriggerMin",
    group: "exitTrigger",
    path: "exit.options[n].trigger.value.min",
    memberOf: "Exits",
    constraints: { numeric: { lessThan: "exitTriggerMax" } }
  },
  exitTriggerMax: {
    field: "exitTriggerMax",
    group: "exitTrigger",
    path: "exit.options[n].trigger.value.max",
    memberOf: "Exits",
    constraints: { numeric: { greaterThan: "exitTriggerMin" } }
  },

  exitProfitLossPctMin: {
    field: "exitProfitLossPctMin",
    group: "exitProfitLossPct",
    placeholder: 50,
    path: "exit.spread.profitLossPct.min",
    memberOf: "Exits",
    constraints: {
      numeric: { lessThan: "exitProfitLossPctMax", range: [-500, 500] }
    }
  },
  exitProfitLossPctMax: {
    field: "exitProfitLossPctMax",
    group: "exitProfitLossPct",
    placeholder: 50,
    path: "exit.spread.profitLossPct.max",
    memberOf: "Exits",
    constraints: {
      numeric: { greaterThan: "exitProfitLossPctMin", range: [0, 500] }
    }
  },
  exitSpreadTriggerType: {
    field: "exitSpreadTriggerType",
    group: "exitSpreadTrigger",
    path: "exit.spread.strikeTrigger.type",
    memberOf: "Exits",
    control: "select"
  },

  exitSpreadTriggerMin: {
    field: "exitSpreadTriggerMin",
    group: "exitSpreadTrigger",
    path: "exit.spread.strikeTrigger.value.min",
    memberOf: "Exits",
    constraints: { numeric: { lessThan: "exitSpreadTriggerMax" } }
  },
  exitSpreadTriggerMax: {
    field: "exitSpreadTriggerMax",
    group: "exitSpreadTrigger",
    path: "exit.spread.strikeTrigger.value.max",
    memberOf: "Exits",
    constraints: { numeric: { greaterThan: "exitSpreadTriggerMin" } }
  },
  exitSpreadPxMin: {
    field: "exitSpreadPxMin",
    group: "exitSpreadPx",
    path: "exit.spread.price.min",
    memberOf: "Exits",
    constraints: { numeric: { lessThan: "exitSpreadPxMax" } }
  },
  exitSpreadPxMax: {
    field: "exitSpreadPxMax",
    group: "exitSpreadPx",
    path: "exit.spread.price.max",
    memberOf: "Exits",
    constraints: { numeric: { greaterThan: "exitSpreadPxMin" } }
  },
  exitStrikeDiffPctValueMin: {
    field: "exitStrikeDiffPctValueMin",
    group: "exitStrikeDiffPctValue",
    path: "exit.spread.strikeDiffPctValue.min",
    memberOf: "Exits",
    constraints: { numeric: { lessThan: "exitStrikeDiffPctValueMax" } }
  },
  exitStrikeDiffPctValueMax: {
    field: "exitStrikeDiffPctValueMax",
    group: "exitStrikeDiffPctValue",
    path: "exit.spread.strikeDiffPctValue.max",
    memberOf: "Exits",
    constraints: { numeric: { greaterThan: "exitStrikeDiffPctValueMin" } }
  },
  hedgeDays: {
    field: "hedgeDays",
    group: "hedgeDays",
    path: "hedge.days",
    memberOf: "Hedge",
    constraints: { numeric: true }
  },
  hedgeToleranceMin: {
    field: "hedgeToleranceMin",
    group: "hedgeTolerance",
    path: "hedge.deltaTolerance.min",
    memberOf: "Hedge",
    constraints: { numeric: true }
  },
  hedgeToleranceMax: {
    field: "hedgeToleranceMax",
    group: "hedgeTolerance",
    path: "hedge.deltaTolerance.max",
    memberOf: "Hedge",
    constraints: { numeric: true }
  },
  entryDays: {
    field: "entryDays",
    group: "entryDays",
    path: "entry.entryDays",
    memberOf: "Entry",
    constraints: { numeric: true }
  },
  contractSize: {
    field: "contractSize",
    group: "contractSize",
    path: "entry.contractSize",
    memberOf: "Entry",
    constraints: {
      presence: { allowEmpty: false },
      numeric: true
    }
  },
  leg1Leg2DeltaTotalMin: {
    field: "leg1Leg2DeltaTotalMin",
    group: "leg1Leg2DeltaTotal",
    path: "entry.legRelation.deltaTotal.leg1Leg2.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg1Leg2DeltaTotalMax" } }
  },
  leg1Leg2DeltaTotalMax: {
    field: "leg1Leg2DeltaTotalMax",
    group: "leg1Leg2DeltaTotal",
    path: "entry.legRelation.deltaTotal.leg1Leg2.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg1Leg2DeltaTotalMin" } }
  },
  leg2Leg3DeltaTotalMin: {
    field: "leg2Leg3DeltaTotalMin",
    group: "leg2Leg3DeltaTotal",
    path: "entry.legRelation.deltaTotal.leg2Leg3.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg2Leg3DeltaTotalMax" } }
  },
  leg2Leg3DeltaTotalMax: {
    field: "leg2Leg3DeltaTotalMax",
    group: "leg2Leg3DeltaTotal",
    path: "entry.legRelation.deltaTotal.leg2Leg3.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg2Leg3DeltaTotalMin" } }
  },
  leg3Leg4DeltaTotalMin: {
    field: "leg3Leg4DeltaTotalMin",
    group: "leg3Leg4DeltaTotal",
    path: "entry.legRelation.deltaTotal.leg3Leg4.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg3Leg4DeltaTotalMax" } }
  },
  leg3Leg4DeltaTotalMax: {
    field: "leg3Leg4DeltaTotalMax",
    group: "leg3Leg4DeltaTotal",
    path: "entry.legRelation.deltaTotal.leg3Leg4.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg3Leg4DeltaTotalMin" } }
  },
  leg1Leg2DteDiffMin: {
    field: "leg1Leg2DteDiffMin",
    group: "leg1Leg2DteDiff",
    path: "entry.legRelation.dteDiff.leg1Leg2.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg1Leg2DteDiffMax" } }
  },
  leg1Leg2DteDiffMax: {
    field: "leg1Leg2DteDiffMax",
    group: "leg1Leg2DteDiff",
    path: "entry.legRelation.dteDiff.leg1Leg2.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg1Leg2DteDiffMin" } }
  },
  leg2Leg3DteDiffMin: {
    field: "leg2Leg3DteDiffMin",
    group: "leg2Leg3DteDiff",
    path: "entry.legRelation.dteDiff.leg2Leg3.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg2Leg3DteDiffMax" } }
  },
  leg2Leg3DteDiffMax: {
    field: "leg2Leg3DteDiffMax",
    group: "leg2Leg3DteDiff",
    path: "entry.legRelation.dteDiff.leg2Leg3.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg2Leg3DteDiffMin" } }
  },
  leg3Leg4DteDiffMin: {
    field: "leg3Leg4DteDiffMin",
    group: "leg3Leg4DteDiff",
    path: "entry.legRelation.dteDiff.leg3Leg4.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg3Leg4DteDiffMax" } }
  },
  leg3Leg4DteDiffMax: {
    field: "leg3Leg4DteDiffMax",
    group: "leg3Leg4DteDiff",
    path: "entry.legRelation.dteDiff.leg3Leg4.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg3Leg4DteDiffMin" } }
  },
  leg1Leg2StrikeWidthMin: {
    field: "leg1Leg2StrikeWidthMin",
    group: "leg1Leg2StrikeWidth",
    path: "entry.legRelation.strikeWidth.leg1Leg2.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg1Leg2StrikeWidthMax" } }
  },
  leg1Leg2StrikeWidthMax: {
    field: "leg1Leg2StrikeWidthMax",
    group: "leg1Leg2StrikeWidth",
    path: "entry.legRelation.strikeWidth.leg1Leg2.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg1Leg2StrikeWidthMin" } }
  },
  leg2Leg3StrikeWidthMin: {
    field: "leg2Leg3StrikeWidthMin",
    group: "leg2Leg3StrikeWidth",
    path: "entry.legRelation.strikeWidth.leg2Leg3.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg2Leg3StrikeWidthMax" } }
  },
  leg2Leg3StrikeWidthMax: {
    field: "leg2Leg3StrikeWidthMax",
    group: "leg2Leg3StrikeWidth",
    path: "entry.legRelation.strikeWidth.leg2Leg3.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg2Leg3StrikeWidthMin" } }
  },
  leg3Leg4StrikeWidthMin: {
    field: "leg3Leg4StrikeWidthMin",
    group: "leg3Leg4StrikeWidth",
    path: "entry.legRelation.strikeWidth.leg3Leg4.min",
    memberOf: "Relation",
    constraints: { numeric: { lessThan: "leg3Leg4StrikeWidthMax" } }
  },
  leg3Leg4StrikeWidthMax: {
    field: "leg3Leg4StrikeWidthMax",
    group: "leg3Leg4StrikeWidth",
    path: "entry.legRelation.strikeWidth.leg3Leg4.max",
    memberOf: "Relation",
    constraints: { numeric: { greaterThan: "leg3Leg4StrikeWidthMin" } }
  },
  mktWidthPctMin: {
    field: "mktWidthPctMin",
    group: "mktWidthPct",
    path: "entry.mktWidthPct.min",
    memberOf: "Entry",
    constraints: { numeric: { lessThan: "mktWidthPctMax" } }
  },
  mktWidthPctMax: {
    field: "mktWidthPctMax",
    group: "mktWidthPct",
    path: "entry.mktWidthPct.max",
    memberOf: "Entry",
    constraints: { numeric: { greaterThan: "mktWidthPctMin" } }
  },
  absCpDiffStkPxRatioMax: {
    field: "absCpDiffStkPxRatioMax",
    group: "absCpDiffStkPxRatioMax",
    path: "entry.absCpDiffStkPxRatioMax",
    memberOf: "Entry",
    constraints: { numeric: true }
  },
  iVRankMin: {
    field: "iVRankMin",
    group: "iVRank",
    path: "entry.stock.iVRank.min",
    memberOf: "Stock",
    constraints: { numeric: { lessThan: "iVRankMax" } }
  },
  iVRankMax: {
    field: "iVRankMax",
    group: "iVRank",
    path: "entry.stock.iVRank.max",
    memberOf: "Stock",
    constraints: { numeric: { greaterThan: "iVRankMin" } }
  },
  entryDaysToEarnMin: {
    field: "entryDaysToEarnMin",
    group: "entryDaysToEarn",
    path: "entry.stock.entryDaysToEarn.min",
    memberOf: "Stock",
    constraints: { numeric: { lessThan: "entryDaysToEarnMax" } }
  },
  entryDaysToEarnMax: {
    field: "entryDaysToEarnMax",
    group: "entryDaysToEarn",
    path: "entry.stock.entryDaysToEarn.max",
    memberOf: "Stock",
    constraints: { numeric: { greaterThan: "entryDaysToEarnMin" } }
  },
  exitBizDaysEarnType: {
    field: "exitBizDaysEarnType",
    group: "exitBizDaysEarn",
    path: "exit.bizDaysEarn.type",
    memberOf: "Exits",
    control: "select"
  },
  exitBizDaysEarnDays: {
    field: "exitBizDaysEarnDays",
    group: "exitBizDaysEarn",
    path: "exit.bizDaysEarn.days",
    memberOf: "Exits"
  },
  iBidVolMin: {
    field: "iBidVolMin",
    group: "iBidVol",
    path: "entry.options[n].iBidVol.min",
    memberOf: "Stock",
    constraints: { numeric: { lessThan: "iBidVolMax", range: [3, 500] } }
  },
  iBidVolMax: {
    field: "iBidVolMax",
    group: "iBidVol",
    path: "entry.options[n].iBidVol.max",
    memberOf: "Stock",
    constraints: { numeric: { greaterThan: "iBidVolMin", range: [3, 500] } }
  },
  iAskVolMin: {
    field: "iAskVolMin",
    group: "iAskVol",
    path: "entry.options[n].iAskVol.min",
    memberOf: "Stock",
    constraints: { numeric: { lessThan: "iAskVolMax", range: [3, 500] } }
  },
  iAskVolMax: {
    field: "iAskVolMax",
    group: "iAskVol",
    path: "entry.options[n].iAskVol.max",
    memberOf: "Stock",
    constraints: { numeric: { greaterThan: "iAskVolMin", range: [3, 500] } }
  },
  optionBidMin: {
    field: "optionBidMin",
    group: "optionBid",
    path: "entry.options[n].optionBid.min",
    memberOf: "Stock",
    constraints: { numeric: { lessThan: "optionBidMax" } }
  },
  optionBidMax: {
    field: "optionBidMax",
    group: "optionBid",
    path: "entry.options[n].optionBid.max",
    memberOf: "Stock",
    constraints: { numeric: { greaterThan: "optionBidMin" } }
  },
  optionAskMin: {
    field: "optionAskMin",
    group: "optionAsk",
    path: "entry.options[n].optionAsk.min",
    memberOf: "Stock",
    constraints: { numeric: { lessThan: "optionAskMax" } }
  },
  optionAskMax: {
    field: "optionAskMax",
    group: "optionAsk",
    path: "entry.options[n].optionAsk.max",
    memberOf: "Stock",
    constraints: { numeric: { greaterThan: "optionAskMin" } }
  },
  adjustmentTriggerType: {
    field: "adjustmentTriggerType",
    group: "adjustmentTrigger",
    path: "entry.options[n].adjustment.trigger.type",
    memberOf: "Options",
    control: "select"
  },
  adjustmentTriggerMin: {
    field: "adjustmentTriggerMin",
    group: "adjustmentTrigger",
    path: "entry.options[n].adjustment.trigger.value.min",
    memberOf: "Options",
    constraints: {
      numeric: { lessThan: "adjustmentTriggerMax", range: [0, 1] }
    }
  },
  adjustmentTriggerMax: {
    field: "adjustmentTriggerMax",
    group: "adjustmentTrigger",
    path: "entry.options[n].adjustment.trigger.value.max",
    memberOf: "Options",
    constraints: {
      numeric: { greaterThan: "adjustmentTriggerMin", range: [0, 1] }
    }
  },
  tiedToLegNum: {
    field: "tiedToLegNum",
    group: "adjustmentTrigger",
    path: "entry.options[n].adjustment.trigger.tiedTo.leg",
    memberOf: "Options",
    control: "select",
    constraints: { numeric: { withinLegRange: true } }
  },
  tiedDeltaMin: {
    field: "tiedDeltaMin",
    group: "adjustmentTrigger",
    path: "entry.options[n].adjustment.trigger.tiedTo.min",
    memberOf: "Options",
    constraints: { numeric: { lessThan: "tiedDeltaMax", range: [0, 1] } }
  },
  tiedDeltaMax: {
    field: "tiedDeltaMax",
    group: "adjustmentTrigger",
    path: "entry.options[n].adjustment.trigger.tiedTo.max",
    memberOf: "Options",
    constraints: { numeric: { greaterThan: "tiedDeltaMin", range: [0, 1] } }
  },
  adjustmentStrikeSelectionType: {
    field: "adjustmentStrikeSelectionType",
    group: "adjustmentStrikeSelection",
    path: "entry.options[n].adjustment.strikeSelection.type",
    memberOf: "Options",
    control: "select"
  },
  adjustmentStrikeSelectionTarget: {
    field: "adjustmentStrikeSelectionTarget",
    group: "adjustmentStrikeSelection",
    path: "entry.options[n].adjustment.strikeSelection.value.target",
    memberOf: "Options",
    constraints: { numeric: { range: [0, 1] } }
  },
  adjustmentStrikeSelectionMin: {
    field: "adjustmentStrikeSelectionMin",
    group: "adjustmentStrikeSelection",
    path: "entry.options[n].adjustment.strikeSelection.value.min",
    memberOf: "Options",
    constraints: {
      numeric: { lessThan: "adjustmentStrikeSelectionMax", range: [0, 1] }
    }
  },
  adjustmentStrikeSelectionMax: {
    field: "adjustmentStrikeSelectionMax",
    group: "adjustmentStrikeSelection",
    path: "entry.options[n].adjustment.strikeSelection.value.max",
    memberOf: "Options",
    constraints: {
      numeric: { greaterThan: "adjustmentStrikeSelectionMin", range: [0, 1] }
    }
  },
  adjustmentDteTarget: {
    field: "adjustmentDteTarget",
    group: "adjustmentDte",
    path: "entry.options[n].adjustment.dte.target",
    memberOf: "Options",
    constraints: { numeric: { range: [0, 1] } }
  },
  adjustmentDteMin: {
    field: "adjustmentDteMin",
    group: "adjustmentDte",
    path: "entry.options[n].adjustment.dte.min",
    memberOf: "Options",
    constraints: {
      numeric: { lessThan: "adjustmentStrikeSelectionMax", range: [0, 1] }
    }
  },
  adjustmentDteMax: {
    field: "adjustmentDteMax",
    group: "adjustmentDte",
    path: "entry.options[n].adjustment.dte.max",
    memberOf: "Options",
    constraints: {
      numeric: { greaterThan: "adjustmentStrikeSelectionMin", range: [0, 1] }
    }
  },
  minDaysForAdjusting: {
    field: "minDaysForAdjusting",
    group: "adjustMinMaxDays",
    path: "entry.options[n].adjustment.daysForAdjusting.min",
    memberOf: "Options",
    constraints: { numeric: { range: [1, 1000] } }
  },
  maxDaysForAdjusting: {
    field: "maxDaysForAdjusting",
    group: "adjustMinMaxDays",
    path: "entry.options[n].adjustment.daysForAdjusting.max",
    memberOf: "Options",
    constraints: { numeric: { range: [1, 1000] } }
  },
  rollWithLeg: {
    field: "rollWithLeg",
    group: "adjustWithLegNumber",
    path: "entry.options[n].adjustment.rollWithLeg",
    memberOf: "Options",
    control: "select",
    constraints: { numeric: { withinLegRange: true } }
  }
}
