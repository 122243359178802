import history from "../../history"
import auth0 from "auth0-js"
import { Auth0LockPasswordless } from "auth0-lock"
import { AUTH_CONFIG } from "../../config"
import Moment from "moment"

// const  = {
//   domain: "orats.auth0.com",
//   clientId: "QjoZEemFq-wvMa2QyGFtX4yy_x-fjrW7",
//   redirectUri: "http://app.local:3000/callback"
// }

export default class Authenticator {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId
  })

  lock = new Auth0LockPasswordless(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
    passwordlessMethod: "link",
    rememberLastLogin: false,
    closable: false,
    languageDictionary: {
      title: process.env.REACT_APP_ENV === "production" ? "ORATS" : ""
    },
    theme: {
      logo:
        "http://assets.orats.com.s3-website-us-east-1.amazonaws.com/logo5.svg",
      primaryColor: "#FD8224",
      labeledSubmitButton: false
    },
    auth: {
      redirectUrl: AUTH_CONFIG.redirectUri,
      responseType: "token id_token",
      params: {
        scope: "openid email"
      }
    }
  })

  login = () => {
    this.lock.show()
  }

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.idToken) {
        this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
          if (err) {
            history.replace("/auth")
            console.log(err)
          }
          const { idToken, expiresIn } = authResult
          this.setSession({ email: user.email, idToken, expiresIn })
          history.replace("/scan")
        })
      } else if (err) {
        history.replace("/auth")
        console.log(err)
        alert(`Error: ${err.error}. Check the console for further details.`)
      }
    })
  }

  setSession = ({ idToken, email, expiresIn }) => {
    let expiresAt = JSON.stringify(
      Moment()
        .add(expiresIn, "s")
        .valueOf()
    )
    // const { idToken, email } = authResult
    localStorage.setItem("id_token", idToken)
    localStorage.setItem("expires_at", expiresAt)
    localStorage.setItem("email", email)
  }

  logout = () => {
    // Clear access token and ID token from local storage
    this.auth0.logout()
    localStorage.removeItem("id_token")
    localStorage.removeItem("expires_at")
    localStorage.removeItem("email")
    history.replace("/auth")
  }

  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"))
    // OR
    // Moment().isBefore(Moment(expiresAt))
    return new Date().getTime() < expiresAt
  }
}
