import React from "react"
import { Route, Switch } from "react-router-dom"
import TradeFinder from "./TradeFinder"
import { Box } from "rebass"

export default props => (
  <Box p={3}>
    <Switch>
      <Route path="/scan" render={props => <TradeFinder {...props} />} />
    </Switch>
  </Box>
)
