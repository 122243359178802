import React from "react"
import { Text, Flex, Box } from "rebass"
import styled from "styled-components"
import numeral from "numeral"
import _ from "lodash"
import { Popup, Table, List } from "semantic-ui-react"

const PATHS = [
  "dteDays",
  "holdDays",
  "bizDaysEarn",
  "options",
  "spread.price",
  "spread.profitLossPct",
  "spread.strikeTrigger.value",
  "spread.strikeDiffPctValue"
]
const exitParamsUsed = exitParams => {
  return _.reduce(
    PATHS,
    (result, path) => {
      if (_.startsWith(path, "spread")) {
        const { min, max } = _.get(exitParams, path)
        if (min || max) {
          result.push(path.replace(/\.value/, ""))
        }
      } else {
        const val = _.get(exitParams, path)
        if (val) {
          result.push(path)
        }
      }
      return result
    },
    []
  )
}
const ExitParamContent = ({ path, content }) => {
  if (!_.isObject(content) && !_.isArray(content)) {
    return <Text children={content} />
  } else if (_.isArray(content)) {
    return (
      <Box
        children={_.map(content, (leg, i) => {
          return (
            <Text
              key={`l-${i}`}
              children={_.map(
                [
                  "leg",
                  "trigger.type",
                  "trigger.value.min",
                  "trigger.value.max"
                ],
                path => _.get(leg, path) || null
              ).join(", ")}
            />
          )
        })}
      />
    )
  } else {
    if (path === "options") {
      const items = _.map(
        content,
        ({
          leg,
          trigger: {
            type,
            value: { min, max }
          }
        }) => `leg${leg}, ${type}, ${min}, ${max}`
      )
      return <List items={items} />
    } else {
      return (
        <Text
          children={_.reduce(
            content,
            (result, value, key) => {
              if (_.isObject(value)) {
                result = _.concat(result, _.values(value))
              } else {
                result.push(value)
              }
              return result
            },
            []
          ).join(", ")}
        />
      )
    }
  }
}

export const ExitParams = ({ params }) => {
  return (
    <div>
      {_.map(exitParamsUsed(params), (path, i) => {
        return (
          <Popup
            key={`ep-${i}`}
            position="left center"
            trigger={
              <span
                style={{
                  cursor: "pointer",
                  marginRight: 4,
                  textDecoration: "underline"
                }}
                key={`exit-p-${i}`}>
                {path}
              </span>
            }
            content={
              <ExitParamContent path={path} content={_.get(params, path)} />
            }
          />
        )
      })}
    </div>
  )
}

const TradeRow = styled(Flex)`
  &:not(:last-child) {
    padding-bottom: 4px;
    border-bottom: solid rgba(34, 36, 38, 0.1) 1px;
  }
  &:last-child {
    padding-top: 4px;
  }
  &:only-child {
    padding-top: 0px;
  }
`

export const Trade = ({ legs }) => {
  return (
    <Box style={{ display: "inline-block" }}>
      {_.map(
        legs,
        ({ ratio, ticker, exp, strike, optionType, tradeOptPx }, i) => {
          return (
            <TradeRow key={`leg-${i}`}>
              <Box width={42}>
                <Text children={ratio > 0 ? "BUY" : "SELL"} />
              </Box>
              <Box width={55}>
                <Text color="gray.6" children={exp} />
              </Box>
              <Box width={45}>
                <Text children={strike} />
              </Box>
              <Box width={45}>
                <Text color="gray.6" children={optionType.toUpperCase()} />
              </Box>
              <Text children={numeral(tradeOptPx).format("$0.00")} />
            </TradeRow>
          )
        }
      )}
    </Box>
  )
}

export const Row = styled(Table.Row)`
  cursor: pointer;
`
export const ActionContent = styled("span")`
  display: none;
  color: #329ad6;
  margin-right: 4px;
  ${Row}:hover & {
    display: inline-block;
  }
`
