import fetch from "isomorphic-fetch"
import axios from "axios"
import _ from "lodash"
import AWSAppSyncClient from "aws-appsync"

import util from "../util"
import {
  GET_USER_BACKTESTS,
  GET_USER_BACKTESTS_COUNT,
  GET_BACKTEST,
  CREATE_BACKTEST,
  UPDATE_BACKTEST,
  UPDATE_BACKTEST_SUBSCRIPTION,
  GET_COMBINATION,
  GET_BACKTEST_NAME,
  GET_BACKTEST_STATS,
  GET_BACKTEST_TRADES,
  GET_BACKTEST_REPORT,
  GET_BACKTEST_FORM,
  GET_BACKTEST_DATA_ZIP,
  CREATE_HOLDING,
  GET_HOLDINGS,
  UPDATE_HOLDING,
  GET_USER,
  GET_USER_BY_EMAIL,
  CREATE_USER,
  UPDATE_USER,
  GET_GLOBALS,
  CREATE_STRIPE_CUSTOMER,
  PROCESS_SUBSCRIPTION,
} from "../queries"

import { SCANNER_PARAMS } from "../constants"
import { backtestTemplate } from "../util/templates"
import {
  OPRA_API_ROOT,
  WHEEL_API_ROOT,
  ORATS_TOKEN,
  WHEEL_TOKEN,
  ORATS_BT_ENDPOINT,
  SR_API_ROOT,
  APPSYNC_ENDPOINT,
  ORATS_IO_ENDPOINT,
  ORATS_IO_TOKEN,
} from "../config"

const JSON_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

const config = {
  url: APPSYNC_ENDPOINT,
  region: "us-east-1",
  auth: {
    type: "OPENID_CONNECT",
    jwtToken: async () => {
      const token = window.localStorage.getItem("id_token")
      return Promise.resolve(token)
    },
  },
  disableOffline: true,
}

const SCAN_DEF_PAYLOAD = {
  backtest: {
    general: {
      startDate: "2007-01-03",
      endDate: "2019-06-18",
      symbols: [],
    },
    entry: {
      options: [],
      spread: {
        price: {
          target: null,
          min: null,
          max: null,
        },
        delta: {
          target: null,
          min: null,
          max: null,
        },
        yieldPct: {
          target: null,
          min: null,
          max: null,
        },
        strikeDiffPctValue: {
          target: null,
          min: null,
          max: null,
        },
      },
    },
  },
  totalTrades: 10,
  tradesPerSymbol: 10,
  dataSource: "delayed",
}

const client = new AWSAppSyncClient(config)

// APP
export const loadGlobals = async (email, token) => {
  return client.query({ query: GET_GLOBALS })
}

export const getCurrentUser = (userId) => {
  return client.query({ query: GET_USER, variables: { id: userId } })
}

export const getUserByEmail = (email) => {
  return client.query({ query: GET_USER_BY_EMAIL, variables: { email } })
}

export const updateUser = ({ id, stripeCustomerId, plan }) => {
  return client.mutate({
    mutation: UPDATE_USER,
    variables: { id, stripeCustomerId, plan },
  })
}

export const createUser = (input) => {
  return client.mutate({
    mutation: CREATE_USER,
    variables: input,
  })
}

// STRIPE
export const createStripeCustomer = async (email, token) => {
  return client.mutate({
    mutation: CREATE_STRIPE_CUSTOMER,
    variables: { input: { email, token } },
  })
}

export const processSubscription = async (userId, stripeCustomerId, plan) => {
  return client.mutate({
    mutation: PROCESS_SUBSCRIPTION,
    variables: { input: { userId, stripeCustomerId, plan } },
  })
}

// BT
export const getBacktest = async ({ id, ownerId }) => {
  let {
    data: { getBacktest },
  } = await client.query({ query: GET_BACKTEST, variables: { id } })

  // let {
  //   data: { getBacktest }
  // } = await API.graphql(graphqlOperation(GET_BACKTEST, { id }))

  getBacktest = { ...getBacktest, form: JSON.parse(getBacktest.form) }
  return Promise.resolve({ data: { getBacktest } })
}

export const getBacktests = ({ ownerId, count, nextToken }) => {
  return client.query({
    query: GET_USER_BACKTESTS,
    variables: { ownerId, count, nextToken },
    fetchPolicy: "no-cache",
  })
}

export const getBacktestsCount = ({ ownerId, status_not }) => {
  return client.query({
    query: GET_USER_BACKTESTS_COUNT,
    variables: { ownerId, status_not },
  })
}

export const getBacktestForm = async (id, format) => {
  let {
    data: {
      getBacktestForm: { form },
    },
  } = await client.query({
    query: GET_BACKTEST_FORM,
    variables: { id, format },
  })
  form = JSON.parse(form)
  return Promise.resolve(form)
}

export const getCombination = ({ id }) => {
  return client.query({
    query: GET_COMBINATION,
    variables: { id },
  })
}

export const getBacktestName = ({ id, ownerId }) => {
  return client.query({
    query: GET_BACKTEST_NAME,
    variables: { id, ownerId },
  })
}

export const getBacktestStats = ({ id, ownerId }) => {
  return client.query({
    query: GET_BACKTEST_STATS,
    variables: { id, ownerId },
  })
}

export const createBacktestRecord = (input) => {
  return client.mutate({
    mutation: CREATE_BACKTEST,
    variables: input,
  })
}

export const fetchBacktestResult = async (id) => {
  try {
    const {
      data: { getBacktestReport },
    } = await client.query({
      query: GET_BACKTEST_REPORT,
      variables: { id },
    })
    // console.log(getBacktestReport)
    const result = _.mapValues(
      _.omit(getBacktestReport, "__typename"),
      (v, k) => {
        return JSON.parse(v)
      }
    )
    return Promise.resolve({ ...result })
  } catch (error) {
    console.log(error)
  }
}

export const fetchBacktestResultTrades = async (id) => {
  return client.query({
    query: GET_BACKTEST_TRADES,
    variables: { id },
  })
}

export const subscribeBacktests = (ownerId, cb) => {
  client
    .subscribe({
      query: UPDATE_BACKTEST_SUBSCRIPTION,
      variables: { ownerId },
    })
    .subscribe({
      next: (result) => {
        console.log(result)
        try {
          const {
            data: { updatedBacktest },
          } = result
          console.log(updatedBacktest)
          cb(updatedBacktest)
        } catch (error) {
          console.log(error)
          cb(null)
        }
      },
    })
}

export const updateBacktest = (input) => {
  return client.mutate({
    mutation: UPDATE_BACKTEST,
    variables: { input },
  })
}
export const updateBacktests = (backtests) => {
  const promises = _.map(backtests, (bt) => updateBacktest(bt))
  return Promise.all(promises)
}

export const getBacktestDataZip = async (id) => {
  try {
    const {
      data: {
        getBacktestDataZip: { data },
      },
    } = await client.query({
      query: GET_BACKTEST_DATA_ZIP,
      variables: { id },
    })
    const typedArray = Uint8Array.from(JSON.parse(data))
    const blob = new Blob([typedArray], { type: "application/zip" })
    return Promise.resolve(blob)
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  }
}

// HOLDINGS
export const getHoldings = async (ownerId) => {
  let {
    data: {
      getUserHoldings: { holdings },
    },
  } = await client.query({
    query: GET_HOLDINGS,
    variables: { ownerId },
  })

  holdings = _.map(holdings, (h) => {
    return {
      ...h,
      legs: JSON.parse(h.legs),
      exitParams: JSON.parse(h.exitParams),
    }
  })
  return Promise.resolve(holdings)
}

export const createHolding = (input) => {
  return client.mutate({
    mutation: CREATE_HOLDING,
    variables: input,
  })
}

export const createHoldings = (ownerId, toSave) => {
  const promises = _.map(
    toSave,
    ({ ticker, legs, signature, orderId, qty }) => {
      return createHolding({
        input: {
          orderId,
          ticker,
          legs: JSON.stringify(legs.slice()),
          signature,
          qty,
          status: "STAGED",
          ownerId,
        },
      })
    }
  )
  return Promise.all(promises)
}

export const updateHolding = (input) => {
  return client.mutate({
    mutation: UPDATE_HOLDING,
    variables: input,
  })
  // return API.graphql(graphqlOperation(UPDATE_HOLDING, input))
}
export const updateHoldings = (inputs) => {
  const promises = _.map(inputs, (input) => {
    return updateHolding(input)
  })
  return Promise.all(promises)
}

// ** RESTFUL ** //

// APP
export const loadStrategyDefaults = async () => {
  const url =
    process.env.REACT_APP_ENV === "production"
      ? "https://assets.orats.com/strategyDefaults.csv"
      : "https://assets.orats.com/strategyDefaults-dev.csv"
  return axios.get(url)
}

// HOLDINGS / SCANNER
export const getScanResult = (payload) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ ...payload, dataSource: "nxcore" }),
  }
  console.log(JSON.stringify(options))
  console.log(`${ORATS_BT_ENDPOINT}/scanner/`)

  return fetch(`${ORATS_BT_ENDPOINT}/scanner/`, options).then((response) =>
    response.json()
  )
}

export const getScanExitResult = (payload) => {
  if (payload.positions.length === 0) {
    return Promise.resolve(null)
  }

  const options = {
    method: "POST",
    body: JSON.stringify(payload),
  }
  return fetch(`${ORATS_BT_ENDPOINT}/scanner/exit`, options).then((response) =>
    response.json()
  )
}

// SPIDER ROCK
// cannot convert to GQL until I get lambda comm with VPN
export const createSpiderRockOrder = (input) => {
  // return API.graphql(graphqlOperation(CREATE_SPIDER_ROCK_ORDER, input))
  return axios.post(
    `${SR_API_ROOT}/trade`,
    { ...input },
    {
      headers: { Authorization: WHEEL_TOKEN },
    }
  )
}

export const pollForSpiderRockFills = () => {
  return axios.get(`${SR_API_ROOT}/orders`, {
    headers: { Authorization: WHEEL_TOKEN },
  })
}
// BT
export const getBacktestStatus = (taskIds) => {
  const req = {
    method: "POST",
    body: JSON.stringify(taskIds),
  }
  return fetch(
    `${ORATS_BT_ENDPOINT}/backtest/status?user_key=${ORATS_TOKEN}`,
    req
  ).then((response) => response.json())
}

export const postBacktestRequest = (payload) => {
  const req = {
    method: "POST",
    body: _.has(payload[0], "weight")
      ? `combinestrategy=${JSON.stringify(payload)}`
      : backtestTemplate(payload),
  }
  return fetch(
    `${ORATS_BT_ENDPOINT}/backtest/input?user_key=${ORATS_TOKEN}`,
    req
  ).then((response) => response.json())
}

export const postJSONBacktestRequest = (payload) => {
  const req = {
    method: "POST",
    body: JSON.stringify(payload),
  }
  return fetch(
    `${ORATS_BT_ENDPOINT}/backtest/json?user_key=${ORATS_TOKEN}`,
    req
  ).then((response) => response.json())
}

export const getcombinedBacktestIds = async (id) => {
  const url = `${ORATS_IO_ENDPOINT}/backtest/status?backtestId=${id}`
  const options = {
    method: "GET",
    headers: { Authorization: ORATS_IO_TOKEN },
    url,
  }
  const {
    data: {
      data: {
        payload: { ids },
      },
    },
  } = await axios(options)
  return Promise.resolve(ids)
}

// TRADEFINDER
// lastClose, divDate, divYield, etc.
// source: GET https://api.orats.com/v2/live/coredatas/general'
export const fetchGeneral = (symbol) => {
  let url = `${WHEEL_API_ROOT}/general/${symbol}`
  return fetch(url).then((response) => response.json())
}

export const fetchAll = (symbol) => {
  let url = `${WHEEL_API_ROOT}/all/${symbol}`
  return fetch(url).then((response) => response.json())
}

export const fetchLastTrade = (symbol) => {
  const payload = typeof symbol === "string" ? [symbol] : symbol
  const req = {
    method: "POST",
    body: JSON.stringify(payload),
  }
  return fetch(
    `https://api.orats.com/v2/live/stock_price?user_key=${ORATS_TOKEN}`,
    req
  ).then((response) => response.json())
}

export const fetchScannerResult = async (
  symbol,
  duration,
  strategies,
  distribution,
  lastClose
) => {
  const dte = util.calcDTEParam(duration)

  const payloads = _.map(strategies, (strategy) => {
    let { options, legRelation } = SCANNER_PARAMS[strategy]

    options = _.map(options, (leg, i) => {
      _.set(leg, "opening.dte", dte)
      return leg
    })

    let result = { ...SCAN_DEF_PAYLOAD }
    _.set(result, "backtest.entry.options", options)
    _.set(result, "backtest.general.symbols", [
      { symbol, weight: null, signals: null },
    ])
    if (!_.isNil(legRelation)) {
      _.set(result, "backtest.entry.legRelation", legRelation)
    }
    return result
  })

  console.log("PL: ", JSON.stringify(payloads))
  const scans = await Promise.all(
    _.map(payloads, async (pl) => {
      const { data } = await axios.post(
        "https://api.orats.io/scanner/scan",
        pl,
        {
          headers: { Authorization: "27733774-5cb2-4c3f-ab4d-e9ad96ca2933" },
        }
      )
      return data
    })
  )
  console.log("RAW: ", JSON.stringify(scans))

  let result = _.map(scans, (d, i) => {
    const strategy = strategies[i]
    return _.map(d, (theLegs) => ({
      ...util.valueOfTradeAtExpiration(theLegs, distribution, lastClose),
      strategy,
    }))
  })

  result = _.orderBy(_.flatten(result), "roc", "desc")
  // console.log(_.map(result, "roc").join(":")) // good
  return _.map(_.take(result, 20), (t, i) =>
    _.assign(t, {
      id: i + 1,
    })
  )
}

// OPRA
export const fetchOpraExpirations = (symbol) => {
  let url = `${OPRA_API_ROOT}/api/expirations/${symbol}`
  return fetch(url).then((response) => response.json())
}

export const leaveChannel = (channel) => {
  const options = {
    method: "DELETE",
    headers: JSON_HEADERS,
  }
  console.log(`API leave channel: ${channel}`)
  fetch(`${OPRA_API_ROOT}/api/channels/${channel}`, options).catch((err) =>
    console.log(err)
  )
}

export const joinChannel = (channel) => {
  const options = {
    method: "POST",
    headers: JSON_HEADERS,
  }
  fetch(`${OPRA_API_ROOT}/api/channels/${channel}`, options).catch((err) =>
    console.log(err)
  )
}
