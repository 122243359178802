import { observable, computed, toJS, extendObservable } from "mobx"
import _ from "lodash"
import { STRATEGIES } from "../constants"
import FORM_META from "./FormMeta"
import { Backtest as BacktestValidator } from "./Validator"
import symbols from "../util/btSymbols"
import Moment from "moment"

const STRIKE_SELECTORS = [
  "adjustmentTriggerType",
  "adjustmentStrikeSelectionType",
  "strikeSelectionType",
  "reEnterStrikeSelectionType",
  "exitTriggerType",
  "exitSpreadTriggerType"
]

const DISABLEABLE_GROUPS = {
  exitBizDaysEarn: {
    path: "exit.bizDaysEarn",
    defaults: { type: "before", days: 1 }
  },
  reEnter: {
    path: "entry.options[n].reEnter",
    defaults: {
      dte: { target: null, min: null, max: null },
      strikeSelection: {
        type: "absDelta",
        value: { target: null, min: null, max: null }
      }
    }
  },
  adjustmentTrigger: {
    path: "entry.options[n].adjustment",
    defaults: {
      trigger: {
        type: "absDelta",
        value: { min: null, max: null },
        tiedTo: { leg: null, min: null, max: null }
      },
      dte: { target: null, min: null, max: null },
      strikeSelection: {
        type: "absDelta",
        value: { target: null, min: null, max: null }
      },
      daysForAdjusting: { min: null, max: null },
      rollWithLeg: null
    }
  },
  exitTrigger: {
    path: "exit.options",
    defaults: {
      leg: 1,
      trigger: { type: "absDelta", value: { min: null, max: null } }
    }
  }
}

// const ERROR_MSGS = {min_above_range_1: "value must"}
// add a field to preserve the current strikeSelection type
const defaultsForStrategy = (strategy, currentStrikeSelection = null) => {
  let defaults = STRATEGIES[strategy]
  return _.reduce(
    FORM_META,
    (result, { path: p, field, type, memberOf }) => {
      const iterations = /options/.test(p) ? defaults.length : 1
      _.times(iterations, i => {
        let value =
          defaults[i][field] === undefined || defaults[i][field] === ""
            ? null
            : defaults[i][field]
        if (!value && _.includes(STRIKE_SELECTORS, field)) {
          value = currentStrikeSelection || "absDelta"
        }
        const path = p.replace(/\[.*\]/, `[${i}]`)
        _.set(result, path, value)
      })
      return result
    },
    {}
  )
}

export default class Strategy {
  @observable errors = new Map()
  @observable loading = false
  @observable isShowingBasic = true

  constructor(strategy, startDate, endDate, form = null) {
    this.dateBoundary = [startDate, endDate]
    // console.log(form)
    if (form) {
      // console.log(form)
      if (form.length) {
        const LEGACY_PROP_MAP = {
          spreadPx: "spreadPxTarget",
          spreadDelta: "spreadDeltaTarget",
          absDeltaMax: "strikeSelectionMax",
          absDeltaMin: "strikeSelectionMin",
          stockOTMPctMax: "strikeSelectionMax",
          stockOTMPctMin: "strikeSelectionMin",
          hedgeDeltaToleranceMin: "hedgeToleranceMin",
          hedgeDeltaToleranceMax: "hedgeToleranceMax",
          absDelta: (leg, legIndex) => {
            this.set("strikeSelectionType", "absDelta", legIndex)
            this.set("strikeSelectionTarget", leg["absDelta"], legIndex)
          },
          stockOTMPct: (leg, legIndex) => {
            this.set("strikeSelectionType", "stockOTMPct", legIndex)
            this.set("strikeSelectionTarget", leg["stockOTMPct"], legIndex)
          },
          // weightings: (leg, legIndex) => {
          //   console.log("coming ere?")
          //   this.set("weight", w, i))
          // },
          weightings: "weight",
          exitBizDaysBeforeEarn: (leg, legIndex) => {
            this.set("exitBizDaysEarnType", "before", legIndex)
            this.set(
              "exitBizDaysEarnDays",
              leg["exitBizDaysBeforeEarn"],
              legIndex
            )
          },
          exitBizDaysAfterEarn: (leg, legIndex) => {
            this.set("exitBizDaysEarnType", "after", legIndex)
            this.set(
              "exitBizDaysEarnDays",
              leg["exitBizDaysAfterEarn"],
              legIndex
            )
          },
          exitSpreadDeltaMax: (leg, legIndex) => {
            this.set("exitSpreadTriggerType", "absDelta", legIndex)
            this.set(
              "exitSpreadTriggerMax",
              leg["exitSpreadDeltaMax"],
              legIndex
            )
          },
          exitSpreadDeltaMin: (leg, legIndex) => {
            this.set("exitSpreadTriggerType", "absDelta", legIndex)
            this.set(
              "exitSpreadTriggeraMin",
              leg["exitSpreadDeltaMin"],
              legIndex
            )
          },
          adjDeltaMin: (leg, legIndex) => {
            this.set("adjustmentTriggerType", "absDelta", legIndex)
            this.set("adjustmentTriggerMin", leg["adjDeltaMin"], legIndex)
          },
          adjDeltaMax: (leg, legIndex) => {
            this.set("adjustmentTriggerType", "absDelta", legIndex)
            this.set("adjustmentTriggerMax", leg["adjDeltaMax"], legIndex)
          },
          adjToDelta: (leg, legIndex) => {
            this.set("adjustmentStrikeSelectionType", "absDelta", legIndex)
            this.set(
              "adjustmentStrikeSelectionTarget",
              leg["adjToDelta"],
              legIndex
            )
          },
          adjToDeltaMax: (leg, legIndex) => {
            this.set("adjustmentStrikeSelectionType", "absDelta", legIndex)
            this.set(
              "adjustmentStrikeSelectionMax",
              leg["adjToDeltaMax"],
              legIndex
            )
          },
          adjToDeltaMin: (leg, legIndex) => {
            this.set("adjustmentStrikeSelectionType", "absDelta", legIndex)
            this.set(
              "adjustmentStrikeSelectionMin",
              leg["adjToDeltaMin"],
              legIndex
            )
          },
          exitLeg1DeltaMax: (leg, legIndex) => {
            this.set("exitTriggerType", "absDelta", legIndex)
            this.set("exitTriggerLeg", 1, legIndex)
            this.set("exitTriggerMax", leg["exitLeg1DeltaMax"], legIndex)
          },
          exitLeg1DeltaMin: (leg, legIndex) => {
            this.set("exitTriggerType", "absDelta", legIndex)
            this.set("exitTriggerLeg", 1, legIndex)
            this.set("exitTriggerMin", leg["exitLeg1DeltaMin"], legIndex)
          },
          exitLeg1OTMPctMin: (leg, legIndex) => {
            this.set("exitTriggerType", "stockOTMPct", legIndex)
            this.set("exitTriggerLeg", 1, legIndex)
            this.set("exitTriggerMin", leg["exitLeg1OTMPctMin"], legIndex)
          },
          exitLeg1OTMPctMax: (leg, legIndex) => {
            this.set("exitTriggerType", "stockOTMPct", legIndex)
            this.set("exitTriggerLeg", 1, legIndex)
            this.set("exitTriggerMin", leg["exitLeg1OTMPctMax"], legIndex)
          }
        }
        const { strategyName, startDate: start, endDate: end } = form[0]
        this.rawStrategyDefaults = STRATEGIES[strategyName]
        this.form = observable(defaultsForStrategy(strategyName))
        this.set("startDate", end)
        this.set("endDate", start)
        _.forEach(form, (leg, i) => {
          const inputs = _.omitBy(leg, (v, k) => v === "none" || v === "")
          const keys = _.keys(inputs)
          if (i < this.rawStrategyDefaults.length) {
            _.forEach(keys, key => {
              if (
                !_.includes(
                  [
                    "email",
                    "optionType",
                    "ratio",
                    "strategyName",
                    "leg",
                    "startDate",
                    "endDate",
                    "signalEntryDate",
                    "signalExitDate",
                    "signalSymbol"
                  ],
                  key
                )
              ) {
                if (LEGACY_PROP_MAP[key]) {
                  const val = LEGACY_PROP_MAP[key]
                  _.isFunction(val) ? val(leg, i) : this.set(val, leg[key], i)
                } else {
                  let toSet = leg[key]
                  if (toSet === "yes" || toSet === "no") {
                    toSet = toSet === "yes" ? true : false
                  }
                  this.set(key, toSet, i)
                }
              }
            })
            // null out groups
            _.set(this.form, `entry.options[${i}].reEnter`, null)
            if (
              !_.includes(keys, "adjDeltaMin", "adjDeltaMax", "tiedToLegNum")
            ) {
              _.set(this.form, `entry.options[${i}].adjustment`, null)
            }
            // these will only ever operate on leg1
            if (
              !_.includes(keys, "exitBizDaysBeforeEarn", "exitBizDaysAfterEarn")
            ) {
              _.set(this.form, `exit.bizDaysEarn`, null)
            }
            if (
              !_.includes(
                keys,
                "exitLeg1DeltaMax",
                "exitLeg1DeltaMin",
                "exitLeg1OTMPctMin",
                "exitLeg1OTMPctMax"
              )
            ) {
              _.set(this.form, `exit.options`, null)
            }
          }
        })
        // rules: can't have both signals and weight, only one unique signal symbol
        // I do seem to be finding IBM in there with some signals input.csv
        const signalSymbolsOnly = _.map(
          form,
          ({ signalSymbol }) => signalSymbol
        )
        const hasSignals =
          _.filter(signalSymbolsOnly, ss => ss !== "none").length > 0
        if (hasSignals) {
          const groupedSignals = _.groupBy(
            _.map(
              form,
              ({ signalSymbol, signalEntryDate, signalExitDate }) => ({
                signalSymbol,
                signalEntryDate,
                signalExitDate
              })
            ),
            "signalSymbol"
          )
          const symbolArray = _.map(groupedSignals, (v, k) => {
            const signals = _.map(
              v,
              ({ signalEntryDate: entryDate, signalExitDate: exitDate }) => ({
                entryDate,
                exitDate
              })
            )
            return { symbol: k, weight: null, signals }
          })
          _.set(this.form, "general.symbols", symbolArray)
        } else {
          const symbolArray = _.map(form, ({ weightings, symbol: symbols }) => {
            const weight =
              weightings && weightings !== "none" ? +weightings : null
            const symbol = symbols || null
            return { weight, symbol }
          })
          _.set(this.form, "general.symbols", symbolArray)
        }
      } else {
        // you don't have the block here!!!!!
        this.form = observable(form)
      }
    } else {
      this.rawStrategyDefaults = STRATEGIES[strategy]
      this.form = observable(defaultsForStrategy(strategy))
      // console.log(toJS(this.form))
      this.setMiscDefaults()
    }
  }

  setMiscDefaults = () => {
    const [startDate, endDate] = this.dateBoundary
    this.set("startDate", startDate)
    this.set("endDate", endDate)
    this.set("strikeSelectionType", "absDelta")
    this.set("weight", null, 0)
    // hidden
    this.set("perTradeReturnType", "notional")
    this.set("dailyReturnType", "average")
    this.set("optionCommission", 1)
    this.set("stockCommission", 0.01)
    // disable
    _.forEach(this.disableableGroups, g => this.toggleNullifyGroup(g))
    // this.inputs.replace({symbol:this.get("symbol"), strategyName:this.get("strategyName") })
  }

  @computed
  get panels() {
    const filtered = this.isShowingBasic
      ? _.filter(FORM_META, { isBasic: true })
      : _.reject(FORM_META, {
          memberOf: "hidden"
        })
    return _.uniq(_.map(filtered, item => item.memberOf))
  }

  @computed
  get groups() {
    return _.groupBy(FORM_META, "memberOf")
  }

  @computed
  get legCount() {
    return this.form.entry.options.length
  }

  @computed
  get strategyOptions() {
    return _.map(_.keys(STRATEGIES), k => ({
      key: k,
      text: _.startCase(k),
      value: k
    }))
  }

  @computed
  get groupsWithErrors() {
    const groups = _.map(toJS(this.errors), (v, k) => {
      const [field] = k.split(":")
      return FORM_META[field].group
    })
    return groups
  }

  @computed
  get disabledGroups() {
    let results = _.reduce(
      DISABLEABLE_GROUPS,
      (result, { path: p }, key) => {
        const path = p.replace(/\[.*\]/, "[0]")
        if (_.get(this.form, path) === null) {
          result.push(key)
        }
        return result
      },
      []
    )
    if (_.includes(results, "adjustmentTrigger")) {
      results = _.concat(results, [
        "adjustmentDte",
        "adjustmentStrikeSelection",
        "adjustMinMaxDays",
        "adjustWithLegNumber"
      ])
    } else {
      results = _.without(
        results,
        "adjustmentDte",
        "adjustmentStrikeSelection",
        "adjustMinMaxDays",
        "adjustWithLegNumber"
      )
    }
    return results
  }

  @computed
  get disableableGroups() {
    return _.keys(DISABLEABLE_GROUPS)
  }

  @computed
  get backtestName() {
    const { strategyName, symbols } = this.form.general
    const { dte, strikeSelection: ss } = this.form.entry.options[0].opening
    const ssType = ss.type === "absDelta" ? "Dlt" : "StkOTMPct"
    // SPY :  ShortStrangle :Dte:30,20,45,  Dlt:0.3,0.2,0.5,  AdjLgDlt,AdjLg2Dlt,AdjMnMxDys,AdjTdLgDlt,AdjWLgNm
    // IBM :  LongCall      :Dte:30,20,45,  Dlt:0.3,0.2,0.5,  overLayWithStock

    const sym = _.map(symbols, "symbol").join("+")
    const sig = `${sym}:${strategyName}:Dte:${dte.target},${dte.min},${
      dte.max
    },${ssType}:${ss.value.target},${ss.value.min},${
      ss.value.max
    },${this.inputGroups.join(",")}`
    return _.trimEnd(sig, ",")
  }

  // should this not be in strategyStore
  groupsForPanel = panel => {
    const filtered = this.isShowingBasic
      ? _.filter(FORM_META, { isBasic: true, memberOf: panel })
      : _.filter(FORM_META, { memberOf: panel })

    return _.groupBy(filtered, "group")
  }

  panelForGroup = group => {
    return _.find(FORM_META, { group }).memberOf
  }

  optionProps = legIndex => {
    const { optionType, ratio } = this.form.entry.options[legIndex]
    return { optionType, ratio }
  }

  toggleBasic = () => {
    this.isShowingBasic = !this.isShowingBasic
  }

  @computed
  get inputGroups() {
    const groupedFields = _.groupBy(
      _.filter(FORM_META, g => g.group !== undefined),
      "group"
    )
    const inputGroups = _.reduce(
      groupedFields,
      (result, value, key) => {
        const fieldValues = _.map(value, ({ field }) => this.get(`${field}`))
        const filtered = _.reject(fieldValues, val => {
          return _.includes(["absDelta", "stockOTMPct", "", false], val)
        })
        if (filtered.length) {
          result.push(key)
        }
        return result
      },
      []
    )
    return _.without(
      inputGroups,
      "symbol",
      "strategy",
      "dateRange",
      "dte",
      "strikeSelection",
      "contractSize",
      "exitDTEDays"
    )
  }

  set = (key, value, legIndex = null) => {
    if (key === "strategyName") {
      this.rawStrategyDefaults = STRATEGIES[value]
      const currentStrikeSelectionType = this.get("strikeSelectionType")

      const defaultPropsToMerge = _.omit(
        defaultsForStrategy(value, currentStrikeSelectionType),
        "general.symbols"
      )
      defaultPropsToMerge.general.symbols = toJS(this.form.general.symbols)
      console.log("this.form.general.symbols")
      console.log(this.form.general.symbols)
      console.log("defaultPropsToMerge.general.symbols")
      console.log(defaultPropsToMerge.general.symbols)
      extendObservable(this.form, defaultPropsToMerge)
      this.setMiscDefaults()
      return
    }

    const path = FORM_META[key].path.replace(/\[.*\]/, `[${legIndex}]`)

    _.set(this.form, path, value)

    // sync the other legs with this value
    if (_.includes(STRIKE_SELECTORS, key)) {
      _.times(this.legCount, legIndex => {
        const path = FORM_META[key].path.replace(/\[.*\]/, `[${legIndex}]`)
        _.set(this.form, path, value)
        // assumption:
        // - 'type' selection results in 'value' sibling target, min or max being set
        // - only doing tghis for one field (strikeSelectionType)
        if (key === "strikeSelectionType") {
          _.forEach(["target", "min", "max"], f => {
            const valsPath = path.replace(/type$/, `value.${f}`)
            if (_.get(this.form, valsPath) !== undefined) {
              let val = this.rawStrategyDefaults[legIndex][value]
              if (f === "min") {
                val = this.rawStrategyDefaults[legIndex][`${value}Min`]
              }
              if (f === "max") {
                val = this.rawStrategyDefaults[legIndex][`${value}Max`]
              }
              _.set(this.form, valsPath, val)
            }
          })
        }
      })
    }
  }

  get = (key, legIndex = 0) => {
    if (legIndex > 0 && !/\[.*\]/.test(FORM_META[key].path)) {
      return null
    }
    const path = FORM_META[key].path.replace(/\[.*\]/, `[${legIndex}]`)
    const val =
      FORM_META[key].control === "checkbox"
        ? _.get(this.form, path) || false
        : _.get(this.form, path) === undefined ||
          _.get(this.form, path) === null
          ? ""
          : _.get(this.form, path)
    return val
  }

  validate = (key, legIndex = 0) => {
    // console.log(this.backtestName)
    let erz = {}

    _.forEach(this.get("symbols"), ({ symbol }, i) => {
      const isValid = _.findIndex(symbols, s => s === symbol.toUpperCase()) >= 0
      if (!isValid) {
        erz[`symbol:${i}`] = ["invalid symbol"]
      }
    })

    const startDate = Moment(this.get("startDate"))
    const endDate = Moment(this.get("endDate"))
    const boundaryStart = Moment(this.dateBoundary[0])
    const boundaryEnd = Moment(this.dateBoundary[1])

    if (!startDate.isSameOrAfter(boundaryStart)) {
      erz[`startDate:0`] = [
        `start date must be no earlier than ${this.dateBoundary[0]}`
      ]
    }

    if (!endDate.isSameOrBefore(boundaryEnd)) {
      erz[`endDate:0`] = [
        `end date must be no later than ${this.dateBoundary[1]}`
      ]
    }

    try {
      BacktestValidator(this.toJS())
      this.errors.clear()
    } catch (e) {
      console.log("Not valid")
      const { path: p, value: values, reason } = e
      const regex = /\.(\d+)\./
      const match = p.join(".").match(regex)
      const path = p.join(".").replace(regex, `[n].`)
      const [, idx] = match || [null, 0]

      let field = undefined
      if (reason) {
        switch (reason) {
          case "min_gt_max":
            field = _.find(FORM_META, { path: `${path}.min` })
            erz[`${field.field}:${idx}`] = ["must be less than max"]
            break
          case "max_lt_min":
            field = _.find(FORM_META, { path: `${path}.max` })
            erz[`${field.field}:${idx}`] = ["must be greater than min"]
            break
          case "target_lt_min":
            field = _.find(FORM_META, { path: `${path}.target` })
            erz[`${field.field}:${idx}`] = ["must be greater than min"]
            break
          case "target_gt_max":
            field = _.find(FORM_META, { path: `${path}.target` })
            erz[`${field.field}:${idx}`] = ["must be less than max"]
            break
          case "not_valid_date":
            field = _.find(FORM_META, { path: `${path}` })
            erz[`${field.field}:${idx}`] = [
              "must be input as either YYYY-MM-DD or M/D/YYYY"
            ]
            break
          case "start_after_end":
            field = _.find(FORM_META, { path: `${path}` })
            erz[`${field.field}:${idx}`] = [
              "must be before your chosen end date"
            ]
            console.log(erz)
            break
          case "end_before_start":
            field = _.find(FORM_META, { path: `${path}` })
            erz[`${field.field}:${idx}`] = [
              "must be after your chosen start date"
            ]
            break
          case "strike_selection_not_set":
            if (this.get("adjustmentTriggerMin", idx)) {
              erz[`adjustmentTriggerMin:${idx}`] = [
                "strike selection target, min and max also required"
              ]
            }
            if (this.get("adjustmentTriggerMax", idx)) {
              erz[`adjustmentTriggerMax:${idx}`] = [
                "strike selection target, min and max also required"
              ]
            }
            _.map(["target", "min", "max"], k => {
              field = _.find(FORM_META, {
                path: `entry.options[n].adjustment.strikeSelection.value.${k}`
              })
              erz[`${field.field}:${idx}`] = ["value required"]
            })
            break
          default:
            console.log("no result")
        }
        if (/(min|max|target)_(below|above)_range_[-\d]+/.test(reason)) {
          field = _.find(FORM_META, { path: `${path}.min` })
          const [, aboveOrBelow, boundary] = reason.match(
            /(above|below).*_([-\d]+)/
          )
          const msg =
            aboveOrBelow === "above"
              ? `must be below ${boundary}`
              : `must be above ${boundary}`
          erz[`${field.field}:${idx}`] = [msg]
        }
      } else if (values === null) {
        field = _.find(FORM_META, { path: `${path}` })
        erz[`${field.field}:${idx}`] = ["cannot be empty"]
      }
      // this.errors.replace(erz)
    }

    // symbol and date validation
    // let erz = {}
    // erz[`endDate:0`] = [`end date must be no later than`]
    // erz[`startDate:0`] = [`end date must be no later than`]

    // validate.validators.startDateBoundary = (value, options, key, attributes) => {
    //   return !Moment(value).isSameOrAfter(options.start)
    //     ? ` must be on or after ${options.start}`
    //     : null
    // }0
    // validate.validators.endDateBoundary = (value, options, key, attributes) => {
    //   return !Moment(value).isSameOrBefore(options.end)
    //     ? ` must be on or before ${options.end}`
    //     : null
    // }

    this.errors.replace(erz)
  }

  addSymbolRow = () => {
    const rows = this.get("symbols")
    rows.push({ symbol: "", weight: "", signals: null })
  }

  removeSymbolRow = index => {
    const rows = this.get("symbols")
    if (rows.length === 1) {
      rows[0].symbol = null
      rows[0].weight = null
      rows[0].signals = null
    } else {
      rows.splice(index, 1)
    }
    // after row removed
    if (rows.length === 1) {
      console.log("AHOK")
      rows[0].weight = null
    }
  }

  toggleNullifyGroup = key => {
    const currentlyDisabled = _.includes(this.disabledGroups, key)
    const { path, defaults } = DISABLEABLE_GROUPS[key]

    let paths = /\[.*\]/.test(path)
      ? _.times(this.legCount, l => path.replace(/\[.*\]/, `[${l}]`))
      : [path]

    let value = _.includes(this.disabledGroups, key) ? defaults : null
    if (key === "exitTrigger" && currentlyDisabled) {
      value = _.times(this.legCount, i => ({
        ...defaults,
        leg: i + 1
      }))
    }
    _.forEach(paths, p => {
      extendObservable(this.form, toJS(_.set(this.form, p, value)))
    })
  }

  toJS() {
    return toJS(this.form)
  }
}
