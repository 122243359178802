import React, { Component } from "react"
import { toJS, observable } from "mobx"
import { observer, inject } from "mobx-react"
import {
  Checkbox,
  Grid,
  Table,
  Icon,
  Input,
  List,
  Message,
  Breadcrumb,
  Button
} from "semantic-ui-react"
import { Text, Flex, Box } from "rebass"
import { Link, NavLink } from "react-router-dom"
import classNames from "classnames"
import Moment from "moment"
import YouTube from "react-youtube"
import _ from "lodash"
import "babel-polyfill"

const BacktestLink = ({ name, id, status, messages }) => {
  const isCombined = name.split("|").length > 1
  if (isCombined) {
    const combined = _.reduce(
      name.split("|"),
      (result, nm) => {
        const [symbol, strategyName] = nm.split(":")
        result = result.length
          ? `${result} + ${symbol}:${strategyName}`
          : `${symbol}:${strategyName}`
        return result
      },
      ""
    )
    return <Link to={`/backtest/${id}`}>{combined}</Link>
  }

  let [, symbolStrat, dteSs] = name.match(
    /(^[\w+]+:\w+):(\w+:[0-9.,]+\w+:[0-9.,]+)/
  ) || [null, null, null]
  // console.log(`taskId: ${taskId} symStrat: ${symbolStrat}, dteStrikeSelection: ${dteSs}`)

  if (symbolStrat === null) {
    const [sym, strat, dte, ss] = name.split(":")
    symbolStrat = `${sym}:${strat}`
    dteSs = `${dte},${ss}`
  }

  let linkContent =
    status === "COMPLETE" && !messages ? (
      <Link to={`/backtest/${id}`}>{symbolStrat}</Link>
    ) : (
      symbolStrat
    )
  if ((status === "COMPLETE" && messages) || status === "ERROR") {
    linkContent = (
      <div>
        {`${symbolStrat} | `}{" "}
        <Link to={`/backtest/create/${id}`}> [edit] </Link>
      </div>
    )
  }

  return (
    <div>
      {linkContent}
      <div
        style={{
          fontSize: 11,
          color: "rgba(40,40,40,.3)",
          lineHeight: "11px"
        }}>
        {_.trimEnd(dteSs, ",")}
      </div>
    </div>
  )
}

const rb = (
  { rowCheckHandler },
  { id, createdAt, status, messages, name, stats, isSelected, isChecked },
  i
) => {
  const processing = status === "PROCESSING"
  return (
    <Table.Row key={`row-${i}`} disabled={processing}>
      <Table.Cell collapsing>
        <Checkbox
          checked={isChecked}
          onChange={rowCheckHandler.bind(null, id)}
        />
      </Table.Cell>
      <Table.Cell
        width={4}
        content={
          <BacktestLink
            name={name}
            id={id}
            messages={messages}
            status={status}
          />
        }
      />
      <Table.Cell content={stats ? stats.AnnReturn : ""} />
      <Table.Cell content={stats ? stats.AnnSharpe : ""} />
      <Table.Cell className={classNames({ processing: processing })}>
        {status}
        {processing && <Icon loading name="asterisk" />}
      </Table.Cell>
      <Table.Cell
        content={messages && messages.length ? messages[0].toUpperCase() : ""}
      />
      <Table.Cell content={Moment(createdAt).calendar()} />
    </Table.Row>
  )
}

@inject("userStore", "strategyStore")
@observer
export default class BacktestList extends Component {
  @observable
  isCombineMode = false
  @observable
  weightings = null
  @observable
  errors = null

  // componentWillMount() {
  //   await this.props.strategyStore.getBacktestsCount()
  //   await this.props.strategyStore.getBacktests()
  // }

  toggleSelectAllBacktests = e => {
    this.props.strategyStore.toggleSelectAllBacktests()
  }

  rowCheckHandler = id => {
    this.props.strategyStore.toggleBacktestSelect(id)
  }

  deleteHandler = (e, data) => {
    this.props.strategyStore.archiveSelectedBacktests()
  }

  nextPageHandler = () => {
    this.props.strategyStore.getBacktests(1)
  }

  previousPageHandler = () => {
    this.props.strategyStore.getBacktests(-1)
  }

  submitHandler = async e => {
    // const total = _.map(values(this.weightings), v => numeral(v).value())
    // if (sum(total) > 100) {
    //   this.errors = ["Weightings must not exceed 100"]
    // } else {
    const payload = _.map(this.weightings, (value, key) => ({
      weight: value * 0.01,
      id: key
    }))
    await this.props.strategyStore.combineBacktests(payload)
    this.isCombineMode = false
    // }
  }

  toggleCombineHandler = e => {
    this.errors = null
    const { selectedBacktestIds } = this.props.strategyStore
    this.isCombineMode = !this.isCombineMode
    if (this.isCombineMode) {
      this.weightings = _.reduce(
        selectedBacktestIds,
        (result, id) => {
          result[id] = _.round(100 / selectedBacktestIds.length, 1)
          return result
        },
        {}
      )
    } else {
      this.weightings = null
    }
  }

  handleChange = key => {
    return {
      value: this.weightings[key],
      onChange: (e, { value }) => {
        this.errors = null
        this.weightings[key] = value
      }
    }
  }

  render() {
    const {
      strategyStore,
      userStore: { isFreePlan, isNewSignUp, isInternalUser }
    } = this.props
    const {
      backtestsForRender: backtests,
      allChecked,
      selectedBacktestIds,
      isBusy,
      isCombining,
      isDeleting,
      canCombine,
      backtestsCount,
      backtestsPageNumberInfo,
      backtestsPageNumber
    } = strategyStore
    const someSelected = selectedBacktestIds.length > 0
    const showCombineButton = canCombine && !isFreePlan
    const createUrl = isInternalUser ? `/backtest/ncreate` : `/backtest/create`
    return (
      <div>
        <Grid verticalAlign="middle" columns="equal">
          {!someSelected && (
            <Grid.Column floated="left">
              <Breadcrumb size="large">
                <Breadcrumb.Section>My Backtests</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          )}
          <Grid.Column>
            <List horizontal divided relaxed>
              <List.Item>
                {someSelected && !this.isCombineMode && (
                  <Button
                    size="tiny"
                    loading={isDeleting}
                    disabled={this.isCombineMode || isDeleting}
                    content="Delete"
                    color="red"
                    onClick={this.deleteHandler}
                  />
                )}
                {showCombineButton && !this.isCombineMode && (
                  <Button
                    size="tiny"
                    loading={isCombining}
                    disabled={isDeleting || isDeleting}
                    content="Combine"
                    color="blue"
                    onClick={this.toggleCombineHandler}
                  />
                )}
                {this.isCombineMode && (
                  <span>
                    <Button
                      size="tiny"
                      loading={isCombining}
                      color="blue"
                      content="Submit Combination"
                      onClick={this.submitHandler}
                    />
                    <Button
                      size="tiny"
                      content="Cancel"
                      loading={isBusy}
                      onClick={this.toggleCombineHandler}
                    />
                  </span>
                )}
              </List.Item>
            </List>
            {!someSelected && (
              <List floated="right" horizontal divided relaxed>
                <List.Item>
                  <Button
                    id="backtestCreate"
                    size="tiny"
                    content="New Backtest"
                    as={NavLink}
                    to={createUrl}
                    primary
                  />
                </List.Item>
              </List>
            )}
          </Grid.Column>
        </Grid>
        {this.errors && (
          <Message
            warning
            header="There are some errors with your input"
            list={toJS(this.errors)}
          />
        )}
        {!this.isCombineMode && backtests.length > 0 && (
          <div>
            <Table
              style={{ marginTop: 4 }}
              compact
              headerRow={[
                {
                  key: 0,
                  content: (
                    <Checkbox
                      checked={allChecked}
                      onClick={this.toggleSelectAllBacktests}
                    />
                  )
                },
                "Name",
                "Return (Ann)",
                "Sharpe (Ann)",
                "Status",
                "Info",
                "Run Date"
              ]}
              tableData={backtests}
              renderBodyRow={rb.bind(null, {
                rowCheckHandler: this.rowCheckHandler
              })}
            />
            <Flex alignItems="center">
              <Box ml="auto" />
              <Box mr={2}>
                <Text color={isBusy ? "gray.2" : "grey"} fontWeight="bold">{`${
                  backtestsPageNumberInfo[0]
                }-${backtestsPageNumberInfo[1]} of ${backtestsCount}`}</Text>
              </Box>
              <Box>
                <div>
                  <Button
                    basic
                    size="tiny"
                    icon="left chevron"
                    disabled={isBusy || backtestsPageNumber === 1}
                    onClick={this.previousPageHandler}
                  />
                  <Button
                    basic
                    size="tiny"
                    icon="right chevron"
                    disabled={
                      isBusy || backtestsCount === backtestsPageNumberInfo[1]
                    }
                    onClick={this.nextPageHandler}
                  />
                </div>
              </Box>
            </Flex>
          </div>
        )}
        {this.isCombineMode && (
          <Table style={{ marginTop: 4 }} compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    disabled
                    checked={allChecked}
                    onClick={this.toggleSelectAllBacktests}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell colSpan="5">Weightings</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                _.filter(backtests, b => _.includes(selectedBacktestIds, b.id)),
                (
                  {
                    taskId,
                    createdAt,
                    status,
                    messages,
                    name,
                    stats,
                    isSelected,
                    isChecked,
                    id
                  },
                  i
                ) => {
                  return (
                    <Table.Row key={`row-${i}`}>
                      <Table.Cell collapsing>
                        <Checkbox checked={isChecked} disabled />
                      </Table.Cell>
                      <Table.Cell
                        width={4}
                        content={
                          <BacktestLink
                            name={name}
                            taskId={taskId}
                            messages={messages}
                            status={status}
                          />
                        }
                      />
                      <Table.Cell
                        colSpan={5}
                        content={
                          <Input size="mini" {...this.handleChange(id)} />
                        }
                      />
                    </Table.Row>
                  )
                }
              )}
            </Table.Body>
          </Table>
        )}
        {isNewSignUp && (
          <Grid centered>
            <Grid.Row>
              <YouTube
                videoId="PSdd20L4vfU"
                onReady={() => console.log("ready")}
              />
            </Grid.Row>
          </Grid>
        )}
      </div>
    )
  }
}
