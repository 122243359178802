import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { observable } from "mobx"
import numeral from "numeral"
import {
  Button,
  Checkbox,
  Grid,
  Table,
  Label,
  List,
  Icon,
  Modal,
  Popup,
  Loader
} from "semantic-ui-react"
import Moment from "moment"
import util from "../../util"
import chunk from "lodash/chunk"
import keys from "lodash/keys"
import map from "lodash/map"

const LegItem = props => {
  const { leg } = props
  return (
    <Table.Row>
      <Table.Cell>
        <Label size="mini" color={leg.ratio === 1 ? "green" : "red"}>
          {leg.ratio === 1 ? "BUY" : "SELL"}
        </Label>
      </Table.Cell>
      <Table.Cell style={{ width: 65 }}>
        {Moment(leg.expirDate)
          .format("MMM DD")
          .toUpperCase()}
      </Table.Cell>
      <Table.Cell style={{ width: 50 }}>{leg.strike}</Table.Cell>
      <Table.Cell>
        <Label circular>{leg.optionType === "call" ? "C" : "P"}</Label>
      </Table.Cell>
      <Table.Cell>{numeral(leg.tradeOptPx).format("$0.00")}</Table.Cell>
    </Table.Row>
  )
}

class TradeItem extends Component {
  rowClick = e => {
    e.preventDefault()
    this.props.store.selectedTradeId = this.props.trade.id
  }

  render() {
    const { trade, selectedTradeId } = this.props
    return (
      <Table.Row
        onClick={e => this.rowClick(e)}
        positive={selectedTradeId === trade.id}>
        <Table.Cell>
          <Label circular>{trade.id}</Label>
        </Table.Cell>
        <Table.Cell>{util.strategyNamesMap[trade.strategy]}</Table.Cell>
        <Table.Cell>
          <Table compact basic="very" size="small" celled collapsing>
            <Table.Body>
              {map(trade.legs, (l, i) => {
                return <LegItem key={i} idx={i} leg={l} />
              })}
            </Table.Body>
          </Table>
        </Table.Cell>
        <Table.Cell textAlign="center">
          {numeral(trade.roc).format("0%")}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {numeral(trade.expectedReturn).format("$0.00")}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {numeral(util.margin(trade)).format("$0.00")}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {numeral(util.maxGain(trade)).format("$0.00")}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {trade.strategy === "SC"
            ? util.maxLoss(trade)
            : numeral(util.maxLoss(trade)).format("($0.00)")}
        </Table.Cell>
        <Table.Cell textAlign="center">{trade.dte}</Table.Cell>
        <Table.Cell textAlign="center">
          {numeral(trade.notional).format("0.0%")}
        </Table.Cell>
      </Table.Row>
    )
  }
}

@inject("store")
@observer
export default class Trades extends Component {
  @observable open = false

  show = () => (this.open = true)
  close = () => (this.open = false)
  onSubmit = () => {
    this.props.store.reFetchTrades()
    this.open = false
  }

  handleChange = key => ({
    checked: this.props.store.availableStategies[key],
    onChange: (e, { checked }) => {
      this.props.store.updateStategies(key, checked)
    }
  })

  render() {
    const {
      selectedTopTrades: trades,
      isLoadingHistory,
      isLoadingTrades,
      underlying: { ticker, currentPrice },
      availableStategies: strategies
    } = this.props.store
    const priceSlug = `[${ticker} | ${numeral(currentPrice).format("$0.00")}]`
    const [colOne, colTwo, colThree] = chunk(keys(strategies), 3)
    return (
      <section className="section" style={{ paddingTop: 0 }}>
        {!isLoadingHistory && (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Popup
                    trigger={<Icon circular name="help" />}
                    position="bottom left"
                    content="Top trades based on the distribution above"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>Strategy</Table.HeaderCell>
                <Table.HeaderCell>
                  Trade{" "}
                  <span style={{ color: "#B3B6B7", paddingLeft: 10 }}>
                    {priceSlug}
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Potential Return
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Potential Profit
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Margin</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Max Gain</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Max Loss</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Days to Expiry
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Return on Notional
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {map(trades, t => (
                <TradeItem
                  key={t.id}
                  trade={t}
                  store={this.props.store}
                  selectedTradeId={this.props.store.selectedTradeId}
                />
              ))}
            </Table.Body>
          </Table>
        )}
        {!isLoadingHistory && isLoadingTrades && (
          <Loader active size="tiny" inline="centered">
            loading top trades
          </Loader>
        )}
        <Modal dimmer={"inverted"} open={this.open} onClose={this.close}>
          <Modal.Header>Choose Strategy</Modal.Header>
          <Modal.Content>
            <Grid columns={3} divided>
              <Grid.Row>
                <Grid.Column>
                  <List>
                    {map(colOne, (k, i) => (
                      <List.Item
                        key={i}
                        content={
                          <Checkbox
                            label={util.strategyNamesMap[k]}
                            {...this.handleChange(k)}
                          />
                        }
                      />
                    ))}
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <List>
                    {map(colTwo, (k, i) => (
                      <List.Item
                        key={i}
                        content={
                          <Checkbox
                            label={util.strategyNamesMap[k]}
                            {...this.handleChange(k)}
                          />
                        }
                      />
                    ))}
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <List>
                    {map(colThree, (k, i) => (
                      <List.Item
                        key={i}
                        content={
                          <Checkbox
                            label={util.strategyNamesMap[k]}
                            {...this.handleChange(k)}
                          />
                        }
                      />
                    ))}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.close} content="Cancel" />
            <Button primary content="OK" onClick={this.onSubmit} />
          </Modal.Actions>
        </Modal>
      </section>
    )
  }
}
