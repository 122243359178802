export const OPRA_API_ROOT = "https://opra.orats.com"

export const WHEEL_API_ROOT = "https://tradefinder.now.sh/api/tf" //"http://localhost:5000/api/tf"

const srApiRoot = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return "https://staging.api.orats.io/sr"
    case "staging":
      return "https://staging.api.orats.io/sr"
    case "production":
      return "https://api.orats.io/sr"
    default:
      return "http://localhost:8000/api/sr"
  }
}
export const SR_API_ROOT = srApiRoot()

export const RAVEN_ENDPOINT =
  "https://3405ff65559a4bc080f9713b41cf097e@sentry.io/158174"

export const ORATS_TOKEN = "sv45al1h9epzx0whxqi24k2ooppfhnrc"
export const WHEEL_TOKEN = "7B6E960A-3515-440E-8717-90749DB8AA01"
export const ORATS_IO_TOKEN =
  process.env.REACT_APP_ENV === "production"
    ? `27733774-5cb2-4c3f-ab4d-e9ad96ca2933`
    : `f2ad9714-6a68-48f6-839d-967c9723e455`

export const ORATS_BT_ENDPOINT =
  process.env.REACT_APP_ENV === "production"
    ? `https://production.api.orats.com/api`
    : `https://staging.api.orats.com/api`

export const ORATS_IO_ENDPOINT =
  process.env.REACT_APP_ENV === "production"
    ? `https://api.orats.io`
    : `https://staging.api.orats.io`

export const BACKTEST_URL =
  process.env.REACT_APP_ENV === "production"
    ? `https://wheel.orats.com/backtest`
    : `https://devwheel.netlify.com/backtest`

export const STRIPE_PK =
  process.env.REACT_APP_ENV === "production"
    ? "pk_live_4sK9Wdcjy7u1wnwyBXqksZiX"
    : "pk_test_1ppscp13kTKpU7ItSNnnfmN6"

export const APPSYNC_ENDPOINT =
  process.env.REACT_APP_ENV === "production"
    ? "https://qlsvh2v7jffd3ehzallioo4nrq.appsync-api.us-east-1.amazonaws.com/graphql"
    : "https://tok2uriygvc5pliwzjwaukshoi.appsync-api.us-east-1.amazonaws.com/graphql"

const auth0RedirectUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return "http://app.local:3000/callback"
    case "staging":
      return "https://development.wheel.orats.com/callback"
    case "tf":
      return "https://tf.wheel.orats.com/callback"
    case "production":
      return "https://wheel.orats.com/callback"
    default:
      return "http://app.local:3000/callback"
  }
}

export const AUTH_CONFIG = {
  domain: "orats.auth0.com",
  clientId:
    process.env.REACT_APP_ENV === "production"
      ? "MnoVL7mYO21FD7LDaoyZp66jWP3N2ZYv"
      : "A1NwT12aINRlU6Cv6F0Xt2OalmwMR8yJ",
  redirectUri: auth0RedirectUri()
}
