import round from "lodash/round"
import map from "lodash/map"
import sumBy from "lodash/sumBy"
import minBy from "lodash/minBy"
import floor from "lodash/floor"
import reduce from "lodash/reduce"
import forEach from "lodash/forEach"
import last from "lodash/last"
import reverse from "lodash/reverse"
import range from "lodash/range"
import nth from "lodash/nth"
import orderBy from "lodash/orderBy"
import concat from "lodash/concat"
import findIndex from "lodash/findIndex"
import find from "lodash/find"
import times from "lodash/times"
import partition from "lodash/partition"
import slice from "lodash/slice"

// import _ from 'lodash'

import Moment from "moment"

const strategyNamesMap = {
  LP: "Long Put",
  SP: "Short Put",
  LC: "Long Call",
  SC: "Short Call",
  LCV: "Long Call Vertical",
  SCV: "Short Call Vertical",
  LPV: "Long Put Vertical",
  SPV: "Short Put Vertical",
  IWMLP: "IWM_LongPut_10Delta",
}

const calcDTEParam = (days) => {
  var mindays = round(days * 0.8, 0)

  if (days > 20) {
    mindays = mindays - round((days / 2 - 10) / 5, 0)
  }

  if (days - mindays > 150) {
    mindays = days - 150
  }

  var maxdays = days + 35
  if (days > 20) {
    maxdays = maxdays + round((days / 2 - 10) / 5, 0)
  }

  if (maxdays - days > 200) {
    maxdays = days + 200
  }

  const mid = (mindays + maxdays) / 2
  return { target: mid, min: mindays, max: maxdays }
}

const optionValueAtExpiration = (option, stockClose) => {
  const premium_paid = option.tradeOptPx
  const strike = option.strike

  if (option.optionType === "call") {
    if (stockClose <= strike) {
      return option.ratio === 1 ? premium_paid * -1 : premium_paid
    } else {
      return (stockClose - strike - premium_paid) * option.ratio
    }
  } else {
    // put
    if (stockClose >= strike) {
      return option.ratio === 1 ? premium_paid * -1 : premium_paid
    } else {
      return (strike - stockClose - premium_paid) * option.ratio
    }
  }
}

const tradeValueAtExpiration = (trade, stockClose) => {
  return reduce(
    trade,
    (sum, t) => {
      return sum + optionValueAtExpiration(t, stockClose)
    },
    0
  )
}

const valueOfTradeAtExpiration = (legs, dist, lastClose) => {
  const tradeCost = reduce(
    legs,
    (sum, leg) => {
      return sum + leg.tradeOptPx * leg.ratio
    },
    0
  )

  var capital = tradeCost
  if (tradeCost < 0) {
    capital = reduce(
      map(orderBy(legs, "strike", "desc"), "strike"),
      (sum, val) => {
        return sum - val
      }
    )

    // .reduce((sum, val) =>{
    //   return sum - val
    // })
    // capital = _
    //   .chain(legs)
    //   .orderBy('strike', 'desc')
    //   .map('strike')
    //   .reduce((sum, val) =>{
    //     return sum - val
    //   })
    //   .value()
  }

  const expectedReturn = reduce(
    dist,
    (sum, bin) => {
      var vae = tradeValueAtExpiration(legs, bin.calcPrice)
      return sum + vae * bin.probability
    },
    0
  )

  const roc = expectedReturn / capital
  if (legs[0].expirDate === "2020-07-31" && legs[0].strike === 34) {
    console.log(JSON.stringify(legs))
    console.log(JSON.stringify(dist))
    console.log("tradeCost: ", tradeCost, "roc", roc, "lc", lastClose)
  }
  const notional = expectedReturn / lastClose

  const dte = minBy(legs, "dte").dte

  return { legs, expectedReturn, roc, capital, tradeCost, notional, dte }
}

let leg1, leg2
const maxLoss = (trade) => {
  switch (trade.strategy) {
    case "LP":
    case "LC":
      return trade.tradeCost * -1
    case "LPV":
      ;[leg1, leg2] = trade.legs
      return round(leg1.tradeOptPx - leg2.tradeOptPx, 2) * -1
    case "LCV":
      ;[leg1, leg2] = trade.legs
      return round(leg1.tradeOptPx - leg2.tradeOptPx, 2) * -1
    case "SC":
      return "Infinite"
    case "SP":
      const { strike, tradeOptPx: premium } = trade.legs[0]
      return strike - premium
    case "SCV":
    case "SPV":
      return (
        trade.legs[0].strike -
        trade.legs[1].strike -
        (trade.legs[0].tradeOptPx - trade.legs[1].tradeOptPx) * -1
      )
    default:
      return -999999
  }
}

const maxGain = (trade) => {
  switch (trade.strategy) {
    case "LC":
      return "Infinite"
    case "LP":
      const { strike, tradeOptPx: premium } = trade.legs[0]
      return strike - premium
    case "SP":
    case "SC":
      return trade.legs[0].tradeOptPx
    case "LCV":
    case "LPV":
      return (
        Math.abs(trade.legs[0].strike - trade.legs[1].strike) -
        (trade.legs[0].tradeOptPx - trade.legs[1].tradeOptPx)
      )
    case "SCV":
    case "SPV":
      return trade.legs[0].tradeOptPx - trade.legs[1].tradeOptPx
    default:
      return -999999
  }
}

const margin = (trade) => {
  switch (trade.strategy) {
    case "SP":
    case "SC":
      return trade.legs[0].strike * 0.2
    case "SPV":
    case "SCV":
      let [leg1, leg2] = trade.legs
      return Math.abs(round(leg1.strike - leg2.strike, 2))
    default:
      return trade.capital
  }
}

const median = (arr) => {
  arr.sort((a, b) => a - b)
  return (arr[(arr.length - 1) >> 1] + arr[arr.length >> 1]) / 2
}

// DISTRIBUTIONS
// =============
const generateStockMovements = (dte, history) => {
  return map(
    slice(history, 0, history.length - dte),
    ({ stkPx: currentDayClose, vol: currentDayIV }, i) => {
      // if the expirations extend beyond the stock history...
      if (history[i + dte] === undefined) {
        let daysOutClose = _.last(history).stkPx
        let daysOutIV = _.last(history).vol
        return {
          return: ((daysOutClose - currentDayClose) / daysOutClose) * 100,
          iv: ((daysOutIV - currentDayIV) / daysOutIV) * 100,
        }
      } else {
        let daysOutClose = history[i + dte].stkPx
        let daysOutIV = history[i + dte].vol
        return {
          return: ((daysOutClose - currentDayClose) / daysOutClose) * 100,
          iv: ((daysOutIV - currentDayIV) / daysOutIV) * 100,
        }
      }
    }
  )
  // .chain(history)
  //
  // .slice(0, history.length-dte)
  // .map( ({stkPx:currentDayClose, vol:currentDayIV}, i) => {
  //   let daysOutClose = history[i+dte].stkPx
  //   let daysOutIV    = history[i+dte].vol
  //   return {
  //     return: (daysOutClose - currentDayClose) / daysOutClose * 100,
  //     iv: (daysOutIV - currentDayIV) / daysOutIV * 100
  //   }
  // })
  //
  // .value()

  // return _
  //   .chain(history)
  //   .slice(0, history.length-dte)
  //   .map( ({stkPx:currentDayClose, vol:currentDayIV}, i) => {
  //     let daysOutClose = history[i+dte].stkPx
  //     let daysOutIV    = history[i+dte].vol
  //     return {
  //       return: (daysOutClose - currentDayClose) / daysOutClose * 100,
  //       iv: (daysOutIV - currentDayIV) / daysOutIV * 100
  //     }
  //   })
  //   .value()
}

const calcRawDistribution = (dte, history) => {
  const binCount = 7
  const minPercentInWing = 0.1
  const movements = generateStockMovements(dte, history)

  const returns = map(movements, "return")
  let minreturn,
    maxreturn,
    centerreturn,
    increment = 0
  const minnumberofreturnsinbucket = floor(minPercentInWing * returns.length)

  for (var x = 20; x > 0; x--) {
    // count down candidate increments
    var changebuckets = [0, 0, 0, 0, 0, 0, 0]
    let rtrnz = [[], [], [], [], [], [], []]
    forEach(returns, (ret) => {
      //test every return
      centerreturn = (-(binCount - 1) / 2) * x //-3 * x  // starting with extreme negative return possibilty (-60)
      increment = x
      forEach(range(binCount), (y) => {
        // now go through each bucket
        if (y === 0) {
          // if this is the first bucket then make it's min extreme minus
          minreturn = -999
          maxreturn = round(centerreturn + x / 2)
        } else if (y === 6) {
          maxreturn = 999
          minreturn = round(centerreturn - x / 2)
        } else {
          minreturn = round(centerreturn - x / 2)
          maxreturn = round(centerreturn + x / 2)
        }

        if (ret >= minreturn && ret < maxreturn) {
          changebuckets[y] = changebuckets[y] + 1
          rtrnz[y].push(ret)
        }

        centerreturn = centerreturn + increment
      })
    })
    if (
      changebuckets[0] > minnumberofreturnsinbucket &&
      changebuckets[6] > minnumberofreturnsinbucket
    ) {
      break
    }
  }

  // price moves result ----
  const lower = (-(binCount - 1) / 2) * increment
  const bins = range(lower, lower * -1 + increment, increment)

  // console.log(last(history))
  const startPrice = last(history).stkPx // but Matt was sourcing earlier close, form different endpoint

  var result = map(changebuckets, (count, i) => {
    return {
      bin: bins[i],
      probability: (count - 1) / returns.length,
      calcPrice: startPrice * (1 + bins[i] / 100),
    }
  })

  return reverse(result)
}

// // TODO: distribution passed in here must be the drift adjusted one
// // TODO: get this rendering in the app
const distributionVolatility = ({ distribution, dte }) => {
  const expectedMovesTotal = reduce(
    distribution,
    (sum, d) => {
      return sum + Math.abs(d.bin * d.probability)
    },
    0.0
  )
  let dteToUse = dte > 0 ? dte : 1
  return Math.sqrt(365 / dteToUse) * expectedMovesTotal // as per MA instruction, if dte is < 1 then set to 30
}

const adjustDistributionToCurrentIV = (
  distribution,
  currentImpliedVolatility,
  dte
) => {
  let adjustmentAttempt
  let binWidth = Math.abs(distribution[3].bin - distribution[4].bin)
  const originalBinWidth = binWidth
  let distVol = distributionVolatility({ distribution, dte })
  const originalDistributionVolatility = distVol
  const currentPrice = find(distribution, { bin: 0 }).calcPrice

  // figure out which way to go (are we lower or higher than target)
  const binWidthAdjustment =
    currentImpliedVolatility > distVol ? 0.0001 : -0.0001
  while (Math.abs(distVol - currentImpliedVolatility) > 0.01) {
    binWidth = binWidth + binWidthAdjustment
    const topHalf = times(4, (i) => i * binWidth)
    const bottomHalf = reverse(
      slice(
        times(4, (i) => i * (-1 * binWidth)),
        1
      )
    )
    const newBins = concat(bottomHalf, topHalf)
    // console.log("newBins: ", newBins)

    adjustmentAttempt = map(newBins, (bin, i) => {
      const calcPrice =
        bin === 0
          ? distribution[i].calcPrice
          : currentPrice + (currentPrice / 100) * bin
      return { bin, probability: distribution[i].probability, calcPrice }
    })
    distVol = distributionVolatility({ distribution: adjustmentAttempt, dte })
  }

  return {
    adjustedDistribution: orderBy(adjustmentAttempt, "bin", "desc"),
    adjustedDistributionVolatility: distVol,
    originalDistributionVolatility,
    originalBinWidth,
  }
}

const UP = "UP"
const DOWN = "DOWN"
const driftIt = (distribution, target, direction = UP) => {
  const up = direction === UP
  const [lower, upper] = up
    ? partition(distribution, (o) => o.bin <= target)
    : partition(distribution, (o) => o.bin < target)
  const sumLower = sumBy(lower, "probability") * (up ? -1 : 1)
  const sumUpper = sumBy(upper, "probability") * (up ? 1 : -1)
  const lowerResult = map(lower, ({ bin, probability, calcPrice }) => {
    return {
      bin,
      calcPrice,
      probability: (probability / sumLower) * 0.01 + probability,
    }
  })
  const upperResult = map(upper, ({ bin, probability, calcPrice }) => {
    return {
      bin,
      calcPrice,
      probability: (probability / sumUpper) * 0.01 + probability,
    }
  })
  return orderBy(concat(upperResult, lowerResult), "bin", "desc")
}

const adjustDistributionForTargetDrift = (distribution, targetDrift) => {
  if (targetDrift >= distribution[0].bin) {
    return map(distribution, (d, i) => {
      let [bin, probability] = i === 0 ? [targetDrift, 1.0] : [d.bin, 0]
      return { bin, probability, calcPrice: d.calcPrice }
    })
  }
  if (targetDrift <= distribution[6].bin) {
    return map(distribution, (d, i) => {
      let [bin, probability] = i === 6 ? [targetDrift, 1.0] : [d.bin, 0]
      return { bin, probability, calcPrice: d.calcPrice }
    })
  }

  const rebal = (direction) => {
    rebalancedDists.push(driftIt(last(rebalancedDists), targetDrift, direction))
    lastAdjustedDrift = sumBy(
      last(rebalancedDists),
      (d) => d.probability * d.bin
    )
  }

  // first iteration
  let rebalancedDists = [map(distribution, (d) => d)]
  let lastAdjustedDrift = sumBy(distribution, (d) => d.probability * d.bin)

  // keep iterating
  if (lastAdjustedDrift < targetDrift) {
    while (lastAdjustedDrift < targetDrift) {
      rebal(UP)
    }
  } else {
    while (lastAdjustedDrift > targetDrift) {
      rebal(DOWN)
    }
  }

  const previousDrift = sumBy(
    nth(rebalancedDists, -2),
    (d) => d.probability * d.bin
  )

  if (
    Math.abs(previousDrift - targetDrift) <
    Math.abs(lastAdjustedDrift - targetDrift)
  ) {
    return nth(rebalancedDists, -2)
  } else {
    return last(rebalancedDists)
  }
}

// UTIL

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  var canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"))
  var context = canvas.getContext("2d")
  context.font = font
  var metrics = context.measureText(text)
  return metrics.width
}

const indexForDate = (moment, plotData) => {
  return findIndex(plotData, (d) => {
    return moment.startOf("day").isSame(Moment(d.date).startOf("day"))
  })
}

const isThirdFriday = (date) => {
  let m = Moment(date)
  return Math.ceil(m.date() / 7) === 3 && m.isoWeekday() === 5
}

const bunchOfWeekDays = (num, unit) => {
  const someTimeAway = Moment().add(num, unit)
  let nextDay = Moment().startOf("day")
  let result = []
  while (nextDay.isBefore(someTimeAway)) {
    if (nextDay.days() !== 0 && nextDay.days() !== 6) {
      const tradeDate = nextDay.format("YYYY-MM-DD")
      result.push({ date: nextDay.toDate(), close: null, tradeDate })
    }
    nextDay.add(1, "days")
  }
  return result
}

export default {
  calcDTEParam,
  calcRawDistribution,
  median,
  strategyNamesMap,
  getTextWidth,
  indexForDate,
  adjustDistributionForTargetDrift,
  valueOfTradeAtExpiration,
  isThirdFriday,
  maxLoss,
  maxGain,
  margin,
  driftIt,
  distributionVolatility,
  adjustDistributionToCurrentIV,
  bunchOfWeekDays,
}
