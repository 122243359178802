import React, { Component } from "react"
import { inject, observer } from "mobx-react"
import { toJS } from "mobx"
import { NavLink, Link } from "react-router-dom"
import {
  Grid,
  Message,
  Image,
  List,
  Breadcrumb,
  Header,
  Segment,
  Dimmer,
  Popup,
  Loader,
  Table,
  Button
} from "semantic-ui-react"

import _ from "lodash"
import numeral from "numeral"
import FileSaver from "file-saver"

import GraphReport from "./GraphReport"
import { LegSignature, NLegSignature } from "./LegSignature"

import { backtestNameComponents } from "./FormUtils"

import { ANNUAL_RESULTS_HEADERS, BT_STAT_PROP_NAMES } from "../../constants"
import { HELP_SNIPPETS } from "./Help"
import { track } from "../../services/analytics"
import Logo from "../../images/logo5.svg"

const renderStatItem = (item, key) => {
  let format
  switch (key) {
    case "AnnReturn":
    case "MaxDrawDown%":
    case "TotStratP&L%":
    case "StratWinRate":
    case "P&L%PerTradeAvg":
    case "P&L%PerDayAvg":
    case "%OfTimeInMarket":
      format = "0.00%"
      break
    case "P&L$PerTradeAvg":
    case "P&L$PerDayAvg":
    case "TotStratP&L$":
      format = "$0,0.00"
      break
    case "DrawDownDays":
    case "DaysInTradeAvg":
    case "TotStratTrades":
      format = "0,0"
      break
    case "AnnSharpe":
      format = "0.00"
      break
    default:
      format = null
  }

  if (!item) return "N/A"
  return format ? numeral(item).format(format) : item
}

const MetaReport = ({ data }) => {
  const rows = _.chunk(_.keys(_.omit(data, "CreditReceivedPerTradeAvg")), 5)
  return (
    <Segment>
      <Header as="h3" content="Summary" />
      <Grid columns="equal" stretched>
        {_.map(rows, (row, i) => (
          <Grid.Row key={`row-${i}`}>
            {_.map(row, (v, i) => {
              return (
                <Grid.Column key={i}>
                  <Popup
                    position="top center"
                    trigger={
                      <Header
                        textAlign="center"
                        as="h2"
                        block
                        content={renderStatItem(data[v], v)}
                        subheader={BT_STAT_PROP_NAMES[v]}
                      />
                    }
                    content={HELP_SNIPPETS[v].content}
                  />
                </Grid.Column>
              )
            })}
          </Grid.Row>
        ))}
      </Grid>
    </Segment>
  )
}

const AnnualReturnsReport = ({ tableData, symbol, strategy, title }) => {
  return (
    <Segment>
      <Header as="h3" content={`Monthly Returns — ${title}`} />
      <Table
        className="monthly_returns"
        size="small"
        headerRow={ANNUAL_RESULTS_HEADERS}
        tableData={toJS(tableData)}
        renderBodyRow={(data, i) => ({
          key: `row-${i}`,
          cells: _.map(data, (d, i) => ({
            key: `cell-${i}`,
            content: d
          }))
        })}
      />
    </Segment>
  )
}

const TradesReport = ({ tableData, isLoading }) => {
  return (
    <Segment>
      <Header as="h3">
        <Header.Content>Trades</Header.Content>
      </Header>
      {isLoading && (
        <Dimmer active inverted>
          <Loader inverted size="small" />
        </Dimmer>
      )}
      {!isLoading && (
        <Table
          className="trades_list"
          size="small"
          headerRow={_.keys(tableData[0])}
          tableData={toJS(tableData)}
          renderBodyRow={(data, i) => ({
            key: `row-${i}`,
            cells: _.map(_.values(data), (d, i) => ({
              key: `cell-${i}`,
              content: d
            }))
          })}
        />
      )}
    </Segment>
  )
}

const FormInputs = ({ form, name, showPlus, symbol }) => {
  let [, symbolStrat, dteSs] = name.match(
    /(^[\w+]+:\w+):(\w+:[0-9.,]+\w+:[0-9.,]+)/
  ) || [null, null, null]

  if (symbolStrat === null) {
    const [sym, strat, dte, ss] = name.split(":")
    symbolStrat = `${sym}:${strat}`
    dteSs = `${dte},${ss}`
  }
  return (
    <div style={{ marginBottom: 8 }}>
      <Header as="h4" style={{ marginBottom: 4 }} content={symbolStrat} />
      <NLegSignature form={form} name={name} />
    </div>
  )
}

@inject("userStore", "strategyStore")
@observer
export default class Backtest extends Component {
  componentWillMount() {
    const { loadBacktest } = this.props.strategyStore
    loadBacktest(this.props.match.params.id)
  }

  componentDidMount() {
    const { id } = this.props.match.params
    document.title = "Backtest View"
    track("WHEEL | Backtest View", {
      backtestId: id
    })
  }

  downloadCsv = async (e, data) => {
    const blob = await this.props.strategyStore.getBacktestDataZip(
      this.props.match.params.id
    )
    const { name, form } = this.props.strategyStore.report
    // const { symbol, strategy } = backtestNameComponents(form)

    // console.log(symbol, strategy)

    let [, symbolStrat] = name.match(
      /(^[\w+]+:\w+):(\w+:[0-9.,]+\w+:[0-9.,]+)/
    ) || [null, null, null]

    const [symbol, strategy] = symbolStrat.split(":")

    const nameComponents = name.split("|")
    const isCombined = nameComponents.length > 1
    let title = isCombined
      ? _.reduce(
          nameComponents,
          (result, comp) => {
            const [symbol, strategyName] = comp.split(":")
            result = result.length
              ? `${result} + ${symbol}:${strategyName}`
              : `${symbol}:${strategyName}`
            return result
          },
          ""
        )
      : `${_.truncate(symbol)}:${strategy}`
    const fileName = title.replace(/:/g, "_")
    FileSaver.saveAs(blob, `${fileName}.zip`)
  }

  render() {
    const {
      errors,
      report,
      backtestTrades: { trades },
      isLoadingTrades
    } = this.props.strategyStore
    let {
      meta,
      graphReturns,
      symbol,
      strategy,
      name,
      perYearData,
      title,
      form,
      combinedForms,
      loading
    } = report
    if (!title && name) {
      const [sym, strat] = name.split(":")
      title = `${sym}:${strat}`
      if (/|/.test(name)) {
        title = _.reduce(
          name.split("|"),
          (result, comp) => {
            const [symbol, strategyName] = comp.split(":")
            result = result.length
              ? `${result} + ${symbol}:${strategyName}`
              : `${symbol}:${strategyName}`
            return result
          },
          ""
        )
      }
    }

    const {
      userStore: { isFreePlan, isLoggedIn, isInternalUser }
    } = this.props
    const { id } = this.props.match.params

    const canDisplayEdit = (isFreePlan && symbol === "IBM") || !isFreePlan
    const editUrl = isInternalUser
      ? `/backtest/ncreate/${id}`
      : `/backtest/create/${id}`
    return (
      <Grid>
        <Grid.Column>
          {errors.length > 0 && (
            <Message negative>
              <Message.Header>An Error Occured</Message.Header>
              <List items={toJS(errors)} />
            </Message>
          )}
          {isLoggedIn && (
            <Grid verticalAlign="middle" columns="equal">
              <Grid.Column
                style={{ paddingTop: 0, paddingBottom: 0 }}
                floated="left">
                <Breadcrumb size="large">
                  <Breadcrumb.Section as={NavLink} to="/backtest" link>
                    My Backtests
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section active>{title}</Breadcrumb.Section>
                </Breadcrumb>
              </Grid.Column>
              <Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }}>
                <List floated="right" style={{ paddingBottom: 10 }} horizontal>
                  {canDisplayEdit &&
                    (combinedForms === null || combinedForms === undefined) && (
                      <List.Item style={{ marginLeft: 2 }}>
                        <Button
                          size="tiny"
                          floated="right"
                          content="Edit"
                          id="editBacktest"
                          labelPosition="left"
                          as={NavLink}
                          to={editUrl}
                          primary
                          icon="clone"
                        />
                      </List.Item>
                    )}
                  <List.Item style={{ marginLeft: 2 }}>
                    <Button
                      size="tiny"
                      disabled={isLoadingTrades}
                      floated="right"
                      content="Download"
                      id="downloadCsv"
                      labelPosition="left"
                      icon="download"
                      onClick={this.downloadCsv}
                    />
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          )}
          {!isLoggedIn && (
            <Grid verticalAlign="middle" columns="equal">
              <Grid.Column>
                <Image width={120} href="/home" src={Logo} alt="orats logo" />
              </Grid.Column>
              <Grid.Column floated="right" textAlign="right">
                <Button
                  basic
                  size="large"
                  color="blue"
                  as={Link}
                  to="/"
                  content="Try Wheel Today!"
                />
              </Grid.Column>
            </Grid>
          )}

          {loading && (
            <Segment>
              <Loader active size="tiny" inline="centered">
                LOADING
              </Loader>
            </Segment>
          )}
          {!loading && (
            <Segment.Group>
              {form && (
                <Segment>
                  {combinedForms ? (
                    _.map(combinedForms, ([name, form], i) => (
                      <FormInputs
                        key={i}
                        form={form}
                        name={name}
                        showPlus={i === 0 || i < combinedForms.length - 1}
                      />
                    ))
                  ) : (
                    <FormInputs form={form} name={name} symbol={symbol} />
                  )}
                </Segment>
              )}
              {meta && <MetaReport data={meta} />}
              {graphReturns && (
                <GraphReport name={name} title={title} data={graphReturns} />
              )}
              {perYearData && (
                <AnnualReturnsReport
                  tableData={perYearData}
                  symbol={symbol}
                  strategy={strategy}
                  title={title}
                />
              )}
              {trades && (
                <TradesReport
                  tableData={toJS(trades)}
                  isLoading={isLoadingTrades}
                />
              )}
            </Segment.Group>
          )}
        </Grid.Column>
      </Grid>
    )
  }
}
