import React, { Component } from "react"
import { GenericChartComponent, utils } from "react-stockcharts"
import Moment from "moment"
import find from "lodash/find"
import last from "lodash/last"

const { noop } = utils

export default class ExpirationArea extends Component {
  saveNode = node => {
    this.node = node
  }

  renderSVG = moreProps => {
    const {
      xScale,
      chartConfig: { height },
      plotData
    } = moreProps
    const { xAccessor } = moreProps

    let x, w

    let m = Moment()
    const todayWeekday = m.day() <= 5 ? m : m.add(8 - m.day(), "d")
    if (todayWeekday.isBefore(Moment(plotData[0].tradeDate))) {
      x = 0
    } else if (todayWeekday.isAfter(Moment(last(plotData).tradeDate))) {
      // allow for diff
      return null
    } else {
      x = xScale(
        xAccessor(
          find(plotData, { tradeDate: todayWeekday.format("YYYY-MM-DD") })
        )
      )
    }

    const exp = Moment(this.props.expiration)
    if (exp.isAfter(Moment(last(plotData).tradeDate))) {
      w = 2000
    } else if (exp.isBefore(Moment(plotData[0].tradeDate))) {
      return null
    } else {
      w =
        xScale(
          xAccessor(find(plotData, { tradeDate: this.props.expiration }))
        ) - x
    }

    return (
      <g>
        <rect x={x} y={0} width={w} height={height} fill="#F1FAFB" />
      </g>
    )
  }

  render() {
    return (
      <GenericChartComponent
        ref={this.saveNode}
        svgDraw={this.renderSVG}
        drawOnPan
      />
    )
  }
}

ExpirationArea.drawOnCanvas = noop
