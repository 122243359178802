import React from "react"
import { Label, Table } from "semantic-ui-react"
import { OuterParam, Params, ParamName } from "../Styled"
import FORM_META from "../../models/FormMeta"
import Strategy from "../../models/Strategy"
import _ from "lodash"

// i should only persist what's been enabled
// i can do a migration by parsing the names and updating all of the form values
// consider putting the values into a backup field, just to be safe
export const LegSignature = ({ form, nameComponents }) => {
  const { formMeta } = nameComponents
  return (
    <div>
      {_.map(formMeta, ({ optionType, ratio, params }, i) => (
        <div key={`leg-sig-${i}`}>
          <Label
            circular
            color={ratio >= 1 ? "green" : "red"}
            size="tiny"
            content={optionType === "call" ? "C" : "P"}
            style={{ marginRight: 6 }}
          />
          {_.map(params, ({ title, vals }, i) => {
            return (
              <OuterParam key={i} ratio={ratio}>
                <ParamName>{title}</ParamName>
                <Params>{vals}</Params>
              </OuterParam>
            )
          })}
        </div>
      ))}
    </div>
  )
}

const COMPONENT_NAME_CONV = {
  ExL1Dlt: "exitTrigger",
  ExDteDys: "exitDTEDays",
  ExSpdPx: "exitSpreadPx",
  ivRnk: "iVRank",
  MktWthPc: "mktWidthPct",
  EDys: "entryDays",
  HDys: "hedgeDays",
  ExSpdDlt: "exitSpreadTrigger",
  ExPLPct: "exitProfitLossPct",
  ExL1OTMPct: "exitTrigger",
  ExHldDys: "exitHoldDays",
  SpdYld: "spreadYieldPct",
  SpdDlt: "spreadDelta",
  SpdPx: "spreadPx"
}

export const NLegSignature = ({ form, name }) => {
  const nameComponents = _.map(name.split(","), c =>
    COMPONENT_NAME_CONV[c] ? COMPONENT_NAME_CONV[c] : c
  )
  const endIdx = _.findIndex(_.reverse(nameComponents), item => !isNaN(+item))
  let inputGroups = _.concat(
    ["dte", "strikeSelection"],
    nameComponents.slice(0, endIdx)
  )
  const strategy = new Strategy(null, null, null, form)
  return (
    <Table basic="very" compact celled collapsing>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="Param" />
          <Table.HeaderCell content="Value / Leg 1" />
          {_.times(strategy.legCount - 1, i => (
            <Table.HeaderCell key={i} content={`Leg ${i + 2}`} />
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(inputGroups, (g, i) => {
          let group = _.filter(FORM_META, { group: g })
          if (g === "exitTrigger") {
            group = _.reject(group, { field: "exitTriggerLeg" })
          }
          const content = _.map(group, ({ field }, i) => strategy.get(field))
          return (
            <Table.Row key={i}>
              <Table.Cell content={g} />
              <Table.Cell content={content.join(", ")} />
              {group[0] === undefined && <Table.Cell content={null} />}
              {strategy.legCount > 1 &&
                group[0] !== undefined &&
                _.times(strategy.legCount - 1, i => (
                  <Table.Cell
                    key={i}
                    content={
                      /\[.*\]/.test(group[0].path)
                        ? _.map(group, ({ field }, l) =>
                            strategy.get(field, i + 1)
                          ).join(", ")
                        : ""
                    }
                  />
                ))}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}
