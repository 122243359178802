import React from "react"
import { Header, Segment } from "semantic-ui-react"

import _ from "lodash"

import { VictoryLine, VictoryChart, VictoryAxis, VictoryLegend } from "victory"

export default ({ data, symbol, strategy, title }) => {
  // const rows = chunk(keys(data), 5)
  // TODO revisit
  let shortGraphResults = _.filter(
    data,
    (point, index) => index % Math.ceil(data.length / 1000) === 0
  )
  shortGraphResults.push(_.last(data))

  return (
    <Segment>
      <Header as="h3">
        <Header.Content>Returns</Header.Content>
      </Header>
      <VictoryChart
        scale={{ x: "time" }}
        padding={{ top: 10, right: 50, bottom: 30, left: 50 }}
        width={1200}
        height={300}
        domainPadding={10}>
        <VictoryAxis
          dependentAxis={true}
          style={{
            axis: { stroke: "#756f6a" },
            ticks: { stroke: "grey", size: 2, strokeWidth: 2 },
            tickLabels: {
              fontSize: 12,
              padding: 4,
              fontFamily: "Roboto Condensed"
            }
          }}
        />
        <VictoryAxis
          dependentAxis={true}
          orientation="right"
          style={{
            axis: { stroke: "#756f6a" },
            ticks: { stroke: "grey", size: 2, strokeWidth: 2 },
            tickLabels: {
              fontSize: 12,
              padding: 4,
              fontFamily: "Roboto Condensed"
            }
          }}
        />
        <VictoryLegend
          x={60}
          y={10}
          data={[
            {
              name: `${title}`,
              symbol: { type: "circle" }
            },
            { name: "STOCK", symbol: { type: "circle" } }
          ]}
          symbolSpacer={6}
          orientation="horizontal"
          colorScale={["MEDIUMPURPLE", "TOMATO"]}
          style={{
            labels: {
              fontFamily: "Roboto Condensed",
              fontSize: 12
            }
          }}
        />
        <VictoryAxis
          fixLabelOverlap={true}
          style={{
            axis: { stroke: "#756f6a" },
            ticks: { stroke: "grey", size: 2, strokeWidth: 2 },
            tickLabels: {
              fontSize: 12,
              padding: 4,
              fontFamily: "Roboto Condensed"
            }
          }}
        />
        <VictoryLine
          style={{
            data: { stroke: "TOMATO", opacity: 0.7 },
            labels: { fontSize: 12 },
            parent: { border: "1px solid #ccc" }
          }}
          data={_.slice(shortGraphResults, 1)}
          x={d => d.date}
          y="stock"
        />
        <VictoryLine
          style={{
            data: {
              stroke: "MEDIUMPURPLE",
              opacity: 0.7
            },
            labels: { fontSize: 12 },
            parent: { border: "1px solid #ccc" }
          }}
          data={_.slice(shortGraphResults, 1)}
          x={d => d.date}
          y="option"
        />
      </VictoryChart>
    </Segment>
  )
}
