import { observable, action, computed } from "mobx"
import _ from "lodash"
import history from "../history"
import { api } from "../services"
import { v4 as uuid } from "uuid"
import Authenticator from "../components/Auth/Authenticator"
import { identify } from "../services/analytics"

class UserStore {
  @observable
  stripeCustomerId = null
  @observable
  isLoading = false
  @observable
  enterpriseUsers = []
  @observable
  isNewSignUp = false
  @observable
  user = {}
  @observable
  errors = []
  @observable
  authState = null

  @computed
  get currentUser() {
    return this.user || {}
  }

  @computed
  get isLoggedIn() {
    return this.user && this.user.id !== undefined
  }

  @computed
  get isEnterpriseUser() {
    const { email } = this.currentUser
    return (
      email !== undefined &&
      _.includes(this.enterpriseUsers, email.toLowerCase())
    )
  }

  @computed
  get isPaidUser() {
    return this.availableCredits >= 1
  }

  @computed
  get isCalper() {
    const { email } = this.currentUser
    return (
      // email.split("@")[1] === "calpers.ca.gov" ||
      _.includes(["jsleeuw@gmail.com", "smanhattan@gmail.com"], email)
    )
  }

  @computed
  get isNJ() {
    const { email } = this.currentUser
    return (
      email.split("@")[1] === "treas.nj.gov" ||
      _.includes(["jsleeuw@gmail.com", "smanhattan@gmail.com"], email)
    )
  }

  @computed
  get isFreePlan() {
    return (
      this.currentUser.plan === null &&
      !_.includes(this.enterpriseUsers, this.currentUser.email.toLowerCase())
    )
  }

  @computed
  get isBasicPlan() {
    return _.includes(
      ["BASIC", "WHEEL_BASIC (trialing)", "WHEEL_BASIC (active)"],
      this.currentUser.plan
    )
  }

  @computed
  get isProPlan() {
    return _.includes(
      [
        "PRO",
        "WHEEL_PRO (trialing)",
        "WHEEL_PRO (active)",
        "WHEEL_PORTFOLIO (trialing)",
        "WHEEL_PORTFOLIO (active)"
      ],
      this.currentUser.plan
    )
  }

  @computed
  get isEnterprisePlan() {
    const { email } = this.currentUser
    return _.includes(this.enterpriseUsers, email.toLowerCase())
  }

  @computed
  get isProOrEntPlan() {
    return this.isProPlan || this.isEnterprisePlan
  }

  @computed
  get isInternalUser() {
    const { email } = this.currentUser
    return _.includes(["matt@orats.com", "jon@orats.com"], email)
  }

  @computed
  get canSeePositions() {
    const { email } = this.currentUser
    console.log(email)
    return _.includes(
      [
        "matt@orats.com",
        "jsleeuw@gmail.com",
        "jon@orats.com",
        "smanhattan@gmail.com",
        "todd.rowohlt@treas.nj.gov"
      ],
      email
    )
  }

  @action("createUser")
  createUser = async input => {
    return api.createUser(input)
  }

  @action("createStripeCustomer")
  createStripeCustomer = async stripeToken => {
    const { email, id } = this.currentUser
    const {
      data: {
        createStripeCustomer: { stripeCustomerId }
      }
    } = await api.createStripeCustomer(email, stripeToken)
    this.user = { ...this.user, stripeCustomerId: stripeCustomerId }
    await api.updateUser({ id, stripeCustomerId: stripeCustomerId })
    return Promise.resolve(stripeCustomerId)
  }

  @action("processSubscription")
  processSubscription = async (customer, plan) => {
    const { id } = this.user
    await api.processSubscription(id, customer, plan)
    this.user = { ...this.user, plan }
    await api.updateUser({ id, plan })
    return Promise.resolve()
  }

  @action("loadCurrentUser")
  loadCurrentUser = async email => {
    let {
      data: { getUserByEmail: user }
    } = await api.getUserByEmail(email)
    if (!user) {
      user = { id: uuid(), email, plan: null }
      user.id = await this.createUser({ input: { id: user.id, email } })
    }

    this.user = { ...this.user, id: user.id, email, plan: user.plan }
    identify(user.id, { email })
    return Promise.resolve()
  }

  @action("logout")
  logout = () => {
    const auth = new Authenticator()
    auth.logout()
  }
}

const userStore = new UserStore()
export default userStore
