import { observable, action, computed, toJS, autorun } from "mobx"
import util from "../util"
import map from "lodash/map"
import assign from "lodash/assign"
import reduce from "lodash/reduce"
import includes from "lodash/includes"
import uniqBy from "lodash/uniqBy"
import maxBy from "lodash/maxBy"
import concat from "lodash/concat"
import minBy from "lodash/minBy"
import keys from "lodash/keys"
import has from "lodash/has"
import pickBy from "lodash/pickBy"
import findIndex from "lodash/findIndex"
import find from "lodash/find"
import filter from "lodash/filter"
import reject from "lodash/reject"
import first from "lodash/first"
import set from "lodash/set"
import flatten from "lodash/flatten"
import reverse from "lodash/reverse"
import trim from "lodash/trim"
import zipObject from "lodash/zipObject"
import groupBy from "lodash/groupBy"
import spread from "lodash/spread"
import merge from "lodash/merge"
import tail from "lodash/tail"
import last from "lodash/last"
import Moment from "moment"
import Raven from "raven-js"
import RSVP from "rsvp"

// import history from "../util/history"
import { CHAIN_PROPS } from "../constants"
import { RAVEN_ENDPOINT } from "../config"
import { api } from "../services"

Raven.config(RAVEN_ENDPOINT, { environment: process.env.NODE_ENV }).install()

export class AppState {
  @observable availableStategies = {}

  @observable timer = 0
  @observable ohlc = []
  @observable pendingRequestCount = 0
  @observable selectedExpiration = null //{dte: 1, expiration: '2017-03-31'}
  @observable topTrades = {}
  @observable selectedTradeId = 1
  @observable underlying = {} //{ticker: 'AAPL', name: 'Apple Inc.', typeDisp: 'Equity', exchDisp: 'NASDAQ', lastClose: 0, lastCloseDate: null}
  @observable isOutlookRangeSliderInFocus = false
  @observable outlook = { pctChg: 0.0, dollarValue: null }
  @observable expirations = []
  @observable opraExpirations = []
  @observable isLoadingHistory = false
  @observable isLoadingTrades = false
  @observable isPostingBacktest = false
  @observable isLoadingBacktest = false
  @observable errors = []
  @observable chartHistoryDuration = 180
  @observable activeRoute = ""
  @observable chain = []
  @observable implieds = null
  @observable historicalVol = []
  @observable backtestResult = {}
  @observable currentBacktest = {}

  constructor() {
    this.availableStategies = assign(
      {
        LC: true,
        SC: false,
        LP: false,
        SP: false,
        LCV: false,
        SCV: false,
        LPV: false,
        SPV: false,
      },
      JSON.parse(localStorage.getItem("WHEEL_STRATEGIES"))
    )

    this.userAdjustedXExtents = null

    autorun(() => {
      if (this.activeRoute && this.activeRoute !== "backtest") {
        this.setSelectedUnderlying({
          ticker: "UBER",
          name: "SPDR S&P 500 ETF",
        })
      }
    })

    // but you also need to didconnect from currrent!
    autorun(() => {
      if (this.activeRoute && this.currentChannelId) {
        if (this.activeRoute === "chain") {
          if (this.c && this.c.name !== this.currentChannelId) {
            console.log(
              `FOUND EXISTING CONNECTION -- DETACHING: ${this.c.name}`
            )
            this.c.unsubscribe(this.onMessage)
            this.c.detach()
            api.leaveChannel(this.c.name)
            this.chain = []
          }
          console.log(`entering channel: ${this.currentChannelId}`)
          api.joinChannel(this.currentChannelId)
          // this.c = realtime.channels.get(this.currentChannelId)
          // this.c.on(stateChange => console.log(stateChange))
          this.c.subscribe(this.onMessage)
        } else if (this.c) {
          console.log(`DETACH: ${this.c.name}`)
          this.c.unsubscribe(this.onMessage)
          this.c.detach()
          api.leaveChannel(this.c.name)
          this.chain = []
        }
      }
    })
  }

  @computed
  get distributions() {
    var result = null
    if (this.ohlc.length > 0) {
      result = map(this.expirations, ({ dte, expirDate }) => {
        const raw = util.calcRawDistribution(dte, this.history)

        const zeroDrift = util.adjustDistributionForTargetDrift(raw, 0)

        const adjs = util.adjustDistributionToCurrentIV(
          reverse(zeroDrift),
          this.implieds[expirDate],
          dte
        )

        const { adjustedDistribution } = adjs

        return { dte, raw, zeroDrift, ivAdjusted: adjustedDistribution }
      })
    }
    console.log("D: ", JSON.stringify(result))
    return result
  }

  @computed
  get chartData() {
    return this.ohlc.peek()
  }

  @computed
  get userStrategyOptions() {
    return reduce(
      keys(this.availableStategies),
      (result, s) => {
        if (!this.availableStategies[s]) {
          result.push({
            key: s,
            text: util.strategyNamesMap[s] || s,
            value: s,
          })
        }
        return result
      },
      []
    )
  }

  // this does not seem to be updating on pan
  @computed
  get chartExtents() {
    console.log("CHART EXTENTS")
    const xExtents = this.userAdjustedXExtents || [
      Moment().subtract(6, "months").toDate(),
      Moment().add(6, "months").toDate(),
    ]
    return { xExtents }
  }

  @computed
  get topTradesMinMax() {
    if (this.selectedTopTrades.length > 0) {
      return [
        minBy(
          flatten(map(this.selectedTopTrades, (t) => flatten(t.legs))),
          "strike"
        ).strike,
        maxBy(
          flatten(map(this.selectedTopTrades, (t) => flatten(t.legs))),
          "strike"
        ).strike,
      ]
    } else {
      return [0, 0]
    }
  }

  @computed
  get chainDataSource() {
    const result = reduce(
      this.chain,
      (result, item) => {
        item.type === "C" ? result.calls.push(item) : result.puts.push(item)
        return result
      },
      { calls: [], puts: [] }
    )
    const { calls, puts } = {
      calls: uniqBy(result.calls, "strike"),
      puts: uniqBy(result.puts, "strike"),
    }
    return { calls, puts, strikes: map(calls, "strike") }
  }

  @computed
  get currentChannelId() {
    // console.log(this.selectedChainExpiration)
    return this.underlying && this.underlying.ticker && this.selectedExpiration
      ? `${this.underlying.ticker}::${this.selectedExpiration.expiration}`
      : null
  }

  @computed
  get longestDte() {
    let result = 0
    if (this.selectedTopTrades.length > 0) {
      result = maxBy(this.selectedTopTrades, "dte").dte
    }
    return result
  }

  @computed
  get selectedTopTrades() {
    return this.topTrades[`${this.selectedExpiration.dte}`] || []
  }

  @computed
  get selectedTrade() {
    var result = null
    if (
      this.selectedTopTrades &&
      this.selectedTopTrades.length &&
      this.selectedTopTrades[`${this.selectedTradeId - 1}`]
    ) {
      result = this.selectedTopTrades[`${this.selectedTradeId - 1}`]
    }
    return result
  }

  @computed
  get selectedDistribution() {
    if (
      this.distributions !== null &&
      this.selectedExpiration &&
      this.implieds
    ) {
      const { dte } = this.selectedExpiration
      const ivAdjusted = find(this.distributions, { dte: dte }).ivAdjusted
      const withTargetDrift = util.adjustDistributionForTargetDrift(
        ivAdjusted,
        this.outlook.pctChg
      )
      return withTargetDrift
    } else {
      return null
    }
  }

  @computed
  get zeroDriftPrice() {
    return this.selectedDistribution[3].calcPrice
  }

  @computed
  get expirationsInView() {
    const exps = map(this.expirations, "expirDate")
    return filter(exps, (e) =>
      Moment(e).add(4, "d").isBefore(this.chartExtents.x[1])
    )
  }

  @computed
  get selectedStrategies() {
    return keys(pickBy(this.availableStategies, (item) => item === true))
  }

  @computed
  get expirationIndeces() {
    const texts = map(this.expirations, (d) =>
      Moment(d.expirDate).format("YYYY-MM-DD")
    )
    return reduce(
      this.chartData,
      (result, d, index) => {
        if (includes(texts, Moment(d.date).format("YYYY-MM-DD"))) {
          result.push(index)
        }
        return result
      },
      []
    )
  } // i'm going to move this to autorun

  @computed
  get loadingMessage() {
    if (this.isPostingBacktest) {
      return "Processing backtest"
    } else if (this.isLoadingBacktest) {
      return "Rendering backtest output"
    } else {
      return ""
    }
  }

  // USER

  @action("setActiveRoute")
  setActiveRoute(route) {
    this.activeRoute = route
  }

  @action
  setSelectedExpiration(expiration) {
    // do not select an expiration that is beyond our set of filtered expirations
    if (_.find(this.expirations, { expirDate: expiration })) {
      let dte = Moment(expiration).diff(Moment().startOf("day"), "days")
      this.selectedExpiration = { dte, expiration }
      this.selectedTradeId = 1
      this.fetchTopTrades()
    }
  }

  @action
  setSelectedUnderlying(underlying) {
    if (!has(underlying, "ticker")) {
      this.underlying = {}
      return
    }

    const fetchAllDataStart = performance.now()

    this.isLoadingHistory = true
    this.selectedExpiration = { dte: -1, expiration: null }
    this.outlook = { pctChg: 0.0, dollarValue: null }
    // this.outlookMousePos = [0,0]
    this.topTrades = {}
    this.implieds = null

    const { ticker } = underlying

    let promz = {
      general: api.fetchGeneral(ticker),
      all: api.fetchAll(ticker),
    }

    RSVP.hash(promz)
      .then(({ general, all: { history, implieds, historicalVol } }) => {
        const networkTotal = performance.now() - fetchAllDataStart
        console.info(`network took: ${networkTotal / 1000} s`)

        // Expitations and Implied Vol
        const expirations = map(
          tail(implieds),
          ([vol50, tradeDate, expirDate]) => ({
            // `expirations` contains all exps
            expirDate,
            date: Moment(expirDate).toDate(),
            tradeDate,
            dte: Moment(expirDate).diff(Moment().startOf("day"), "days"),
            vol50,
          })
        )

        this.expirations = reject(
          filter(expirations, (d) => util.isThirdFriday(d.expirDate)),
          (d) => d.dte < 0
        )

        if (!this.expirations.length) {
          this.expirations = reject(expirations, (d) => d.dte < 0)
        }

        // do not include expirations that extend beyond the available history
        let [earliestHistory] = history[1]
        const dteLimit = Moment().diff(earliestHistory, "days")
        if (dteLimit < _.last(this.expirations).dte) {
          this.expirations = _.reject(
            this.expirations,
            ({ dte }) => dte > dteLimit
          )
        }

        console.log(this.expirations)

        this.implieds = zipObject(
          map(expirations, "expirDate"),
          map(expirations, "vol50")
        ) // map: { 2017-04-28: 8.38, ... }

        // Historical Volatility
        this.historicalVol = reduce(
          tail(historicalVol),
          (result, [iv30d, orHv20d, tradeDate]) => {
            set(result, `${tradeDate}`, [iv30d, orHv20d])
            result[tradeDate] = [iv30d, orHv20d]
            return result
          },
          {}
        )

        // console.log(this.historicalVol)

        // Underlying
        const {
          clsStkPx: lastClose,
          dLast: currentPrice,
          stkVolu: stockVolume,
          divDate,
          divYield,
          divAmt,
          nextErn,
          daysToNextErn,
          dOpen: open,
          dLow: low,
          dHigh: high,
          dChange: change,
          dChangePct: changePct,
          dLast: yahooLast,
          fiftyTwoWeekRange,
          updatedAt,
        } = general

        // let fiftyTwoWeekRange = undefined
        // const backIndex = util.indexForDate(Moment().subtract(52, 'weeks'), this.ohlc)
        // if (backIndex !== -1) {
        //   const fiftyTwoWeekHist = slice(this.ohlc, backIndex)
        //   fiftyTwoWeekRange = [minBy(fiftyTwoWeekHist, 'stkPx').stkPx, maxBy(fiftyTwoWeekHist, 'stkPx').stkPx]
        // }

        // const previous = nth(this.history, -1)
        // const change = currentPrice - previous.stkPx
        // const changePct = (change / currentPrice) * 100

        this.underlying = Object.assign(underlying, {
          lastClose,
          currentPrice,
          updatedAt,
          change: parseFloat(change),
          changePct: parseFloat(trim(changePct, '"')),
          stockVolume,
          fiftyTwoWeekRange: fiftyTwoWeekRange.split(" - "),
          divDate,
          divYield,
          divAmt,
          nextErn,
          daysToNextErn,
          open,
          low,
          high,
          yahooLast,
        })

        // console.log(toJS(this.underlying))

        // in market hours we add today's ohlc
        // const todayHistKey = updatedAt.split(' ')[0]
        // const todayHistItem = ohlcVol[todayHistKey][0]
        // if (!has(todayHistItem, 'open')) { // rather than messing around with tz, let's just see if we have today market data available
        //   const tdy = Moment().startOf('day')
        //   const todayIntradayVals = {tradeDate:tdy.format("YYYY-MM-DD"), open, high, low, close:lastClose, stkPx:lastClose, date: tdy.toDate()}
        //   ohlcVol[tdy.format("YYYY-MM-DD")][0] = {...todayIntradayVals, todayHistItem}
        // }

        const todayCloseKey = updatedAt.split(" ")[0]
        let todayIntraDay = []
        let futureDayPaddingStart = Moment()
        if (last(history)[0] !== todayCloseKey) {
          console.log("* * * * adding today market to history * * * * ")
          todayIntraDay.push([
            todayCloseKey,
            open,
            high,
            low,
            currentPrice,
            currentPrice,
            0,
          ])
          futureDayPaddingStart.add(1, "d")
        }

        this.history = map(
          concat(tail(history), todayIntraDay),
          ([tradeDate, open, high, low, close, stkPx, vol]) => ({
            tradeDate,
            open,
            high,
            low,
            close,
            stkPx,
            vol,
            date: Moment(tradeDate).toDate(),
          })
        )

        // Price History
        let futureDayPadding = []
        const someTimeAway = Moment(futureDayPaddingStart).add(2, "years") // replace with last exp
        var nextDay = Moment(futureDayPaddingStart).startOf("day")
        while (nextDay.isBefore(someTimeAway)) {
          if (nextDay.isoWeekday() < 6) {
            const tradeDate = nextDay.format("YYYY-MM-DD")
            const hasExp =
              findIndex(expirations, (d) =>
                Moment(d.expirDate).isSame(nextDay)
              ) > -1
                ? tradeDate
                : null
            futureDayPadding.push({
              date: nextDay.toDate(),
              close: null,
              tradeDate,
              hasExp,
            })
          }
          nextDay.add(1, "days")
        }

        let ohlcVol = concat(this.history, futureDayPadding)
        ohlcVol = groupBy(ohlcVol, "tradeDate")
        this.ohlc = map(ohlcVol, spread(merge))

        this.topTrades = reduce(
          this.expirations,
          (obj, item) => set(obj, `${item.dte}`, []),
          {}
        )

        if (first(this.expirations).dte < 1) {
          this.setSelectedExpiration(this.expirations[1].expirDate)
        } else {
          this.setSelectedExpiration(this.expirations[0].expirDate)
        }

        this.isLoadingHistory = false
      })
      .catch((e) => {
        this.errors.push("There was an error loading data")
        this.logException(e)
      })
      .finally(() => {
        const fetchTimeTotal = performance.now() - fetchAllDataStart
        if (fetchTimeTotal >= 7000) {
          if (process.env.NODE_ENV === "production") {
            Raven.captureMessage("Data fetch slow: ", {
              level: "warning",
              extra: { ms: fetchTimeTotal },
            })
          }
          console.warn(`All data fetch took: ${fetchTimeTotal / 1000} s`)
        }
      })
  }

  @action
  chartPanEnded(xExtents) {
    this.userAdjustedXExtents = xExtents
  }

  @action
  setOutlook(pctChg, dollarValue) {
    if (pctChg !== this.outlook.pctChg) {
      this.outlook = { pctChg, dollarValue }
    }
  }

  @action
  reFetchTrades() {
    this.fetchTopTrades({ force: true })
  }

  fetchTopTrades = ({ force } = { force: false }) => {
    if (force) {
      console.log("==== WITH FORCE ====")
      this.topTrades[`${this.selectedExpiration.dte}`] = []
      this.selectedTradeId = 1
    }

    if (this.topTrades[`${this.selectedExpiration.dte}`].length < 1) {
      this.isLoadingTrades = true

      const { lastClose, ticker } = this.underlying

      api
        .fetchScannerResult(
          ticker,
          this.selectedExpiration.dte,
          this.selectedStrategies,
          this.selectedDistribution,
          lastClose
        )
        .then((trades) => {
          this.topTrades[`${this.selectedExpiration.dte}`] = trades
          if (trades.length === 0) {
            this.errors = ["No trades found"]
          }
          this.isLoadingTrades = false
        })
    } else {
      console.log("not gonna fetch")
    }
  }

  @action
  setChartHistoryDuration = (duration) => {
    this.chartHistoryDuration = duration
  }

  @action
  updateStategies = (key, value) => {
    this.availableStategies[key] = value
    this.persistStrategies()
  }

  @action("receiveChain")
  receiveChain(chain) {
    this.chain = chain
  }

  @action("logException")
  logException(ex, context) {
    if (process.env.NODE_ENV === "production") {
      Raven.captureException(ex, { extra: context })
    }
    console.error && console.error(ex)
  }

  persistStrategies = () => {
    console.log("PERSIST WHEEL_STRATEGIES")
    localStorage.setItem(
      "WHEEL_STRATEGIES",
      JSON.stringify(toJS(this.availableStategies))
    )
  }

  lookupStrategies = () => {
    return JSON.parse(localStorage.getItem("WHEEL_STRATEGIES"))
  }

  onMessage = ({ data }) => {
    console.log("* * * chain update received * * *")
    const [raw, stock_midpoint, calibration_stock_price] = data
    const c = map(raw, (d) => {
      const src = zipObject(CHAIN_PROPS, d.split(":"))
      let obj = reduce(
        src,
        (result, v, k) => {
          if (includes(["asksize", "bidsize", "option_volume"], k)) {
            result[k] = parseInt(v, 0)
          } else if (k === "ticker") {
            result[k] = v
          } else {
            result[k] = parseFloat(v)
          }
          return result
        },
        {}
      )
      const meta = trim(obj.ticker.split("  ")[1])
      return assign(obj, {
        type: meta.substring(6, 7),
        strike: parseInt(meta.substring(7), 0) / 1000,
        stock_midpoint,
        calibration_stock_price,
      })
    })
    this.receiveChain(c)
  }
}
