import colors from "./colors"

const breakpoints = [32, 48, 64, 80]

const space = [0, 4, 8, 16, 32, 64, 128]

const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72, 96]

const weights = [100, 700]

const widths = [340]

const letterSpacings = {
  normal: "normal",
  caps: "0.25em"
}

// border-radius
const radii = [0, 2, 4, 8]

const borderWidths = [0, 1, 2]

const shadows = [
  `0 3px 6px red`,
  `0 1px 2px ${colors.darken[2]}`,
  `0 1px 4px 0 ${colors.text}`
]

const radius = 4
const font = `-apple-system, BlinkMacSystemFont, sans-serif`
const monospace = '"SF Mono", "Roboto Mono", Menlo, monospace'

export default {
  breakpoints,
  space,
  fontSizes,
  weights,
  letterSpacings,
  radii,
  borderWidths,
  shadows,
  font,
  monospace,
  colors,
  radius,
  widths
}
