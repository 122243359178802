import React, { Component } from "react"
import { Search, Menu, List } from "semantic-ui-react"
import { observer, inject } from "mobx-react"
import symbols from "../../util/symbols"
import map from "lodash/map"
import findIndex from "lodash/findIndex"
import Moment from "moment"
import numeral from "numeral"
import Fuse from "fuse.js"

import { ListItem, Slug, UnderlyingInfoItem, Price } from "../Styled"

const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length
  let results =
    inputLength === 0
      ? []
      : symbols.filter(
          item =>
            item.name.toLowerCase().slice(0, inputLength) === inputValue ||
            item.ticker.toLowerCase().slice(0, inputLength) === inputValue
        )
  const fuse = new Fuse(results, {
    keys: [{ name: "ticker", weight: 0.7 }, { name: "name", weight: 0.3 }],
    shouldSort: true,
    location: 0,
    maxPatternLength: 32,
    tokenize: true,
    threshold: 0.0,
    minMatchCharLength: 2
  })
  results = fuse.search(inputValue)
  return map(results, item => {
    return { title: item.ticker, description: item.name }
  })
}

@inject("store")
@observer
export default class SymbolSlug extends Component {
  componentWillMount() {
    this.resetComponent()
  }

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" })

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title })
    this.props.store.setSelectedUnderlying({
      ticker: result.title,
      name: result.description
    })
    this.resetComponent()
  }

  handleSearchChange = (e, { value }) => {
    const ticker = value.toUpperCase()
    this.setState({ isLoading: true, value: ticker })
    let filteredResults = getSuggestions(value)
    if (
      filteredResults.length < 3 &&
      findIndex(filteredResults, { title: ticker }) === -1
    ) {
      filteredResults = [{ title: ticker }]
    }
    this.setState({
      isLoading: false,
      results: filteredResults
    })
  }

  render() {
    const { isLoading, value, results } = this.state
    const { store } = this.props

    const {
      underlying: {
        ticker,
        fiftyTwoWeekRange,
        name,
        currentPrice,
        updatedAt,
        divAmt,
        divYield,
        stockVolume,
        change,
        changePct
      }
    } = store
    const priceChangeText = `${numeral(change).format("0.0[0000]")} (${numeral(
      changePct
    ).format("0.00")}%)`
    const fiftyTwoWeekRangeText = fiftyTwoWeekRange
      ? `${numeral(fiftyTwoWeekRange[0]).format("$0,0.00")} - ${numeral(
          fiftyTwoWeekRange[1]
        ).format("$0,0.00")}`
      : ""

    return (
      <Menu.Menu>
        {ticker && (
          <Menu.Item fitted="vertically">
            <Search
              size="mini"
              open={results.length > 0}
              loading={isLoading}
              selectFirstResult
              onResultSelect={this.handleResultSelect}
              onSearchChange={this.handleSearchChange}
              results={results}
              value={value}
            />
          </Menu.Item>
        )}
        <Menu.Item fitted>
          {name &&
            ticker && (
              <List style={{ margin: 0, paddingLeft: 12 }}>
                <ListItem>
                  <UnderlyingInfoItem>
                    <strong>{name}</strong>
                  </UnderlyingInfoItem>
                  <Slug>{ticker}</Slug>
                </ListItem>
                <ListItem>
                  <UnderlyingInfoItem>
                    <strong>{`${numeral(currentPrice).format("$0.00") ||
                      ""} `}</strong>
                    <Price changePct={changePct}>{priceChangeText}</Price>
                  </UnderlyingInfoItem>
                  <Slug>
                    As of{" "}
                    <time dateTime="2016-08-20T16:30:00-06:00">
                      {Moment(updatedAt).format("MMM Do, h:mm a")}
                    </time>
                  </Slug>
                </ListItem>
                <ListItem>
                  <UnderlyingInfoItem>
                    {fiftyTwoWeekRangeText}
                  </UnderlyingInfoItem>
                  <Slug>52 week range</Slug>
                </ListItem>
                <ListItem>
                  <UnderlyingInfoItem>{`${numeral(divAmt).format(
                    "$0.00"
                  )} ${numeral(divYield).format("0.00")}%`}</UnderlyingInfoItem>
                  <Slug>Div/Yield</Slug>
                </ListItem>
                <ListItem>
                  <UnderlyingInfoItem>
                    {numeral(stockVolume).format("0,0")}
                  </UnderlyingInfoItem>
                  <Slug>Volume</Slug>
                </ListItem>
              </List>
            )}
        </Menu.Item>
      </Menu.Menu>
    )
  }
}
