import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"
import { Grid, Dropdown, Message, List, Table } from "semantic-ui-react"
import map from "lodash/map"
import delay from "lodash/delay"
import includes from "lodash/includes"
import findIndex from "lodash/findIndex"
import slice from "lodash/slice"
import numeral from "numeral"

class TickItem extends Component {
  saveNode = node => {
    this.node = node
  }
  componentWillUpdate(nextProps) {
    if (nextProps.tickValue !== this.props.tickValue) {
      if (nextProps.tickValue < this.props.tickValue) {
        this.node.classList.remove("tick-up")
        this.node.classList.add("tick-down")
      } else {
        this.node.classList.remove("tick-down")
        this.node.classList.add("tick-up")
      }
      this.node.classList.add(
        nextProps.tickValue < this.props.tickValue
          ? "flash-text-red"
          : "flash-text-green"
      )
      delay(() => {
        if (this.node) {
          this.node.classList.remove("flash-text-red")
          this.node.classList.remove("flash-text-green")
        }
      }, 1000)
    }
  }
  render() {
    const { tickValue, format, col } = this.props
    let text = numeral(tickValue).format(format)
    if (
      includes(
        ["implied_vol_bid", "implied_vol_midpoint", "implied_vol_ask"],
        col
      )
    ) {
      text = text.length > 7 ? "--" : text
    }
    return <div ref={this.saveNode}>{text}</div>
  }
}

const isInMoney = ({ stock_midpoint, strike, type }) => {
  return (
    (stock_midpoint && type === "C" && strike < stock_midpoint) ||
    (stock_midpoint && type === "P" && strike > stock_midpoint)
  )
}

const TableRow = ({ data: { strike, call, put } }) => {
  const callStyle = { backgroundColor: isInMoney(call) ? "#D5EDDF" : "white" }
  const putStyle = { backgroundColor: isInMoney(put) ? "#D5EDDF" : "white" }
  return (
    <Table.Row textAlign="center">
      <Table.Cell style={callStyle}>
        <TickItem option={call} format="0.00" tickValue={call.smv_theo_value} />
      </Table.Cell>
      <Table.Cell style={callStyle}>
        <TickItem option={call} format="0.00" tickValue={call.smv_theo_vol} />
      </Table.Cell>
      <Table.Cell style={callStyle}>
        <TickItem option={call} format="0.00" tickValue={call.smv_delta} />
      </Table.Cell>
      <Table.Cell style={callStyle}>
        <TickItem option={call} format="0,0" tickValue={call.bidsize} />
      </Table.Cell>
      <Table.Cell style={callStyle}>
        <TickItem option={call} format="0,0" tickValue={call.asksize} />
      </Table.Cell>
      <Table.Cell style={callStyle}>
        <TickItem option={call} format="0.00" tickValue={call.bid} />
      </Table.Cell>
      <Table.Cell style={callStyle}>
        <TickItem option={call} format="0.00" tickValue={call.ask} />
      </Table.Cell>
      <Table.Cell>{strike}</Table.Cell>
      <Table.Cell style={putStyle}>
        <TickItem option={put} format="0.00" tickValue={put.bid} />
      </Table.Cell>
      <Table.Cell style={putStyle}>
        <TickItem option={put} format="0.00" tickValue={put.ask} />
      </Table.Cell>
      <Table.Cell style={putStyle}>
        <TickItem option={put} format="0,0" tickValue={put.bidsize} />
      </Table.Cell>
      <Table.Cell style={putStyle}>
        <TickItem option={put} format="0,0" tickValue={put.asksize} />
      </Table.Cell>
      <Table.Cell style={putStyle}>
        <TickItem option={put} format="0.00" tickValue={put.smv_delta} />
      </Table.Cell>
      <Table.Cell style={putStyle}>
        <TickItem option={put} format="0.00" tickValue={put.smv_theo_vol} />
      </Table.Cell>
      <Table.Cell style={putStyle}>
        <TickItem option={put} format="0.00" tickValue={put.smv_theo_value} />
      </Table.Cell>
    </Table.Row>
  )
}

@inject("store")
@observer
export default class OptionChain extends Component {
  componentWillMount() {
    this.props.store.setActiveRoute("chain")
  }

  onExpirationChange = (e, { value }) => {
    this.props.store.setSelectedExpiration(value)
  }

  render() {
    const {
      chainDataSource: { puts, calls, strikes },
      expirations,
      selectedExpiration: { expiration: selectedExpiration },
      errors
    } = this.props.store
    let tableData = []
    if (strikes.length > 0) {
      tableData = map(strikes, (d, i) => ({
        strike: d,
        call: calls[i],
        put: puts[i]
      }))
      const itmIndex = findIndex(strikes, s => s > calls[0].stock_midpoint)
      tableData = slice(tableData, itmIndex - 10, itmIndex + 10)
    }
    // const cols = [
    //   {col:"smv_delta", format:"0.00", label:"smv_delta"},
    //   {col:"smv_theo_vol", format:"0.00", label:"smv_theo_vol"},
    //   {col:"smv_theo_value", format:"0.00", label:"smv_theo_value"},
    //   {col:"bidsize", format:"0,0", label:"Bid Size"},
    //   {col:"asksize", format:"0,0", label:"Ask Size"},
    //   {col:"option_volume", format:"0,0", label:"Opt Volume"},
    //   {col:"implied_vol_bid", format:"0.00%", label:"Impl Vol Bid"},
    //   {col:"implied_vol_midpoint", format:"0.00%", label:"Impl Vol Mid"},
    //   {col:"implied_vol_ask", format:"0.00%", label:"Impl Vol Ask"},
    //   {col:"calibration_stock_price", format:"$0,0.00", label:"Clbr Stk Prc"},
    //   {col:"calibration_option_bid", format:"0.00", label:"Clbr Opt Bid"},
    //   {col:"calibration_option_ask", format:"0.00", label:"Clbr Opt Bid"},
    //   {col:"calibration_theo_value", format:"0.00", label:"Clbr Theo Val"},
    //   {col:"calibration_theo_forecast", format:"0.00", label:"Clbr Theo Frcst"},
    //   {col:"calibration_vol_bid", format:"0,0", label:"Clbr Volume Bid"},
    //   {col:"calibration_vol_midpoint", format:"0,0", label:"Clbr Volume Mid"},
    //   {col:"calibration_vol_ask", format:"0,0", label:"Clbr Volume Ask"},
    //   {col:"delta", format:"0.00", label:"delta"},
    //   {col:"gamma", format:"0.00", label:"gamma"},
    //   {col:"theta", format:"0.00", label:"theta"},
    //   {col:"vega", format:"0.00", label:"vega"},
    //   {col:"rho", format:"0.00", label:"rho"}
    // ]
    const expirationOptions = map(expirations, d => ({
      key: d.expirDate,
      value: d.expirDate,
      text: d.expirDate
    }))
    const stockMidpoint =
      calls.length > 0
        ? numeral(calls[0].stock_midpoint).format("$0,0.00") || ""
        : ""
    return (
      <Grid padded>
        <Grid.Column>
          {errors.length > 0 && (
            <Message negative>
              <Message.Header>An Error Occured</Message.Header>
              <List items={toJS(errors)} />
            </Message>
          )}
          <Table
            compact="very"
            celled
            style={{ fontFamily: '"Roboto", sans-serif' }}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell style={{ padding: 6 }} colSpan="7">
                  Calls
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  {stockMidpoint}
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }} colSpan="7">
                  Puts
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row textAlign="center">
                <Table.HeaderCell style={{ padding: 6 }}>
                  SMV Theo Value
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  SMV Theo Vol
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  SMV Delta
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  Ask Size
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  Bid Size
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>Bid</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>Ask</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6, width: 140 }}>
                  <Dropdown
                    compact
                    selection
                    options={expirationOptions}
                    value={selectedExpiration}
                    onChange={this.onExpirationChange}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>Bid</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>Ask</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  Ask Size
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  Bid Size
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  SMV Delta
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  SMV Theo Vol
                </Table.HeaderCell>
                <Table.HeaderCell style={{ padding: 6 }}>
                  SMV Theo Value
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {errors.length === 0 &&
              strikes.length > 0 &&
              selectedExpiration && (
                <Table.Body>
                  {map(tableData, (d, i) => <TableRow key={i} data={d} />)}
                </Table.Body>
              )}
          </Table>
        </Grid.Column>
      </Grid>
    )
  }
}
