import React from "react"
import { inject } from "mobx-react"
import { withRouter, Switch, Route, Redirect } from "react-router-dom"
// import { Auth } from "aws-amplify"

import Backtest from "./Backtest"
import Landing from "./Landing"
import Video from "./Landing/Video"
import Scan from "./Scan"
import Monitor from "./Monitor"
import ShareableReport from "./Backtest/ShareableReport"
import Pricing from "./Pricing"
import OptionChain from "./OptionChain"
import Positions from "./Positions"
import Auth from "./Auth"
import Callback from "./Auth/Callback"
import Authenticator from "./Auth/Authenticator"

const auth = new Authenticator()

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication()
  }
}

@withRouter
@inject("strategyStore", "userStore")
class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
  }

  async componentDidMount() {
    const { userStore, strategyStore } = this.props
    const isAuthenticated = auth.isAuthenticated()
    if (isAuthenticated) {
      const email = localStorage.getItem("email")
      await userStore.loadCurrentUser(email)
      await Promise.all([strategyStore.loadGlobals()])
      strategyStore.appInit()
    }
    this.setState({ loaded: true, isAuthenticated })
  }

  render() {
    const { component: Component, ...rest } = this.props
    const { loaded, isAuthenticated } = this.state
    if (!loaded) return null
    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth",
              }}
            />
          )
        }}
      />
    )
  }
}

export const Routes = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={(props) => <Landing auth={auth} {...props} />}
    />
    <Route path="/auth" render={(props) => <Auth auth={auth} {...props} />} />
    <Route exact path="/video" component={Video} />
    <Route path="/bt/:id" render={(props) => <ShareableReport {...props} />} />
    <Route
      path="/callback"
      render={(props) => {
        handleAuthentication(props)
        return <Callback {...props} />
      }}
    />
    <Route path="/scan" component={Scan} />
    <PrivateRoute path="/monitor" component={Monitor} />
    <PrivateRoute path="/chain" component={OptionChain} />
    <PrivateRoute path="/pricing" component={Pricing} />
    <PrivateRoute path="/positions" component={Positions} />
    <PrivateRoute path="/backtest" component={Backtest} />
  </Switch>
)
