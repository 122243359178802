import React, { Component } from "react"
import { toJS } from "mobx"
import { observer, inject } from "mobx-react"
import { Table, Icon, Label, Dropdown } from "semantic-ui-react"
import { Link } from "react-router-dom"

import map from "lodash/map"

import util from "../../util"

const rb = ({ id, name }, i) => {
  return (
    <Table.Row key={`row-${i}`}>
      <Table.Cell content={id} />
      <Table.Cell content={name} />
    </Table.Row>
  )
}

@inject("store", "strategyStore")
@observer
export default class MonitorList extends Component {
  onStrategyRemove = (e, { name: key }) => {
    this.props.store.updateStategies(key, false)
  }

  onStrategyAdd = (e, { value: key }) => {
    this.props.store.updateStategies(key, true)
  }

  render() {
    const { strategyStore, store } = this.props
    const { scans } = strategyStore
    const { selectedStrategies, userStrategyOptions } = store

    return (
      <div>
        {map(selectedStrategies, (k, i) => (
          <Label
            key={i}
            name={k}
            content={util.strategyNamesMap[k]}
            onRemove={this.onStrategyRemove}
          />
        ))}
        <Label color="teal">
          <Icon name="add" />
          <Dropdown
            search
            label
            labeled
            options={userStrategyOptions}
            onChange={this.onStrategyAdd}
            text="Strategy"
          />
        </Label>
        <Link style={{ marginLeft: 4 }} to="/scan/strategies">
          Edit
        </Link>
        <Table
          compact
          headerRow={["Id", "Name"]}
          tableData={toJS(scans)}
          renderBodyRow={rb}
        />
      </div>
    )
  }
}
