import React from "react"
import { Route, Switch } from "react-router-dom"
import { Box } from "rebass"
import List from "./List"
import Report from "./Report"
import Form from "./Form"
import NewForm from "./NewForm"

export default props => (
  <Box p={3}>
    <Switch>
      <Route exact path="/backtest" render={props => <List {...props} />} />
      <Route
        exact
        path="/backtest/create"
        render={props => <Form {...props} />}
      />
      <Route
        exact
        path="/backtest/ncreate"
        render={props => <NewForm {...props} />}
      />
      <Route
        path="/backtest/create/:id"
        render={props => <Form {...props} />}
      />
      <Route
        path="/backtest/ncreate/:id"
        render={props => <NewForm {...props} />}
      />
      <Route path="/backtest/:id" render={props => <Report {...props} />} />
    </Switch>
  </Box>
)
