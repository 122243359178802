import React, { Component } from "react"
import { Accordion, List, Icon } from "semantic-ui-react"
import { observer, inject } from "mobx-react"
import _ from "lodash"
import { ValueFormatterService } from "../../../node_modules/ag-grid"

@inject("strategyStore")
@observer
export default class FilterHits extends Component {
  state = { activeIndex: null, bigMovers: [], corporateEvents: [] }

  async componentWillMount() {
    const { strategyStore } = this.props
    const tickers = _.keys(strategyStore.symbolQtyMap)
    if (tickers.length) {
      let [
        { data: corporateEvents },
        { data: bigMovers }
      ] = await strategyStore.getFilterHits()

      bigMovers = _.pick(bigMovers, tickers)
      bigMovers = _.map(bigMovers, (value, key) => {
        const text = _.map(value, v => `[${_.values(v).join(", ")}]`).join(", ")
        return `${key}: ${text}`
      })

      corporateEvents = _.map(corporateEvents, (value, key) => {
        const text = _.reverse(value).join(", ")
        return `${key}: ${text}`
      })
      this.setState({ bigMovers, corporateEvents })
    }

    // console.log(bigMovers)
    // console.log(corporateEvents)
    // console.log(bigMovers)
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex, bigMovers, corporateEvents } = this.state
    return (
      <div>
        {bigMovers.length > 0 || corporateEvents.length > 0 ? (
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}>
              <Icon name="dropdown" />
              Big Movers
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <List bulleted items={bigMovers} />
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.handleClick}>
              <Icon name="dropdown" />
              Corporate Events
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <List bulleted items={corporateEvents} />
            </Accordion.Content>
          </Accordion>
        ) : null}
      </div>
    )

    // return <div>yo</div>
    // <List items={["Apples", "Pears", "Oranges"]} />
  }
}
