import { superstruct } from "superstruct"
import moment from "moment"
import _ from "lodash"

const struct = superstruct({
  types: {
    date: v => {
      if (!moment(v, "YYYY-MM-DD", true).isValid()) return `not_valid_date`
      return true
    },
    minLtMax: v => {
      const { min, max } = v
      if (min === null && max === null) return true
      if (min && max) {
        if (max < min) return `max_lt_min`
      }
      return true
    },
    minLtMaxTargetInBetween: v => {
      const { target, min, max } = v
      if (min === null && max === null) return true
      if (min && max) {
        if (min > max) return `min_gt_max`
        if (max < min) return `max_lt_min`
      }
      if (min && target < min) return `target_lt_min`
      if (max && target > max) return `target_gt_max`
      return true
    },
    float: v => {},
    integer: v => {}
  }
})

const ABSDELTA_OR_STOCKOTMPCT = ["absDelta", "stockOTMPct"]

const MinMax = struct({
  min: "number | null",
  max: "number | null"
})

const TargetMinMax = struct({
  target: "number | null",
  min: "number | null",
  max: "number | null"
})

const minMax = () => struct.intersection([MinMax, "minLtMax"])
const targetMinMax = () => struct.intersection([TargetMinMax, "minLtMax"])

const proportional = ({ target, min, max }) => {
  if (min === null && max === null) return true
  if (min && min < 0) return `min_below_range_0`
  if (min && min > 1) return `min_above_range_1`
  if (max && max < 0) return `max_below_range_0`
  if (max && max > 1) return `max_above_range_1`
  if (target !== undefined) {
    if (target && target < 0) return `target_below_range_0`
    if (target && target > 1) return `target_above_range_1`
  }
  return true
}

// 'general' node
const Symbol = struct({
  symbol: "string",
  weight: "number | null",
  signals: struct.union([
    [
      struct({
        entryDate: "date",
        exitDate: "date"
      })
    ],
    "null"
  ])
})

const General = struct({
  strategyName: "string",
  backtestName: "string | null",
  stockPosition: {
    type: struct.enum([null, "overlay", "married"]),
    ratio: struct.enum([1, 0, -1])
  },
  startDate: (value, data) => {
    if (!moment(value, "YYYY-MM-DD", true).isValid()) return `not_valid_date`
    if (moment(value).isAfter(data.endDate)) return `start_after_end`
    return true
  },
  endDate: (value, data) => {
    if (!moment(value, "YYYY-MM-DD", true).isValid()) return `not_valid_date`
    if (moment(value).isBefore(data.startDate)) return `end_before_start`
    return true
  },
  symbols: [Symbol],
  exitAtSignal: "boolean | null",
  standardExpiration: "boolean | null",
  returnType: {
    perTrade: struct.enum(["notional", "margin"]),
    daily: struct.enum(["average", "compound"])
  },
  commission: {
    option: "number",
    stock: "number"
  }
})

// 'entry' node
const Adjustment = struct({
  daysForAdjusting: struct.intersection([
    minMax(),
    struct(({ min, max }) => {
      if (min === null && max === null) return true
      if (min && min < 1) return `min_below_range_1`
      if (min && min > 1000) return `min_above_range_1000`
      if (max && max < 1) return `max_below_range_1`
      if (max && max > 1000) return `max_above_range_1000`
      return true
    })
  ]),
  rollWithLeg: "number | null",
  trigger: {
    type: struct.enum(ABSDELTA_OR_STOCKOTMPCT),
    value: struct.intersection([minMax(), struct(v => proportional(v))]),
    tiedTo: struct.intersection([
      struct({
        leg: "number | null",
        min: "number | null",
        max: "number | null"
      }),
      "minLtMax",
      struct(v => proportional(v))
    ])
  },
  dte: targetMinMax(),
  strikeSelection: {
    type: struct.enum(ABSDELTA_OR_STOCKOTMPCT),
    value: struct.intersection([targetMinMax(), struct(v => proportional(v))])
  }
})

const Option = struct({
  leg: "number",
  ratio: "number",
  optionType: struct.enum(["call", "put"]),
  iBidVol: struct.intersection([
    minMax(),
    struct(({ min, max }) => {
      if (min === null && max === null) return true
      if (min && min < 3) return `min_below_range_3`
      if (min && min > 500) return `min_above_range_500`
      if (max && max < 3) return `max_below_range_3`
      if (max && max > 500) return `max_above_range_500`
      return true
    })
  ]),
  iAskVol: struct.intersection([
    minMax(),
    struct(({ min, max }) => {
      if (min === null && max === null) return true
      if (min && min < 3) return `min_below_range_3`
      if (min && min > 500) return `min_above_range_500`
      if (max && max < 3) return `max_below_range_3`
      if (max && max > 500) return `max_above_range_500`
      return true
    })
  ]),
  optionBid: minMax(),
  optionAsk: minMax(),
  opening: struct({
    dte: struct.intersection([
      struct({
        target: "number",
        min: "number | null",
        max: "number | null"
      }),
      "minLtMaxTargetInBetween"
    ]),
    strikeSelection: {
      type: struct.enum(ABSDELTA_OR_STOCKOTMPCT),
      value: struct.intersection([
        struct({
          target: "number",
          min: "number | null",
          max: "number | null"
        }),
        "minLtMaxTargetInBetween"
      ])
    }
  }),
  reEnter: struct.union([
    struct({
      dte: targetMinMax(),
      strikeSelection: {
        type: struct.enum(ABSDELTA_OR_STOCKOTMPCT),
        value: targetMinMax()
      }
    }),
    "null"
  ]),
  adjustment: struct.union([
    "null",
    struct.intersection([
      Adjustment,
      struct(({ trigger, strikeSelection }) => {
        const triggerSet =
          trigger.value.min !== null || trigger.value.max !== null
        const strikeSelectionNotSet =
          _.filter(strikeSelection.value, v => v === null).length > 0
        if (triggerSet && strikeSelectionNotSet)
          return "strike_selection_not_set"
        return true
      })
    ])
  ]) // null needs to go first here!
})

const Entry = struct({
  entryDays: "number | null",
  contractSize: "number",
  mktWidthPct: minMax(),
  absCpDiffStkPxRatioMax: "number | null",
  stock: {
    iVRank: minMax(),
    entryDaysToEarn: minMax()
  },
  options: [Option],
  spread: {
    price: targetMinMax(),
    delta: targetMinMax(),
    yieldPct: minMax()
  },
  legRelation: {
    strikeWidth: {
      leg1Leg2: minMax(),
      leg2Leg3: minMax(),
      leg3Leg4: minMax()
    },
    deltaTotal: {
      leg1Leg2: minMax(),
      leg2Leg3: minMax(),
      leg3Leg4: minMax()
    },
    dteDiff: {
      leg1Leg2: minMax(),
      leg2Leg3: minMax(),
      leg3Leg4: minMax()
    }
  }
})

// 'exit' node
const Exit = struct({
  dteDays: struct.union(["number", struct.enum(["expire"])]),
  holdDays: "number | null",
  bizDaysEarn: struct.union([
    struct({
      type: struct.enum(["before", "after"]),
      days: "number"
    }),
    "null"
  ]),
  options: struct.union([
    [
      struct({
        leg: "number",
        trigger: {
          type: struct.enum(ABSDELTA_OR_STOCKOTMPCT),
          value: minMax()
        }
      })
    ],
    "null"
  ]),
  spread: {
    price: minMax(),
    profitLossPct: struct.intersection([
      minMax(),
      struct(({ min, max }) => {
        if (min === null && max === null) return true
        if (min && min < -500) return `min_below_range_-500`
        if (min && min > 500) return `min_above_range_500`
        if (max && max < 0) return `max_below_range_0`
        if (max && max > 500) return `max_above_range_500`
        return true
      })
    ]),
    strikeTrigger: {
      type: struct.enum(["absDelta", "delta"]),
      value: minMax()
    },
    strikeDiffPctValue: minMax()
  }
})

export const Backtest = struct({
  general: General,
  entry: Entry,
  exit: Exit,
  hedge: struct({
    days: "number | null",
    deltaTolerance: minMax()
  })
})
