import React from "react"
// import { hot } from "react-hot-loader"

class Auth extends React.Component {
  componentDidMount() {
    // console.log(this.props.auth)
    this.props.auth.login()
  }
  render() {
    return null
  }
}

export default Auth
