import React, { Component } from "react"
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Icon,
  Header,
  Loader,
  Message,
  Segment
} from "semantic-ui-react"
import scriptLoader from "react-async-script-loader"
import { observer, inject } from "mobx-react"
import { BT_FEATURES } from "../../constants"
import { STRIPE_PK } from "../../config"

import { track, identify } from "../../services/analytics"
import { PlanHeader } from "../Styled"
import { observable } from "mobx"
import map from "lodash/map"
import capitalize from "lodash/capitalize"
// import { Link } from "react-router-dom"

const PLAN_PRICES = { FREE: 0, BASIC: 9900, PRO: 29900 }

@scriptLoader("https://checkout.stripe.com/checkout.js")
@inject("userStore")
@observer
export default class Pricing extends Component {
  @observable isLoading = false
  @observable errors = null
  @observable isStripeLoaded = false
  @observable selectedPlan = null

  stripeHandler = null

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      if (isScriptLoadSucceed) {
        this.stripeHandler = window.StripeCheckout.configure({
          key: STRIPE_PK,
          image: "/images/orats.png",
          token: this.tokenHandler
        })
        this.isStripeLoaded = true
      }
    }
  }

  talkSales = (e, data) => {
    window.location = "mailto:support@orats.com"
  }

  choosePlanHandler = (plan = null, data) => {
    this.selectedPlan = plan

    // TODO: let's have the User store hold user profile (keep it out of localStorage)
    // const { userStore: { stripeCustomerId } } = this.props
    const { email, stripeCustomerId } = this.props.userStore.currentUser

    if (!stripeCustomerId) {
      console.log("no stripeCustomerId: display Checkout")
      this.stripeHandler.open({
        name: "Wheel",
        description: `Subscription to the Wheel ${capitalize(plan)} plan`,
        email: email,
        allowRememberMe: false,
        amount: plan === "BASIC" ? 9900 : 29900 //this.planAmount //BT_UNIT_PRICE * BT_UNIT_VOLUME
      })
    } else {
      this.processSubscription(stripeCustomerId)
    }
  }

  tokenHandler = async token => {
    const { id } = token
    this.isLoading = true
    const stripeCustomerId = await this.props.userStore.createStripeCustomer(id)
    this.processSubscription(stripeCustomerId)
  }

  processSubscription = async stripeCustomerId => {
    this.isLoading = true
    try {
      const { id, email } = this.props.userStore.currentUser
      await this.props.userStore.processSubscription(
        stripeCustomerId,
        this.selectedPlan
      )
      const toPlan = this.selectedPlan || "FREE"
      const value = PLAN_PRICES[toPlan]
      track("Process Subscription", { toPlan, value })
      identify(id, { plan: toPlan, email })
    } catch (e) {
      console.log(e)
      this.errors = ["There was an error processing your subscription"]
    } finally {
      this.isLoading = false
    }
  }

  handleDismissError = (e, data) => {
    this.errors = null
  }

  render() {
    const {
      userStore: { isFreePlan, isBasicPlan, isProPlan, isEnterprisePlan }
    } = this.props

    return (
      <Container style={{ marginTop: 14 }} textAlign="center">
        <Dimmer active={this.isLoading} page inverted>
          <Loader>Processing subscription...</Loader>
        </Dimmer>

        {this.errors && (
          <Message error onDismiss={this.handleDismissError}>
            <Message.Header content={this.errors[0]} />
            <p>
              Please contact&nbsp;
              <a href="mailto:support@orats.com?Subject=Payment%20processing%20issue">
                support@orats.com
              </a>
              &nbsp;if problems persist
            </p>
          </Message>
        )}

        <Header
          style={{ fontSize: "2em", fontWeight: 100 }}
          content="Pricing"
          textAlign="center"
          as="h1"
          subheader="Plans that scale with you or your organization's needs"
        />

        <Grid style={{ marginTop: 30, marginBottom: 30 }} columns="equal">
          <Grid.Column>
            <Segment.Group piled>
              <Segment textAlign="center">
                <span>FREE</span>
                <PlanHeader>Free</PlanHeader>
              </Segment>

              <Segment textAlign="center">One Symbol</Segment>
              <Segment textAlign="center">
                <Button
                  style={{ fontWeight: 100 }}
                  disabled={isFreePlan}
                  // color={isFreePlan ? null : "teal"}
                  content={isFreePlan ? "CURRENT PLAN" : "CHOOSE PLAN"}
                  onClick={this.choosePlanHandler.bind(null, null)}
                />
              </Segment>
            </Segment.Group>
          </Grid.Column>
          <Grid.Column>
            <Segment.Group piled>
              <Segment textAlign="center">
                <span>BASIC</span>
                <PlanHeader>
                  $99
                  <span style={{ fontSize: 18, color: "rgba(0,0,0,.6)" }}>
                    /mo
                  </span>
                </PlanHeader>
              </Segment>
              <Segment textAlign="center">
                Non-professional subscribers only
              </Segment>
              <Segment textAlign="center">Unlimited Symbols</Segment>
              <Segment textAlign="center">
                <Button
                  style={{ fontWeight: 100 }}
                  disabled={isBasicPlan}
                  data-plan="BASIC"
                  color={isBasicPlan ? "teal" : "teal"}
                  content={isBasicPlan ? "CURRENT PLAN" : "CHOOSE PLAN"}
                  onClick={this.choosePlanHandler.bind(null, "BASIC")}
                />
              </Segment>
            </Segment.Group>
          </Grid.Column>
          <Grid.Column>
            <Segment.Group piled>
              <Segment textAlign="center">
                <span>PRO</span>
                <PlanHeader>
                  $299
                  <span style={{ fontSize: 18, color: "#616A6B" }}>/mo</span>
                </PlanHeader>
              </Segment>
              <Segment textAlign="center">
                Professional subscribers or non-professional subscribers wanting
                access to additional features
              </Segment>
              <Segment textAlign="center">Unlimited Symbols</Segment>
              <Segment textAlign="center">
                Combine multiple backtests into one report
              </Segment>
              <Segment textAlign="center">
                Apply signal dates to enter and exit trades
              </Segment>
              <Segment textAlign="center">
                <Button
                  style={{ fontWeight: 100 }}
                  disabled={isProPlan}
                  color={isProPlan ? null : "teal"}
                  content={isProPlan ? "CURRENT PLAN" : "CHOOSE PLAN"}
                  data-plan="PRO"
                  onClick={this.choosePlanHandler.bind(null, "PRO")}
                />
              </Segment>
            </Segment.Group>
          </Grid.Column>

          <Grid.Column>
            <Grid.Column>
              <Segment.Group piled>
                <Segment textAlign="center">
                  <Header as="h4" icon>
                    <Icon name="building outline" />
                  </Header>
                  <div>ENTERPRISE</div>
                </Segment>
                <Segment textAlign="center">
                  <p>
                    We offer custom <br />
                    packages tailored to suit <br />
                    your specific requirements
                  </p>
                </Segment>
                <Segment textAlign="center">
                  <Button
                    style={{ fontWeight: 100 }}
                    color="blue"
                    disabled={isEnterprisePlan}
                    content={isEnterprisePlan ? "CURRENT PLAN" : "CONTACT US"}
                    onClick={this.talkSales}
                  />
                  {!isEnterprisePlan && (
                    <div style={{ marginTop: 4 }}>
                      Or call <a href="tel:1-312-986-1060">(312) 986-1060</a>
                    </div>
                  )}
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid.Column>
        </Grid>

        <Header
          style={{ fontSize: "2em", fontWeight: 100 }}
          content="Core features"
          textAlign="center"
          as="h1"
          subheader="In all plans, including Free"
        />
        <Container text>
          {map(BT_FEATURES, (v, k) => (
            <div
              style={{
                paddingBottom: "1em",
                whiteSpace: "pre-line"
              }}
              key={k}>
              {v}
            </div>
          ))}
        </Container>
      </Container>
    )
  }
}

// edit card info: https://stripe.com/docs/recipes/updating-customer-cards
