import gql from "graphql-tag"
import { print } from "graphql/language/printer"

export const GET_USER = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      plan
      acknowledgedTerms
    }
  }
`

export const GET_USER_BY_EMAIL = gql`
  query GetUser($email: String!) {
    getUserByEmail(email: $email) {
      id
      email
      plan
      acknowledgedTerms
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      acknowledgedTerms
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $stripeCustomerId: String, $plan: String) {
    updateUser(
      input: { id: $id, stripeCustomerId: $stripeCustomerId, plan: $plan }
    ) {
      id
      plan
      stripeCustomerId
    }
  }
`

export const GET_USER_BACKTESTS = gql`
  query GetUserBacktests($ownerId: String!, $count: Int, $nextToken: String) {
    getUserBacktestsLam(
      ownerId: $ownerId
      status_not: ARCHIVED
      count: $count
      nextToken: $nextToken
    ) {
      backtests {
        id
        name
        form
        status
        stats
        messages
        createdAt
      }
      nextToken
    }
  }
`

export const GET_USER_BACKTESTS_COUNT = gql`
  query GetUserBacktestsCount($ownerId: String!) {
    getUserBacktestsCount(ownerId: $ownerId, status_not: ARCHIVED) {
      count
    }
  }
`

// NOTE: !!! subscriptions will not fire if you don't return ownerId in the results !!!
export const CREATE_BACKTEST = gql`
  mutation CreateBacktest($input: CreateBacktestInput!) {
    createBacktest(input: $input) {
      id
      ownerId
      name
      status
      form
      stats
      messages
      createdAt
    }
  }
`

export const GET_BACKTEST = gql`
  query GetBacktest($id: ID!) {
    getBacktest(id: $id) {
      id
      name
      status
      stats
      form
      messages
      createdAt
    }
  }
`

export const GET_COMBINATION = gql`
  query GetBacktest($id: ID!) {
    getBacktest(id: $id) {
      id
      form
    }
  }
`

export const GET_BACKTEST_NAME = gql`
  query GetBacktest($id: ID!) {
    getBacktest(id: $id) {
      id
      name
    }
  }
`

export const GET_BACKTEST_STATS = gql`
  query GetBacktest($id: ID!, $ownerId: String!) {
    getBacktest(id: $id, ownerId: $ownerId) {
      id
      stats
    }
  }
`

export const GET_BACKTEST_TRADES = gql`
  query GetBacktest($id: ID!) {
    getBacktestTrades(id: $id) {
      items {
        Date
        Ticker
        Leg
        Ratio
        OptionType
        Strike
        DTE
        TradeOptPx
        Delta
        EntryStockPx
        ExitDate
        ExitStockPx
        ExitOptionPx
        ExpirDate
        Profit
        TradeType
      }
    }
  }
`

export const GET_BACKTEST_REPORT = gql`
  query GetBacktest($id: ID!) {
    getBacktestReport(id: $id) {
      meta
      startYear
      perYearData
      stockReturns
      optReturns
    }
  }
`

export const GET_BACKTEST_FORM = gql`
  query GetBacktest($id: ID!, $format: String) {
    getBacktestForm(id: $id, format: $format) {
      form
    }
  }
`

export const UPDATE_BACKTEST = gql`
  mutation UpdateBacktest($input: UpdateBacktestInput!) {
    updateBacktest(input: $input) {
      id
      status
      messages
      stats
    }
  }
`

// WAITING ON SUBSCRIPTION SOLUTION
export const UPDATE_BACKTEST_SUBSCRIPTION = gql`
  subscription updatedBacktest($ownerId: String!) {
    updatedBacktest(ownerId: $ownerId) {
      id
      status
      messages
      stats
    }
  }
`

export const CREATE_BACKTEST_SUBSCRIPTION = gql`
  subscription createdBacktest($ownerId: String!) {
    createdBacktest(ownerId: $ownerId) {
      id
      status
    }
  }
`

export const GET_BACKTEST_DATA_ZIP = gql`
  query GetBacktest($id: ID!) {
    getBacktestDataZip(id: $id) {
      data
    }
  }
`

// HOLDINGS

export const GET_HOLDINGS = gql`
  query GetUserHoldings($ownerId: String!, $count: Int, $nextToken: String) {
    getUserHoldings(
      ownerId: $ownerId
      status_not: ARCHIVED
      count: $count
      nextToken: $nextToken
    ) {
      holdings {
        id
        legs
        ticker
        orderId
        qty
        exitParams
        backtestId
      }
      nextToken
    }
  }
`

export const CREATE_HOLDING = gql`
  mutation CreateHolding($input: CreateHoldingInput!) {
    createHolding(input: $input) {
      id
      orderId
    }
  }
`

export const UPDATE_HOLDING = gql`
  mutation UpdateHolding($input: UpdateHoldingInput!) {
    updateHolding(input: $input) {
      id
    }
  }
`

export const CREATE_SPIDER_ROCK_ORDER = gql`
  mutation CreateSpiderRockOrder($input: CreateSpiderRockOrderInput!) {
    createSpiderRockOrder(input: $input) {
      id
    }
  }
`

// MISC
export const GET_GLOBALS = gql`
  query GetGlobals {
    getGlobals {
      startDate
      endDate
      enterpriseUsers
    }
  }
`

// STRIPE
export const CREATE_STRIPE_CUSTOMER = gql`
  mutation CreateStripeCustomer($input: CreateStripeCustomerInput!) {
    createStripeCustomer(input: $input) {
      stripeCustomerId
    }
  }
`

export const PROCESS_SUBSCRIPTION = gql`
  mutation ProcessSubscription($input: ProcessSubscriptionInput!) {
    processSubscription(input: $input) {
      subscriptionId
    }
  }
`
