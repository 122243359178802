import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { observable } from "mobx"
import { Text, Flex, Box } from "rebass"
import _ from "lodash"
import { Button } from "semantic-ui-react"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid/dist/styles/ag-grid.css"
import "ag-grid/dist/styles/ag-theme-balham.css"
import { currencyFormatter, floatFormatter, numberParser } from "./Utils"
import Moment from "moment"
import FileSaver from "file-saver"
import FilterHits from "./FilterHits"

@inject("strategyStore", "userStore")
@observer
export default class Scanner extends Component {
  @observable
  isCreatingSRTrade = false
  @observable
  copied = false
  state = { focusedTradeId: null }

  onSelectionChanged = () => {
    const { strategyStore } = this.props
    const { rowIndex } = this.gridApi.getSelectedNodes()[0]
    strategyStore.setSelectedTradeIndex(rowIndex)
    this.setState({ focusedTradeId: rowIndex })
  }

  handleCreateSpiderRockOrder = async () => {
    this.isCreatingSRTrade = true
    const { strategyStore } = this.props
    const selectedTrade = this.gridApi.getSelectedRows()[0]
    // console.log(selectedTrade)
    const { srOrder, id } = selectedTrade

    const { orderId } = await strategyStore.createSpiderRockOrder({
      ...srOrder,
      positionType: "opening",
      id
    })
    selectedTrade.orderId = orderId
    await strategyStore.createStagedHolding(selectedTrade)
    this.isCreatingSRTrade = false
  }

  handleSetNotionalQty = holding => {
    // holding.qty = 99
    const { strategyStore } = this.props
    const { id } = this.gridApi.getSelectedRows()[0]

    strategyStore.setQtyForScan(id, 99)

    // console.log(id)
    // console.log(scans)

    // const scan = _.find(scans, h => h.id === id)

    // console.log(scan)
    // scan.id = 99

    // this.props.strategyStore.scans = _.map(scans, s => {
    //   s.qty = 99
    //   return s
    // })

    // console.log(holding)
    // console.log(selectedTrade.qty)
    // selectedTrade.qty = 99
    // console.log(selectedTrade.qty)
  }

  handleClearScanResults = () => {
    const { strategyStore } = this.props
    strategyStore.symbolQtyMap = null
    strategyStore.scans = []
  }

  handleDownloadCsv = async (e, data) => {
    const { scansForExport } = this.props.strategyStore
    const fileName = `WheelScan.${Moment().format("MMM.DD.HH.mma")}.csv`
    const blob = new Blob([scansForExport], {
      type: "text/plain;charset=utf-8"
    })
    FileSaver.saveAs(blob, fileName)
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
  }

  getContextMenuItems = ({ value, node, column: { colId }, defaultItems }) => {
    const { data: holding } = node
    if (!holding) {
      return defaultItems
    }
    switch (colId) {
      case "thinkOrSwimSlug":
        return [
          {
            name: "Create SR Order",
            action: () => {
              this.handleCreateSpiderRockOrder()
            }
          },
          {
            name: "Copy Trade",
            action: () => {
              holding.copyToClipboard()
            }
          }
        ]
      case "qty":
        return [
          {
            name: "Set Notional Quantity",
            action: () => {
              this.handleSetNotionalQty(holding)
            }
          }
        ]
      default:
        return defaultItems
    }
  }

  cellValueChanged = params => {
    const {
      strategyStore: { scans }
    } = this.props
    const { data: holding, newValue } = params
    // console.log("cellEditingStopped")
    holding.qty = newValue
    console.log(scans)
    // console.log(params)
  }

  render() {
    const { strategyStore, userStore } = this.props
    const { selectedBacktest, scans } = strategyStore
    const { focusedTradeId } = this.state
    const [, strategyName] = selectedBacktest.name.split(":")
    return (
      <Box mb={4}>
        <Flex>
          <Text f={2} fontWeight="bold" children="Scan Results" />
          <Box mr="auto" />
          {focusedTradeId !== null && (
            <Button
              size="tiny"
              onClick={this.handleCreateSpiderRockOrder}
              loading={this.isCreatingSRTrade}
              primary
              content="Create SR Order"
            />
          )}
          <Button
            size="tiny"
            onClick={this.handleClearScanResults}
            icon="delete"
          />
          <Button
            size="tiny"
            onClick={this.handleDownloadCsv}
            icon="download"
          />
        </Flex>

        <div
          className="ag-theme-balham"
          style={{
            marginTop: 6,
            height: "320px",
            width: "100%"
          }}>
          <AgGridReact
            enableColResize={true}
            toolPanelSuppressPivots={true}
            toolPanelSuppressPivotMode={true}
            defaultColDef={{ width: 130 }}
            rowSelection="single"
            onSelectionChanged={this.onSelectionChanged}
            onGridReady={this.onGridReady}
            deltaRowDataMode={true}
            getRowNodeId={data => data.id}
            rowData={scans}
            getContextMenuItems={this.getContextMenuItems}
            columnDefs={[
              { headerName: "Ticker", field: "ticker" },
              {
                headerName: "Strategy",
                field: "strategyName",
                valueGetter: () => _.startCase(strategyName)
              },
              {
                headerName: "Qty",
                field: "qty",
                width: 90,
                editable: true,
                type: "numericColumn",
                onCellValueChanged: this.cellValueChanged,
                valueParser: numberParser
              },
              { headerName: "Trade", field: "thinkOrSwimSlug", width: 350 },
              {
                headerName: "Price",
                field: "spreadTradePrice",
                type: "numericColumn",
                valueFormatter: currencyFormatter
              },
              {
                headerName: "Delta",
                field: "spreadDelta",
                type: "numericColumn",
                valueFormatter: floatFormatter
              },
              {
                headerName: "Stock Price",
                field: "stockPx",
                type: "numericColumn",
                valueFormatter: currencyFormatter
              }
            ]}
          />
        </div>
        {userStore.isNJ && <FilterHits />}
      </Box>
    )
  }
}
