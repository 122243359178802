// TODO:
// - weightings                               [ ]
// - signals                                  [ ]
// - rules for signals and weightings         [ ] really?
//    :signals override symbol input
//    :only one unique symbol for sigs
//    :no weightings and signals
// - TiedToLegNum                             [ ]
//    add no tied leg "none" (i can't get rid of it now that i clicked the dropdown)
//    can you have it not show its leg, ie leg 1 should not show 1 as an option
// - need to get csv defaults updated         [ ]
//    then revisit need for setMiscDefaults
// - can we reduce the width of the fields?   [ ]
// - revisit props table in report            [ ]

import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { observable } from "mobx"
import {
  Button,
  Grid,
  Sticky,
  Header,
  Segment,
  List,
  Accordion,
  Menu
} from "semantic-ui-react"
import _ from "lodash"
import Strategy from "../../models/Strategy"

import { FormPanel, LABELS } from "./FormSupport"
import { AccordianMenuLink, FormGroupPosition } from "../Styled"

@inject("strategyStore")
@observer
export default class NFrm extends Component {
  state = {
    activeIndex: 0,
    activeGroup: "strategy",
    activePanel: "General",
    hiddenAlternates: ["stockOTMPct", "exitBizDaysBeforeEarn"],
    isLoading: false
  }
  @observable form = {}

  // now I've got the positions maybe i can ditch the Visibility tags
  constructor(props) {
    super(props)
    if (props.match.params.id) {
      this.loadFormForEdit(props.match.params.id)
    } else {
      const [start, end] = this.props.strategyStore.backtestStartEndDates
      this.form = new Strategy("LongCall", start, end)
    }
    this.shouldQueryPositions = true
    this.groupPositions = []
  }

  loadFormForEdit = async id => {
    const { getBacktestForm } = this.props.strategyStore
    const form = await getBacktestForm(this.props.match.params.id)
    this.form = new Strategy(null, null, null, form)
  }

  componentDidMount() {
    window.addEventListener("scroll", _.throttle(this.handleScroll, 200))
    this.shouldQueryPositions = false
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    // not quite there with this
    // not sure we should be measuring to bottom
    // maybe we should determine based on scroll direction?
    const { key: activeGroup } = _.minBy(
      _.filter(this.groupPositions, el => el.bottom - window.scrollY > 0),
      el => el.bottom - window.scrollY
    )
    const activePanel = this.form.panelForGroup(activeGroup)
    this.setState({ activeGroup, activePanel })
  }

  handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    const activePanel = this.form.panels[newIndex]
    this.setState({ activeIndex: newIndex, activePanel })
  }

  scrollTo = key => () => {
    const { top } = _.find(this.groupPositions, { key })
    window.scroll({
      top: top - 10,
      left: 0,
      behavior: "smooth"
    })
  }

  handleTargetVisibility = (key, eventName, elementType) => () => {
    if (elementType === "group") {
      this.setState({ activeGroup: key })
    } else {
      this.setState({ activePanel: key })
    }
  }

  handleContextRef = contextRef => this.setState({ contextRef })

  handleDismiss = (e, data) => {
    const { history } = this.props
    history.push("/backtest")
  }

  toggleBasic = (e, data) => {
    const { strategyStore } = this.props
    strategyStore.setFocusGroup("symbol")
    this.form.toggleBasic()
  }

  handleSumbit = async (e, data) => {
    console.log(JSON.stringify(this.form.toJS(), 0, 2))
    const { history, strategyStore } = this.props
    const { backtestName } = this.form
    if (!window.doNotPost) {
      console.log("POST")
      this.setState({ loading: true })
      await strategyStore.postJSONBacktestRequest(
        this.form.toJS(),
        backtestName
      )
      this.setState({ loading: false })
      history.push("/backtest")
    } else {
      console.log("NOPOST")
    }
  }

  render() {
    const { contextRef, activeGroup, activePanel } = this.state
    const {
      panels,
      groupsForPanel,
      errors,
      backtestName,
      isShowingBasic
    } = this.form
    const { focusGroup } = this.props.strategyStore

    const basicToggleLinkText = isShowingBasic ? "Advanced" : "Basic"
    const basicToggleText = isShowingBasic ? "Basic" : "Advanced"
    return panels ? (
      <div ref={this.handleContextRef}>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column width="three">
              <Sticky context={contextRef} offset={10}>
                <Accordion as={Menu} fluid vertical>
                  <Menu.Item>
                    {isShowingBasic && (
                      <span>
                        <span>{basicToggleText}</span>
                        <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                          |
                        </span>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={this.toggleBasic}>
                          {basicToggleLinkText}
                        </a>
                      </span>
                    )}
                    {!isShowingBasic && (
                      <span>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={this.toggleBasic}>
                          {basicToggleLinkText}
                        </a>
                        <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                          |
                        </span>
                        <span>{basicToggleText}</span>
                      </span>
                    )}
                    {/* <Icon
                      onClick={this.toggleBasic}
                      color={isShowingBasic ? null : "blue"}
                      link
                      name={basicToggleIcon}
                      size="large"
                    /> */}
                  </Menu.Item>
                  {_.map(panels, (p, i) => {
                    return (
                      <Menu.Item key={p}>
                        <Accordion.Title
                          index={i}
                          active={activePanel === p}
                          onClick={this.handleAccordianClick}
                          content={<strong>{p}</strong>}
                        />
                        <Accordion.Content
                          active={activePanel === p}
                          content={
                            <List>
                              {_.map(groupsForPanel(p), (group, key) => {
                                return _.includes(
                                  this.state.hiddenAlternates,
                                  key
                                ) ? null : (
                                  <List.Item
                                    key={key}
                                    content={
                                      <AccordianMenuLink
                                        isActive={activeGroup === key}
                                        onClick={this.scrollTo(key)}>
                                        {LABELS[key] || _.startCase(key)}
                                      </AccordianMenuLink>
                                    }
                                  />
                                )
                              })}
                            </List>
                          }
                        />
                      </Menu.Item>
                    )
                  })}
                  <Menu.Item>
                    <List.Item>
                      <Button
                        style={{ marginRight: 10 }}
                        disabled={this.isPosting}
                        onClick={this.handleDismiss}
                        content="Cancel"
                        id="cancelBacktestCreate"
                        size="small"
                      />
                      <Button
                        content="Submit"
                        id="submitBacktest"
                        disabled={errors.size > 0}
                        onClick={this.handleSumbit}
                        primary
                        size="small"
                      />
                    </List.Item>
                  </Menu.Item>
                </Accordion>
              </Sticky>
            </Grid.Column>
            <Grid.Column>
              <div>
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.4)",
                    marginRight: "auto"
                  }}>{`Showing ${
                  isShowingBasic ? "Basic" : "Advanced"
                } fields: `}</span>
                <a style={{ cursor: "pointer" }} onClick={this.toggleBasic}>
                  {`Switch to ${isShowingBasic ? "Advanced" : "Basic"}`}
                </a>
              </div>
              <div
                style={{
                  marginTop: 8,
                  color: "rgba(0, 0, 0, 0.4)",
                  marginRight: "auto"
                }}>
                {backtestName}
              </div>
              {_.map(panels, panelName => (
                <Segment key={panelName}>
                  <Header content={panelName} />
                  {_.map(groupsForPanel(panelName), (group, groupKey) => {
                    // i think your position collecting div could use render props
                    return (
                      <FormGroupPosition key={groupKey}>
                        <div
                          ref={c => {
                            if (
                              !this.shouldQueryPositions &&
                              _.find(this.groupPositions, { key: groupKey }) ===
                                undefined
                            ) {
                              const {
                                top,
                                height,
                                bottom
                              } = c.getBoundingClientRect()

                              this.groupPositions.push({
                                top: window.scrollY + top,
                                height,
                                bottom,
                                key: groupKey
                              })
                            }

                            if (
                              c &&
                              !_.find(this.groupPositions, {
                                key: groupKey
                              })
                            ) {
                              const {
                                top,
                                height,
                                bottom
                              } = c.getBoundingClientRect()
                            }
                            if (c && this.shouldQueryPositions) {
                              const {
                                top,
                                height,
                                bottom
                              } = c.getBoundingClientRect()
                              this.groupPositions.push({
                                top,
                                height,
                                bottom,
                                key: groupKey
                              })
                            }
                          }}>
                          <FormPanel
                            focusGroup={focusGroup}
                            group={group}
                            form={this.form}
                            activeGroup={activeGroup}
                            groupKey={groupKey}
                          />
                        </div>
                      </FormGroupPosition>
                    )
                  })}
                </Segment>
              ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    ) : null
  }
}
