import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import {
  Checkbox,
  Button,
  Form,
  Grid,
  Segment,
  Modal,
  Popup,
  List,
  Table,
  Dropdown
} from "semantic-ui-react"
import _ from "lodash"
// import Strategy from "../../models/Strategy"
import { HELP_SNIPPETS } from "./Help"
import Papa from "papaparse"

import { Leg, GroupName } from "../Styled"

export const LABELS = {
  absDelta: "Absolute Delta",
  stockOTMPct: "Stock OTM Percentage",
  dte: "Days to Expiration",
  spreadPx: "Spread Price",
  spreadYieldPct: "Spread Yield Percent",
  symEntryExit: "Symbol Entry and Exit",
  iVRank: "IV Rank",
  iAskVol: "Implied Ask Volatility",
  iBidVol: "Implied Bid Volatility",
  exitBizDaysBeforeEarn: "Exit Business Days Before Earnings",
  exitBizDaysAfterEarn: "Exit Business Days After Earnings",
  adjustLegDelta: "Adjust Leg Delta (Trigger)",
  reEnter: "Re-Entry"
}

export const GroupHeader = ({
  groupKey,
  alternateGroup,
  nullifyHandler,
  disabled,
  groupLabel
}) => {
  const { content } = HELP_SNIPPETS[groupKey] || "no help snippet"
  return (
    <div>
      <Popup
        hideOnScroll
        wide
        position="top left"
        trigger={
          <GroupName disabled={disabled} name={groupKey}>
            {groupLabel}
          </GroupName>
        }
        content={content}
      />
      {nullifyHandler && (
        <Checkbox
          id={`enable-${groupKey}`}
          label={
            <label
              style={{
                color: disabled ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.9)"
              }}>
              {disabled ? "(disabled)" : "(enabled)"}
            </label>
          }
          onChange={() => nullifyHandler(groupKey)}
          checked={!disabled}
          style={{ marginLeft: 10, paddingTop: 3 }}
        />
      )}
    </div>
  )
}

@inject("strategyStore")
@observer
export class FormPanel extends Component {
  shouldComponentUpdate(nextProps) {
    // return true
    // if (this.props.groupKey === nextProps.focusGroup) {
    //   console.log(`only render group: ${this.props.groupKey}`)
    // }
    // if (this.props.form.isShowingBasic !== nextProps.form.isShowingBasic) {
    //   return true
    // }
    if (nextProps.focusGroup === "all") return true
    if (_.includes(nextProps.form.groupsWithErrors, this.props.groupKey))
      return true
    return this.props.groupKey === nextProps.focusGroup
  }

  handleChange = (key, legIndex = 0) => {
    const { form } = this.props
    const value = form.get(key, legIndex)
    return {
      value,
      onChange: (e, data) => {
        let { value, type, checked } = data || e.target
        if (type === "checkbox") {
          this.props.strategyStore.setFocusGroup(key)
          value = checked
        }
        if (key === "strategyName") {
          this.props.strategyStore.setFocusGroup("all")
        }
        form.set(key, value, legIndex)
      },
      onBlur: e => {
        if (key === "signals") {
          const arr = value.split(",")
          if (arr.length > 0) {
            form.set(
              "signals",
              [{ entryDate: arr[0], exitDate: arr[1] }],
              legIndex
            )
          }
        }
        if (/\.\d*/.test(value)) {
          form.set(key, +value, legIndex)
        }

        if (_.isString(value) && !isNaN(Number(value)) && value !== "") {
          form.set(key, +value, legIndex)
        }

        form.validate(key, legIndex)
      },
      onFocus: e => {
        this.props.strategyStore.setFocusGroup(key)
      },
      onPaste: e => {
        if (key === "symbol") {
          const { clipboardData } = e
          const text = clipboardData.getData("Text")
          if (text.split("\n").length > 1) {
            e.preventDefault()
            const parsed = Papa.parse(text, {
              skipEmptyLines: true
            }).data

            const symbols = _.reduce(
              parsed,
              (result, row, i) => {
                let [symbol, weight, entryDate, exitDate] = row
                result[symbol]
                  ? result[symbol].signals.push({ entryDate, exitDate })
                  : (result[symbol] = {
                      symbol,
                      weight: +weight,
                      signals:
                        entryDate && exitDate ? [{ entryDate, exitDate }] : null
                    })

                return result
              },
              {}
            )
            // console.log(_.values(symbols))
            form.set("symbols", _.values(symbols))
          }
        }
      }
    }
  }

  handleNullifyGroup = key => {
    // console.log(key)
    this.props.strategyStore.setFocusGroup(key)
    const { form } = this.props
    form.toggleNullifyGroup(key)
  }

  handleAddSymbolRow = e => {
    const { form } = this.props
    form.addSymbolRow()
  }

  handleRemoveSymbolRow = (index, e, data) => {
    const { form } = this.props
    form.removeSymbolRow(index)
  }

  handleAddSignals = (index, signals) => {
    const { form } = this.props
    form.set("signals", signals, index)
  }

  render() {
    const { form, group, groupKey } = this.props
    const { errors, disabledGroups, disableableGroups, isShowingBasic } = form
    const groupIsDisabled = _.includes(disabledGroups, groupKey)

    return (
      <Segment>
        <GroupHeader
          nullifyHandler={
            _.includes(disableableGroups, groupKey)
              ? this.handleNullifyGroup
              : null
          }
          disabled={groupIsDisabled}
          groupKey={groupKey}
          groupLabel={LABELS[groupKey] || _.startCase(groupKey)}
        />
        <Form error warning>
          {_.map(group, ({ field, label, placeholder, path, control }) => {
            const isOption = /options/.test(path)
            let checked = false
            let checkboxLabel = null
            const iterations = isOption ? form.legCount : 1
            const formLabel = label || _.replace(field, groupKey, "") //_.startCase(field)
            if (control === "checkbox") {
              checked = form.get(field)
              checkboxLabel =
                field === "standardExpiration"
                  ? `Standard Expirations ${checked ? "ONLY" : "NOT used"}`
                  : _.lowerCase(field)
            }
            const selectOptions = {
              strategyName: form.strategyOptions,
              exitTriggerLeg: _.map(_.range(iterations), i => ({
                key: i + 1,
                text: i + 1,
                value: i + 1
              })),
              exitBizDaysEarnType: _.map(["before", "after"], v => ({
                key: v,
                text: v,
                value: v
              })),
              tiedToLegNum: _.map(_.range(form.legCount), v => ({
                key: v + 1,
                text: v + 1,
                value: v + 1
              })),
              rollWithLeg: _.map(_.range(form.legCount), v => ({
                key: v + 1,
                text: v + 1,
                value: v + 1
              })),
              stockPositionType: _.map([null, "overlay", "married"], v => ({
                key: v,
                text: v === null ? "none" : v,
                value: v
              })),
              stockPositionRatio: _.map([1, 0, -1], v => ({
                key: v,
                text: v,
                value: v
              })),
              else: [
                {
                  key: "absDelta",
                  text: "Absolute Delta",
                  value: "absDelta"
                },
                {
                  key: "stockOTMPct",
                  text: "Stock OTM Percentage",
                  value: "stockOTMPct"
                }
              ]
            }

            return field === "symbol" && !isShowingBasic ? (
              <div key={`${field}`}>
                <SymbolInputs
                  addSymbolRowHandler={this.handleAddSymbolRow}
                  removeSymbolRowHandler={this.handleRemoveSymbolRow}
                  addSignalsHandler={this.handleAddSignals}
                  exitAtSignalValue={form.get("exitAtSignal")}
                  data={form.get("symbols")}
                  changeHandler={this.handleChange}
                />
              </div>
            ) : (
              <Form.Group key={`${field}`} widths="equal">
                {_.times(iterations, i => {
                  const error = errors.get(`${field}:${i}`) //errors[`${field}:${i}`]
                  const options = selectOptions[field] || selectOptions.else
                  return (
                    <FormItem
                      groupKey={groupKey}
                      disabled={groupIsDisabled}
                      key={`${field}-${i}`}
                      legIndex={i}
                      labelText={formLabel}
                      field={field}
                      error={error}
                      placeholder={placeholder}
                      checked={checked}
                      checkboxLabel={checkboxLabel}
                      options={options}
                      isOption={isOption}
                      control={control}
                      optionProps={form.optionProps(i)}
                      changeHandler={this.handleChange(field, i)}
                    />
                  )
                })}
              </Form.Group>
            )
          })}
        </Form>
      </Segment>
    )
  }
}

class FormItem extends Component {
  shouldComponentUpdate(nextProps) {
    // return true
    // if (this.props.changeHandler.value !== nextProps.changeHandler.value) {
    //   console.log(`only render: ${nextProps.changeHandler.value}`)
    // }
    if (nextProps.disabled !== this.props.disabled) return true
    if (nextProps.error || this.props.error) return true
    return this.props.changeHandler.value !== nextProps.changeHandler.value
  }

  render() {
    const {
      disabled,
      groupKey,
      changeHandler,
      labelText,
      legIndex,
      options,
      optionProps,
      placeholder,
      error,
      isOption,
      control,
      checked,
      checkboxLabel
    } = this.props
    const { optionType, ratio } = optionProps
    const hasError = error !== undefined
    // const val = changeHandler.value
    // console.log(`render for: ${field} with value: ${val}`)
    return (
      <Form.Field>
        <label style={{ color: "rgba(0, 0, 0, 0.5)" }}>
          <Grid columns="equal">
            <Grid.Column>
              {legIndex === 0 ? (labelText !== "none" ? labelText : null) : ""}
            </Grid.Column>
            {isOption && (
              <Grid.Column textAlign="right">
                <Leg ratio={ratio}>
                  {ratio} {optionType.toUpperCase()}
                </Leg>
              </Grid.Column>
            )}
          </Grid>
        </label>
        {control === "select" && (
          <Dropdown
            disabled={disabled}
            selection
            id={`select-${groupKey}-${labelText}`}
            search
            labeled
            {...changeHandler}
            options={options}
          />
        )}
        {control === "checkbox" && (
          <Checkbox
            disabled={disabled}
            label={checkboxLabel}
            checked={checked}
            onChange={changeHandler.onChange}
          />
        ) // legIndex
        }
        {!control && (
          <Form.Input
            id={`${groupKey}-${labelText}-${legIndex}`}
            disabled={disabled}
            {...changeHandler}
            placeholder={placeholder}
            error={hasError}
          />
        )}
        {hasError && <Errors errors={error.slice()} />}
      </Form.Field>
    )
  }
}

export const Errors = ({ errors }) => {
  return (
    <List
      size="small"
      style={{ marginTop: 10, paddingLeft: 10, color: "#9f3a38" }}
      items={errors}
      bulleted
    />
  )
}

class SignalsModal extends Component {
  state = {
    input: "",
    signals: [],
    open: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialSignals) {
      this.setState({ signals: [...nextProps.initialSignals.slice()] })
    }
  }

  handleChange = key => {
    const value = this.state.input
    return {
      value,
      onPaste: e => {
        const { clipboardData } = e
        const text = clipboardData.getData("Text")
        if (text.split("\n").length > 1) {
          e.preventDefault()
          const parsed = Papa.parse(text, {
            skipEmptyLines: true
          }).data
          const sigs = _.map(parsed, ([entryDate, exitDate]) => {
            return { entryDate, exitDate }
          })
          this.setState({ signals: sigs, input: "" })
        }
      }
    }
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = (e, { content }) => {
    const { signals } = this.state
    if (content === "OK" && signals.length > 0) {
      const { signalIndex, addSignalsHandler } = this.props
      const { signals } = this.state
      addSignalsHandler(signalIndex, signals)
    } else {
      console.log("not setting anything")
    }
    this.setState({ open: false })
  }

  render() {
    const { initialSignals, symbol } = this.props
    const { signals, open } = this.state
    const triggerText = initialSignals
      ? `View | ${initialSignals.length} entries`
      : "Add"
    return (
      <Modal
        open={open}
        trigger={
          <a
            style={{ fontWeight: 600, cursor: "default" }}
            onClick={this.handleOpen}>
            {triggerText}
          </a>
        }>
        <Modal.Header>{`Signals for ${symbol}`}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <p>Paste your entry and exit dates into the field below:</p>
            <Form>
              <Form.Input
                placeholder="paste values here"
                width={16}
                {...this.handleChange("input")}
              />
            </Form>
            <Table
              basic="very"
              celled
              size="small"
              compact
              headerRow={["Entry", "Exit"]}
              renderBodyRow={({ entryDate, exitDate }, i) => ({
                key: `row-${i}`,
                cells: [entryDate, exitDate]
              })}
              tableData={signals}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={this.handleClose} />
          <Button primary content="OK" onClick={this.handleClose} />
        </Modal.Actions>
      </Modal>
    )
  }
}

export const SymbolInputs = observer(
  ({
    data,
    changeHandler,
    addSymbolRowHandler,
    removeSymbolRowHandler,
    addSignalsHandler,
    exitAtSignalValue
  }) => {
    const { onChange: exitAtSignalOnChange } = changeHandler("exitAtSignal")
    return (
      <Table basic="very" size="small" compact>
        <Table.Header
          content={
            <Table.Row>
              {_.map(
                ["Symbol", "Weight", "Entry & Exit Signals", ""],
                (h, i) => (
                  <Table.HeaderCell
                    style={{ color: "rgba(0, 0, 0, 0.5)" }}
                    key={`h-${i}`}
                    content={h}
                  />
                )
              )}
            </Table.Row>
          }
        />
        <Table.Body>
          {_.map(data, ({ symbol, weight, signals }, i) => {
            // const hasSignals = signals ? _.isArray(signals.slice()) : false
            // console.log(signals)
            return (
              <Table.Row key={`row-${i}`}>
                <Table.Cell>
                  <Form.Input
                    id={`symbol-${i}`}
                    {...changeHandler("symbol", i)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Input
                    id={`weight-${i}`}
                    {...changeHandler("weight", i)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <SignalsModal
                    initialSignals={signals ? signals.slice() : null}
                    symbol={symbol}
                    signalIndex={i}
                    addSignalsHandler={addSignalsHandler}
                  />
                </Table.Cell>
                <Table.Cell
                  textAlign="right"
                  collapsing
                  onClick={removeSymbolRowHandler.bind(null, i)}
                  content={<Button size="mini" circular icon="delete" />}
                />
              </Table.Row>
            )
          })}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="2">
              <Checkbox
                label="exit at signal"
                checked={exitAtSignalValue}
                onChange={exitAtSignalOnChange}
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="2">
              <Button
                floated="right"
                size="mini"
                onClick={addSymbolRowHandler}
                content="Add Row"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }
)
