import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { observable, computed } from "mobx"
import { Input, Popup, Table, Message, Button, Loader } from "semantic-ui-react"
import BacktestChooser from "./BacktestChooser"
import { ExitParams } from "./Components"
import Scanner from "./Scanner"
import _ from "lodash"
import { Image, Text, Flex, Box } from "rebass"

import { AgGridReact } from "ag-grid-react"
import "ag-grid-enterprise"
import "ag-grid/dist/styles/ag-grid.css"
import "ag-grid/dist/styles/ag-theme-balham.css"

import { currencyFormatter, intFormatter, floatFormatter } from "./Utils"

import Moment from "moment"
import FileSaver from "file-saver"
import GreenCheck from "../../images/green-check.svg"

class ExitParamsCellRenderer extends React.Component {
  render() {
    const { value } = this.props
    return value ? <ExitParams params={value} /> : null
  }
}

class ExitCellRenderer extends React.Component {
  render() {
    const { value } = this.props
    return value && value.reason ? (
      <Popup
        trigger={
          <Image
            mt={"-4px"}
            style={{ display: "inline-block", verticalAlign: "middle" }}
            src={GreenCheck}
          />
        }
        content={`Reason: ${value.reason}`}
      />
    ) : null
  }
}

@inject("userStore", "strategyStore")
@observer
export default class Positions extends Component {
  state = {
    backtestChooserOpen: false,
    entryModalOpen: false,
    focusedTrade: null,
    focusedTradeId: null
  }
  @observable
  isCreatingSRTrade = false
  @observable
  isImportCsv = false
  @observable
  isPersistingImport = false
  @observable
  holdingsCsv = ""
  @observable
  isDeletingHoldings = false
  @observable
  isPairingBacktest = false

  @computed
  get isBusy() {
    return this.isCreatingSRTrade || this.isPairingBacktest
  }

  componentWillMount() {
    const {
      strategyStore,
      userStore: { isCalper }
    } = this.props
    strategyStore.getHoldings()
    // strategyStore.setHoldingsUpdateCallback(this.holdingsUpdateCallback)
    if (isCalper) {
      strategyStore.pollForSpiderRockFills()
      strategyStore.pollForExits()
    }
  }

  handleOpen = () => {
    const { strategyStore } = this.props
    strategyStore.setActiveBacktest(null)
    this.setState({ backtestChooserOpen: true })
    // if already open
    if (strategyStore.scans.length > 0) {
      strategyStore.symbolQtyMap = null
      strategyStore.scans = []
    }
  }
  handleCancel = () => {
    const { strategyStore } = this.props
    strategyStore.clearSymbolsQtyMap()
    this.setState({ backtestChooserOpen: false })
  }

  handleSelectBacktest = async () => {
    const { strategyStore } = this.props
    const {
      selectedBacktest: { id: backtestId },
      selectedHoldingIds
    } = strategyStore

    if (selectedHoldingIds.length > 0) {
      this.isPairingBacktest = true
      this.setState({ backtestChooserOpen: false })
      await strategyStore.pairBacktestToHoldings({
        holdingIds: selectedHoldingIds,
        backtestId
      })
      this.isPairingBacktest = false
      this.gridApi.deselectAll()
    } else {
      strategyStore.getScanResult(backtestId)
    }
    this.setState({ backtestChooserOpen: false })
  }

  // so call strategyStore.createSpiderRockOrder passing holding id
  handleCreateSpiderRockExitOrder = async holding => {
    this.isCreatingSRTrade = true
    const { strategyStore } = this.props
    const {
      exit: { trade: exitTrade }
    } = holding
    await strategyStore.createSpiderRockOrder({
      ...exitTrade.srOrder,
      positionType: "closing"
    })
    this.isCreatingSRTrade = false
  }

  handleScanForExits = () => {
    const { strategyStore } = this.props
    strategyStore.getScanExitResult()
  }

  handleDismissMessages = () => {
    const { strategyStore } = this.props
    strategyStore.clearMessages()
  }

  handleToggleIsImportCsv = () => {
    this.isImportCsv = !this.isImportCsv
    if (!this.isImportCsv) {
      const { strategyStore } = this.props
      this.holdingsCsv = ""
      strategyStore.clearPastedCvsHoldings()
    }
  }

  handleDownloadPositions = () => {
    const { positionsForExport } = this.props.strategyStore
    const fileName = `WheelPositions.${Moment().format("MMM.DD.HH.mma")}.csv`
    const blob = new Blob([positionsForExport], {
      type: "text/plain;charset=utf-8"
    })
    FileSaver.saveAs(blob, fileName)
  }

  handleHoldingsCsvChange = (e, data) => {
    const value = this.holdingsCsv
    return {
      value,
      onPaste: e => {
        const { strategyStore } = this.props
        const { clipboardData } = e
        const text = clipboardData.getData("Text")
        this.holdingsCsv = text
        strategyStore.parseHoldingsCsv(text)
      }
    }
  }

  handleSaveHoldings = async () => {
    this.isPersistingImport = true
    const { strategyStore } = this.props
    // await strategyStore.createHoldings()
    await strategyStore.replaceHoldings()
    this.handleToggleIsImportCsv()
    this.isPersistingImport = false
  }

  handleDeleteHoldings = async (e, data) => {
    this.isDeletingHoldings = true
    await this.props.strategyStore.archiveSelectedHoldings()
    this.isDeletingHoldings = false
  }

  handleHoldingRowClick = (id, e) => {
    const { strategyStore } = this.props
    strategyStore.setSelectedHoldingId(id)
    this.holdingUnderEdit = strategyStore.selectedHolding.thinkOrSwimSlug
  }

  onGridReady = params => {
    this.gridApi = params.api
  }

  getContextMenuItems = ({ value, node, column, defaultItems }) => {
    const { strategyStore } = this.props
    if (strategyStore.holdings.length === 0) {
      return defaultItems
    }

    const { data: holding } = node
    if (!holding) {
      return defaultItems
    }

    switch (column.colId) {
      case "exit":
        return [
          {
            name: "Create SR Exit Order",
            disabled: holding.qty < 1 || (holding.exit && !holding.exit.reason),
            action: () => {
              this.handleCreateSpiderRockExitOrder(holding)
            }
          },
          {
            name: "Copy Exit Trade",
            disabled: holding.exit && !holding.exit.reason,
            action: () => {
              // call an action method on strategyStore passing holding id
              strategyStore.copyTradeToClipboard({ id: holding.id, exit: true })
              // holding.exit.trade.copyToClipboard()
            }
          }
        ]
      case "thinkOrSwimSlug":
        return [
          {
            name: "Copy Trade",
            action: () => {
              strategyStore.copyTradeToClipboard({
                id: holding.id,
                exit: false
              })
              // holding.copyToClipboard()
            }
          }
        ]
      default:
        return defaultItems
    }
  }

  onSelectionChanged = () => {
    const { strategyStore } = this.props
    const selectedRows = this.gridApi.getSelectedRows()
    const ids = _.map(selectedRows, "id")
    strategyStore.setSelectedHoldingIds(ids)
    // this.holdingUnderEdit = strategyStore.selectedHolding.thinkOrSwimSlug
  }

  getRowStyle = ({ node }) => {
    if (node.group) {
      return { fontWeight: 700 }
    }
  }

  getCurrencyRowStyle = ({ value }) => {
    return value < 0 ? { color: "red" } : { color: "green" }
  }

  handleExitDownloadCsv = async (e, data) => {
    const { exitScansForExport } = this.props.strategyStore
    const fileName = `WheelScan.${Moment().format("MMM.DD.HH.mma")}.csv`
    const blob = new Blob([exitScansForExport], {
      type: "text/plain;charset=utf-8"
    })
    FileSaver.saveAs(blob, fileName)
  }

  render() {
    const { strategyStore } = this.props
    const {
      isScanning,
      scans,
      messages,
      holdingsForAGGrid: holdings,
      selectedHoldingIds,
      holdingsWithExits
    } = strategyStore

    return (
      <Box p={3}>
        {messages.length > 0 && (
          <Message
            warning
            error={/\berror\b/.test(messages[0])}
            onDismiss={this.handleDismissMessages}
            content={messages.length === 1 ? messages[0] : null}
            list={messages.length > 1 ? messages.slice() : null}
          />
        )}
        {scans.length > 0 && <Scanner />}
        <Flex align="center">
          {selectedHoldingIds.length === 0 ? (
            <Flex>
              <Text f={2} fontWeight="bold" children="Positions" />
              {this.isBusy && (
                <Loader
                  style={{ marginTop: 2, marginLeft: 4 }}
                  active
                  size="tiny"
                  inline
                />
              )}
            </Flex>
          ) : (
            <div>
              <Button
                size="tiny"
                onClick={this.handleDeleteHoldings}
                negative
                content={`Delete ${
                  selectedHoldingIds.length === 1 ? "Position" : "Positions"
                }`}
              />
              <BacktestChooser
                open={this.state.backtestChooserOpen}
                triggerLabel="Pair to Backtest"
                actionLabel="Pair"
                handleOpen={this.handleOpen}
                handleCancel={this.handleCancel}
                loading={this.isPairingBacktest}
                handleSelectBacktest={this.handleSelectBacktest}
              />
            </div>
          )}
          {isScanning && (
            <Text f={2} color="darken.2" ml={2} children="Scanning..." />
          )}
          <Box mr="auto" />
          <Flex>
            {this.isImportCsv && (
              <Box pt={1} mr={2}>
                <Popup
                  on="click"
                  flowing={true}
                  trigger={
                    <Text
                      style={{ textDecoration: "underline" }}
                      children="Help"
                    />
                  }>
                  <Box mb={2}>
                    <span>
                      Paste text from a spredsheet containing columns for:{" "}
                      <br />
                    </span>
                    <Table collapsing>
                      <Table.Body>
                        <Table.Row>
                          {_.map(
                            [
                              "symbol",
                              "option type",
                              "entry date",
                              "expiration date",
                              "leg number",
                              "quantity",
                              "strike",
                              "option price"
                            ],
                            (text, i) => (
                              <Table.Cell key={`help-table-${i}`}>
                                {text}
                              </Table.Cell>
                            )
                          )}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Box>
                  <Box mt={3}>
                    <span>
                      Download a{" "}
                      <a href="https://s3.amazonaws.com/assets.orats.com/orats.positions.import.sample.csv">
                        sample file
                      </a>
                    </span>
                  </Box>
                </Popup>
              </Box>
            )}
            {!this.isImportCsv ? (
              <Box>
                <Button
                  size="tiny"
                  onClick={this.handleToggleIsImportCsv}
                  primary
                  content="Import Positions"
                />
                <Button
                  size="tiny"
                  onClick={this.handleDownloadPositions}
                  primary
                  content="Download Positions"
                />
              </Box>
            ) : (
              <Box width={400} mr={1}>
                <Input
                  width="100%"
                  size="mini"
                  fluid
                  type="text"
                  {...this.handleHoldingsCsvChange()}
                  action>
                  <input />
                  <Button
                    positive
                    loading={this.isPersistingImport}
                    size="tiny"
                    onClick={this.handleSaveHoldings}
                    icon="check"
                  />
                  <Button
                    size="tiny"
                    onClick={this.handleToggleIsImportCsv}
                    icon="cancel"
                  />
                </Input>
              </Box>
            )}
            {selectedHoldingIds.length === 0 && (
              <BacktestChooser
                open={this.state.backtestChooserOpen}
                triggerLabel="Scan for Entry Trades"
                actionLabel="Scan"
                loading={isScanning}
                handleOpen={this.handleOpen}
                handleCancel={this.handleCancel}
                handleSelectBacktest={this.handleSelectBacktest}
              />
            )}

            {holdingsWithExits.length > 0 && (
              <Button
                size="tiny"
                onClick={this.handleExitDownloadCsv}
                content={`Download Exit Trades`}
                icon="download"
              />
            )}
          </Flex>
        </Flex>
        <div
          className="ag-theme-balham"
          style={{ marginTop: 6, height: "500px", width: "100%" }}>
          <AgGridReact
            groupDefaultExpanded={1}
            enableColResize={true}
            toolPanelSuppressPivots={true}
            toolPanelSuppressPivotMode={true}
            enableRangeSelection={true}
            defaultColDef={{ width: 130 }}
            rowSelection="multiple"
            onSelectionChanged={this.onSelectionChanged}
            getContextMenuItems={this.getContextMenuItems}
            onGridReady={this.onGridReady}
            rowData={holdings}
            suppressAggFuncInHeader={true}
            rowDeselection={true}
            deltaRowDataMode={true}
            suppressRowClickSelection={true}
            getRowNodeId={data => data.id}
            overlayNoRowsTemplate={"<span>No Positions to display</span>"}
            getRowStyle={this.getRowStyle}
            columnDefs={
              [
                {
                  headerName: "Ticker",
                  field: "ticker",
                  rowGroup: true,
                  width: 110,
                  headerCheckboxSelection: true,
                  checkboxSelection: ({ data }) => {
                    return data && data.id !== null
                  }
                },
                {
                  headerName: "Stock",
                  valueFormatter: currencyFormatter,
                  field: "market.stockPx",
                  type: "numericColumn",
                  width: 100
                },
                { headerName: "Trade", field: "thinkOrSwimSlug" },
                {
                  headerName: "Qty",
                  field: "qty",
                  enableValue: false,
                  valueFormatter: ({ data, value }) => {
                    if (value) {
                      return data.spreadTradePrice < 0 ? value * -1 : value
                    }
                  },
                  type: "numericColumn",
                  width: 90
                },
                {
                  headerName: "Trade Price",
                  field: "spreadTradePrice",
                  valueFormatter: currencyFormatter,
                  type: "numericColumn",
                  width: 110
                },
                {
                  headerName: "Mark",
                  field: "market.mark",
                  cellStyle: this.getCurrencyRowStyle,
                  valueFormatter: currencyFormatter,
                  type: "numericColumn"
                },
                {
                  headerName: "Profit",
                  field: "profit",
                  type: "numericColumn",
                  cellStyle: this.getCurrencyRowStyle,
                  valueFormatter: currencyFormatter,
                  width: 100
                },
                {
                  headerName: "Trade Delta",
                  field: "market.optionDelta",
                  type: "numericColumn",
                  valueFormatter: floatFormatter,
                  width: 100
                },
                {
                  headerName: "Rip Delta",
                  field: "market.spreadTotalRipDelta",
                  aggFunc: "sum",
                  type: "numericColumn",
                  valueFormatter: intFormatter,
                  width: 95
                },
                {
                  headerName: "Delta",
                  field: "market.spreadTotalDelta",
                  aggFunc: "sum",
                  type: "numericColumn",
                  valueFormatter: intFormatter,
                  width: 90
                },
                {
                  headerName: "Gamma",
                  field: "market.spreadTotalGamma",
                  aggFunc: "sum",
                  type: "numericColumn",
                  valueFormatter: intFormatter,
                  width: 90
                },
                {
                  headerName: "Vega",
                  field: "market.spreadTotalVega",
                  aggFunc: "sum",
                  type: "numericColumn",
                  valueFormatter: intFormatter,
                  width: 90
                },
                {
                  headerName: "Exit Params",
                  field: "exitParams",
                  cellRendererFramework: ExitParamsCellRenderer,
                  width: 120
                },
                {
                  headerName: "Exit",
                  field: "exit",
                  cellRendererFramework: ExitCellRenderer,
                  width: 80
                }
              ] // }, //   width: 165 //   valueFormatter: intFormatter, //   type: "numericColumn", //   aggFunc: "sum", //   field: "market.spreadTotalRipDelta", //   headerName: "Total Rip Delta", // { // }, //   width: 140 //   valueFormatter: intFormatter, //   type: "numericColumn", //   aggFunc: "sum", //   field: "market.spreadTotalDelta", //   headerName: "Total Delta", // {
            }
          />
        </div>
      </Box>
    )
  }
}
