import { observable, computed, toJS } from "mobx"
import _ from "lodash"
import Moment from "moment"
import numeral from "numeral"

const STRATSIGMAP = {
  "2SDSS": "VERTICAL",
  "2SDDS": "BACKRATIO",
  "2DSSS": "CALENDAR",
  "2DDSS": "DIAGONAL",
  "2SSSD": "STRADDLE",
  "2SDSD": "STRANGLE",
  "3SDSSD": "BUTTERFLY",
  "4SDSSDSDS": "CONDOR",
  "4SDSSDDDS": "IRON CONDOR"
}

// maybe rename `status` to `type`
export default class Trade {
  @observable status = null
  @observable legs = []
  @observable orderQty = 10
  @observable fillQty = null
  @observable avgFillPrice = null
  @observable progressTime = null
  @observable positionType = null
  @observable exitParams = null
  @observable entryDate = null

  constructor({
    legs,
    status,
    positionId,
    id: tradeId,
    orderQty,
    fillQty,
    avgFillPrice,
    positionType,
    exitParams,
    entryDate
  }) {
    this.status = status
    this.legs = legs
    this.positionId = positionId
    this.tradeId = tradeId
    this.fillQty = fillQty || 0
    this.orderQty = orderQty || 10
    this.avgFillPrice = avgFillPrice
    this.positionType = positionType
    this.exitParams = exitParams
    this.entryDate = entryDate
    // hmm...
    // if (status === "CLOSED") {
    //   _.forEach(this.legs, l => (l.ratio = l.ratio * -1))
    // }
  }

  @computed
  get spreadPx() {
    return _.reduce(
      this.legs,
      (sum, { ratio, tradeOptPx }, i) => {
        return sum + tradeOptPx * ratio
      },
      0
    )
  }

  @computed
  get spreadPrice() {
    return this.spreadPx
  }

  @computed
  get orderSide() {
    return this.positionType === "opening"
      ? this.action === "BUY"
        ? "BUY"
        : "SELL"
      : this.action === "BUY"
        ? "SELL"
        : "BUY"
  }

  @computed
  get spreadDelta() {
    return _.reduce(
      this.legs,
      (sum, { ratio, delta }, i) => {
        return sum + delta * ratio
      },
      0
    )
  }

  @computed
  get spreadTotalDelta() {
    return this.fillQty * this.spreadDelta * 100
  }

  @computed
  get spreadGamma() {
    return _.reduce(
      this.legs,
      (sum, { ratio, gamma }, i) => {
        return sum + gamma * ratio
      },
      0
    )
  }

  @computed
  get spreadVega() {
    return _.reduce(
      this.legs,
      (sum, { ratio, vega }, i) => {
        return sum + vega * ratio
      },
      0
    )
  }

  @computed
  get spreadRipDelta() {
    return _.reduce(
      this.legs,
      (sum, { ratio, ripDelta }, i) => {
        return sum + ripDelta * ratio
      },
      0
    )
  }

  @computed
  get spreadTotalRipDelta() {
    return this.fillQty * this.spreadRipDelta * 100
  }

  @computed
  get stockPx() {
    return this.legs[0].stockPx
  }

  @computed
  get filled() {
    return `${this.fillQty || 0} / ${this.orderQty}`
  }

  @computed
  get ticker() {
    return this.legs[0].ticker
  }

  @computed
  get legCount() {
    return this.legs.length
  }

  @computed
  get oneTwoDTE() {
    return this.legs[0].expirDate === this.legs[1].expirDate ? "S" : "D"
  }

  @computed
  get oneTwoStrike() {
    return this.legs[0].strike === this.legs[1].strike ? "S" : "D"
  }

  @computed
  get twoThreeStrike() {
    return this.legs[1].strike === this.legs[2].strike ? "S" : "D"
  }

  @computed
  get threeFourStrike() {
    return this.legs[2].strike === this.legs[3].strike ? "S" : "D"
  }

  @computed
  get oneTwoQty() {
    return Math.abs(this.legs[0].ratio) === Math.abs(this.legs[1].ratio)
      ? "S"
      : "D"
  }

  @computed
  get oneTwoOptionType() {
    return this.legs[0].optionType === this.legs[1].optionType ? "S" : "D"
  }

  @computed
  get twoThreeOptionType() {
    return this.legs[1].optionType === this.legs[2].optionType ? "S" : "D"
  }

  @computed
  get threeFourOptionType() {
    return this.legs[2].optionType === this.legs[3].optionType ? "S" : "D"
  }

  @computed
  get strategyLabel() {
    let result = ""

    if (this.legCount === 2) {
      const sig = [
        this.legCount,
        this.oneTwoDTE,
        this.oneTwoStrike,
        this.oneTwoQty,
        this.oneTwoOptionType
      ].join("")
      result = STRATSIGMAP[sig]
    } else if (this.legCount === 3) {
      const sig = [
        this.legCount,
        this.oneTwoDTE,
        this.oneTwoStrike,
        this.oneTwoQty,
        this.oneTwoOptionType,
        this.twoThreeStrike
      ].join("")
      result = STRATSIGMAP[sig]
    } else if (this.legCount === 4) {
      const sig = [
        this.legCount,
        this.oneTwoDTE,
        this.oneTwoStrike,
        this.oneTwoQty,
        this.oneTwoOptionType,
        this.twoThreeStrike,
        this.twoThreeOptionType,
        this.threeFourStrike,
        this.threeFourOptionType
      ].join("")
      result = STRATSIGMAP[sig]
    }

    return result
  }

  @computed
  get action() {
    return this.spreadPx > 0 ? "BUY" : "SELL"
  }

  @computed
  get thinkOrSwimSlug() {
    const { legs } = this
    const {
      ratio,
      expirDate,
      optionType,
      ticker
    } = legs[0]

    // const action = ratio > 0 ? "BUY" : "SELL"
    // const action = this.spreadPx > 0 ? "BUY" : "SELL"

    let qty =
      this.positionType === "closing"
        ? this.fillQty
          ? this.fillQty
          : 1
        : this.orderQty
    qty = this.orderSide === "BUY" ? `+${qty}` : `-${qty}`
    const [day, month, year] = Moment(expirDate)
      .format("DD-MMM-YY")
      .split("-")

    if (legs.length > 1 && this.oneTwoQty === "D") {
      qty = `${qty} ${Math.abs(ratio)}/${Math.abs(legs[1].ratio)}`
    }

    const strikes = _.map(legs, "strike").join("/")
    const result = `${this.orderSide} ${qty} ${
      this.strategyLabel
    } ${ticker} 100 ${day} ${month.toUpperCase()} ${year} ${strikes} ${optionType.toUpperCase()} @${numeral(
      this.spreadPx
    ).format("0.00")} LMT`
    return result.replace("  ", " ")
  }

  toJS() {
    return toJS(this)
  }
}
