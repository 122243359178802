import chroma from "chroma-js"
const names = [
  "red", // 0
  "orange", // 30
  "yellow", // 60
  "lime", // 90
  "green", // 120
  "teal", // 150
  "cyan", // 180
  "blue", // 210
  "indigo", // 240
  "violet", // 270
  "fuschia", // 300
  "pink", // 330
  "red" // 360
]

const lums = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map(n => n + 0.5).map(n => n / 10)

const hueName = h => {
  const i = Math.round((h - 2) / 30)
  const name = names[i]
  return name
}

const createHues = h =>
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(n =>
    Math.floor((h + (n * 360) / 12) % 360)
  )

export const createColors = base => {
  const colors = {
    text: "#024",
    black: "#000",
    white: "#fff",
    darken: [
      "rgba(0, 0, 0, 0.001)",
      "rgba(0, 0, 0, 0.125)",
      "rgba(0, 0, 0, 0.25)",
      "rgba(0, 0, 0, 0.5)",
      "rgba(0, 0, 0, 0.75)"
    ],
    dark: "rgba(0, 0, 0, 0.75)",
    gray: [
      "#faf9f8",
      "#efeceb",
      "#e4dfdc",
      "#d8d1cd",
      "#cbc2bc",
      "#bcb0a9",
      "#ab9d94",
      "#97857a",
      "#776960",
      "#453d38"
    ]
  }
  const color = chroma(base)
  const [hue, sat, lite] = color.hsl()
  const hues = createHues(hue)
  hues.forEach(h => {
    const name = hueName(h)
    const val = chroma.hsl(h, sat, lite)
    // console.log(chroma.scale(val.hex))
    const colorArray = lums.map(lum => {
      return chroma(val.hex())
        .luminance(lum)
        .hex()
    })
    // console.log(bjbja)
    colors[name] = colorArray
  })

  return colors
}

const colors = createColors("#E6762D")

export default colors
